import React, { Component } from 'react'
import { connect } from 'react-redux'
import "./product_dropdown.scss"
import { Select } from 'antd';
import cur_icon_open from "../../images/emotion_analysis/cur_icon_open.png"
import UserAPI from '../../api/UserAPI';
import { getProjectProgress } from '../../api/progressApi/progress';
import XmSelect from '../../components/Select/xm_select';
import { message } from 'antd';

import { getQueryParameter } from "../../util/utils"

class product_dropdown extends Component {
    constructor(props) {
        super(props)
        this.state = {
            product_list: [], //名称
            brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : "23",   //品牌ID
            product_title: "",//产品名称
            product_id_cem: "",
            project_survey_id_cem: "",
            project_survey_list: [],
            brand_id_cem: "",
            brand_list: [], //品牌
            brand_title: "",
            init_brand_id: "", //跳转
            init_brand_text: "",
            init_product_id: "", //跳转
            init_product_text: "",
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this.GetBrand()
    }
    componentWillUnMount = () => {
        this._isMounted = false;
        // this.setState = () => { }
        this.setState = (state, callback) => {
            return
        }
    }
    // 初始化页面
    async initPanel() {
        let product_id_cem = localStorage.getItem("product_id_cem")
        let product_list = localStorage.getItem("product_list_cem") !== "" ? JSON.parse(localStorage.getItem("product_list_cem")) : []
        let survey_id = localStorage.getItem("project_survey_id_cem");
        let project_survey_list = localStorage.getItem("project_survey_list_cem") !== "" ? JSON.parse(localStorage.getItem("project_survey_list_cem")) : []
        let product_title = localStorage.getItem("product_text_cem")
        // 品牌 默认
        let brand_id_cem = localStorage.getItem("brand_id_cem")
        let brand_list = JSON.parse(localStorage.getItem("brand_list_cem"))
        let brand_title = localStorage.getItem("brand_text_cem")
        this.setState({
            product_id_cem: product_id_cem ? parseInt(product_id_cem) : "",
            product_list: product_list,
            product_title: product_title,
            project_survey_id_cem: survey_id ? parseInt(survey_id) : "",
            project_survey_list: project_survey_list,
            brand_id_cem: parseInt(brand_id_cem),
            brand_list: brand_list,
            brand_title: brand_title,
        }, () => {
            this.props.handleProductChange(this.state.product_id_cem, this.state.project_survey_id_cem)
        })
    }
    // brand 列表变更要初始化 + 第一次进入要setbrand
    initBrand(brand_list_cem) {
        // 判断品牌列表是否未空
        if (brand_list_cem?.length) {
            localStorage.setItem('brand_id_cem', brand_list_cem[0].key)
            localStorage.setItem('brand_text_cem', brand_list_cem[0].value)
            localStorage.setItem('brand_list_cem', JSON.stringify(brand_list_cem))
            this.setState({
                brand_id_cem: brand_list_cem[0].key,
                brand_list: brand_list_cem,
                brand_title: brand_list_cem[0].value
            }, () => {
                this.GetBrandProduct(false)
            })
        } else {
            // 没有品牌时  -1：代表没有品牌
            this.props.handleProductChange('-1', '-1')
        }

    }
    // 从项目/产品列表的详情跳转来的
    initSelectedBrand(brand_list_cem) {
        localStorage.setItem('brand_id_cem', this.state.init_brand_id)
        localStorage.setItem('brand_text_cem', this.state.init_brand_text)
        localStorage.setItem('brand_list_cem', JSON.stringify(brand_list_cem))
        this.setState({
            brand_id_cem: this.state.init_brand_id,
            brand_list: brand_list_cem,
            brand_title: this.state.init_brand_text
        }, () => {
            this.GetBrandProduct(true)
        })
    }
    // 获取派送进度
    getProjectProgress(id) {
        return new Promise((resolve, reject) => { // Wrap everything inside a new Promise
            if (!id) {
                this.setState({ project_survey_list: [] });
                resolve(); // Resolve the promise since no further action is needed
                return;
            }
            let param = {
                product_id: id
            }
            getProjectProgress(param).then(res => {
                res.data.map(item => {
                    item.progess_status = Number(item.progess_status) === 6 ? 7 : Number(item.progess_status);
                    item.key = item.survey_id;
                    item.value = item.project_name;
                })
                let default_survey_id = res.data[0].survey_id;
                localStorage.setItem('project_survey_id_cem', default_survey_id)
                localStorage.setItem('project_survey_list_cem', JSON.stringify(res.data))


                this.setState({ project_survey_list: res.data, project_survey_id_cem: default_survey_id });
                console.log(this.state);

                resolve(); // Resolve the promise when this asynchronous operation is done
            }, err => {
                console.log(err);
                this.setState({ project_survey_list: [] });
                reject(err); // Reject the promise with the error if there's a failure
            });
        });
    }
    //获取品牌
    GetBrand() {
        UserAPI.GetBrand().then((res) => {
            if (res.status === 200) {  //获取到品牌
                let brand_list_cem = []
                let hasInitSelected = false
                let init_brand_id = ""
                let init_product_id = ""
                if (window.location.search.indexOf("brand_id") !== -1 && window.location.search.indexOf("product_id") !== -1) {
                    init_brand_id = getQueryParameter("brand_id");
                    init_product_id = getQueryParameter("product_id");
                    this.setState({
                        init_brand_id: parseInt(init_brand_id),
                        init_product_id: parseInt(init_product_id)
                    })
                    hasInitSelected = true
                }
                for (let i = 0; i < res.data.length; i++) {
                    let obj = { key: res.data[i].id, selected: res.data[i].selected, value: res.data[i].name }
                    if (hasInitSelected && obj.key.toString() === init_brand_id.toString()) { //详情跳转
                        this.setState({
                            init_brand_text: obj.value
                        })
                    }
                    brand_list_cem.push(obj)
                }
                if (hasInitSelected) {
                    this.initSelectedBrand(brand_list_cem)
                } else if (localStorage.getItem("brand_id_cem")) {
                    let brand_list_cem_prev = JSON.parse(localStorage.getItem("brand_list_cem"))
                    let isObjectValueEqual = this.isObjectValueEqual(brand_list_cem, brand_list_cem_prev)
                    if (isObjectValueEqual) {
                        if (localStorage.getItem("product_id_cem") || localStorage.getItem("product_id_cem") === "") { //之前有 如204的时候 product_id_cem为空 为了切换的时候面板的时候不用重新加载
                            this.initPanel()
                        } else { // 一般不会到这里，因为获取品牌的时候，会给第二个下拉框默认的产品
                            this.initBrand(brand_list_cem)
                        }
                    } else { //如超户的品牌进行了更新
                        this.initBrand(brand_list_cem)
                    }
                } else { // 之前没有品牌id
                    this.initBrand(brand_list_cem)
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }
    // 判断两个对象是否相等
    isObjectValueEqual(obj1, obj2) {
        if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
            return true
        }
        return false
    }
    //获取品牌所有产品
    GetBrandProduct(hasInitSelected) {
        let brand_id_cem = localStorage.getItem("brand_id_cem")
        return new Promise((resolve, reject) => {
            UserAPI.GetBrandProduct({ brand_id: parseInt(brand_id_cem) }).then(async (res) => {
                if (res.status === 200) {
                    let product_list_cem = []

                    for (let i = 0; i < res.data.length; i++) {
                        let obj = { key: res.data[i].id, selected: res.data[i].selected, value: res.data[i].title, is_sample: res.data[i]?.is_sample ? true : false }
                        if (hasInitSelected && obj.key.toString() === this.state.init_product_id.toString()) { //详情跳转
                            this.setState({
                                init_product_text: obj.value
                            })
                        }
                        product_list_cem.push(obj)
                    }
                    console.log(product_list_cem);
                    let product_id_cem = hasInitSelected ? this.state.init_product_id : product_list_cem[0].key
                    let product_text_cem = hasInitSelected ? this.state.init_product_text : product_list_cem[0].value
                    await this.getProjectProgress(product_id_cem);
                    localStorage.setItem('product_id_cem', product_id_cem)
                    localStorage.setItem('product_text_cem', product_text_cem)
                    localStorage.setItem('product_list_cem', JSON.stringify(product_list_cem))
                    this.setState({
                        product_id_cem: product_id_cem,
                        product_list: product_list_cem,
                        product_title: product_text_cem
                    }, () => {
                        this.props.handleProductChange(this.state.product_id_cem, this.state.project_survey_id_cem)
                    })
                    resolve(true)
                } else if (res.status === 204) {
                    message.warning('该品牌没有产品', 2, () => { })
                    localStorage.setItem('product_id_cem', "")
                    localStorage.setItem('product_text_cem', "")
                    localStorage.setItem('product_list_cem', [])
                    this.setState({
                        product_id_cem: "",
                        product_list: [],
                        product_title: "",
                        project_survey_id_cem: 0,
                    }, () => {
                        this.props.handleProductChange(this.state.product_id_cem, this.state.project_survey_id_cem)
                    })
                    resolve(true)
                }
            })
        })

    }

    // 点击选择产品
    async onSelectChange(key, option) {
        let cur_index = parseInt(option.key)
        if (this.props.is_freecross) {
            if (this.props.user.account_type === "1" && !this.state.product_list[cur_index].is_sample) { // is_sample 0 自己的问卷 1 给的可以看的问卷
                message.warning(`请联系商务进行升级`, 3, () => { })
                return
            }
        }
        let tmpList = JSON.parse(JSON.stringify(this.state.product_list))
        tmpList.forEach((item, index) => {
            if (cur_index === index) {
                tmpList[index].selected = true
                tmpList[index].selected = false
            }
        })
        // 获取到产品以后根据产品id获取项目 
        let product_id_cem = this.state.product_list[cur_index].key
        await this.getProjectProgress(product_id_cem);
        localStorage.setItem('product_id_cem', this.state.product_list[cur_index].key)
        localStorage.setItem('product_text_cem', this.state.product_list[cur_index].value)
        this.setState({
            product_title: this.state.product_list[cur_index].value,
            product_list: tmpList,
            product_id_cem: product_id_cem,
        }, () => {
            this.props.handleProductChange(this.state.product_id_cem, this.state.project_survey_id_cem)

        })
    };
    // 点击选择项目
    onSelectProjectChange(key, option) {
        let cur_index = parseInt(option.key)

        let tmpList = JSON.parse(JSON.stringify(this.state.project_survey_list))
        tmpList.forEach((item, index) => {
            if (cur_index === index) {
                tmpList[index].selected = true
                tmpList[index].selected = false
            }
        })
        localStorage.setItem('project_survey_id_cem', this.state.project_survey_list[cur_index].key)
        this.setState({
            project_survey_list: tmpList,
            project_survey_id_cem: this.state.project_survey_list[cur_index].key,
        }, () => {
            this.props.handleProductChange(this.state.product_id_cem, this.state.project_survey_id_cem)

        })
    };
    // 点击选择品牌
    onSelectBrandChange(key, option) {
        let cur_index = parseInt(option.key) // 位置
        let tmpList = JSON.parse(JSON.stringify(this.state.brand_list))
        tmpList.forEach((item, index) => {
            if (cur_index === index) {
                tmpList[index].selected = true
                tmpList[index].selected = false
            }
        })
        localStorage.setItem('brand_id_cem', this.state.brand_list[cur_index].key)
        localStorage.setItem('brand_text_cem', this.state.brand_list[cur_index].value)
        // 产品清空
        localStorage.setItem('product_id_cem', "")
        localStorage.setItem('product_text_cem', "")
        localStorage.setItem('product_list_cem', [])

        this.setState({
            brand_title: this.state.brand_list[cur_index].value,
            brand_list: tmpList,
            brand_id_cem: this.state.brand_list[cur_index].key,
            product_id_cem: "",
            product_list: [],
            project_survey_id_cem: 0,
            project_survey_list: [{ "key": 0, "value": "全部项目", "project_id": 0, "project_name": "全部项目", "survey_id": 0 }],
            product_title: "",
        }, () => {
            this.GetBrandProduct(false)
            // this.props.handleProductChange(this.state.product_id_cem)
        })
    }
    // 下拉框搜索可以大小写
    filterSelectOption(input, option) {
        return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    render() {
        return (
            <div className="product-dropdown-container">
                <div>
                    <XmSelect
                        // getPopupContainer={triggerNode => {
                        //     return triggerNode.parentNode || document.body
                        // }}
                        dropdownClassName="product-dropdown"
                        showSearch
                        filterOption={(input, option) => {
                            return this.filterSelectOption.call(this, input, option)
                        }

                        }
                        defaultValue={this.state.brand_id_cem} key={this.state.brand_id_cem}
                        options={this.state.brand_list}
                        onChange={(e, index) => {
                            this.onSelectBrandChange(e, index)
                        }}></XmSelect>
                </div>
                <div className={this.state.product_id_cem === "" ? "isHide" : ""}>
                    <XmSelect
                        // getPopupContainer={triggerNode => {
                        //     return triggerNode.parentNode || document.body
                        // }}
                        dropdownClassName="product-dropdown"
                        showSearch
                        filterOption={(input, option) => {
                            return this.filterSelectOption.call(this, input, option)
                        }

                        }
                        defaultValue={this.state.product_id_cem} key={this.state.product_id_cem}
                        options={this.state.product_list}
                        onChange={(e, index) => {
                            this.onSelectChange(e, index)
                        }}></XmSelect>
                </div>
                <div className={this.state.project_survey_id_cem === "" ? "isHide" : ""}>
                    <XmSelect

                        dropdownClassName="product-dropdown"
                        showSearch
                        filterOption={(input, option) => {
                            return this.filterSelectOption.call(this, input, option)
                        }

                        }
                        defaultValue={this.state.project_survey_id_cem} key={this.state.project_survey_id_cem}
                        options={this.state.project_survey_list}
                        onChange={(e, index) => {
                            this.onSelectProjectChange(e, index)
                        }}></XmSelect>
                </div>
            </div >
        )
    }
}
export default connect(
    state => ({
        user: state.user,
    }),//映射状态
    {}//映射操作状态的方法
)(product_dropdown)
