import React, { Component } from 'react'
import './Desire_single_chart.scss';
import * as echarts from 'echarts';
import { addListener } from "resize-detector";
import { stackbar_tooltip, stackbar_legend, get_stackbar_xAxis, stackbar_yAxis, getStackbarSeries, getStackbarOption } from "../../../util/Chart_config/Stackbar_chart_config";
import { formatChart } from "../../../util/Chart_config/Config";
// import { theme_color_list } from "../../../util/Chart_config/Pie_chart_config";
export default class Desire_single_chart extends Component {
    constructor(props) {
        super(props);
        //   控制提示框的样式
        this.state = {
            name: 'Desire_single_chart',
            dataList: {},
            _isMounted: false,
            needRefresh: false,
            highest_name: '',//最高种草职业
            highest_val: "", //种草率最高值
            chart_name: "",//图表名字
        };
    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            this.setState({
                dataList: this.props.dataList,
                dataList_prev: this.props.dataList,
                chart_name: this.props.dataList.chart_name,
                highest_name: this.props.dataList.highest_name,
                highest_val: this.props.dataList.highest_val,
            })

            this.toInitChart()
        }
    }
    componentWillUnMount = () => {
        this._isMounted = false;
        this.setState = (state, callback) => {
            return;
        };
    }
    toInitChart() {
        let id = this.props.single_id
        let xAxisList = this.props.dataList.xAxisList;//x轴
        let seriesBarList = this.props.dataList.seriesBarList; //bar
        let seriesLineObj = this.props.dataList.seriesLineList; // line
        let series = []
        seriesBarList.forEach((item, index) => {
            let obj = {
                name: item.name,
                type: 'bar',
                stack: 'overlap',
                data: item.data,
                barMaxWidth: 50,
            }
            series.push(obj)
        })
        let arr = []
        seriesLineObj.data.forEach((item, index) => {
            let tmp = parseInt(item.split('.')[0])
            arr.push(tmp)
        })
        let obj2 = { name: seriesLineObj.name, type: 'line', data: arr, yAxisIndex: 1, color: '#FBC155', smooth: true }
        series.push(obj2)

        let option = getStackbarOption(xAxisList, series)
        formatChart(id, option)
    }
    render() {
        let single_id = String(this.props.single_id) || ""
        return (
            <div className='pannel-card panel-card-desire-single'>
                <div className='card-title-wrapper'>
                    <div className='card-title'>{this.state.chart_name}</div>
                    <div className="card-select-wrapper">
                        <div>
                            <div className="card-select-title">最高种草{this.state.chart_name.split("-")[0] || ""}</div>
                            <div className="card-select-content">{this.state.highest_name}</div>
                        </div>
                        <div>
                            <div className="card-select-title">种草率最高值</div>
                            <div className="card-select-content">{this.state.highest_val}</div>
                        </div>
                    </div>
                </div>
                <div id={single_id} className="echart-container desire-job-echart"></div>
            </div>
        )
    }
}
