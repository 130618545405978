import React, { Component } from 'react'
import './Score_formula.scss'
import cur_icon_back from '../../../../images/cur_icon_back.png'
import cur_icon_delete from '../../../../images/cur_icon_delete.png'
import XmSelect from '../../../../components/Select/xm_select';
import EditFormulaDialog from './Edit_formula_dialog'
import { Select } from "antd";
const { Option } = Select;
let that = ''
export default class Score_formula extends Component {
    constructor(props) {
        super(props);
        this.state = {
            score_formula_width: '',     //底部的宽度
            score_formula_main_height: '',   //主内容的高度
            question_list: [],// 题目列表
            canShowScoreFormula: false, // 展示分数公式
            name: "",// 名称
            formula_val: "",//公式
            variable_list: [],//变量对照
            selected_question_key_list: [],// 题目列表
            selected_question_list: [],// 题目列表
            canShowEditFormula: false,
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.canShowScoreFormula) !== JSON.stringify(state.canShowScoreFormula_prev) && props.canShowScoreFormula) {
            return {
                needRefresh: true,
                question_list: props.question_list || [],
                canShowScoreFormula: props.canShowScoreFormula,
                canShowScoreFormula_prev: props.canShowScoreFormula,
            }
        }
        return null;
    }
    componentDidMount() {
        that = this

        this._isMounted = true
        this.getBaseInfoWidth()
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.initData()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        //销毁监听
        clearInterval(window.get_score_formula_width)
        this._isMounted = false;
        this.setState = () => { }
    }
    getBaseInfoWidth() {
        window.get_score_formula_width = setInterval(() => {
            that.setState({ score_formula_width: document.getElementById('contain').offsetWidth + 'px' })
            that.setState({
                score_formula_main_height: document.getElementById('contain').offsetHeight - document.getElementById('score_formula_bottom').offsetHeight - document.getElementById('project_entry_top').offsetHeight - 15 + 'px'
            })
        })
    }
    initData() {
        this.getScoreFormula();
    }
    getScoreFormula() {
        // let obj = {
        //     name: "求和分数公式",
        //     variable_list: [
        //         {
        //             label: "你好吗？",
        //             value: "你好吗？",
        //             question_name: "你好吗？",
        //             question_sort: 1,
        //             question_id: "",
        //             question_variable: "X1"
        //         },
        //         {
        //             label: "你好吗？",
        //             value: "你好吗？",
        //             question_name: "你不好吗？",
        //             question_sort: 1,
        //             question_id: "",
        //             question_variable: "X2"
        //         }
        //     ],
        //     formula_val: "X1+X2",
        //     formula_id: ""
        // };
        // this.setState({
        //     name: obj.name,
        //     variable_list: obj.variable_list,
        //     formula_val: obj.formula_val
        // })
    }
    //上一步
    prev() {
        this.setState({
            canShowScoreFormula: false
        })
        this.props.handleScoreFormulaDisplay({
            canShowScoreFormula: false
        })
    }
    accomplish() {

    }
    // 单改变值，非下拉框的，比如填空题
    handleInputChange(t, event) {
        if (!t) return false
        if (!event) return false
        this.setState({ [t]: event.target.value })
    }
    // 下拉框搜索可以大小写
    filterSelectOption(input, option) {
        return option.children.props.children.toString().indexOf(input.toString()) >= 0
    }
    //下拉框选择后，并赋值到state里面
    onSelectChange(key_arr) {
        this.setState({
            selected_question_key_list: key_arr
        }, () => {
            this.handleSelectedQuestion()
        })
    }
    // 渲染变量对照
    handleSelectedQuestion() {
        let question_list = JSON.parse(JSON.stringify(this.state.question_list))
        let selected_question_key_list = JSON.parse(JSON.stringify(this.state.selected_question_key_list))
        let selected_question_list = []
        for (let i = 0; i < question_list.length; i++) {
            if (selected_question_key_list.indexOf("question_id_" + i) !== -1) {
                let obj = {
                    question_id: "question_id_" + i,
                    question_name: question_list[i].name,
                    question_variable: "X" + parseInt(i + 1),
                    question_sort: i
                }
                selected_question_list.push(obj);
            }
        }
        this.setState({
            selected_question_list: selected_question_list
        })
    }
    // 渲染下拉框select的题目
    handleQuestionListOption() {
        let question_list = JSON.parse(JSON.stringify(this.state.question_list))
        let question_option_list = []
        for (let i = 0; i < question_list.length; i++) {
            let obj = {
                key: "question_id_" + i,
                value: "" + question_list[i].name
            }
            question_option_list.push(obj);
        }
        return question_option_list
    }
    // 渲染默认的题目
    handleDefaultQuestionListOption() {
        // return ["question_id_1"]
        // console.log(123)
        // if (this.state.selected_question_key_list.length) {
        //     return this.state.selected_question_key_list
        //     // return ["question_id_1"]
        // } else {
        //     return []
        // }

        return this.state.selected_question_key_list
    }
    // 删除选择的题目
    handleDeleteQuestion(index) {
        let selected_question_list = JSON.parse(JSON.stringify(this.state.selected_question_list))
        let selected_question_key_list = JSON.parse(JSON.stringify(this.state.selected_question_key_list))
        selected_question_list.splice(index, 1)
        selected_question_key_list.splice(index, 1)
        this.setState({
            selected_question_key_list: selected_question_key_list,
            selected_question_list: selected_question_list
        })
    }
    //编辑公式
    handleShowEditFormula(handleShowEditFormula, formulaText) {
        this.setState({
            canShowEditFormula: !this.state.canShowEditFormula,
        })
        if (handleShowEditFormula === "submit") {
            this.setState({
                formulaText: formulaText
            })
        }
    }
    render() {
        return (
            <div className={this.state.canShowScoreFormula ? "score-formula-contain" : "isHide"} style={{ height: this.state.score_formula_main_height }}>
                {/* 顶部 */}
                <div className='score-formula-top'>
                    <img src={cur_icon_back} alt="" onClick={this.prev.bind(this)} />
                    <span>计算逻辑设置</span>
                </div>
                {/* 中部 */}
                <div className='score-formula-contain-main'>
                    {/* 名称 */}
                    <div className='score-formula-item'>
                        <div className='score-formula-item-left'>名称</div>
                        <div className='score-formula-item-right'>
                            <input placeholder='请输入产品名称' className='score-formula-input' type='text' defaultValue={this.state.name} onChange={this.handleInputChange.bind(this, 'name')} />
                        </div>
                    </div>
                    {/* 名称 */}
                    <div className='score-formula-item'>
                        <div className='score-formula-item-left'>变量对照</div>
                        <div className='score-formula-item-right'>
                            <XmSelect
                                mode="multiple" placeholder="请选择题目" onChange={(e) => { this.onSelectChange.call(this, e) }} maxTagCount={'responsive'} allowClear
                                options={this.handleQuestionListOption.call(this)}
                                value={this.handleDefaultQuestionListOption.call(this)}
                                filterOption={(input, option) => {
                                    return this.filterSelectOption.call(this, input, option)
                                }
                                }
                            >
                            </XmSelect>
                            <div className={this.state.selected_question_list.length ? 'variable-card' : "isHide"}>
                                {
                                    this.state.selected_question_list.map((item, index) => {
                                        return (
                                            <div key={index} className="variable-item">
                                                <div className="variable-item-icon">
                                                    <img src={cur_icon_delete} className='cur_icon_delete' alt="" onClick={this.handleDeleteQuestion.bind(this, index)} />
                                                </div>
                                                <div className="variable-item-name">{`Q${item.question_sort + 1}:${item.question_name}`}</div>
                                                <div className="variable-item-sign">{item.question_variable}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    {/* 公式 */}
                    <div className='score-formula-item'>
                        <div className='score-formula-item-left'>公式</div>
                        {/* <div className='score-formula-item-right'>
                            <input placeholder='请输入产品名称' className='score-formula-input' type='text' defaultValue={this.state.formula_val} onChange={this.handleInputChange.bind(this, 'formula_val')} />
                        </div> */}
                        <div className='score-formula-item-right'>
                            <input placeholder='请输入产品名称' className='score-formula-input' type='text' defaultValue={this.state.formulaText} disabled="disabled" />
                        </div>
                        <div onClick={this.handleShowEditFormula.bind(this)} className='score-formula-edit'>编辑公式</div>
                    </div>
                </div>
                {/* 底部 */}
                <div className='score-formula-bottom' id="score_formula_bottom" style={{ width: this.state.score_formula_width }}>
                    <div className='score-formula-bottom-content'>
                        <div></div>
                        <div>
                            <div onClick={this.prev.bind(this)}>上一步</div>
                            <div onClick={this.accomplish.bind(this)}>保存</div>
                            <div onClick={this.accomplish.bind(this, 'next')}>完成</div>
                        </div>
                    </div>
                </div>
                {/* 公式 */}
                {
                    this.state.canShowEditFormula ? <EditFormulaDialog formulaText={this.state.formulaText} selected_question_list={this.state.selected_question_list} handleShowEditFormula={this.handleShowEditFormula.bind(this)} canShowEditFormula={this.state.canShowEditFormula}></EditFormulaDialog> : ""
                }
            </div>
        )
    }
}
