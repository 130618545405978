import * as echarts from 'echarts';
export const date_tooltip = {
    trigger: 'axis',
}

export const date_legend = {
    top: 10,
    x: 'center',
    icon: 'circle', //设置图例图表形状
    itemWidth: 10,
    itemHeight: 10,
    itemGap: 60, //图例每项之间的间隔
    textStyle: {
        fontSize: 14,
        lineHeight: 20,
        color: "#595961",
        fontFamily: "PingFang SC"
    },
}

export const date_xAxis = {
    type: 'category',
    // min: new Date(minDate),
    // max: new Date(maxDate)
    // silent: false,
    // splitLine: {
    //     show: false
    // },
    // splitArea: {
    //     show: false
    // }
    axisLabel: {
        show: true,
        textStyle: {
            color: '#9797A1', //x轴文本字体颜色改变
            fontSize: 12
        }
    },
    axisTick: { // 去掉刻度条
        show: false
    },
    axisLine: { //x轴的颜色改变
        lineStyle: {
            color: '#E3E2E5'
        }
    }
}

export const date_yAxis = {
    type: 'value',
    // boundaryGap: [0, '100%']
    splitLine: {  // y轴的分割线
        lineStyle: {
            color: '#E3E2E5',
            type: 'dashed'
        }
    },
    axisLabel: {
        show: true,
        textStyle: {
            color: '#9797A1', //x轴文本字体颜色改变
            fontSize: 12
        }
    }
}
export const dataZoom = [
    {
        type: 'inside',
    },
    {
        type: 'slider',
        height: '7%',
        width: '72%',
        right: '15%',
        // bottom: 20,
        // top: '95%',
        fillerColor: "rgba(255,204,220,0.3)", //  //选中范围的填充颜色。
        borderColor: "#ffccdc",//datazoom边框
        // backgroundColor: "transparent",
        textStyle: {
            color: '#9797A1',//滚动条两边字体样式
            fontSize: 11
        },
        dataBackground: { // 未选中的数据
            lineStyle: {
                color: '#ff99b8'
            },
            areaStyle: {
                color: '#ffccdc'
            }

        },
        selectedDataBackground: { // 选中的数据
            lineStyle: {
                color: '#ff80a6'
            },
            areaStyle: {
                color: '#ff99b9'
            }
        },

        handleStyle: {  // 选中的数据的手柄边框
            borderColor: "#ff99b9",
        },
        moveHandleStyle: {  // 选中的数据的边框 不悬浮
            color: "none",
            borderColor: 'none',
        },
        emphasis: { // 选中的数据的边框与手柄边框 悬浮
            handleStyle: {
                borderColor: "#ff80a7",
            },
            moveHandleStyle: {
                color: "#ff99b9",
                borderColor: '#ff99b9',
                opacity: 0.7,
            },
        },
        brushStyle: { // 选其他的数据区域
            color: "rgba(255,204,220,0.3)",
            borderColor: 'rgba(255,204,220,0.3)',
        },

    }
]

export function compareDate(date1, date2) {
    var oDate1 = new Date(date1);
    var oDate2 = new Date(date2);
    if (oDate1.getTime() > oDate2.getTime()) {
        return 1
    } else {
        return 2
    }
}

export function getBarSeries(res) {
    let series = []
    let minDate = "2050-01-01"
    let maxDate = "1970-01-01"
    let areaStyle_0 = { //红色
        normal: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{

                offset: 0,
                color: 'rgba(255, 81, 134, 0.39)'
            }, {
                offset: .34,
                color: 'rgba(255, 81, 134, 0.25)'
            }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0.00)'
            }])

        },
    }
    let areaStyle_1 = { //黄色
        normal: {
            //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{

                offset: 0,
                color: 'rgba(251, 193, 85, 0.39)'
            }, {
                offset: .34,
                color: 'rgba(251, 193, 85, 0.25)'
            }, {
                offset: 1,
                color: 'rgba(251, 193, 85, 0.00)'
            }])

        }
    }
    let areaStyleList = [areaStyle_0, areaStyle_1]
    res.forEach((item, index) => {
        let len = item.status_day.length
        let obj = { name: "", type: "line", data: [], smooth: true, areaStyle: {}, showSymbol: len >= 2 ? false : true, symbolSize: len >= 2 ? 4 : 10, }
        if (len === 1) {
            obj['symbol'] = 'circle'
        }
        obj.name = item.status
        for (let i = 0; i < len; i++) {
            let tmpArr = [item.status_day[i], parseInt(item.status_day_num[i])]
            obj.data.push(tmpArr)
        }
        let mindate2 = item.status_day[0];
        let maxdate2 = item.status_day[len - 1]
        if (compareDate(minDate, mindate2) === 1) {
            //mindate大
            minDate = mindate2
        }
        if (compareDate(maxDate, maxdate2) === 2) {
            //maxDate小
            maxDate = maxdate2
        }
        obj.areaStyle = areaStyleList[index]
        series.push(obj)
    })
    return series
}

export const line_color = [
    "#FF5186", "#FBC155",
]

export function getBarOption(res) {
    let option = {
        grid: {
            left: '60px',
            right: '40px'
        },
        tooltip: date_tooltip,
        legend: date_legend,
        color: line_color,
        xAxis: date_xAxis,
        yAxis: date_yAxis,
        dataZoom: dataZoom,
        series: getBarSeries(res)
    }
    return option
}