import React, { Component } from 'react'
import './xm_upload.scss';
import upload_pic from '../../images/project_entry/project_info/upload_pic.png'
import { handleUploadImg } from "../../util/utils"
import { Upload, Modal, Image, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import upload_close_img from '../../images/upload_close.png'
import XmModel from '../../components/Xm_model/Xm_model'
let that = ''
export default class xm_upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: props.image_name || "",
      filename: "",
      needRefresh: false,
      modelContent: "确定删除该图片吗？",
      showModel: false,
      modelTitle: "删除提示"
    }
  }
  // static getDerivedStateFromProps(props, state) {
  //   if (JSON.stringify(props.image) !== JSON.stringify(state.image_prev)) {
  //     // console.log("123")
  //     return {
  //       needRefresh: true,
  //       image: props.image.val || "", //question
  //       image_prev: props.image.val,
  //     }
  //   }
  //   return null;
  // }
  // componentDidMount() {
  //   this._isMounted = true
  //   // console.log("123")
  //   // if (this._isMounted) {
  //   //     this.toInitChart()
  //   //     setTimeout(() => {
  //   //         this.toInitFoot()
  //   //     })
  //   // }
  // }
  componentDidUpdate(preProps, preState) {
    if (preProps.image_name !== this.props.image_name) {
      this.setState({
        image: this.props.image_name
      })
    }
  }
  // componentWillUnmount() {
  //   this._isMounted = false;
  //   this.setState = () => { }
  // }
  // 上传图片
  handleUpload(args) {
    let _this = this//烦人的this
    handleUploadImg(args).then(res => {
      if (res !== -1) {
        _this.props.handleUploadPic(res.imgs, res.filename)
        _this.setState({
          image: res.imgs,
        })
      }
    })
  }
  handleDeleteUpload() {
    this.setState({
      image: "",
      filename: "",
      showModel: true,
    }, () => {
      this.props.handleDeletePic()
    })

  }
  // 第一个参数是设置是否显示，第二个参数就是子组件选确认
  handleModel(val, flag) {
    if (flag) {
      this.handleDeleteUpload()
    }
    this.setState({
      showModel: val,
    })
  }
  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <div className={this.state.image ? "isHide" : "show-upload-wrap"}>
          <Upload
            showUploadList={false}
            customRequest={this.handleUpload.bind(this)}
          >
            <img src={upload_pic} alt="" className="upload-pic" />
          </Upload>
        </div>
        <div className={this.state.image ? "show-pic-wrap" : "isHide"}>
          <ConfigProvider locale={zhCN}>
            <Image src={this.state.image} alt="" className="show-pic" />
          </ConfigProvider>
          <img src={upload_close_img} alt="" className="upload-close-img" onClick={this.handleModel.bind(this, true, false)} />
        </div>
        <div className='hello'>
          <XmModel isShowModel={this.state.showModel} handleShowModel={this.handleModel.bind(this)} modelContent={this.state.modelContent} modelTitle={this.state.modelTitle}></XmModel>
        </div>
      </div>
    )
  }
}
