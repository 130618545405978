import axiosInstance from './APIUtils'

export default {
    GetCategory(data) {        //获取品类信息
        return axiosInstance.post('cem/getCategory/', data)
    },
    GetProduct(data) {         //获取产品信息
        return axiosInstance.post('cem/getProduct/', data)
    },
    SaveProduct(data) {         //保存产品信息
        // console.log(123)//mp.weixin.qq.com
        return axiosInstance.post('cem/saveProduct/', data)
    },
    UpdateProduct(data) {
        return axiosInstance.post('cem/updateProduct/', data)
    },
    GetBrand(data) {     //获取所有品牌
        return axiosInstance.post('cem/getBrand/', data)
    },
    GetBrandProduct(data) {     //获取品牌所有产品
        return axiosInstance.post('cem/getBrandProduct/', data)
    },
    GetProductWeights(data) {   //获取重量分级
        return axiosInstance.post('cem/getProductWeights/', data)
    },
    GetProductDevelopments(data) {   //获取产品研发阶段
        return axiosInstance.post('cem/getProductDevelopments/', data)
    },
    SaveProjectBasic(data) {    //保存项目基础信息
        return axiosInstance.post('cem/saveProjectBasic/', data)
    },
    GetProjectBasic(data) {    //获取项目基础信息
        return axiosInstance.post('cem/getProjectBasic/', data)
    },
    UpdateProjectBasic(data) {    //更新项目基础信息
        return axiosInstance.post('cem/updateProjectBasic/', data)
    },
    GetTagCategory(data) {    //获取标签类别
        return axiosInstance.post('cem/getTagCategory/', data)
    },
    GetTags(data) {    //获取标签
        return axiosInstance.post('cem/getTags/', data)
    },
    SaveProjectTA(data) {  //保存项目筛选人群
        return axiosInstance.post('cem/saveProjectTA/', data)
    },
    UpdateProjectTA(data) {  //更新项目筛选人群
        return axiosInstance.post('cem/updateProjectTA/', data)
    },
    GetProjectTA(data) {     //获取项目筛选人群
        return axiosInstance.post('cem/getProjectTA/', data)
    },
    SaveProjectSurvey(data) {    //保存项目问卷信息
        return axiosInstance.post('cem/saveProjectSurvey/', data)
    }
}

