import React, { Component } from 'react'
import { Route, Redirect } from "react-router-dom";
import { getToken, getUserStatus } from "../util/auth"

export default class FrontendAuth extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    async componentDidMount() {

    }
    render() {
        const { routerConfig, location } = this.props;
        const { pathname } = location; //path

        // 定位到当前的path对象
        const targetRouterConfig = routerConfig.find(
            (item) => item.path === pathname
        );

        // 登录的状态有token且
        if (getToken()) {
            // 如果是登陆状态，想要跳转到登陆，重定向到Emotion_analysis
            if (pathname === "/login" || pathname === "/") {
                // return <Redirect to="/emotion_analysis" />;
                return <Redirect to="/home_page" />;
            }
        }

        // 白名单
        if (targetRouterConfig && !targetRouterConfig.auth) {
            const { component } = targetRouterConfig;
            return <Route exact path={pathname} component={component} />;
        }

        // 获取token
        if (getToken()) {
            // 如果路由合法，就跳转到相应的路由
            if (targetRouterConfig) {
                return (
                    <Route path={pathname} component={targetRouterConfig.component} />
                );
            } else {
                // 如果路由不合法，重定向到 404 页面
                return <Redirect to="/error_page" />;
            }
        } else {
            // 非登陆状态下，当路由合法时且需要权限校验时，跳转到登陆页面，要求登陆
            if (targetRouterConfig && targetRouterConfig.auth) {
                return <Redirect to="/login" />;
            } else {
                // 非登陆状态下，路由不合法时，重定向至 404
                return <Redirect to="/error_page" />;
            }
        }
    }
}
