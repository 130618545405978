import axiosInstance from '../APIUtils'

export function getSpecialEventProgress(obj) {
    return axiosInstance({
        url: "/cem/getSpecialEventProgress/",
        method: "POST",
        data: obj
    });
}
export function getProjectProgress(obj) {
    return axiosInstance({
        url: "/cem/getProjectProgress/",
        method: "POST",
        data: obj
    });
}