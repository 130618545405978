import React, { Component } from 'react'
import './freecross_max_chart_popup.scss'
import debounce from 'lodash/debounce';
import cur_icon_close_gre from '../../../images/cur_icon_close_gre.png'

export default class freecross_max_chart_popup extends Component {
    constructor(props) {
        super(props);
        this.resize = debounce(this.resize, 1000);
        this.maxChartRef = React.createRef();
        this.state = {
            dataList: { name: "", question_type: "" },
            cur_detail_chart: {}, //当前tab页面的图
            current_tab: '0', //当前tab页面
            single_id: "",
            chart_flag: 0,// 0 为饼图 1 为 列表
            isConsumer: false,
            isMaxSreen: false,
            blankListHeight: 0,
            option_distribution: [],
            option_distribution_prev: [],
            isShowRightDetail: true, // 是否展示右边列表
            search_text: "",
            bar_option_name: "",// 当前所选择的柱子
            tab_list: [] // 三个切换
        }

    }
    setMaxChart(obj) {
        this.debounceFunc = this.resize.bind(this);
        window.addEventListener("resize", this.debounceFunc, false);
        let { dataList, single_id, isConsumer } = obj
        this.setState({
            dataList: dataList,
            single_id: single_id,
            isConsumer: isConsumer,
            search_text: ""
        }, () => {
            this.resize("first_enter")
        })
    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    resize(flag) {
        let isMaxSreen = this.state.isMaxSreen
        let sreenWidth = document.body.clientWidth
        let type = this.state.dataList.question_type
        let dom = document.getElementById("max_chart_popup_" + this.state.single_id)
        if (!dom) { return; }
        let domWidth = dom.offsetWidth;
        let domWHeight = dom.offsetHeight;
        let blankDomHeight = 258
        if (type === 11) { // 特殊的自定义 因为下面有foot
            this.props.toInitChart()
            return
        }
        if (domWidth > 800) {
            if (isMaxSreen === false || flag === "first_enter") { // 之前是false
                if (type === 3 || type === 15 || type === 16) {//简答题
                    let blankDom = document.getElementById("max_chart_" + this.state.single_id + "_wrapper")
                    blankDomHeight = blankDom.offsetHeight - 140;
                    this.setState({ blankListHeight: blankDomHeight })
                } else {
                    this.props.toInitChart(true)
                }
                this.setState({ isMaxSreen: true })
            }
        } else {
            if (isMaxSreen === true || flag === "first_enter") { // 之前是true
                if (type === 3 || type === 15 || type === 16) {//简答题
                    let blankDom = document.getElementById("max_chart_" + this.state.single_id + "_wrapper")
                    blankDomHeight = blankDom.offsetHeight - 140;
                    this.setState({ blankListHeight: blankDomHeight })
                } else {
                    this.props.toInitChart(false)
                }
                this.setState({ isMaxSreen: false })
            }
        }
    }
    tags_close() {
        this.props.canShowMaxChartPopup({ showMaxChartPopup: false })
    }
    render() {
        return (
            <div className='max-chart-popup' id={`max_chart_popup_${this.state.single_id}`} >
                <div className='max-chart-popup-container'>
                    <div className='max-chart-popup-top'>
                        <div className='popup-top-title'>图表展示</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                        </div>
                    </div>
                    {/* 图表名字 */}
                    <div className='popup-content-top'>
                        <div className='top-title-wrapper'>
                            {this.state.dataList.name}
                        </div>
                    </div>
                    {/* 显示真正的图表 */}
                    <div className='max-chart-wrapper' id={`max_chart_${this.state.single_id}_wrapper`} >
                        <div className={this.state.chart_flag === 1 || this.state.dataList.question_type === 3 || this.state.dataList.question_type === 16 ? "isHide" : "max-chart-wrapper-2"}>
                            <div id={`max_chart_${this.state.single_id}`} className={`echart-container max-preinstall-echart comment_${this.state.single_id} max-chart-left`}></div>
                        </div>
                        {
                            this.state.dataList.question_type === 11 ?
                                <div className="max-custom-chart-foot">
                                    <div className="max-foot-name max-foot-name-0">
                                        <div className="max-line"></div>
                                        <div className="max-sign"></div>
                                        <div className="max-content">贬损者</div>
                                    </div>
                                    <div className="max-foot-name max-foot-name-1">
                                        <div className="max-line"></div>
                                        <div className="max-sign"></div>
                                        <div className="max-content">被动者</div>
                                    </div>
                                    <div className="max-foot-name max-foot-name-2">
                                        <div className="max-line"></div>
                                        <div className="max-sign"></div>
                                        <div className="max-content">推荐者</div>
                                    </div>
                                </div> : ""
                        }
                    </div>
                </div>
            </div >
        )
    }
}
