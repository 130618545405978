import axiosInstance from '../APIUtils'

// 获取二维码图片
export function getQRCode(obj) {
    return axiosInstance({
        url: "/cem/getQRCode/",
        method: "POST",
        data: obj
    });
}

// 获取项目列表
export function getProjectList(obj) {
    return axiosInstance({
        url: "/cem/getProjectList/",
        method: "POST",
        data: obj
    });
}
// 搜索项目
export function searchProject(obj) {
    return axiosInstance({
        url: "/cem/searchProject/",
        method: "POST",
        data: obj
    });
}

// 删除项目
export function deleteProject(obj) {
    return axiosInstance({
        url: "/cem/deleteProject/",
        method: "POST",
        data: obj
    });
}

// 获取调研问卷列表
export function getSpecialEventList(obj) {
    return axiosInstance({
        url: "/cem/getSpecialEventList/",
        method: "POST",
        data: obj
    });
}

// 删除调研项目
export function deleteSpeicalEvent(obj) {
    return axiosInstance({
        url: "/cem/deleteSpeicalEvent/",
        method: "POST",
        data: obj
    });
}
// 调研下架
export function updateSpecialEventStatus(obj) {
    return axiosInstance({
        url: "/cem/updateSpecialEventStatus/",
        method: "POST",
        data: obj
    });
}
// 调研审核+发布
export function updateSurveyAuditProgress(obj) {
    return axiosInstance({
        url: "/cem/updateSurveyAuditProgress/",
        method: "POST",
        data: obj
    });
}
// 调研审核+发布
export function updateSpecialEventFlagCount(obj) {
    return axiosInstance({
        url: "/cem/updateSpecialEventFlagCount/",
        method: "POST",
        data: obj
    });
}

// 获取问卷列表(用于问卷复制）
export function getSurveyList(obj) {
    return axiosInstance({
        url: "/data/GetSurveyList/",
        method: "POST",
        data: obj
    });
}

// 复制问卷
export function duplicateSurvey(obj) {
    return axiosInstance({
        url: "/data/DuplicateSurvey/",
        method: "POST",
        data: obj
    });
}
