import React, { Component } from 'react'
import { BrowserRouter as Router, Switch } from "react-router-dom";
import FrontendAuth from "./router/FrontendAuth";
import routerMap from "./router/routerMap";
import SideBar from './components/Side_bar/side_bar'
import './App.css'
import { Provider } from 'react-redux'
import store from './redux/store'
import packageJson from '../package.json';
import { formatDate } from './util/enum.js';
export default class Routers extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  handleVersion(packageJson) {
    const projectNameStyle = 'font-size: 20px;font-weight: 600;color: rgb(244,167,89);';
    const versionStyle = 'color: rgb(30,152,255);padding:8px 0 2px;';
    const dateTimeStyle = 'color: rgb(30,152,255);padding:0 0 5px;';

    /* 内容代码 */
    let projectName = '上海心盒科技';
    const version = `版 本 号：${packageJson.version}`;
    const dateTime = `编译日期：${formatDate(new Date, 'yyyy-MM-dd hh:mm:ss')}`;
    console.log(`%c${projectName}\n%c${version}\n%c${dateTime}`, projectNameStyle, versionStyle, dateTimeStyle);
  }
  componentDidMount() {
    this.handleVersion.call(this, packageJson)
    console.log(`version: ${packageJson.version}`);
  }
  render() {
    return (
      <div className="xh-layout">
        <Provider store={store}>
          <Router>
            <SideBar />
            <Switch>
              <FrontendAuth routerConfig={routerMap} />
            </Switch>
          </Router>
        </Provider>
      </div>
    );
  }
}
