import React, { Component } from 'react'
import './Score_list.scss';

import cur_icon_back from '../../../../images/cur_icon_back.png'
import overall_icon_add from '../../../../images/overall_data/overall_icon_add.png'
import cur_icon_search from "../../../../images/emotion_analysis/cur_icon_search.png"
import project_icon_full from '../../../../images/project_icon_full.png'
import { Input, message, Table, ConfigProvider, Tooltip } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import ScoreFormula from './Score_formula';
const { Search } = Input;

let that = ''
export default class Score_list extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: '名称',
                dataIndex: 'calculation_name',
                key: 'calculation_name',
                width: 50,
                fixed: 'left',
            },
            {
                title: '关联题目',
                dataIndex: 'calculation_question_name',
                key: 'calculation_question_name',
                width: 100,
                render: (item) => {
                    return <div>
                        <Tooltip placement="bottom" title={this.handleQuestionNameTooltip.call(this, item)}>
                            {this.handleQuestionName.call(this, item)}
                        </Tooltip>

                    </div>
                    // return <div>123</div>
                }
            },
            {
                title: '公式',
                dataIndex: 'calculation_formula',
                key: 'calculation_formula',
                width: 100,
            },
            {
                title: '操作',
                dataIndex: '',
                key: 'action',
                width: 50,
                render: (item) => {
                    return <label>
                        <span onClick={this.handleRevise.bind(this, item)} >修改</span>
                    </label >
                }
            },
        ]
        this.state = {
            score_list_width: '',     //底部的宽度
            score_list_main_height: '',   //主内容的高度
            question_list: [],// 题目列表
            calculation_list: [],//分数列表
            selectedRowKeys: [],
            modelContent: "确定删除该计算公式吗？",  // 删除弹出框
            showModel: false, // 删除弹出框
            modelTitle: "删除提示", // 删除弹出框
            showQuestionInfo: true,
            canPrev: false,
            canShowScoreFormula: false, // 展示分数公式

        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.showQuestionInfo) !== JSON.stringify(state.showQuestionInfo_prev) && props.canShowScoreList) {
            return {
                needRefresh: true,
                question_list: props.question_list || [],
                showQuestionInfo: props.showQuestionInfo,
                showQuestionInfo_prev: props.showQuestionInfo,
            }
        }
        return null;
    }
    componentDidMount() {
        that = this
        this._isMounted = true
        this.getBaseInfoWidth()
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.initData()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        //销毁监听
        clearInterval(window.get_score_list_width)
        this._isMounted = false;
        this.setState = () => { }
    }
    getBaseInfoWidth() {
        window.get_score_list_width = setInterval(() => {
            that.setState({ score_list_width: document.getElementById('contain').offsetWidth + 'px' })
            that.setState({
                score_list_main_height: document.getElementById('contain').offsetHeight - document.getElementById('calculation_list_bottom').offsetHeight - document.getElementById('project_entry_top').offsetHeight - 15 + 'px'
            })
        })
    }
    initData() {
        console.log('init calculation')
        this.getScoreList();
    }
    getScoreList() {
        let calculation_list = JSON.parse(JSON.stringify(this.state.calculation_list))
        let obj = {
            calculation_id: "",
            calculation_name: "求和",
            calculation_question_name: [
                {
                    sort: 1,
                    question_name: "你平时喜欢什么",
                    question_id: ""
                }
            ],
            calculation_formula: "x+y"
        }
        calculation_list.push(obj)
        this.setState({
            calculation_list: calculation_list
        })
    }
    prev() {                                     //上一步
        this.setState({
            project_entry_number: 3
        })
        this.props.handleQuestionInfo({
            project_entry_number: 3,
        })
    }
    handleModel() {

    }
    addProject() {

    }
    onSelectChange = (selectedRowKeys, record) => {
        this.setState({ selectedRowKeys, record });
    };
    onSearch() {

    }
    handleRevise() {
        this.setState({
            canShowScoreFormula: true
        })
    }
    handleQuestionNameTooltip(question_name_arr) {
        let question_name_list = []
        for (let i = 0; i < question_name_arr.length; i++) {
            let str = "Q" + question_name_arr[i].sort + ":" + question_name_arr[i].question_name
            question_name_list.push(str)
        }
        return question_name_list.join(" ")
    }
    handleQuestionName(question_name_arr) {
        let question_name_list = []
        for (let i = 0; i < question_name_arr.length; i++) {
            let str = "Q" + question_name_arr[i].sort + ":" + question_name_arr[i].question_name
            question_name_list.push(str)
        }
        return question_name_list.join(" ")
    }
    handleScoreFormulaDisplay(object) {
        const { canShowScoreFormula } = object
        this.setState({
            canShowScoreFormula: canShowScoreFormula
        })
    }
    render() {
        const prefix = (
            <img src={cur_icon_search} alt="" className='evaluation-icon-search' />
        )
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div>
                <div className={this.state.canShowScoreFormula ? "isHide" : "calculation-list-contain"} style={{ height: this.state.score_list_main_height }}>
                    {/* 顶部 */}
                    <div className='calculation-list-top'>
                        <img src={cur_icon_back} alt="" onClick={this.prev.bind(this)} />
                        <span>计算设置</span>
                    </div>
                    {/* 中部 */}
                    <div className='calculation-list-contain-main'>
                        <div className="project-operaion">
                            <div className="project-operaion-left">
                                <div className="project-delete" onClick={this.handleModel.bind(this, true, false)}>
                                    删除
                                </div>
                                <div className="project-add" onClick={this.addProject.bind(this)}>
                                    <img src={overall_icon_add} alt="" />
                                    <span>添加计算逻辑</span>
                                </div>
                            </div>
                            <div className="project-operaion-right">
                                <Search
                                    placeholder="请输入商品名称"
                                    allowClear
                                    enterButton="查询"
                                    prefix={prefix}
                                    onSearch={this.onSearch}
                                />
                            </div>
                        </div>
                        <div className='calculation-list-main'>
                            <div className='calculation-list-table'>
                                <ConfigProvider locale={zhCN}>
                                    <Table rowSelection={rowSelection} dataSource={this.state.calculation_list} columns={this.columns} pagination={{ pageSize: 10, showSizeChanger: false, showQuickJumper: true }} scroll={{ x: 900 }} rowKey={columns => columns.calculation_id} />
                                </ConfigProvider>
                                <div className="evaluation-table-sum">共<span>{this.state.calculation_list.length || 0}</span>个结果，当前展示1-10行</div>
                            </div>
                        </div>
                    </div>
                    {/* 底部 */}
                    <div className='calculation-list-bottom' id="calculation_list_bottom" style={{ width: this.state.score_list_width }}>
                        <div className='calculation-list-bottom-content'>
                            <div onClick={this.prev.bind(this)}>上一步</div>
                        </div>
                    </div>
                </div>
                <div className={this.state.canShowScoreFormula ? "" : "isHide"} >
                    <ScoreFormula
                        question_list={this.state.question_list}
                        canShowScoreFormula={this.state.canShowScoreFormula}
                        handleScoreFormulaDisplay={this.handleScoreFormulaDisplay.bind(this)}
                    ></ScoreFormula>
                </div>
            </div >

        )
    }
}
