import React, { Component } from 'react'
import './maxdiff_info.scss';
import EditNode from '../../../../../EditNode/edit_node';
import XmUpload from '../../../../../Upload_pic/xm_upload';
import cur_icon_delete from "../../../../../../images/cur_icon_delete.png"
import cur_icon_add from "../../../../../../images/cur_icon_add.png"
import { message } from 'antd';

export default class maxdiff_info extends Component {
    constructor(props) {
        super(props);
        // this.has_fixed_list = [11, 12, 13] // 最大小值 名称
        // this.has_option_active_list = [1, 2, 12, 13, 4] //单选多选排序的选项上下家
        this.state = {
            name: 'maxdiff_info',
            dataList: {},
            answers: [],
        }

    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                answers: props.dataList.answers || state.answers,
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    // 编辑选项
    onChangeEditor(option_index, obj) {
        this.props.handleQuestionOptionName(option_index, obj.value)
    }
    // 添加选项图片
    addOptionPic(option_index, image, filename) {
        this.props.addOptionPic(option_index, image, filename)
    }
    // 删除选项图片
    deleteOptionPic(option_index) {
        this.props.deleteOptionPic(option_index)
    }
    // 删除选项
    handleDeleteOption(option_index) {
        if (this.state.answers.length <= 3) {
            message.warning(`选项数量限制在3-50范围内`, 2, () => { });
            return
        }
        this.props.handleDeleteOption(option_index)
    }
    //添加选项
    handleAddOption(option_index) {
        if (this.state.answers.length >= 50) {
            message.warning(`选项数量限制在3-50范围内`, 2, () => { });
            return
        }
        this.props.handleAddOption(option_index)
    }
    render() {
        return (
            <div className='maxdiff-info-container'>
                <div className='maxdiff-table'>
                    <div className='maxdiff-table-header'>
                        <div className='maxdiff-table-header-title'><span>{this.state.dataList.min_name}</span></div>
                        <div className='maxdiff-table-header-title'></div>
                        <div className='maxdiff-table-header-title'><span>{this.state.dataList.max_name}</span></div>
                    </div>
                    <div className='maxdiff-table-body'>
                        {
                            this.state.answers.map((item, index) => {
                                return (
                                    <div className='maxdiff-table-body-item' key={index}>
                                        <div className='main-content'>
                                            <div className="table-content">
                                                <div className='item'></div>
                                            </div>
                                            <div className="table-content table-content-option">
                                                {/* <div>{item.name}</div> */}
                                                <div className="edit-question-title-wrap">
                                                    <EditNode value={item.name} onChange={this.onChangeEditor.bind(this, index)} className="edit-question-title"></EditNode>
                                                </div>
                                                <XmUpload className="upload-question-pic"
                                                    image_name={item.image.val}
                                                    handleDeletePic={this.deleteOptionPic.bind(this, index)}
                                                    handleUploadPic={this.addOptionPic.bind(this, index)}>
                                                </XmUpload>
                                            </div>
                                            <div className="table-content">
                                                <div className='item'></div>
                                            </div>
                                        </div>
                                        <div className='main-content-operate' onClick={this.handleAddOption.bind(this, index)}>
                                            <img src={cur_icon_add} className='add-option-icon' alt="" />
                                        </div>
                                        <div className='main-content-operate' onClick={this.handleDeleteOption.bind(this, index)}>
                                            <img src={cur_icon_delete} className='delete-option-icon' alt="" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="info-foot" onClick={this.handleAddOption.bind(this, "")} >
                    <img src={cur_icon_add} className='add-option-icon' alt="" />
                    <div>添加选项</div>
                </div>
            </div>
        )
    }
}
