import React, { Component } from 'react'
import "./ColorPicker.scss"

export default class ColorPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "ColorPicker",
        }

    }
    // onChangeColor() {
    //     this.props.onChange()
    // }
    //验证数字
    validateColor(event) {
        let text = event.target.value
        if (text.charAt(0) === '#') {
            event.target.value = '#' + text.replace(/[^(a-fA-F0-9)]/g, "");
        } else {
            event.target.value = "#";
        }
    }
    render() {
        let { value, onChange, ...rest } = this.props
        return (
            <span className="color-picker-container">
                <input type="color" value={value} onChange={onChange} {...rest} />
                <input type='text' value={value} onChange={onChange} {...rest} maxLength="7" onInput={this.validateColor.bind(this)} />
            </span>
        )
    }
}
