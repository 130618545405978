import axiosInstance from '../APIUtils'

export function GetFollowUpSankeySession(obj) {
  return axiosInstance({
      url: "/cem/GetFollowUpSankeySession/",
      method: "POST",
      data: obj
  });
}

export function GetFollowUpSankey(obj) {
  return axiosInstance({
      url: "/cem/GetFollowUpSankey/",
      method: "POST",
      data: obj
  });
}

export function GetFollowUpSankeyWithCondition(obj) {
  return axiosInstance({
      url: "/cem/GetFollowUpSankeyWithCondition/",
      method: "POST",
      data: obj
  });
}
export function GetFollowUpRemaningNodes(obj) {
  return axiosInstance({
      url: "/cem/GetFollowUpRemaningNodes/",
      method: "POST",
      data: obj
  });
}