import React, { Component } from 'react'
import './incentive_popup.scss';
import cur_icon_close_gre from '../../../../images/cur_icon_close_gre.png'

export default class incentive_popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            amount: 0
        }
    }
    tags_close() {
        this.setState({
            amount: 0
        })
        this.props.get_incentive_popup_status({
            amount: this.state.amount,
            canIncentive: false,
            incentive_popup_status: false
        })
    }
    tags_confirm() {
        this.setState({
            amount: 0
        })
        this.props.get_incentive_popup_status({
            amount: this.state.amount,
            canIncentive: true,
            incentive_popup_status: false
        })

    }
    // 数字
    handleInputChange(event) {
        if (event.target.value === "") {
            event.target.value = 0
        }
        this.setState({
            amount: event.target.value
        })
    }
    //验证数字
    validateNumber(event) {
        event.target.value = event.target.value.replace(/^0[0-9]+/g, 0);
        event.target.value = event.target.value.replace(/[^/.\d]/g, "");
        event.target.value = event.target.value.replace(".", "");
    }
    //操作数量
    handleAmount(t) {
        if (t === "add") {
            this.setState({
                amount: Number(this.state.amount) + 1
            })
        } else {
            if (this.state.amount > 0) {
                this.setState({
                    amount: Number(this.state.amount) - 1
                })
            }
        }
    }
    render() {
        return (
            <div className='incentive-popup'>
                <div className='incentive-popup-contain'>
                    <div className='incentive-popup-top'>
                        <div className='popup-top-title'>{this.props.selectedRowKeys.length > 1 ? "批量发起小红书奖励" : "发起小红书奖励"}</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                        </div>
                    </div>
                    <div className='incentive-popup-input'>
                        <div className='custom-input-title'>小红书激励</div>
                        <div className='custom-input-wrapper'>
                            <div className='input-icon-wrapper'>
                                <div className='input-icon' onClick={this.handleAmount.bind(this, "add")}>+</div>
                            </div>
                            <input className='custom-input' type="text" defaultValue={this.state.amount} key={this.state.amount} onInput={this.validateNumber.bind(this)} onBlur={this.handleInputChange.bind(this)} />
                            <div className='input-icon-wrapper'>
                                <div className='input-icon' onClick={this.handleAmount.bind(this, "subtract")}>-</div>
                            </div>
                        </div>
                    </div>
                    <div className='tag-btn'>
                        <div onClick={this.tags_confirm.bind(this)}>{this.props.selectedRowKeys.length > 1 ? "批量发起" : "发起"}</div>
                        <div onClick={this.tags_close.bind(this)}>取消</div>
                    </div>
                </div>
            </div>
        )
    }
}
