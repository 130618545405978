export const incentiveStatus = {
    0: "已发起",
    1: "用户已反馈",
    2: "审核通过",
    3: "审核未通过",
    4: "未发起",
    5: "已关闭"
}


export const incentiveStatusList = getIncentiveStatusList(incentiveStatus)

function getIncentiveStatusList() {
    let list = [{ key: -1, value: "全部" }]
    for (let key in incentiveStatus) {
        list.push({
            key: key,
            value: incentiveStatus[key]
        })
    }
    return list
}