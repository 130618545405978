import React, { Component } from 'react'
import "./Evaluation_detail.scss"
import back_btn_icon from '../../../images/home_page/about_us/back_btn_icon.png'
import SimpleQuestion from '../Question_detail/Simple_question';
import NpsQuestion from '../Question_detail/Nps_question';
import MultipleQuestion from '../Question_detail/Multiple_question'
import SortQuestion from '../Question_detail/Sort_question'
export default class Evaluation_detail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dataList: {}, // 答卷人
            comment_detail_list: [], //
            product_image: "",
            product_title: localStorage.getItem("brand_text_cem") + "-" + localStorage.getItem("product_text_cem") || "",
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            dataList: props.dataList || {},
            dataList_prev: props.dataList,
            comment_detail_list: props.comment_details.comment_detail || [],
            product_image: props.comment_details.product_image,
            product_title: props.product_title
        }
    }
    componentDidMount() {
    }
    componentWillUnMount = () => {
        this._isMounted = false;
        this.setState = () => { };

    }
    // 返回到列表页
    handleBack() {
        this.props.handleBack()
    }
    choose_question_detail(item, index) {
        if (item.question_type === 1 || item.question_type === 12 || item.question_type === 13 || item.question_type === 3) { //单选题 简答题
            return <SimpleQuestion dataList={item} index={index} key={index}></SimpleQuestion>
        } else if (item.question_type === 7 || item.question_type === 11) { //滑标题
            return <NpsQuestion dataList={item} index={index} key={index}></NpsQuestion>
        } else if (item.question_type === 2) { //多选题
            return <MultipleQuestion dataList={item} index={index} key={index}></MultipleQuestion>
        } else if (item.question_type === 4) { //排序题
            return <SortQuestion dataList={item} index={index} key={index}></SortQuestion>
        }
    }
    render() {
        return (
            <div className="evaluation-detail-container">
                <div className='evaluation-title-wrapper'>
                    <div className="title-wrapper">
                        <span className='line'></span>
                        <span className='text'>产品评论</span>
                    </div>
                    <div className='btn-wrapper'>
                        <div className="back-btn" onClick={this.handleBack.bind(this)}>
                            <img src={back_btn_icon} alt="" />
                            <span>返回</span>
                        </div>
                    </div>
                </div>
                <div className="evaluation-detail-panel">
                    {/* 答卷详情 */}
                    <div className="panel-left">
                        <div className="detail-content">
                            {this.state.comment_detail_list.map((item, index) => {
                                return this.choose_question_detail.call(this, item, index)
                            })}
                        </div>
                    </div>
                    {/* 答卷人 */}
                    <div className="panel-right">
                        <div className="commentator-title-wrapper">
                            <div className="product-image">
                                <img src={this.state.product_image} alt="" />
                            </div>
                            <div className="commentator-title">
                                <div className="commentator-username">{this.state.dataList.username}</div>
                                <div className="commentator-product">{this.state.product_title}</div>
                            </div>
                        </div>
                        <div className="commentator-content">
                            {this.state.dataList.content}
                        </div>
                        <div className="commentator-intro">
                            <div className="commentator-intro-line">-----</div>
                            <div className="commentator-intro-option">{this.state.dataList.address}</div>
                            <div className="commentator-intro-option">{this.state.dataList.gender}</div>
                            <div className="commentator-intro-option">{this.state.dataList.age}岁</div>
                            <div className="commentator-intro-option">{this.state.dataList.occupation}</div>
                        </div>
                        <div className={this.state.dataList.comment_image ? "commentator-image" : ""}>
                            <img src={this.state.dataList.comment_image} alt="" />
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
