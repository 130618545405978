import React, { Component } from 'react'
import './heatmap_info.scss'
import XmModel from '../../../../../../components/Xm_model/Xm_model'
import HeatmapPopup from './Heatmap_popup/heatmap_popup'
import upload_add_img from '../../../../../../images/project_entry/project_info/upload_add_pic.png'
import upload_close_img from '../../../../../../images/upload_close.png'
import { handleUploadImg } from "../../../../../../util/utils"
import { Upload, Image, ConfigProvider, message } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';

export default class heatmap_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'heatmap_info',
            modelContent: "确定删除该图片吗？",
            showModel: false,
            modelTitle: "删除提示",
            dataList: props.dataList || {},
            answers: props.dataList.answers || [],
            showHeatmapPopup: false,
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                answers: props.dataList.answers || state.answers,
            }
        }
        return null;
    }
    // 上传图片
    handleUpload(args) {
        let _this = this//烦人的this
        handleUploadImg(args).then(res => {
            if (res !== -1) {
                _this.props.addQuestionPic(res.imgs, res.filename)
            }
        })
    }
    // 编辑按钮选项
    onChangeEditor(option_index, obj) {
        this.props.handleQuestionOptionName(option_index, obj.value)
    }
    // 第一个参数是设置是否显示，第二个参数就是子组件选确认
    handleModel(val, flag) {
        if (flag) {
            // this.handleDeleteUpload()
            this.props.deleteQuestionPic()
        }
        this.setState({
            showModel: val,
        })
    }
    handleHeatmapRegion() {
        if (!this.state.dataList.image.val) {
            message.warning(`请先上传热力图背景`, 2, () => { });
            return
        }
        this.setState({
            showHeatmapPopup: true,
        })
    }
    // 是否显示heatmap框
    canShowHeatmapPopup(object) {
        this.setState({
            showHeatmapPopup: object.showHeatmapPopup
        })
        this.props.handleQuestionOption(object.heatmaop_answers)
    }
    render() {
        return (
            <div className='heatmap-info-container'>
                <div className="heatmap-info-tip">
                    请上传热力图背景
                </div>
                <div className="heatmap-info-wrapper">
                    <div className="heatmap-info-left">
                        <div className={this.state.dataList.image.val ? "isHide" : "show-upload-wrap"}>
                            <Upload
                                showUploadList={false}
                                customRequest={this.handleUpload.bind(this)}
                            >
                                <div className="upload-pic-wrapper">
                                    <img src={upload_add_img} alt="" className="upload-pic" />
                                    <div className="upload-pic-text">添加图片</div>
                                </div>

                            </Upload>
                        </div>
                        <div className={this.state.dataList.image.val ? "show-pic-wrap" : "isHide"}>
                            <ConfigProvider locale={zhCN}>
                                <Image src={this.state.dataList.image.val} alt="" className="show-pic" width={108} height={108} />
                            </ConfigProvider>
                            <img src={upload_close_img} alt="" className="upload-close-img" onClick={this.handleModel.bind(this, true, false)} />
                        </div>
                    </div>
                    <div className="heatmap-info-right">
                        <div className="heatmap-info-btn" onClick={this.handleHeatmapRegion.bind(this)}>
                            设置热力图选区
                        </div>
                    </div>
                </div>
                <div className='delete-image-popup'>
                    <XmModel isShowModel={this.state.showModel} handleShowModel={this.handleModel.bind(this)} modelContent={this.state.modelContent} modelTitle={this.state.modelTitle}></XmModel>
                </div>
                {
                    this.state.showHeatmapPopup ?
                        <HeatmapPopup
                            dataList={this.state.dataList}
                            canShowHeatmapPopup={this.canShowHeatmapPopup.bind(this)}
                        ></HeatmapPopup> : ''
                }
            </div>
        )
    }
}
