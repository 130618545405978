import React, { Component } from 'react'
import './detail_chart_popup.scss';
import cur_icon_close_gre from '../../../images/cur_icon_close_gre.png'
import cur_icon_search from "../../../images/emotion_analysis/cur_icon_search.png"
import DetailOptionList from './detail_option_list'
import debounce from 'lodash/debounce';
import { Input } from 'antd';

export default class detail_chart_popup extends Component {
    constructor(props) {
        super(props);
        this.resize = debounce(this.resize, 1000);
        this.state = {
            desired_user_list: [],
            desired_user_list_formal: [],
            single_id: "",
            bar_option_name: "",
            question_name: "",
            blankListHeight: 0,
            search_text: ""
        }
    }
    setDetailList(obj) {
        this.debounceFunc = this.resize.bind(this);
        window.addEventListener("resize", this.debounceFunc, false);
        let { desired_user_list, single_id, bar_option_name, question_name } = obj
        this.setState({
            desired_user_list: desired_user_list,
            desired_user_list_formal: desired_user_list,
            single_id: single_id,
            bar_option_name: bar_option_name,
            question_name: question_name,
            search_text: ""
        }, () => {
            this.resize()
            // this.props.toInitChart()
        })
    }
    // 这里主要是定义高度
    resize() {
        let blankDom = document.getElementById("detail_chart_" + this.state.single_id + "_wrapper")
        if (!blankDom) { return; }
        let blankDomHeight = blankDom.offsetHeight - 60;
        this.setState({ blankListHeight: blankDomHeight })
    }
    // 关闭对话框
    tags_close() {
        this.props.canShowDetailChartPopup({ showDetailChartPopup: false })
    }
    // 搜索按钮 传父
    handleSearch(event) {
        if (!event) return false
        let search_text = event.target.value.trim()
        if (search_text === "") {
            this.setState({
                desired_user_list: this.state.desired_user_list_formal,
                search_text: ""
            })
        } else {
            let desired_user_list = []
            for (let i = 0; i < this.state.desired_user_list_formal.length; i++) {
                if (this.state.desired_user_list_formal[i].content.indexOf(search_text) !== -1) {
                    desired_user_list.push(this.state.desired_user_list_formal[i])
                }
            }
            this.setState({
                desired_user_list: desired_user_list,
                search_text: search_text
            })
        }
    }
    render() {
        const prefix = (
            <img src={cur_icon_search} alt="" className='evaluation-icon-search' />
        )
        return (
            <div className='detail-chart-popup'>
                <div className='detail-chart-popup-container'>
                    <div className='detail-chart-popup-top'>
                        <div className='popup-top-title'>列表详情展示</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                        </div>
                    </div>
                    {/* 图表名字 */}
                    <div className='popup-content-top'>
                        <div className='top-title-wrapper'>
                            <div className='main-title'>{this.state.question_name}</div>
                            <div className='sub-title'>选项：{this.state.bar_option_name}</div>
                        </div>
                        <div className='search-wrapper'>
                            <Input prefix={prefix} type="text" value={this.state.search_text} placeholder="请输入要搜索的评论" onChange={this.handleSearch.bind(this)} />
                        </div>
                    </div>
                    {/* 显示真正的列表*/}
                    <div className='detail-chart-wrapper' id={`detail_chart_${this.state.single_id}_wrapper`} >
                        <div className="detail-chart-wrapper-2">
                            <DetailOptionList
                                search_text={this.state.search_text}
                                dataList={this.state.desired_user_list}
                                blankListHeight={this.state.blankListHeight}
                            ></DetailOptionList>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
