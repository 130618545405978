import React, { Component } from "react";
import "./question_survey.scss";
import { connect } from "react-redux";
import Toast from "../../../components/Toast/Toast";
import ColorPicker from "../../../components/ColorPicker/ColorPicker";
import Loading from "../../../components/Loading/loading";
import XmEditor from "../../../components/Xm_editor/xm_editor";
import {
  DatePicker,
  message,
  Upload,
  Modal,
  Image,
  ConfigProvider,
} from "antd";
import "antd/dist/antd.css";
import "moment/locale/zh-cn";
import locale from "antd/lib/date-picker/locale/zh_CN";
import moment from "moment";
import E from "wangeditor";
import survey_rule_placeholder from "./rule_editor_data";
import zhCN from "antd/lib/locale/zh_CN";
import { getUser } from "../../../redux/actions/user";
import { handleUploadImg } from "../../../util/utils";
import {
  saveSpecialEvent,
  getSpecialEvent,
  updateSpecialEvent,
} from "../../../api/Basic_information/question_survey_api.js";
let that = "";
class question_survey extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      loading_save: 0, // 防止保存的loading
      loadingText: "加载中",
      name: "question_survey",
      product_id: localStorage.getItem("product_id")
        ? localStorage.getItem("product_id")
        : "", //产品ID
      brand_id: localStorage.getItem("brand_id")
        ? localStorage.getItem("brand_id")
        : "", //品牌ID
      project_id: localStorage.getItem("project_id")
        ? localStorage.getItem("project_id")
        : "",
      shouldJudgeTime: false,
      isCorrectStartTime: true,
      isCorrectEndTime: true,
      toastStatus: false,
      msg: "", //验证错误信息
      question_survey_width: "", //底部的宽度
      question_survey_main_height: "", //主内容的高度
      project_entry_number: 0, //第1步
      survey_name_val: "", //名称
      survey_respondent_num_val: "", // 调研人数
      survey_respondent_num_val_prev: "",
      survey_description_val: "", //描述
      survey_description_placeholder: "请输入调研详情...", //
      survey_description_status: false,

      survey_rule_val: survey_rule_placeholder, //规则
      survey_rule_status: false,

      survey_start_time_val: "", //开始时间
      survey_end_time_val: "", //结束时间
      survey_title_color_val: "#000000", //标题颜色
      survey_bg_color_val: "#FFFFFF", //背景色
      survey_img: "", //图片
      survey_img_filename: "", //图片
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (JSON.stringify(props.tab_key) !== JSON.stringify(state.tab_key_prev)) {
      return {
        needRefresh: true,
        project_entry_number: props.project_entry_number || 0, //question
        tab_key_prev: props.tab_key,
        tab_key: props.tab_key,
      };
    }
    return null;
  }
  componentDidMount() {
    this._isMounted = true;
    that = this;
    this.setState({
      project_entry_number: this.props.project_entry_number,
    });

    // this.getBaseInfoWidth()
    // this.getSpecialEvent()
  }
  componentDidUpdate() {
    if (this._isMounted && this.state.needRefresh) {
      // this.toInitChart()
      // setTimeout(() => {
      //     this.toInitFoot()
      // })
      this.getSpecialEvent();

      this.setState({
        needRefresh: false,
      });
    }
  }
  componentWillUnmount() {
    // clearInterval(window.get_question_survey_width)           //销毁监听
    this._isMounted = false;
    this.setState = () => { };
    console.log("Component WILL UNMOUNT!");
  }
  // 获取基础信息宽度
  getBaseInfoWidth() {
    // window.get_question_survey_width = setInterval(() => {                //监听元素宽度
    //     this.setState({ question_survey_width: document.getElementById('contain').offsetWidth + 'px' })
    //     this.setState({     //监听主内容的高度并赋值
    //         question_survey_main_height: document.getElementById('contain').offsetHeight - document.getElementById('contain_modules').offsetHeight - document.getElementsByClassName('ant-tabs-nav')[0].offsetHeight - document.getElementById('question_survey_bottom').offsetHeight - document.getElementById('project_entry_top').offsetHeight - 15 + 'px'
    //     })
    // })
  }
  // 判断人数额度是否正确
  handleQuotaJudge(event) {
    let value = Number(event.target.value);
    if (parseInt(localStorage.getItem("current_project_progress")) >= 6) {
      message.warning("问卷已经结束，不可修改", 4, () => { });
      this.setState({
        survey_respondent_num_val: this.state.survey_respondent_num_val_prev,
      });
      return;
    }
    let survey_respondent_num_val_prev = parseInt(
      this.state.survey_respondent_num_val_prev
    );
    let quota = parseInt(this.props.user.quota);
    let project_id = localStorage.getItem("project_id") || "";
    if (project_id) {
      if (value < parseInt(localStorage.getItem("finished_project_quota"))) {
        message.warning("人数额度需要大于等于已经完成的人数额度", 4, () => { });
        this.setState({
          survey_respondent_num_val: this.state.survey_respondent_num_val_prev,
        });
        return;
      } else if (value > quota + survey_respondent_num_val_prev) {
        this.setState({
          survey_respondent_num_val: this.state.survey_respondent_num_val_prev,
        });
        message.warning("账户人数余额不够，请联系商务进行升级", 4, () => { });
        return;
      }
    } else if (value > quota + survey_respondent_num_val_prev) {
      this.setState({ survey_respondent_num_val: "" });
      message.warning("账户人数余额不够，请联系商务进行升级", 4, () => { });
      return;
    }
    this.setState({
      survey_respondent_num_val: String(value),
    });
  }
  // 单改变值，非下拉框的，比如填空题
  handleChange(t, event) {
    if (!t) return false;
    if (!event) return false;
    this.setState({ [t + "_val"]: event.target.value });
  }

  disabledDate(t, current) {
    // clearInterval(window.get_question_survey_width)
    // console.log(current)
    // console.log(moment())
    // Can not select days before today and today
    // return current && current < moment().endOf('second');
    return current < moment().subtract(1, "day");
  }
  timestampToTime(timestamp) {
    var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + "-";
    var M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
    var h =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var m =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
  }

  // 显示日期
  handleChangeDate(t, date, string) {
    // clearInterval(window.get_question_survey_width)
    // console.log(moment(string).valueOf())// 转时间戳
    // 1653667200000
    // console.log(moment(1653667200000).format('YYYY-MM-DD HH:mm:ss')); //时间戳转
    // this.setState({ [t + '_val']: moment(string).valueOf() })
    // eslint-disable-next-line no-extend-native
    let currentTimeStamp = Date.parse(new Date()) / 1000;
    let currentTime = this.timestampToTime(currentTimeStamp);
    let isCorrectStartTime = true;
    let isCorrectEndTime = true;
    let survey_start_time_val = this.state.survey_start_time_val;
    let survey_end_time_val = this.state.survey_end_time_val;
    if (t === "survey_start_time") {
      if (string < currentTime && this.state.shouldJudgeTime) {
        message.warning("开始时间需要大于当前时间", 5, () => { });
        isCorrectStartTime = false;
      }
      survey_start_time_val = string;
      if (survey_end_time_val < currentTime) {
        isCorrectEndTime = false;
      }
    }
    if (t === "survey_end_time") {
      if (string < currentTime && this.state.shouldJudgeTime) {
        message.warning("结束时间需要大于当前时间", 5, () => { });
        isCorrectEndTime = false;
      }
      survey_end_time_val = string;
      if (survey_start_time_val < currentTime) {
        isCorrectStartTime = false;
      }
    }
    if (
      survey_start_time_val &&
      survey_end_time_val &&
      survey_start_time_val > survey_end_time_val
    ) {
      isCorrectStartTime = false;
      isCorrectEndTime = false;
      message.warning("开始时间需要小于结束时间", 5, () => { });
    }
    this.setState({
      [t + "_val"]: string,
      isCorrectStartTime: isCorrectStartTime,
      isCorrectEndTime: isCorrectEndTime,
    });
  }
  // 上传图片
  handleUpload(args) {
    handleUploadImg(args).then((res) => {
      if (res !== -1) {
        let isLt1M = res.file_size / 1024 / 1024 < 1;
        if (isLt1M) {
          this.setState({
            survey_img: res.imgs,
            survey_img_filename: res.filename,
          });
        } else {
          message.warning("图片大小要小于1MB", 0.2, () => { });
        }
      }
    });
  }
  // 颜色选择器
  handleColor(t, e) {
    this.setState({ [t + "_val"]: e.target.value });
  }
  //是否可以下一步标红
  canNext() {
    let flag = false;
    if (
      this.state.survey_name_val &&
      this.state.survey_description_val &&
      this.state.survey_rule_val &&
      this.state.survey_respondent_num_val &&
      this.state.survey_start_time_val &&
      this.state.survey_end_time_val &&
      this.state.survey_img
    ) {
      flag = true;
    }
    return flag;
  }
  //验证
  validate() {
    let flag = true;
    let currentTimeStamp = Date.parse(new Date()) / 1000;
    let currentTime = this.timestampToTime(currentTimeStamp);
    if (!this.state.survey_name_val) {
      message.warning("名称不可为空", 5, () => { });
      return false;
    }
    if (!this.state.survey_description_val) {
      message.warning("描述不可为空", 5, () => { });
      return false;
    }
    if (!this.state.survey_rule_val) {
      message.warning("规则不可为空", 5, () => { });
      return false;
    }
    if (!this.state.survey_respondent_num_val) {
      message.warning("调研人数不可为空", 5, () => { });
      return false;
    }
    if (!this.state.survey_start_time_val) {
      message.warning("开始时间不可为空", 5, () => { });
      return false;
    }
    if (!this.state.survey_end_time_val) {
      message.warning("结束时间不可为空", 5, () => { });
      return false;
    }
    if (
      this.state.survey_start_time_val < currentTime &&
      this.state.shouldJudgeTime
    ) {
      message.warning("开始时间需要大于当前时间", 5, () => { });
      return false;
    }
    if (
      this.state.survey_end_time_val < currentTime &&
      this.state.shouldJudgeTime
    ) {
      message.warning("结束时间需要大于当前时间", 5, () => { });
      return false;
    }
    if (this.state.survey_start_time_val > this.state.survey_end_time_val) {
      message.warning("开始时间需要小于结束时间", 5, () => { });
      return false;
    }
    if (!this.state.survey_img) {
      message.warning("图片不可为空", 5, () => { });
      return false;
    }
    return flag;
  }
  // 保存
  async saveSpecialEvents(obj, t) {
    try {
      const res = await saveSpecialEvent(obj);
      localStorage.setItem("project_id", res.data.project_id);
      localStorage.setItem("special_event_id", res.data.special_event_id);
      this.setState({
        project_id: res.data.project_id,
        project_type: res.data.project_type,
        special_event_id: res.data.special_event_id,
        loading_save: 0,
      });
      if (t === "next") {
        this.props.getData({
          project_entry_number: 2, //第几步
          survey_id: res.data.survey_id,
          dispatch_count: this.state.survey_respondent_num_val,
        });
      } else if (t === "save_drag") {
        this.props.getData({
          project_entry_number: 1, //第几步
          survey_id: res.data.survey_id,
          dispatch_count: this.state.survey_respondent_num_val,
        });
        localStorage.setItem("project_id_2", res.data.project_id);
      }
      message.success("保存成功", 1, () => { });
      this.props.getUser();
    } catch (err) {
      if (err.response?.data?.detail.indexOf("quota") !== -1) {
        message.warning(`${err.response.data.detail}，保存失败，请联系商务`, 2, () => { })
      } else {
        message.error("后台发生错误，保存失败");
      }
      this.setState({ loading_save: 0 });
      throw err;
    }
  }
  //更新
  async updateSpecialEvent(obj, t) {
    try {
      const res = await updateSpecialEvent(obj);
      this.setState({
        loading_save: 0,
      });
      if (t === "next") {
        this.props.getData({
          project_entry_number: 2, //第几步
          dispatch_count: this.state.survey_respondent_num_val,
        });
      } else if (t === "save_drag") {
        localStorage.setItem("project_id_2", res.data.project_id);
      }
      message.success("更新成功", 1, () => { });
      this.props.getUser();
    } catch (error) {
      message.error("更新失败");
      this.setState({ loading_save: 0 });
      throw error;
    }
  }
  //获取
  async getSpecialEvent() {
    if (!localStorage.getItem("project_id")) {
      this.setState({
        shouldJudgeTime: true,
      });
      return;
    }
    let project_id = localStorage.getItem("project_id") || "";
    let special_event_id = localStorage.getItem("special_event_id")
      ? localStorage.getItem("special_event_id")
      : "";
    if (project_id) {
      this.setState({ loading: 1 });
      try {
        const res = await getSpecialEvent({
          project_id: project_id,
          special_event_id: special_event_id,
        });
        this.setState({
          survey_name_val: res.data.survey_name,
          survey_description_val: res.data.survey_description,
          survey_rule_val: res.data.survey_rule,
          survey_respondent_num_val: res.data.survey_respondent_num,
          survey_respondent_num_val_prev: res.data.survey_respondent_num,
          survey_start_time_val: res.data.survey_start_time,
          survey_end_time_val: res.data.survey_end_time,
          survey_title_color_val:
            res.data.survey_title_color || this.state.survey_title_color_val,
          survey_bg_color_val:
            res.data.survey_bg_color || this.state.survey_bg_color,
          survey_img: res.data.survey_img,
          survey_img_filename: res.data.survey_name,
          project_type: res.data.project_type,
          special_event_id: res.data.special_event_id,
          loading: 0,
        });
      } catch (error) {
        message.error("获取失败，后台发生错误");
        this.setState({
          loading: 0,
        });
        throw error;
      }
    } else {
      localStorage.removeItem("current_project_progress");
      localStorage.removeItem("finished_project_quota");
    }
  }

  //下一步
  next(t) {
    //下一步
    let project_id = localStorage.getItem("project_id") || "";
    let special_event_id = localStorage.getItem("special_event_id")
      ? localStorage.getItem("special_event_id")
      : "";
    if (this.validate()) {
      let object = {
        survey_name: this.state.survey_name_val,
        survey_description: this.state.survey_description_val,
        survey_rule: this.state.survey_rule_val,
        survey_respondent_num: this.state.survey_respondent_num_val,
        survey_start_time: this.state.survey_start_time_val,
        survey_end_time: this.state.survey_end_time_val,
        survey_title_color: this.state.survey_title_color_val,
        survey_bg_color: this.state.survey_bg_color_val,
        survey_img: {
          filename: this.state.survey_img_filename,
          file: this.state.survey_img,
        },
        brand_id: localStorage.getItem("brand_id")
          ? localStorage.getItem("brand_id")
          : "", //品牌ID
        project_type: 2,
        special_event_id: special_event_id,
      };
      // localStorage.getItem('brand_id')
      // localStorage.setItem('product_id_cem', this.state.product_list[key].id)
      if (this.state.loading_save) {
        return;
      }
      this.setState({
        loading_save: 1,
        loadingText: "正在保存中，请稍后",
      });
      if (!project_id) {
        object.amount = 0;
        this.saveSpecialEvents(object, t);
      } else {
        object.project_id = project_id;
        this.updateSpecialEvent(object, t);
      }
    }
  }
  //
  handleEditorChange(t, val) {
    this.setState({
      [t + "_val"]: val,
    });
  }
  render() {
    return (
      <div className="question-survey-container">
        {this.state.loading <= 0 && this.state.loading_save <= 0 ? (
          ""
        ) : (
          <Loading loadingText={this.state.loadingText}></Loading>
        )}
        <div className="survey-item">
          <div className="survey-left">
            <span>*</span>名称
          </div>
          <div className="survey-right">
            <input
              placeholder="请输入项目名称"
              className="survey_input"
              type="text"
              defaultValue={this.state.survey_name_val}
              onChange={this.handleChange.bind(this, "survey_name")}
            />
          </div>
        </div>
        <div className="survey-item">
          <div className="survey-left">
            <span>*</span>描述
          </div>
          <div className="survey-right description-editor">
            <XmEditor
              handleEditorChange={this.handleEditorChange.bind(
                this,
                "survey_description"
              )}
              html={this.state.survey_description_val}
            ></XmEditor>
          </div>
        </div>
        <div className="survey-item">
          <div className="survey-left">
            <span>*</span>规则
          </div>
          <div className="survey-right survey-editor">
            <XmEditor
              handleEditorChange={this.handleEditorChange.bind(
                this,
                "survey_rule"
              )}
              html={this.state.survey_rule_val}
            ></XmEditor>
          </div>
        </div>
        <div className="survey-item">
          <div className="survey-left">
            <span>*</span>调研人数
          </div>
          <div className="survey-right">
            <input
              placeholder="请输入调研人数"
              className="survey_input"
              type="text"
              value={this.state.survey_respondent_num_val}
              onChange={this.handleChange.bind(this, "survey_respondent_num")}
              onBlur={this.handleQuotaJudge.bind(this)}
            />
          </div>
        </div>
        <div className="survey-item">
          <div className="survey-left">
            <span>*</span>开始时间
          </div>
          <div className="survey-right">
            <DatePicker
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              className={`date-picker ${this.state.isCorrectStartTime ? "" : "date-picker-wrong"
                }`}
              disabledDate={this.disabledDate.bind(this, "survey_start_time")}
              placeholder={
                this.state.survey_start_time_val
                  ? this.state.survey_start_time_val
                  : "请选择开始时间"
              }
              locale={locale}
              onChange={this.handleChangeDate.bind(this, "survey_start_time")}
            />
          </div>
        </div>
        <div className="survey-item">
          <div className="survey-left">
            <span>*</span>结束时间
          </div>
          <div className="survey-right">
            <DatePicker
              className={`date-picker ${this.state.isCorrectEndTime ? "" : "date-picker-wrong"
                }`}
              showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              format="YYYY-MM-DD HH:mm:ss"
              disabledDate={this.disabledDate.bind(this, "survey_end_time")}
              placeholder={
                this.state.survey_end_time_val
                  ? this.state.survey_end_time_val
                  : "请选择开始时间"
              }
              locale={locale}
              onChange={this.handleChangeDate.bind(this, "survey_end_time")}
            />
          </div>
        </div>
        <div className="survey-item">
          <div className="survey-left">
            <span></span>标题颜色
          </div>
          <div className="survey-right">
            <ColorPicker
              value={this.state.survey_title_color_val}
              onChange={this.handleColor.bind(this, "survey_title_color")}
            ></ColorPicker>
          </div>
        </div>
        <div className="survey-item">
          <div className="survey-left">
            <span></span>背景色
          </div>
          <div className="survey-right">
            <ColorPicker
              value={this.state.survey_bg_color_val}
              onChange={this.handleColor.bind(this, "survey_bg_color")}
            ></ColorPicker>
          </div>
        </div>
        <div className="survey-item survey-item-image">
          <div className="survey-left">
            <span>*</span>图片
          </div>
          <div className="survey-right">
            <div
              className={
                this.state.survey_img ? "survey-image-wrapper" : "isHide"
              }
            >
              <ConfigProvider locale={zhCN}>
                <Image
                  src={this.state.survey_img}
                  alt=""
                  className="survey-image"
                />
              </ConfigProvider>
            </div>
            <div className="upload-wrapper">
              <Upload
                showUploadList={false}
                customRequest={this.handleUpload.bind(this)}
              >
                <div className="upload-btn">上传图片</div>
              </Upload>
              <div className="upload-text">
                (图片尺寸：532*280px；文件大小＜1MB)
              </div>
            </div>
          </div>
        </div>
        <div className="operaion-row">
          <div onClick={this.next.bind(this, "save_drag")}>保存草稿</div>
          <div
            onClick={this.next.bind(this, "next")}
            className={this.canNext() ? "next-active" : ""}
          >
            下一步
          </div>
        </div>
        <Toast
          toastStatus={this.state.toastStatus}
          msg={this.state.msg}
        ></Toast>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }), //映射状态
  { getUser } //映射操作状态的方法
)(question_survey);
