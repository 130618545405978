
import React, { Component } from 'react'
import './Product_info.css';


let that = ''
class Product_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "Product_info",

        }

    }


    componentDidMount() {
        that = this
    }



    render() {
        return (
            <div className='contain'>
                <p>产品名</p>
                <p>简介</p>
                <p>简介简介简介简介简介简介简介简介简介简介简介简介简介</p>
                <p>详细介绍</p>
                <p>详细介绍详细介绍详细介绍详细介绍详细介绍详细介绍详细介绍详细介绍详细介绍</p>
                <p>Places for adjusting other informations</p>
                <p>Stage1</p>
                <p>Stage1 Stage1 Stage1 Stage1 Stage1 Stage1 Stage1 Stage1 Stage1 Stage1 </p>
                <p>StageX</p>
                <p>StageX StageX StageX StageX StageX StageX StageX StageX StageX StageX </p>
            </div>
        )
    }
}



export default Product_info;