import { theme_color_list } from './Color_config'
export function stackbar_tooltip(sum_arr, isConsumer, showOptionImg, option_obj) {
    let tooltip = {
        trigger: 'item',
        formatter: function (param) {
            let percent = (Number(param.data / sum_arr[param.dataIndex]) * 100).toFixed(2)
            let option_img_str = ""
            let option_img_str_2 = ""
            if (showOptionImg) {
                if (option_obj.question_type === 4) {
                    if (option_obj.option_img[parseInt(param.seriesIndex)]) {
                        option_img_str = "<img src=" + option_obj.option_img[parseInt(param.seriesIndex)] + " style='width: auto;height: 150px;vertical-align: middle;object-fit: cover;'></img></br>"
                    }
                } else if (option_obj.question_type === 8 || option_obj.question_type === 9) {
                    if (option_obj.option_up_img[parseInt(param.seriesIndex)]) {
                        option_img_str = "<img src=" + option_obj.option_up_img[parseInt(param.seriesIndex)] + " style='width: auto;height: 150px;vertical-align: middle;object-fit: cover;'></img></br>"
                    }
                    if (option_obj.option_left_img[parseInt(param.dataIndex)]) {
                        option_img_str_2 = "<img src=" + option_obj.option_left_img[parseInt(param.dataIndex)] + " style='width: auto;height: 120px;vertical-align: middle;object-fit: cover;'></img></br>"
                    }
                }
            }
            var str = ""
            if (isConsumer) {
                str = param.name + "：</br>" + option_img_str_2 +
                    param.marker + param.seriesName + "</br>" + option_img_str +
                    "占比：" + percent + "%";
            } else {
                str = param.name + "：</br>" + option_img_str_2 +
                    param.marker + param.seriesName + "</br>" + option_img_str +
                    "数量：" + param.value + "</br>" +
                    "占比：" + percent + "%";
            }
            return str;
        }
    }
    return tooltip
}

export const stackbar_legend = {
    top: '2%',
    left: 'left',
    icon: 'circle', //设置图例图表形状
    itemWidth: 8,
    itemHeight: 8,
    itemGap: 10,
    width: '80%',
    type: 'scroll',
    pageIconColor: '#cbc9cf',
    pageIconInactiveColor: '#e5e4e7',
    pageIconSize: 12,
    pageTextStyle: {
        color: '#595961',
    },
    textStyle: {
        color: '#595961',
        fontSize: 12
    }
}


export function get_stackbar_xAxis(xAxisList) {
    let xAxis = {
        type: 'category',
        data: xAxisList,
        axisTick: { // 去掉刻度条
            show: false
        },
        axisLine: { //x轴的颜色改变
            lineStyle: {
                color: '#E3E2E5'
            }
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#9797A1', //x轴文本字体颜色改变
                fontSize: 12,
                lineHeight: 14
            },
            interval: 0,
            // rotate: 30
            formatter: function (value, index) {
                let ans = ""
                let maxWordLength = 12  //每个柱子的title最多3个文字
                let barNum = xAxisList.length
                let valueLen = value.length //文字长度
                if (barNum >= 7) {
                    maxWordLength = 2
                } else if (barNum >= 6) {
                    maxWordLength = 3
                } else if (barNum >= 5) {
                    maxWordLength = 4
                } else if (barNum >= 4) {
                    maxWordLength = 5
                } else if (barNum >= 3) {
                    maxWordLength = 6
                }
                let rowNum = Math.ceil(valueLen / maxWordLength)
                if (rowNum >= 4) {
                    let len = 3 * maxWordLength
                    value = value.substring(0, len + 1) + "..";
                }
                if (rowNum > 1) {
                    for (let i = 0; i < rowNum; i++) {
                        let tmp = ""
                        let start = i * maxWordLength
                        let end = start + maxWordLength
                        if (i === rowNum - 1) {
                            tmp = value.substring(start, end)
                        } else {
                            tmp = value.substring(start, end) + "\n"
                        }
                        ans += tmp
                    }
                    return ans
                } else {
                    return value
                }
            }
        },
    }
    return xAxis
}

export function get_stackbar_xAxis_max(xAxisList) {
    let xAxis = {
        type: 'category',
        data: xAxisList,
        axisTick: { // 去掉刻度条
            show: false
        },
        axisLine: { //x轴的颜色改变
            lineStyle: {
                color: '#E3E2E5'
            }
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#9797A1', //x轴文本字体颜色改变
                fontSize: 12,
                lineHeight: 14
            },
            interval: 0,
            // rotate: 30
            formatter: function (value, index) {
                let ans = ""
                let maxWordLength = 12  //每个柱子的title最多3个文字
                let barNum = xAxisList.length
                let valueLen = value.length //文字长度

                if (barNum >= 15) {
                    maxWordLength = 3
                } else if (barNum >= 7) {
                    maxWordLength = 4
                } else if (barNum >= 6) {
                    maxWordLength = 5
                } else if (barNum >= 5) {
                    maxWordLength = 6
                } else if (barNum >= 4) {
                    maxWordLength = 7
                } else if (barNum >= 3) {
                    maxWordLength = 8
                }
                let rowNum = Math.ceil(valueLen / maxWordLength)
                if (rowNum >= 7) {
                    let len = 6 * maxWordLength
                    value = value.substring(0, len + 1) + "..";
                }
                if (rowNum > 1) {
                    for (let i = 0; i < rowNum; i++) {
                        let tmp = ""
                        let start = i * maxWordLength
                        let end = start + maxWordLength
                        if (i === rowNum - 1) {
                            tmp = value.substring(start, end)
                        } else {
                            tmp = value.substring(start, end) + "\n"
                        }
                        ans += tmp
                    }
                    return ans
                } else {
                    return value
                }
            }
        },
    }
    return xAxis
}

export const stackbar_yAxis = [
    {
        type: 'value',
        // name: '数量',
        splitLine: {  // y轴的分割线
            lineStyle: {
                color: '#E3E2E5',
                type: 'dashed'
            }
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#9797A1', //x轴文本字体颜色改变
                fontSize: 12
            }
        }
    }
]


export function getStackbarSeries(series) {
    return series
}

export function getStackbarOption(xAxisList, series, sum_arr, isMaxSreen, isConsumer, showOptionImg, option_obj) {
    let option = {
        grid: {
            left: '50px',
            right: '10px'
        },
        color: theme_color_list,
        tooltip: stackbar_tooltip(sum_arr, isConsumer, showOptionImg, option_obj),
        legend: stackbar_legend,
        xAxis: isMaxSreen === true ? get_stackbar_xAxis_max(xAxisList) : get_stackbar_xAxis(xAxisList),
        yAxis: stackbar_yAxis,
        series: getStackbarSeries(series)
    }
    return option
}



