export const account_type_obj = {
    "1": "基础共创",
    "2": "进阶共创",
    "3": "深度共创",
    "4": "定制化新品服务"
}

export const desired_info_list = [
    { val: "产品派样/用户评测/新品共创", is_active: false },
    { val: "品类发展趋势/市场研究/消费者洞察", is_active: false },
    { val: "小红书等线上平台营销推广及笔记种草", is_active: false },
    { val: "新品0-1全案开发", is_active: false },
    { val: "其他数据咨询服务", is_active: false }
]