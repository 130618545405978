
import React, { Component } from 'react'
import './QuestionnaireTamplate.css';
import cur_icon_close_gre from '../../../../images/cur_icon_close_gre.png'
import cur_icon_select from "../../../../images/cur_icon_select.png";


class QuestionnaireTamplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product_development_text: '', //产品研发阶段
            product_development_val: '',
            product_development_show: false,
            product_development_list: [{ id: 1, text: '选项一' }, { id: 2, text: '选项二' }, { id: 3, text: '选项三' }, { id: 4, text: '选项四' }],
        }

    }


    componentDidMount() {
    }

    handleClick(t, index, event) {
        if (!t) return false
        if (!event) return false
        this.setState({
            [t + '_text']: this.state[t + '_list'][index].text,
            [t + '_val']: this.state[t + '_list'][index].id,
            [t + '_show']: !this.state[t + '_show']
        })
    }

    handle_show(t, event) {
        this.setState({
            [t + '_show']: !this.state[t + '_show']
        })
    }
    // 不显示模版
    handleQuestionTemplate() {
        this.props.handleQuestionTemplate()
    }

    render() {
        return (
            <div className='QuestionnaireTamplate '>
                <div className='QuestionnaireTamplate_contain'>
                    <div className='contain_modules noneTop'>
                        <div className='contain_modules_title update_Flex'>
                            <div className='name'>
                                <span className='text'>导入问卷模版</span>
                            </div>
                            <img src={cur_icon_close_gre} alt="" onClick={this.handleQuestionTemplate.bind(this)} />
                        </div>
                    </div>
                    <div className='tamplate_contain'>
                        <div className='tamplate_item'>
                            <div className='tamplate_left'><span></span>选择大类</div>
                            <div className='tamplate_select'>
                                <input type='text' placeholder='请选择题目标签' readOnly="readonly" className='tamplate_right cursor' defaultValue={this.state.product_development_text} onClick={this.handle_show.bind(this, 'product_development')} />
                                <img src={cur_icon_select} className='cur_icon_select' />
                                <ul className={this.state.product_development_show ? '' : 'isHide'}>
                                    {
                                        this.state.product_development_list.map((item, index) => {
                                            return <li key={'product_development' + item.id} onClick={this.handleClick.bind(this, 'product_development', index)}>{item.text}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='tamplate_item'>
                            <div className='tamplate_left'><span></span>选择小类</div>
                            <div className='tamplate_select'>
                                <input type='text' placeholder='请选择题目标签' readOnly="readonly" className='tamplate_right cursor' defaultValue={this.state.product_development_text} onClick={this.handle_show.bind(this, 'product_development')} />
                                <img src={cur_icon_select} className='cur_icon_select' />
                                <ul className={this.state.product_development_show ? '' : 'isHide'}>
                                    {
                                        this.state.product_development_list.map((item, index) => {
                                            return <li key={'product_development' + item.id} onClick={this.handleClick.bind(this, 'product_development', index)}>{item.text}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='tamplate_item'>
                            <div className='tamplate_left'><span></span>选择分类</div>
                            <div className='tamplate_select'>
                                <input type='text' placeholder='请选择题目分类' readOnly="readonly" className='tamplate_right cursor' defaultValue={this.state.product_development_text} onClick={this.handle_show.bind(this, 'product_development')} />
                                <img src={cur_icon_select} className='cur_icon_select' />
                                <ul className={this.state.product_development_show ? '' : 'isHide'}>
                                    {
                                        this.state.product_development_list.map((item, index) => {
                                            return <li key={'product_development' + item.id} onClick={this.handleClick.bind(this, 'product_development', index)}>{item.text}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='tamplate_item'>
                            <div className='tamplate_left'><span></span>选择题目</div>
                            <div className='tamplate_select'>
                                <input type='text' placeholder='请选择题目' readOnly="readonly" className='tamplate_right cursor' defaultValue={this.state.product_development_text} onClick={this.handle_show.bind(this, 'product_development')} />
                                <img src={cur_icon_select} className='cur_icon_select' />
                                <ul className={this.state.product_development_show ? '' : 'isHide'}>
                                    {
                                        this.state.product_development_list.map((item, index) => {
                                            return <li key={'product_development' + item.id} onClick={this.handleClick.bind(this, 'product_development', index)}>{item.text}</li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='tamplate_btn'>
                            <div onClick={this.handleQuestionTemplate.bind(this)}>取消</div>
                            <div>确认</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



export default QuestionnaireTamplate;