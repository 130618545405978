/* 
    1.该文件是用于创建一个为Count组件服务的reducer，reducer的本质就是一个函数
    2.reducer函数会接到两个参数，分别为：之前的状态(preState)，动作对象(action)
*/
import PriorityQueue from 'priorityqueuejs';

import { ADD_SURVEY_QUEUE_ITEM, EDIT_SURVEY_QUEUE_ITEM, DELETE_SURVEY_QUEUE_ITEM, EDIT_SURVEY_QUEUE, START_POLLING, PRIORITIZE_SURVEY_QUEUE_ITEM } from '../constant'
import { survey_queue, polling_obj } from '../states/survey';



export function surveyReducer(preState = survey_queue, action) {
    //从action对象中获取：type、data
    const { type, data } = action
    // let tmp = JSON.parse(JSON.stringify(preState))
    //根据type决定如何加工数据
    switch (type) {
        case EDIT_SURVEY_QUEUE: // 整个复制
            // data是queue
            let pq_1 = new PriorityQueue((a, b) => {
                return a.priority - b.priority;
            });
            while (!pq_1.isEmpty()) {
                pq_1.deq()
            }
            data.forEach((item, index) => {
                pq_1.enq(item)
            })
            return pq_1._elements
        case ADD_SURVEY_QUEUE_ITEM: //如果是用户信息发生变动
            // data是queue_item
            let pq_2 = new PriorityQueue((a, b) => {
                return a.priority - b.priority;
            });
            let tmp_2 = JSON.parse(JSON.stringify(preState))
            tmp_2.forEach((item, index) => {
                item.priority = tmp_2.length - index
                pq_2.enq(item)
            })
            data["priority"] = tmp_2.length + 1
            pq_2.enq(data) //
            return pq_2._elements
        case EDIT_SURVEY_QUEUE_ITEM: //如果是用户信息单项发生变动 深拷贝
            let pq_3 = new PriorityQueue((a, b) => {
                return a.priority - b.priority;
            });

            let tmp_3 = JSON.parse(JSON.stringify(preState))
            tmp_3.shift()
            tmp_3.unshift(data)
            tmp_3.forEach((item, index) => {
                item.priority = tmp_3.length - index
                pq_3.enq(item)
            })
            return pq_3._elements
        case DELETE_SURVEY_QUEUE_ITEM:
            let pq_4 = new PriorityQueue((a, b) => {
                return a.priority - b.priority;
            });
            let tmp_4 = JSON.parse(JSON.stringify(preState))
            tmp_4.shift() // 删除第一个
            tmp_4.forEach((item, index) => {
                item.priority = tmp_4.length - index
                pq_4.enq(item)
            })
            return pq_4._elements
        case PRIORITIZE_SURVEY_QUEUE_ITEM:
            let pq_5 = new PriorityQueue((a, b) => {
                return a.priority - b.priority;
            });
            let tmp_5 = []
            let has_given_survey = false
            let given_survey = ""
            preState.forEach((item, index) => {
                if (Number(item.survey_id) === Number(data)) {
                    has_given_survey = true
                    given_survey = item
                } else {
                    tmp_5.push(item)
                }
            })
            if(has_given_survey){
                tmp_5.unshift(given_survey)
            }
            tmp_5.forEach((item, index) => {
                item.priority = tmp_5.length - index
                pq_5.enq(item)
            })
            return pq_5._elements
        default:
            return preState
    }
}

export function isPollingReducer(preState = polling_obj, action) {
    //从action对象中获取：type、data
    const { type, data } = action
    //根据type决定如何加工数据
    switch (type) {
        case START_POLLING: // 整个复制
            let tmp = JSON.parse(JSON.stringify(preState))
            tmp.is_polling = data.is_polling
            tmp.poling_index = data.poling_index
            return tmp
        default:
            return preState
    }
}