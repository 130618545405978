import React, { Component } from 'react'
import './incentive_list_popup.scss';
import cur_icon_close_gre from '../../../../images/cur_icon_close_gre.png'
import copy_icon from '../../../../images/project_entry/logistics_info/copy.png'
import Toast from "../../../../components/Toast/Toast";
import { Image, ConfigProvider, message } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { incentiveStatus } from "../util"

export default class incentive_list_popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toastStatus: false,
            msg: '请输入',
            comment: "",
            comment_id: "",
            xhs_incentive_record_list: [],
            isShowNoBtn: false, // 是否显示审核不通过的按钮
        }
    }
    componentDidMount() {
        this.setState({
            comment_id: this.props.comment_id,
            xhs_incentive_record_list: this.props.xhs_incentive_record_list
        })
    }
    tags_close() {
        this.setState({
            comment: ""
        })
        this.props.get_incentive_list_popup_status({
            comment_id: this.state.comment_id,
            comment: this.state.comment,
            status: "",
            incentive_list_popup_status: false
        })
    }
    tags_confirm(status) {
        // 不能进行审核了
        if (this.state.xhs_incentive_record_list.length && !this.canPass.call(this, this.state.xhs_incentive_record_list[this.state.xhs_incentive_record_list.length - 1])) {
            return
        }
        let obj = this.state.xhs_incentive_record_list[this.state.xhs_incentive_record_list.length - 1]
        if (obj.status === 0) {
            this.setState({
                msg: "该用户暂未上传小红书",
                toastStatus: true
            })
            setTimeout(() => {
                this.setState({
                    msg: "该用户暂未上传小红书",
                    toastStatus: false
                })
            }, 2000)
        } else if (this.state.comment !== "") {
            this.setState({
                comment: ""
            })
            this.props.get_incentive_list_popup_status({
                comment_id: this.state.comment_id,
                comment: this.state.comment,
                status: status,
                incentive_list_popup_status: false
            })
        } else {
            if (!this.state.toastStatus) {
                this.setState({
                    msg: "评论不可为空",
                    toastStatus: true
                })
                setTimeout(() => {
                    this.setState({
                        msg: "评论不可为空",
                        toastStatus: false
                    })
                }, 2000)
            }
        }

    }
    handleCommentChange(event) {
        this.setState({
            comment: event.target.value
        })
    }
    // 是否显示审核不通过的按钮
    canShowNoBtn() {
        let canShow = true;
        let len = this.state.xhs_incentive_record_list.length
        if (this.state.xhs_incentive_record_list.length >= 3) {
            let obj_1 = this.state.xhs_incentive_record_list[len - 2]
            let obj_2 = this.state.xhs_incentive_record_list[len - 3]
            if (Number(obj_1.status) === 3 && Number(obj_2.status) === 3) {
                canShow = false
            }
        }
        return canShow
    }
    // 是否可以填写评论
    canComment(item) {
        if (item.status === 3 || item.status === 2 || item.status === 5) {
            return true
        }
        return false
    }
    // 是否能通过
    canPass(item) {
        if (item.status === 3 || item.status === 2 || item.status === 5) {
            return false
        }
        return true
    }
    // 复制链接
    copyUrl(url) {
        let textToCopy = url
        // navigator.clipboard.writeText(textToCopy)
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    message.success('复制成功', 0.7, () => { })
                })
                .catch(() => {
                    message.error('复制成功失败')
                })
        } else {
            // 创建text area
            const textArea = document.createElement('textarea')
            textArea.value = textToCopy
            // 使text area不在viewport，同时设置不可见
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            return new Promise((resolve, reject) => {
                // 执行复制命令并移除文本框
                document.execCommand('copy') ? resolve() : reject(new Error('出错了'))
                textArea.remove()
            }).then(
                () => {
                    message.success('复制成功', 0.7, () => { })
                },
                () => {
                    message.error('复制成功失败')
                }
            )
        }
    }
    // 处理小红书的链接
    handle_xhs_url(xhs_url) {
        let regexp = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g
        let tmp = xhs_url.replace(regexp, function ($url) {
            return "<a href='" + $url + "' target='_blank'>" + $url + "</a>";
        });
        return <div dangerouslySetInnerHTML={{ __html: tmp }}></div>
    }
    render() {
        return (
            <div className='incentive-list-popup'>
                <div className='incentive-list-popup-contain'>
                    <div className='incentive-list-popup-top'>
                        <div className='popup-top-title'>小红书激励审核</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                        </div>
                    </div>
                    <div className='incentive-list-popup-content'>
                        {
                            this.state.xhs_incentive_record_list.map((item, index) => {
                                return <div className='xhs-card-wrapper' key={index}>
                                    {/* {
                                        this.state.xhs_incentive_record_list.length > 1 ? <div className='card-title'>第{index + 1}次审核</div> : ""
                                    } */}
                                    <div className='card-title-wrapper'>
                                        <div className='card-title'>第{index + 1}次审核</div>
                                        <div className="card-subtitle">审核状态:{incentiveStatus[item.status]}</div>
                                    </div>
                                    <div className='card-item-wrapper'>
                                        <div className='card-item-title'>
                                            用户上传图片
                                        </div>
                                        <div className='card-item card-item-images'>
                                            {
                                                item.images.length ? <div >
                                                    {
                                                        item.images.map((image, image_index) => {
                                                            return <ConfigProvider locale={zhCN} key={image_index}>
                                                                <Image src={image} alt="" width={96} height={96} />
                                                            </ConfigProvider>
                                                        })
                                                    }
                                                </div> : <div className='card-item-images-text'>用户暂未上传</div>
                                            }

                                        </div>
                                    </div>
                                    <div className='card-item-wrapper'>
                                        <div className='card-item-title'>
                                            用户上传链接
                                        </div>
                                        <div className='card-item card-item-link-wrapper'>
                                            {
                                                item.xhs_url ?
                                                    <div className='card-item-link'>
                                                        {/* <div>{item.xhs_url}</div> */}
                                                        <div>{this.handle_xhs_url(item.xhs_url)}</div>
                                                        <img src={copy_icon} alt="" className="copy-icon" onClick={this.copyUrl.bind(this, item.xhs_url)} />
                                                    </div> : <div>用户暂未上传</div>
                                            }
                                        </div>
                                    </div>
                                    <div className='card-item-wrapper'>
                                        <div className='card-item-title'>
                                            审核评论
                                        </div>
                                        <div className='card-item'>
                                            <textarea rows={3} placeholder='请输入内容' className={this.canComment.call(this, item) ? 'card-item-textarea-disabled' : ''} type='text' defaultValue={item.comment} onChange={this.handleCommentChange.bind(this)} disabled={this.canComment.call(this, item)} />
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className={this.state.xhs_incentive_record_list.length && this.canPass.call(this, this.state.xhs_incentive_record_list[this.state.xhs_incentive_record_list.length - 1]) ? 'tag-btn' : 'tag-btn-disabled'}>
                        <div onClick={this.tags_confirm.bind(this, 2)}>审核通过</div>
                        {
                            this.canShowNoBtn.call(this) ? <div onClick={this.tags_confirm.bind(this, 3)}>审核不通过</div> : ""
                        }
                    </div>
                    <Toast toastStatus={this.state.toastStatus} msg={this.state.msg}></Toast>
                </div>
            </div>
        )
    }
}
