import React, { Component } from 'react'
import { connect } from 'react-redux'
import '../../css/ant_common/ant_table.scss';
import '../../css/dialog_flag.scss'
import './survey_list.scss'
import icon_add from "../../images/product_list/icon_add.png"
import icon_delete from "../../images/product_list/icon_delete.png"
import icon_search from "../../images/product_list/icon_search.png"
import cur_icon_close from "../../images/cur_icon_close_gre.png"
import { Input, Table, ConfigProvider, Image } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import DownloadPic from './Download_pic/download_pic';
import { getSpecialEventList, deleteSpeicalEvent, updateSpecialEventStatus, updateSurveyAuditProgress, updateSpecialEventFlagCount } from '../../api/Project/project_list_api'
import { getUser } from "../../redux/actions/user"
import XmModel from '../../components/Xm_model/Xm_model'
import Loading from '../../components/Loading/loading'
import CopyPopup from './Copy_popup/copy_popup'
import { message } from 'antd';
import { getQueryParameter } from "../../util/utils";
const { Search } = Input;

let that = ''
class survey_list extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: '项目名称',
                dataIndex: 'survey_name',
                key: 'survey_name',
                width: 130,
                fixed: 'left',
            },
            {
                title: '图片',
                dataIndex: 'survey_img',
                key: 'survey_img',
                width: 100,
                align: 'center',
                render: (text) => {
                    if (text) {
                        return <ConfigProvider locale={zhCN}>
                            <Image src={text} alt="" width={60} height={60} style={{ "objectFit": "cover" }} />
                        </ConfigProvider>
                    } else {
                        return "未传图片"
                    }
                }
            },
            {
                title: '项目描述',
                dataIndex: 'survey_description',
                key: 'survey_description',
                width: 150,
                render: (content, index) => {
                    let html = { __html: content };
                    return <div dangerouslySetInnerHTML={html}></div>;
                }
            },
            {
                title: '目标调研人数',
                dataIndex: '',
                key: 'flag_count',
                width: 120,
                render: (item) => {
                    return <label>
                        <span>{item.flag_count ? item.flag_count : 0}</span>
                        {
                            item.status === 0 ? <span className="btn" onClick={this.onChangeFlagCount.bind(this, item)}>修改</span> : ''
                        }

                    </label>
                }
            },
            {
                title: '累计完成人数',
                dataIndex: '',
                key: 'answered_count',
                width: 120,
                render: (item) => {
                    return item.answered_count ? item.answered_count : 0;
                }
            },
            {
                title: '题目数量',
                dataIndex: '',
                key: 'question_count',
                width: 90,
                render: (item) => {
                    return item.question_count ? item.question_count : 0;
                }
            },
            // {
            //     title: '调研人数',
            //     dataIndex: 'survey_respondent_num',
            //     key: 'survey_respondent_num',
            //     width: 90,
            // },
            {
                title: '分享',
                dataIndex: '',
                key: 'action',
                width: 110,
                render: (item) => {
                    if (item.survey_id === "") {
                        return <span></span>
                    } else {
                        return <label>
                            <span onClick={this.handleQrcode.bind(this, item)}>下载二维码</span>
                        </label >
                    }
                }
            },
            {
                title: '状态',
                dataIndex: '',
                key: 'audit_progress',
                width: 120,
                align: 'center',
                render: (item) => {
                    if (item.audit_progress === 0) {
                        return <span>未审核</span>
                    }
                    if (item.audit_progress === 1) {
                        return <span>待审核</span>
                    }
                    if (item.audit_progress === 2) {
                        return <span>审核通过</span>
                    }
                    if (item.audit_progress === 3) {
                        return <span>审核未通过</span>
                    }
                    if (item.audit_progress === 4) {
                        return <span>发布</span>
                    }
                }
            },
            {
                title: '上下架',
                dataIndex: '',
                key: 'status',
                width: 100,
                render: (item) => {
                    if (item.status === 0) {
                        return <span>下架</span>
                    } else {
                        return <span>上架</span>
                    }
                }
            },
            {
                title: '平均回答时长',
                dataIndex: 'duration',
                key: 'duration',
                width: 120,
                align: 'center',
            },
            {
                title: '开始时间',
                dataIndex: 'start_time',
                key: 'start_time',
                width: 110,
                align: 'center',
            },
            {
                title: '结束时间',
                dataIndex: 'end_time',
                key: 'end_time',
                width: 110,
                align: 'center',
            },
            {
                title: '操作',
                dataIndex: '',
                key: 'action',
                width: 190,
                fixed: 'right',
                render: (item) => {
                    if (item.audit_progress === 0) {
                        return <label>
                            <span style={{ color: 'var(--audit-color)' }} onClick={this.onExamine.bind(this, item, 1)}>审核</span>
                            <span style={{ color: 'var(--modify-color)' }} onClick={this.handleRevise.bind(this, item)} >修改</span>
                            <span style={{ color: 'var(--detail-color)' }} onClick={this.handleDetail.bind(this, item)}>详情</span>
                            <span style={{ color: 'var(--import-color)' }} onClick={this.handleCopy.bind(this, item)}>导入</span>
                        </label >
                    } else if (item.audit_progress === 2) {
                        return <label>
                            <span style={{ color: 'var(--publish-color)' }} onClick={this.onExamine.bind(this, item, 4)}>发布</span>
                            <span style={{ color: 'var(--modify-color)' }} onClick={this.handleRevise.bind(this, item)}>修改</span>
                            <span style={{ color: 'var(--detail-color)' }} onClick={this.handleDetail.bind(this, item)}>详情</span>
                            <span style={{ color: 'var(--import-color)' }} onClick={this.handleCopy.bind(this, item)}>导入</span>
                        </label >
                    } else if (item.status === 2) {
                        return <label>
                            <span style={{ color: 'var(--remove-color)' }} onClick={this.updateSpecialEventStatus.bind(this, item)}>下架</span>
                            <span style={{ color: 'var(--detail-color)' }} onClick={this.handleDetail.bind(this, item)}>详情</span>
                            <span style={{ color: 'var(--import-color)' }} onClick={this.handleCopy.bind(this, item)}>导入</span>
                        </label >
                    } else {
                        return <label>
                            <span style={{ color: 'var(--modify-color)' }} onClick={this.handleRevise.bind(this, item)}>修改</span>
                            <span style={{ color: 'var(--detail-color)' }} onClick={this.handleDetail.bind(this, item)}>详情</span>
                            <span style={{ color: 'var(--import-color)' }} onClick={this.handleCopy.bind(this, item)}>导入</span>
                        </label >
                    }
                }
            },
        ]
        this.state = {
            brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : 1,   //品牌ID
            project_list: [],//产品列表
            selectedRowKeys: [],
            showQrcodeModal: false,
            modelContent: "确定删除该调研计划吗？",
            showModel: false,
            modelTitle: "删除提示",
            qr_project_id: "",
            qr_project_name: "",
            total_page: 1, // 总共多少页
            total_number: 0, // 总共多少条
            current_page: 1, //当前页
            loading: 0, // 显示加载
            search_name: null, //是否有
            dialogFlag: false, //显示弹窗
            editFlagCount: {},
            newFlagCount: 0,
            showCopyPopup: false, // 是否显示导入弹窗
            to_survey_id: "", // 导入的问卷id
        }

    }
    componentDidMount() {
        that = this
        let value = getQueryParameter('s');
        this.setState({
            search_name: value,
            current_page: 1
        }, () => {
            this.getSpecialEventList();
        })

    }
    async getSpecialEventList() {
        this.setState({
            loading: 0
        })
        let brand_id = this.state.brand_id
        getSpecialEventList({
            brand_id: parseInt(brand_id),
            page: this.state.current_page,
            count: 10,
            project_name: this.state.search_name,
            type: 2
        }).then((res) => {
            if (res.status === 200) {
                this.setState({
                    project_list: res.data.project_list,
                    total_page: res.data.total_page,
                    total_number: res.data.total_number,
                    loading: 1
                })

            } else if (res.status === 204) {
                this.setState({
                    project_list: [],
                    total_page: 1,
                    total_number: 0,
                    loading: 1
                })
            }
        }).catch((err) => {
            this.setState({
                loading: 1
            })
            message.error('加载失败!')
        })
    }
    onSelectChange = (selectedRowKeys, record) => {
        this.setState({ selectedRowKeys, record });
    };
    //修改跳转
    handleRevise(item) {
        let project_type = 2
        localStorage.setItem('project_id', item.project_id)
        localStorage.setItem('special_event_id', item.special_event_id)
        localStorage.setItem('current_project_type', project_type)
        localStorage.setItem('current_project_progress', item.current_progress) // 当前的问卷进度
        // localStorage.setItem('expected_project_quota', item.flag_count || 0) //期望目标派送数量
        localStorage.setItem('finished_project_quota', item.answered_count || 0) //已完成派送数量
        if (item.survey_id) {
            this.props.history.push(`/survey_list/project_entry?survey_id=${item.survey_id}&project_type=${project_type}`)
        } else {
            this.props.history.push(`/survey_list/project_entry?project_type=${project_type}`)
        }
    }
    //查看详情
    handleDetail(item) {
        if (item.survey_id === "") {
            message.warning('暂无详情', 1, () => { })
        } else {
            this.props.history.push(`/brand_survey?survey_id=${item.survey_id}`)
        }
    }
    // 导入
    handleCopy(item) {
        this.setState({
            showCopyPopup: true,
            to_survey_id: item.survey_id
        })
    }
    // 下载二维码
    handleQrcode(item) {
        this.setState({
            qr_project_id: item.special_event_id,
            qr_project_name: item.survey_name
        })
        this.handleShowQrcode()
    }
    //显示二维码弹窗
    handleShowQrcode() {
        this.setState({ showQrcodeModal: !this.state.showQrcodeModal })
    }
    // 第一个参数是设置是否显示，第二个参数就是子组件选确认
    handleModel(val, flag) {
        let project_id_list = this.state.selectedRowKeys.map(Number)
        if (!project_id_list.length) {
            message.warning('删除不可为空', 0.4, () => {
            })
            return
        }
        if (flag) {
            this.deleteSpeicalEvent()
        }
        this.setState({
            showModel: val,
        })
    }
    //搜索
    onSearch(e) {
        that.searchProject(e)
    };
    //搜索接口
    async searchProject(value) {
        this.props.history.push(`/survey_list?s=${value}`)
        this.setState({
            search_name: value,
            current_page: 1
        }, () => {
            this.getSpecialEventList();
        })
    }
    //删除
    async deleteSpeicalEvent() {
        let brand_id = this.state.brand_id
        let project_id_list = this.state.selectedRowKeys.map(Number)
        let tmpList = this.state.project_list.filter((item) => {
            return !project_id_list.includes(parseInt(item.special_event_id))
        })
        deleteSpeicalEvent({
            brand_id: parseInt(brand_id),
            special_event_id_list: project_id_list
        }).then((res) => {
            if (res.status === 200 || res.status === 202) {
                message.success('删除成功', 0.2, () => {
                    this.setState({
                        project_list: tmpList
                    }, () => {
                        this.getSpecialEventList()
                    })
                })

            }
        }).catch((err) => {
            message.error('删除失败!')
        })
    }
    //添加产品
    addProject() {
        this.props.history.push(`/survey_list/project_entry`)
        localStorage.removeItem('project_id')
    }
    //对表格的行进行操作
    handleTableRow(record, index) {
        return {
            onMouseEnter: (event) => { // 鼠标移入
            },
            onMouseLeave: (event) => {// 鼠标移出
            }
        }
    }
    // 操作分页
    handlePage(page, pageSize) {
        this.setState({
            current_page: page
        }, () => {
            this.getSpecialEventList()
        });
    }
    // 审核
    onExamine(item, audit_progress) {
        let param = {
            id: item.special_event_id,
            type: 1,
            audit_progress: audit_progress
        }
        this.setState({ loading: 1 })
        updateSurveyAuditProgress(param).then(res => {
            this.getSpecialEventList();
        }, err => {
            this.getSpecialEventList();
        })
    }
    // 调研下架
    updateSpecialEventStatus(item) {
        updateSpecialEventStatus({ id: item.special_event_id }).then(res => {
            this.getSpecialEventList();
        }, err => {
            this.getSpecialEventList();
        })
    }
    // 修改目标
    onChangeFlagCount(item) {
        this.setState({ dialogFlag: true, editFlagCount: item })
    }
    updateFlagCount() {
        let param = {
            id: this.state.editFlagCount.special_event_id,
            flag_count: Number(this.state.newFlagCount),
            type: 0,
        }
        this.setState({ loading: 1 });
        updateSpecialEventFlagCount(param).then(res => {
            this.props.getUser()
            this.getSpecialEventList();
            this.setState({ dialogFlag: false });
        }, err => {
            message.error('修改失败，后台发生错误')
        })
    }
    // 关闭弹窗
    onClose() {
        this.setState({ dialogFlag: false })
    }
    // 是否显示导入框
    canShowCopyPopup(object) {
        this.setState({
            showCopyPopup: object.showCopyPopup
        })
    }
    render() {
        const prefix = (
            <img src={icon_search} alt="" className='evaluation-icon-search' />
        )
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className='container survey-list-container-bg'>
                {
                    this.state.loading === 0 ? <Loading></Loading> : ""
                }
                <div className='survey-list-container'>
                    <div className={this.state.showQrcodeModal ? '' : 'isHide'}>
                        <DownloadPic qr_project_id={this.state.qr_project_id}
                            qr_project_name={this.state.qr_project_name}
                            handleShowQrcode={this.handleShowQrcode.bind(this)}></DownloadPic>
                    </div>
                    <div className='survey-entry-top'>
                        <div className='top-title'>项目-调研列表</div>
                    </div>
                    <div className="survey-operaion">
                        <div className="survey-operaion-left">
                            <Search
                                placeholder="请输入商品名称"
                                allowClear
                                enterButton="查询"
                                prefix={prefix}
                                onSearch={this.onSearch}
                            />
                        </div>
                        <div className="survey-operaion-right">
                            <div className="survey-operaion-button" onClick={this.handleModel.bind(this, true, false)}>
                                <img src={icon_delete} alt="" />
                                <span>删除</span>
                            </div>
                            <div className="survey-operaion-button" onClick={this.addProject.bind(this)}>
                                <img src={icon_add} alt="" />
                                <span>添加计划</span>
                            </div>
                        </div>

                    </div>
                    <div className='survey-list-main'>
                        <div className='survey-list-table'>
                            <ConfigProvider locale={zhCN}>
                                {/* <Table rowSelection={rowSelection} dataSource={this.state.project_list} columns={this.columns} pagination={{ pageSize: 10, showSizeChanger: false, showQuickJumper: true }} scroll={{ x: 900 }} rowKey={columns => columns.project_id} onRow={(record, index) => this.handleTableRow.call(this, record, index)} /> */}
                                <Table rowSelection={rowSelection} dataSource={this.state.project_list} columns={this.columns}
                                    pagination={{
                                        current: this.state.current_page,
                                        pageSize: 10, total: this.state.total_number,
                                        showSizeChanger: false, showQuickJumper: true,
                                        onChange: (page, pageSize) => {
                                            this.handlePage(page, pageSize)
                                        }
                                    }} scroll={{ x: 900 }} rowKey={columns => columns.special_event_id} onRow={(record, index) => this.handleTableRow.call(this, record, index)} />
                            </ConfigProvider>
                            <div className="table-sum-wrapper">共<span>{this.state.total_number}</span>个结果，当前展示1-10行</div>
                        </div>
                    </div>
                </div>
                <div className='hello'>
                    <XmModel isShowModel={this.state.showModel} handleShowModel={this.handleModel.bind(this)} modelContent={this.state.modelContent} modelTitle={this.state.modelTitle}></XmModel>
                </div>
                {
                    this.state.dialogFlag ?
                        <div className="dialog_flag">
                            <div className="dialog_main">
                                <div className="dialog_main_title">
                                    <span>修改目标数量</span>
                                    <div onClick={this.onClose.bind(this)}>
                                        <img src={cur_icon_close} alt="" />
                                    </div>
                                </div>
                                <div className="dialog_main_content">
                                    <div className="item">
                                        <span>当前目标：</span>{this.state.editFlagCount.flag_count}
                                    </div>
                                    <div className="item">
                                        <span>新设目标：</span>
                                        <input type="number" defaultValue={this.state.editFlagCount.flag_count} onInput={(e) => { this.setState({ newFlagCount: e.target.value }) }} />
                                    </div>
                                </div>
                                <div className="dialog_main_foot">
                                    <button onClick={this.onClose.bind(this)}>取消</button>
                                    <button onClick={this.updateFlagCount.bind(this)}>确定</button>
                                </div>
                            </div>
                        </div>
                        : ''
                }
                {
                    this.state.showCopyPopup ?
                        <CopyPopup
                            to_survey_id={this.state.to_survey_id}
                            canShowCopyPopup={this.canShowCopyPopup.bind(this)}
                        ></CopyPopup> : ''
                }
            </div>
        )
    }
}

export default connect(
    state => ({
        user: state.user,
    }),//映射状态
    { getUser }//映射操作状态的方法
)(survey_list)