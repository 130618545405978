export function getBarTooltip(sum, isConsumer, showOptionImg, option_img, option_obj) {
    let tooltip = {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        },
        position: function (pos, params, dom, rect, size) {
            // 鼠标在左侧时 tooltip 显示到右侧，鼠标在右侧时 tooltip 显示到左侧。
            var obj = { top: 0 };
            let tmp1 = Math.abs(parseInt((size.viewSize[0] / 2 - pos[0]) / 2))
            obj[['right', 'left'][+(pos[0] < size.viewSize[0] / 2)]] = `${tmp1}`;
            let tmp2 = parseInt(pos[1])
            obj['top'] = `${tmp2}`;
            return obj;
        },
        formatter: function (params) {
            localStorage.setItem('Xindex', JSON.stringify(params))
            let param = params[0]
            let name = ""
            let maxNameLength = 10
            let rowNum = Math.ceil(param.name.length / maxNameLength)
            for (let i = 0; i < rowNum; i++) {
                let tmp = ""
                let start = i * maxNameLength
                let end = start + maxNameLength
                if (i === rowNum - 1) {
                    tmp = param.name.substring(start, end)
                } else {
                    tmp = param.name.substring(start, end) + "</br>"
                }
                name += tmp
            }
            let percent = (Number(param.data / sum) * 100).toFixed(2)

            let option_index = parseInt(params[0].dataIndex)
            let option_img_str = ""
            if (showOptionImg && option_img[option_index]) {
                option_img_str = "<img src=" + option_img[option_index] + " style='width: auto;height: 150px;vertical-align: middle;object-fit: cover;'></img>"
            }
            var str = param.marker + "" + name + "</br>"
            if (option_img_str) {
                str = str + option_img_str + "</br>"
            }
            if (option_obj && option_obj.question_type === 19) {
                let { occurence_number_list } = option_obj
                str = str +
                    "偏好份额：" + (Number(param.data) * 100).toFixed(1) + "%</br>" +
                    "最重要：" + occurence_number_list[option_index].option_max_selected_count + "</br>" +
                    "最不重要：" + occurence_number_list[option_index].option_min_selected_count + "</br>" +
                    "出现次数：" + occurence_number_list[option_index].option_occurance + "</br>";
                return str;
            }
            if (isConsumer) {
                str = str +
                    "占比：" + percent + "%</br>";
            } else {
                str = str +
                    "数量：" + param.data + "</br>" +
                    "占比：" + percent + "%</br>";
            }

            return str;
        }
    }
    return tooltip
}

export function get_bar_yAxis(option_obj) {
    let bar_yAxis = {
        type: 'value',
        splitLine: {  // y轴的分割线
            lineStyle: {
                color: '#E3E2E5',
                type: 'dashed'
            }
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#9797A1', //x轴文本字体颜色改变
                fontSize: 12
            },
            formatter: function (val) {
                if (option_obj && option_obj.question_type === 19) {
                    return val * 100 + '%';
                }
                return val
            }
        }
    }
    return bar_yAxis
}

export function get_bar_xAxis(xAxisList) {
    let xAxis = {
        type: 'category',
        axisTick: { // 去掉刻度条
            show: false
        },
        axisLine: { //x轴的颜色改变
            lineStyle: {
                color: '#E3E2E5'
            }
        },
        axisLabel: { //x轴的标题
            show: true,
            textStyle: {
                color: '#9797A1', //x轴文本字体颜色改变
                fontSize: 12,
                lineHeight: 14
            },
            interval: 0,
            // rotate: 30
            // formatter: function (value, index) {
            //   if (index % 2 !== 0) {
            //     return '\n\n' + value + '1';
            //   }
            //   else {
            //     return value;
            //   }
            // }
            formatter: function (value, index) {
                let ans = ""
                let maxWordLength = 12  //每个柱子的title最多3个文字
                let barNum = xAxisList.length
                let valueLen = value.length //文字长度

                let num_reg = /[0-9]/g
                let tmp = Number(value[0])
                let hasNum = false
                if (num_reg.test(tmp)) {
                    hasNum = true
                }
                if (barNum >= 15) {
                    maxWordLength = 1
                } else if (barNum >= 7) {
                    maxWordLength = 2
                } else if (barNum >= 6) {
                    maxWordLength = 3
                } else if (barNum >= 5) {
                    if (hasNum) {
                        maxWordLength = 5
                    } else {
                        maxWordLength = 4
                    }
                } else if (barNum >= 4) {
                    maxWordLength = 5
                } else if (barNum >= 3) {
                    maxWordLength = 6
                }
                let rowNum = Math.ceil(valueLen / maxWordLength)
                if (rowNum >= 4) {
                    let len = 3 * maxWordLength
                    value = value.substring(0, len + 1) + "..";
                }
                if (rowNum > 1) {
                    for (let i = 0; i < rowNum; i++) {
                        let tmp = ""
                        let start = i * maxWordLength
                        let end = start + maxWordLength
                        if (i === rowNum - 1) {
                            tmp = value.substring(start, end)
                        } else {
                            tmp = value.substring(start, end) + "\n"
                        }
                        ans += tmp
                    }
                    return ans
                } else {
                    return value
                }
            }
        },
        data: xAxisList
    }
    return xAxis
}
export function get_bar_xAxis_max(xAxisList) {
    let xAxis = {
        type: 'category',
        axisTick: { // 去掉刻度条
            show: false
        },
        axisLine: { //x轴的颜色改变
            lineStyle: {
                color: '#E3E2E5'
            }
        },
        axisLabel: { //x轴的标题
            show: true,
            textStyle: {
                color: '#9797A1', //x轴文本字体颜色改变
                fontSize: 12,
                lineHeight: 14
            },
            interval: 0,
            formatter: function (value, index) {
                let ans = ""
                let maxWordLength = 12  //每个柱子的title最多3个文字
                let barNum = xAxisList.length
                let valueLen = value.length //文字长度

                let num_reg = /[0-9]/g
                let tmp = Number(value[0])
                let hasNum = false
                if (num_reg.test(tmp)) {
                    hasNum = true
                }
                if (barNum >= 15) {
                    maxWordLength = 3
                } else if (barNum >= 7) {
                    maxWordLength = 4
                } else if (barNum >= 6) {
                    maxWordLength = 5
                } else if (barNum >= 5) {
                    if (hasNum) {
                        maxWordLength = 7
                    } else {
                        maxWordLength = 6
                    }
                } else if (barNum >= 4) {
                    maxWordLength = 7
                } else if (barNum >= 3) {
                    maxWordLength = 8
                }
                let rowNum = Math.ceil(valueLen / maxWordLength)
                if (rowNum >= 7) {
                    let len = 6 * maxWordLength
                    value = value.substring(0, len + 1) + "..";
                }
                if (rowNum > 1) {
                    for (let i = 0; i < rowNum; i++) {
                        let tmp = ""
                        let start = i * maxWordLength
                        let end = start + maxWordLength
                        if (i === rowNum - 1) {
                            tmp = value.substring(start, end)
                        } else {
                            tmp = value.substring(start, end) + "\n"
                        }
                        ans += tmp
                    }
                    return ans
                } else {
                    return value
                }
            }
        },
        data: xAxisList
    }
    return xAxis
}

export function getBarSeries(data, sum, option_obj) {
    let series = [
        {
            data: data,
            type: 'bar',
            barWidth: '50%', //柱子宽度
            barMaxWidth: 50,
            label: {
                show: true,
                position: 'top',
                formatter: function (param) {
                    if (option_obj && option_obj.question_type === 19) {
                        // if (Number(param.data) === -1) {
                        //     return 
                        // }
                        let percent = (Number(param.data) * 100).toFixed(1)
                        let str = percent + "%";
                        return str;
                    }
                    let percent = (Number(param.data / sum) * 100).toFixed(2)
                    let str = percent + "%";
                    return str;
                },
                textStyle: {
                    color: '#9797A1', //x轴文本字体颜色改变
                    fontSize: 12
                }
            }
        }
    ]
    return series
}

export function getBarOption(xAxisList, data, sum, isConsumer, isMaxSreen, showOptionImg, option_img, option_obj) {
    let option = {
        grid: {
            left: '50px',
            right: '10px',
            bottom: isMaxSreen === true ? '120px' : '80px'
        },
        color: '#FF5186',
        tooltip: getBarTooltip(sum, isConsumer, showOptionImg, option_img, option_obj),
        xAxis: isMaxSreen === true ? get_bar_xAxis_max(xAxisList) : get_bar_xAxis(xAxisList),
        yAxis: get_bar_yAxis(option_obj),
        series: getBarSeries(data, sum, option_obj)
    }
    return option
}