import React, { Component } from 'react'
import './nps_template_info.scss';

export default class nps_template_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'nps_info',               //从哪个页面进入的
            dataList: {},
            min_value: 0,
            max_value: 10,
            min_name: "",
            max_name: "",
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                min_value: parseInt(props.dataList.min_value),
                max_value: parseInt(props.dataList.max_value) || state.max_value,
                min_name: props.dataList.min_name || state.min_name,
                max_name: props.dataList.max_name || state.max_name,
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    render() {
        let items = [];
        for (let i = 1; i <= this.state.max_value; i++) {
            items.push(<span key={i}>{i}</span>);
        }
        return (
            <div className="nps-template-info">
                <div className='draw_slip'>
                    <div className='draw_slip_top'>
                        <div className='draw_slip_line'>
                            <span></span>
                            {/* <span className={`draw_slip_line_current_${this.state.question_index}`}></span> */}
                        </div>
                        <span className='draw_slip_num'>0</span>
                    </div>
                    <div className={this.state.max_value < 10 ? "draw_slip_index" : "isHide"}>
                        {
                            items
                        }
                    </div>
                    <div className={this.state.max_value === 10 ? "draw_slip_index" : "isHide"}>
                        <span>1</span>
                        <span>2</span>
                        <span>4</span>
                        <span>6</span>
                        <span>8</span>
                        <span>10</span>
                    </div>
                    <div className={this.state.max_value > 10 ? "draw_slip_index" : "isHide"}>
                        <span>1</span>
                        <span>20</span>
                        <span>40</span>
                        <span>60</span>
                        <span>80</span>
                        <span>100</span>
                    </div>
                    <div className='draw-slip-foot'>
                        <div className='draw-slip-foot-name'>{this.state.min_name}</div>
                        <div className='draw-slip-foot-name'>{this.state.max_name}</div>
                    </div>
                </div>
            </div>
        )
    }
}
