import React, { Component } from 'react'
import './blank_info.scss'

export default class blank_info extends Component {
    render() {
        return (
            <div className="blank-info-container">
                <input className='blank-info-input' disabled="disabled" />
            </div>
        )
    }
}
