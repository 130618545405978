import React, { Component } from 'react'
import './question_ai.scss';
import { connect } from 'react-redux'
import { generateAISurvey, getCeleryProgressView, getAISurveyList } from "../../../../api/Questionnaire_setting/question_template_api";
import QuestionTemplatePanel from '../Question_template_panel/question_template_panel';
import { addSurveyQueueItem, setSurveyQueueToBackEnd } from "../../../../redux/actions/survey"
import { Input, Button, Form, message, Collapse } from 'antd';
import QuestionAiList from './Question_ai_list/question_ai_list';
import QuestionAiGeneration from './Question_ai_generation/question_ai_generation';
import Loading from "../../../Loading/loading";
// import PriorityQueue from 'priorityqueuejs';
const { Panel } = Collapse;
let that = ""
class question_ai extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_ai_list: 0, // 获取getAISurveyList
            loadingText: "加载中",
            needRefresh: true,
            current_question_template_list: [],
            ai_survey_list: [], // AI问卷列表
            test_prompt: "",
            current_uid: "",
            survey_placeholder_id: "",
            question_ai_tab_list: [
                { name: "AI生成", is_show: true },
                { name: "AI问卷列表", is_show: false },
            ],
            end_interval: false
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.project_entry_number) !== JSON.stringify(state.project_entry_number_prev)) {
            return {
                survey_id: props.survey_id,
                // needRefresh: true,
                project_entry_number: props.project_entry_number || 0, //question
                project_entry_number_prev: props.project_entry_number,
            }
        }
        return null;
    }
    componentDidMount() {
        that = this
        this._isMounted = true
        // this.init()
    }
    componentDidUpdate(preProps, preState) {
        if (this._isMounted && this.state.needRefresh) {
            if (this.state.project_entry_number === 3 && this.props.survey_id) {
                this.init()
                this.setState({
                    needRefresh: false
                })
            }
        }
    }
    componentWillUnmount() {
        console.log('Component WILL UNMOUNT!')
        this.setState = () => { }
    }
    init() {
        // this.generateAISurvey()
        // this.getCeleryProgressView()
        this.getAISurveyList()
    }
    // AI生成问卷
    async generateAISurvey(prompt, interests) {
        let res = await generateAISurvey({
            // survey_id: 996,
            survey_id: this.props.survey_id,
            // prompt: "帮我生成一套关于名侦探柯南的问卷"
            prompt: prompt,
            interests: interests
        })
        let curTime = new Date()
        let queue_item = {
            prompt: prompt,
            // survey_id: 996,
            survey_id: this.props.survey_id,
            uid: res.data.uid,
            status: -1,
            val: prompt,
            create_time: curTime.getTime(),
            finish_time: new Date(curTime.setSeconds(curTime.getSeconds() + 180)).getTime()
        }

        this.props.addSurveyQueueItem(queue_item)
        this.setState({
            end_interval: false,
            current_uid: res.data.uid,
            survey_placeholder_id: res.data.survey_placeholder_id
        }, () => {
            clearInterval(that.surveyInterval)
            this.surveyInterval = setInterval(that.getSurvey, 2000)
        })
    }
    // 当前获取survey
    async getSurvey() {
        if (!that.props.survey_id) {
            clearInterval(that.surveyInterval)
        }
        let survey_queue = that.props.survey_queue || []
        let flag = false
        survey_queue.forEach((item, index) => {
            if (item.uid === that.state.current_uid) {
                flag = true
            }
        })
        if (survey_queue.length === 0) {
            flag = false
        }
        if (flag === false && that.state.end_interval === false) {
            clearInterval(that.surveyInterval)
            // that.props.setSurveyQueueToBackEnd(that.props.survey_queue)
            that.getAISurveyList()
        }
    }
    // 获取AI问卷列表
    async getAISurveyList() {
        this.setState({
            loading_ai_list: 1
        })
        try {
            const response = await getAISurveyList({
                // survey_id: 996
                survey_id: this.props.survey_id,
            })
            this.setState({
                loading_ai_list: 0
            })
            clearInterval(that.surveyInterval)
            if (!response) return
            if (parseInt(response.status) === 204) {
                // message.warning('暂无以往AI生成数据', 2, () => { })
                that.setState({
                    end_interval: true,
                    current_question_template_list: [],
                    ai_survey_list: [],
                })
            } else if (response.data.survey_list.length === 0) { // 无数据
                // message.error('AI问卷执行失败')
                that.setState({
                    end_interval: true,
                    current_question_template_list: [],
                    ai_survey_list: [],
                })
            } else {
                let tmp_len = response.data.survey_list.length
                let survey_list = response.data.survey_list
                if (response.data.survey_list[tmp_len - 1].survey_placeholder_id === that.state.survey_placeholder_id) {
                    message.success("当前问卷执行成功")
                    let tmp_question_list = response.data.survey_list[tmp_len - 1].question_list
                    that.setState({
                        end_interval: true,
                        current_question_template_list: tmp_question_list,
                        ai_survey_list: survey_list,
                        // ai_survey_list: response.data.survey_list
                    })
                } else {
                    // message.error('AI问卷执行失败')
                    that.setState({
                        end_interval: true,
                        current_question_template_list: [],
                        ai_survey_list: response.data.survey_list
                    })
                }
            }
            return response
        } catch (error) {
            this.setState({
                loading_ai_list: 0
            })
            message.error('获取AI问卷列表失败')
            clearInterval(that.surveyInterval)
            throw error;
        }
    }
    // 获取异步任务执行进度
    async getCeleryProgressView() {
        let res = await getCeleryProgressView({
            uid: "855f9e1a-186d-400c-84ae-c3dd0e7c5a1d",
        })
    }
    // 传给父亲
    addTemplateQuestion(item) {
        this.props.addTemplateQuestion(item)
    }
    // 测试输入框
    handleTestInput(event) {
        this.setState({
            test_prompt: event.target.value
        }, () => {
            this.generateAISurvey(event)
        })
    }
    // 测试
    test(user) {
    }
    handleQuestionAiTab(index) {
        let tmp_list = this.state.question_ai_tab_list
        tmp_list.forEach((item, i) => {
            if (i === index) {
                item.is_show = true
            } else {
                item.is_show = false
            }
        })
        this.setState({
            question_ai_tab_list: tmp_list
        })
    }
    // 子传父 生产新的ai问卷
    generateAiQuestion(prompt, interests) {
        this.setState({ current_question_template_list: [] })
        this.generateAISurvey(prompt, interests)
    }
    render() {
        return (
            <div className='question-ai-container'>
                {
                    this.state.loading_ai_list <= 0 ? "" : <Loading loadingText={this.state.loadingText}></Loading>
                }
                <div className='question-ai-tab-panel'>
                    {
                        this.state.question_ai_tab_list.map((item, index) => {
                            return <div className={`question-ai-tab ${item.is_show ? 'question-ai-tab-active' : ''}`} key={index} onClick={this.handleQuestionAiTab.bind(this, index)}>{item.name}</div>
                        })
                    }
                </div>
                <div className={this.state.question_ai_tab_list[0].is_show ? "" : "isHide"}>
                    <QuestionAiGeneration
                        survey_id={this.props.survey_id}
                        ai_selected_survey_option={this.props.ai_selected_survey_option}
                        project_entry_number={this.props.project_entry_number}
                        current_question_template_list={this.state.current_question_template_list}
                        generateAiQuestion={this.generateAiQuestion.bind(this)}
                        addTemplateQuestion={this.addTemplateQuestion.bind(this)}
                    ></QuestionAiGeneration>
                </div>
                <div className={this.state.question_ai_tab_list[1].is_show ? "" : "isHide"}>
                    <QuestionAiList
                        ai_survey_list={this.state.ai_survey_list}
                        addTemplateQuestion={this.addTemplateQuestion.bind(this)}
                        getAISurveyList={this.getAISurveyList.bind(this)}
                    ></QuestionAiList>
                </div>
                {/* <input placeholder='请输入仅仅供开发先测试（失去焦点触发）' className='test-input' type='text' onBlur={this.handleTestInput.bind(this)} />
                <div onClick={this.test.bind(this, this.props.user)}>mmmmmmppppppppppp</div>
                <QuestionTemplatePanel
                    question_template_list={this.state.current_question_template_list}
                    addTemplateQuestion={this.addTemplateQuestion.bind(this)}
                ></QuestionTemplatePanel> */}

            </div >
        )
    }
}

export default connect(
    state => ({
        survey_queue: state.surveyReducer,
    }),//映射状态
    { addSurveyQueueItem, setSurveyQueueToBackEnd }//映射操作状态的方法
)(question_ai)