import React, { Component } from "react";
import "./updata_notice.scss";
import back_btn_icon from "../../../images/home_page/about_us/back_btn_icon.png";
import download_icon from "../../../images/overall_data/download_icon.png";

export default class updata_notice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      upload_time: "",
      location: "",
      type: ''
    };
  }
  setUpdataNotice(obj,type) {
    let { name, descriptionCopy, upload_time, location } = obj;
    console.log(type);
    this.setState({
      name: name,
      description: descriptionCopy,
      upload_time: upload_time,
      location: location,
      type,
    });
  }
  clickBackBtn() {
    this.props.canShowUpdataNotice();
  }
  clickLookBtn() {
    console.log(this.state.location);
    window.open(this.state.location, "_blank");
  }

  render() {
    return (
      <div className="download-brief-container">
        <div className="download-brief-content">
          <div className="download-title-wrapper">
            <div className="title">{this.state.type==1?'更新公告':'常见问题'}</div>
            <div className="btn-wrapper">
              {this.state.location ? (
                <div className="btn" onClick={this.clickLookBtn.bind(this)}>
                  <img src={download_icon} alt="" />
                  <span>查看</span>
                </div>
              ) : (
                ""
              )}

              <div className="btn" onClick={this.clickBackBtn.bind(this)}>
                <img src={back_btn_icon} alt="" />
                <span>返回</span>
              </div>
            </div>
          </div>
          <div className="download-brief-wrapper">
            <div className="name">{this.state.name}</div>
            <div className="time">{this.state.upload_time}</div>
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: this.state.description }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}
