import React, { Component } from "react";
import "./Project_entry.scss";
import BasicInformation from "./Basic_info/basic_information";
import project_icon_next from "../../images/project_icon_next.png";
import TagDispatch from "../../components/Project_entry/TagDispatch/TagDispatch";
import QuestionnaireSetting from "../../components/Project_entry/QuestionnaireSetting/QuestionnaireSetting";
import LogisticsInfo from "../Project_entry/Logistics_info/logistics_info";
// import LogisticsInfo from '../../components/Project_entry/Logistics_info/logistics_info';
import { getQueryParameter } from "../../util/utils";
let that = "";
class Project_entry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Project_entry",
      project_entry_number: 1, //第几步    第4步代表:逻辑设置
      dataList: [],
      survey_id: "",
      project_id: "",
      hasLogisticsStep: false,
      dispatch_count: "", // 调研人数
      is_native: false, //0 老问卷后台 1新问卷cem
      product_id_for_template: "",
      a: 2,
    };
  }

  componentDidMount() {
    that = this;
    let hasLogisticsStep = false;
    if (parseInt(localStorage.getItem("current_project_type")) === 1) {
      hasLogisticsStep = true;
    }
    let step = getQueryParameter("step");
    if (!step || step === "undefined") {
      step = 1;
    }
    this.setState({
      hasLogisticsStep: hasLogisticsStep,
      project_entry_number: Number(step),
    });
  }

  componentWillUnmount() {
    if (localStorage.getItem("project_id")) {
      localStorage.removeItem("project_id");
    }
    if (localStorage.getItem("project_id_1")) {
      localStorage.removeItem("project_id_1");
    }
    if (localStorage.getItem("project_id_2")) {
      localStorage.removeItem("project_id_2");
    }
    if (localStorage.getItem("current_project_type")) {
      localStorage.removeItem("current_project_type");
    }
    this.setState = () => {};
  }
  getData(object) {
    that.setState({
      ...object,
    });
  }

  handleLogicSetting(obj) {
    this.setState({
      project_entry_number: obj.project_entry_number,
      dataList: obj.question_list,
    });
  }
  // 题目
  handleProjectEntryTitle() {
    if (this.state.project_entry_number <= 3) {
      return "项目-项目录入";
    } else if (this.state.project_entry_number === 4) {
      return "项目-项目录入-逻辑设置";
    } else if (this.state.project_entry_number === 5) {
      return "项目-项目录入-计算设置";
    }
    return "";
  }
  render() {
    return (
      <>
        {this.state.project_entry_number === 6 ? (
          <LogisticsInfo
            history={this.props.history}
            project_id={this.state.project_id}
            getData={this.getData}
            project_entry_number={this.state.project_entry_number}
          ></LogisticsInfo>
        ) : (
          <div
            className={`container ${
              this.state.project_entry_number === 3
                ? "project-entry-bg-2"
                : "project-entry-bg"
            }`}
          >
            <div
              className={
                this.state.project_entry_number === 3
                  ? "project-entry-container-2"
                  : "project-entry-container"
              }
            >
              <div className="project-entry-top-title">
                {this.handleProjectEntryTitle.call(this)}
              </div>
              <div
                className={
                  this.state.project_entry_number <= 3
                    ? "step-container"
                    : "isHide"
                }
              >
                <div className="step-card-wrapper">
                  <div
                    className={
                      this.state.project_entry_number >= 1
                        ? "step-card step-card-active"
                        : "step-card"
                    }
                  >
                    <div className="step-card-number">1</div>
                    <div className="step-card-text">填写基础信息</div>
                  </div>
                </div>
                <div
                  className={
                    this.state.hasLogisticsStep ? "step-line-2" : "step-line"
                  }
                ></div>
                <div className="step-card-wrapper">
                  <div
                    className={
                      this.state.project_entry_number >= 2
                        ? "step-card step-card-active"
                        : "step-card"
                    }
                  >
                    <div className="step-card-number">2</div>
                    <div className="step-card-text">标签派送</div>
                  </div>
                </div>
                <div
                  className={
                    this.state.hasLogisticsStep ? "step-line-2" : "step-line"
                  }
                ></div>
                <div className="step-card-wrapper">
                  <div
                    className={
                      this.state.project_entry_number >= 3
                        ? "step-card step-card-active"
                        : "step-card"
                    }
                  >
                    <div className="step-card-number">3</div>
                    <div className="step-card-text">调研问卷设置</div>
                  </div>
                </div>
                {this.state.hasLogisticsStep ? (
                  <>
                    <div
                      className={
                        this.state.hasLogisticsStep
                          ? "step-line-2"
                          : "step-line"
                      }
                    ></div>
                    <div className="step-card-wrapper">
                      <div
                        className={
                          this.state.project_entry_number >= 6
                            ? "step-card step-card-active"
                            : "step-card"
                        }
                      >
                        <div className="step-card-number">4</div>
                        <div className="step-card-text">产品物流设置</div>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <BasicInformation
                history={this.props.history}
                project_entry_number={this.state.project_entry_number}
                getData={this.getData}
              ></BasicInformation>
              <TagDispatch
                project_entry_number={this.state.project_entry_number}
                getData={this.getData}
                dispatch_count={this.state.dispatch_count}
              ></TagDispatch>
              <QuestionnaireSetting
                history={this.props.history}
                product_id_for_template={this.state.product_id_for_template}
                survey_id={this.state.survey_id}
                is_native={this.state.is_native}
                project_entry_number={this.state.project_entry_number}
                getData={this.getData}
                handleLogicSetting={this.handleLogicSetting.bind(this)}
              ></QuestionnaireSetting>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Project_entry;
