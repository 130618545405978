import React, { Component } from 'react'
import "./sort_template_info.scss"
import cur_icon_px_nor from "../../../../../../images/cur_icon_px_nor.png"

export default class sort_template_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'sort_template_info',
            dataList: {},
            answers: [],
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                answers: props.dataList.answers || state.answers,
            }
        }
        return null;
    }
    componentDidMount() {
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
        this.setState = () => { }
    }
    render() {
        return (
            <div className='sort-template-info'>
                {
                    this.state.answers.map((item, index) => {
                        return <div className="sort-item-wrapper" key={index}>
                            <div className="sort-item-left">
                                <img src={cur_icon_px_nor} alt="" className="setting-radio-icon" />
                            </div>
                            <div className="sort-item-right">
                                <div className="sort-item-title-wrapper">
                                    <div className="sort-item-title">{`${!item.is_other ? item.name : "其他"}`}</div>
                                    <div className={`${item.image.val ? "upload-question-pic" : "isHide"}`}>
                                        <img src={item.image.val} alt="" />
                                    </div>
                                </div>
                                {
                                    item.is_other ? <div className="sort-item-other-input"></div> : ""
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        )
    }
}
