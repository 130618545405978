import axios from 'axios';
import { setToken, setRefreshToken, getToken, getRefreshToken, removeToken, removeRefreshToken, setBrandID } from '../util/auth'
import { refreshToken, login } from '../api/token'
import { message } from 'antd';
import { get_url } from "./config/get_url"
const baseURL = get_url()
// 声明一个 Map 用于存储每个请求的标识 和 取消函数
const pending = new Map();

const { isCancel } = axios

const axiosInstance = axios.create({
    baseURL: baseURL,
    timeout: 360000, //6分钟
    headers: {
        'Authorization': "JWT " + localStorage.getItem('access_token'), //可以没有 已经在请求拦截里声明
        'Content-Type': 'application/json',
        'accept': 'application/json'

    }
});

// 添加请求
function addPending(config) {
    let url = config.url + "&" + config.method;
    if (config.compareRequestParams) {
        Object.keys(config.data).forEach(key => {
            url += "&" + key + "=" + config.data[key]
        })
    }
    config.cancelToken = new axios.CancelToken(cancel => {
        if (!pending.has(url)) {
            // 如果 pending 中不存在当前请求，则添加进去
            pending.set(url, cancel);
        }
    });
};
// 移除请求
function removePending(config) {
    let url = config.url + "&" + config.method;
    if (config.compareRequestParams) {
        Object.keys(config.data).forEach(key => {
            url += "&" + key + "=" + config.data[key]
        })
    }

    if (pending.has(url)) {
        // 如果在 pending 中存在当前请求标识，需要取消当前请求，并且移除
        const cancel = pending.get(url);
        cancel(url);
        pending.delete(url);
    }
};

axiosInstance.interceptors.request.use(
    config => {
        if (getToken()) {
            // console.log("正确request拦截器中,有token的时候", getToken())
            //1 no
            // axiosInstance.defaults.headers['Authorization'] = "JWT " + getToken();
            //2
            config.headers = {
                Authorization: "JWT " + getToken(),
                "Content-Type": 'application/json'
            };
        }
        if (config.isRequestPending) {
            removePending(config) // 在请求开始前，对之前的请求做检查取消操作
            addPending(config); // 将当前请求添加到 pending 中
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    async response => {
        if (response.config.isRequestPending) {
            removePending(response.config);
        }
        return response
    },
    async error => {
        if (isCancel(error)) {
            return Promise.reject({ message: "isCancel", error })
        }
        if (!error.response && error.__proto__.__CANCEL__) {
            return  //返回的是undefinde到回调的try 和 then
        }
        const originalRequest = error.config; //获取到config
        if (error.response?.status === 402) { //总体数据分析的下载
            return error.response
        }
        if (error.response && error.response.status === 400 && error.response.message === "token信息缺失") {
            localStorage.clear();
            window.location.href = '/login';
        }
        if (error.response?.status === 400 && originalRequest.url === '/core/blacklist/') {
            // 登出 的时候 400
            // 如果登出的时候，refresh_token早就过期，会400，
            removeToken();
            removeRefreshToken()
            localStorage.clear();
            window.location.href = '/login';
        }

        if (error.response?.status === 401 && originalRequest.url === '/core/token/refresh/') {
            // 这里应该是refresh token 的请求 过来都401
            // 普通请求，若过期，则刷新token 
            // 这边到login，到路由守卫那里是到首页
            localStorage.clear();
            window.location.href = '/login';
            return Promise.reject(error);
        }
        if (error.response?.status === 401) {
            const refresh_token = getRefreshToken()
            if (refresh_token) {
                // const token_parts = JSON.parse(atob(refresh_token.split('.')[1]));
                const payloadBase64 = refresh_token.split('.')[1];
                const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
                const decoded = JSON.parse(decodedJson)
                const exp = decoded.exp * 1000;
                const now = Date.now();

                if (exp > now) {
                    const response = await refreshToken(refresh_token)
                    setToken(response.data.access)
                    setRefreshToken(response.data.refresh)

                    // 设置brand_id 防止refresh token后 brand_id丢失
                    const payloadBase64 = response.data.access.split('.')[1];
                    const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
                    const decoded = JSON.parse(decodedJson)
                    setBrandID(decoded.brand_id)

                    // axiosInstance.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
                    // originalRequest.headers['Authorization'] = 'JWT ' + response.data.access;
                    return await axiosInstance(originalRequest);
                } else {
                    console.log('Refresh token is expired.');
                    removeToken();
                    removeRefreshToken()
                    localStorage.clear();
                    window.location.href = '/login';

                }
            } else {
                console.log('Refresh token not available.');
                window.location.href = '/login';
            }
        }
        if (error.response?.status === 403) {
            message.warning('没有权限访问', 2, () => { })
            localStorage.clear();
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }

)

export default axiosInstance;



