import React, { Component } from 'react'
import './xm_select.scss'
import { Select } from "antd";
import cur_icon_select from "../../images/cur_icon_select.png";
const { Option } = Select;
class xm_select extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    //去除富文本
    removeRichtext(richtext) {
        return richtext.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, ""); //这里是去除标签
    }
    // 获取值
    getValue(item) {
        if (this.props.hasRichText) {
            if (this.props.showRichText) {
                return <span dangerouslySetInnerHTML={{ __html: item.value }} ></span>
            } else {
                return this.removeRichtext(item.value)
            }
        } else {
            return item.value
        }
    }
    render() {
        let iskey = this.props.iskey || "false"
        let { options, ...rest } = this.props;
        let suffixIcon = (
            <img src={cur_icon_select} alt="" className='select-icon' />
        )
        return (
            <div className="select">
                {/* <Select {...rest} suffixIcon={suffixIcon} showArrow> */}
                <Select {...rest}>
                    {options && options.map((item, index) => {
                        return <Option key={iskey === "true" ? item.key : index} value={item.key} title={item.show_image ? "" : item.value} disabled={item.disabled}>
                            {
                                item.show_image ? <span>
                                    <img src={item.value} style={{ width: '72px', height: '72px' }} alt="" />
                                </span> : <span>{this.getValue.call(this, item)}</span>
                            }
                        </Option>
                    })}
                </Select>
            </div>
        )
    }
    // render() {
    //     let { options, ...rest } = this.props;
    //     return (
    //         <div className="select">
    //             <Select defaultValue={this.props.defaultValue} key={this.props.defaultValue}>
    //                 {this.state.options.map((item, index) => {
    //                     return <Option key={index} value={item.key}>
    //                         {item.value}
    //                     </Option>
    //                 })}
    //             </Select>
    //         </div>
    //     )
    // }
}
export default xm_select;