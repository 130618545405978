/* 
    1.该文件是用于创建一个为Count组件服务的reducer，reducer的本质就是一个函数
    2.reducer函数会接到两个参数，分别为：之前的状态(preState)，动作对象(action)
*/
import { EDIT_USER, EDIT_USER_ITEM } from '../constant'
import initialUserState from '../states/user';

export default function userReducer(preState = initialUserState, action) {
    // console.log('countReducer@#@#@#');
    //从action对象中获取：type、data
    const { type, data } = action
    //根据type决定如何加工数据
    switch (type) {
        case EDIT_USER: //如果是用户信息发生变动
            return data
        case EDIT_USER_ITEM: //如果是用户信息单项发生变动 深拷贝
            let tmp = JSON.parse(JSON.stringify(preState))
            tmp[data.edit_type] = data.edit_value
            return tmp
        default:
            return preState
    }
}