export const theme_color_list = ["#3DD0AD", " #FBC15A"]
export const pie_radius = ['50%', '70%']

export function getRingLegend(data, sum) {
    let ring_legend = {
        width: '80%',
        bottom: "36%",
        left: 'center',
        icon: 'circle', //设置图例图表形状
        itemWidth: 8,
        itemHeight: 8,
        itemGap: 50,
        // padding: 20,
        // type: 'scroll',
        // pageIconColor: '#cbc9cf',
        // pageIconInactiveColor: '#e5e4e7',
        // pageIconSize: 12,
        // pageTextStyle: {
        //     color: '#595961',
        // },
        formatter: function (name, index) {
            var percent = 0;
            for (var i = 0; i < data.length; i++) {
                if (name === data[i].name) {
                    percent = data[i].percent;
                }
            }
            return (
                '{name| ' + name + '}  ' +
                '{value| ' + percent + '} '
            );
        },
        textStyle: {
            rich: {
                name: {
                    // verticalAlign: 'right',
                    // align: 'left',
                    width: 30,
                    fontSize: 13,
                    color: '#595961',
                    fontWeight: 400,
                },
                value: {
                    // align: 'left',
                    width: 30,
                    fontSize: 13,

                },
            },
        },
        data: [
            {
                name: '女性',
                textStyle: {
                    color: theme_color_list[0]      // 图例文字颜色
                }
            },
            {
                name: '男性',
                textStyle: {
                    color: theme_color_list[1]         // 图例文字颜色
                }
            },

        ],
    }
    return ring_legend
}

export const ring_tooltip = {
    trigger: 'item',
}

export function getRingSeries(data, sum) {
    let ring_series = [
        {
            type: 'pie',
            radius: pie_radius,
            avoidLabelOverlap: false,
            emphasis: {
                label: {
                    show: true,
                    fontSize: 12
                }
            },
            top: -20,
            bottom: "35%",
            label: {
                normal: {
                    show: true,
                    position: 'center',
                    color: '#4c4a4a',
                    formatter: function (params) {
                        let num_text = "(总参与人数)"
                        return `{num|${sum}}\n{num_text|${num_text}}`
                    },
                    rich: {
                        num: {
                            fontSize: 40,
                            fontFamily: "Bahnschrift",
                            fontWeight: 400,
                            color: '#000000',
                            lineHeight: 40,
                        },
                        num_text: {
                            fontFamily: "微软雅黑",
                            fontSize: 16,
                            color: '#909EBE',
                            lineHeight: 21,
                        },
                    }
                },
                emphasis: {//中间文字显示
                    show: true,
                }
            },
            lableLine: {
                normal: {
                    show: false
                },
                emphasis: {
                    show: true
                },
                tooltip: {
                    show: false
                }
            },
            data: data
        }
    ]
    return ring_series
}
export function getRingOption(data, sum) {

    let option = {
        tooltip: ring_tooltip,
        legend: getRingLegend(data, sum),
        color: theme_color_list,
        series: getRingSeries(data, sum)
    }
    return option
}