import { createRef, useEffect, useState } from "react"
import './question_item.scss'
import * as echarts from 'echarts';
import { Table, Tabs } from 'antd'



export default function Question_item({ item }) {
  const [questionItem, setQuestionItem] = useState([]);

  const columns = {
    3: [
      {
        title: '用户',
        dataIndex: 'name',
        key: 'name',
        width: 80
      },
      {
        title: '性别',
        dataIndex: 'gender',
        key: 'gender',
        width: 60
      },
      {
        title: '评论',
        dataIndex: 'comment',
        key: 'comment',
      },
    ],
    18: [
      {
        title: '用户',
        dataIndex: 'name',
        key: 'name',
        width: 80
      },
      {
        title: '性别',
        dataIndex: 'gender',
        key: 'gender',
        width: 60
      },
      {
        title: '评论',
        dataIndex: 'comment',
        key: 'comment',
      },
    ],
    10: [
      {
        title: '用户',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: '性别',
        dataIndex: 'gender',
        key: 'gender',
        width: 60
      },
      {
        title: '图片',
        dataIndex: 'comment',
        key: 'comment',
        width: 100,
        render: (item) => {
          return <img src={item} style={{ width: '50px', height: 'auto' }} />
        }
      },
    ],
  }
  const topColumns = [
    {
      title: '统计方式',
      dataIndex: 'name',
      key: 'name',
      width: 120
    }, {
      title: '值',
      dataIndex: 'value',
      key: 'value',
    },
  ]




  const echart = createRef();

  useEffect(() => {
    constructionData(item);
  }, [questionItem])

  // 数据处理
  const constructionData = () => {
    let question = item;

    let option = {}
    // 饼状图
    // 单选题、
    if (question.question_type === 1 || question.question_type === 12 || question.question_type === 13) {
      let data = [];
      for (let i in question.data) {
        data.push({
          value: question.data[i].value,
          name: question.data[i].name,
          option_position: Number(question.data[i].option_position)
        })
      }
      for (let i = 0, len = data.length; i < len - 1; i++) {
        for (let n = 0; n < len - i - 1; n++) {
          if (data[n].option_position < data[n + 1].option_position) {
            const tmp = data[n + 1];
            data[n + 1] = data[n];
            data[n] = tmp;
          }
        }
      }

      option = {
        series: [
          {
            type: 'pie',
            emphasis: {
              label: {
                show: true,
                fontWeight: 'bold',
              }
            },
            label: {
              show: true,
              color: 'inherit',
              fontSize: 12,
              formatter(param) {
                return `${param.name}\n${param.percent}%`
              }
            },
            radius: ['30%', '60%'],
            data: data,
          }
        ],
        tooltip: {
          appendToBody: true,
          formatter(param) {
            return `${param.marker} ${param.name}<br/>数量：${param.value}<br/>占比${param.percent}%`;
          }
        },
      }
      randerEcharts(option);
    }
    // 柱状图
    // 多选题
    if (question.question_type === 2) {
      let Xdata = question.x_axis || [];
      let data = question.data || [];
      let num = 0;
      for (let i in data) {
        num += data[i];
      }
      option = {
        axisLabel: { //x轴的标题
          show: true,
          textStyle: {
            color: '#9797A1', //x轴文本字体颜色改变
            fontSize: 12,
            lineHeight: 14
          },
          interval: 0,
          formatter: function (value, index) {
            let ans = ""
            let maxWordLength = 12  //每个柱子的title最多3个文字
            let barNum = Xdata.length
            let valueLen = value.length //文字长度

            let num_reg = /[0-9]/g
            let tmp = Number(value[0])
            let hasNum = false
            if (num_reg.test(tmp)) {
              hasNum = true
            }
            if (barNum >= 15) {
              maxWordLength = 1
            } else if (barNum >= 7) {
              maxWordLength = 2
            } else if (barNum >= 6) {
              maxWordLength = 3
            } else if (barNum >= 5) {
              if (hasNum) {
                maxWordLength = 5
              } else {
                maxWordLength = 4
              }
            } else if (barNum >= 4) {
              maxWordLength = 5
            } else if (barNum >= 3) {
              maxWordLength = 6
            }
            let rowNum = Math.ceil(valueLen / maxWordLength)
            if (rowNum >= 4) {
              let len = 3 * maxWordLength
              value = value.substring(0, len + 1) + "..";
            }
            if (rowNum > 1) {
              for (let i = 0; i < rowNum; i++) {
                let tmp = ""
                let start = i * maxWordLength
                let end = start + maxWordLength
                if (i === rowNum - 1) {
                  tmp = value.substring(start, end)
                } else {
                  tmp = value.substring(start, end) + "\n"
                }
                ans += tmp
              }
              return ans
            } else {
              return value
            }
          }
        },

        xAxis: {
          type: 'category',
          data: Xdata,
          axisTick: { // 去掉刻度条
            show: false
          },
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: data,
            type: 'bar',
            color: '#ff5186',
            label: {
              show: true,
              position: 'top',
              barWidth: '50%', //柱子宽度
              barMaxWidth: 50,
              formatter: function (param) {
                return (param.value / num * 100).toFixed(2) + '%';
              }
            },
          }
        ],
        tooltip: {
          appendToBody: true,
          axisPointer: {
            type: 'shadow'
          },
          formatter(param) {
            return `${param.marker} ${param.name}<br/>数量：${param.value}<br/>占比：${(param.value / num * 100).toFixed(2)}%`;
          }
        },
      }
      randerEcharts(option);
    }
    // 打分题
    if (question.question_type === 5 || question.question_type === 7 || question.question_type === 11) {
      let Xdata = question.x_axis || [];
      let data = question.data || [];
      let num = 0;
      for (let i in data) {
        num += data[i];
      }
      option = {
        xAxis: {
          type: 'category',
          data: Xdata,
          axisTick: { // 去掉刻度条
            show: false
          },
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: data,
            type: 'bar',
            color: '#ff5186',
            label: {
              show: true,
              position: 'top',
              barWidth: '50%', //柱子宽度
              barMaxWidth: 50,
              formatter: function (param) {
                return (param.value / num * 100).toFixed(2) + '%';
              }
            },
          }
        ],
        tooltip: {
          appendToBody: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter(param) {
            return `${param[0].marker} ${param[0].name}<br/>数量：${param[0].value}<br/>占比：${(param[0].value / num * 100).toFixed(1)}%`;
          }
        },
      }
      randerEcharts(option);
    }
    // 排序题
    if (question.question_type === 4) {
      let Xdata = question.xAxisList || [];
      // let data = question.seriesBarList || [];
      let series = [];
      for (let i in question.seriesBarList) {
        series.push({
          type: 'bar',
          stack: 'overlap',
          data: question.seriesBarList[i].data
        })
      }
      option = {
        xAxis: {
          type: 'category',
          data: Xdata,
          axisLabel: {
            interval: 0,
            rotate: 30
          },
          axisTick: { // 去掉刻度条
            show: false
          },
        },
        yAxis: {
          type: 'value'
        },
        series: series,
        tooltip: {
          appendToBody: true,
          formatter(param) {
            console.log(param);
            let num = 0;
            for (let i in series[0].data) {
              num += Number(series[0].data[i])
            }
            return `${param.name}<br/>${param.marker}${param.seriesIndex + 1}<br/>数量：${param.value}<br/>占比：${(param.value / num * 100).toFixed(1)}%`;
          }
        },
      }
      randerEcharts(option);
    }
    // 矩阵题
    if (question.question_type === 8 || question.question_type === 9) {
      let Xdata = question.xAxisList || [];
      // let data = question.seriesBarList || [];
      let series = [];
      for (let i in question.seriesBarList) {
        series.push({
          type: 'bar',
          stack: 'overlap',
          data: question.seriesBarList[i].data
        })
      }
      option = {
        xAxis: {
          type: 'category',
          data: Xdata,
          axisLabel: {
            interval: 0,
            rotate: 30
          },
          axisTick: { // 去掉刻度条
            show: false
          },
        },
        yAxis: {
          type: 'value'
        },
        series: series,
        tooltip: {
          appendToBody: true,
          formatter(param) {
            let num = 0;
            let index = param.dataIndex;
            for (let i in question.seriesBarList) {
              num += question.seriesBarList[i].data[index]
            }
            return `${param.name}<br/>${param.marker}${question.seriesBarList[param.seriesIndex].name}<br/>数量：${param.value}<br/>占比：${(param.value / num * 100).toFixed(1)}%`;
          }
        },
      }
      randerEcharts(option);
    }

    // 折线图
    // 矩阵打分题
    if (question.question_type === 14 || question.question_type === 15 || question.question_type === 22) {
      let Xdata = question?.chart_list[0]?.x_axis || [];
      let series = [];
      for (let i in question.chart_list) {
        series.push({
          data: question.chart_list[i].value,
          type: 'line',
          smooth: true
        })
      }

      option = {
        xAxis: {
          type: 'category',
          data: Xdata
        },
        yAxis: {
          type: 'value'
        },
        series: series,
        tooltip: {
          appendToBody: true,
          trigger: 'axis',
          formatter: function (params) {
            let str = params[0].name + "分</br>"
            params.forEach((item, index) => {
              let option_img_str = ""
              if (question.chart_list[index].option_img) {
                option_img_str = "<img src=" + question.chart_list[index].option_img + " style='width: auto;height: 50px;vertical-align: middle;object-fit: cover;'></img></br>"
              }
              str += params[index].marker + question.chart_list[index].option_name + "：" + params[index].value + "</br>" + option_img_str
            })
            return str
          }
        }

      }
      randerEcharts(option);
    }

    // 表格
    // 简答题
    if (question.question_type === 3) {

    }
    if (question.question_type === 16) {
      for (let i in question.option_list) {
        let column = [
          {
            title: '用户',
            dataIndex: 'name',
            key: 'name',
            width: 80,
          },
          {
            title: '性别',
            dataIndex: 'gender',
            key: 'gender',
            width: 60
          },
        ];
        Object.keys(question.option_list[i].data[0]).map(item => {
          if (!['age', 'gender', 'name', 'user_id'].includes(item)) {
            column.push({
              title: item,
              dataIndex: item,
              key: item,
            })
          }
        })
        question.option_list[i].columns = column;

      }
    }


    let list = question.data || [];


    if (question.question_type === 1 || question.question_type === 12 || question.question_type === 13) {
      let sum = 0;
      let std = 0;
      list.map(item => {
        if (item.option_position) {
          sum += Number(item.option_position);
        } else {
          sum = NaN;
        }
      })

      // 计算标准差
      let samples = [];
      for (let i in list) {
        samples.push({
          value: Number(list[i].option_position),
          size: list[i].value,
        })
      }
      std = calculateStandardDeviation(samples);
      console.log(std);

      // topx: sum > 0;
      // jar: sum === 0;
      // 无表格: sum < 0;
      if (sum > 0 && !question.is_jar) {
        let score = [];
        let topx = [];
        let topXobj = {};
        let num_option = question.num_option;
        let sumScore = 0;



        if (num_option >= 7) {
          topx = ['top1', 'top3'];
        } else if (num_option >= 5) {
          topx = ['top1', 'top2'];
        } else {
          topx = ['top1'];
        }

        for (let i = 0; i < num_option; i++) {
          score[i] = {
            s: i + 1,
            v: 0
          }
        }

        for (let i in list) {
          if (list[i].option_position && Number(list[i].option_position) <= num_option) {
            score[list[i].option_position - 1].v = list[i].value;
            sumScore += Number(list[i].value) * Number(list[i].option_position);
          }
        }

        let n = 0;
        for (let i = score.length - 1; i >= 0; i--) {
          n += score[i].v;
          topXobj[`top${score.length - i}`] = (n / question.sum * 100).toFixed(1);
        }

        let tableData = [];
        for (let i in topx) {
          if (topXobj[topx[i]]) {
            tableData.push({
              name: topx[i],
              value: topXobj[topx[i]] + '%',
              oriValue: question.sum,
            })
          }
        }
        tableData.push({
          name: 'Mean',
          value: (sumScore / question.sum).toFixed(2),
          oriValue: { value_std: std, sum: question.sum },
        })


        item.tableData = tableData;

      } else if (sum === 0 || question.is_jar) {
        let n = 0;
        for (let i in list) {
          if (list[i].option_position == 0) {
            n += list[i].value
          }
        }

        item.tableData = [{
          name: 'JAR',
          value: (n / question.sum * 100).toFixed(1) + '%'
        }];
      } else {
        item.tableData = null;
      }
    }

    if (question.question_type === 5 || question.question_type === 7) {
      let a = [];
      let o = {};
      let n = 0;
      let sumn = 0;
      let sum = 0;
      let std = 0;

      for (let i in list) {
        a.unshift(list[i]);
        sum += list[i];
      }

      for (let i in a) {
        n += Number(a[i]);
        o[`top${Number(i) + 1}`] = (n / sum * 100).toFixed(1);
        sumn += a[i] * (a.length - i);
      }

      // 计算标准差
      let samples = [];
      for (let i in list) {
        samples.push({
          value: question.x_axis[i],
          size: list[i],
        })
      }
      std = calculateStandardDeviation(samples);
      console.log(std);


      let topX = [];
      if (list.length >= 7) {
        topX = ['top1', 'top3']
      } else if (list.length >= 5) {
        topX = ['top1', 'top2']
      } else {
        topX = ['top1']
      }

      let tableData = [];
      for (let i in topX) {
        if (o[topX[i]]) {
          tableData.push({
            name: topX[i],
            value: o[topX[i]] + '%',
            oriValue: sum
          })
        }
      }
      tableData.push({
        name: 'Mean',
        value: (sumn / sum).toFixed(2),
        oriValue: { value_std: std, sum: sum },
      })
      item.tableData = tableData;
    }


    setQuestionItem(question);
  }

  // 渲染echart
  const randerEcharts = (data) => {
    var myChart = echarts.init(echart.current);
    myChart.setOption(data);

    window.addEventListener('resize', function () {
      myChart.resize();
    })
  }

  // 计算标准差
  const calculateStandardDeviation = (samples) => {
    const n = samples.reduce((acc, sample) => acc + sample.size, 0); // Total sample size
    const mean = samples.reduce((acc, sample) => acc + (sample.value * sample.size), 0) / n; // Mean

    const squaredDifferencesSum = samples.reduce((acc, sample) => {
      const difference = sample.value - mean;
      return acc + (difference * difference * sample.size);
    }, 0); // Sum of squared differences

    const variance = squaredDifferencesSum / n; // Variance
    const standardDeviation = Math.sqrt(variance); // Standard deviation

    return standardDeviation;
  }



  return (
    <div className="question-item">
      {
        ((questionItem.question_type === 1 || questionItem.question_type === 12 || questionItem.question_type === 13 || questionItem.question_type === 5 || questionItem.question_type === 7) && questionItem.tableData) ?
          <div className="question-item-table">
            <Table dataSource={questionItem.tableData} columns={topColumns} pagination={{ position: ['none'] }} />
          </div>
          : ''
      }
      {
        (questionItem.question_type === 22) ?
          <Table
            dataSource={questionItem.data_table_obj.data_list}
            columns={questionItem.data_table_obj.columns}>
          </Table>
          : ""

      }

      {
        (questionItem.question_type != 3 && questionItem.question_type != 10 && questionItem.question_type != 18 && questionItem.question_type != 16) ?
          <div className="question-item-echart">
            <div ref={echart} className="echarts"></div>
          </div>
          : (questionItem.question_type === 16) ?
            <div>
              <Tabs defaultActiveKey={0}>
                {questionItem.option_list.map((item, index) => {
                  return <Tabs.TabPane tab={item.name} key={index}>
                    <Table dataSource={item.data} columns={item.columns} scroll={{ y: 200 }} size="small" />
                  </Tabs.TabPane>
                })}

              </Tabs>
            </div>
            :
            <div>
              <Table dataSource={questionItem.data} columns={columns[questionItem.question_type]} scroll={{ y: 200 }} size="small" />
            </div>
      }

    </div>
  )
}