import React, { Component } from "react";
import "./Product_entry.scss";
import product_icon_save from "../../images/product_icon_save.png";
import preview_icon_rel from "../../images/preview_icon_rel.png";
import Upload from "../../components/Upload/Upload";
import XmEditor from "../../components/Xm_editor/xm_editor";
import Browse from "../../components/Browse/Browse";
import XmSelect from "../../components/Select/xm_select";
import Loading from "../../components/Loading/loading";
// import vchart from "../../images/vchart.png";
import cur_icon_select from "../../images/cur_icon_select.png";
import Toast from "../../components/Toast/Toast";
import UserAPI from "../../api/UserAPI";
import { getTagCategory } from "../../api/Product/Product_list_api";
import { getBrand } from "../../api/Home_page/home_page_api";
import { getQueryParameter } from "../../util/utils";
import { TreeSelect, message } from "antd";

let that = "";
class Product_entry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0, //加载产品中 GetProduct接口
      loading_brand: 0, // 加载品牌 getBrand接口
      loading_product_type: 0, // 加载产品树 get_product_type_list接口
      loadingText: "加载产品中",
      toastStatus: false,
      msg: "", //验证错误信息
      name: "Product_entry",
      product_id: localStorage.getItem("product_id")
        ? localStorage.getItem("product_id")
        : "", //产品ID

      product_name_val: "", //产品名称

      product_type_text: "", //产品品类
      product_type_val: "",
      product_type_show: "",
      product_type_list: [],

      product_introduction_val: "", //产品简介

      product_price_val: "", //产品现价

      product_detail: "", //产品详情介绍

      product_imgs: [], //产品宣传照

      product_battle_field_text: "", //阵地
      product_battle_field_val: "",
      product_battle_field_show: "",

      product_battle_field_list: [
        { key: 3, value: "公众号" },
        { key: 4, value: "小程序" },
      ],

      product_wx_appid_val: "", //小程序appid
      product_wx_link_val: "", //小程序页面
      product_gzh_link_val: "", //公众号页面链接

      transform_text: "", //转换-阵地
      transform_val: "",
      transform_show: "",

      transform_list: [
        { key: 2, value: "淘宝" },
        { key: 4, value: "小程序" },
      ],

      transform_link_val: "", //口令
      transform_wx_appid_val: "",
      transform_wx_link_val: "",
      // product_gzh_link:'',
      // product_info:'aa',
      // product_tkl:'',
      // product_lq:''
      treeData: [], // 树选择框
      tree_id: undefined,
      selected_brand_id: "", //品牌下拉框
      selected_brand_list: [], // 可选品牌列表
      selected_brand_list_formal: [],
      selected_brand_key_list: [],
      product_info: {},
    };
  }

  componentDidMount() {
    that = this;
    if (window.location.search.indexOf("id") !== -1) {
      let product_id = getQueryParameter("id");
      this.setState({ product_id: product_id }, () => {
        this.get_product_list();
      });
    } else {
      this.get_product_list();
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    this.setState = () => {};
  }
  async get_product_list() {
    //获取产品录入信息
    await this.getBrand();
    await this.get_product_type_list();
    if (this.state.product_id === "") {
      return false;
    }
    this.setState({ loading: 1 });
    UserAPI.GetProduct({ product_id: this.state.product_id })
      .then((res) => {
        let product_type_text = "";
        let product_type_val = "";
        let product_battle_field_text = "";
        let product_battle_field_val = "";
        let product_gzh_link_val = "";
        let product_wx_appid_val = "";
        let product_wx_link_val = "";
        let transform_text = "";
        let transform_val = "";
        let transform_link_val = "";
        let transform_wx_appid_val = "";
        let transform_wx_link_val = "";
        let product_imgs = [];
        let tree_id = undefined;
        if (res.status === 200) {
          tree_id = res.data.product_category_id;
          if (res.data.product_retention.platform_id === 3) {
            product_battle_field_text = "公众号";
            product_battle_field_val = res.data.product_retention.platform_id;
            product_gzh_link_val = res.data.product_retention.url;
          }
          if (res.data.product_retention.platform_id === 4) {
            product_battle_field_text = "小程序";
            product_battle_field_val = res.data.product_retention.platform_id;
            product_wx_appid_val = res.data.product_retention.url.split("#")[0];
            product_wx_link_val = res.data.product_retention.url.split("#")[1];
          }
          if (res.data.product_purchase.platform_id === 2) {
            transform_text = "淘宝";
            transform_val = res.data.product_purchase.platform_id;
            transform_link_val = res.data.product_purchase.url;
          }
          if (res.data.product_purchase.platform_id === 4) {
            transform_text = "小程序";
            transform_val = res.data.product_purchase.platform_id;
            transform_wx_appid_val =
              res.data.product_purchase.url.split("#")[0];
            transform_wx_link_val = res.data.product_purchase.url.split("#")[1];
          }
          product_imgs = res.data.product_resources;
          let data_obj = {
            product_resources: res.data.product_resources,
            product_title: res.data.product_title,
            product_category_id: res.data.product_category_id.toString(),
            product_subtitle: res.data.product_subtitle,
            product_price: (Number(res.data.product_price) * 100).toFixed(),
            product_description: res.data.product_description,
            product_retention: res.data.product_retention || {
              url: "",
              platform_id: "",
            },
            product_purchase: res.data.product_purchase || {
              url: "",
              platform_id: "",
            },
            product_brand_id: res.data.brand_id,
            brand_name: res.data.brand_name || "",
            product_id: this.state.product_id,
          };
          this.setState({
            product_info: data_obj,
            selected_brand_id: "brand_id_" + res.data.brand_id,
            selected_brand_name: res.data.brand_name || "",
            selected_brand_key_list: ["brand_id_" + res.data.brand_id],
            tree_id: Number(tree_id),
            product_detail: res.data.product_description,
            product_price_val: res.data.product_price,
            product_name_val: res.data.product_title,
            product_introduction_val: res.data.product_subtitle,
            product_battle_field_text,
            product_battle_field_val,
            product_gzh_link_val,
            product_wx_appid_val,
            product_wx_link_val,
            transform_text,
            transform_val,
            transform_link_val,
            transform_wx_appid_val,
            transform_wx_link_val,
            product_imgs,
          });
        }
        this.setState({
          loading: 0,
        });
      })
      .catch((err) => {
        this.setState({ loading: 0 });
        message.error(`获取产品信息后台发生错误`, 2, () => {});
      });
  }

  getImgs(list) {
    let imgs = list.concat([]);
    imgs.splice(imgs.length - 1, 1);
    for (let i = 0; i < imgs.length; i++) {
      imgs[i]["sort"] = i;
    }
    // console.log(imgs)
    that.setState({ product_imgs: imgs });
  }

  handleClick(t, index, event) {
    if (!t) return false;
    if (!event) return false;
    this.setState({
      [t + "_text"]: this.state[t + "_list"][index].name,
      [t + "_val"]: this.state[t + "_list"][index].id,
      [t + "_show"]: !this.state[t + "_show"],
    });
  }

  handle_show(t, event) {
    this.setState({
      [t + "_show"]: !this.state[t + "_show"],
    });
  }

  handleChange(t, event) {
    if (!t) return false;
    if (!event) return false;
    this.setState({ [t + "_val"]: event.target.value });
  }
  getProductDetail(val) {
    this.setState({ product_detail: val });
  }

  validatePrice(val) {
    let reg_price = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
    if (reg_price.test(val)) {
      return true;
    }
    return false;
  }

  validateNumber(event) {
    event.target.value = event.target.value.replace(
      /^\D*(\d*(?:\.\d{0,2})?).*$/g,
      "$1"
    );
    //处理0开头的问题
    if (event.target.value.length > 1) {
      event.target.value = event.target.value.replace(/^0+/, "");
      event.target.value =
        event.target.value.length == 0 ? "0" : event.target.value;
    }
    if (event.target.value.startsWith(".")) {
      event.target.value = "0" + event.target.value;
    }
  }

  validate() {
    //验证
    let flag = true;
    if (this.state.product_price_val) {
      if (!this.validatePrice(this.state.product_price_val)) {
        this.setState({ msg: "请输入正确的价格" });
        return false;
      }
    }
    if (!this.state.selected_brand_key_list.length) {
      this.setState({ msg: "请选择品牌名称" });
      return false;
    }
    if (this.state.product_imgs.length <= 0) {
      this.setState({ msg: "请上传产品宣传照" });
      return false;
    }
    if (!this.state.product_name_val) {
      this.setState({ msg: "请填写产品名称" });
      return false;
    } else {
      let char = 0;
      let reg = /[\u4e00-\u9fa5]/;
      for (let i = 0; i < this.state.product_name_val.length; i++) {
        if (this.state.product_name_val[i].match(reg)) {
          char += 2;
        } else {
          char += 1;
        }
      }
      if (char > 50) {
        this.setState({ msg: "产品名称字符不能超过50个" });
        return false;
      }
    }
    if (!this.state.tree_id) {
      this.setState({ msg: "请填写产品品类" });
      return false;
    }
    if (!this.state.product_introduction_val) {
      this.setState({ msg: "请填写产品简介" });
      return false;
    } else {
      let char = 0;
      let reg = /[\u4e00-\u9fa5]/;
      for (let i = 0; i < this.state.product_introduction_val.length; i++) {
        if (this.state.product_introduction_val[i].match(reg)) {
          char += 2;
        } else {
          char += 1;
        }
      }
      if (char > 255) {
        this.setState({ msg: "产品简介字符不能超过255个" });
        return false;
      }
    }
    if (!this.state.product_detail) {
      //产品详情介绍
      this.setState({ msg: "请填写产品详情" });
      return false;
    } else {
      let char = 0;
      let reg = /[\u4e00-\u9fa5]/;
      for (let i = 0; i < this.state.product_detail.length; i++) {
        if (this.state.product_detail[i].match(reg)) {
          char += 2;
        } else {
          char += 1;
        }
      }
      if (char > 1000) {
        //暂时不要限制
        // this.setState({ msg: "产品详情介绍字符不能超过1000个" })
        // return false
      }
    }
    //验证 品牌好礼-导流
    if (
      this.state.product_battle_field_text === "公众号" &&
      this.state.product_gzh_link_val.length > 0
    ) {
      //公众号
      let reg = "mp.weixin.qq.com";
      if (!this.state.product_gzh_link_val.match(reg)) {
        this.setState({ msg: "品牌好礼-导流 公众号链接填写错误" });
        return false;
      }
    }
    if (
      this.state.product_battle_field_text === "小程序" &&
      this.state.product_wx_appid_val.length > 0
    ) {
      //小程序appid
      let reg = /^wx/;
      if (!this.state.product_wx_appid_val.match(reg)) {
        this.setState({ msg: "品牌好礼-导流 小程序appid填写错误" });
        return false;
      }
    }
    if (
      this.state.product_battle_field_text === "小程序" &&
      this.state.product_wx_link_val.length > 0
    ) {
      //小程序页面
      let reg = /^(http|https)/;
      let reg2 = /([0-9a-zA-Z]?\/[0-9a-zA-Z])+/;
      // console.log(this.state.product_wx_link_val.match(reg2))
      if (this.state.product_wx_link_val.match(reg)) {
        this.setState({ msg: "品牌好礼-导流 小程序页面填写错误" });
        return false;
      } else if (!this.state.product_wx_link_val.match(reg2)) {
        this.setState({ msg: "品牌好礼-导流 小程序页面填写错误" });
        return false;
      }
    }
    //验证 立即购买-转化
    if (
      this.state.transform_text === "公众号" &&
      this.state.transform_link_val.length > 0
    ) {
      //淘口令
      let reg = /^(http|https)/;
      if (!this.state.transform_link_val.match(reg)) {
        this.setState({ msg: "立即购买-转化 淘口令填写错误" });
        return false;
      }
    }
    if (
      this.state.transform_text === "小程序" &&
      this.state.transform_wx_appid_val.length > 0
    ) {
      //小程序appid
      let reg = /^wx/;
      if (!this.state.transform_wx_appid_val.match(reg)) {
        this.setState({ msg: "立即购买-转化 小程序appid填写错误" });
        return false;
      }
    }
    if (
      this.state.transform_text === "小程序" &&
      this.state.transform_wx_link_val.length > 0
    ) {
      //小程序页面
      let reg = /^(http|https)/;
      let reg2 = /([0-9a-zA-Z]?\/[0-9a-zA-Z])+/;
      // console.log(this.state.transform_wx_link_val.match(reg2))
      if (this.state.transform_wx_link_val.match(reg)) {
        this.setState({ msg: "立即购买-转化 小程序页面填写错误" });
        return false;
      } else if (!this.state.transform_wx_link_val.match(reg2)) {
        this.setState({ msg: "立即购买-转化 小程序页面填写错误" });
        return false;
      }
    }
    return flag;
  }

  // 判断两个对象是否相等
  isObjectValueEqual(obj1, obj2) {
    if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
      return true;
    }
    return false;
  }

  submit(string) {
    if (this.validate()) {
      let product_retention_platform_id = "";
      let product_retention_url = "";
      let product_purchase_platform_id = "";
      let product_purchase_url = "";
      let product_imgs = [];
      if (
        this.state.product_battle_field_val === 3 &&
        this.state.product_gzh_link_val
      ) {
        product_retention_platform_id = this.state.product_battle_field_val;
        product_retention_url = this.state.product_gzh_link_val;
      }
      if (
        this.state.product_battle_field_val === 4 &&
        this.state.product_wx_appid_val &&
        this.state.product_wx_link_val
      ) {
        product_retention_platform_id = this.state.product_battle_field_val;
        product_retention_url =
          this.state.product_wx_appid_val +
          "#" +
          this.state.product_wx_link_val;
      }
      if (this.state.transform_val === 2 && this.state.transform_link_val) {
        product_purchase_platform_id = this.state.transform_val;
        product_purchase_url = this.state.transform_link_val;
      }
      if (
        this.state.transform_val === 4 &&
        this.state.transform_wx_appid_val &&
        this.state.transform_wx_link_val
      ) {
        product_purchase_platform_id = this.state.transform_val;
        product_purchase_url =
          this.state.transform_wx_appid_val +
          "#" +
          this.state.transform_wx_link_val;
      }
      for (let i = 0; i < this.state.product_imgs.length; i++) {
        // if (this.state.product_imgs[i].hasOwnProperty('file') && this.state.product_imgs[i].file !== '') {
        //     product_imgs.push(this.state.product_imgs[i])
        // }
        product_imgs.push(this.state.product_imgs[i]);
      }
      let product_price_val = this.state.product_price_val;
      if (product_price_val) {
        product_price_val = (Number(product_price_val) * 100).toFixed();
      }
      //接口
      let data = {
        product_resources: product_imgs,
        product_title: this.state.product_name_val,
        product_category_id: this.state.tree_id.toString(),
        product_subtitle: this.state.product_introduction_val,
        product_price: product_price_val,
        product_description: this.state.product_detail,
        product_retention: {
          url: product_retention_url,
          platform_id: product_retention_platform_id,
        },
        product_purchase: {
          url: product_purchase_url,
          platform_id: product_purchase_platform_id,
        },
        product_brand_id: parseInt(
          this.state.selected_brand_key_list[0].slice(9)
        ),
        brand_name: this.state.selected_brand_name,
      };
      if (this.state.product_id) {
        data["product_id"] = this.state.product_id;
      }
      let is_same = true;
      if (!this.isObjectValueEqual(this.state.product_info, data)) {
        is_same = false;
        this.setState({
          product_info: data,
        });
      }
      if (is_same && string !== "save_build") {
        message.warning(`本次填写的产品信息与上次相同`, 1, () => {});
        return;
      } else if (is_same && string == "save_build") {
        localStorage.setItem("product_id", this.state.product_id);
        if (string === "save_build") {
          localStorage.removeItem("project_id");
          this.props.history.push("/dispatch_list/project_entry");
        }
        return;
      }
      if (this.state.product_id) {
        UserAPI.UpdateProduct(data)
          .then((res) => {
            message.success("保存成功", 2, () => {});
            if (res.hasOwnProperty("status") && res.status === 202) {
              localStorage.setItem("product_id", res.data.data.id);
              if (string === "save_build") {
                localStorage.removeItem("project_id");
                this.props.history.push("/dispatch_list/project_entry");
              }
            } else {
            }
          })
          .catch((err) => {
            message.error("后台发生错误");
          });
      } else {
        UserAPI.SaveProduct(data)
          .then((res) => {
            message.success("保存成功", 2, () => {});
            if (res.hasOwnProperty("status") && res.status === 201) {
              localStorage.setItem("product_id", res.data.data.id);
              if (string === "save_build") {
                localStorage.removeItem("project_id");
                this.props.history.push("/dispatch_list/project_entry");
              }
            } else {
            }
          })
          .catch((err) => {
            message.error("后台发生错误");
          });
      }
    } else {
      this.showToast();
    }
  }

  showToast() {
    if (!this.state.toastStatus) {
      this.setState({
        toastStatus: true,
      });
      setTimeout(() => {
        this.setState({
          toastStatus: false,
        });
      }, 2000);
    }
  }
  // 下拉框搜索可以大小写
  filterSelectOption(input, option) {
    return (
      option.children.props.children
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  // 获取品牌列表
  async getBrand() {
    this.setState({ loading_brand: 1 });
    try {
      const response = await getBrand({});
      let selected_brand_list = [];
      if (response.status === 204) {
        this.setState({
          selected_brand_list: [],
          selected_brand_list_formal: [],
        });
      } else {
        for (let i = 0; i < response.data.length; i++) {
          let obj = {
            key: "brand_id_" + response.data[i].id,
            value: response.data[i].name,
          };
          selected_brand_list.push(obj);
        }
        this.setState({
          selected_brand_list: selected_brand_list,
          selected_brand_list_formal: selected_brand_list,
          loading_brand: 0,
        });
      }
      return response;
    } catch (error) {
      this.setState({ loading_brand: 0 });
      message.error("加载失败!");
    }
  }
  // 获取标签类别
  async get_product_type_list() {
    this.setState({ loading_product_type: 1 });
    try {
      const response = await getTagCategory({
        type: 3,
        project_id: "",
        project_type: "",
        special_event_id: "",
      });
      let tag_category = response.data.tag_category.filter((element) => {
        element.title = element.name;
        element.value = element.id;
        return element.flag === 4;
      });
      const treeData = this.formatDataTree(tag_category);
      this.setState({
        treeData: treeData,
        loading_product_type: 0,
      });
      return response;
    } catch (error) {
      this.setState({ loading_product_type: 0 });
      message.error("加载失败!");
    }
  }
  // 获取树
  formatDataTree(data) {
    let parents = data.filter((p) => p.parent_id === 0);
    let children = data.filter((c) => c.parent_id !== 0);
    dataToTree(parents, children);
    return parents;
    function dataToTree(parents, children) {
      parents.map((p) => {
        children.map((c, i) => {
          if (c.parent_id === p.id) {
            let _children = JSON.parse(JSON.stringify(children));
            _children.splice(i, 1);
            dataToTree([c], _children);
            if (p.children) {
              p.children.push(c);
            } else {
              p.children = [c];
            }
          }
          return null;
        });
        return null;
      });
    }
  }
  //下拉框选择后，并赋值到state里面
  onSelectChange(key, option, t) {
    if (!option) {
      this.setState({
        [t + "_text"]: "",
        [t + "_val"]: "",
      });
      return;
    }
    let cur_index = parseInt(option.key);
    this.setState({
      [t + "_text"]: this.state[t + "_list"][cur_index].value,
      [t + "_val"]: this.state[t + "_list"][cur_index].key,
    });
  }
  // 树标签 change
  onTreeSelectChange(value, label, extra) {
    this.setState({
      tree_id: value,
    });
  }
  // 渲染brand下拉框
  handleBrandListOption() {
    return this.state.selected_brand_list;
  }
  // 渲染brand默认的题目
  handleDefaultBrandListOption() {
    return this.state.selected_brand_key_list;
  }
  //验证品牌并不能为空
  validateBrand(event) {
    if (event.target.value === "") {
      return;
    }
  }
  //下拉框选择后，并赋值到brand state里面
  onBrandSelectChange(key_arr) {
    if (key_arr.length) {
      let input_value = String(key_arr[key_arr.length - 1]);
      if (input_value.trim() === "") {
        message.warning("品牌不可为空");
        return;
      }
      let selected_brand_list = JSON.parse(
        JSON.stringify(this.state.selected_brand_list)
      );
      let is_exist = false;
      // debugger
      let max = -1;
      let has_same_brand = false;
      let brand_name = input_value;
      for (let i = 0; i < selected_brand_list.length; i++) {
        if (selected_brand_list[i].key === input_value) {
          is_exist = true;
          brand_name = selected_brand_list[i].value;
        }
        // else if (selected_brand_list[i].value.startsWith('[') && selected_brand_list[i].value.startsWith(']') && selected_brand_list[i].value.slice(1, -1) === input_value) {
        //     has_same_brand = true
        // }
        else if (selected_brand_list[i].value === input_value) {
          has_same_brand = true;
        }
        max = Math.max(max, parseInt(selected_brand_list[i].key.slice(9)));
      }
      if (!is_exist && has_same_brand) {
        message.warning("已存在相同品牌");
        return;
      } else if (!is_exist) {
        // 不存在
        let obj = { key: "brand_id_" + parseInt(max + 1), value: input_value };
        selected_brand_list.push(obj);
        this.setState({
          selected_brand_name: brand_name,
          selected_brand_key_list: ["brand_id_" + parseInt(max + 1)],
          selected_brand_list: selected_brand_list,
        });
      } else {
        this.setState({
          selected_brand_name: brand_name,
          selected_brand_key_list: [input_value],
          selected_brand_list: selected_brand_list,
        });
      }
    } else {
      this.setState({
        selected_brand_name: "",
        selected_brand_key_list: [],
      });
    }
  }
  // 下拉框搜索可以大小写
  filterOption(input, option) {
    if (option.title) {
      return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    } else {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  }
  render() {
    return (
      <div className="container product-container">
        {this.state.loading <= 0 &&
        this.state.loading_brand <= 0 &&
        this.state.loading_product_type <= 0 ? (
          ""
        ) : (
          <Loading loadingText={this.state.loadingText}></Loading>
        )}
        <div className="product-entry">
          {/* 产品标题头 */}
          <div className="product-entry-top">
            <div className="top-title">产品-产品录入</div>
            <div className="top-right">
              <div
                className="top-right-save-button"
                onClick={this.submit.bind(this, "save")}
              >
                <span>保存</span>
              </div>
              <div
                className="top-right-reslute-button"
                onClick={this.submit.bind(this, "save_build")}
              >
                <img src={preview_icon_rel} alt="" />
                <span>快速生成项目</span>
              </div>
            </div>
          </div>
          <div className="product-entry-main">
            <div className="product-entry-left">
              <div className="product-entry-left-content">
                <Browse
                  product_imgs={this.state.product_imgs}
                  product_name_val={this.state.product_name_val}
                  product_introduction_val={this.state.product_introduction_val}
                  product_price_val={this.state.product_price_val}
                  product_detail={this.state.product_detail}
                ></Browse>
              </div>
            </div>
            <div className="product-entry-right">
              <div className="upload-container">
                <div className="contain-module">
                  <div className="contain-module-title">
                    <div className="name">
                      <span className="line"></span>
                      <span className="text">
                        产品宣传照
                        <span className="sub">
                          <span className="red">(必填项)</span>
                          (图片尺寸：1:1；文件大小＜1MB)
                        </span>
                      </span>
                    </div>
                  </div>
                  <Upload
                    getImgs={this.getImgs}
                    product_imgs={this.state.product_imgs}
                    max_number={9}
                    flag={"product_entry"}
                  ></Upload>
                </div>
              </div>
              <div className="product-info-container">
                <div className="contain-module">
                  <div className="contain-module-title">
                    <div className="name">
                      <span className="line"></span>
                      <span className="text">常规信息</span>
                    </div>
                  </div>
                  <div className="product-info-item">
                    <div className="product-info-left">
                      <span className="red">*</span>品牌名称
                    </div>
                    <div className="product-info-select">
                      <XmSelect
                        mode="tags"
                        placeholder="请选择派送品牌"
                        onChange={(e) => {
                          this.onBrandSelectChange.call(this, e);
                        }}
                        maxTagCount={"responsive"}
                        allowClear
                        options={this.handleBrandListOption.call(this)}
                        value={this.handleDefaultBrandListOption.call(this)}
                        onInput={this.validateBrand.bind(this)}
                        iskey="true"
                        filterOption={(input, option) => {
                          return this.filterOption.call(this, input, option);
                        }}
                      ></XmSelect>
                    </div>
                  </div>
                  <div className="product-info-item">
                    <div className="product-info-left">
                      <span className="red">*</span>产品名称
                    </div>
                    <input
                      placeholder="请输入产品名称"
                      className="product-info-right"
                      type="text"
                      defaultValue={this.state.product_name_val}
                      onChange={this.handleChange.bind(this, "product_name")}
                    />
                  </div>
                  <div className="product-info-item">
                    <div className="product-info-left">
                      <span className="red">*</span>产品品类
                    </div>
                    <div className="product-info-select">
                      <TreeSelect
                        showSearch
                        dropdownClassName="tree-select-popup"
                        treeNodeFilterProp="title"
                        style={{ width: "100%" }}
                        value={this.state.tree_id}
                        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                        treeData={this.state.treeData}
                        placeholder="请选择产品品类"
                        treeDefaultExpandAll
                        onChange={(value, label, extra) => {
                          this.onTreeSelectChange(value, label, extra);
                        }}
                      ></TreeSelect>
                    </div>
                  </div>
                  <div className="product-info-item">
                    <div className="product-info-left product-info-left-textarea">
                      <span className="red">*</span>产品简介
                    </div>
                    <textarea
                      placeholder="请输入产品简介，最多255个字符"
                      className="product-info-right"
                      type="text"
                      defaultValue={this.state.product_introduction_val}
                      onChange={this.handleChange.bind(
                        this,
                        "product_introduction"
                      )}
                    />
                  </div>
                  <div className="product-info-item nonePaddingBottom">
                    <div className="product-info-left">
                      <span></span>产品现价
                    </div>
                    <input
                      placeholder="请输入产品现价"
                      className="product-info-right"
                      type="text"
                      defaultValue={this.state.product_price_val}
                      onChange={this.handleChange.bind(this, "product_price")}
                      onInput={this.validateNumber.bind(this)}
                    />
                  </div>
                </div>
              </div>
              <div className="product-detail-container">
                <div className="contain-module">
                  <div className="contain-module-title">
                    <div className="name">
                      <span className="line"></span>
                      <span className="text">
                        产品详细介绍<span className="red">(必填项)</span>
                      </span>
                    </div>
                  </div>
                  <div className="product-editor">
                    <XmEditor
                      handleEditorChange={this.getProductDetail.bind(this)}
                      html={this.state.product_detail}
                    ></XmEditor>
                  </div>
                </div>
              </div>
              <div className="brand-gift-container">
                <div className="contain-module">
                  <div className="contain-module-title">
                    <div className="name">
                      <span className="line"></span>
                      <span className="text">品牌好礼-导流</span>
                    </div>
                  </div>
                  <div className="product-info-item">
                    <div className="product-info-left">阵地</div>
                    <div className="product-info-select">
                      <XmSelect
                        allowClear
                        dropdownClassName="product-entry-dropdown"
                        placeholder="请选择阵地"
                        defaultValue={
                          this.state.product_battle_field_val || null
                        }
                        key={this.state.product_battle_field_val}
                        options={this.state.product_battle_field_list}
                        onChange={(e, index) => {
                          this.onSelectChange(e, index, "product_battle_field");
                        }}
                      ></XmSelect>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.product_battle_field_text === "小程序"
                        ? "product-info-item"
                        : "isHide"
                    }
                  >
                    <div className="product-info-left">小程序appid</div>
                    <input
                      placeholder="请输入小程序appid"
                      className="product-info-right"
                      type="text"
                      defaultValue={this.state.product_wx_appid_val}
                      onChange={this.handleChange.bind(
                        this,
                        "product_wx_appid"
                      )}
                    />
                  </div>
                  <div
                    className={
                      this.state.product_battle_field_text === "小程序"
                        ? "product-info-item"
                        : "isHide"
                    }
                  >
                    <div className="product-info-left">小程序页面</div>
                    <input
                      placeholder="请输入小程序页面"
                      className="product-info-right"
                      type="text"
                      defaultValue={this.state.product_wx_link_val}
                      onChange={this.handleChange.bind(this, "product_wx_link")}
                    />
                  </div>
                  <div
                    className={
                      this.state.product_battle_field_text === "公众号"
                        ? "product-info-item"
                        : "isHide"
                    }
                  >
                    <div className="product-info-left">页面链接</div>
                    <input
                      placeholder="请输入公众号页面链接"
                      className="product-info-right"
                      type="text"
                      defaultValue={this.state.product_gzh_link_val}
                      onChange={this.handleChange.bind(
                        this,
                        "product_gzh_link"
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="brand-gift-container">
                <div className="contain-module">
                  <div className="contain-module-title">
                    <div className="name">
                      <span className="line"></span>
                      <span className="text">立即购买-转化</span>
                    </div>
                  </div>
                  <div className="product-info-item">
                    <div className="product-info-left">阵地</div>
                    <div className="product-info-select">
                      <XmSelect
                        allowClear
                        dropdownClassName="product-entry-dropdown"
                        placeholder="请选择阵地"
                        defaultValue={this.state.transform_val || null}
                        key={this.state.transform_val}
                        options={this.state.transform_list}
                        onChange={(e, index) => {
                          this.onSelectChange(e, index, "transform");
                        }}
                      ></XmSelect>
                    </div>
                  </div>
                  <div
                    className={
                      this.state.transform_text === "淘宝"
                        ? "product-info-item nonePaddingBottom"
                        : "isHide"
                    }
                  >
                    <div className="product-info-left">
                      {this.state.transform_text}
                    </div>
                    <input
                      placeholder="请输入淘口令"
                      className="product-info-right"
                      type="text"
                      defaultValue={this.state.transform_link_val}
                      onChange={this.handleChange.bind(this, "transform_link")}
                    />
                  </div>
                  <div
                    className={
                      this.state.transform_text === "小程序"
                        ? "product-info-item"
                        : "isHide"
                    }
                  >
                    <div className="product-info-left">小程序appid</div>
                    <input
                      placeholder="请输入小程序appid"
                      className="product-info-right"
                      type="text"
                      defaultValue={this.state.transform_wx_appid_val}
                      onChange={this.handleChange.bind(
                        this,
                        "transform_wx_appid"
                      )}
                    />
                  </div>
                  <div
                    className={
                      this.state.transform_text === "小程序"
                        ? "product-info-item nonePaddingBottom"
                        : "isHide"
                    }
                  >
                    <div className="product-info-left">小程序页面</div>
                    <input
                      placeholder="请输入小程序页面"
                      className="product-info-right"
                      type="text"
                      defaultValue={this.state.transform_wx_link_val}
                      onChange={this.handleChange.bind(
                        this,
                        "transform_wx_link"
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Toast
            toastStatus={this.state.toastStatus}
            msg={this.state.msg}
          ></Toast>
        </div>
      </div>
    );
  }
}

export default Product_entry;
