import React, { Component } from 'react'
import Slider from 'react-slick';
import './Browse.scss';
import product_img_nav from '../../images/product_img_nav.png'
import product_icon_img_image from '../../images/product_icon_img_image.png'
import bannerDefualt from '../../images/bannerDefualt.png'
import Slice from '../../images/Slice@2x.png'
import score from '../../images/score_2.png'

class Browse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "Browse",
            settings:{
                dots: true,
                infinite: true,
                autoplay:true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                slidesPerRow: 1,
                initialSlide: 0
            },
            product_imgs:[]
        }

    }


    componentDidMount() {
        this.setState({
            product_imgs:this.props.product_imgs
        })
      // console.log(this.props.product_imgs)
    }

    componentWillReceiveProps(obj){
       // console.log(obj)
        let product_imgs = []
        for(let i=0;i<obj.product_imgs.length;i++){
            if(obj.product_imgs[i].file!=''){
                product_imgs.push(obj.product_imgs[i])
            }
        }
        this.setState({
            product_imgs
        })
    }



    render() {
        return (
            <div className='Browse_main'>
                <div className='goods'>
                    <img className='product_img_nav' src={product_img_nav}/>
                    <img className={this.state.product_imgs.length<=0?'product_img_banner':'isHide'} src={bannerDefualt}/>
                    <Slider {...this.state.settings} className="view_imgs">
                        {
                            this.state.product_imgs.map((item,index)=>{
                                return (
                                    <img src={item.file || item.url} key={'imgs'+index}/>
                                )
                            })
                        }
                    </Slider>
                    <div className='goods_content'>
                        <div className='goods_name'>{this.props.product_name_val?this.props.product_name_val:'产品名称例：每日的菌-随形片*2'}</div>
                        <div className='score'>
                            <img src={score}/>
                            <img src={score}/>
                            <img src={score}/>
                            <img src={score}/>
                            <img src={score}/>
                            <span className='num'>5.0</span>
                        </div>
                        <div className='desc'>{this.props.product_introduction_val?this.props.product_introduction_val:'产品简介例如：心愿盒链接下单专属优惠，明星专利，做成糖果活性更高！'}</div>
                    </div>
                    <div className='comments'>
                        <div className="font-style"><span className="line"></span>商品详情</div>
                        <div className='comments_name' dangerouslySetInnerHTML= {{__html: (this.props.product_detail?this.props.product_detail:'产品详情例如： 主要卖点专利东方之菌，这个菌有两个最大的特点；从本土植物中发酵提取，更适合国人肠道。')}}></div>
                        <div className='comments_price'>【在售价】<span>{this.props.product_price_val?this.props.product_price_val:100}</span>元</div>
                    </div>
                    <div className='foot'>
                        <div className='pl_btn'>
                            <img src={Slice}/>
                            <span>评论</span>
                        </div>
                        <div className='sumbit'>立即购买</div>
                    </div>
                </div>
            </div>
        )
    }
}



export default Browse;