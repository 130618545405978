import axiosInstance from "../APIUtils";

// 获取心愿相关的信息 (人)
export function getWishStats(obj) {
  return axiosInstance({
    url: "/cem/getWishStats/",
    method: "POST",
    data: obj,
  });
}

// 获取所有心愿的列表 取倒数两个
export function getWishList(obj) {
  return axiosInstance({
    url: "/cem/getWishList/",
    method: "POST",
    data: obj,
  });
}

// 根据心愿获取产品列表信息
export function getWishProductList(obj) {
  return axiosInstance({
    url: "/cem/getWishProductList/",
    method: "POST",
    data: obj,
  });
}

// 获取明星用户
export function getCelebrityList(obj) {
  return axiosInstance({
    url: "/cem/getCelebrityList/",
    method: "POST",
    data: obj,
  });
}

// 获取明星用户
export function getResearchReportList(obj) {
  return axiosInstance({
    url: "/cem/getResearchReportList/",
    method: "POST",
    data: obj,
  });
}

// 获取下载文件列表类别
export function getResearchReportCategoryList(obj) {
  return axiosInstance({
    url: "/cem/getResearchReportCategoryList/",
    method: "POST",
    data: obj,
  });
}

// 获取下载文件列表类别
export function getBrand(obj) {
  return axiosInstance({
    url: "/cem/getBrand/",
    method: "POST",
    data: obj,
  });
}

// 获取账号相关品类
export function getAccountCategoryList(obj) {
  return axiosInstance({
    url: "/cem/getAccountCategoryList/",
    method: "POST",
    data: obj,
  });
}

// 获取品类norm值轴
export function getCategoryNorm(obj) {
  return axiosInstance({
    url: "/cem/getCategoryNorm/",
    method: "POST",
    data: obj,
  });
}

// 获取更新公告列表
export function getAnnouncementList(obj) {
  return axiosInstance({
    url: "/cem/getAnnouncementList/",
    method: "POST",
    data: obj,
  });
}
// 获取更新公告列表
export function getCommonQuestionList(obj) {
  return axiosInstance({
    url: "/cem/getCommonQuestionList/",
    method: "POST",
    data: obj,
  });
}
