import React, { Component } from 'react'
import './heatmap_popup.scss'
import cur_icon_close_gre from "../../../../../../../images/cur_icon_close_gre.png"
import cur_icon_delete from "../../../../../../../images/cur_icon_delete.png"
import { message } from 'antd';
let _this = ""
export default class heatmap_popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_type_list: [
                { key: 2, value: "产品问卷" },
                { key: 4, value: "调研问卷" },
            ],
            survey_type: null,
            from_survey_id: null,
            dispatch_list: [],
            survey_list: [],
            current_survey_list: [],
            question_img: "", // 图片
            default_answer: {
                name: "选项",
                is_other: false,
                is_option_active: 1,
                image: { name: "", val: "" },
                option_id: "",
                option_position: "",
                content: "",
                mutex: [],
                inconsistent_option_ids: "",
            },
            answers_formal: [],
            answers: []
        }
    }
    componentDidMount() {
        this.init()
        _this = this
    }
    init() {
        let question_img = this.props.dataList.image.val || ""
        let answers = this.props.dataList.answers || []
        // answers.push(this.state.default_answer)
        this.setState({
            question_img: question_img,
            answers: answers,
            answers_formal: this.props.dataList.answers || []
        }, () => {
            setTimeout(() => {
                this.initPoint()
                this.clearCanvas()
            }, 100)
        })
    }
    // 初始化点位
    initPoint() {
        let heatmap_box = document.getElementById(`heatmap-image-container`)
        let heatmap_canvas = document.getElementById(`heatmap-canvas`)
        let heatmap_image_width = heatmap_box.offsetWidth
        let heatmap_image_height = heatmap_box.offsetHeight
        heatmap_canvas.width = heatmap_image_width;
        heatmap_canvas.height = heatmap_image_height;

        this.drawCanvas(heatmap_image_width, heatmap_image_height)
    }
    drawCanvas(heatmap_image_width, heatmap_image_height) {
        let heatmap_canvas = document.getElementById(`heatmap-canvas`)
        let ctx = heatmap_canvas.getContext('2d');
        heatmap_canvas.addEventListener('click', detec);
        heatmap_canvas.addEventListener('contextmenu', detec_right);
        let list = []
        let color = "#f55186";
        let left_cnt = -1
        // 点击canvas 获取鼠标点击位置
        function detec(event) {
            let x = event.clientX - heatmap_canvas.getBoundingClientRect().left;
            let y = event.clientY - heatmap_canvas.getBoundingClientRect().top;
            list.push({
                x: Number((x / heatmap_image_width * 100).toFixed(2)),
                y: Number((y / heatmap_image_height * 100).toFixed(2))
            })
            left_cnt = left_cnt + 1
            drawPoint()
            if (list.length >= 2) {
                drawLine();
            }
        }
        // 保存点位址
        function detec_right(event) {
            event.preventDefault(); // 阻止默认菜
            left_cnt = -1

            if (list.length <= 2) {
                list = []
                _this.clearCanvas()
            } else {
                let default_answer = JSON.parse(JSON.stringify(_this.state.default_answer))
                default_answer.content = JSON.stringify(list)
                // default_answer.content = list
                let answers = JSON.parse(JSON.stringify(_this.state.answers))
                answers.push(default_answer)
                _this.setState({
                    answers: answers
                }, () => {
                    list = []
                    _this.clearCanvas()
                })
            }
        }
        // 画点
        function drawPoint() {
            for (let i in list) {
                ctx.beginPath()
                let x = list[i].x * heatmap_image_width / 100
                let y = list[i].y * heatmap_image_height / 100
                ctx.arc(x, y, 3, 0, Math.PI * 2, true);
                ctx.fillStyle = color
                ctx.fill();
                ctx.closePath();
            }
        }
        // 画线
        function drawLine() {
            ctx.beginPath();
            for (let i = left_cnt - 1; i < list.length; i++) {
                let x = list[i].x * heatmap_image_width / 100
                let y = list[i].y * heatmap_image_height / 100
                ctx.lineTo(x, y);
            }
            ctx.strokeStyle = color;
            ctx.lineWidth = 2;
            ctx.closePath();
            ctx.stroke();
        }


    }
    // 清除
    clearCanvas() {
        let heatmap_box = document.getElementById(`heatmap-image-container`)
        let canvas = document.getElementById(`heatmap-canvas`)
        canvas.width = heatmap_box.offsetWidth;
        canvas.height = heatmap_box.offsetHeight;
        for (let i = 0; i < _this.state.answers.length; i++) {
            if (_this.state.answers[i].content) {
                let list_tmp = JSON.parse(_this.state.answers[i].content)
                // let list_tmp = _this.state.answers[i].content
                _this.drawRegion(list_tmp)
            }
        }
    }
    // 画区域
    drawRegion(list_tmp) {
        let heatmap_box = document.getElementById(`heatmap-image-container`)
        let heatmap_canvas = document.getElementById(`heatmap-canvas`)
        let ctx = heatmap_canvas.getContext('2d');
        let heatmap_image_width = heatmap_box.offsetWidth
        let heatmap_image_height = heatmap_box.offsetHeight
        ctx.beginPath();
        for (let i = 0; i < list_tmp.length; i++) {
            let x = list_tmp[i].x * heatmap_image_width / 100
            let y = list_tmp[i].y * heatmap_image_height / 100
            if (i === 0) {
                ctx.moveTo(x, y);
            } else {
                ctx.lineTo(x, y);
            }
        }
        let color = "#f55186";
        ctx.strokeStyle = color;
        ctx.lineWidth = 2;
        ctx.closePath();
        ctx.stroke();
        ctx.fillStyle = "rgba(255, 81, 134, 0.4)";
        ctx.fill();
    }
    tags_confirm() {
        this.props.canShowHeatmapPopup({
            showHeatmapPopup: false,
            heatmaop_answers: this.state.answers
        })
    }
    tags_close() {
        this.props.canShowHeatmapPopup({
            showHeatmapPopup: false,
            heatmaop_answers: this.state.answers_formal
        })
    }
    // 选区描述
    handleOptionInput(index, event) {
        let answers = JSON.parse(JSON.stringify(this.state.answers))
        answers[index].name = event.target.value
        this.setState({
            answers: answers
        })
    }
    // 删除选项
    handleDeleteOption(option_index) {
        let answers = JSON.parse(JSON.stringify(this.state.answers))
        answers.splice(option_index, 1)
        this.setState({
            answers: answers
        }, () => {
            this.clearCanvas()
        })
    }
    // 选区聚焦
    handleOptionFocus(index) {
        this.clearCanvas()
        let list_tmp = JSON.parse(this.state.answers[index].content)
        // let list_tmp = this.state.answers[index].content
        this.drawRegion(list_tmp)
    }
    // 选区失焦
    handleOptionBlur(index) {
        this.clearCanvas()
    }
    render() {
        return (
            <div className='heatmap-popup-container'>
                <div className='heatmap-popup-bg'>
                    <div className='heatmap-popup-main'>
                        <div className='heatmap-popup-top'>
                            <div className='popup-top-title'>问卷复制</div>
                            <div className='popup-delete-icon'>
                                <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                            </div>
                        </div>
                        <div className='heatmap-popup-content'>
                            <div className="heatmap-left-wrapper">
                                <div className="heatmap-left-title">
                                    左键单击图片添加点，点之间自动连接，右键确定形成封闭选区
                                </div>
                                <div className="heatmap-image-container" id="heatmap-image-container">
                                    <img src={this.state.question_img} alt="" />
                                    <canvas id="heatmap-canvas"></canvas>
                                </div>
                            </div>
                            <div className="heatmap-right-wrapper">
                                <div className="heatmap-right-title">
                                    选区列表
                                </div>
                                <div className="heatmap-right-card">
                                    {
                                        this.state.answers.map((item, index) => {
                                            return <div className='card-wrapper' key={index}>
                                                {
                                                    item.content ? <>
                                                        <div className='card-input-wrapper'>
                                                            <input
                                                                placeholder="请输入选项描述"
                                                                className="card-input"
                                                                type="text"
                                                                onFocus={this.handleOptionFocus.bind(this, index)}
                                                                onBlur={this.handleOptionBlur.bind(this, index)}
                                                                onChange={this.handleOptionInput.bind(this, index)}
                                                                value={
                                                                    this.state.answers[index].name
                                                                }
                                                            />
                                                        </div>
                                                        <div className='card-delete-icon'>
                                                            <img src={cur_icon_delete} alt="" onClick={this.handleDeleteOption.bind(this, index)} />
                                                        </div>
                                                    </> : ""
                                                }
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='tag-btn'>
                            <div onClick={this.tags_confirm.bind(this)}>确认</div>
                            <div onClick={this.tags_close.bind(this)}>取消</div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
