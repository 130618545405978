import axiosInstance from '../APIUtils'

// 保存项目问卷逻辑
export function saveProjectSurveyLogic(obj) {
    return axiosInstance({
        url: "/cem/saveProjectSurveyLogic/",
        method: "POST",
        data: obj
    });
}

// 获取项目问卷逻辑
export function getProjectSurveyLogic(obj) {
    return axiosInstance({
        url: "/cem/getProjectSurveyLogic/",
        method: "POST",
        data: obj
    });
}

// 更新项目问卷逻辑
export function updateProjectSurveyLogic(obj) {
    return axiosInstance({
        url: "/cem/updateProjectSurveyLogic/",
        method: "POST",
        data: obj
    });
}