
import React, { Component } from 'react'
import './Toast.css';


let that = ''
class Toast extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "Toast",
        }
    }


    componentDidMount() {
        that = this
    }

    componentWillReceiveProps(newProps) {
        // if(!this.props.toastStatus){
        //     this.props.toastStatus = true
        // }
        // setTimeout(()=>{
        //     this.props.toastStatus = false
        // },2000)
        //    console.log(newProps)
    }



    render() {
        return (
            <div className='Toast' style={this.props.toastStatus ? { display: 'block' } : { display: 'none' }}>
                <div className='Toast_popup'></div>
                <div className='Toast_contain'>
                    <div className='Toast_content'>
                        <div className='Toast_msg'>{this.props.msg}</div>
                    </div>
                </div>
            </div>
        )
    }
}



export default Toast;