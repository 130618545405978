import React, { Component } from 'react'
import './no_product_popup.scss';
import cur_icon_close_gre from '../../../../images/cur_icon_close_gre.png'

export default class no_product_popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            msg: '您还未上传产品信息，请先到【我的产品】页面完成产品信息录入，再生成产品【派送计划】，谢谢'
        }

    }
    tags_close() {
        this.props.canShowNoProductPopup({ showNoProductPopup: false })
    }
    tags_confirm() {
        this.props.history.push(`/product_list/product_entry`)
    }
    render() {
        return (
            <div className='product-tip-popup'>
                <div className='product-tip-popup-container'>
                    <div className='product-tip-popup-top'>
                        <div className='popup-top-title'>提示</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                        </div>
                    </div>
                    <div className='popup-content'>
                        {this.state.msg}
                    </div>
                    <div className='tag-btn'>
                        <div onClick={this.tags_confirm.bind(this)}>确认</div>
                        <div onClick={this.tags_close.bind(this)}>取消</div>
                    </div>
                </div>
            </div>
        )
    }
}
