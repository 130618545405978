import React, { Component } from "react";
import { connect } from "react-redux";
import "./freecross_config_product.scss";
import { getProductSingleQuestionChart } from "../../../api/panelApi";
import XmSelect from "../../../components/Select/xm_select";
import FreecrossChart from "../../Free_cross/Freecross_chart/freecross_chart";
import FreecrossCustomChart from "../../Free_cross/Freecross_chart/freecross_custom_chart";
import { message, Select } from "antd";
class freecross_config_product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top_variable_list: [],
      left_variable_list: [],
      calculation_method_list: [],
      score_source_list: [],
      age_list: [
        { key: "0-18", value: "0-18" },
        { key: "19-22", value: "19-22" },
        { key: "23-27", value: "23-27" },
        { key: "28-35", value: "28-35" },
        { key: "35+", value: "35+" },
      ],
      selected_method_key_list: [],
      selected_age_key_list: [],
      top_variable_id: "",
      left_variable_id: "",
      score_source_id: "",
      product_id: "",
      survey_id: "",
      top_variable_datalist: {}, //顶部图表数据
      left_variable_datalist: {}, //左部图表数据
      has_top_variable_chart: true, // 选项是否有图表
      has_left_variable_chart: true, // 选项是否有图表
    };
  }
  componentDidMount() {
    this._isMounted = true;
    // this.init()
  }
  componentDidUpdate(preProps, preState) {
    if (
      preProps.top_variable_list !== this.props.top_variable_list ||
      preProps.left_variable_list !== this.props.left_variable_list ||
      preProps.calculation_method_list !== this.props.calculation_method_list ||
      preProps.score_source_list !== this.props.score_source_list ||
      preProps.age_list !== this.props.age_list ||
      preProps.product_id !== this.props.product_id ||
      preProps.survey_id !== this.props.survey_id
    ) {
      this.setState(
        {
          product_id: this.props.product_id,
          survey_id: this.props.survey_id,
          top_variable_list: this.props.top_variable_list,
          left_variable_list: this.props.left_variable_list,
          calculation_method_list: this.props.calculation_method_list,
          score_source_list: this.props.score_source_list,
          top_variable_id: this.props.top_variable_list.length
            ? this.props.top_variable_list[0].key
            : "",
          left_variable_id: this.props.left_variable_list.length
            ? this.props.left_variable_list[
              this.props.left_variable_list.length - 1
            ].key
            : "",
          selected_method_key_list: this.props.calculation_method_list.length
            ? [this.props.calculation_method_list[0].key]
            : [],
          selected_age_key_list: [],
          score_source_id: "",
        },
        () => {
          this.init();
        }
      );
    }
  }
  componentWillUnMount = () => {
    this._isMounted = false;
    this.setState = () => { };
  };
  // 将另一个选择框置灰
  resetDisabledItem(t, value_1, value_2) {
    let list = this.state[t + "_list"].filter((item) => {
      item.disabled = false;
      if (item.value === value_1) {
        item.disabled = true;
      } else if (item.value === value_2) {
        item.disabled = true;
      }
      return item;
    });
    this.setState({
      [t + "_list"]: list,
    });
  }
  //下拉框选择后，并赋值到state里面
  onSelectConfigChange(key, option, t) {
    if (this.props.user.account_type === "1") {
      if (t === "top_variable") {
        let id = this.state.top_variable_list[parseInt(key)].id;
        if (id !== 1 && id !== 2 && id !== 3) {
          message.warning(
            `基础共创的顶部选项只支持地域性别年龄区间的选择，请联系商务进行升级`,
            3,
            () => { }
          );
          return;
        }
      } else if (t === "left_variable") {
        let id = this.state.left_variable_list[parseInt(key)].id;
        if (id === 1 || id === 2 || id === 3) {
          message.warning(
            `基础共创的左侧选项不支持地域性别年龄区间的选择，请联系商务进行升级`,
            3,
            () => { }
          );
          return;
        }
      }
    }
    this.setState(
      {
        [t + "_id"]: key,
      },
      () => {
        this.resetDisabledItem(
          "top_variable",
          this.state.left_variable_list[parseInt(this.state.left_variable_id)]
            .value,
          this.state.score_source_id
            ? this.state.score_source_list[parseInt(this.state.score_source_id)]
              .value
            : ""
        );
        this.resetDisabledItem(
          "left_variable",
          this.state.top_variable_list[parseInt(this.state.top_variable_id)]
            .value,
          this.state.score_source_id
            ? this.state.score_source_list[parseInt(this.state.score_source_id)]
              .value
            : ""
        );
        this.resetDisabledItem(
          "score_source",
          this.state.top_variable_list[parseInt(this.state.top_variable_id)]
            .value,
          this.state.left_variable_list[parseInt(this.state.left_variable_id)]
            .value
        );
        if (t === "top_variable" || t === "left_variable") {
          this.getProductSingleQuestionChart(t);
        }
      }
    );
    this.props.onSelectConfigChange(key, option, t);
  }
  //获取图表
  async getProductSingleQuestionChart(t) {
    this.setState({
      [t + "_datalist"]: {},
    });
    let obj = this.getItem(t, this.state[t + "_id"]);
    let age_list = [];
    if (obj.value.indexOf("年龄") !== -1) {
      age_list = this.state.selected_age_key_list;
    }
    console.log(this.state);
    try {
      const response = await getProductSingleQuestionChart({
        product_id: this.state.product_id,
        survey_id: this.state.survey_id,
        question_type: obj.type,
        age_list: age_list,
        question_id: obj.id,
      });
      if (parseInt(response.status) === 204) {
        message.warning("暂无该选项的图表表数据");
        this.setState({
          [t + "_datalist"]: {},
          ["has_" + t + "_chart"]: false,
        });
      } else {
        this.setState({
          [t + "_datalist"]: response.data,
          ["has_" + t + "_chart"]: true,
        });
      }
    } catch (error) { }
  }
  init() {
    if (
      !this.state.selected_method_key_list.length ||
      this.state.top_variable_id === "" ||
      this.state.left_variable_id === "" ||
      this.state.product_id === ""
    ) {
      return;
    }
    let obj = {
      top_variable_id: this.state.top_variable_id,
      left_variable_id: this.state.left_variable_id,
      selected_method_key_list: this.state.selected_method_key_list,
    };
    let top_variable_list = this.state.top_variable_list;
    top_variable_list.forEach((item) => {
      if (
        item.id ===
        this.props.left_variable_list[this.props.left_variable_list.length - 1]
          .id
      ) {
        item.disabled = true;
      }
    });
    let left_variable_list = this.state.left_variable_list;
    left_variable_list[0].disabled = true;
    this.setState({
      top_variable_list: top_variable_list,
      left_variable_list: left_variable_list,
    });
    this.getProductSingleQuestionChart("top_variable");
    this.getProductSingleQuestionChart("left_variable");
    this.props.onSelectAllConfigChange(obj);
  }
  // 下拉框搜索可以大小写
  filterSelectOption(input, option) {
    return (
      option.children.props.children
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  //验证数字
  validateNumber(event) {
    if (event.target.value === "") {
      return;
    }
    event.target.value = event.target.value.replace(/^0[0-9]+/g, 0);
    event.target.value = event.target.value.replace(/[^/\-+\d]/g, "");
  }
  //下拉框低于
  onRegionSelectChange(key_arr) {
    if (key_arr.length === 0) {
      this.setState(
        {
          selected_method_key_list: key_arr,
          score_source_id: "",
        },
        () => {
          this.props.onSelectMethodConfigChange(key_arr, "");
        }
      );
      return;
    }
    let score_source_id = "";
    for (let i = 0; i < key_arr.length; i++) {
      let selected_method_key = parseInt(key_arr[i]);
      if (
        this.props.user.account_type === "1" ||
        this.props.user.account_type === "2"
      ) {
        if (
          this.state.calculation_method_list[selected_method_key].value ===
          "平均分"
        ) {
          message.warning(
            `基础共创的计算方式不支持平均分，请联系商务进行升级`,
            3,
            () => { }
          );
          return;
        }
      }
      if (
        this.state.calculation_method_list[selected_method_key].value ===
        "平均分" &&
        this.state.score_source_id !== ""
      ) {
        // 有平均分
        score_source_id = this.state.score_source_id;
      } else if (
        this.state.calculation_method_list[selected_method_key].value ===
        "平均分" &&
        this.state.score_source_id === ""
      ) {
        // 有平均分 但还没设置
        // score_source_id = this.state.score_source_list[0].key
        score_source_id = "";
      }
    }
    this.setState(
      {
        score_source_id: score_source_id,
        selected_method_key_list: key_arr,
      },
      () => {
        this.props.onSelectMethodConfigChange(key_arr, score_source_id);
      }
    );
  }
  //下拉框选择后，并赋值到age state里面
  onAgeSelectChange(key_arr) {
    if (key_arr.length === 0) {
      this.setState({
        selected_age_key_list: key_arr,
      });
      this.props.onSelectConfigChange(key_arr, [], "age_list");
      return;
    }
    let age = String(key_arr[key_arr.length - 1]);
    if (this.isConnectAge(age)) {
      if (age.indexOf("-") >= 0) {
        let [age_s, age_e] = age.split("-");
        if (Number(age_s) === 0 && Number(age_e) === 18) {
          // ok
        } else if (Number(age_s) < 15 || Number(age_s) > 70) {
          message.warning("起始年龄最小15岁,最大70岁");
          return;
        } else if (Number(age_e) < 15 || Number(age_e) > 70) {
          message.warning("结束年龄最小15岁,最大70岁");
          return;
        } else if (Number(age_s) > Number(age_e)) {
          message.warning("起始年龄需要大雨结束年龄");
          return;
        }
      } else if (age.indexOf("+") >= 0) {
        let [age_s] = age.split("+");
        if (Number(age_s) < 15 || Number(age_s) > 70) {
          message.warning("年龄最小15岁,最大70岁");
          return;
        }
      } else {
        if (Number(age) < 15 || Number(age) > 70) {
          message.warning("年龄最小15岁,最大70岁");
          return;
        }
      }
      this.setState({
        selected_age_key_list: key_arr,
      });
      this.props.onSelectConfigChange(key_arr, [], "age_list");
    } else {
      message.warning("年龄格式不正确,请输入20-30或30+或30", 2, () => { });
    }
  }
  // 判断年龄是否输入正确
  isConnectAge(age) {
    let reg = /^((\d)+-?(\d)+)|(\d)+\+$/;
    let flag = reg.test(age);
    if (!flag) {
      let reg2 = /^(\d)+\+$/;
      flag = reg2.test(age);
    }
    return flag;
  }
  // 是否有平均分
  hasSelectedAverage() {
    for (let i = 0; i < this.state.selected_method_key_list.length; i++) {
      let selected_method_key = parseInt(
        this.state.selected_method_key_list[i]
      );
      if (
        this.state.calculation_method_list[selected_method_key].value ===
        "平均分"
      ) {
        return true;
      }
    }
    return false;
  }
  // 根据key查找item
  getItem(t, id) {
    let list = JSON.parse(JSON.stringify(this.state[t + "_list"]));
    return list[parseInt(id)];
  }
  // 根据key查找value
  getValue(t, id) {
    let list = JSON.parse(JSON.stringify(this.state[t + "_list"]));
    for (let i = 0; i < list.length; i++) {
      if (list[i].key.toString() === id.toString()) {
        return list[i].value;
      }
    }
    return "";
  }
  // 是否显示年龄下拉框
  showAgeSelect() {
    if (this.state.top_variable_id === "") return false;
    let value = this.getValue("top_variable", this.state.top_variable_id);
    if (value.indexOf("年龄") !== -1) {
      return true;
    }
    return false;
  }
  // 选择图表
  chooseChart(variable_index, variable_datalist, option_position) {
    let variable_id = variable_index;
    variable_id =
      this.state[option_position + "_variable_list"][variable_index].id;
    if (variable_datalist.question_type === 11) {
      return (
        <FreecrossCustomChart
          single_id={variable_id}
          dataList={variable_datalist}
        ></FreecrossCustomChart>
      );
    }
    return (
      <FreecrossChart
        single_id={variable_id}
        dataList={variable_datalist}
      ></FreecrossChart>
    );
  }
  render() {
    return (
      <div className="freecross-config-container">
        <div className="freecross-card-wrapper top-card-wrapper">
          <div className="freecross-card-title">顶部选项</div>
          <div className="freecross-card-select">
            <XmSelect
              showSearch
              placeholder="请进行选择"
              filterOption={(input, option) => {
                return this.filterSelectOption.call(this, input, option);
              }}
              value={
                this.state.top_variable_id === ""
                  ? null
                  : this.state.top_variable_id
              }
              options={this.state.top_variable_list}
              onChange={(e, index) => {
                this.onSelectConfigChange(e, index, "top_variable");
              }}
            ></XmSelect>
          </div>
          {this.showAgeSelect() ? (
            <div className="freecross-card-select freecross-card-select-age">
              <XmSelect
                mode="tags"
                placeholder="请输入年龄,如20-30或35+"
                onChange={(e) => {
                  this.onAgeSelectChange.call(this, e);
                }}
                maxTagCount={"responsive"}
                allowClear
                options={this.state.age_list}
                value={this.state.selected_age_key_list}
                onInput={this.validateNumber.bind(this)}
                iskey="true"
              ></XmSelect>
            </div>
          ) : (
            ""
          )}
          {this.state.top_variable_id !== "" &&
            this.state.has_top_variable_chart
            ? this.chooseChart(
              this.state.top_variable_id,
              this.state.top_variable_datalist,
              "top"
            )
            : ""}
        </div>
        <div className="freecross-card-wrapper">
          <div className="freecross-card-title">左侧选项</div>
          <div className="freecross-card-select">
            <XmSelect
              showSearch
              placeholder="请进行选择"
              filterOption={(input, option) => {
                return this.filterSelectOption.call(this, input, option);
              }}
              value={
                this.state.left_variable_id === ""
                  ? null
                  : this.state.left_variable_id
              }
              options={this.state.left_variable_list}
              onChange={(e, index) => {
                this.onSelectConfigChange(e, index, "left_variable");
              }}
            ></XmSelect>
          </div>
          {this.state.left_variable_id !== "" &&
            this.state.has_left_variable_chart
            ? this.chooseChart(
              this.state.left_variable_id,
              this.state.left_variable_datalist,
              "left"
            )
            : ""}
        </div>
        <div className="freecross-card-wrapper">
          <div className="freecross-card-title">计算方式</div>
          <div className="freecross-card-select">
            <XmSelect
              mode="multiple"
              placeholder="请进行选择"
              onChange={(e) => {
                this.onRegionSelectChange.call(this, e);
              }}
              maxTagCount={"responsive"}
              allowClear
              options={this.state.calculation_method_list}
              value={this.state.selected_method_key_list}
              filterOption={(input, option) => {
                return this.filterSelectOption.call(this, input, option);
              }}
            ></XmSelect>
          </div>
        </div>
        <div className="freecross-card-wrapper">
          <div className="freecross-card-title">分数来源</div>
          {this.hasSelectedAverage() && this.state.score_source_list.length ? (
            <div className="freecross-card-select">
              <XmSelect
                showSearch
                placeholder="请进行选择"
                filterOption={(input, option) => {
                  return this.filterSelectOption.call(this, input, option);
                }}
                value={
                  this.state.score_source_id === ""
                    ? null
                    : this.state.score_source_id
                }
                options={this.state.score_source_list}
                onChange={(e, index) => {
                  this.onSelectConfigChange(e, index, "score_source");
                }}
              ></XmSelect>
            </div>
          ) : (
            <div className="freecross-card-text">暂无分数来源</div>
          )}
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    user: state.user,
  }), //映射状态
  {} //映射操作状态的方法
)(freecross_config_product);
