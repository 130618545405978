import React, { Component } from 'react'
import './maxdiff_list.scss';
import { Tabs, message, ConfigProvider, Table } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import cur_icon_search from "../../../images/emotion_analysis/cur_icon_search.png"
import { Input } from 'antd';
const { TabPane } = Tabs;

export default class open_blank_list extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'CommentCustomChart',
            dataList: {},
            needRefresh: false,
            columns: [],
            search_text: "",
            table_list: [],
            sortedInfo: {
                // columnKey: "option_occurance",
                // order: "ascend"
            }
        };
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList,
                search_text: ""
            }
        }
        return null;

    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            this.toInitChart()
        }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    toInitChart() {
        let table_list = []
        let columns = []
        // 拿column
        let cur_table_list = []
        if (this.props.dataList.data_list.length) {
            cur_table_list = this.props.dataList.data_list
            columns = this.initColumn(cur_table_list[0], this.state.sortedInfo)
        }
        let cmp = function (a, b) {
            return b.preference - a.preference;
        };
        table_list = this.props.dataList.data_list.sort(cmp).filter((item, index) => {
            item = Object.assign({}, item, { id: "table_list_" + index })
            return item
        })
        this.setState({
            table_list: table_list,
            columns: columns
        })
    }
    handleChange(pagination, filters, sorter) {
        let columns = []
        let cur_table_list = []
        if (this.props.dataList.data_list.length) {
            cur_table_list = this.props.dataList.data_list
            columns = this.initColumn(cur_table_list[0], sorter)
        }
        this.setState({
            sortedInfo: sorter,
            columns: columns
        })
    }
    initColumn(table_item_obj, sortedInfo) {
        let columns = []
        for (var key in table_item_obj) {
            if (key === "option_name") {
                let tmp = {
                    title: '选项',
                    dataIndex: 'option_name',
                    key: 'option_name',
                    align: 'center',
                }
                columns.push(tmp)
            } else if (key === "preference") {
                let tmp = {
                    title: '偏好份额',
                    dataIndex: 'preference',
                    key: 'preference',
                    align: 'center',
                    sorter: (a, b) => a.preference - b.preference,
                    sortOrder: sortedInfo.columnKey === 'preference' ? sortedInfo.order : null,
                    render: (text) => {
                        return (Number(text) * 100).toFixed(2) + "%"
                    }
                }
                columns.push(tmp)
            } else if (key === "option_max_selected_count") {
                let tmp = {
                    title: '最重要',
                    dataIndex: 'option_max_selected_count',
                    key: 'option_max_selected_count',
                    align: 'center',
                    sorter: (a, b) => a.option_max_selected_count - b.option_max_selected_count,
                    sortOrder: sortedInfo.columnKey === 'option_max_selected_count' ? sortedInfo.order : null,
                }
                columns.push(tmp)
            } else if (key === "option_min_selected_count") {
                let tmp = {
                    title: '最不重要',
                    dataIndex: 'option_min_selected_count',
                    key: 'option_min_selected_count',
                    align: 'center',
                    sorter: (a, b) => a.option_min_selected_count - b.option_min_selected_count,
                    sortOrder: sortedInfo.columnKey === 'option_min_selected_count' ? sortedInfo.order : null,
                }
                columns.push(tmp)
            } else if (key === "option_occurance") {
                let tmp = {
                    title: '出现次数',
                    dataIndex: 'option_occurance',
                    key: 'option_occurance',
                    align: 'center',
                    sorter: (a, b) => a.option_occurance - b.option_occurance,
                    sortOrder: sortedInfo.columnKey === 'option_occurance' ? sortedInfo.order : null,
                }
                columns.push(tmp)
            } else if (key === "score") {
                let tmp = {
                    title: '分数',
                    dataIndex: 'score',
                    key: 'score',
                    align: 'center',
                    sorter: (a, b) => a.score - b.score,
                    sortOrder: sortedInfo.columnKey === 'score' ? sortedInfo.order : null,
                }
                columns.push(tmp)
            }
        }
        return columns
    }
    showPagination() {
        if (this.state.table_list.length < 30) {
            return false
        }
        return { pageSize: 30, showSizeChanger: false, showQuickJumper: true, size: "small" }
    }
    render() {
        const prefix = (
            <img src={cur_icon_search} alt="" className='evaluation-icon-search' />
        )
        return (
            <div className="maxdiff-list-container">
                <ConfigProvider locale={zhCN}>
                    <Table
                        dataSource={this.state.table_list}
                        onChange={this.handleChange.bind(this)}
                        columns={this.state.columns}
                        pagination={this.showPagination()}
                        rowKey={columns => columns.id}
                        scroll={{ y: this.props.isMaxChart ? this.props.blankListHeight : 258 }} />
                </ConfigProvider>
            </div>
        )
    }
}
