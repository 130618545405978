import React, { Component } from "react";
import "./freecross_product.scss";
import {
  getProductCrossAnalysisInfo,
  getProductCrossAnalysisTable,
  getBrandSurvey,
  getProductSingleQuestionChart,
} from "../../api/panelApi";
import ProductDropdown from "../Cem_product_dropdown/product_dropdown";
import Loading from "../../components/Loading/loading";
import { download, getQueryParameter, saveImgZip } from "../../util/utils";
import { message } from "antd";
import FreecrossConfigProduct from "./Freecross_config_product/freecross_config_product";
import FreecrossInfoProduct from "./Freecross_info_product/freecross_info_product";

export default class freecross_product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0,
      loadingText: "加载中",
      brand_id: localStorage.getItem("brand_id")
        ? localStorage.getItem("brand_id")
        : 1, //品牌ID
      product_id: localStorage.getItem("product_id_cem")
        ? localStorage.getItem("product_id_cem")
        : "", //产品ID
      survey_id: localStorage.getItem("project_survey_id_cem") ? localStorage.getItem("project_survey_id_cem") : 0, //问卷ID
      top_variable_list: [],
      left_variable_list: [],
      calculation_method_list: [],
      score_source_list: [],
      table_list: [],
      selected_method_key_list: [],
      selected_age_key_list: [],
      top_variable_id: "",
      left_variable_id: "",
      score_source_id: "",
    };
  }
  componentDidMount() {
    this._isMounted = true;
    // this.init()
  }
  componentWillUnMount = () => {
    this._isMounted = false;
    this.setState = () => { };
  };
  //清空数据
  clearData() {
    this.setState({
      table_list: [],
      selected_method_key_list: [],
      selected_age_key_list: [],
      top_variable_id: "",
      left_variable_id: "",
      score_source_id: "",
    });
  }
  // 品牌产品下拉框传来的数据
  handleProductChange(id, survey_id) {
    if (id === "-1") {
      // 没有品牌 没有产品
      message.warning("没有品牌的数据", 2, () => { });
      this.clearData();
      this.setState({
        loading: 0,
      });
      return;
    }
    if (id === "") {
      // 只有品牌 没有产品
      message.warning("没有该产品的数据", 2, () => { });
      this.clearData();
      this.setState({
        loading: 0,
        product_id: "",
        top_variable_list: [],
        left_variable_list: [],
        calculation_method_list: [],
        score_source_list: [],
      });
      return;
    } else if (id !== this.state.product_id) {
      this.clearData();
    }
    this.setState(
      {
        product_id: id,
        survey_id: survey_id,
      },
      () => {
        this.getProductCrossAnalysisInfo();
      }
    );
  }
  // 将返回只有字符串的转为key和value
  getObjectArray(array) {
    return array.map((ele, index) => {
      return { key: index, value: ele };
    });
  }
  // 将返回为id和value的 转换为key和value
  getKeyObjectArray(array) {
    return array.map((ele, index) => {
      return {
        key: index,
        value: ele.value,
        type: ele.type,
        disabled: false,
        id: ele.id,
      };
    });
  }
  // 获取产品问卷自由交叉分析表格
  async getProductCrossAnalysisTable() {
    if (!this.state.selected_method_key_list.length) {
      message.warning("请选择计算方式", 2, () => { });
      return;
    } else if (
      this.state.left_variable_id === "" &&
      this.state.top_variable_id === ""
    ) {
      message.warning("请选择顶部选项或左侧选项", 2, () => { });
      return;
    } else if (
      this.getValue("left_variable", this.state.left_variable_id) ===
      this.getValue("top_variable", this.state.top_variable_id)
    ) {
      this.setState({ table_list: [] });
      message.warning("顶部选项与左侧选项不可以一样，请重新选择");
      return;
    }
    // else if (this.state.left_variable_list[this.state.left_variable_id].value === this.state.top_variable_list[this.state.top_variable_id].value) {
    //     this.setState({ table_list: [] })
    //     message.warning('顶部选项与左侧选项不可以一样，请重新选择')
    //     return
    // }
    for (let i = 0; i < this.state.selected_method_key_list.length; i++) {
      let selected_method_key = parseInt(
        this.state.selected_method_key_list[i]
      );
      if (
        this.state.calculation_method_list[selected_method_key].value ===
        "平均分" &&
        this.state.score_source_id === ""
      ) {
        message.warning("请选择分数来源", 2, () => { });
        return;
      }
    }
    this.setState({ loading: 1 });
    try {
      const response = await getProductCrossAnalysisTable({
        age_list: this.state.selected_age_key_list,
        // left_variable: this.state.left_variable_id === "" ? "" : this.state.left_variable_list[this.state.left_variable_id].value,
        // top_variable: this.state.top_variable_id === "" ? "" : this.state.top_variable_list[this.state.top_variable_id].value,
        left_variable:
          this.state.left_variable_id === ""
            ? ""
            : this.getValue("left_variable", this.state.left_variable_id),
        top_variable:
          this.state.top_variable_id === ""
            ? ""
            : this.getValue("top_variable", this.state.top_variable_id),
        calculation_method: this.state.selected_method_key_list.map(
          (ele, index) => {
            return this.state.calculation_method_list[ele].value;
          }
        ),
        // score_source: this.state.score_source_id === "" ? "" : this.state.score_source_list[this.state.score_source_id].value,
        score_source:
          this.state.score_source_id === ""
            ? ""
            : this.getValue("score_source", this.state.score_source_id),
        special_event_id: "",
        product_id: this.state.product_id,
        survey_id: this.state.survey_id,
      });
      this.setState({ loading: 0 });
      if (parseInt(response.status) === 204) {
        message.warning(`暂无自由交叉分析数据`, 2, () => { });
        this.setState({
          table_list: [],
        });
      } else {
        let table_list = [];
        for (let i = 0; i < response.data.length; i++) {
          let columns = [];
          let table_data_list = [];
          if (response.data[i].table_data_list.length) {
            columns = this.initColumn(response.data[i].column);
            table_data_list = response.data[i].table_data_list.map(
              (item, index) => {
                item["id"] = index;
                return item;
              }
            );
          }
          table_list.push({
            table_name: response.data[i].table_name,
            table_data_list: table_data_list,
            columns: columns,
          });
        }
        this.setState({
          table_list: table_list,
        });
      }
      return response;
    } catch (error) {
      this.setState({ loading: 0 }, () => {
        message.error("后台发生错误");
      });
      throw error;
    }
  }
  // 获取产品问卷自由交叉分析
  async getProductCrossAnalysisInfo() {
    this.setState({ loading: 1 });
    try {
      const response = await getProductCrossAnalysisInfo({
        product_id: this.state.product_id,
        brand_id: localStorage.getItem("brand_id")
          ? localStorage.getItem("brand_id")
          : "",
        survey_id: this.state.survey_id,
      });
      this.setState({ loading: 0 });
      if (parseInt(response.status) === 204) {
        message.warning(`暂无配置数据`, 2, () => { });
        this.setState({
          calculation_method_list: [],
          left_variable_list: [],
          score_source_list: [],
          top_variable_list: [],
        });
      } else {
        let { calculation_method, left_variable, score_source, top_variable } =
          response.data;
        this.setState({
          top_variable_list: this.getKeyObjectArray(top_variable),
          left_variable_list: this.getKeyObjectArray(left_variable),
          calculation_method_list: this.getObjectArray(calculation_method),
          score_source_list: this.getKeyObjectArray(score_source),
        });
      }
      return response;
    } catch (error) {
      this.setState({ loading: 0 }, () => {
        message.error("后台发生错误");
      });
      throw error;
    }
  }
  // 配置方法变化
  onSelectMethodConfigChange(selected_method_key_list, score_source_id) {
    this.setState(
      {
        selected_method_key_list: selected_method_key_list,
        score_source_id: score_source_id,
      },
      () => {
        this.getProductCrossAnalysisTable();
      }
    );
  }
  // 配置变化则变化
  onSelectConfigChange(key, option, t) {
    if (t === "age_list") {
      this.setState({ selected_age_key_list: key }, () => [
        this.getProductCrossAnalysisTable(),
      ]);
    } else {
      this.setState({ [t + "_id"]: key }, () => {
        this.getProductCrossAnalysisTable();
      });
    }
  }
  onSelectAllConfigChange(obj) {
    let { top_variable_id, left_variable_id, selected_method_key_list } = obj;
    this.setState(
      {
        top_variable_id,
        left_variable_id,
        selected_method_key_list,
      },
      () => {
        this.getProductCrossAnalysisTable();
      }
    );
  }
  // 根据key查找value
  getValue(t, id) {
    if (id === "") return "";
    let list = JSON.parse(JSON.stringify(this.state[t + "_list"]));
    return list[parseInt(id)].value;
  }
  // 初始化table列表
  initColumn(table_item_obj) {
    let columns = [];
    for (let i = 0; i < table_item_obj.length; i++) {
      let tmp = {
        title: table_item_obj[i],
        dataIndex: table_item_obj[i],
        key: table_item_obj[i],
        // width: 200
      };
      if (i) {
        // tmp.width = 150
        tmp.align = "center";
      } else {
        tmp.title = "";
        // tmp.width = 300
      }
      columns.push(tmp);
    }
    return columns;
  }
  render() {
    return (
      <div className="container product-freecross-container">
        {this.state.loading <= 0 ? (
          ""
        ) : (
          <Loading loadingText={this.state.loadingText}></Loading>
        )}
        <div className="overall-top">
          <ProductDropdown
            handleProductChange={this.handleProductChange.bind(this)}
            is_freecross={true}
          ></ProductDropdown>
          {/* <div className="top-right" onClick={this.addData.bind(this)}>
                    <img src={overall_icon_add} alt="" />
                    添加数据-敬请期待
                </div> */}
        </div>
        <div className="product-freecross-main">
          <FreecrossInfoProduct
            table_list={this.state.table_list}
            product_id={this.state.product_id}
          ></FreecrossInfoProduct>
          <FreecrossConfigProduct
            survey_id={this.state.survey_id}
            product_id={this.state.product_id}
            top_variable_list={this.state.top_variable_list}
            left_variable_list={this.state.left_variable_list}
            calculation_method_list={this.state.calculation_method_list}
            score_source_list={this.state.score_source_list}
            onSelectConfigChange={this.onSelectConfigChange.bind(this)}
            onSelectMethodConfigChange={this.onSelectMethodConfigChange.bind(
              this
            )}
            onSelectAllConfigChange={this.onSelectAllConfigChange.bind(this)}
          ></FreecrossConfigProduct>
        </div>
      </div>
    );
  }
}
