import React, { Component } from "react";
import "./Upload.css";
import UploadPopup from "./Upload_Popup/Upload_Popup";
import upload_add from "../../images/upload_add_img.png";
import upload_close_img from "../../images/upload_close.png";
import { message } from "antd";
import { deleteProductImage } from "../../api/token";
var that = "";
class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "Upload",
      list: [{ file: "" }],
      isShow: false, //是否展示删除弹框
      img_index: 0, //需要删除图片的下标
      flag: "", //从哪个页面进入的
    };
  }

  componentDidMount() {
    that = this;
  }

  handleImageChange(index, e) {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      // console.log(file.name);
      // console.log(reader.result);
      let isLt1M = file.size / 1024 / 1024 < 1;
      let isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg";
      if (!isJPG) {
        message.warning("图片格式只能是jpg、jpeg、png", 2, () => {});
        return;
      }
      let width = 0;
      let height = 0;
      let img = reader.result;
      let list = this.state.list;
      let domImage = document.getElementById("image" + index);
      domImage.src = reader.result;
      if (!isLt1M) {
        message.warning("图片大小要小于1MB", 2, () => {});
        return;
      }
      setTimeout(() => {
        width = domImage.width;
        height = domImage.height;
        if (width && height && width !== height) {
          message.warning("图片尺寸需要1:1", 2, () => {});
          return;
        }
        if (
          width > 0 &&
          width == height &&
          isLt1M &&
          isJPG &&
          list.length <= this.props.max_number
        ) {
          list[index].file = img;
          list[index].type = 0;
          list[index].filename = file.name;
          list.push({ file: "" });
          this.setState({
            list: list,
          });
          this.props.getImgs(list); //传递图片给父组件
        }
      });
    };
    reader.readAsDataURL(file);
  }

  componentWillReceiveProps(obj) {
    // let list = obj.product_imgs ? obj.product_imgs : []
    let list = JSON.parse(
      JSON.stringify(obj.product_imgs ? obj.product_imgs : [])
    ); //！！！否则会修改父组件的file
    if (
      list.length > 0 &&
      list.length < obj.max_number &&
      obj.flag === "product_entry"
    ) {
      if (
        !list[list.length - 1].hasOwnProperty("file") ||
        list[list.length - 1]["file"] !== ""
      ) {
        list.push({ file: "" });
      }
      this.setState({
        list,
      });
    }
    this.setState({
      flag: obj.flag,
    });
  }

  upload_Is_Show(index, e) {
    //出现弹框是否需要删除
    this.setState({
      isShow: true,
      img_index: index,
    });
  }

  Get_Is_Show_Status(obj) {
    that.setState({
      isShow: obj.isShow,
    });
    if (obj.is_delete) {
      //删除指定位置的图片
      let list = that.state.list;
      let delImg = list.splice(that.state.img_index, 1);
      console.log(delImg);
      if (delImg[0].id) {
        deleteProductImage({ id: delImg[0].id }).then(
          (res) => {
            that.setState({ list });
            that.props.getImgs(list);
          },
          (err) => {}
        );
      } else {
        that.setState({ list });
        that.props.getImgs(list);
      }
    }
  }

  render() {
    return (
      <div className="upload">
        {this.state.list.map((item, index) => {
          return (
            <div
              className={
                item.file || item.url ? "upload_item isHover" : "upload_item"
              }
              key={"upload_" + index}
              style={index < this.props.max_number ? {} : { display: "none" }}
            >
              <input
                id={"avatarFor" + index}
                style={{ display: "none" }}
                type="file"
                value=""
                onChange={this.handleImageChange.bind(this, index)}
              />
              <label
                className="upload_bg"
                htmlFor={"avatarFor" + index}
                style={
                  item.file || item.url
                    ? { background: "url(" + (item.file || item.url) + ")" }
                    : { background: "url(" + upload_add + ")" }
                }
              ></label>
              <div className="upload_popup"></div>
              <img
                className="upload_popup_img"
                src={upload_close_img}
                onClick={this.upload_Is_Show.bind(this, index)}
                alt=""
              />
              <img src="" id={"image" + index} className="domImg" alt="" />
            </div>
          );
        })}
        <div className={this.state.isShow ? "Upload_Popup" : "isHide"}>
          <UploadPopup
            isShow={this.state.isShow}
            Get_Is_Show_Status={this.Get_Is_Show_Status}
          ></UploadPopup>
        </div>
      </div>
    );
  }
}

export default Upload;
