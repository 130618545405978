import React, { Component } from 'react'
import './CommentCustomChart.scss';
import { getCustomBarOption } from "../../util/Chart_config/Custom_chart_config";
import MaxChartPopup from './Popup/max_chart_popup'
import { formatChart } from "../../util/Chart_config/Config";
import { exportImg } from '../../util/utils'
import { Image } from 'antd';

export default class CommentCustomChart extends Component {
    constructor(props) {
        super(props);
        this.maxChartRef = React.createRef();
        this.state = {
            name: 'CommentCustomChart',
            dataList: {},
            needRefresh: false,
            isMaxSreen: false,
            showMaxChartPopup: false, // 是否显示放大图表的对话框
            isMouseOn: false,//鼠标是否悬浮
            showQuestionName: false,// 是否显示问题名称
            enlarge_question_img: false,//是否显示放大图片
        };
    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            this.toInitChart()
            setTimeout(() => {
                this.toInitFoot()
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.toInitChart()
            setTimeout(() => {
                this.toInitFoot()
            })
            this.setState({
                needRefresh: false
            })
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList
            }
        }
        return null;

    }
    toInitChart() {
        let id = "chart_" + this.props.single_id
        if (this.state.showMaxChartPopup) {
            id = "max_" + id
        }
        let xAxisList = this.props.dataList.x_axis
        let data = this.props.dataList.data

        let sum = data.reduce(function (prev, curr) {
            return prev + curr;
        }, 0);

        let option = getCustomBarOption(xAxisList, data, sum)

        formatChart(id, option)
        if (this.state.showMaxChartPopup) {
            setTimeout(() => {
                this.toInitFootMax()
            }, 10)
        }
    }
    // 生产三个title的下标
    toInitFoot() {
        let single_id = this.props.single_id
        let canvas = document.querySelector(`.custom_${single_id}`);
        if (!canvas) return
        let canvas_height = window.getComputedStyle(canvas).height;
        let canvas_height_val = parseInt(canvas_height.slice(0, canvas_height.length - 2))

        let foot = document.querySelector(`.custom-chart-foot`);
        foot.style.top = 0
        foot.style.marginTop = canvas_height_val - 45 + "px"

        let count_arr = this.props.dataList.word_bar_count_arr
        let sum = count_arr.reduce(function (prev, curr) {
            return prev + curr;
        }, 0);

        // 设置三个下标的距离
        let count_width_arr = []
        count_arr.forEach((item, index) => {
            let foot_div = document.querySelector(`.foot-name-${index}`);
            let tmp_width = parseInt(item) / sum * 100
            count_width_arr[index] = tmp_width
            if (index === 0) {
                foot_div.style.cssText = `width: ${tmp_width}%;`
            } else if (index === 1) {
                foot_div.style.cssText = `width: ${tmp_width}%`
            } else if (index === 2) {
                foot_div.style.cssText = `width: ${tmp_width}%;right: 0;`
                let foot_div_1 = document.querySelector(`.foot-name-1`);
                foot_div_1.style.cssText = `width: ${count_width_arr[1]}%;right:${tmp_width}%;`
            }
        })
    }
    toInitFootMax() {
        let id = "max_chart_" + this.props.single_id
        let canvas = document.getElementById(id)
        if (!canvas) return
        let canvas_width = window.getComputedStyle(canvas).width;
        let canvas_width_val = parseInt(canvas_width.slice(0, canvas_width.length - 2))
        let canvas_height = window.getComputedStyle(canvas).height;
        let canvas_height_val = parseInt(canvas_height.slice(0, canvas_height.length - 2))

        let card_chart_rect = canvas.getBoundingClientRect();
        let card_chart_rect_top = card_chart_rect.top
        let card_chart_rect_left = card_chart_rect.left
        let foot = document.querySelector(`.max-custom-chart-foot`);
        foot.style.top = 0
        foot.style.left = parseInt(card_chart_rect_left + 48) + "px"
        foot.style.marginTop = parseInt(canvas_height_val + card_chart_rect_top - 45) + "px"
        foot.style.width = parseInt(canvas_width_val - 58) + "px"

        let count_arr = this.props.dataList.word_bar_count_arr
        let sum = count_arr.reduce(function (prev, curr) {
            return prev + curr;
        }, 0);

        // 设置三个下标的距离
        let count_width_arr = []
        count_arr.forEach((item, index) => {
            let foot_div = document.querySelector(`.max-foot-name-${index}`);
            let tmp_width = parseInt(item) / sum * 100
            count_width_arr[index] = tmp_width
            if (index === 0) {
                foot_div.style.cssText = `width: ${tmp_width}%;`
            } else if (index === 1) {
                foot_div.style.cssText = `width: ${tmp_width}%`
            } else if (index === 2) {
                foot_div.style.cssText = `width: ${tmp_width}%;right: 0;`
                let foot_div_1 = document.querySelector(`.max-foot-name-1`);
                foot_div_1.style.cssText = `width: ${count_width_arr[1]}%;right:${tmp_width}%;`
            }
        })
    }
    //下载图表
    downloadChart() {
        let id = "chart_" + this.props.single_id + "_wrapper"
        if (this.state.showMaxChartPopup) {
            id = "max_chart_" + this.props.single_id + "_wrapper"
        }
        exportImg(
            document.getElementById(id),
            this.props.dataList.name
        );
    }
    // 子组件传给父组件状态 是否放大图表
    canShowMaxChartPopup(object) {
        this.setState({
            showMaxChartPopup: object.showMaxChartPopup,
        })
    }
    // 放大图表
    handleMaxChart() {
        this.maxChartRef.current.setMaxChart({
            dataList: this.props.dataList,
            single_id: this.props.single_id,
            isConsumer: false
        })
        this.setState({
            showMaxChartPopup: true,
        })
    }
    // 鼠标移入
    onMouseOver = () => {
        let name_div = document.querySelector(`.title-left-${this.props.single_id}`)
        if (name_div.clientHeight >= name_div.scrollHeight) { // 没有溢出
            if (this.props.dataList.question_img) {
                let name_div_width = name_div.offsetWidth - 2
                let question_hover_div = document.querySelector(`.question-hover-card-${this.props.single_id}`)
                question_hover_div.style.width = name_div_width + "px"
                this.setState({
                    isMouseOn: true,
                    showQuestionName: false
                }, () => {
                    let tmp_height = question_hover_div.offsetHeight + 8
                    question_hover_div.style.top = "-" + tmp_height + "px"
                })
            }
            return
        }
        this.setState({
            isMouseOn: true,
            showQuestionName: true
        }, () => {
            let name_div_width = name_div.offsetWidth - 2
            let question_hover_div = document.querySelector(`.question-hover-card-${this.props.single_id}`)
            let tmp_height = question_hover_div.offsetHeight + 8
            question_hover_div.style.top = "-" + tmp_height + "px"
            question_hover_div.style.width = name_div_width + "px"

        })
    }
    // 鼠标移出
    onMouseOut = (index) => {
        this.setState({ showQuestionName: false, isMouseOn: false });
    }
    // 放大图片
    enlargeQuestionImg(value) {
        this.setState({
            enlarge_question_img: value
        })
    }
    render() {
        let single_id = String(this.props.single_id) || ""
        let name = this.props.dataList.name || ""
        let question_img = this.props.dataList.question_img || ""
        return (
            <div className='pannel-card pannel-card-custom'>
                <div className='card-title-wrapper'>
                    {/* <Tooltip placement="top" title={name}>
            <span className='title-left'>{name}</span>
          </Tooltip> */}
                    <div className='title-left-wrapper' onMouseOver={() => this.onMouseOver()} onMouseOut={() => this.onMouseOut()}>
                        <div className={`title-left title-left-${this.props.single_id}`}>{name}</div>
                        <div className={`question-hover-card question-hover-card-${this.props.single_id} ${this.state.isMouseOn ? "" : "isHide"} `}>
                            <div className="question-hover-card-arrow"></div>
                            {
                                this.state.showQuestionName ? <div className="question-hover-card-name">{name}</div> : ""
                            }
                            {
                                question_img ? <div className="question-hover-card-img"><img src={question_img} alt="" /></div> : ""
                            }
                        </div>
                    </div>
                    {
                        question_img ? <img src={require("../../images/overall_data/pic_enlarge.png").default} alt='' className='title-right-pic' onClick={this.enlargeQuestionImg.bind(this, true)} /> : ""
                    }
                    <Image
                        style={{ display: 'none' }}
                        preview={{
                            visible: this.state.enlarge_question_img,
                            src: question_img,
                            onVisibleChange: (value) => {
                                this.enlargeQuestionImg(value)
                            },
                        }}
                    />
                </div>
                <div className='chart-operaion-wrapper'>
                    <div className='chart-change-wrapper'>
                        <div className="chart-change-text" onClick={this.handleMaxChart.bind(this)}>放大</div>
                        <div className="chart-change-sign">|</div>
                        <div className="chart-change-text" onClick={this.downloadChart.bind(this)}>下载</div>
                    </div>
                </div>
                <div className="custom-chart-father" id={`chart_${single_id}_wrapper`}>
                    <div id={`chart_${single_id}`} className={`echart-container custom-echart custom_${single_id}`}></div>
                    <div className="custom-chart-foot">
                        <div className="foot-name foot-name-0">
                            <div className="line"></div>
                            <div className="sign"></div>
                            <div className="content">贬损者</div>
                        </div>
                        <div className="foot-name foot-name-1">
                            <div className="line"></div>
                            <div className="sign"></div>
                            <div className="content">被动者</div>
                        </div>
                        <div className="foot-name foot-name-2">
                            <div className="line"></div>
                            <div className="sign"></div>
                            <div className="content">推荐者</div>
                        </div>
                    </div>
                    <div className={this.state.showMaxChartPopup ? 'chart-wrapper-2' : 'isHide'}>
                        <MaxChartPopup ref={this.maxChartRef}
                            canShowMaxChartPopup={this.canShowMaxChartPopup.bind(this)}
                            downloadChart={this.downloadChart.bind(this)}
                            toInitChart={this.toInitChart.bind(this)}></MaxChartPopup>
                    </div>
                </div>
            </div >
        )
    }
}
