import React, { Component } from "react";
import { connect } from "react-redux";
import "./Evaluation.scss";
import data_icon_mod from "../../../images/emotion_analysis/data_icon_mod.png";
import data_icon_list from "../../../images/emotion_analysis/data_icon_list.png";
import data_icon_mod_cur from "../../../images/emotion_analysis/data_icon_mod_cur.png";
import data_icon_list_cur from "../../../images/emotion_analysis/data_icon_list_cur.png";
import cur_icon_search from "../../../images/emotion_analysis/cur_icon_search.png";
import level_icon_2 from "../../../images/emotion_analysis/emotion_level/level_icon_good.png"; // 好评
import level_icon_2_active from "../../../images/emotion_analysis/emotion_level/level_icon_good_active.png";
import level_icon_1 from "../../../images/emotion_analysis/emotion_level/level_icon_middle.png"; // 中评
import level_icon_1_active from "../../../images/emotion_analysis/emotion_level/level_icon_middle_active.png";
import level_icon_0 from "../../../images/emotion_analysis/emotion_level/level_icon_bad.png"; // 差评
import level_icon_0_active from "../../../images/emotion_analysis/emotion_level/level_icon_bad_active.png";

import check_1 from "../../../images/emotion_analysis/emotion_level/check_1.png";
import check_2 from "../../../images/emotion_analysis/emotion_level/check_2.png";
import check_3 from "../../../images/emotion_analysis/emotion_level/check_3.png";
import check_4 from "../../../images/emotion_analysis/emotion_level/check_4.png";
import XmSelect from "../../../components/Select/xm_select";
import Loading from "../../../components/Loading/loading";
import {
  getXhsIncentiveList,
  translateContentLanguage,
  translateContent,
} from "../../../api/panelApi";
import { isObjectEqual } from "../../../util/utils";

import {
  Input,
  Checkbox,
  Table,
  Pagination,
  ConfigProvider,
  message,
} from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import TranslationPanel from "../Translation_panel/translation_panel";
import WaterfallEvaluation from "./Waterfall_evaluation";
import FilterSelect from "../../Overall_data/Filter_chart_select/filter_select";
import IncentivePopup from "./Popup/incentive_popup";
import IncentiveListPopup from "./Popup/incentive_list_popup";
import { incentiveStatus, incentiveStatusList } from "./util";
class Evaluation extends Component {
  constructor(props) {
    super(props);
    this.filterRef = React.createRef();
    this.state = {
      list_show_type: 0, //0是box 1是列表
      evaluation_level_list: [],
      evaluation_word_list: [],
      columns: [
        {
          title: "序号",
          dataIndex: "user_id",
          key: "user_id",
          width: 50,
          render: (text, record, index) => `${index + 1}`,
        },
        {
          title: "",
          dataIndex: "",
          key: "check",
          width: 50,
          render: (item, rowIndex) => {
            let checkStatus = {
              1: check_1,
              2: check_2,
              3: check_3,
              4: check_4,
            };
            return (
              <img
                className={
                  item.check === 1 || item.check === 2
                    ? "check-btn"
                    : "check-btn-disabled"
                }
                src={checkStatus[item.check]}
                alt=""
                width="16px"
                height="16px"
                onClick={this.hanldleCheck.bind(this, item)}
              />
            );
          },
        },
        {
          title: "审核状态",
          dataIndex: "status",
          key: "status",
          width: 100,
          render: (text) => {
            return <span>{incentiveStatus[text]}</span>;
          },
        },
        {
          title: "审核总次数",
          dataIndex: "sort",
          key: "sort",
          width: 110,
          render: (text) => {
            if (text === 0) {
              return "/";
            } else {
              return text;
            }
          },
        },
        {
          title: "产品图片",
          dataIndex: "comment_image",
          key: "comment_image",
          width: 100,
          render: (text) => {
            if (text) {
              return <img src={text} alt="" width="40px" height="40px" />;
            } else {
              return "未传图片";
            }
          },
        },
        {
          title: "用户名称",
          dataIndex: "username",
          key: "username",
          width: 100,
        },
        {
          title: "年龄",
          dataIndex: "age",
          key: "age",
          width: 60,
        },
        {
          title: "性别",
          dataIndex: "gender",
          key: "gender",
          width: 60,
        },
        {
          title: "职业",
          dataIndex: "occupation",
          key: "occupation",
          width: 60,
        },
        {
          title: "地址",
          dataIndex: "address",
          key: "address",
          width: 60,
        },
        {
          title: "评价",
          dataIndex: "content",
          key: "content",
          // width: 300,
          // ellipsis: {
          //     showTitle: false,
          // },
          // render: content => (
          //     <Tooltip placement="bottom" title={content}>
          //         {content}
          //     </Tooltip>
          // ),
          render: (content, index) => {
            this.state.evaluation_word_list.forEach((item, index) => {
              if (item.checked) {
                content = content.replace(
                  item.val,
                  `<span style='color: #FF5186'>${item.val}</span>`
                );
              }
            });
            if (this.state.search_text !== "") {
              content = content.replace(
                this.state.search_text,
                `<span style='color: #FF5186'>${this.state.search_text}</span>`
              );
            }
            let html = { __html: content };
            return <div dangerouslySetInnerHTML={html}></div>;
          },
        },
        {
          title: "翻译",
          dataIndex: "content",
          key: "content",
          // ellipsis: {
          //     showTitle: false,
          // },
          // render: content => (
          //     <Tooltip placement="bottom" title={content}>
          //         {content}
          //     </Tooltip>
          // ),
          render: (content, obj, index) => {
            return (
              <div className="table-operation">
                <TranslationPanel
                  language_list={this.state.language_list}
                  language_id={obj.language_id}
                  translate_status={obj.translate_status}
                  retranslate={obj.retranslate}
                  translate={obj.translate}
                  handleTranslate={this.handleTranslateLocal.bind(
                    this,
                    obj.comment_index
                  )}
                  onLanguageChange={this.onLanguageChange.bind(this, {
                    is_global: false,
                    current_index: obj.comment_index,
                  })}
                ></TranslationPanel>
              </div>
            );
          },
        },
        {
          title: "小红书激励",
          dataIndex: "",
          key: "id",
          width: 110,
          align: "center",
          render: (item) => {
            return (
              <label>
                <span
                  className="incentive-btn"
                  onClick={this.hanldleIncentiveList.bind(this, item)}
                >
                  查看
                </span>
              </label>
            );
          },
        },
        {
          title: "评论时间",
          dataIndex: "create_time",
          key: "create_time",
          width: 110,
        },
        {
          title: "产品评论",
          dataIndex: "",
          key: "detail",
          width: 100,
          align: "center",
          // onCell: (record, rowIndex) => {
          //     return {
          //         onClick: (e) => {
          //             console.log(record, rowIndex)
          //         }
          //     }
          // },
          render: (item) => {
            return (
              <span
                style={{ color: "#FF5186" }}
                onClick={this.handleCell.bind(this, item)}
              >
                评论详情
              </span>
            );
          },
        },
      ],
      comment_result_sum: 0, //总共多少个评论结果
      comment_list: [],
      comment_list_prev: [],
      search_text: "",
      pagination_box_interval: 12, //每页12个
      pagination_box_current_page: 1, //当前页面
      region_list: [], // filter_select
      product_id_cem_prev: "",
      filter_start_date: "",
      filter_start_date_prev: "",
      filter_end_date: "",
      filter_end_date_prev: "",
      selectedRowKeys: [], //小红书的勾选框
      incentive_popup_status: false, //小红书批量发起奖励
      xhs_incentive_record_list: [], //小红书激励记录列表
      incentive_list_popup_status: false, //小红书激励审核
      filter_incentive_status: -1,
      language_list: [], // 翻译语言列表
      language_id_global: null, // 翻译语言id
      translate_status_global: 0, // 0 初始化没有翻译 1 准备翻译选择语言 或 翻译中 2 翻译完成
      language_id_global_num: 0, // 翻译语言个数
      language_id_local_num: 0, // 翻译语言个数
      loadingText: "翻译中",
      product_id_cem: "",
      translate_global_list: [], // 和分页联系在一起
    };
  }
  componentDidUpdate() {
    if (this.props.product_id_cem !== this.state.product_id_cem) {
      this.init("initFilter");
    } else if (
      this.props.comment_result_sum !== this.state.comment_result_sum
    ) {
      this.init();
    } else if (
      this.props.comment_list &&
      this.props.comment_list.length >= 0 &&
      !isObjectEqual(this.props.comment_list, this.state.comment_list_prev)
    ) {
      this.init();
    }
  }
  componentDidMount() {
    this._isMounted = true;
    this.translateContentLanguage();
  }
  componentWillUnMount = () => {
    this._isMounted = false;
    this.setState = (state, callback) => {
      return;
    };
  };
  // gpt翻译评论语言列表
  async translateContentLanguage() {
    try {
      const response = await translateContentLanguage({});
      let language_list = [];
      response.data.forEach((item, index) => {
        if (item.type !== "Chinese") {
          let obj = item;
          obj["key"] = item.id;
          language_list.push(obj);
        }
      });
      this.setState({ language_list: language_list });
      return response;
    } catch (error) {
      message.error("获取语言列表发生错误");
      throw error;
    }
  }
  // 判断两个对象是否相等
  isObjectValueEqual(obj1, obj2) {
    if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
      return true;
    }
    return false;
  }
  // 初始化
  init(type) {
    if (!this.props.comment_list) return;
    let num = Math.ceil(
      this.props.comment_list.length / this.state.pagination_box_interval
    );
    let translate_global_list = [];
    for (let i = 1; i <= num; i++) {
      let tmp = {
        pagination_box_current_page: this.state.pagination_box_current_page, //从1开始
        language_id_global: null, // 翻译语言id
        translate_status_global: 0, // 0 初始化没有翻译 1 准备翻译选择语言 或 翻译中 2 翻译完成
        language_id_global_num: 0, // 翻译语言个数
        retranslate: 0,
        translate: 0,
      };
      translate_global_list.push(tmp);
    }
    this.setState(
      {
        product_id_cem: this.props.product_id_cem,
        pagination_box_current_page: 1,
        comment_list: this.props.comment_list,
        comment_list_prev: this.props.comment_list,
        filter_start_date: this.props.filter_start_date,
        filter_end_date: this.props.filter_end_date,
        comment_result_sum: this.props.comment_result_sum,
        evaluation_level_list: this.props.evaluation_level_list,
        evaluation_word_list: this.props.evaluation_word_list,
        region_list: this.props.region_list,
        search_text: this.props.search_text || "",
        selectedRowKeys: [],
        translate_global_list: translate_global_list,
      },
      () => {
        if (type) {
          this.initChart("initFilter");
        } else {
          this.initChart();
        }
      }
    );
  }
  // 删选初始化图表
  initChart(type) {
    // 防止接口回来满，但是setFilterList这个组件已经unmonted
    if (!this.filterRef.current) return;
    this.filterRef.current.setFilterList({
      region_list: this.state.region_list,
      filter_start_date: this.state.filter_start_date,
      filter_end_date: this.state.filter_end_date,
      is_init_filter: type === "initFilter" ? true : false,
    });
  }
  handleCell(item) {
    this.props.handleShowDetail(item);
  }
  // 选择评价的显示方式
  selectListType(type) {
    if (type === "list" && this.state.list_show_type === 1) {
      return;
    }
    if (type === "mod" && this.state.list_show_type === 0) {
      return;
    }
    this.setState({
      list_show_type: parseInt(1 - this.state.list_show_type),
    });
  }
  // checkbox 传父
  onChangeLevelCheckbox(param, index, event) {
    this.props.handleChangeLevelCheckbox(index, event.target.checked);
  }

  // 搜索按钮 传父
  handleSearch(t, event) {
    if (!t) return false;
    if (!event) return false;
    if (this.props.user.account_type === "1" && event.target.value !== "") {
      //基础共创
      message.warning(
        `基础共创不支持搜索关键词，请联系商务进行升级`,
        3,
        () => {}
      );
      return;
    }
    this.props.handleCommentSearch(event.target.value);
  }

  // checkbox 传父
  onChangeWordCheckbox(param, index, event) {
    if (this.props.user.account_type === "1") {
      //基础共创
      message.warning(
        `基础共创不支持进行高频词筛选，请联系商务进行升级`,
        3,
        () => {}
      );
      return;
    }
    this.props.handleChangeWordCheckbox(index, event.target.checked);
  }
  //改变页码的时候
  onchangeBoxPagination(page, pageSize) {
    // let start = (page - 1) * this.state.pagination_box_interval
    // let end = start + this.state.pagination_box_interval
    // let tmp = this.state.comment_list.slice(start, end)
    // setTimeout(() => {
    //     this.setState({ a: 10 })
    // })
    setTimeout(() => {
      this.setState({ pagination_box_current_page: page });
    });
  }
  // 瀑布流box点击
  handleBox(item) {
    this.props.handleShowDetail(item);
  }
  //选择平级
  choose_evaluation_level(item, index) {
    let level_icon_status = {
      0: level_icon_0,
      1: level_icon_1,
      2: level_icon_2,
    };
    let level_icon_status_active = {
      0: level_icon_0_active,
      1: level_icon_1_active,
      2: level_icon_2_active,
    };
    if (item.id === 2 || item.id === 1 || item.id === 0) {
      return (
        <Checkbox
          checked={item.checked}
          onChange={this.onChangeLevelCheckbox.bind(this, "word", index)}
          key={index}
        >
          <span
            className={`content-label content-label-${
              item.checked ? "active" : "inactive"
            }`}
          >
            {item.val}
            <span>
              <img
                src={level_icon_status[item.id]}
                alt=""
                className={item.checked ? "isHide" : "icon-image"}
              />
            </span>
            <span>
              <img
                src={level_icon_status_active[item.id]}
                alt=""
                className={item.checked ? "icon-image" : "isHide"}
              />
            </span>
            <span
              className={`content-label-percent`}
            >{`(${item.percent})`}</span>
          </span>
        </Checkbox>
      );
    } else {
      return (
        <Checkbox
          checked={item.checked}
          onChange={this.onChangeLevelCheckbox.bind(this, "word", index)}
          key={index}
        >
          <span
            className={`content-label content-label-${
              item.checked ? "active" : "inactive"
            }`}
          >
            {item.val}
            <span
              className={`content-label-percent`}
            >{`(${item.percent})`}</span>
          </span>
        </Checkbox>
      );
    }
  }
  // 搜索的filter
  searchFilter(filter_obj) {
    if (this.props.user.account_type === "1") {
      //基础共创
      message.warning(
        `基础共创不支持筛选搜索功能，请联系商务进行升级`,
        3,
        () => {}
      );
      return;
    }
    this.props.searchFilter(filter_obj);
  }
  //获取小红书激励记录列表
  hanldleIncentiveList(item) {
    let comment_id = item.id;
    this.getXhsIncentiveList(comment_id);
  }
  async getXhsIncentiveList(comment_id) {
    try {
      const response = await getXhsIncentiveList({
        // comment_id: 734639
        comment_id: comment_id,
      });
      if (response.status === 204) {
        message.warning("未发起小红书激励", 3, () => {});
        this.setState({
          xhs_incentive_record_list: [],
          current_comment_id: comment_id,
        });
        return;
      }
      this.setState({
        xhs_incentive_record_list: response.data.xhs_incentive_record_list,
        incentive_list_popup_status: true,
        current_comment_id: comment_id,
      });
      return response;
    } catch (error) {
      message.error("后台发生错误，删除失败");
      throw error;
    }
  }
  // 获取小红书的元素下标
  getCheckIndex(id) {
    let comment_list = JSON.parse(JSON.stringify(this.state.comment_list));
    for (let i = 0; i < comment_list.length; i++) {
      if (comment_list[i].id === id) {
        return i;
      }
    }
    return -1;
  }
  // table 的 check
  hanldleCheck(item) {
    let comment_list = JSON.parse(JSON.stringify(this.state.comment_list));
    let selectedRowKeys = this.state.selectedRowKeys;
    if (item.status === 4 && item.check === 2) {
      // 未发起 checkbox未勾选
      let checkIndex = this.getCheckIndex(item.id);
      comment_list[checkIndex].check = 1;
      selectedRowKeys.push(checkIndex);
    } else if (item.status === 4 && item.check === 1) {
      // 未发起 checkbox勾选
      let checkIndex = this.getCheckIndex(item.id);
      comment_list[checkIndex].check = 2;
      selectedRowKeys = selectedRowKeys.filter((item) => item !== checkIndex);
    } else if (item.status === 5 && item.check === 2) {
      // 已关闭 checkbox未勾选
      let checkIndex = this.getCheckIndex(item.id);
      comment_list[checkIndex].check = 1;
      selectedRowKeys.push(checkIndex);
    } else if (item.status === 5 && item.check === 1) {
      // 已关闭 checkbox勾选
      let checkIndex = this.getCheckIndex(item.id);
      comment_list[checkIndex].check = 2;
      selectedRowKeys = selectedRowKeys.filter((item) => item !== checkIndex);
    }
    this.setState({
      comment_list: comment_list,
      selectedRowKeys: Array.from(new Set([...selectedRowKeys])),
    });
  }
  // 小红书批量发起弹窗
  handleSaveIncentivePopup() {
    if (this.props.user.account_type === "1") {
      //基础共创
      message.warning(`请联系商务进行升级`, 3, () => {});
      return;
    }

    this.setState({
      incentive_popup_status: true,
    });
  }
  // 小红书批量发起弹窗 传父
  get_incentive_popup_status(obj) {
    let { incentive_popup_status, amount, canIncentive } = obj;
    this.setState(
      {
        incentive_popup_status: incentive_popup_status,
      },
      () => {
        if (canIncentive) {
          this.props.saveIncentive(this.state.selectedRowKeys, amount);
        }
      }
    );
  }
  // 小红书激励审核 传父
  get_incentive_list_popup_status(obj) {
    let { incentive_list_popup_status, status, comment_id, comment } = obj;
    this.setState(
      {
        incentive_list_popup_status: incentive_list_popup_status,
      },
      () => {
        if (status !== "") {
          this.props.updateXhsIncentive(status, comment_id, comment);
        }
      }
    );
  }
  // 下拉框搜索可以大小写
  filterSelectOption(input, option) {
    return (
      option.children.props.children
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  // 下拉框筛选审核状态
  filterIncentiveStatus(key, option) {
    this.setState(
      {
        filter_incentive_status: key,
        language_id_global: null,
        translate_status_global: 0,
        language_id_global_num: 0,
      },
      () => {
        this.props.filter_comment_list(this.state.filter_incentive_status);
      }
    );
  }
  // 选择下拉框语言
  onLanguageChange(other_obj, translate_obj) {
    let { language_id, retranslate, translate } = translate_obj;
    let { is_global, current_index } = other_obj;
    if (is_global) {
      let start =
        (this.state.pagination_box_current_page - 1) *
        this.state.pagination_box_interval;
      let end = start + this.state.pagination_box_interval;

      let comment_list = JSON.parse(JSON.stringify(this.state.comment_list));
      let tmp = comment_list.slice(start, end);
      // if (language_id === 1) { // 中文
      //     let translate_global_list = JSON.parse(JSON.stringify(this.state.translate_global_list))
      //     translate_global_list[this.state.pagination_box_current_page - 1].retranslate = 0
      //     translate_global_list[this.state.pagination_box_current_page - 1].language_id_global = language_id
      //     translate_global_list[this.state.pagination_box_current_page - 1].translate_status_global = 2
      //     for (let i = 0; i < tmp.length; i++) {
      //         comment_list[start + i].content = this.props.comment_list[start].content
      //     }
      //     this.setState({
      //         comment_list: comment_list,
      //         translate_global_list: translate_global_list
      //     })
      //     return
      // }

      let translate_global_list = JSON.parse(
        JSON.stringify(this.state.translate_global_list)
      );
      translate_global_list[
        this.state.pagination_box_current_page - 1
      ].retranslate = retranslate;
      translate_global_list[
        this.state.pagination_box_current_page - 1
      ].translate = translate;
      translate_global_list[
        this.state.pagination_box_current_page - 1
      ].language_id_global = language_id;
      translate_global_list[
        this.state.pagination_box_current_page - 1
      ].language_id_global_num = this.state.pagination_box_interval;
      this.setState(
        {
          translate_global_list: translate_global_list,
        },
        () => {
          for (let i = 0; i < tmp.length; i++) {
            let translate_obj_tmp = {
              language_id: language_id,
              user_comment_id: tmp[i].id,
              comment_index: start + i,
              retranslate: retranslate,
              translate: translate,
            };
            this.translateContentGlobal(translate_obj_tmp);
          }
        }
      );
    } else {
      let start = current_index;
      let comment_list = JSON.parse(JSON.stringify(this.state.comment_list));
      // if (language_id === 1) { // 中文
      //     comment_list[start].content = this.props.comment_list[start].content
      //     comment_list[start].translate_status = 2
      //     comment_list[start].language_id = language_id
      //     comment_list[start].retranslate = 0
      //     this.setState({
      //         language_id_local_num: 0,
      //         comment_list: comment_list,
      //     })
      //     return
      // }
      let translate_obj_tmp = {
        language_id: language_id,
        user_comment_id: this.props.comment_list[start].id,
        comment_index: start,
        retranslate: retranslate,
        translate: translate,
      };
      comment_list[start].retranslate = retranslate;
      comment_list[start].translate = translate;
      comment_list[start].language_id = language_id;
      this.setState(
        {
          comment_list: comment_list,
        },
        () => {
          this.translateContentLocal(translate_obj_tmp);
        }
      );
    }
  }
  // gpt翻译单个评论语言
  async translateContentLocal(translate_obj) {
    let { language_id, user_comment_id, comment_index, retranslate } =
      translate_obj;
    this.setState({
      language_id_local_num: this.state.language_id_local_num + 1,
    });

    try {
      const response = await translateContent({
        language_id: language_id,
        user_comment_id: user_comment_id,
        retranslate: retranslate, //1：重新翻译，0：非重新翻译
      });
      // const response = {
      //     data: {
      //         content: `123====+${language_id}`
      //     }
      // }
      // setTimeout(() => {
      let comment_list = JSON.parse(JSON.stringify(this.state.comment_list));
      comment_list[comment_index].content = response.data.content;
      comment_list[comment_index].translate_status = 2;
      comment_list[comment_index].retranslate = 0; // 都变成  0：非重新翻译 这样取的是数据库的数据 不是gpt的数据
      comment_list[comment_index].translate = 0;
      this.setState({
        comment_list: comment_list,
        language_id_local_num: this.state.language_id_local_num - 1,
      });
      // }, 4000)

      return response;
    } catch (error) {
      if (error?.message !== "isCancel") {
        message.error("后台翻译发生错误");
      }
      let comment_list = JSON.parse(JSON.stringify(this.state.comment_list));
      comment_list[comment_index].translate_status = 2;
      comment_list[comment_index].retranslate = 0;
      comment_list[comment_index].translate = 0;
      this.setState({
        comment_list: comment_list,
        language_id_local_num: this.state.language_id_local_num - 1,
      });
      throw error;
    }
  }
  // gpt翻译评论语言列表
  async translateContentGlobal(translate_obj) {
    let { language_id, user_comment_id, comment_index } = translate_obj;

    try {
      const response = await translateContent({
        language_id:
          this.state.translate_global_list[
            this.state.pagination_box_current_page - 1
          ].language_id_global,
        user_comment_id: user_comment_id,
        retranslate:
          this.state.translate_global_list[
            this.state.pagination_box_current_page - 1
          ].retranslate, //1：重新翻译，0：非重新翻译
      });
      // const response = {
      //     data: {
      //         content: `123====+${this.state.translate_global_list[this.state.pagination_box_current_page - 1].language_id_global}`
      //     }
      // }
      // setTimeout(() => {
      let translate_global_list = JSON.parse(
        JSON.stringify(this.state.translate_global_list)
      );
      let pagination_box_current_page =
        Math.floor(comment_index / this.state.pagination_box_interval) + 1;
      let comment_list = JSON.parse(JSON.stringify(this.state.comment_list));
      comment_list[comment_index].content = response.data.content;
      comment_list[comment_index].translate_status = 2;
      comment_list[comment_index].language_id = language_id;

      if (
        translate_global_list[pagination_box_current_page - 1]
          .language_id_global_num -
          1 <=
        0
      ) {
        translate_global_list[
          pagination_box_current_page - 1
        ].translate_status_global = 2;
        translate_global_list[pagination_box_current_page - 1].retranslate = 0;
        translate_global_list[pagination_box_current_page - 1].translate = 0;
      }
      translate_global_list[
        pagination_box_current_page - 1
      ].language_id_global_num =
        translate_global_list[pagination_box_current_page - 1]
          .language_id_global_num - 1;
      this.setState({
        comment_list: comment_list,
        translate_global_list: translate_global_list,
      });
      // }, 5000)
      return response;
    } catch (error) {
      if (error?.message !== "isCancel") {
        message.error("后台翻译发生错误");
      }
      let translate_global_list = JSON.parse(
        JSON.stringify(this.state.translate_global_list)
      );
      let pagination_box_current_page =
        Math.floor(comment_index / this.state.pagination_box_interval) + 1;
      if (
        translate_global_list[pagination_box_current_page - 1]
          .language_id_global_num -
          1 <=
        0
      ) {
        translate_global_list[
          pagination_box_current_page - 1
        ].translate_status_global = 2;
        translate_global_list[pagination_box_current_page - 1].retranslate = 0;
        translate_global_list[pagination_box_current_page - 1].translate = 0;
      }
      translate_global_list[
        pagination_box_current_page - 1
      ].language_id_global_num =
        translate_global_list[pagination_box_current_page - 1]
          .language_id_global_num - 1;
      this.setState({
        translate_global_list: translate_global_list,
      });
      throw error;
    }
  }
  // 操作翻译图标
  handleTranslateGlobal() {
    let translate_global_list = JSON.parse(
      JSON.stringify(this.state.translate_global_list)
    );
    let translate_status =
      translate_global_list[this.state.pagination_box_current_page - 1]
        .translate_status_global;
    if (translate_status === 0) {
      // 状态0 灰色
      translate_global_list[
        this.state.pagination_box_current_page - 1
      ].translate_status_global = 1;
      translate_global_list[
        this.state.pagination_box_current_page - 1
      ].language_id_global = null;
      this.setState({
        translate_global_list: translate_global_list,
      });
    } else {
      // 状态1 红色+下拉框  状态2 红色+重新翻译
      translate_global_list[
        this.state.pagination_box_current_page - 1
      ].translate_status_global = 0;
      translate_global_list[
        this.state.pagination_box_current_page - 1
      ].language_id_global = null;

      let start =
        (this.state.pagination_box_current_page - 1) *
        this.state.pagination_box_interval;
      let end = start + this.state.pagination_box_interval;

      let comment_list = JSON.parse(JSON.stringify(this.state.comment_list));
      let tmp = comment_list.slice(start, end);
      for (let i = 0; i < tmp.length; i++) {
        comment_list[start + i].translate_status = 0;
        comment_list[start + i].language_id = null;
        comment_list[start + i].content =
          this.props.comment_list[start].content;
      }
      message.warning(`语言设置恢复为，默认中文`, 2, () => {});
      this.setState({
        comment_list: comment_list,
        translate_global_list: translate_global_list,
      });
    }
  }
  // 操作翻译图标 单个
  handleTranslateLocal(comment_index) {
    let comment_list = JSON.parse(JSON.stringify(this.state.comment_list));
    if (comment_list[comment_index].translate_status === 0) {
      // 状态0 灰色
      comment_list[comment_index].translate_status = 1;
      comment_list[comment_index].language_id = null;
      this.setState({
        comment_list: comment_list,
      });
    } else {
      // 状态1 红色+下拉框 // 状态2 红色+重新翻译
      comment_list[comment_index].translate_status = 0;
      comment_list[comment_index].language_id = null;
      comment_list[comment_index].content =
        this.props.comment_list[comment_index].content;
      message.warning(`语言设置恢复为，默认中文`, 2, () => {});
      this.setState({
        language_id_local_num: 0,
        comment_list: comment_list,
      });
    }
  }
  //  处理comment_list_for_box
  handleCommentListForBox() {
    if (!this.state.comment_list || this.state.comment_list.length === 0) {
      return [];
    }
    let start =
      (this.state.pagination_box_current_page - 1) *
      this.state.pagination_box_interval;
    let end = start + this.state.pagination_box_interval;
    return this.state.comment_list.slice(start, end);
  }
  render() {
    const prefix = (
      <img src={cur_icon_search} alt="" className="evaluation-icon-search" />
    );
    // 搜索按钮防抖
    const debounce = (fn, wait) => {
      let timeout = null;
      return function (input) {
        input.persist();
        if (timeout !== null) clearTimeout(timeout);
        timeout = setTimeout(fn, wait, input);
      };
    };
    return (
      <>
        <div className="evaluation-title-wrapper">
          {this.state.language_id_local_num <= 0 ? (
            ""
          ) : (
            <Loading loadingText={this.state.loadingText}></Loading>
          )}
          {this.state.translate_global_list.length &&
          this.state.translate_global_list[
            this.state.pagination_box_current_page - 1
          ].language_id_global_num > 0 ? (
            <Loading loadingText={this.state.loadingText}></Loading>
          ) : (
            ""
          )}
          <div className="evaluation-right">
            <div
              className="evaluation-icon-mod"
              onClick={this.selectListType.bind(this, "mod")}
            >
              <img
                src={data_icon_mod}
                alt=""
                className={this.state.list_show_type === 0 ? "isHide" : ""}
              />
              <img
                src={data_icon_mod_cur}
                alt=""
                className={this.state.list_show_type === 1 ? "isHide" : ""}
              />
            </div>
            <div
              className="evaluation-icon-list"
              onClick={this.selectListType.bind(this, "list")}
            >
              <img
                src={data_icon_list}
                alt=""
                className={this.state.list_show_type === 1 ? "isHide" : ""}
              />
              <img
                src={data_icon_list_cur}
                alt=""
                className={this.state.list_show_type === 0 ? "isHide" : ""}
              />
            </div>
            {/* <Input prefix={prefix} placeholder="搜索关键词" onChange={debounce(this.handleSearch.bind(this, 'comment_search'), 1000)} /> */}
            <Input
              prefix={prefix}
              placeholder="搜索关键词"
              onPressEnter={this.handleSearch.bind(this, "comment_search")}
              onBlur={this.handleSearch.bind(this, "comment_search")}
            />
          </div>
        </div>
        {/* 筛选 */}
        <div className="filter-container-fixed">
          <FilterSelect
            ref={this.filterRef}
            searchFilter={this.searchFilter.bind(this)}
          ></FilterSelect>
        </div>
        {/* 词汇 */}
        <div className="evaluation-prev-card">
          <div className="evaluation-prev-item evaluation-prev-word">
            <div className="item-name">情感评级</div>
            <div className="item-content">
              {this.state.evaluation_level_list.map((item, index) => {
                return this.choose_evaluation_level.call(this, item, index);
                // return <Checkbox checked={item.checked} onChange={this.onChangeLevelCheckbox.bind(this, "word", index)} key={index}><span className={`content-label content-label-${item.checked ? "active" : "inactive"}`}>{item.val}<span className={`content-label-percent`}>{`(${item.percent})`}</span></span></Checkbox>
              })}
            </div>
          </div>
          <div className="evaluation-prev-item evaluation-prev-word">
            <div className="item-name">评价高频词</div>
            <div className="item-content">
              {this.state.evaluation_word_list.map((item, index) => {
                return (
                  <Checkbox
                    checked={item.checked}
                    onChange={this.onChangeWordCheckbox.bind(
                      this,
                      "word",
                      index
                    )}
                    key={index}
                  >
                    <span
                      className={`content-label content-label-${
                        item.checked ? "active" : "inactive"
                      }`}
                    >
                      {item.val}
                      <span
                        className={`content-label-percent`}
                      >{`(${item.count})`}</span>
                    </span>
                  </Checkbox>
                );
              })}
            </div>
          </div>
        </div>
        <div className="evaluation-operaion">
          <div className="evaluation-operaion-left">
            <div className="evaluation-operaion-left-1">
              <div className="evaluation-operaion-text">小红书审核</div>
              <XmSelect
                showSearch
                placeholder="请选择审核状态"
                filterOption={(input, option) => {
                  return this.filterSelectOption.call(this, input, option);
                }}
                value={
                  this.state.filter_incentive_status === ""
                    ? null
                    : this.state.filter_incentive_status
                }
                options={incentiveStatusList}
                onChange={(e, index) => {
                  this.filterIncentiveStatus(e, index);
                }}
              ></XmSelect>
            </div>
            {this.state.selectedRowKeys.length ? (
              <div className="evaluation-operaion-left-2">
                <div
                  className="evaluation-operaion-button"
                  onClick={this.handleSaveIncentivePopup.bind(this)}
                >
                  {this.state.selectedRowKeys.length > 1 ? (
                    <span>批量发起小红书奖励</span>
                  ) : (
                    <span>发起小红书奖励</span>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="evaluation-operaion-right">
            {this.state.translate_global_list.length ? (
              <TranslationPanel
                language_list={this.state.language_list}
                language_id={
                  this.state.translate_global_list[
                    this.state.pagination_box_current_page - 1
                  ].language_id_global
                }
                translate_status={
                  this.state.translate_global_list[
                    this.state.pagination_box_current_page - 1
                  ].translate_status_global
                }
                retranslate={
                  this.state.translate_global_list[
                    this.state.pagination_box_current_page - 1
                  ].retranslate
                }
                translate={
                  this.state.translate_global_list[
                    this.state.pagination_box_current_page - 1
                  ].translate
                }
                handleTranslate={this.handleTranslateGlobal.bind(this)}
                onLanguageChange={this.onLanguageChange.bind(this, {
                  is_global: true,
                  current_index: -1,
                })}
              ></TranslationPanel>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* ant-table */}
        <div
          className={
            this.state.list_show_type === 1 ? "evaluation-table-wrap" : "isHide"
          }
        >
          <ConfigProvider locale={zhCN}>
            <Table
              dataSource={this.state.comment_list}
              columns={this.state.columns}
              pagination={{
                current: this.state.pagination_box_current_page,
                defaultCurrent: 1,
                pageSize: 12,
                showSizeChanger: false,
                showQuickJumper: true,
                onChange: this.onchangeBoxPagination.bind(this),
              }}
              scroll={{ x: 1400 }}
              rowKey={(columns) => columns.id}
            />
          </ConfigProvider>
          <div className="evaluation-table-sum">
            共<span>{this.state.comment_result_sum}</span>个结果，当前展示1-10行
          </div>
        </div>

        {/* box */}
        <div
          className={
            this.state.list_show_type === 0 ? "evaluation-box-wrap" : "isHide"
          }
        >
          <WaterfallEvaluation
            tab_key={this.props.tab_key}
            product_id_cem={this.state.product_id_cem}
            list_show_type={this.state.list_show_type}
            language_list={this.state.language_list}
            pagination_box_interval={this.state.pagination_box_interval}
            pagination_box_current_page={this.state.pagination_box_current_page}
            comment_list={this.handleCommentListForBox.call(this)}
            wordList={this.state.evaluation_word_list}
            handleBox={(item) => this.handleBox(item)}
            search_text={this.state.search_text}
            hanldleCheck={(item) => this.hanldleCheck(item)}
            getXhsIncentiveList={(item) => this.getXhsIncentiveList(item)}
            onLanguageChange={this.onLanguageChange.bind(this)}
            handleTranslateLocal={this.handleTranslateLocal.bind(this)}
          ></WaterfallEvaluation>
        </div>
        <div
          className={
            this.state.list_show_type === 0 ? "pagination-footer" : "isHide"
          }
        >
          <div className="pagination-sum">
            共<span>{this.state.comment_result_sum}</span>个结果
          </div>
          {this.state.comment_result_sum > 0 ? (
            <ConfigProvider locale={zhCN}>
              <Pagination
                current={this.state.pagination_box_current_page}
                defaultCurrent={1}
                defaultPageSize={this.state.pagination_box_interval}
                total={this.state.comment_result_sum}
                onChange={this.onchangeBoxPagination.bind(this)}
                showSizeChanger={false}
                showQuickJumper
              />
            </ConfigProvider>
          ) : (
            ""
          )}
        </div>
        {this.state.incentive_popup_status ? (
          <IncentivePopup
            selectedRowKeys={this.state.selectedRowKeys}
            get_incentive_popup_status={this.get_incentive_popup_status.bind(
              this
            )}
          ></IncentivePopup>
        ) : (
          ""
        )}
        {this.state.incentive_list_popup_status ? (
          <IncentiveListPopup
            comment_id={this.state.current_comment_id}
            xhs_incentive_record_list={this.state.xhs_incentive_record_list}
            get_incentive_list_popup_status={this.get_incentive_list_popup_status.bind(
              this
            )}
          ></IncentiveListPopup>
        ) : (
          ""
        )}
      </>
    );
  }
}
export default connect(
  (state) => ({
    user: state.user,
  }), //映射状态
  {} //映射操作状态的方法
)(Evaluation);
