
import React, { Component } from 'react'
import '../../css/ant_common/ant_table.scss';
import './Product_list.scss'


import icon_add from "../../images/product_list/icon_add.png"
import icon_delete from "../../images/product_list/icon_delete.png"
import icon_search from "../../images/product_list/icon_search.png"
import { Input, Checkbox, Table, Tooltip, Pagination, ConfigProvider, Image } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import { getProductList, deleteProduct } from '../../api/Product/Product_list_api'
import XmModel from '../../components/Xm_model/Xm_model'
import Loading from '../../components/Loading/loading'
import { message } from 'antd';
const { Search } = Input;
let that = ''
class Product_list extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: '产品名称',
                dataIndex: 'title',
                key: 'title',
                width: 130,
                fixed: 'left',
            },
            {
                title: '品牌名称',
                dataIndex: 'brand_name',
                key: 'brand_name',
                width: 130,
            },
            {
                title: '图片',
                dataIndex: 'product_image',
                key: 'product_image',
                width: 80,
                align: 'center',
                render: (text) => {
                    if (text) {
                        return <ConfigProvider locale={zhCN}>
                            <Image src={text} alt="" width={60} height={60} style={{ "objectFit": "cover" }} />
                        </ConfigProvider>
                    } else {
                        return "未传图片"
                    }
                }
            },
            {
                title: '产品零售价',
                dataIndex: 'price',
                key: 'price',
                width: 110,
                align: 'center',
            },
            {
                title: '总派送件数',
                dataIndex: 'dispatch_count',
                key: 'dispatch_count',
                width: 110,
                align: 'center',
            },
            {
                title: 'NLP评论好评率',
                dataIndex: 'good_comment_rate',
                key: 'good_comment_rate',
                width: 140,
                align: 'center',
            },
            {
                title: '产品总反馈率',
                dataIndex: 'product_feedback_rate',
                key: 'product_feedback_rate',
                width: 120,
                align: 'center',
            },
            {
                title: '购买UV',
                dataIndex: 'buy_click_uv',
                key: 'buy_click_uv',
                width: 100,
                align: 'center',
            },
            {
                title: '了解更多UV',
                dataIndex: 'learn_more_click_uv',
                key: 'learn_more_click_uv',
                width: 120,
                align: 'center',

            },
            {
                title: '操作',
                dataIndex: '',
                key: 'action',
                width: 110,
                render: (item) => {
                    return <label>
                        <span style={{ color: 'var(--modify-color)' }} onClick={this.handleRevise.bind(this, item)} >修改</span>
                        <span style={{ color: 'var(--detail-color)' }} onClick={this.handleDetail.bind(this, item)}>详情</span>
                    </label >
                }
            },
        ]
        this.state = {
            brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : 1,   //品牌ID
            product_list: [],//产品列表
            selectedRowKeys: [],
            modelContent: "确定删除该产品吗？",
            showModel: false,
            modelTitle: "删除提示",
            total_page: 1, // 总共多少页
            total_number: 0, // 总共多少条
            current_page: 1, //当前页
            loading: 0, // 显示加载
            search_name: "", //是否有
        }

    }

    componentDidMount() {
        that = this
        this.getProductList();
    }

    async getProductList() {
        this.setState({
            loading: 0
        })
        let brand_id = this.state.brand_id
        try {
            const response = await getProductList({
                brand_id: parseInt(brand_id),
                page: this.state.current_page,
                count: 10,
                product_name: this.state.search_name
            })
            if (response.status === 200) {
                this.setState({
                    product_list: response.data.product_list,
                    total_page: response.data.total_page,
                    total_number: response.data.total_number,
                    loading: 1
                })
            } else if (response.status === 204) {
                this.setState({
                    product_list: [],
                    total_page: 1,
                    total_number: 0,
                    loading: 1
                })
            }
            return response;
        } catch (error) {
            this.setState({
                loading: 1
            })
            message.error('加载失败!')
        }
    }
    async searchProduct(value) {
        this.setState({
            search_name: value,
            current_page: 1
        }, () => {
            this.getProductList();
        })
    }
    //添加产品
    addProduct() {
        localStorage.removeItem('product_id')
        this.props.history.push(`/product_list/product_entry`)
    }
    //修改跳转
    handleRevise(item) {
        this.props.history.push(`/product_list/product_entry?id=${item.product_id}`)
    }
    //查看详情
    handleDetail(item) {
        this.props.history.push(`/overall_data?brand_id=${item.brand_id}&product_id=${item.product_id}`)
    }
    // 点击checkbox
    onSelectChange = (selectedRowKeys, record) => {
        this.setState({ selectedRowKeys, record });
    };
    //搜索
    onSearch(value) {
        that.searchProduct(value)
    };
    //删除
    async deleteProduct() {
        // let tmpList = JSON.parse(JSON.stringify(this.state.product_list))
        let brand_id = this.state.brand_id
        let product_id_list = this.state.selectedRowKeys.map(Number)

        let tmpList = this.state.product_list.filter((item) => {
            return !product_id_list.includes(parseInt(item.product_id))
        })
        deleteProduct({ brand_id: parseInt(brand_id), product_id_list: product_id_list }).then((res) => {
            if (res.status === 200) {
                message.success('删除成功', 0.2, () => {
                    this.setState({
                        product_list: tmpList
                    }, () => {
                        this.getProductList()
                    })
                })

            }
        }).catch((err) => {
            message.error('删除失败!')
        })
    }
    // 第一个参数是设置是否显示，第二个参数就是子组件选确认
    handleModel(val, flag) {
        let product_id_list = this.state.selectedRowKeys.map(Number)
        if (!product_id_list.length) {
            message.warning('删除不可为空', 1, () => {
            })
            return
        }
        if (flag) {
            this.deleteProduct()
        }
        this.setState({
            showModel: val,
        })
    }
    // 操作分页
    handlePage(page, pageSize) {
        this.setState({
            current_page: page
        }, () => {
            this.getProductList()
        });
    }
    render() {
        const prefix = (
            <img src={icon_search} alt="" className='evaluation-icon-search' />
        )
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className='container product-list-container-bg'>
                {
                    this.state.loading === 0 ? <Loading></Loading> : ""
                }
                <div className='product-list-container'>
                    {/* 产品列表标题 */}
                    <div className='product-entry-top'>
                        <div className='top-title'>产品-产品列表</div>
                    </div>
                    {/* 产品列表操作 */}
                    <div className="product-operaion">
                        <div className="product-operaion-left">
                            <Search
                                placeholder="请输入商品名称"
                                allowClear
                                enterButton="查询"
                                prefix={prefix}
                                onSearch={this.onSearch}
                            />
                        </div>
                        <div className="product-operaion-right">
                            <div className="product-operaion-button" onClick={this.handleModel.bind(this, true, false)}>
                                <img src={icon_delete} alt="" />
                                <span>删除</span>
                            </div>
                            <div className="product-operaion-button" onClick={this.addProduct.bind(this)}>
                                <img src={icon_add} alt="" />
                                <span>添加产品</span>
                            </div>
                        </div>
                    </div>
                    {/* 产品列表table */}
                    <div className='product-list-main'>
                        <div className='product-list-table'>
                            <ConfigProvider locale={zhCN}>
                                <Table rowSelection={rowSelection} dataSource={this.state.product_list} columns={this.columns} pagination={{
                                    current: this.state.current_page,
                                    pageSize: 10, total: this.state.total_number,
                                    showSizeChanger: false, showQuickJumper: true,
                                    onChange: (page, pageSize) => {
                                        this.handlePage(page, pageSize)
                                    }
                                }} scroll={{ x: 900 }} rowKey={columns => columns.product_id} />
                            </ConfigProvider>
                            <div className="table-sum-wrapper">共<span>{this.state.total_number || 0}</span>个结果，当前展示1-10行</div>
                        </div>
                    </div>
                </div>
                {/* 产品列表删除弹窗 */}
                <div>
                    <XmModel isShowModel={this.state.showModel} handleShowModel={this.handleModel.bind(this)} modelContent={this.state.modelContent} modelTitle={this.state.modelTitle}></XmModel>
                </div>
            </div>
        )
    }
}



export default Product_list;