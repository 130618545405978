import React, { Component } from 'react'
import './Dispatch_single_chart.scss';
import { getPieOption } from "../../../util/Chart_config/Pie_chart_config";
import { getBarOption } from "../../../util/Chart_config/Bar_chart_config";
import { formatChart } from "../../../util/Chart_config/Config";
export default class Dispatch_single_chart extends Component {
    constructor(props) {
        super(props);
        //   控制提示框的样式
        this.state = {
            name: 'Dispatch_single_chart',
            needRefresh: false,
        };
    }
    toInitPieChart() {

        let id = this.props.single_id
        let data = this.props.dataList.data
        let option = getPieOption(data)
        formatChart(id, option)
    }
    toInitBarChart(sum) {
        let id = this.props.single_id
        let xAxisList = []
        let seriesList = []
        this.props.dataList.data.forEach((item, index) => {
            xAxisList.push(item.name)
            seriesList.push(Number(item.value))
        })
        let option = getBarOption(xAxisList, seriesList, sum)
        formatChart(id, option)
    }
    toInitChart() {
        let type = this.props.dataList.type
        let sum = this.props.dataList.sum
        if (type === 1) {
            this.toInitPieChart()
        } else {
            this.toInitBarChart(sum)
        }

    }
    componentDidMount() {
        this.toInitChart()
    }
    componentDidUpdate() {
        if (this.state.needRefresh) {
            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
        return true
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList,
            };
        }
        return null;

    }
    render() {
        let single_id = String(this.props.single_id) || ""
        let name = this.props.dataList.name || ""
        return (
            <div className='pannel-card panel-card-dispatch-single'>
                <div className='card-title-wrapper'>{name}</div>
                <div id={single_id} className="echart-container dispatch-single-echart"></div>
            </div>
        )
    }
}
