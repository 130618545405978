import React, { Component } from 'react'
import './matrix_nps_info.scss';
import EditNode from '../../../../../EditNode/edit_node';
import XmUpload from '../../../../../Upload_pic/xm_upload';
import cur_icon_radio_sel from "../../../../../../images/emotion_analysis/cur_icon_radio_sel.png"
import cur_icon_radio_nor from "../../../../../../images/emotion_analysis/cur_icon_radio_nor.png"
import cur_icon_delete from "../../../../../../images/cur_icon_delete.png"
import cur_icon_add from "../../../../../../images/cur_icon_add.png"


export default class matrix_nps_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'matrix_nps_info',               //从哪个页面进入的
            dataList: {},
            min_value: 0,
            max_value: 10,
            min_name: "",
            max_name: "",
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                min_value: parseInt(props.dataList.min_value),
                max_value: parseInt(props.dataList.max_value) || "",
                min_name: props.dataList.min_name || state.min_name,
                max_name: props.dataList.max_name || state.max_name,
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    // 编辑选项
    onChangeEditor(option_index, obj) {
        this.props.handleQuestionOptionName(option_index, obj.value)
    }
    // 添加选项图片
    addOptionPic(option_index, image, filename) {
        this.props.addOptionPic(option_index, image, filename)
    }
    // 删除选项图片
    deleteOptionPic(option_index) {
        this.props.deleteOptionPic(option_index)
    }
    //添加选项
    handleAddOption() {
        this.props.handleAddOption()
    }
    // 删除选项
    handleDeleteOption(option_index) {
        this.props.handleDeleteOption(option_index)
    }
    // 选项上下架
    handleActiveOption(option_index) {
        this.props.handleActiveOption(option_index)
    }
    // 设置选项
    handleSetOption(option_index, e) {
        //如果提供了事件对象，则这是一个非IE浏览器 
        if (e && e.stopPropagation) {
            //因此它支持W3C的stopPropagation()方法 
            e.stopPropagation();
        } else {
            //否则，我们需要使用IE的方式来取消事件冒泡 
            window.event.cancelBubble = true;
        }

        this.props.handleSetOption(option_index)
    }
    render() {
        let items = [];
        for (let i = 1; i <= this.state.max_value; i++) {
            items.push(<span key={i}>{i}</span>);
        }
        return (
            <div className="matrix-nps-info-container">
                <div className="matrix-nps-info-top">
                    <div className="info-top-name-wrap">
                        <div className="info-top-name">{this.state.min_name}</div>
                        <div className="info-top-name">{this.state.max_name}</div>
                    </div>
                    <div className="info-top-value-wrap">
                        <div className={this.state.max_value < 10 ? "draw-slip-index" : "isHide"}>
                            {
                                items
                            }
                        </div>
                        <div className={this.state.max_value === 10 ? "draw-slip-index" : "isHide"}>
                            <span>1</span>
                            <span>2</span>
                            <span>4</span>
                            <span>6</span>
                            <span>8</span>
                            <span>10</span>
                        </div>
                        <div className={this.state.max_value > 10 ? "draw-slip-index" : "isHide"}>
                            <span>1</span>
                            <span>20</span>
                            <span>40</span>
                            <span>60</span>
                            <span>80</span>
                            <span>100</span>
                        </div>
                    </div>
                </div>
                <div className="matrix-score-info-answer">
                    {
                        this.state.dataList.answers.map((item, index) => {
                            return (
                                <div className={`draw-slip ${item.is_option_active === 1 ? "" : "draw-slip-2"} ${index === this.props.currentOptionIndex ? "draw-slip-setting" : ""}`} key={index}>
                                    <div className="draw-slip-left draw-slip-left-border">
                                        <div className='draw-slip-title'>
                                            <div className="edit-question-title-wrap">
                                                <EditNode value={item.name} onChange={this.onChangeEditor.bind(this, index)} className="edit-question-title"></EditNode>
                                            </div>
                                            <div>
                                                <XmUpload className="upload-question-pic"
                                                    image_name={item.image.val}
                                                    handleDeletePic={this.deleteOptionPic.bind(this, index)}
                                                    handleUploadPic={this.addOptionPic.bind(this, index)}></XmUpload>
                                            </div>
                                        </div>
                                        <div className="draw-slip-top">
                                            <div className='draw-slip-line'>
                                                <span></span>
                                            </div>
                                            <span className='draw-slip-num'>0</span>
                                        </div>
                                    </div >
                                    <div className="draw-slip-right">
                                        <img src={cur_icon_delete} className='delete-option-icon' alt="" onClick={this.handleDeleteOption.bind(this, index)} />
                                        {
                                            <div className='option-active-wrapper' onClick={this.handleSetOption.bind(this, index)}>
                                                {
                                                    <div>设置</div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div >
                <div className="info-foot" onClick={this.handleAddOption.bind(this)} >
                    <img src={cur_icon_add} className='add-option-icon' alt="" />
                    <div>添加选项</div>
                </div>

            </div>
        )
    }
}
