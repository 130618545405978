import React, { Component } from 'react'
import { Table, ConfigProvider, Input, Image } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import "./blank_list.scss"
import cur_icon_search from "../../images/emotion_analysis/cur_icon_search.png"
export default class blank_list extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blank_list: [],
            blank_list_formal: [],
            dataList: {},
            needRefresh: false,
            columns: [],
            search_text: ""
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList,
                search_text: ""
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            this.toInitChart()
        }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    toInitChart() {
        let blank_list = []
        let columns = []

        let cur_table_list = []
        if (this.props.dataList.data.length) {
            cur_table_list = this.props.dataList.data
            columns = this.initColumn(cur_table_list[0])
        }

        for (let i = 0; i < this.props.dataList.data.length; i++) {
            let obj = {
                name: this.props.dataList.data[i].name,
                gender: this.props.dataList.data[i].gender,
                comment: this.props.dataList.data[i].comment,
                id: "blank_list_" + i
            }
            blank_list.push(obj)
        }
        this.setState({
            blank_list: blank_list,
            blank_list_formal: blank_list,
            columns: columns
        })
    }
    initColumn(table_item_obj) {
        let columns = []
        for (var key in table_item_obj) {
            if (key === "name") {
                let tmp = {
                    title: '用户名',
                    dataIndex: 'name',
                    key: 'name',
                    width: 80,
                }
                if (this.props.dataList.type === 10) { // 图片题
                    tmp.render = (content, index) => {
                        if (this.state.search_text !== "") {
                            content = content.replace(this.state.search_text, `<span style='color: #FF5186'>${this.state.search_text}</span>`)
                        }
                        let html = { __html: content };
                        return <div dangerouslySetInnerHTML={html}></div>;
                    }
                }
                columns.push(tmp)
            } else if (key === "gender") {
                let tmp = {
                    title: '性别',
                    dataIndex: 'gender',
                    key: 'gender',
                    width: 60,
                }
                if (this.props.dataList.type === 10) { // 图片题
                    tmp.render = (content, index) => {
                        if (this.state.search_text !== "") {
                            content = content.replace(this.state.search_text, `<span style='color: #FF5186'>${this.state.search_text}</span>`)
                        }
                        let html = { __html: content };
                        return <div dangerouslySetInnerHTML={html}></div>;
                    }
                }
                columns.push(tmp)
            } else if (key === "age") {
                let tmp = {
                    title: '年龄',
                    dataIndex: 'age',
                    key: 'age',
                    width: 60,
                }
                columns.push(tmp)
            } else if (key === "comment") {
                // let tmp = {
                //     title: '评论',
                //     dataIndex: 'comment',
                //     key: 'comment',
                // }
                let tmp = {}
                if (this.props.dataList.type === 10) {
                    tmp = {
                        title: '评论',
                        dataIndex: 'comment',
                        key: 'comment',
                        width: 100,
                        align: 'center',
                        render: (text) => {
                            if (text) {
                                return <ConfigProvider locale={zhCN}>
                                    <Image src={text} alt="" width={60} height={60} style={{ "objectFit": "cover" }} />
                                </ConfigProvider>
                            } else {
                                let content = "未传图片"
                                if (this.state.search_text !== "") {
                                    content = content.replace(this.state.search_text, `<span style='color: #FF5186'>${this.state.search_text}</span>`)
                                }
                                let html = { __html: content };
                                return <div dangerouslySetInnerHTML={html}></div>;
                            }
                        }
                    }
                } else {
                    tmp = {
                        title: '评论',
                        dataIndex: 'comment',
                        key: 'comment',
                        render: (content, index) => {
                            if (this.state.search_text !== "") {
                                content = content.replace(this.state.search_text, `<span style='color: #FF5186'>${this.state.search_text}</span>`)
                            }
                            let html = { __html: content };
                            return <div dangerouslySetInnerHTML={html}></div>;
                        }
                    }
                }


                columns.push(tmp)
            } else if (key === "user_answer_id") {
                // 因为后端有user_answer_id
            } else {
                let tmp = {
                    title: key,
                    dataIndex: key,
                    key: key,
                }
                columns.push(tmp)
            }
        }
        return columns
    }
    showPagination() {
        if (this.state.blank_list.length < 30) {
            return false
        }
        return { pageSize: 30, showSizeChanger: false, showQuickJumper: true, size: "small" }
    }
    // 搜索按钮 传父
    handleSearch(t, event) {
        if (!t) return false
        if (!event) return false
        this.setState({
            blank_list: []
        })
        setTimeout(() => {
            let search_text = event.target.value.trim()
            if (search_text === "") {
                this.setState({
                    search_text: "",
                    blank_list: this.state.blank_list_formal
                })
            } else {
                let blank_list = []
                for (let i = 0; i < this.state.blank_list_formal.length; i++) {
                    if (this.props.dataList.type === 10) { // 图片题
                        if (this.state.blank_list_formal[i].name.indexOf(search_text) !== -1) {
                            blank_list.push(this.state.blank_list_formal[i])
                        } else if (this.state.blank_list_formal[i].gender.indexOf(search_text) !== -1) {
                            blank_list.push(this.state.blank_list_formal[i])
                        } else if ("未传图片".indexOf(search_text) !== -1 && this.state.blank_list_formal[i].comment === "") {
                            blank_list.push(this.state.blank_list_formal[i])
                        }
                    } else {
                        if (this.state.blank_list_formal[i].comment.indexOf(search_text) !== -1) {
                            blank_list.push(this.state.blank_list_formal[i])
                        }
                    }
                }
                this.setState({
                    search_text: search_text,
                    blank_list: blank_list
                })
            }
        })

    }
    render() {
        const prefix = (
            <img src={cur_icon_search} alt="" className='evaluation-icon-search' />
        )
        return (
            <div className="blank-list-container">
                <div className="search-wrapper">
                    <Input prefix={prefix} placeholder={this.props.dataList.type === 10 ? "搜索关键词" : "搜索评论关键词"} onPressEnter={this.handleSearch.bind(this, 'comment_search')} onBlur={this.handleSearch.bind(this, 'comment_search')} />
                </div>
                <ConfigProvider locale={zhCN}>
                    <Table
                        dataSource={this.state.blank_list}
                        columns={this.state.columns}
                        pagination={this.showPagination()}
                        rowKey={columns => columns.id}
                        scroll={{ y: this.props.isMaxChart ? this.props.blankListHeight : 258 }} />
                </ConfigProvider>
            </div>
        )
    }
}
