class util{
  static getTime({type,timestamp}={type:1,timestamp:Date.now()}){
      if(!(type && timestamp)){return false}
      let date = new Date(timestamp)
      let result = ''
      let setNumber = (number)=>{
          let string = ''
          if(number<10){
              string = '0'+number
          }else{
              string = number
          }
          return string
      }
      let y = date.getFullYear()
      let m = setNumber(date.getMonth()+1)
      let d = setNumber(date.getDate())
      let hh = setNumber(date.getHours())
      let mm = setNumber(date.getMinutes())
      let ss = setNumber(date.getSeconds())

      if(type==1) result = `${y}-${m}-${d} ${hh}:${mm}:${ss}`
      if(type==2) result = `${y}/${m}/${d} ${hh}:${mm}:${ss}`
      if(type==3) result = `${y}-${m}-${d} ${hh}:${mm}`
      if(type==4) result = `${y}/${m}/${d} ${hh}:${mm}`
      if(type==5) result = `${y}-${m}-${d}`
      if(type==6) result = `${y}/${m}/${d}`

      return result

  }
  // 数组去重
  static unique (arr = []) {
    return [...new Set(arr)];
  }
  // 数组排序
  static pop (arr = []) {
    for(let i = 0;i < arr.length - 1;i++){
      for(let n = 0; n < arr.length - 1;n++){
        if(arr[n]>arr[i]){
          [arr[n],arr[i]] = [arr[i],arr[n]];
        }
      }
    }
    return arr;
  }
  // 获取缓存
  static localGetItem (key = '') {
    if(!key) {
      return null;
    } else {
      let item = localStorage.getItem(key);
      if(item) {
        if(JSON.parse(item)) {
          return JSON.parse(item);
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }
  // 存储缓存
  static localSetItem (key,value) {
    if(key) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }
}
export default util