import React, { Component } from "react";
import "./question_ai_generation.scss";
import { connect } from "react-redux";
import { TreeSelect, message } from "antd";
import { getTagCategory } from "../../../../../api/Product/Product_list_api";
import { getBrand } from "../../../../../api/Home_page/home_page_api";
import XmSelect from "../../../../../components/Select/xm_select";
import EditNode from "../../../../../components/EditNode/edit_node";
import QuestionTemplatePanel from "../../Question_template_panel/question_template_panel";

class question_ai_generation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: 123,
      ai_survey_list: [],
      project_entry_number: "",
      needRefresh: true,
      prompt_level: -1,
      tree_id: null, //品类
      tree_val: "", //品类
      treeData: [], // 树选择框
      //1
      selected_brand_list: [], // 可选品牌列表
      selected_brand_list_formal: [],
      selected_brand_key_list: [],
      selected_brand_name: "", //品牌
      //2
      selected_product_name: "", //产品
      //3
      selected_survey_list: [], //调研类型
      selected_survey_option: [], //调研类型下拉框
      selected_survey_key_list: [],
      // 4
      other_prompt: "",
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props.project_entry_number) !==
      JSON.stringify(state.project_entry_number_prev)
    ) {
      return {
        survey_id: props.survey_id,
        // needRefresh: true,
        project_entry_number: props.project_entry_number || 0, //question
        project_entry_number_prev: props.project_entry_number,
      };
    }
    return null;
  }
  componentDidMount() {
    this._isMounted = true;
    this.setState({
      // survey_id: this.props.survey_id,
      project_entry_number: this.props.project_entry_number,
    });
  }
  componentDidUpdate(preProps, preState) {
    if (this._isMounted && this.state.needRefresh) {
      if (this.state.project_entry_number === 3) {
        this.init();
        this.setState({
          needRefresh: false,
        });
      }
    }
    if (
      this.props.ai_selected_survey_option?.length !==
      this.state.selected_survey_option.length
    ) {
      this.setState({
        selected_survey_option: this.props.ai_selected_survey_option,
      });
    }
  }
  componentWillUnmount() {
    this.setState({
      // survey_id: ""
    });
  }
  init() {
    this.getBrand();
    this.get_product_type_list();
  }
  // 获取品牌列表
  async getBrand() {
    this.setState({ loading_brand: 1 });
    try {
      const response = await getBrand({});
      let selected_brand_list = [];
      if (response.status === 204) {
        this.setState({
          selected_brand_list: [],
          selected_brand_list_formal: [],
        });
      } else {
        for (let i = 0; i < response.data.length; i++) {
          let obj = {
            key: "brand_id_" + response.data[i].id,
            value: response.data[i].name,
          };
          selected_brand_list.push(obj);
        }
        this.setState({
          selected_brand_list: selected_brand_list,
          selected_brand_list_formal: selected_brand_list,
          loading_brand: 0,
        });
      }
      return response;
    } catch (error) {
      this.setState({ loading_brand: 0 });
      message.error("加载失败!");
    }
  }
  // 获取标签类别
  async get_product_type_list() {
    this.setState({ loading_product_type: 1 });
    try {
      const response = await getTagCategory({
        type: 3,
        project_id: "",
        project_type: "",
        special_event_id: "",
      });
      let tag_category = response.data.tag_category.filter((element) => {
        element.title = element.name;
        element.value = element.id;
        return element.flag === 4;
      });
      const treeData = this.formatDataTree(tag_category);
      this.setState({
        treeData: treeData,
        loading_product_type: 0,
      });
      return response;
    } catch (error) {
      this.setState({ loading_product_type: 0 });
      message.error("加载失败!");
    }
  }
  // 获取树
  formatDataTree(data) {
    let parents = data.filter((p) => p.parent_id === 0);
    let children = data.filter((c) => c.parent_id !== 0);
    dataToTree(parents, children);
    return parents;
    function dataToTree(parents, children) {
      parents.map((p) => {
        children.map((c, i) => {
          if (c.parent_id === p.id) {
            let _children = JSON.parse(JSON.stringify(children));
            _children.splice(i, 1);
            dataToTree([c], _children);
            if (p.children) {
              p.children.push(c);
            } else {
              p.children = [c];
            }
          }
          return null;
        });
        return null;
      });
    }
  }
  // 选择第几个prompt
  handlePromptLevel(index) {
    this.setState({
      prompt_level: index,
    });
  }
  // 树标签 change
  onTreeSelectChange(value, label, extra) {
    this.setState({
      tree_id: value,
      tree_val: label[0],
    });
  }
  //2
  // 渲染brand下拉框
  //验证品牌并不能为空
  validateBrand(event) {
    if (event.target.value === "") {
      return;
    }
  }
  // 下拉框搜索可以大小写
  filterOption(input, option) {
    if (option.title) {
      return option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    } else {
      return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  }
  //下拉框选择后，并赋值到brand state里面
  onBrandSelectChange(key_arr) {
    if (key_arr.length) {
      let input_value = String(key_arr[key_arr.length - 1]);
      if (input_value.trim() === "") {
        message.warning("品牌不可为空");
        return;
      }
      let selected_brand_list = JSON.parse(
        JSON.stringify(this.state.selected_brand_list)
      );
      let is_exist = false;
      // debugger
      let max = -1;
      let has_same_brand = false;
      let brand_name = input_value;
      for (let i = 0; i < selected_brand_list.length; i++) {
        if (selected_brand_list[i].key === input_value) {
          is_exist = true;
          brand_name = selected_brand_list[i].value;
        }
        // else if (selected_brand_list[i].value.startsWith('[') && selected_brand_list[i].value.startsWith(']') && selected_brand_list[i].value.slice(1, -1) === input_value) {
        //     has_same_brand = true
        // }
        else if (selected_brand_list[i].value === input_value) {
          has_same_brand = true;
        }
        max = Math.max(max, parseInt(selected_brand_list[i].key.slice(9)));
      }
      if (!is_exist && has_same_brand) {
        message.warning("已存在相同品牌");
        return;
      } else if (!is_exist) {
        // 不存在
        let obj = { key: "brand_id_" + parseInt(max + 1), value: input_value };
        selected_brand_list.push(obj);
        this.setState({
          selected_brand_name: brand_name,
          selected_brand_key_list: ["brand_id_" + parseInt(max + 1)],
          selected_brand_list: selected_brand_list,
        });
      } else {
        this.setState({
          selected_brand_name: brand_name,
          selected_brand_key_list: [input_value],
          selected_brand_list: selected_brand_list,
        });
      }
    } else {
      this.setState({
        selected_brand_name: "",
        selected_brand_key_list: [],
      });
    }
  }
  // 问卷
  onSurveySelectChange(key_arr) {
    this.setState({
      selected_survey_key_list: key_arr,
    });
  }
  //3
  //产品
  onChangeEditor(editor_name, obj) {
    let { value } = obj;
    this.setState({
      [editor_name]: value,
    });
  }
  //3
  // 根据key获取value
  getSurveyValue(key) {
    for (let i = 0; i < this.state.selected_survey_option.length; i++) {
      if (this.state.selected_survey_option[i].key === key) {
        return this.state.selected_survey_option[i].value;
      }
    }
  }
  // 判断是否填写
  validateAiPrompt() {
    if (this.state.tree_val === "") {
      this.setState({
        prompt_level: 0,
      });
      message.warning("品类不可为空");
      return false;
    } else if (this.state.selected_brand_name === "") {
      message.warning("品牌不可为空");
      this.setState({
        prompt_level: 1,
      });
      return false;
    } else if (this.state.selected_product_name === "") {
      message.warning("产品不可为空");
      this.setState({
        prompt_level: 2,
      });
      return false;
    } else if (this.state.selected_survey_key_list.length === 0) {
      message.warning("调研选项不可为空");
      this.setState({
        prompt_level: 3,
      });
      return false;
    } else if (this.state.other_prompt === "") {
      message.warning("还想知道的填空不可为空");
      this.setState({
        prompt_level: 4,
      });
      return false;
    }
    return true;
  }
  // 是否生产新的ai问卷
  generateAiQuestion() {
    let isValidateAiPrompt = this.validateAiPrompt();
    if (isValidateAiPrompt) {
      message.warning("问卷生成中，请稍等片刻，您可同步进行其他操作。");
      let prompt =
        "我是" +
        this.state.tree_val +
        "品类，我的品牌叫" +
        this.state.selected_brand_name +
        "，我的产品是" +
        this.state.selected_product_name +
        "，我现在想调研的是" +
        this.state.selected_survey_key_list
          .map((item) => this.getSurveyValue(item))
          .join("、") +
        "，同时我还想知道" +
        this.state.other_prompt;
      this.props.generateAiQuestion(
        prompt,
        this.state.selected_survey_key_list
      );
    }
  }
  // 传给父亲
  addTemplateQuestion(item) {
    this.props.addTemplateQuestion(item);
  }
  // 是否可以生成问卷
  canGenerateAiQuestion() {
    if (!this.props.survey_queue || this.props.survey_queue.length === 0) {
      return true;
    }
    for (let i = 0; i < this.props.survey_queue.length; i++) {
      if (
        parseInt(this.props.survey_queue[i].survey_id) ===
        parseInt(this.props.survey_id)
      ) {
        return false;
      }
    }
    return true;
  }
  // 下拉框搜索可以大小写
  filterSelectOption(input, option) {
    return (
      option.children.props.children
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  render() {
    return (
      <div className="question-ai-generation-container">
        <div className="question-ai-generation">
          <div className="question-prompt-card">
            <span>我是</span>
            <span
              className={`prompt-fill ${
                this.state.prompt_level === 0 ? "prompt-fill-active" : ""
              }`}
              onClick={this.handlePromptLevel.bind(this, 0)}
            >
              {this.state.tree_id === null ? (
                <span className="prompt-fill-empty">（请选择）</span>
              ) : (
                this.state.tree_val
              )}
            </span>
            <span>品类，我的品牌叫</span>
            <span>
              <span
                className={`prompt-fill ${
                  this.state.prompt_level === 1 ? "prompt-fill-active" : ""
                }`}
                onClick={this.handlePromptLevel.bind(this, 1)}
              >
                {this.state.selected_brand_name === "" ? (
                  <span className="prompt-fill-empty">（请选择）</span>
                ) : (
                  this.state.selected_brand_name
                )}
              </span>
            </span>
            <span>，我的产品是</span>
            <span>
              <span
                className={`prompt-fill ${
                  this.state.prompt_level === 2 ? "prompt-fill-active" : ""
                }`}
                onClick={this.handlePromptLevel.bind(this, 2)}
              >
                {this.state.selected_product_name === "" ? (
                  <span className="prompt-fill-empty">（请填写）</span>
                ) : (
                  this.state.selected_product_name
                )}
              </span>
            </span>
            <span>，我现在想调研的是</span>
            {this.state.selected_survey_key_list.length ? (
              <span>
                {this.state.selected_survey_key_list.map((item, index) => {
                  return (
                    <span key={index}>
                      {index >= 1 ? "、" : ""}
                      <span
                        onClick={this.handlePromptLevel.bind(this, 3)}
                        className={`prompt-fill ${
                          this.state.prompt_level === 3
                            ? "prompt-fill-active"
                            : ""
                        }`}
                      >
                        {this.getSurveyValue.call(this, item)}
                      </span>
                    </span>
                  );
                })}
              </span>
            ) : (
              <span
                className={`prompt-fill ${
                  this.state.prompt_level === 3 ? "prompt-fill-active" : ""
                }`}
                onClick={this.handlePromptLevel.bind(this, 3)}
              >
                <span className="prompt-fill-empty">（请选择）</span>
              </span>
            )}
            <span>，同时我还想知道</span>
            <span>
              <span
                className={`prompt-fill ${
                  this.state.prompt_level === 4 ? "prompt-fill-active" : ""
                }`}
                onClick={this.handlePromptLevel.bind(this, 4)}
              >
                {this.state.other_prompt === "" ? (
                  <span className="prompt-fill-empty">（请填写）</span>
                ) : (
                  this.state.other_prompt
                )}
              </span>
            </span>
          </div>
          <div className="question-prompt-fill-card">
            {this.state.prompt_level === 0 ? (
              <div className="question-prompt-fill-wrapper">
                <div className="question-prompt-fill-name">品类</div>
                <TreeSelect
                  showSearch
                  dropdownClassName="tree-select-popup"
                  treeNodeFilterProp="title"
                  style={{ width: "100%" }}
                  value={this.state.tree_id}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  treeData={this.state.treeData}
                  placeholder="请选择品类"
                  treeDefaultExpandAll
                  onChange={(value, label, extra) => {
                    this.onTreeSelectChange(value, label, extra);
                  }}
                  autoFocus={true}
                ></TreeSelect>
              </div>
            ) : (
              ""
            )}
            {this.state.prompt_level === 1 ? (
              <div className="question-prompt-fill-wrapper">
                <div className="question-prompt-fill-name">品牌</div>
                <XmSelect
                  mode="tags"
                  placeholder="请选择或输入品牌"
                  onChange={(e) => {
                    this.onBrandSelectChange.call(this, e);
                  }}
                  maxTagCount={"responsive"}
                  allowClear
                  options={this.state.selected_brand_list}
                  value={this.state.selected_brand_key_list}
                  onInput={this.validateBrand.bind(this)}
                  iskey="true"
                  filterOption={(input, option) => {
                    return this.filterOption.call(this, input, option);
                  }}
                  defaultOpen
                  autoFocus={true}
                ></XmSelect>
              </div>
            ) : (
              ""
            )}
            {this.state.prompt_level === 2 ? (
              <div className="question-prompt-fill-wrapper">
                <div className="question-prompt-fill-name">产品</div>
                <EditNode
                  value={this.state.selected_product_name}
                  onChange={this.onChangeEditor.bind(
                    this,
                    "selected_product_name"
                  )}
                  placeholder="请输入产品名称"
                  className="edit-question-title edit-question-title-ai"
                ></EditNode>
              </div>
            ) : (
              ""
            )}
            {this.state.prompt_level === 3 ? (
              <div className="question-prompt-fill-wrapper question-prompt-fill-wrapper-multiple">
                <div className="question-prompt-fill-name">调研</div>
                <XmSelect
                  mode="multiple"
                  placeholder="请进行选择"
                  onChange={(e) => {
                    this.onSurveySelectChange.call(this, e);
                  }}
                  allowClear
                  options={this.state.selected_survey_option}
                  value={this.state.selected_survey_key_list}
                  filterOption={(input, option) => {
                    return this.filterSelectOption.call(this, input, option);
                  }}
                  defaultOpen
                  autoFocus={true}
                ></XmSelect>
              </div>
            ) : (
              ""
            )}
            {this.state.prompt_level === 4 ? (
              <div className="question-prompt-fill-wrapper">
                <div className="question-prompt-fill-name">其他</div>
                <EditNode
                  value={this.state.other_prompt}
                  onChange={this.onChangeEditor.bind(this, "other_prompt")}
                  placeholder="请输入"
                  className="edit-question-title edit-question-title-ai"
                ></EditNode>
              </div>
            ) : (
              ""
            )}
          </div>
          {this.canGenerateAiQuestion() ? (
            <div
              className="question-prompt-operaion"
              onClick={this.generateAiQuestion.bind(this)}
            >
              确定
            </div>
          ) : (
            <div className="question-prompt-operaion question-prompt-operaion-loading">
              正在生成中...
            </div>
          )}
        </div>
        {this.props.current_question_template_list.length ? (
          <div className="question-ai-template-panel">
            <QuestionTemplatePanel
              template_name="question-ai-template"
              question_template_list={this.props.current_question_template_list}
              addTemplateQuestion={this.addTemplateQuestion.bind(this)}
            ></QuestionTemplatePanel>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default connect(
  (state) => ({
    survey_queue: state.surveyReducer,
  }), //映射状态
  {} //映射操作状态的方法
)(question_ai_generation);
