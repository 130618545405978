import React, { Component } from 'react'
import './norm_info.scss';
import { getAccountCategoryList, getCategoryNorm } from '../../../api/Home_page/home_page_api'
import { Input, Tooltip, Image, ConfigProvider, message, Empty, Pagination as Pagination2 } from 'antd';
import NormItem from './Norm_item/norm_item'
import XmSelect from '../../../components/Select/xm_select';
import Loading from "../../../components/Loading/loading"
import { getUserStatus } from '../../../util/auth'

export default class norm_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading_norm: 0,
            loading_category: 0,
            loadingText: "数据较大，加载中",
            category_list: [], //账号相关品类分类
            category_list_1_formal: [],
            category_list_2_formal: [],
            category_list_3_formal: [],
            category_list_1: [],
            category_list_2: [],
            category_list_3: [],
            major_category_id: "",//账号相关品类
            norm_list: [], //品类norm值
            product_id: "",
            category_id_1: "",
            category_id_2: "",
            category_id_3: "",
            is_show: 0,
        }
    }
    componentDidMount() {
        // this.init()
    }

    componentDidUpdate() {
        if (this.props.product_id !== this.state.product_id) {
            this.init()
        }
    }
    init() {
        this.setState({
            product_id: this.props.product_id
        }, () => {
            this.getAccountCategoryList()
        })

    }
    // test 测试
    setCategoryList(category_list_formal) {
        let all = [{ level: 2, id: -1, parent_id: -1, name: "全部" }, { level: 3, id: -1, parent_id: -1, name: "全部" }]
        category_list_formal = [...all, ...category_list_formal]
        let category_list_1_formal = []
        let category_list_2_formal = []
        let category_list_3_formal = []

        let category_list_1 = []
        let category_list_2 = []
        let category_list_3 = []

        for (let i = 0; i < category_list_formal.length; i++) {
            let obj = { key: category_list_formal[i].id, value: category_list_formal[i].name, parent_id: category_list_formal[i].parent_id }
            if (category_list_formal[i].level === 1) {
                category_list_1_formal.push(obj)
            } else if (category_list_formal[i].level === 2) {
                category_list_2_formal.push(obj)
            } else {
                category_list_3_formal.push(obj)
            }
        }
        let category_id_1 = category_list_1_formal.length ? category_list_1_formal[0].key : ""
        category_list_1 = category_list_1_formal
        category_list_2 = category_list_2_formal.filter(ele => ele.key === -1 || ele.parent_id === category_id_1)
        let category_id_2 = category_list_2.length ? category_list_2[0].key : ""
        if (category_id_2 === -1) {
            category_list_3 = category_list_3_formal
        } else {
            category_list_3 = category_list_3_formal.filter(ele => ele.key === -1 || ele.parent_id === category_id_2)
        }
        let category_id_3 = category_list_3.length ? category_list_3[0].key : ""

        this.setState({
            category_list_1_formal: category_list_1_formal,
            category_list_2_formal: category_list_2_formal,
            category_list_3_formal: category_list_3_formal,
            category_list_1: category_list_1,
            category_list_2: category_list_2,
            category_list_3: category_list_3,
            category_id_1: category_id_1,
            category_id_2: category_id_2,
            category_id_3: category_id_3
        }, () => {
            this.getCategoryNorm()
        })
    }
    //获取账号相关品类
    async getAccountCategoryList() {
        this.setState({
            loading_category: 1
        })
        try {
            const response = await getAccountCategoryList({
                product_id: this.state.product_id || ""
            })
            this.setState({
                loading_category: 0
            })
            if (!response) return
            if (response.status === 204) {
                this.setState({
                    category_list_1: [],
                    category_list_2: [],
                    category_list_3: [],
                    category_id_1: "",
                    category_id_2: "",
                    category_id_3: "",
                    is_show: 0
                })
                message.warning('暂无数据', 2, () => { })
            } else {
                let category_list = response.data.category_list
                let is_show = response.data.is_show
                this.setState({
                    is_show: is_show
                })
                // let category_list = [
                //     { level: 1, id: 1, parent_id: 0, name: "1-" },
                //     { level: 1, id: 2, parent_id: 0, name: "2-" },
                //     { level: 2, id: 3, parent_id: 1, name: "1-1" },
                //     { level: 2, id: 4, parent_id: 1, name: "1-2" },
                //     { level: 2, id: 5, parent_id: 2, name: "2-1" },
                //     { level: 3, id: 6, parent_id: 3, name: "1-1-1" },
                //     { level: 3, id: 7, parent_id: 3, name: "1-1-2" },
                //     { level: 3, id: 8, parent_id: 4, name: "1-2-1" },
                //     { level: 3, id: 8, parent_id: 5, name: "2-1-1" },
                // ]
                this.setCategoryList(category_list)
            }
        } catch (error) {
            this.setState({
                loading_category: 0
            })
            message.error('后台发生错误')
            throw error;
        }
    }
    //获取品类norm值轴
    async getCategoryNorm() {
        this.setState({
            loading_norm: 1
        })
        try {
            const response = await getCategoryNorm({
                level_1: this.state.category_id_1 === -1 ? "" : this.state.category_id_1,
                level_2: this.state.category_id_2 === -1 ? "" : this.state.category_id_2,
                level_3: this.state.category_id_3 === -1 ? "" : this.state.category_id_3,
                // major_category_id: this.state.major_category_id,
                product_id: this.state.product_id || ""
            })
            this.setState({
                loading_norm: 0
            })
            if (!response) return
            if (response.status === 204) {
                this.setState({
                    norm_list: [],
                })
                message.warning('暂无品类Norm数据', 2, () => { })
            } else {
                let norm_list = response.data.norm_list
                for (let i = 0; i < norm_list.length; i++) {
                    norm_list[i].name = norm_list[i].brand_name + "-" + norm_list[i].product_title
                }
                this.setState({
                    norm_list: norm_list
                })
            }
        } catch (error) {
            this.setState({
                loading_norm: 0
            })
            message.error('后台发生错误')
            throw error;
        }
    }
    //筛选norm
    selectNormChange(key, option, level) {
        if (level === 1) {
            let category_id_1 = key
            let category_list_2 = this.state.category_list_2_formal.filter(ele => ele.key === -1 || ele.parent_id === category_id_1)
            let category_id_2 = category_list_2.length ? category_list_2[0].key : ""
            let category_list_3 = this.state.category_list_3_formal.filter(ele => ele.key === -1 || ele.parent_id === category_id_2)
            let category_id_3 = category_list_3.length ? category_list_3[0].key : ""

            this.setState({
                category_id_1: category_id_1,
                category_id_2: category_id_2,
                category_id_3: category_id_3,
                category_list_2: category_list_2,
                category_list_3: category_list_3
            }, () => {
                this.getCategoryNorm()
            })
        }
        else if (level === 2) {
            let category_id_2 = key
            let category_list_3 = []
            if (category_id_2 === -1) {
                category_list_3 = this.state.category_list_3_formal
            } else {
                category_list_3 = this.state.category_list_3_formal.filter(ele => ele.key === -1 || ele.parent_id === category_id_2)
            }
            let category_id_3 = category_list_3.length ? category_list_3[0].key : ""
            this.setState({
                category_id_2: category_id_2,
                category_id_3: category_id_3,
                category_list_3: category_list_3
            }, () => {
                this.getCategoryNorm()
            })
        } else { //3
            this.setState({
                category_id_3: key,
            }, () => [
                this.getCategoryNorm()
            ])
        }
    }
    //获取上下和白分左右的norm_list
    getNormList(location, quantiles) {
        let norm_list = this.state.norm_list.filter(item => {
            return item.location === location && item.quantiles === quantiles
        })
        return norm_list
    }
    // 下拉框搜索可以大小写
    filterSelectOption(input, option) {
        return option.children.props.children.toString().indexOf(input.toString()) >= 0
    }
    render() {
        return (
            <>
                {
                    this.state.loading_norm <= 0 ? "" : <Loading loadingText={this.state.loadingText}></Loading>
                }
                <div className="like-top-wrapper">
                    <div className="like-title">产品种草率</div>
                    <div className="like-title-right-wrapper">
                        <div className="like-top-select">
                            <XmSelect
                                dropdownClassName="download-type-dropdown"
                                showSearch
                                filterOption={(input, option) => {
                                    return this.filterSelectOption.call(this, input, option)
                                }}
                                placeholder="请选择"
                                defaultValue={this.state.category_id_1} key={this.state.category_id_1}
                                options={this.state.category_list_1}
                                onChange={(e, index) => {
                                    this.selectNormChange(e, index, 1)
                                }}>
                            </XmSelect>
                        </div>
                        <div className="like-top-select">
                            <XmSelect
                                dropdownClassName="download-type-dropdown"
                                showSearch
                                filterOption={(input, option) => {
                                    return this.filterSelectOption.call(this, input, option)
                                }}
                                placeholder="请选择"
                                defaultValue={this.state.category_id_2} key={this.state.category_id_2}
                                options={this.state.category_list_2}
                                onChange={(e, index) => {
                                    this.selectNormChange(e, index, 2)
                                }}>
                            </XmSelect>
                        </div>
                        {
                            this.state.is_show === 1 ?
                                <div className="like-top-select">
                                    <XmSelect
                                        dropdownClassName="download-type-dropdown"
                                        showSearch
                                        filterOption={(input, option) => {
                                            return this.filterSelectOption.call(this, input, option)
                                        }}
                                        placeholder="请选择"
                                        defaultValue={this.state.category_id_3} key={this.state.category_id_3}
                                        options={this.state.category_list_3}
                                        onChange={(e, index) => {
                                            this.selectNormChange(e, index, 3)
                                        }}>
                                    </XmSelect>
                                </div> : ""
                        }

                    </div>
                </div>
                <div className="like-content-wrapper">
                    <div className="like-up-container">
                        {
                            [5, 4, 3, 2, 1].map((item, index1) => {
                                return <div className="like-location-wrapper" key={index1}>
                                    <div className="like-location">
                                        {
                                            this.getNormList(2, item).map((item, index) => {
                                                return <NormItem norm={item} index={index} key={index}></NormItem>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                    <div className="like-norm-container">
                        <div className="like-norm-wrapper">
                            种草率ranked Top 20%
                        </div>
                        <div className="like-norm-wrapper">
                            <div className="like-norm-sign"></div>
                            <div className="like-norm-text">Ranked BET 20%~40%</div>
                        </div>
                        <div className="like-norm-wrapper">
                            <div className="like-norm-sign"></div>
                            <div className="like-norm-text">Ranked BET 40%~60%</div>
                        </div>
                        <div className="like-norm-wrapper">
                            <div className="like-norm-sign"></div>
                            <div className="like-norm-text">Ranked BET 60%~80%</div>
                        </div>
                        <div className="like-norm-wrapper">
                            <div className="like-norm-sign"></div>
                            <div className="like-norm-text">种草率ranked Bottom 20%</div>
                        </div>
                    </div>
                    <div className="like-down-container">
                        {
                            [5, 4, 3, 2, 1].map((item2, index2) => {
                                return <div className="like-location-wrapper" key={index2}>
                                    <div className="like-location">
                                        {
                                            this.getNormList(1, item2).map((item, index) => {
                                                return <NormItem norm={item} index={index} key={index}></NormItem>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </>
        )
    }
}
