export function get_url() {
    if (window.location.href.includes('cem-beta.xinhekeji.net')) {             // beta服
        return 'https://cem-beta.xinhekeji.net/api/'
    } else if (window.location.href.includes('cem-alpha.xinhekeji.net')) {      // alpha服
        return 'https://cem-alpha.xinhekeji.net/api/'
    } else if (window.location.href.includes('cem.xinhekeji.net')) {            // 正式服
        return 'https://cem.xinhekeji.net/api/'
    } else {                                                              // 本地
        // return 'http://192.168.7.27:8000'
        return 'https://cem-beta.xinhekeji.net/api/'
    }
}
