import React, { Component } from 'react'
import "./Edit_formula_dialog.scss"
import cur_icon_close_gre from '../../../../images/cur_icon_close_gre.png'
import { message } from 'antd';

export default class Edit_formula_dialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formulaText: "",
            selected_question_list: [],
            canShowEditFormula: false,
            canShowEditFormula_prev: false
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.canShowEditFormula) !== JSON.stringify(state.canShowEditFormula_prev)) {
            return {
                formulaText: props.formulaText,
                canShowEditFormula: props.canShowEditFormula,
                canShowEditFormula_prev: props.canShowEditFormula,
                selected_question_list: props.selected_question_list
            };
        }
        return null;
    }
    componentDidMount() {
        this.initFormula()
        this._isMounted = true
    }
    componentDidUpdate() {

    }
    handleCloseEditFormula(dialog_setting) {
        let formulaText = JSON.parse(JSON.stringify(this.state.formulaText));
        if (Object.prototype.toString.call(this.state.formulaText) === "[object Array]") {
            formulaText = formulaText.join('')
        }
        this.props.handleShowEditFormula(dialog_setting, formulaText);
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    // 初始化变量
    initFormula() {
        let formulaText = []
        if (Object.prototype.toString.call(this.state.formulaText) === "[object String]") {
            formulaText = this.state.formulaText.split('')
        }
        for (let i = 0; i < formulaText.length; i++) {
            if (/^X\d*$/.test(formulaText[i])) {
                if (formulaText[i + 1] && formulaText[i + 1] - 0 === Number(formulaText[i + 1])) {
                    formulaText[i] = formulaText[i] + formulaText[i + 1];
                    formulaText.splice(i + 1, 1);
                    i -= 1;
                }
            }
        }
        this.setState({
            formulaText: formulaText
        })
    }
    // 点击变量
    clickFormula(e) {
        let formulaText = JSON.parse(JSON.stringify(this.state.formulaText));
        let length = formulaText.length;
        if (length) {
            let value = formulaText[length - 1];
            if (typeof value === "number") {
                message.error('请选择运算符')
            } else if (value === '(') {
                formulaText.push(e);
            } else if (value === ")") {
                message.error('请选择运算符')
            } else if (/^X\d*$/.test(value)) {
                message.error('请选择运算符')
            } else {
                formulaText.push(e);
            }
        } else {
            formulaText.push(e);
        }
        this.setState({
            formulaText: formulaText
        })
    }
    // 点击输入 括号
    clickBrackets(e) {
        let formulaText = JSON.parse(JSON.stringify(this.state.formulaText));
        let length = formulaText.length;
        switch (e) {
            case '(':
                if (length) {
                    let value = formulaText[length - 1];
                    if (typeof value === "number") {
                        message.error('请选择运算符')
                    } else if (value === '(') {
                        formulaText.push(e);
                    } else if (value === ')') {
                        message.error('请选择运算符')
                    } else if (/^X\d*$/.test(value)) {
                        message.error('请选择运算符')
                    } else {
                        formulaText.push(e);
                    }
                } else {
                    formulaText.push(e);
                }
                break;
            case ')':
                if (length) {
                    let value = formulaText[length - 1];
                    if (typeof value === "number") {
                        let num1 = 0;
                        let num2 = 0;
                        formulaText.map(item => {
                            if (item === '(') num1 += 1;
                            if (item === ')') num2 += 1;
                        })
                        num1 > num2 ? formulaText.push(e) : message.error('没有想匹配的"("')
                    } else if (value === '(') {
                        message.error('()中没有表达式')
                    } else if (value === ')') {
                        let num1 = 0;
                        let num2 = 0;
                        formulaText.map(item => {
                            if (item === '(') num1 += 1;
                            if (item === ')') num2 += 1;
                        })
                        num1 > num2 ? formulaText.push(e) : message.error('没有想匹配的"("')
                    } else if (/^X\d*$/.test(value)) {
                        let num1 = 0;
                        let num2 = 0;
                        formulaText.map(item => {
                            if (item === '(') num1 += 1;
                            if (item === ')') num2 += 1;
                        })
                        num1 > num2 ? formulaText.push(e) : message.error('没有想匹配的"("')
                    } else {
                        message.error('请输入数字或变量')
                    }
                } else {
                    message.error('）不能在第一位')
                }
                break;
            default:
                break;
        }
        this.setState({
            formulaText: formulaText
        })
    }
    // 回退
    backspaceFormula() {
        let formulaText = JSON.parse(JSON.stringify(this.state.formulaText));
        if (formulaText.length) {
            formulaText.splice(formulaText.length - 1, 1);
        }
        this.setState({
            formulaText: formulaText
        })
    }
    // 点击输入 数字
    clickNumber(e) {
        let formulaText = JSON.parse(JSON.stringify(this.state.formulaText));
        let length = formulaText.length;
        if (length) {
            let value = formulaText[length - 1];
            if (typeof value === "number") {
                formulaText[length - 1] = formulaText[length - 1] * 10 + e;
            } else if (value === '(') {
                formulaText.push(e);
            } else if (value === ")") {
                message.error('请选择运算符')
            } else if (/^X\d*$/.test(value)) {
                message.error('请选择运算符')
            } else {
                formulaText.push(e);
            }
        } else {
            formulaText.push(e);
        }
        this.setState({
            formulaText: formulaText
        })
    }
    // 点击输入 运算符
    clickOperator(e) {
        let formulaText = JSON.parse(JSON.stringify(this.state.formulaText));
        let length = formulaText.length;
        if (length) {
            let value = formulaText[length - 1];
            if (typeof value === "number") {
                formulaText.push(e);
            } else if (value === '(') {
                message.error('请输入数字或变量')
            } else if (value === ')') {
                formulaText.push(e);
            } else if (/^X\d*$/.test(value)) {
                formulaText.push(e);
            } else {
                message.error('请输入数字或变量')
            }
        } else {
            message.error(`${e}不能在第一位`)
        }
        this.setState({
            formulaText: formulaText
        })
    }
    // 清除公式
    clearFormula() {
        let formulaText = [];
        this.setState({
            formulaText: formulaText
        })
    }
    render() {
        return (
            <div className="edit-formula-dialog">
                <div className="edit-formula-container">
                    <div className="edit-formula-top">
                        <div className="edit-formula-top-title">
                            编辑公式
                        </div>
                        <img src={cur_icon_close_gre} onClick={this.handleCloseEditFormula.bind(this)} className='edit-formula-top-close' alt="" />
                    </div>
                    <div className="edit-formula-content">
                        <div className="edit-formula-text">
                            {this.state.formulaText}
                        </div>
                        <div className="edit-formula-main">
                            <div className="edit-formula-left">
                                <div className="edit-formula-box" onClick={this.clickBrackets.bind(this, '(')}>(</div>
                                <div className="edit-formula-box" onClick={this.clickBrackets.bind(this, ')')}>)</div>
                                <div className="edit-formula-box"></div>
                                <div className="edit-formula-box" onClick={this.backspaceFormula.bind(this)}>BS</div>
                                <div className="edit-formula-box" onClick={this.clickNumber.bind(this, 7)}>7</div>
                                <div className="edit-formula-box" onClick={this.clickNumber.bind(this, 8)}>8</div>
                                <div className="edit-formula-box" onClick={this.clickNumber.bind(this, 9)}>9</div>
                                <div className="edit-formula-box" onClick={this.clickOperator.bind(this, '+')}>+</div>
                                <div className="edit-formula-box" onClick={this.clickNumber.bind(this, 4)}>4</div>
                                <div className="edit-formula-box" onClick={this.clickNumber.bind(this, 5)}>5</div>
                                <div className="edit-formula-box" onClick={this.clickNumber.bind(this, 6)}>6</div>
                                <div className="edit-formula-box" onClick={this.clickOperator.bind(this, '-')}>-</div>
                                <div className="edit-formula-box" onClick={this.clickNumber.bind(this, 1)}>1</div>
                                <div className="edit-formula-box" onClick={this.clickNumber.bind(this, 2)}>2</div>
                                <div className="edit-formula-box" onClick={this.clickNumber.bind(this, 3)}>3</div>
                                <div className="edit-formula-box" onClick={this.clickOperator.bind(this, '*')}>*</div>
                                <div className="edit-formula-box" onClick={this.clearFormula.bind(this)}>C</div>
                                <div className="edit-formula-box" onClick={this.clickNumber.bind(this, 0)}>0</div>
                                <div className="edit-formula-box" onClick={this.clickOperator.bind(this, '%')}>%</div>
                                <div className="edit-formula-box" onClick={this.clickOperator.bind(this, '/')}>/</div>
                            </div>
                            <div className="edit-formula-right">
                                {
                                    this.state.selected_question_list.map((item, index) => {
                                        return <div className="edit-formula-box" key={index} onClick={this.clickFormula.bind(this, item.question_variable)}>
                                            {item.question_variable}
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div className="edit-formula-bottom">
                        <div onClick={this.handleCloseEditFormula.bind(this, "cancel")}>取消</div>
                        <div onClick={this.handleCloseEditFormula.bind(this, "submit")}>确认</div>
                    </div>
                </div>
            </div >
        )
    }
}
