import "../App.css";
import Login from "../components/Login";
import Home from "../pages/Home/Home";
import Home_page from "../pages/Home_page/home_page";
import Product_entry from "../pages/Product_entry/Product_entry";
import Product_list from "../pages/Product_list/Product_list";
import Product_info from "../pages/Product_info/Product_info";
import Project_entry from "../pages/Project_entry/Project_entry";
import Dispatch_list from "../pages/Dispatch_list/dispatch_list";
import Survey_list from "../pages/Survey_list/survey_list";
import Logistics_info from "../pages/Project_entry/Logistics_info/logistics_info";
// import All_data from '../pages/All_data/All_data'
// import All_cloud from '../pages/All_cloud/a'
import Overall_data from "../pages/Overall_data/Overall_data";
import Consumer_research from "../pages/Consumer_research/consumer_research";
import Brand_survey from "../pages/Brand_survey/brand_survey";
import Emotion_analysis from "../pages/Emotion_analysis/Emotion_analysis";
import Free_cross from "../pages/Free_cross/free_cross"; // 调研的交叉分析
import FreecrossProduct from "../pages/Freecross_product/freecross_product"; // 产品的交叉分析
import GptAnalysis from "../pages/Gpt_analysis/gpt_analysis";
import Error_page from "../pages/Error_page/Error_page";
import Product_dropdown from "../pages/Cem_product_dropdown/product_dropdown";
import User_agreement from "../pages/Login/Login_file/user_agreement";
import Privacy_agreement from "../pages/Login/Login_file/privacy_agreement";
import User_info from "../pages/User_info/user_info";
// import Brand_survey from '../Brand_survey/Brand_survey copy';
import Survey_sankey from "../pages/Survey_sankey/survey_sankey";
import Contrast_survey from '../pages/Contrast_survey/contrast_survey'

const routerMap = [
  { path: "/", name: "/", component: Login },
  { path: "/login", name: "Login", component: Login },
  { path: "/home", name: "Home", component: Home, auth: true },
  {
    path: "/user_agreement",
    name: "User_agreement",
    component: User_agreement,
  },
  {
    path: "/privacy_agreement",
    name: "Privacy_agreement",
    component: Privacy_agreement,
  },
  { path: "/home_page", name: "Home_page", component: Home_page, auth: true },
  {
    path: "/dispatch_list/logistics_info",
    name: "Logistics_info",
    component: Logistics_info,
    auth: true,
  },
  {
    path: "/dispatch_list/project_entry",
    name: "Project_entry",
    component: Project_entry,
    auth: true,
  },
  {
    path: "/survey_list/project_entry",
    name: "Project_entry",
    component: Project_entry,
    auth: true,
  },
  {
    path: "/dispatch_list",
    name: "dispatch_list",
    component: Dispatch_list,
    auth: true,
  }, //派送计划
  {
    path: "/survey_list",
    name: "survey_list",
    component: Survey_list,
    auth: true,
  }, //调研计划
  {
    path: "/product_list/product_entry",
    name: "Product_entry",
    component: Product_entry,
    auth: true,
  },
  {
    path: "/product_list",
    name: "Product_list",
    component: Product_list,
    auth: true,
  },
  {
    path: "/product_info",
    name: "Product_info",
    component: Product_info,
    auth: true,
  },
  // { path: "/all_data", name: "All_data", component: All_data, auth: true },
  {
    path: "/overall_data",
    name: "Overall_data",
    component: Overall_data,
    auth: true,
  },
  {
    path: "/consumer_research",
    name: "Consumer_research",
    component: Consumer_research,
    auth: true,
  },
  {
    path: "/emotion_analysis",
    name: "Emotion_analysis",
    component: Emotion_analysis,
    auth: true,
  },
  // { path: "/free_cross", name: "Free_cross", component: Free_cross, auth: true },
  {
    path: "/survey_freecross",
    name: "Free_cross",
    component: Free_cross,
    auth: true,
  }, // 调研的交叉分析
  {
    path: "/product_freecross",
    name: "FreecrossProduct",
    component: FreecrossProduct,
    auth: true,
  }, // 产品的交叉分析
  {
    path: "/ai_analysis_product",
    name: "GptAnalysis",
    component: GptAnalysis,
    auth: true,
  }, // GPT简答题分析
  {
    path: "/ai_analysis_survey",
    name: "GptAnalysis",
    component: GptAnalysis,
    auth: true,
  }, // GPT简答题分析s
  {
    path: "/error_page",
    name: "Error_page",
    component: Error_page,
    auth: true,
  },
  {
    path: "/product_dropdown",
    name: "Product_dropdown",
    component: Product_dropdown,
    auth: true,
  },
  {
    path: "/brand_survey",
    name: "Brand_survey",
    component: Brand_survey,
    auth: true,
  },
  // { path: "/all_cloud", name: "All_cloud", component: All_cloud, auth: false },
  { path: "/user_info", name: "User_info", component: User_info, auth: true },
  {
    path: "/survey_sankey",
    name: "Survey_sankey",
    component: Survey_sankey,
    auth: true,
  }, // sankey图表
  {
    path: '/contrast_survey',
    name: 'Contrast_survey',
    component: Contrast_survey,
    auth: true,
  },
  {
    path: '/contrast_product',
    name: 'Contrast_product',
    component: Contrast_survey,
    auth: true,
  },
];
export default routerMap;
