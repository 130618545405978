import React, { Component } from 'react'
import { message } from 'antd';
import './copy_popup.scss';
import cur_icon_close_gre from "../../../images/cur_icon_close_gre.png"
import { getSurveyList, duplicateSurvey } from '../../../api/Project/project_list_api'
import XmSelect from '../../../components/Select/xm_select'

export default class copy_popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            survey_type_list: [
                { key: 2, value: "产品问卷" },
                { key: 4, value: "调研问卷" },
            ],
            survey_type: null,
            from_survey_id: null,
            dispatch_list: [],
            survey_list: [],
            current_survey_list: []
        }
    }
    componentDidMount() {
        this.init()
    }
    init() {
        this.getSurveyList(2)
        this.getSurveyList(4)
    }
    // 获取问卷列表(用于问卷复制）
    async getSurveyList(survey_type) {
        try {
            const response = await getSurveyList({
                survey_type: survey_type
            })
            let current_survey_list = response.data.data.map((item) => {
                return {
                    key: item.survey_id,
                    value: item.name
                }
            })
            if (survey_type === 2) {
                this.setState({
                    dispatch_list: current_survey_list
                })
            } else if (survey_type === 4) {
                this.setState({
                    survey_list: current_survey_list
                })
            }
            return response;
        } catch (err) {
            if (err.response?.data?.detail) {
                message.error(`${err.response.data.detail}`, 2, () => { })
            } else {
                message.error('登录失败')
            }
            throw err
        }
    }
    // 复制问卷
    async duplicateSurvey() {
        try {
            const response = await duplicateSurvey({
                from_survey_id: this.state.from_survey_id,
                to_survey_id: this.props.to_survey_id
            })
            message.success('复制成功', 1, () => { })
            this.props.canShowCopyPopup({
                showCopyPopup: false,
            })
            return response;
        } catch (err) {
            if (err.response?.data?.detail) {
                message.error(`${err.response.data.detail}`, 2, () => { })
            } else {
                message.error('后台发生错误，复制失败')
            }
            throw err
        }
    }
    tags_confirm() {
        if (!this.state.survey_type) {
            message.warning('请选择问卷类型', 2, () => { })
            return
        }
        if (!this.state.from_survey_id) {
            message.warning('请选择问卷', 2, () => { })
            return
        }
        if (!this.props.to_survey_id) {
            message.warning('缺失survey_id，暂不能复制', 2, () => { })
            return
        }
        this.duplicateSurvey()
        // this.props.canShowCopyPopup({
        //     showCopyPopup: false,
        // })
    }
    tags_close() {
        this.props.canShowCopyPopup({
            showCopyPopup: false,
            // add_question_type: ""
        })
    }
    // 下拉框搜索可以大小写
    filterSelectOption(input, option) {
        return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    // 选择问卷
    onSelectChange(key, option, t) {
        if (t === "survey_type") {
            if (key === 2) {
                this.setState({
                    from_survey_id: null,
                    current_survey_list: this.state.dispatch_list
                })
            } else if (key === 4) {
                this.setState({
                    from_survey_id: null,
                    current_survey_list: this.state.survey_list
                })
            }
        }
        this.setState({
            [t]: key
        })
    }
    render() {
        return (
            <div className='question-copy-popup-container'>
                <div className='question-copy-popup-bg'>
                    <div className='question-copy-popup-main'>
                        <div className='question-copy-popup-top'>
                            <div className='popup-top-title'>问卷导入</div>
                            <div className='popup-delete-icon'>
                                <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                            </div>
                        </div>
                        <div className='question-copy-popup-content'>
                            <div className='card-item-wrapper'>
                                <div className='card-item-title'>
                                    选择问卷类型
                                </div>
                                <div className='card-item'>
                                    <XmSelect
                                        options={this.state.survey_type_list}
                                        value={this.state.survey_type}
                                        onChange={(e, index) => { this.onSelectChange(e, index, "survey_type") }}
                                        placeholder="请选择问卷类型"
                                        showSearch
                                        filterOption={(input, option) => {
                                            return this.filterSelectOption.call(this, input, option)
                                        }
                                        }
                                    ></XmSelect>
                                </div>
                            </div>
                            <div className='card-item-wrapper'>
                                <div className='card-item-title'>
                                    选择问卷
                                </div>
                                <div className='card-item'>
                                    <XmSelect
                                        options={this.state.current_survey_list}
                                        value={this.state.from_survey_id}
                                        onChange={(e, index) => { this.onSelectChange(e, index, "from_survey_id") }}
                                        placeholder="请选择问卷"
                                        showSearch
                                        filterOption={(input, option) => {
                                            return this.filterSelectOption.call(this, input, option)
                                        }
                                        }
                                    ></XmSelect>
                                </div>
                            </div>
                        </div>
                        <div className='tag-btn'>
                            <div onClick={this.tags_confirm.bind(this)}>确认</div>
                            <div onClick={this.tags_close.bind(this)}>取消</div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
