import React, { Component } from 'react'
import * as echarts from 'echarts';
import { addListener } from "resize-detector";
import { getPieOption } from "../../../util/Chart_config/Pie_chart_config";
import { getBarOption } from "../../../util/Chart_config/Bar_chart_config";
import { getStackbarOption } from "../../../util/Chart_config/Stackbar_chart_config_survey";
import { getMatrixLineOption } from "../../../util/Chart_config/Matrix_line_config";
import { getCustomBarOption } from "../../../util/Chart_config/Custom_bar_chart_config"
import { formatChart } from "../../../util/Chart_config/Config";
import FreecrossMaxChartPopup from '../Freecross_max_chart_popup/freecross_max_chart_popup'
import "./freecross_chart.scss"

export default class freecross_chart extends Component {
    constructor(props) {
        super(props);
        this.maxChartRef = React.createRef();
        this.state = {
            dataList: {},
            single_id: "",
            needRefresh: false,
            isMaxSreen: false,
            showMaxChartPopup: false, // 是否显示放大图表的对话框
        }
    }
    componentDidMount() {
        this._isMounted = true;
        // this.init()
    }
    componentDidUpdate(preProps, preState) {
        if (preProps.single_id !== this.props.single_id || preProps.dataList !== this.props.dataList) {
            this.setState({
                dataList: this.props.dataList,
                single_id: this.props.single_id
            }, () => {
                this.init()
            })
        }
    }
    componentWillUnMount = () => {
        this._isMounted = false;
        this.setState = () => { }
    }
    init() {
        // 当图表没有id的时候，不生成图表
        if (this.state.single_id === "") return
        this.toInitChart()
    }
    //生成饼图
    toInitPieChart(id, isMaxSreen) {
        let data = this.props.dataList.data
        let isConsumer = false
        let option = getPieOption(data, isConsumer, isMaxSreen)
        formatChart(id, option)
    }
    // 生成柱状图
    toInitBarChart(id, isMaxSreen) {
        let data = this.props.dataList.data
        let xAxisList = this.props.dataList.xAxisList
        let sum = this.props.dataList.sum
        let option = getBarOption(xAxisList, data, sum, isMaxSreen)
        formatChart(id, option)
    }
    // 自定义bar 但是没有下面三个黄色的
    toInitCustomBarChart(id, isMaxSreen) {
        // let id = this.props.single_id
        let xAxisList = this.props.dataList.x_axis
        let data = this.props.dataList.data

        let sum = data.reduce(function (prev, curr) {
            return prev + curr;
        }, 0);

        let option = getCustomBarOption(xAxisList, data, sum)
        formatChart(id, option)
    }
    toInitStackBarChart(id, isMaxSreen) {
        // let id = this.props.single_id
        let xAxisList = this.props.dataList.xAxisList
        let seriesBarList = this.props.dataList.seriesBarList
        let series = []
        let sum_arr = []
        seriesBarList.forEach((item, index) => {
            let obj = {
                name: item.name,
                type: 'bar',
                barWidth: '50%', //柱子宽度
                barMaxWidth: 150,
                stack: 'overlap',
                data: item.data
            }
            for (let i = 0; i < item.data.length; i++) {
                if (!sum_arr[i] && sum_arr[i] !== 0) {
                    sum_arr[i] = 0
                    sum_arr[i] = sum_arr[i] + item.data[i]
                } else {
                    sum_arr[i] = sum_arr[i] + item.data[i]
                }
            }
            series.push(obj)
        })
        let option = getStackbarOption(xAxisList, series, sum_arr, isMaxSreen)
        formatChart(id, option)
    }
    toInitMatrixLineChart(id, isMaxSreen) {
        let res = this.props.dataList.chart_list
        let option = getMatrixLineOption(res)
        formatChart(id, option)
    }
    // 生成图表
    toInitChart(isMaxSreen) {
        let id = "chart_" + this.props.single_id
        if (this.state.showMaxChartPopup) {
            id = "max_" + id
        }
        if (!document.getElementById(id)) return
        let type = this.props.dataList.question_type
        if (type === 1 || type === 12 || type === 13) {
            this.toInitPieChart(id, isMaxSreen)
        } else if (type === 3) { //简单题
        } else if (type === 2) {
            this.toInitBarChart(id, isMaxSreen)
        } else if (type === 4) {
            this.toInitStackBarChart(id, isMaxSreen)
        } else if (type === 5 || type === 7) {
            this.toInitCustomBarChart(id, isMaxSreen)
        } else if (type === 15 || type === 14 || type === 22) {
            this.toInitMatrixLineChart(id, isMaxSreen)
        }
    }
    // 放大图表
    handleMaxChart() {
        this.setState({
            showMaxChartPopup: true,
        }, () => {
            this.maxChartRef.current.setMaxChart({
                dataList: this.props.dataList,
                single_id: this.props.single_id,
                isConsumer: false
            })
        })
    }
    // 子组件传给父组件状态 是否放大图表
    canShowMaxChartPopup(object) {
        this.setState({
            showMaxChartPopup: object.showMaxChartPopup,
        })
    }
    render() {
        return (
            <div className="freecross-chart-container">
                <div className='chart-operaion-wrapper'>
                    <div className="chart-operaion-text" onClick={this.handleMaxChart.bind(this)}>放大</div>
                </div>
                <div className='chart-wrapper' id={`chart_${this.props.single_id}_wrapper`}>
                    <div className="chart-wrapper-2">
                        <div id={`chart_${this.props.single_id}`} className={`echart-container preinstall-echart comment_${this.props.single_id}`}></div>
                    </div>
                </div>
                {
                    this.state.showMaxChartPopup ? <div className="chart-wrapper-2">
                        <FreecrossMaxChartPopup ref={this.maxChartRef}
                            canShowMaxChartPopup={this.canShowMaxChartPopup.bind(this)}
                            toInitChart={this.toInitChart.bind(this)}
                        ></FreecrossMaxChartPopup>
                    </div> : ""
                }
            </div>
        )
    }
}
