import axiosInstance from '../APIUtils'

// 保存项目问卷信息
export function saveProjectSurvey(obj) {
    return axiosInstance({
        url: "/cem/saveProjectSurvey/",
        method: "POST",
        data: obj
    });
}

// 获取项目问卷信息
export function getProjectSurvey(obj) {
    return axiosInstance({
        url: "/cem/getProjectSurvey/",
        method: "POST",
        data: obj
    });
}

// 更新项目问卷信息
export function updateProjectSurvey(obj) {
    return axiosInstance({
        url: "/cem/updateProjectSurvey/",
        method: "POST",
        data: obj
    });
}

// 保存项目选项互斥信息
export function saveProjectSurveyMutex(obj) {
    return axiosInstance({
        url: "/cem/saveProjectSurveyMutex/",
        method: "POST",
        data: obj
    });
}