import React, { Component } from 'react'
import './single_info.scss';
import EditNode from '../../../../../EditNode/edit_node';
import XmUpload from '../../../../../Upload_pic/xm_upload';
import cur_icon_delete from "../../../../../../images/cur_icon_delete.png"
import cur_icon_add from "../../../../../../images/cur_icon_add.png"
import cur_icon_radio_nor from "../../../../../../images/cur_icon_radio_nor.png"
let that = ''
export default class single_info extends Component {
    constructor(props) {
        super(props);
        this.has_fixed_list = [11, 12, 13] // 最大小值 名称
        this.has_option_active_list = [1, 2, 12, 13, 4] //单选多选排序的选项上下家
        this.state = {
            name: 'single_info',
            dataList: {},
            answers: [],
        }

    }

    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                answers: props.dataList.answers || state.answers,
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
        // if (this._isMounted) {
        //     this.toInitChart()
        //     setTimeout(() => {
        //         this.toInitFoot()
        //     })
        // }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            // this.toInitChart()
            // setTimeout(() => {
            //     this.toInitFoot()
            // })
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    // 编辑选项
    onChangeEditor(option_index, obj) {
        this.props.handleQuestionOptionName(option_index, obj.value)
    }
    // 添加选项图片
    addOptionPic(option_index, image, filename) {
        this.props.addOptionPic(option_index, image, filename)
    }
    // 删除选项图片
    deleteOptionPic(option_index) {
        this.props.deleteOptionPic(option_index)
    }
    // 选项上下架
    handleActiveOption(option_index) {
        this.props.handleActiveOption(option_index)
    }
    // 删除选项
    handleDeleteOption(option_index) {
        this.props.handleDeleteOption(option_index)
    }
    // 设置选项
    handleSetOption(option_index, e) {
        //如果提供了事件对象，则这是一个非IE浏览器 
        if (e && e.stopPropagation) {
            //因此它支持W3C的stopPropagation()方法 
            e.stopPropagation();
        } else {
            //否则，我们需要使用IE的方式来取消事件冒泡 
            window.event.cancelBubble = true;
        }

        this.props.handleSetOption(option_index)
    }
    //添加选项
    handleAddOption() {
        this.props.handleAddOption()
    }
    render() {
        return (
            <div className="single-info-container">
                <div>
                    {
                        this.state.answers.map((item, index) => {
                            return (
                                <div className={`single-item-wrapper ${item.is_option_active === 1 ? "" : "single-item-wrapper-2"} ${index===this.props.currentOptionIndex ? "single-item-wrapper-setting" : ""}`} key={index}>
                                    <div className="single-left-wrapper">
                                        {/* 左边 */}
                                        <div className="radio-icon-wrapper">
                                            <img src={cur_icon_radio_nor} alt="" className="setting-radio-icon" />
                                        </div>
                                        {/* 中间 */}
                                        <div className={this.has_fixed_list.includes(this.state.dataList.question_type) ? "isHide" : ""}>
                                            <div className="single-content-wrapper single-content-wrapper-border">
                                                {/* 中上 */}
                                                <div className="single-title-wrapper">
                                                    <div className={item.is_other ? "isHide" : "edit-question-title-wrap"}>
                                                        <EditNode value={item.name} onChange={this.onChangeEditor.bind(this, index)} className="edit-question-title"></EditNode>
                                                    </div>
                                                    <div className={item.is_other ? "edit-question-title-wrap" : "isHide"}>
                                                        其他
                                                    </div>
                                                    <div>
                                                        <XmUpload className="upload-question-pic"
                                                            image_name={item.image.val}
                                                            handleDeletePic={this.deleteOptionPic.bind(this, index)}
                                                            handleUploadPic={this.addOptionPic.bind(this, index)}></XmUpload>
                                                    </div>
                                                </div>
                                                {/* 中下 */}
                                                <div className={item.is_other ? "other-input-container" : "isHide"}>
                                                    <input className='other-input' disabled="disabled" defaultValue="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={this.has_fixed_list.includes(this.state.dataList.question_type) ? "" : "isHide"}>
                                            <div className="single-content-wrapper single-content-wrapper-border">
                                                {/* 中上 */}
                                                <div className="single-title-wrapper">
                                                    <div className={item.is_other ? "isHide" : "edit-question-title-wrap"}>
                                                        {item.name}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    {/* 右边 */}
                                    <div className={`delete-option-wrapper  ${this.has_fixed_list.includes(this.state.dataList.question_type) ? "isHide" : ""}`}>
                                        <img src={cur_icon_delete} className='delete-option-icon' alt="" onClick={this.handleDeleteOption.bind(this, index)} />
                                        {
                                            <div className='option-active-wrapper' onClick={this.handleSetOption.bind(this, index)}>
                                                {
                                                    <div>设置</div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className={`info-foot  ${this.has_fixed_list.includes(this.state.dataList.question_type) ? "isHide" : ""}`} onClick={this.handleAddOption.bind(this)} >
                    <img src={cur_icon_add} className='add-option-icon' alt="" />
                    <div>添加选项</div>
                </div>
            </div >
        )
    }
}
