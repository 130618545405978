
import React, { Component } from 'react'
import './Upload_Popup.css';
import cur_icon_close_gre from '../../../images/cur_icon_close_gre.png'


class Upload_Popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name:'Upload_Popup',
            isShow:false
        }

    }


    componentDidMount() {
        this.setState({
            isShow:this.props.isShow
        })
        // console.log()
    }

    Close_Popup(string){
        if(string=='confirm'){
            this.setState({
                isShow:false
            })
            this.props.Get_Is_Show_Status({isShow:false,is_delete:true})
        }else{
            this.setState({
                isShow:false
            })
            this.props.Get_Is_Show_Status({isShow:false,is_delete:false})
        }

    }



    render() {
        return (
            <div className='Upload_Popup_contain'>
               <div className='Upload_Popup_bg'>
                   <div className='Upload_Popup_main'>
                       <div className='Upload_Popup_title'>
                           <span>确定删除图片？</span>
                           <img src={cur_icon_close_gre} onClick={this.Close_Popup.bind(this)}/>
                       </div>
                       <div className='content'>图片删除后无法再编辑和查看</div>
                       <div className='Upload_Popup_btn'>
                           <span onClick={this.Close_Popup.bind(this)}>取消</span>
                           <span onClick={this.Close_Popup.bind(this,'confirm')}>确认</span>
                       </div>
                   </div>
               </div>
            </div>
        )
    }
}



export default Upload_Popup;