import React, { Component } from 'react'
import './xm_tip.scss';
import cur_icon_close_gre from '../../images/cur_icon_close_gre.png'

export default class xm_tip extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    handleClose() {
        this.props.canShowTipPopup({ showTipPopup: false, confirmed: false })
    }
    handleConfirm() {
        this.props.canShowTipPopup({ showTipPopup: false, confirmed: true })
    }
    render() {
        return (
            <div className='product-tip-popup'>
                <div className='product-tip-popup-container'>
                    <div className='product-tip-popup-top'>
                        <div className='popup-top-title'>提示</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} className='cursor' onClick={this.handleClose.bind(this)} alt="" />
                        </div>
                    </div>
                    <div className='popup-content'>
                        {this.props.tip_popup_msg}
                    </div>
                    <div className='tag-btn'>
                        <div onClick={this.handleConfirm.bind(this)}>确认</div>
                        <div onClick={this.handleClose.bind(this)}>取消</div>
                    </div>
                </div>
            </div>
        )
    }
}
