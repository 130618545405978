import React, { Component } from 'react'
import { privacy_agreement_data } from './privacy_agreement_data'
import './agreement.scss'

export default class privacy_agreement extends Component {
    render() {
        return (
            <div className="agreement-container">
                <div dangerouslySetInnerHTML={{ __html: privacy_agreement_data }}></div>
            </div>

        )
    }
}
