import React, { Component } from 'react'
import './download_pic.scss'
import cur_icon_close_gre from '../../../images/cur_icon_close_gre.png'
import { getQRCode } from '../../../api/Project/project_list_api'
import { downloadPic } from '../../../util/utils'

export default class download_pic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : 23,   //品牌ID
            // project_id: localStorage.getItem('project_id') ? localStorage.getItem('project_id') : '',
            image_url: "",
            qr_project_id: "",
            qr_project_name: "",
            needRefresh: false,
        }
    }
    componentDidMount() {
        this._isMounted = true
    }
    componentDidUpdate() {
        if (this.state.needRefresh && this._isMounted && this.state.qr_project_id) {
            this.getQRCodes()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnMount = () => {
        this._isMounted = false;
        this.setState = (state, callback) => {
            return;
        };

    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.qr_project_id) !== JSON.stringify(state.qr_project_id_prev)) {
            return {
                needRefresh: true,
                qr_project_id: props.qr_project_id,
                qr_project_id_prev: props.qr_project_id,
                qr_project_name: `{${props.qr_project_name}}_${props.qr_project_id}.jpg`
            };
        }
        return null;
    }
    //获取二维码
    async getQRCodes() {
        let project_id = this.state.qr_project_id
        let filename = this.state.qr_project_name
        try {
            const response = await getQRCode({
                special_event_id: project_id,
                filename: filename
            })
            this.setState({
                image_url: response.data.url
                // image_url: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202003%2F25%2F20200325161434_XPRRd.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653375368&t=d794b43d8f52345f908665c86d6b4f33"
            })
            return response;
        } catch (error) {
            throw error;
        }
    }
    //关闭二维码对话框
    handleClose() {
        this.props.handleShowQrcode()
    }
    //下载二维码
    downloadQrcode() {
        downloadPic(this.state.image_url, "二维码")
    }
    render() {
        return (
            <div className="download-pic-container">
                <div className="download-pic-main">
                    <div className='download-pic-top'>
                        <div className='download-pic-top-title'>下载二维码</div>
                        <img src={cur_icon_close_gre} onClick={this.handleClose.bind(this)} className='download-pic-top-close' alt="" />
                    </div>
                    <div className='download-pic-body'>
                        <img src={this.state.image_url} alt="" />
                    </div>
                    <div className='download-pic-foot'>
                        <div className='download-btn' onClick={this.downloadQrcode.bind(this)}>下载</div>
                    </div>
                </div>
            </div>
        )
    }
}
