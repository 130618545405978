import { useEffect, createRef, useState } from "react"
import Question_item from "./components/question_item";
import Loading from "../../components/Loading/loading";

import util from '../../util/util'
import './contrast_survey.scss'
import project_icon_copy_active from '../../images/project_icon_copy_active.png'
import project_icon_del from '../../images/project_icon_del.png'
import overall_icon_add from '../../images/overall_data/overall_icon_add.png'
import clear_icon from '../../images/overall_data/clear_icon.png'

import { message, Select, Checkbox, Table, Tooltip } from 'antd';
import { getBrandSurvey, getPresetCommentSimple, getSurveyAnalysisSimple } from '../../api/panelApi'
import { getBrand } from "../../api/Home_page/home_page_api";
import UserAPI from '../../api/UserAPI';
import { getProjectProgress } from '../../api/progressApi/progress'

export default function Contrast() {
  const [list, setList] = useState([{ i: 0, survey: [] }]);
  const [left, setLeft] = useState(0);
  const [path, setPath] = useState('');
  const [selectList, setSelectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [goodList, setGoodList] = useState([]);

  const [checkObj, setCheckObj] = useState({});
  const [tableOrigin, setTableOrigin] = useState([]);
  const [tableList, setTableList] = useState([]);

  const scorllBox = createRef();

  let timer = null;

  useEffect(() => {
    let pathname = window.location.pathname;
    if (pathname == '/contrast_survey') {
      setList([{ i: 0, survey: [] }]);
      setCheckObj({});
      setTableOrigin([]);
      setTableList([]);
      setPath('survey');
      getSpeEve();
    } else {
      setList([{ i: 0, survey: [] }]);
      setCheckObj({});
      setTableOrigin([]);
      setTableList([]);
      setPath('product');
      getGoods();
    }

    let E = scorllBox.current;
    E.addEventListener('scroll', (e) => {
      setLeft(e.target.scrollLeft);
    })
  }, [window.location.pathname])

  useEffect(() => {
    saveData();
  }, [list, tableList, goodList])

  // 增加对比问卷
  const addContrastQuestion = (i) => {
    let a = [...list];
    a.splice(i + 1, 0, { i: a.length, survey: [] })
    setList(a);
  }
  // 删除对比问卷
  const delContrastQuestion = (i) => {
    let a = [...list];
    if (a.length === 1) {
      setList([{ i: 0, survey: [] }])
    } else {
      a.splice(i, 1);
      setList(a);
    }
  }

  /**
   * 调研问卷
   */
  // 获取调研列表
  const getSpeEve = () => {
    let list = [];
    let brand_id = localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : ""
    getBrandSurvey({ brand_id }).then(
      res => {
        list = res.data;
        for (let i in list) {
          list[i].label = list[i].name;
          list[i].value = list[i].id;
        }
        getSaveData();
        setSelectList(list);
      },
      err => {
        setSelectList([]);
        getSaveData();
      }
    )
  }
  // 选择调研
  const selectChange = (i, e) => {
    list[i].id = e;
    setList(list);
    let name = '';
    for (let n in selectList) {
      if (selectList[n].id == e) {
        name = selectList[n].name;
      }
    }
    getSpeSurver(i, name)
  }
  // 获取问卷统计结果
  const getSpeSurver = (i, name) => {
    let brand_id = localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : ""
    let param = {
      survey_id: list[i].id,
      brand_id: brand_id,
      product_id: "",
      age_list: [],
      region_list: [],
      gender_list: [],
      start_time: '',
      end_time: ''
    }
    setLoading(true);
    getSurveyAnalysisSimple(param).then(
      res => {
        setLoading(false);
        if (res?.data?.length) {
          let arr = JSON.parse(JSON.stringify(list));
          arr[i].survey = res.data;
          arr[i].i = Date.now();
          arr[i].name = name;
          setList(arr);
        }
      },
      err => {
        setLoading(false);
      }
    )
  }

  /**
   * 派送问卷
   */
  // 获取产品列表
  const getGoods = () => {
    getBrand({}).then(
      res => {
        let a = [];
        for (let i in res.data) {
          if (res.data[i].product_count) {
            res.data[i].label = res.data[i].name;
            res.data[i].value = res.data[i].id;
            a.push(res.data[i]);
          }
        }
        getSaveData();
        setGoodList(a);
      },
      err => {
        setGoodList([]);
        getSaveData();
      }
    )
  }
  // 获取项目列表
  const getProject = (i, e) => {
    let a = [...list];


    UserAPI.GetBrandProduct({ brand_id: e }).then(
      res => {
        for (let n in res.data) {
          res.data[n].label = res.data[n].title;
          res.data[n].value = res.data[n].id;
        }
        a[i].projectList = res.data;
        a[i].gid = e;
        a[i].id = '';
        a[i].survey = [];
        setList(a);
      },
      err => {
        message.error('没有项目');
        a[i].id = '';
        a[i].gid = e;
        a[i].projectList = [];
        a[i].survey = [];
        setList(a);
      }
    )
  }
  // 选择项目
  const selectProject = (i, e) => {
    let a = [...list];
    list[i].id = e;
    setList(list);
    let name = '';
    for (let n in list[i].projectList) {
      if (list[i].projectList[n].id == e) {

        name = list[i].projectList[n].title;
      }
    }
    console.log(name);
    let params = {
      product_id: e,
    }
    getProjectProgress(params).then(
      res => {
        for (let n in res.data) {
          res.data[n].label = res.data[n].project_name;
          res.data[n].value = res.data[n].survey_id;
        }
        a[i].surveyList = res.data;
        setList(a);
      }
    )


    getProSurvey(i, name);
  }

  const selectProjectSurvey = (i, e) => {


    list[i].surveyId = e;
    setList(list);
    let name = '';
    for (let n in list[i].surveyList) {
      if (list[i].surveyList[n].survey_id == e) {
        name = list[i].surveyList[n].project_name;
      }
    }
    getProSurvey(i, name);
  }
  // 获取问卷
  const getProSurvey = (i, name) => {
    let param = {
      product_id: list[i].id,
      survey_id: list[i].surveyId,
      age_list: [],
      region_list: [],
      gender_list: [],
      start_time: '',
      end_time: ''
    }
    setLoading(true);
    getPresetCommentSimple(param).then(
      res => {
        console.log(res);
        setLoading(false);

        if (res?.status == 204) {
          // 如果结果返回为204 的情况清空当前问卷
          let arr = JSON.parse(JSON.stringify(list));
          arr[i].survey = [];
          arr[i].i = Date.now();
          arr[i].name = name;
          setList(arr);
          message.warning('暂无数据', 2, () => { });
          return;
        }

        if (res?.data?.length) {
          let arr = JSON.parse(JSON.stringify(list));
          arr[i].survey = res.data;
          arr[i].i = Date.now();
          arr[i].name = name;
          setList(arr);
        }

      },
      err => {
        setLoading(false);
      }
    )
  }

  /**
   * 对比表格
   */


  // 新增对比表格
  const addNewTable = () => {
    let a = [...tableList];
    a.push({});
    setTableList(a);
  }
  // 删除对比表格
  const delTable = (index) => {
    let a = [...tableList];
    a.splice(index, 1);
    setTableList(a);
  }
  // 选择题目
  const checkChange = (id, i, pid) => {
    let o = { ...checkObj };
    o[`${id}-${i}`] = pid;
    setCheckObj(o);
  }
  // 确认选择的题目
  const sureCheck = (index) => {
    let a = [];
    let o = { ...checkObj };
    let arr = list;
    for (let i in arr) {
      if (o[`${arr[i].id}-${i}`]) {
        for (let n in arr[i].survey) {
          if (arr[i].survey[n].question_id == o[`${arr[i].id}-${i}`]) {
            arr[i].survey[n].surveyName = arr[i].name;
            a.push(arr[i].survey[n])
          }
        }
      }
    }
    if (a.length) {
      let aa = [...tableOrigin];
      aa[index] = a;
      console.log(aa);
      setTableOrigin(aa);
      setCheckObj({});
      buildTableList([...aa]);
    }
  }
  // 生成对比表格数据
  const buildTableList = (origin) => {
    origin = JSON.parse(JSON.stringify(origin));
    let a = [];
    let zi = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i'];
    for (let i in origin) {
      let item = origin[i];
      a[i] = {};
      // 生成表格表头
      let tableConfig = [{
        title: item[0].name || item[0].chart_name,
        dataIndex: 'label0',
        key: 'label0',
        width: 200,
        fixed: 'left',
        onCell: (e, index) => {
          if (e.cell0) {
            return e.cell0;
          }
        }
      }, {
        title: '',
        dataIndex: 'label1',
        key: 'label1',
        fixed: 'left',
        width: 100,
        onCell: (e, index) => {
          if (e.cell1) {
            return e.cell1;
          }
        }
      }];
      for (let i in item) {
        tableConfig.push({
          title: `${item[i].surveyName}(${zi[i]})`,
          dataIndex: `label${Number(i) + 2}`,
          key: `label${Number(i) + 2}`,

          render: (e) => {
            if (e) {
              return e;
            } else {
              return 0;
            }
          }
        })
      }
      a[i].tableConfig = tableConfig;

      // 处理数据
      item = chuli(item);
      // 生成表格数据
      let tableData = [];
      let tableObj = {};
      let tableSort = [];
      for (let n in item) {
        if (item[n].tableData) {
          for (let k in item[n].tableData) {
            if (tableObj[`${item[n].tableData[k].name}`]) {
              tableObj[`${item[n].tableData[k].name}`][`label${Number(n) + 2}`] = item[n].tableData[k].value;
              tableObj[`${item[n].tableData[k].name}`][`orilabel${Number(n) + 2}`] = item[n].tableData[k].oriValue;
            } else {
              tableObj[`${item[n].tableData[k].name}`] = {
                label0: item[n].tableData[k].name,
                label1: '',
                [`label${Number(n) + 2}`]: item[n].tableData[k].value,
                [`orilabel${Number(n) + 2}`]: item[n].tableData[k].oriValue,
              }
            }
          }
        }

        if (item[n].data?.length) {
          for (let k in item[n].data) {
            if (tableObj[`${item[n].data[k].name0}-${item[n].data[k].name}`]) {
              tableObj[`${item[n].data[k].name0}-${item[n].data[k].name}`][`label${Number(n) + 2}`] = item[n].data[k].value;
              tableObj[`${item[n].data[k].name0}-${item[n].data[k].name}`][`orilabel${Number(n) + 2}`] = item[n].data[k].oriValue;
            } else {
              tableObj[`${item[n].data[k].name0}-${item[n].data[k].name}`] = {
                label0: item[n].data[k].name0,
                label1: item[n].data[k].name,
                [`label${Number(n) + 2}`]: item[n].data[k].value,
                [`orilabel${Number(n) + 2}`]: item[n].data[k].oriValue,
                cell0: item[n].data[k].cell0,
                cell1: item[n].data[k].cell1,
              }
            }
          }
        }
        tableSort.push(...item[n].tableSort);
      }
      tableSort = util.unique(tableSort);
      for (let k in tableSort) {
        tableData.push(tableObj[tableSort[k]])
      }

      a[i].tableSort = tableSort;
      a[i].tableObj = tableObj;
      a[i].tableData = tableData;
    }
    a = dataSig(a);
    setTableList(a);
  }
  // 处理对比表格数据
  const chuli = (data) => {
    for (let i in data) {
      if (data[i].question_type != 1 && data[i].question_type != 2 && data[i].question_type != 5 && data[i].question_type != 7 && data[i].question_type != 12 && data[i].question_type != 13 && data[i].question_type != 14 && data[i].question_type != 15) {
        data[i].data = [];
        data[i].tableData = [];
        message.error(`目前不支持:${data[i].name} 的题型对比展示`)
      }
      console.log(data[i]);
      if (data[i].question_type == 1 || data[i].question_type == 12 || data[i].question_type == 13) {
        let sort = [];
        let dataArr = [];
        for (let n in data[i].tableData) {
          sort.push(`${data[i].tableData[n].name}`)
        }
        for (let n in data[i].data) {
          let item = {
            name0: data[i].data[n].name,
            name: '',
            value: (data[i].data[n].value / data[i].sum * 100).toFixed(1) + '%',
            cell0: { colSpan: 2 },
            cell1: { colSpan: 0 },
            oriValue: data[i].sum,
          }
          sort.push(`${item.name0}-${item.name}`);
          dataArr.push(item);
        }
        data[i].data = dataArr;
        data[i].tableSort = sort;
        console.log(sort);
      }
      if (data[i].question_type == 2) {
        let sort = [];
        let dataArr = [];
        for (let n in data[i].tableData) {
          sort.push(`${data[i].tableData[n].name}`)
        }
        for (let n in data[i].x_axis) {
          let item = {
            name0: data[i].x_axis[n],
            name: '',
            value: (data[i].data[n] / data[i].sum * 100).toFixed(1) + '%',
            cell0: { colSpan: 2 },
            cell1: { colSpan: 0 },
            oriValue: data[i].sum,
          }
          sort.push(`${item.name0}-${item.name}`);
          dataArr.push(item);
        }
        data[i].data = dataArr;
        data[i].tableSort = sort;
      }
      if (data[i].question_type == 5 || data[i].question_type == 7) {
        let sort = [];
        let dataArr = [];
        let sum = 0;
        for (let n in data[i].data) {
          sum += data[i].data[n];
        }
        for (let n in data[i].tableData) {
          sort.push(`${data[i].tableData[n].name}`)
        }
        for (let n in data[i].x_axis) {
          let item = {
            name0: data[i].x_axis[n] + '分',
            name: '',
            value: (data[i].data[n] / sum * 100).toFixed(1) + '%',
            cell0: { colSpan: 2 },
            cell1: { colSpan: 0 },
            oriValue: sum,
          }
          sort.push(`${item.name0}-${item.name}`);
          dataArr.push(item);
        }
        data[i].data = dataArr;
        data[i].tableSort = sort;
      }
      if (data[i].question_type == 14 || data[i].question_type == 15) {
        let sort = [];
        let dataArr = [];
        for (let n in data[i].chart_list) {
          let num = 0;
          let a = [];
          for (let j in data[i].chart_list[n].value) {
            num += Number(data[i].chart_list[n].value[j]);
            a.unshift(Number(data[i].chart_list[n].value[j]));
          }

          let tmpsum = 0;
          let o = {};
          for (let k in a) {
            tmpsum += Number(a[k]);
            o[`top${Number(k) + 1}`] = (tmpsum / num * 100).toFixed(1);
          }

          let topX = [];
          if (a.length >= 7) {
            topX = ['top1', 'top3']
          } else if (a.length >= 5) {
            topX = ['top1', 'top2']
          } else {
            topX = ['top1']
          }
          for (let k in topX) {
            if (o[topX[k]]) {
              console.log(o[topX[k]]);
              let item = {
                name0: `${data[i].chart_list[n].option_name}`,
                name: topX[k],
                value: o[topX[k]] + '%',
                oriValue: num,
                cell0: {
                  rowSpan: k == 0 ?
                    data[i].chart_list[k].value.length + 1 + topX.length
                    : 0
                },
              }
              sort.push(`${item.name0}-${item.name}`);
              dataArr.push(item);
            }
          }


          for (let k in data[i].data_list) {
            if (data[i].chart_list[n].option_name == data[i].data_list[k].option_name) {
              data[i].data_list[k].sum = num;
              let item = {
                name0: `${data[i].data_list[k].option_name}`,
                name: 'mean',
                value: (data[i].data_list[k].value_mean).toFixed(2),
                cell0: { rowSpan: 0 },
                oriValue: data[i].data_list[k]
              }
              sort.push(`${item.name0}-${item.name}`);
              dataArr.push(item);
            }
          }
          for (let j in data[i].chart_list[n].x_axis) {
            let item = {
              name0: `${data[i].chart_list[n].option_name}`,
              name: `${data[i].chart_list[n].x_axis[j]}分`,
              value: (data[i].chart_list[n].value[j] / num * 100).toFixed(1) + '%',
              cell0: { rowSpan: 0 },
              oriValue: num,
            }
            sort.push(`${item.name0}-${item.name}`);
            dataArr.push(item);
          }
        }
        data[i].data = dataArr;
        data[i].tableSort = sort;
      }
    }
    return data;
  }

  // 数据sig比较
  const dataSig = (data) => {
    let zi = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i']
    for (let i in data) {
      let arr = data[i];
      console.log(arr);
      let num = arr.tableConfig.length - 2;
      if (num == 1) {
        continue;
      }
      for (let j in arr.tableData) {
        for (let n = 0; n < num; n++) {
          for (let k = 0; k < num; k++) {
            if (n == k) continue;
            if (!arr.tableData[j][`label${n + 2}`] || !arr.tableData[j][`label${k + 2}`]) {
              // console.log('没数据，跳过');
              continue;
            }
            // console.log(arr.tableData[j]);
            if (arr.tableData[j].label0 == 'Mean' || arr.tableData[j].label1 == 'mean') {
              if (!arr.tableData[j][`orilabel${n + 2}`] || !arr.tableData[j][`orilabel${k + 2}`]) {
                // console.log('没平均值，跳过');
                continue;
              }
              let n1 = arr.tableData[j][`orilabel${n + 2}`].sum;
              let m1 = arr.tableData[j][`label${n + 2}`];
              let std1 = arr.tableData[j][`orilabel${n + 2}`].value_std;
              let n2 = arr.tableData[j][`orilabel${k + 2}`].sum;
              let m2 = arr.tableData[j][`label${k + 2}`];
              let std2 = arr.tableData[j][`orilabel${k + 2}`].value_std;

              let f = calculateMeanSignificance(n1, m1, std1, n2, m2, std2, 0.95);

              if (f) {
                if (m1 > m2) {
                  arr.tableData[j][`label${n + 2}`] += zi[k];
                } else {
                  arr.tableData[j][`label${k + 2}`] += zi[n];
                }
              }
            } else {
              let a_percentage = arr.tableData[j][`label${n + 2}`];
              let b_percentage = arr.tableData[j][`label${k + 2}`];
              let a_size = arr.tableData[j][`orilabel${n + 2}`];
              let b_size = arr.tableData[j][`orilabel${k + 2}`];
              a_percentage = Number(a_percentage.replace(/[\%a-z]/g, '')) / 100;
              b_percentage = Number(b_percentage.replace(/[\%a-z]/g, '')) / 100;

              let f = calculatePercentageSignificance(a_percentage, b_percentage, a_size, b_size, 0.95);
              if (f) {
                if (a_percentage > b_percentage) {
                  arr.tableData[j][`label${n + 2}`] += zi[k];
                } else {
                  arr.tableData[j][`label${k + 2}`] += zi[n];
                }
              }
            }
          }
        }

      }
    }

    return data;
  }

  // 百分比sign判断
  const calculatePercentageSignificance = (a_percentage, b_percentage, a_size, b_size, confidence_level = 0.95) => {
    /*
    Used to calculate the significance between two groups after conducting a proportion statistical analysis on the same dimension.
    To determine the significance between proportions, we need the actual sizes of the two groups and the respective percentage values.
    Finally, when calculating the confidence interval, we use the value 1.96 for a 95% confidence level. 
    For a 90% confidence interval, we would use the value 1.64.
    Returns true if there is a significant difference where a is higher than b, false otherwise.
    */

    let val = 1.96;
    if (confidence_level == 0.95) {
      // 1.96 is the value for a 95% confidence interval
      val = 1.96;
    }

    const difference = a_percentage - b_percentage;

    // Calculate the standard error
    const standard_error = Math.sqrt((a_percentage * (1 - a_percentage) / a_size) + (b_percentage * (1 - b_percentage) / b_size));

    // Calculate the margin of error
    const margin_of_error = standard_error * val;

    // Calculate the lower and upper bounds of the confidence interval
    const confidence_interval_lower = difference - margin_of_error;
    const confidence_interval_upper = difference + margin_of_error;

    if (confidence_interval_lower > 0) {
      // If the lower bound of the confidence interval is greater than 0, a is significantly higher than b
      return true;
    } else {
      return false;
    }
  }
  // mean sign判断
  const calculateMeanSignificance = (N1, M1, STD1, N2, M2, STD2, CL = 0.95) => {
    let val = 1.96;
    if (CL == 0.95) {
      val = 1.96;
    }
    let SE1 = STD1 / Math.sqrt(N1);
    let SE2 = STD2 / Math.sqrt(N2);
    let SE = Math.sqrt(SE1 * SE1 + SE2 * SE2);
    let Z = (M1 - M2) / SE;
    if (Z > val) {
      return true;
    } else {
      return false;
    }
  }

  // 存储缓存
  const saveData = () => {
    let pathname = window.location.pathname;
    setTimeout(() => {
      if (list[0]?.survey?.length) {
        util.localSetItem(`contrast_list_${pathname}`, list);
        util.localSetItem(`contrast_goodList_${pathname}`, goodList);
        util.localSetItem(`contrast_tableList_${pathname}`, tableList);
      }
    }, 1000);
  }
  // 使用缓存
  const getSaveData = () => {
    let pathname = window.location.pathname;
    let data1 = util.localGetItem(`contrast_list_${pathname}`);
    data1 && setList(data1);
    let data3 = util.localGetItem(`contrast_goodList_${pathname}`);
    data3 && setGoodList(data3);
    let data4 = util.localGetItem(`contrast_tableList_${pathname}`);
    data4 && setTableList(data4);
  }

  return (
    <div className="container contrast_container">
      {loading ? <Loading /> : ''}
      <div className="contrast_main">
        <div className="contrast_main_title">多问卷对比分析</div>
        <div className="contrast_main_content" >
          <div className="main_select_box">
            <div className="select_s" style={{ left: -left, width: `${list.length * 33.3333}%`, minWidth: `${list.length * 380}px` }}>
              {list.map((item, index) => {
                return <>
                  <div className="select_item" style={{ width: `${100 / list.length}%` }} key={item.i}>
                    {path === 'survey' ?
                      <>
                        <div className="select1">
                          <Select showSearch value={item.id} options={selectList} onChange={(e) => selectChange(index, e)} filterOption={(i, o) => (o?.label ?? '').toLowerCase().includes(i.toLowerCase())} />
                        </div>
                      </>
                      : <>
                        <div className="select2">
                          <Select showSearch value={item.gid} options={goodList} onChange={(e) => getProject(index, e)} filterOption={(i, o) => (o?.label ?? '').toLowerCase().includes(i.toLowerCase())} />
                        </div>
                        <div className="select2">
                          <Select showSearch value={item.id} options={item.projectList} onChange={(e) => selectProject(index, e)} filterOption={(i, o) => (o?.label ?? '').toLowerCase().includes(i.toLowerCase())} />
                        </div>
                        <div className="select2">
                          <Select showSearch value={item.surveyId} options={item.surveyList} onChange={(e) => selectProjectSurvey(index, e)} filterOption={(i, o) => (o?.label ?? '').toLowerCase().includes(i.toLowerCase())} />
                        </div>
                      </>
                    }
                    <div className="icon_box">
                      <img onClick={() => addContrastQuestion(index)} src={project_icon_copy_active} />
                      <img onClick={() => delContrastQuestion(index)} src={project_icon_del} />
                    </div>
                  </div>
                </>
              })}
            </div>
          </div>
          <div className="main_container" ref={scorllBox}>
            <div className="main_table_box" style={{ left: left }}>
              {tableList.map((table, index) => {
                return <>
                  <div className="table_box" key={index}>
                    <div className="table_btn_box">
                      <Tooltip placement="top" title="选择题目">
                        <div className="search-wrapper" onClick={() => sureCheck(index)}>
                          <img src={overall_icon_add} />
                        </div>
                      </Tooltip>
                      <Tooltip placement="top" title="删除表格">
                        <div className="search-wrapper" onClick={() => delTable(index)}>
                          <img src={clear_icon} />
                        </div>
                      </Tooltip>
                    </div>
                    <Table dataSource={table.tableData} columns={table.tableConfig} pagination={false} scroll={{ y: 400 }} />
                  </div>
                </>
              })}
              <div className="theme-add-wrapper" onClick={addNewTable}>新增对比表格</div>
            </div>
            <div className="main_survey_box clearfix" style={{ width: `${list.length * 33.3333}%`, minWidth: `${list.length * 380}px` }}>
              {list.map((item, index) => {
                return <>
                  <div className="survey_item" style={{ width: 100 / list.length + '%' }} key={item.i}>

                    {item.survey.map((sur, surIndex) => {
                      return <>
                        <div className="item">
                          <div className="item-title">
                            {sur.question_type == 1 || sur.question_type == 2 || sur.question_type == 5 || sur.question_type == 7 || sur.question_type == 12 || sur.question_type == 13 || sur.question_type == 14 || sur.question_type == 15 ?
                              <Checkbox checked={checkObj[`${item.id}-${index}`] == sur.question_id} onChange={() => checkChange(item.id, index, sur.question_id)} style={{ marginRight: 10 }} />
                              : ''
                            }

                            {sur.name || sur.chart_name}
                          </div>
                          <Question_item item={sur} key={surIndex} />
                        </div>
                      </>
                    })}
                  </div>
                </>
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}