import React, { Component } from "react";
import "./question_edit_popup.scss";
import cur_icon_close_gre from "../../../../../images/cur_icon_close_gre.png";
import XmEditor from "../../../../Xm_editor/xm_editor";

export default class question_edit_popup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question_name: "",
      question_name_prev: "",
      currentQuestionIndex: "",
    };
  }
  componentDidMount() {
    this.setState({
      question_name: this.props.question_name,
      question_name_prev: this.props.question_name,
      currentQuestionIndex: this.props.currentQuestionIndex,
    });
  }
  // 关弹窗
  tags_close() {
    this.props.handleQuestionEditPopupStatus({
      question_name: this.state.question_name_prev,
      showQuestionEditPopup: false,
    });
  }
  // 确认弹窗
  tags_confirm() {
    this.props.handleQuestionEditPopupStatus({
      question_name: this.state.question_name,
      showQuestionEditPopup: false,
    });
  }
  handleEditorChange(val) {
    this.setState({
      question_name: val,
    });
  }
  render() {
    return (
      <div className="edit-question-popup">
        <div className="edit-question-popup-contain">
          <div className="edit-question-popup-top">
            <div className="popup-top-title">富文本编辑</div>
            <div className="popup-delete-icon">
              <img
                src={cur_icon_close_gre}
                className="cursor"
                onClick={this.tags_close.bind(this)}
                alt=""
              />
            </div>
          </div>
          <div className="edit-question-popup-content">
            <div className="edit-question-tip">请编辑题目</div>
            <div className="question-name-editor">
              <XmEditor
                html={this.state.question_name}
                handleEditorChange={this.handleEditorChange.bind(this)}
              ></XmEditor>
            </div>
          </div>
          <div className="tag-btn">
            <div onClick={this.tags_confirm.bind(this)}>确认</div>
            <div onClick={this.tags_close.bind(this)}>取消</div>
          </div>
        </div>
      </div>
    );
  }
}
