import React, { Component } from 'react'
import { Table, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import "./matrix_nps_list.scss"
export default class matrix_nps_list extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chart_list: [],
            dataList: {},
            needRefresh: false,
            columns: [
                {
                    title: '分类名称',
                    dataIndex: 'name',
                    key: 'name',
                    // width: 30,
                    // fixed: 'left',
                },
                {
                    title: '均值',
                    dataIndex: 'value_mean',
                    key: 'value_mean',
                    // width: 30,
                    // fixed: 'left',
                },
                {
                    title: '标准差',
                    dataIndex: 'value_std',
                    key: 'value_std',
                    // width: 20,
                },
                {
                    title: '中位数',
                    dataIndex: 'value_median',
                    key: 'value_median',
                    // width: 30,
                },
            ]
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {

            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    toInitChart() {
        let chart_list = []
        for (let i = 0; i < this.props.dataList.data_list.length; i++) {
            let obj = {
                name: this.props.dataList.data_list[i].option_name,
                value_std: this.props.dataList.data_list[i].value_std || 0,
                value_mean: this.props.dataList.data_list[i].value_mean || 0,
                value_median: this.props.dataList.data_list[i].value_median || 0,
                id: "chart_list_" + i
            }
            chart_list.push(obj)
        }
        this.setState({
            chart_list: chart_list,
        })
    }
    showPagination() {
        if (this.state.chart_list.length < 30) {
            return false
        }
        return { pageSize: 30, showSizeChanger: false, showQuickJumper: true, size: "small" }
    }
    render() {
        return (
            <div className="chart-list-container">
                <ConfigProvider locale={zhCN}>
                    <Table
                        dataSource={this.state.chart_list}
                        columns={this.state.columns}
                        pagination={this.showPagination()}
                        rowKey={columns => columns.id}
                        scroll={{ y: this.props.isMaxChart ? this.props.blankListHeight : 275 }} />
                </ConfigProvider>
            </div>
        )
    }
}
