import React, { Component } from 'react'
import { questionType, getQuestionTypeValue, getQuestionType, getQuestionTypeName } from "../../../Question_rules/question_type_rule"
import XmSelect from '../../../../../Select/xm_select'

export default class matrix_blank_condition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "matrix_blank_condition",
            condition_judge_list: [{ value: "包含", key: 6 }, { value: "不包含", key: 7 }, { value: "小于", key: 4 }, { value: "大于", key: 3 }, { value: "与人群显示冲突", key: 8 }],//如果第2框 单选多选
            condition_settings_index: "",
            logic_settings_index: "",
            condition_settings_item: { judge: "", option: { value: "", answer_id: "" } },
            condition_option_list: [], //如果第3框
            current_question: {},//当前的question
            json_value: "",
            json_option_id: ""
        }
    }
    componentDidMount() {
        this._isMounted = true
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.condition_settings_index) !== JSON.stringify(state.condition_settings_index_prev) || JSON.stringify(props.condition_settings_item) !== JSON.stringify(state.condition_settings_item_prev) || JSON.stringify(props.logic_settings_index) !== JSON.stringify(state.logic_settings_index_prev)) {
            return {
                needRefresh: true,
                condition_settings_index_prev: props.condition_settings_index,
                condition_settings_item_prev: props.condition_settings_item,
                condition_settings_index: props.condition_settings_index,
                condition_settings_item: props.condition_settings_item,
                logic_settings_index: props.logic_settings_index,
                current_question: props.current_question || {},
                json_value: props.condition_settings_item.option.value ? JSON.parse(props.condition_settings_item.option.value).value : "",
                json_option_id: props.condition_settings_item.option.value ? JSON.parse(props.condition_settings_item.option.value).optionId2 : "",
            }
        }
        return null;
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    // 如果第2k
    handleConditionJudge(judge, index) {
        return this.state.condition_judge_list
    }
    // 如果第2k
    handleDefaultConditionJudge(judge, index) {
        if (judge === "") {
            return null
        }
        return judge
    }
    // 如果第2个
    handleConditionJudgeChange(key, index2, index1) {
        this.props.handleConditionJudgeChange(key, index2, index1)
    }
    //验证数字
    validateNumber(event) {
        event.target.value = event.target.value.replace(/^0[0-9]+/g, 0);
        event.target.value = event.target.value.replace(/[^/.\d]/g, "");
        event.target.value = event.target.value.replace(".", "");
    }
    // 如果第3k
    handleConditionOption(judge, index) {
        let condition_option_list = JSON.parse(JSON.stringify(this.state.condition_option_list))
        let question_answers = this.state.current_question.answers
        // let cnt = 0
        for (let i = 0; i < question_answers.length; i++) {
            if (question_answers[i].option_position === "left") {
                condition_option_list[i] = { value: "", key: "" }
                condition_option_list[i].value = ""
                condition_option_list[i].key = this.state.current_question.answers[i].option_id || i
                condition_option_list[i].show_image = false
                if (this.state.current_question.answers[i].name) {
                    condition_option_list[i].value = this.state.current_question.answers[i].name
                } else if (this.state.current_question.answers[i].image.val) {
                    condition_option_list[i].value = this.state.current_question.answers[i].image.val
                    condition_option_list[i].show_image = true
                } else {
                    condition_option_list[i].value = (i + 1)
                }
                // cnt = cnt + 1
            }
        }
        return condition_option_list
    }
    // 如果第3k
    handleConditionOption2(judge, index) {
        let condition_option_list = JSON.parse(JSON.stringify(this.state.condition_option_list))
        let question_answers = this.state.current_question.answers
        // let cnt = 0
        for (let i = 0; i < question_answers.length; i++) {
            if (question_answers[i].option_position === "up") {
                condition_option_list[i] = { value: "", key: "" }
                condition_option_list[i].value = ""
                condition_option_list[i].key = this.state.current_question.answers[i].option_id || i
                condition_option_list[i].show_image = false
                if (this.state.current_question.answers[i].name) {
                    condition_option_list[i].value = this.state.current_question.answers[i].name
                } else if (this.state.current_question.answers[i].image.val) {
                    condition_option_list[i].value = this.state.current_question.answers[i].image.val
                    condition_option_list[i].show_image = true
                } else {
                    condition_option_list[i].value = (i + 1)
                }
                // cnt = cnt + 1
            }
        }
        return condition_option_list
    }
    // 如果第3k
    handleDefaultConditionOption(answer_id, index) {
        // if (answer_id === -1) {
        if (answer_id === "") {
            return null
        }
        return answer_id
    }
    // 如果第3k
    handleDefaultConditionOption2(value, index) {
        // if (answer_id === -1) {
        if (value === "") {
            return null
        }
        return value
    }
    // 如果第3个
    handleConditionOptionChange(key, index2, index1) {
        //key answer_id
        this.props.handleConditionOptionChange(key, index2, index1)
    }
    // 如果第3个
    handleConditionJsonOption(key, index2, index1) {
        //key answer_id
        let json_obj = JSON.stringify({ optionId2: key, value: this.state.json_value })
        this.setState({
            json_option_id: key
        })
        this.handleConditionOptionChange2(json_obj, index2, index1)

    }
    //  如果json
    handleConditionOptionChange2(json_obj, index2, index1) {
        this.props.handleConditionOptionChange2(json_obj, index2, index1)
    }
    // 填空是否有字
    handle_blank_word(judge, index2, index1) {
        if (judge >= 2) {
            return true
        }
        return false
    }
    // 如果第3个 input
    handleConditionInputChange(index2, index1, event) {
        let json_obj = JSON.stringify({ optionId2: this.state.json_option_id, value: event.target.value })
        this.setState({
            json_value: event.target.value
        })
        this.props.handleConditionOptionChange2(json_obj, index2, index1)
    }
    handleDefaultConditionInput(value, index) {
        // if (value === -1) {
        if (value === "") {
            return null
        }
        return value
    }
    render() {
        return (
            <>
                {
                    String(this.state.condition_settings_item.judge) !== "8" ? <>
                        <div className='logic_rule_select condition_select_second'>
                            {/* 如果2 */}
                            <div className="matrix-select">
                                <XmSelect
                                    options={this.handleConditionOption.call(this, this.state.condition_settings_item.option.answer_id, this.state.condition_settings_index)}
                                    key={this.state.condition_settings_item.option.answer_id}
                                    defaultValue={this.handleDefaultConditionOption.call(this, this.state.condition_settings_item.option.answer_id, this.state.condition_settings_index)}
                                    onChange={(e) => { this.handleConditionOptionChange.call(this, e, this.state.condition_settings_index, this.state.logic_settings_index) }}
                                    placeholder="请选择行">
                                </XmSelect>
                            </div>
                        </div>
                        <div className='logic_rule_select condition_select_second'>
                            {/* 如果2 */}
                            <div className="matrix-select">
                                <XmSelect
                                    options={this.handleConditionOption2.call(this, this.state.json_option_id, this.state.condition_settings_index)}
                                    key={this.state.json_option_id}
                                    defaultValue={this.handleDefaultConditionOption2.call(this, this.state.json_option_id, this.state.condition_settings_index)}
                                    onChange={(e) => { this.handleConditionJsonOption.call(this, e, this.state.condition_settings_index, this.state.logic_settings_index) }}
                                    placeholder="请选择列">
                                </XmSelect>
                            </div>
                        </div>
                    </> : ""
                }

                <div className='logic_rule_select condition_select_third'>
                    {/* 矩阵 judge */}
                    <div className="matrix-select">
                        {/* 如果3 */}
                        <XmSelect options={this.handleConditionJudge.call(this, this.state.condition_settings_item.judge, this.state.condition_settings_index)}
                            value={this.handleDefaultConditionJudge.call(this, this.state.condition_settings_item.judge, this.state.condition_settings_index)}
                            key={this.state.condition_settings_item.judge}
                            onChange={(e) => { this.handleConditionJudgeChange.call(this, e, this.state.condition_settings_index, this.state.logic_settings_index) }}
                            placeholder="请选择">
                        </XmSelect>
                    </div>
                </div>
                {
                    String(this.state.condition_settings_item.judge) !== "8" ? <>
                        <div className='logic_rule_select condition_select_fouth'>
                            {/* 矩阵 judge */}
                            {/* 如果4 */}
                            <div className="condition-option">
                                <span className={this.handle_blank_word.call(this, this.state.condition_settings_item.judge, this.state.condition_settings_index, this.state.logic_settings_index) ? "" : "isHide"}>
                                    <input placeholder='请输入' className='condition-input' type='text' maxLength="3" onBlur={this.handleConditionInputChange.bind(this, this.state.condition_settings_index, this.state.logic_settings_index)} onInput={this.validateNumber.bind(this)} defaultValue={this.handleDefaultConditionInput.call(this, this.state.json_value, this.state.condition_settings_index)} key={this.state.json_value} />
                                </span>
                                <span className={this.handle_blank_word.call(this, this.state.condition_settings_item.judge, this.state.condition_settings_index, this.state.logic_settings_index) ? "isHide" : ""}>
                                    <input placeholder='请输入' className='condition-input' type='text' onBlur={this.handleConditionInputChange.bind(this, this.state.condition_settings_index, this.state.logic_settings_index)} defaultValue={this.handleDefaultConditionInput.call(this, this.state.json_value, this.state.condition_settings_index)} key={this.state.json_value} />
                                </span>
                                <span className={this.handle_blank_word.call(this, this.state.condition_settings_item.judge, this.state.condition_settings_index, this.state.logic_settings_index) ? "blank-word" : "isHide"}>字</span>
                            </div>
                        </div>
                    </> : ""
                }
            </>
        )
    }
}
