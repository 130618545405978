import cur_icon_open from "../../images/emotion_analysis/cur_icon_open.png";
import cur_icon_close from "../../images/emotion_analysis/cur_icon_close.png";
import React, { Component } from "react";
import { Menu, Dropdown, Tabs, message } from "antd";
import "./Emotion_analysis.scss";
import Evaluation from "./Evaluation/Evaluation";
import EvaluationDetail from "./Evaluation/Evaluation_detail";
import WordCloudPanel from "./Word_cloud/Word_cloud_panel";
import AiCopywriting from "./AI_copywriting/ai_copywriting";
import {
  getWordcloud,
  getComment,
  getCommentSelection,
  getCommentDetail,
  getRegionList,
  saveXhsIncentive,
  updateXhsIncentive,
} from "../../api/panelApi";
import EmotionChart from "./Emotion_chart";
import ProductDropdown from "../Cem_product_dropdown/product_dropdown";
import Loading from "../../components/Loading/loading";

const { TabPane } = Tabs;
class Emotion_analysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // product_id: localStorage.getItem('product_id') ? localStorage.getItem('product_id') : 272,    //产品ID
      brand_id: localStorage.getItem("brand_id")
        ? localStorage.getItem("brand_id")
        : "23", //品牌ID
      product_id_cem: "", //产品ID
      survey_id: "", //调研ID
      product_list: [], //名称
      product_val: "",
      product_title: "", //产品名称
      tab_key: "1",
      wordCloudList: [],
      isProductChange: false,
      sentiment_list: [],
      frequent_word_list: [],
      comment_result_sum: 0, //评论列表的总数
      comment_list: [], //评论列表
      comment_list_formal: [],
      commentator_detail: {}, //评论人详情
      evaluation_level_list: [],
      evaluation_word_list: [],
      search_text: "",
      user_age_list: {},
      user_occupation_list: {},
      emotion_list: [], //nlp 三个图
      canShowDetail: false,
      comment_details: {}, //答卷详情
      loading: 0,
      loading_wordcloud: 0,
      loading_comment: 0,
      loading_comment_selection: 0,
      selected_region_list: [], //filter_select
      selected_gender_list: [], //filter_select
      selected_age_list: [], //filter_select
      start_time: "",
      end_time: "",
      filter_start_date: "", //筛选时间框的开始时间
      filter_end_date: "", //筛选时间框的结束时间
    };
  }
  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      // this.initPanel();// 初始化页面
    }
    this.init();
  }
  componentWillUnMount = () => {
    this._isMounted = false;
    this.setState = () => { };
  };
  // 初始化
  init() { }
  // 获得词云的数据
  async getWordclouds() {
    this.setState({
      loading_wordcloud: 1,
    });
    let product_id = this.state.product_id_cem;
    getWordcloud({
      product_id: product_id,
      survey_id: this.state.survey_id,
      search_text: this.state.search_text,
      age_list: this.state.selected_age_list,
      region_list: this.state.selected_region_list,
      gender_list: this.state.selected_gender_list,
      start_time: this.state.start_time,
      end_time: this.state.end_time,
    })
      .then((response) => {
        if (response.status === 204) {
          message.warning("暂未词云数据", 2, () => { });
          this.setState({
            wordCloudList: [],
            loading_wordcloud: 0,
          });
        } else {
          this.setState({
            wordCloudList: response.data.reverse(),
            loading_wordcloud: 0,
          });
        }
        return response;
      })
      .catch((err) => {
        this.setState({
          loading_wordcloud: 0,
        });
        throw err;
      });
  }
  // 获取评价表
  async getComments() {
    console.log(this.state);
    let product_id = this.state.product_id_cem;
    let survey_id = this.state.survey_id;

    let sentiment = [];
    this.state.evaluation_level_list.forEach((item, index) => {
      if (item.checked) {
        sentiment.push(item.id);
      }
    });
    let frequent_word_list = [];
    this.state.evaluation_word_list.forEach((item, index) => {
      if (item.checked) {
        frequent_word_list.push(item.val);
      }
    });
    this.setState({
      loading_comment: 1,
    });
    getComment({
      sentiment: sentiment,
      frequent_word: frequent_word_list,
      search_text: this.state.search_text,
      index: 1,
      product_id: product_id,
      survey_id: survey_id,
      age_list: this.state.selected_age_list,
      region_list: this.state.selected_region_list,
      gender_list: this.state.selected_gender_list,
      start_time: this.state.start_time,
      end_time: this.state.end_time,
    })
      .then((response) => {
        if (response.status === 204) {
          this.setState({
            comment_result_sum: 0,
            comment_list: [],
            comment_list_formal: [],
            emotion_list: [],
            filter_start_date: "",
            filter_end_date: "",
            loading_comment: 0,
          });
          message.warning("暂未有数据", 2, () => { });
        } else {
          let comment_result_sum = response.data.sum;
          let comment_list = response.data.comment_list;
          for (let i = 0; i < comment_list.length; i++) {
            comment_list[i]["check"] = 0;
            comment_list[i]["translate_status"] = 0;
            comment_list[i]["language_id"] = null;
            comment_list[i]["comment_index"] = i;
            comment_list[i]["retranslate"] = 0; // 0：非重新翻译 1 为 重新翻译
            comment_list[i]["translate"] = 0; // 0：未翻译 1 为 证书翻译
            if (comment_list[i].status === 0) {
              comment_list[i]["check"] = 3; //已发起
            } else if (comment_list[i].status === 1) {
              comment_list[i]["check"] = 1; //用户已反馈
            } else if (comment_list[i].status === 2) {
              comment_list[i]["check"] = 3; //审核通过
            } else if (
              comment_list[i].status === 4 ||
              comment_list[i].status === 5
            ) {
              comment_list[i]["check"] = 2; //未发起 和 已关闭
            } else if (
              comment_list[i].status === 3 &&
              comment_list[i].sort === 1
            ) {
              comment_list[i]["check"] = 4; //审核未通过第一次
            } else if (
              comment_list[i].status === 3 &&
              comment_list[i].sort === 2
            ) {
              comment_list[i]["check"] = 4; //审核未通过第二次
            }
          }

          this.setState({
            comment_result_sum: comment_result_sum,
            comment_list: comment_list,
            comment_list_formal: comment_list,
            loading_comment: 0,
          });

          let emotion_list = [];
          response.data &&
            response.data.chart.forEach((item, index) => {
              let dataObj = {
                name: item.name,
                sum: item.sum,
                type: item.type,
                data: [],
              };
              for (let i = 0; i < item.data.length; i++) {
                let itemObj = {
                  value: item.data[i].val,
                  name: item.data[i].name || "未知",
                };
                dataObj.data.push(itemObj);
              }
              emotion_list.push(dataObj);
            });
          this.setState({
            emotion_list: emotion_list,
            filter_start_date: response.data.start_time,
            filter_end_date: response.data.end_time,
          });
        }
        return response;
      })
      .catch((err) => {
        this.setState(
          {
            loading_comment: 0,
          },
          () => {
            message.error("后台发生错误");
          }
        );
        throw err;
      });
  }
  // 获取评价词
  async getCommentSelections() {
    let product_id = this.state.product_id_cem;
    this.setState({
      loading_comment_selection: 1,
    });
    getCommentSelection({
      product_id: product_id,
      survey_id: this.state.survey_id,
      search_text: this.state.search_text,
      age_list: this.state.selected_age_list,
      region_list: this.state.selected_region_list,
      gender_list: this.state.selected_gender_list,
      start_time: this.state.start_time,
      end_time: this.state.end_time,
    })
      .then((res) => {
        if (!res) return;
        if (res.status === 204) {
          // message.warning('暂未有数据', 2, () => { })
          this.setState({
            evaluation_level_list: [],
            evaluation_word_list: [],
            loading_comment_selection: 0,
          });
        } else {
          this.setState({
            evaluation_level_list: res.data.evaluation_level,
            evaluation_word_list: res.data.evaluation_word,
            loading_comment_selection: 0,
          });
        }
        return res;
      })
      .catch((err) => {
        this.setState(
          {
            loading_comment_selection: 0,
          },
          () => {
            message.error("后台发生错误");
          }
        );
        throw err;
      });
  }
  // search子组件会调用
  handleCommentSearch(str) {
    if (str === this.state.search_text) {
      return;
    }
    this.setState(
      {
        search_text: str,
        evaluation_level_list: [],
        evaluation_word_list: [],
      },
      () => {
        this.getCommentSelections();
        this.getComments();
        this.getWordclouds();
      }
    );
  }
  // 通用组件的搜索
  searchFilter(filter_obj) {
    let {
      selected_region_list,
      selected_gender_list,
      selected_age_list,
      start_time,
      end_time,
    } = filter_obj;
    this.setState(
      {
        selected_region_list: selected_region_list,
        selected_gender_list: selected_gender_list,
        selected_age_list: selected_age_list,
        start_time: start_time,
        end_time: end_time,
        evaluation_level_list: [],
        evaluation_word_list: [],
      },
      () => {
        this.getCommentSelections();
        this.getWordclouds();
        this.getComments();
      }
    );
  }
  // 切换checkbox 子组件会调用
  handleChangeLevelCheckbox(index, checked) {
    let tmpList = JSON.parse(JSON.stringify(this.state.evaluation_level_list));
    tmpList[index].checked = checked;
    this.setState(
      {
        evaluation_level_list: tmpList,
      },
      async () => {
        await this.getComments();
      }
    );
  }

  // 切换checkbox 子组件会调用
  handleChangeWordCheckbox(index, checked) {
    let tmpList = JSON.parse(JSON.stringify(this.state.evaluation_word_list));
    tmpList[index].checked = checked;
    this.setState({
      evaluation_word_list: tmpList,
    });
    setTimeout(() => {
      this.getComments();
    });
  }

  callback(key) {
    this.setState({ tab_key: key });
  }
  //清空数据
  clearData() {
    this.setState({
      wordCloudList: [], //词云
      comment_result_sum: 0, //评价表
      comment_list: [],
      emotion_list: [],
      evaluation_level_list: [], //评价词
      evaluation_word_list: [],
      selected_region_list: [], //filter_select
      selected_gender_list: [], //filter_select
      selected_age_list: [], //filter_select
    });
  }
  // 子传父 父的获取
  handleProductChange(id, survey_id) {

    if (id === "-1") {
      // 没有品牌 没有产品
      message.warning("没有品牌的数据", 2, () => { });
      this.clearData();
      return;
    }
    if (id === "") {
      // 只有品牌 没有产品
      message.warning("没有该产品的数据", 2, () => { });
      this.clearData();
      return;
    } else if (id !== this.state.product_id) {
      this.clearData();
    }
    this.setState(
      {
        product_id_cem: id,
        survey_id: survey_id,
      },
      () => {
        this.getRegionList();
        this.getCommentSelections();
        this.getComments();
        this.getWordclouds();
      }
    );
  }
  // 获取地域列表
  async getRegionList() {
    this.setState({
      loading: 1,
    });
    let product_id = parseInt(this.state.product_id_cem);
    try {
      const response = await getRegionList({
        product_id: product_id,
        survey_type: 2,
        survey_id: "",
      });
      let region_list = [];
      for (let i = 0; i < response.data.length; i++) {
        let obj = { key: i, value: response.data[i] };
        region_list.push(obj);
      }

      this.setState({
        region_list: region_list,
        loading: 0,
      });
      return response;
    } catch (error) {
      this.setState(
        {
          loading: 0,
        },
        () => {
          message.error("后台发生错误");
        }
      );
      throw error;
    }
  }
  async getCommentDetails(item) {
    let product_id = localStorage.getItem("product_id_cem");
    try {
      const response = await getCommentDetail({
        product_id: parseInt(product_id),
        user_id: item.user_id,
        survey_id: this.state.survey_id,
      });
      this.setState({
        canShowDetail: !this.state.canShowDetail,
        commentator_detail: item,
        comment_details: response.data,
        product_title:
          localStorage.getItem("brand_text_cem") +
          "-" +
          localStorage.getItem("product_text_cem") || "",
      });
    } catch (err) {
      message.error("后台发生错误");
      throw err;
    }
  }
  // 查看详情
  handleShowDetail(item) {
    this.getCommentDetails(item);
  }
  //返回
  handleBack() {
    this.setState({ canShowDetail: !this.state.canShowDetail });
  }
  //批量发起小红书激励接口
  async saveIncentive(selectedRowKeys, amount) {
    let comment_list = [];
    for (let i = 0; i < selectedRowKeys.length; i++) {
      let obj = {
        id: this.state.comment_list[selectedRowKeys[i]].id,
        user_id: this.state.comment_list[selectedRowKeys[i]].user_id,
        amount: amount,
        product_id: this.state.product_id_cem,
      };
      comment_list.push(obj);
    }
    try {
      const response = await saveXhsIncentive({
        comment_list: comment_list,
      });
      message.success("批量发起成功", 0.2, () => { });
      this.getComments();
      return response;
    } catch (error) {
      message.error("后台发生错误，删除失败");
      throw error;
    }
  }
  //审核小红书激励
  async updateXhsIncentive(status, comment_id, comment) {
    try {
      const response = await updateXhsIncentive({
        status: status,
        comment_id: comment_id,
        comment: comment,
      });
      if (status === 2) {
        message.success("审核通过的操作成功", 2, () => { });
      } else {
        message.success("审核不通过的操作成功", 2, () => { });
      }
      this.getComments();
      return response;
    } catch (error) {
      message.error("后台发生错误，删除失败");
      throw error;
    }
  }
  // 根据审核状态筛选评论列表
  filter_comment_list(filter_incentive_status) {
    if (filter_incentive_status === -1) {
      this.setState({
        comment_list: this.state.comment_list_formal,
        comment_result_sum: this.state.comment_list_formal.length,
      });
    } else {
      let comment_list = this.state.comment_list_formal.filter(
        (item) => item.status === Number(filter_incentive_status)
      );
      this.setState({
        comment_list: comment_list,
        comment_result_sum: comment_list.length,
      });
    }
  }
  render() {
    return (
      <div className="container emotion-analysis-contain">
        {this.state.loading <= 0 &&
          this.state.loading_comment <= 0 &&
          this.state.loading_comment_selection <= 0 &&
          this.state.loading_wordcloud <= 0 ? (
          ""
        ) : (
          <Loading></Loading>
        )}
        <ProductDropdown
          handleProductChange={this.handleProductChange.bind(this)}
        ></ProductDropdown>
        <div className="emotion-analysis-card">
          <Tabs defaultActiveKey="1" onChange={this.callback.bind(this)}>
            <TabPane tab="评价列表" key="1" forceRender>
              <div>
                <div className={this.state.canShowDetail ? "" : "isHide"}>
                  <EvaluationDetail
                    dataList={this.state.commentator_detail}
                    comment_details={this.state.comment_details}
                    product_title={this.state.product_title}
                    handleBack={this.handleBack.bind(this)}
                  ></EvaluationDetail>
                </div>
                <div className={this.state.canShowDetail ? "isHide" : ""}>
                  <Evaluation
                    tab_key={this.state.tab_key}
                    product_id_cem={this.state.product_id_cem}
                    comment_result_sum={this.state.comment_result_sum}
                    comment_list={this.state.comment_list}
                    evaluation_level_list={this.state.evaluation_level_list}
                    evaluation_word_list={this.state.evaluation_word_list}
                    searchFilter={this.searchFilter.bind(this)}
                    handleChangeLevelCheckbox={this.handleChangeLevelCheckbox.bind(
                      this
                    )}
                    handleCommentSearch={this.handleCommentSearch.bind(this)}
                    handleChangeWordCheckbox={this.handleChangeWordCheckbox.bind(
                      this
                    )}
                    handleShowDetail={this.handleShowDetail.bind(this)}
                    region_list={this.state.region_list}
                    filter_start_date={this.state.filter_start_date}
                    filter_end_date={this.state.filter_end_date}
                    search_text={this.state.search_text}
                    saveIncentive={this.saveIncentive.bind(this)}
                    updateXhsIncentive={this.updateXhsIncentive.bind(this)}
                    filter_comment_list={this.filter_comment_list.bind(this)}
                  ></Evaluation>
                  <div className="emotion-analysis-card-chart-panel">
                    {this.state.emotion_list.map((item, index) => {
                      return (
                        <EmotionChart
                          dataList={item}
                          single_id={`emotion_analysis_chart_${index}`}
                          key={index}
                        ></EmotionChart>
                      );
                    })}
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="词云分析" key="2" forceRender>
              <WordCloudPanel
                dataList={this.state.wordCloudList}
              ></WordCloudPanel>
            </TabPane>
            <TabPane tab="文案生成" key="3">
              <AiCopywriting
                product_id_cem={this.state.product_id_cem}
              ></AiCopywriting>
            </TabPane>
          </Tabs>
        </div>
      </div>
    );
  }
}
export default Emotion_analysis;
