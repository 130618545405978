import React, { Component } from 'react'
import './Word_cloud.scss'
import { addListener } from "resize-detector";
import * as echarts from 'echarts';
import 'echarts-wordcloud';
import { getWordcloud, login } from '../../../api/panelApi'
import word_cloud_image from './Word_cloud_image';

export default class Word_cloud extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // product_id: localStorage.getItem('product_id') ? localStorage.getItem('product_id') : 11,    //产品ID
            // product_brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : "23",   //品牌ID
            keywords: [],
            dataList: {},
            isProductChange: false,
            _isMounted: false,
            dataList_prev: {},
            needRefresh: false
        }
    }
    componentDidMount() {
        this._isMounted = true
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.initChart();
            this.setState({
                needRefresh: false
            })
        }

        return true
    }
    initChart() {
        let res = this.state.dataList.data
        let single_id = this.props.single_id
        let data = []
        if (!res) {
            return
        }
        res.forEach((item, index) => {
            let obj = { name: item.name, value: parseInt(item.val * item.weight) }
            data.push(obj)
        })
        let image = word_cloud_image

        var maskImage = new Image();
        maskImage.src = image
        let option = {
            tooltip: {
                // pointFormat: "{series.name}:-<b>{point.percentage:.1f}%</b>"
                trigger: 'item',
                formatter: function (parms) {
                    let str = ""

                    let index = parms.dataIndex;
                    let percent = res[index].percentage
                    let val = res[index].val
                    let weight = res[index].weight
                    let all_weight = data[index].value
                    // let percent = res[index].percentage;
                    // let num = res[index].val;
                    str = parms.marker + "" + parms.name + "</br>" +
                        "数量：" + val + "</br>" +
                        "权重：" + weight + "</br>" +
                        "总权重：" + all_weight + "</br>" +
                        "占比：" + percent;
                    return str;
                }
            },
            series: [{
                type: 'wordCloud',
                gridSize: 10,
                sizeRange: [20, 75],
                rotationRange: [-15, 15],
                maskImage: maskImage,
                left: 'center',
                top: 'center',
                width: '100%',
                height: '100%',
                right: null,
                bottom: null,
                data: data,
                textStyle: {
                    color: function () {
                        return (
                            "rgb(" +
                            Math.round(Math.random() * 255) +
                            ", " +
                            Math.round(Math.random() * 255) +
                            ", " +
                            Math.round(Math.random() * 255) +
                            ")"
                        );
                    }

                },
            }]
        }
        setTimeout(() => {
            if (document.getElementById(single_id)) {
                echarts.dispose(document.getElementById(single_id))
            }
            var wordCloudChart = echarts.init(document.getElementById(single_id));
            wordCloudChart.on('click', (param) => {
                // console.log(param);
            });
            wordCloudChart.setOption(option, true);

            addListener(document.getElementById(single_id), () => {
                wordCloudChart.resize()
            })
        })

    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList,
            };
        }
        return null;
    }

    componentWillUnMount = () => {
        this._isMounted = false;
        this.setState = () => { };

    }
    render() {
        let single_id = this.props.single_id || ''
        return (
            <div className="word-cloud-container">
                <div id={single_id} className="echart-container wordcloud-echart" style={{ width: '40vw', height: '40vw' }}></div>
            </div >
        )
    }
}
