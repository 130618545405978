import React, { Component } from 'react'
import upload_add_img from "../../../../../../images/project_entry/project_info/upload_add_pic.png"
import "./picture_template_info.scss"


export default class picture_template_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'picture_info',
            modelContent: "确定删除该图片吗？",
            showModel: false,
            modelTitle: "删除提示",
            dataList: props.dataList || {},
            answers: props.dataList.answers || [],
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                answers: props.dataList.answers || state.answers,
            }
        }
        return null;
    }
    render() {
        return (
            <div className="picture-template-info">
                <div className="pic-info-tip">
                    请上传图片文案示例
                </div>
                <div className="pic-info-wrapper">
                    <div className="pic-info-left">
                        <div className={this.state.dataList.image.val ? "isHide" : "show-upload-wrap"}>

                            <div className="upload-pic-wrapper">
                                <img src={upload_add_img} alt="" className="upload-pic" />
                                <div className="upload-pic-text">添加图片</div>
                            </div>
                        </div>
                        <div className={this.state.dataList.image.val ? "show-pic-wrap" : "isHide"}>
                            <img src={this.state.dataList.image.val} alt="" className="show-pic" />
                        </div>
                    </div>
                    <div className="pic-info-right">
                        <div className="pic-info-btn pic-info-btn-1">
                            {this.state.answers[0].name}
                        </div>
                        <div className="pic-info-btn pic-info-btn-2">
                            {this.state.answers[1].name}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
