import * as echarts from 'echarts';
import { theme_color_list } from "./Color_config"
// export const date_tooltip = {
//     trigger: 'axis',
// }
export function getLineTooltip(res, showOptionImg) {
    let tooltip = {
        trigger: 'axis',

        formatter: function (params) {
            let str = params[0].name + "</br>"
            params.forEach((item, index) => {
                let name = item.seriesName
                let value = item.value[1]
                let color = item.color
                let option_img_str = ""
                if (showOptionImg && res[index].option_img) {
                    option_img_str = "<img src=" + res[index].option_img + " style='width: auto;height: 150px;vertical-align: middle;object-fit: cover;'></img></br>"
                }
                let marker = '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' + color + '"></span>'
                str += marker + name + "：" + value + "</br>" + option_img_str
            })
            return str
        }
    }
    return tooltip
}

export const date_legend = {
    width: '80%',
    top: 10,
    x: 'center',
    icon: 'circle', //设置图例图表形状
    itemWidth: 8,
    itemHeight: 8,
    itemGap: 10,
    // padding:20,
    type: 'scroll',
    pageIconColor: '#cbc9cf',
    pageIconInactiveColor: '#e5e4e7',
    pageIconSize: 12,
    pageTextStyle: {
        color: '#595961',
    },
    textStyle: {
        color: '#595961',
        fontSize: 12
    }
}

export const date_xAxis = {
    type: 'category',
    axisLabel: {
        show: true,
        textStyle: {
            color: '#9797A1', //x轴文本字体颜色改变
            fontSize: 12
        }
    },
    axisTick: { // 去掉刻度条
        show: false
    },
    axisLine: { //x轴的颜色改变
        lineStyle: {
            color: '#E3E2E5'
        }
    }
}

export const date_yAxis = {
    type: 'value',
    // boundaryGap: [0, '100%']
    splitLine: {  // y轴的分割线
        lineStyle: {
            color: '#E3E2E5',
            type: 'dashed'
        }
    },
    axisLabel: {
        show: true,
        textStyle: {
            color: '#9797A1', //x轴文本字体颜色改变
            fontSize: 12
        }
    }
}

export function getBarSeries(res) {
    let series = []
    res.forEach((item, index) => {
        let len = item.x_axis.length
        let obj = { name: "", type: "line", data: [], smooth: true, showSymbol: len >= 2 ? false : true, symbolSize: len >= 2 ? 4 : 10, }
        if (len === 1) {
            obj['symbol'] = 'circle'
        }
        obj.name = item.option_name
        for (let i = 0; i < len; i++) {
            let tmpArr = [item.x_axis[i], parseInt(item.value[i])]
            obj.data.push(tmpArr)
        }
        series.push(obj)
    })
    return series
}

export const line_color = [
    "#FF5186", "#FBC155",
]

export function getMatrixLineOption(res, showOptionImg) {
    //bar
    let option = {
        grid: {
            left: '60px',
            right: '40px'
        },
        // tooltip: date_tooltip,
        tooltip: getLineTooltip(res, showOptionImg),
        legend: date_legend,
        color: theme_color_list,
        xAxis: date_xAxis,
        yAxis: date_yAxis,
        series: getBarSeries(res)
    }
    return option
}