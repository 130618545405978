import React, { Component } from 'react'
import './loading.scss';
import { message } from 'antd';

export default class loading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "loading",
            loadingText: "数据较大，加载中"
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.loadingText) !== JSON.stringify(state.loadingText_prev)) {
            return {
                // needRefresh: true,
                loadingText: props.loadingText || state.loadingText, //question
                loadingText_prev: props.loadingText,
            }
        }
        return null;
    }
    componentDidMount() {
        // this.hide = message.loading('Action in progress..', 0);
        // console.log(this.hide)
    }
    componentDidUpdate() {
        // if (this._isMounted && this.state.needRefresh) {
        //     this.init()
        //     this.setState({
        //         needRefresh: false
        //     })
        // }
    }
    componentWillUnmount() {
        // this._isMounted = false;
        this.setState = () => { }
    }
    render() {
        return (
            <div className="loading-message">
                <div>
                    <div className="loading-message-notice">
                        <div className="loading-message-notice-content">
                            <div className="message-loading">
                                <div className="lds-dual-ring"></div>
                                <div className="message-loading-text">{this.state.loadingText}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
