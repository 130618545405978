
import React, { Component } from 'react'
import './AddTags.scss';
import cur_icon_close_gre from '../../../images/cur_icon_close_gre.png'
import cur_icon_search from "../../../images/emotion_analysis/cur_icon_search.png"
import cur_icon_choice_sel from '../../../images/cur_icon_choice_sel.png'
import cur_icon_choice_nor from '../../../images/cur_icon_choice_nor.png'
import { Tree, Input } from 'antd';
import { message } from 'antd';

class AddTags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tree_data_formal: [],
            tree_data: [],
            fieldNames: {
                title: 'name',
                key: 'id',
                children: 'children'
            },
            expandedKeys: [],
            checkedKeys: [],
            defaultKeys: [],
            defaultData: [],
            MandatoryData: [],
            search_text: "",
            account_type: "1",
            is_native: false,//0 老问卷 1新问卷
        }

    }

    // 接受父组件的数据
    setTreeData(tree_data, checkedKeys, defaultKeys, account_type, is_native) {
        // let { MandatoryData, defaultData } = this.findDefaultNodes(tree_data);
        this.setState({
            tree_data: tree_data,
            tree_data_formal: tree_data,
            checkedKeys: checkedKeys,
            defaultKeys: defaultKeys,
            expandedKeys: [],
            search_text: "",
            account_type: account_type,
            is_native: is_native
            // defaultData,
            // MandatoryData
        })
    }
    // 递归找到必填项
    // findDefaultNodes(list) {
    //     let MandatoryData = [];
    //     let defaultData = [];
    //     for (let i in list) {
    //         if (list[i].children) {
    //             let data = this.findDefaultNodes(list[i].children);
    //             MandatoryData.push(...data.MandatoryData);
    //             defaultData.push(...data.defaultData);
    //         }
    //         if (list[i].flag === 1) {
    //             MandatoryData.push(list[i])
    //         }
    //         if (list[i].flag === 2) {
    //             defaultData.push(list[i])
    //         }
    //     }
    //     return { MandatoryData, defaultData }
    // }


    //
    // 是否可以勾选标签
    // canSelectTag(index) {
    //     let tag_list_copy = this.state.tag_list_copy
    //     let flag = tag_list_copy[index].flag
    //     if (flag === 1) {
    //         return false
    //     }
    //     return true
    // }
    // 勾选标签
    // tag_select(index, event) {
    //     console.log('tag_select')
    //     let defaultData = this.state.defaultData;
    //     let checkedKeys = [];
    //     defaultData[index].tag_flag = !defaultData[index].tag_flag;
    //     for (let i in defaultData) {
    //         if (defaultData[i].tag_flag) checkedKeys.push(defaultData[i].id);
    //     }
    //     this.setState({
    //         defaultData,
    //         checkedKeys: Array.from(new Set([...checkedKeys, ...this.state.defaultKeys]))
    //     })
    // }

    //取消按钮
    tags_close() {
        this.props.get_AddTags_status_Add_Throng_list({ AddTags_status: false })
    }
    //确定按钮
    tags_confirm() {
        if (this.state.account_type === '1' && this.state.is_native) {
            message.warning(`基础共创只提供必填标签组（年龄、性别、地域），请联系商务进行升级`, 3, () => { })
            return
        }
        this.props.get_AddTags_status_Add_Throng_list({ AddTags_status: false, checkedKeys: this.state.checkedKeys })

    }

    // 选择标签
    onCheck(checkedKeys, info) {
        if (this.state.defaultKeys.indexOf(info.node.id) === -1) {
            // let defaultData = this.state.defaultData;
            // for (let i in defaultData) {
            //     defaultData[i].tag_flag = checkedKeys.indexOf(defaultData[i].id) !== -1;
            // }
            this.setState({
                checkedKeys: Array.from(new Set([...checkedKeys, ...this.state.defaultKeys])),
                // defaultData
            })
        } else {
            console.log('默认选项不能修改')
        }
    }

    onExpand(newExpandedKeys) {
        this.setState({
            expandedKeys: newExpandedKeys
        })
    }
    // 搜索按钮 传父
    handleSearch(t, event) {
        if (!t) return false
        if (!event) return false
        let search_text = event.target.value.trim()
        if (search_text === "") {
            this.setState({
                search_text: "",
                tree_data: this.state.tree_data_formal
            })
        } else {
            this.setState({
                search_text: search_text
            })
            this.findSearchNode(search_text)
        }
    }
    // 查找
    findSearchNode(search_text) {
        let tree_data = JSON.parse(JSON.stringify(this.state.tree_data_formal))
        let expandedKeys = []
        const bfs = (root) => {
            const q = root //根结点入队
            while (q.length > 0) {
                const n = q.shift()
                if (n.name.indexOf(search_text) !== -1) {
                    expandedKeys.push(n.id)
                }
                if (n.hasOwnProperty('children')) {
                    n.children.forEach(child => {
                        q.push(child)
                    })
                }
            }
        }
        bfs(tree_data)
        let result = []
        let tree_data2 = JSON.parse(JSON.stringify(this.state.tree_data_formal))
        function findParent(data, target, result) {
            for (let i in data) {
                let item = data[i]
                if (item.id === target) {
                    // item.name = <span className="site-tree-search-value">{item.name}</span>
                    const strTitle = item.name;
                    const index = strTitle.indexOf(search_text);
                    const beforeStr = strTitle.substring(0, index);
                    const afterStr = strTitle.slice(index + search_text.length);
                    const title =
                        index > -1 ? (
                            <span>
                                {beforeStr}
                                <span className="site-tree-search-value">{search_text}</span>
                                {afterStr}
                            </span>
                        ) : (
                            <span>{strTitle}</span>
                        );
                    item.name = title
                    result.unshift(item.id)
                    return true
                }
                if (item.children && item.children.length > 0) {
                    let ok = findParent(item.children, target, result)
                    if (ok) {
                        result.unshift(item.id)
                        return true
                    }
                }
            }
            //走到这说明没找到目标
            return false
        }

        for (let i = 0; i < expandedKeys.length; i++) {
            findParent(tree_data2, expandedKeys[i], result)
        }

        this.setState({
            expandedKeys: Array.from(new Set([...result])),
            tree_data: tree_data2
        })
    }
    render() {
        const prefix = (
            <img src={cur_icon_search} alt="" className='evaluation-icon-search' />
        )
        return (
            <div className='add_tags'>
                <div className='add_tags_popup' onClick={this.tags_close.bind(this)}></div>
                <div className='add_tags_main'>
                    <div className='add_tags_top'>
                        <div className='popup-top-title'>全部类别</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} onClick={this.tags_close.bind(this)} className='cursor' alt="" />
                        </div>
                    </div>

                    {/* <div className='add_tags_items tags_item1'>
                        <p>必填项</p>
                        {
                            this.state.MandatoryData.map((item, index) => {
                                return (
                                    <div className={`item not-allowed`} key={'tags_' + index}>
                                        <img src={item.tag_flag ? cur_icon_choice_sel : cur_icon_choice_nor} alt="" />
                                        <span style={{ color: item.tag_flag ? '#322D2C' : '#595961' }}>{item.name}</span>
                                    </div>
                                )
                            })
                        }
                    </div> */}
                    {/* <div className='add_tags_items tags_item1'>
                        <p>选填项</p>
                        {
                            this.state.defaultData.map((item, index) => {
                                return (
                                    <div className={`item`} key={'tags_' + index} onClick={this.tag_select.bind(this, index)}>
                                        <img src={item.tag_flag ? cur_icon_choice_sel : cur_icon_choice_nor} alt="" />
                                        <span style={{ color: item.tag_flag ? '#322D2C' : '#595961' }}>{item.name}</span>
                                    </div>
                                )
                            })
                        }
                    </div> */}
                    <div className="search-wrapper">
                        <Input prefix={prefix} placeholder="搜索关键词" value={this.state.search_text} onChange={this.handleSearch.bind(this, 'comment_search')} />
                    </div>
                    <div className='add_tags_items tags_item2'>
                        {/* <p>全部类别</p> */}
                        <Tree
                            checkable
                            checkedKeys={this.state.checkedKeys}
                            expandedKeys={this.state.expandedKeys}
                            fieldNames={{ title: 'name', key: 'id' }}
                            onCheck={this.onCheck.bind(this)}
                            onExpand={this.onExpand.bind(this)}
                            treeData={this.state.tree_data}
                        >
                        </Tree>

                    </div>
                    <div className='tag-btn'>
                        <div onClick={this.tags_close.bind(this)}>取消</div>
                        <div onClick={this.tags_confirm.bind(this)}>确认</div>
                    </div>
                </div>
            </div>
        )
    }
}



export default AddTags;