import axiosInstance from '../APIUtils'

// 获取项目筛选人群
export function getProjectTA(obj) {
    return axiosInstance({
        url: "/cem/getProjectTA/",
        method: "POST",
        data: obj
    });
}

// 获取标签类别
export function getTagCategory(obj) {
    return axiosInstance({
        url: "/cem/getTagCategory/",
        method: "POST",
        data: obj
    });
}

// 获取标签类别
export function getTags(obj) {
    return axiosInstance({
        url: "/cem/getTags/",
        method: "POST",
        data: obj
    });
}

// 保存项目筛选人群
export function saveProjectTA(obj) {
    return axiosInstance({
        url: "/cem/saveProjectTA/",
        method: "POST",
        data: obj
    });
}

// 更新项目筛选人群
export function updateProjectTA(obj) {
    return axiosInstance({
        url: "/cem/updateProjectTA/",
        method: "POST",
        data: obj
    });
}