import React, { Component } from 'react'
import './basic_information.scss';
import BaseInfo from "./base_info";
import QuestionSurvey from "./question_survey";
import { Tabs, message } from 'antd';
import { getQueryParameter } from "../../../util/utils"
const { TabPane } = Tabs;
let that = ''
export default class basic_information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product_id: localStorage.getItem('product_id') ? localStorage.getItem('product_id') : '',    //产品ID
            brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : '',   //品牌ID
            project_id: localStorage.getItem('project_id') ? localStorage.getItem('project_id') : '',
            toastStatus: false,
            msg: '',       //验证错误信息
            baseInfo_width: '',       //底部的宽度
            baseInfo_main_Height: '',     //主内容的高度
            project_entry_number: 1,     //第1步
            tab_key: '1',// 基础信息 切换  1样品派送 2问卷调研
            project_type: "",  //1样品派送 2问卷调研
        }

    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.project_entry_number) !== JSON.stringify(state.project_entry_number_prev)) {
            return {
                needRefresh: true,
                project_entry_number: props.project_entry_number || 0, //question
                project_entry_number_prev: props.project_entry_number,
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
        that = this

        this.init()
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.init()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
        console.log('Component WILL UNMOUNT!')
    }
    init() {
        let tab_key = "1"
        let project_type = 0
        let cur_path = window.location.pathname
        let dispatch_path = "/dispatch_list"
        // let survey_path = "/survey_list"
        if (cur_path.indexOf(dispatch_path) >= 0) {
            tab_key = '1'
            project_type = 1
            localStorage.setItem('current_project_type', 1)
        } else {
            tab_key = '2'
            project_type = 2
            localStorage.setItem('current_project_type', 2)
        }
        this.setState({
            project_entry_number: this.props.project_entry_number,
            tab_key: tab_key,
            project_type: project_type
        })
    }
    // 获取
    getData(object) {
        this.props.getData({ ...object })
    }
    render() {
        return (
            <div className={this.state.project_entry_number === 1 ? "baseinfo-tab-container" : "baseinfo-tab-container isHide"}>
                {
                    this.state.tab_key === '1' ?
                        <BaseInfo history={this.props.history} project_entry_number={this.state.project_entry_number} tab_key={this.state.tab_key} getData={this.getData.bind(this)}></BaseInfo>
                        :
                        <QuestionSurvey project_entry_number={this.state.project_entry_number} tab_key={this.state.tab_key} getData={this.getData.bind(this)}></QuestionSurvey>
                }
            </div>
        )
    }
}
