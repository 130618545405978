import React, { Component } from 'react'
import './Emotion_chart.scss';
import { getPieOption } from "../../util/Chart_config/Pie_chart_config";
import { getBarOption } from "../../util/Chart_config/Bar_chart_config";
import { formatChart } from "../../util/Chart_config/Config";
import { Empty, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
export default class Emotion_chart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "Emotion_chart",
            dataList: {},
            needRefresh: false,
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList,
            };
        } else {
            // 因为第一次渲染这个页面的时候 会设置为true
            return {
                needRefresh: false,
            }
        }
        // return null;
    }
    componentDidUpdate() {
        if (this.state.dataList.data && this.state.needRefresh) {
            this.toInitChart();// 初始化页面
            this.setState({
                needRefresh: false
            })
        }
        return true
    }
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.toInitChart();// 初始化页面
        }
    }
    componentWillUnMount = () => {
        this._isMounted = false;
        this.setState = () => { };

    }
    // 制作饼状图
    toInitPieChart() {
        let id = this.props.single_id
        let data = this.props.dataList.data
        let option = getPieOption(data)
        formatChart(id, option)
    }
    // 制作柱状图
    toInitBarChart(sum) {
        let id = this.props.single_id
        let xAxisList = []
        let seriesList = []
        this.props.dataList.data.forEach((item, index) => {
            xAxisList.push(item.name)
            seriesList.push(Number(item.value))
        })
        let option = getBarOption(xAxisList, seriesList, sum)
        formatChart(id, option)
    }
    toInitChart() {
        let type = this.props.dataList.type
        let sum = this.props.dataList.sum
        if (sum === 0) return
        if (type === 0) {
            this.toInitPieChart()
        } else {
            this.toInitBarChart(sum)
        }
    }
    render() {
        let single_id = String(this.props.single_id) || ""
        let name = this.props.dataList.name || ""
        let sum = this.props.dataList.sum || 0
        return (
            <div className='pannel-card panel-card-emotion-single'>
                <div className='emotion-card-title-wrapper'>
                    <span className='line'></span>
                    <div className='text'>{name}</div>
                </div>
                <div id={single_id} className={sum > 0 ? "echart-container emotion-single-echart" : "isHide"}></div>
                {
                    sum === 0 ?
                        <div className="rank-content-empty">
                            <ConfigProvider locale={zhCN}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </ConfigProvider>
                        </div>
                        :
                        ""
                }
            </div>
        )
    }
}
