import { theme_color_list } from './Color_config'
let custom_color = ["#bbb1ac", "#9c755f", "#ff9da7", "#b07aa1", "#26CEBA ", "#488BFF", "#59a24e", "#FBC155", "#FF6B42", "#ff5186"]
export function getBarTooltip(sum) {
    let tooltip = {
        trigger: 'axis',
        axisPointer: {
            type: 'shadow'
        },
        formatter: function (params) {
            let param = params[0]
            let percent = (Number(param.data / sum) * 100).toFixed(2)
            var str = param.marker + "" + param.name + "</br>" +
                "数量：" + param.data + "</br>" +
                "占比：" + percent + "%</br>";
            return str;
        }
    }
    return tooltip
}

export const bar_yAxis = {
    type: 'value',
    splitLine: {  // y轴的分割线
        lineStyle: {
            color: '#E3E2E5',
            type: 'dashed'
        }
    },
    axisLabel: {
        show: true,
        textStyle: {
            color: '#9797A1', //x轴文本字体颜色改变
            fontSize: 12
        }
    }
}

export function get_bar_xAxis(xAxisList) {
    let xAxis = {
        type: 'category',
        axisTick: { // 去掉刻度条
            show: false
        },
        axisLine: { //x轴的颜色改变
            lineStyle: {
                color: '#E3E2E5'
            }
        },
        axisLabel: { //x轴的标题
            show: true,
            textStyle: {
                color: '#9797A1', //x轴文本字体颜色改变
                fontSize: 12,
                lineHeight: 14
            },
            // interval: 0,
            // formatter: function (value, index) {
            //     let ans = ""
            //     let maxWordLength = 12  //每个柱子的title最多3个文字
            //     let barNum = xAxisList.length
            //     let valueLen = value.length //文字长度
            //     if (barNum >= 6) {
            //         maxWordLength = 3
            //     } else if (barNum >= 3) {
            //         maxWordLength = 6
            //     }
            //     let rowNum = Math.ceil(valueLen / maxWordLength)
            //     if (rowNum > 1) {
            //         for (let i = 0; i < rowNum; i++) {
            //             let tmp = ""
            //             let start = i * maxWordLength
            //             let end = start + maxWordLength
            //             if (i === rowNum - 1) {
            //                 tmp = value.substring(start, end)
            //             } else {
            //                 tmp = value.substring(start, end) + "\n"
            //             }
            //             ans += tmp
            //         }
            //         return ans
            //     } else {
            //         return value
            //     }
            // }
        },
        data: xAxisList
    }
    return xAxis
}

export function getBarSeries(data, sum) {

    let series = [
        {
            data: data,
            type: 'bar',
            // barWidth: '50%', //柱子宽度
            barMaxWidth: 50,
            // itemStyle: {
            //     color: function (p) {
            //         return custom_color[p.dataIndex]
            //     }

            // },
            label: {
                show: true,
                position: 'top',
                formatter: function (param) {
                    if (data.length > 20) {
                        return ""
                    } else {
                        let percent = (Number(param.data / sum) * 100).toFixed(0)
                        var str = percent + "%";
                        return str;
                    }

                },
                textStyle: {
                    color: '#9797A1', //x轴文本字体颜色改变
                    fontSize: 12
                }
            }
        }
    ]
    return series
}

export function getCustomBarOption(xAxisList, data, sum) {
    let option = {
        grid: {
            left: '50px',
            right: '10px'
            // bottom: 50,
            // containLabel: true
        },
        color: '#FF5186',
        tooltip: getBarTooltip(sum),
        xAxis: get_bar_xAxis(xAxisList),
        yAxis: bar_yAxis,
        series: getBarSeries(data, sum)
    }
    return option
}