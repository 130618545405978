/** @format */

import React, { Component } from "react";
import "./sankey.scss";
import "../../css/dialog_flag.scss";

import cur_icon_close from "../../images/cur_icon_close_gre.png";

import * as echarts from "echarts";
import { message, Table } from "antd";

import { GetFollowUpRemaningNodes } from "../../api/sankeyApi/sankey";
import { addListener } from "resize-detector";

class sankey extends Component {
	constructor(props) {
		super(props);
		this.state = {
			mySankey: null,
			dialogFlag: false,
			dialogQuestionFlag: false,
			dataSource: [],
			questionList: [],
			heiddenItem: [],
			showLinksMap: {},
			data:[],
			links:[],
		};
		this.columns = [
			{
				title: "姓名",
				dataIndex: "nickname",
				key: "nickname",
				width: 120,
			},
			{
				title: "性别",
				dataIndex: "gender",
				key: "gender",
				width: 60,
			},
			{
				title: "年龄",
				dataIndex: "age",
				key: "age",
				width: 60,
			},
			{
				title: "住址",
				dataIndex: "address",
				key: "address",
				width: 100,
			},
			{
				title: "问题",
				dataIndex: "question",
				key: "question",
			},
			{
				title: "答案",
				dataIndex: "answer",
				key: "answer",
			},
			{
				title: "操作",
				dataIndex: "",
				key: "action",
				width: 100,
				fixed: "right",
				render: (item) => {
						return (
							<label>
								<span onClick={this.GetFollowUpRemaningNodes.bind(this, item)}>更多</span>
							</label>
						);
				},
			},
		];
	}

	// 处理边数据
	constructMetaData(list){
		let dataMap = {};
		let data = [];
		let linksMap = {};
		let links = [];

		for(let i in list){
			for(let n in list[i]){
				let source = list[i][n].source;
				let target = list[i][n].target;
				if(linksMap[`${source}$_$${target}`]){
					linksMap[`${source}$_$${target}`].value += list[i][n].value;
					linksMap[`${source}$_$${target}`].questionList.push(...list[i][n].questionList);
				}else{
					linksMap[`${source}$_$${target}`] = JSON.parse(JSON.stringify(list[i][n]));
				}
			}
		}
		for(let i in linksMap){
			links.push(linksMap[i]);
			
			if(linksMap[i].type != 'group') continue;
			if(dataMap[linksMap[i].source]){
				dataMap[linksMap[i].source].questionList.push(...linksMap[i].questionList);
				dataMap[linksMap[i].source].targetName.push(linksMap[i].targetName);
				dataMap[linksMap[i].source].value += 1;
			}else{
				dataMap[linksMap[i].source] = {
					name: linksMap[i].source,
					value: 1,
					questionList: [...linksMap[i].questionList],
					depth: linksMap[i].depth,
					sourceName: linksMap[i].sourceName,
					targetName: [linksMap[i].targetName],
				}
			}
			if(dataMap[linksMap[i].target]){
				dataMap[linksMap[i].target].questionList.push(...linksMap[i].questionList);
				dataMap[linksMap[i].target].sourceName.push(linksMap[i].sourceName);
				dataMap[linksMap[i].target].value += 1;
			}else{
				dataMap[linksMap[i].target] = {
					name: linksMap[i].target,
					value: 1,
					questionList: [...linksMap[i].questionList],
					depth: linksMap[i].depth + 1,
					sourceName: [linksMap[i].sourceName],
					targetName: linksMap[i].targetName,
				}
			}
		}
		for(let i in dataMap){
			data.push(dataMap[i]);
		}
		this.setState({data,links})
		this.renderSankey(data,links);
	}

	// 渲染sankey
	renderSankey(data, links) {
		if (!this.state.mySankey) {
			let eMySankey = document.getElementById("sankey");
			if (eMySankey) {
				let mySankey = echarts.init(eMySankey);
				this.setState({ mySankey }, () => {
					this.renderSankey(data, links);
				});
			} else {
				message.warning("未找到表格容器", 2, () => {});
			}
		} else {
			this.state.mySankey.setOption({
				tooltip: {
					trigger: "item",
					triggerOn: "mousemove",
				},
				series: {
					type: "sankey",
					layout: "none",
					emphasis: {
						focus: "adjacency",
					},
					nodeAlign: "right",
					draggable: false,
					data: data,
					links: links,
					tooltip: {
						formatter: (e) => {
							if (e.dataType === "node") {
								return ``;
							} else if (e.data?.questionList?.length) {
								return `包含问题数量:${e.data.value}`;
							} else {
								return "";
							}
						},
					},
					lineStyle: {
						color: "gradient",
						curveness: 0.5,
					},
				},
			});

			this.state.mySankey.off("click");
			this.state.mySankey.on("click", (e) => {
				this.shuntClick(e);
			});
			addListener(document.getElementById('sankey'), () => {
        this.state.mySankey.resize()
    	})
		}
	}

	// 清空sankey
	clearSankey() {
		this.renderSankey([], []);
	}

	// 点击事件分流
	shuntClick(e) {
		console.log(e);
		// 点击节点
		if (e.dataType === "node") {
			// 答案节点
			if (e.data.depth % 2 === 1) {
				this.props.nextSankey(e);
			}
			// 题目节点
			if (e.data.depth % 2 === 0){
				let links = this.state.links;
				let data = this.getHeiddenItem(e.data,this.state.data,links);
				let tempData = [];
				let tempLinks = [];
				this.setState({data});
				for(let i in data){
					if(data[i].value > 0){
						tempData.push(data[i])
					}
				}
				for(let i in links){
					if(links[i].showValue > 0){
						tempLinks.push(links[i])
					}
				}
				if(!tempLinks.length){
					tempLinks.push(links[0])
				}
				this.renderSankey(tempData,tempLinks);
			}
		}
		// 点击边
		if (e.dataType === "edge") {
			// 含问题
			if (e.data?.questionList?.length) {
				let questionList = e.data.questionList;
				let is_end = e.data.targetName === "-";
				for (let i in questionList) {
					questionList[i].is_end = is_end;
				}
				this.setState({ dataSource: questionList, dialogFlag: true });
			}
		}
	}

	// 收集不显示的节点
	getHeiddenItem(item,data,links){
		// 找到改变的边
		let selectLink = [];
		for(let i in links){
			if(links[i].source == item.name){
				links[i].showValue = links[i].showValue?0:links[i].value;
				selectLink.push(links[i]);
			}
		}
		this.setState({links})
		// 根据改变的边 维护 节点的显示权重
		for(let i in selectLink){
			for(let n in data){
				if(selectLink[i].target === data[n].name){
					if(selectLink[i].showValue){
						data[n].value += 1;
						if(data[n].value == 1){
							data = this.getHeiddenItem(data[n],data,links)
						}
					}else{
						data[n].value -= 1;
					}
					if(data[n].value <= 0){
						data = this.getHeiddenItem(data[n],data,links)
					}
					break;
				}
			}
		}
		return data;
	}

	// 关闭弹窗
	onClose() {
		this.setState({ dialogFlag: false });
	}
	// 获取后续所有问答
	GetFollowUpRemaningNodes(item) {
		let param = {
			user_id: item.user_id,
			question_id: item.question_id,
			sort: item.sort,
		};
		GetFollowUpRemaningNodes(param).then(
			(res) => {
				if (res.data.conversation) {
					let list = [];
					list.push(...res.data.conversation);
					this.setState({ dialogQuestionFlag: true, questionList: list });
				} else {
					message.error("暂无数据", 2, () => {});
				}
			},
			(err) => {
				message.error('暂无数据', 2,()=>{});
			}
		);
	}
	// 关闭弹窗
	onCloseQuestion() {
		this.setState({dialogQuestionFlag: false,questionList:[]});
	}

	render() {
		return (
			<>
				<div className='sankey' id='sankey'></div>
				{this.state.dialogFlag ? (
					<div className='dialog_flag sankey_dialog'>
						<div className='dialog_main'>
							<div className='dialog_main_title'>
								<span>问答详情</span>
								<div onClick={this.onClose.bind(this)}>
									<img src={cur_icon_close} alt='' />
								</div>
							</div>
							<div className='dialog_main_content'>
								<Table
									dataSource={this.state.dataSource}
									columns={this.columns}
									scroll={{ y: 500 }}
									pagination={false}
								/>
							</div>
						</div>
					</div>
				) : (
					""
				)}
				{this.state.dialogQuestionFlag ? (
					<div className='dialog_flag question_dialog'>
						<div className='dialog_main'>
							<div className='dialog_main_title'>
								<span>问答详情</span>
								<div onClick={this.onCloseQuestion.bind(this)}>
									<img src={cur_icon_close} alt='' />
								</div>
							</div>
							<div className='dialog_main_content'>
								{this.state.questionList.map(item=>{
									return (
										<>
											<div className="question">{item.sort}.{item.question_name}</div>
											<div className="answer">{item.answer_name}</div>
										</>
									)
								})}
							</div>
							<div className='dialog_main_foot'></div>
						</div>
					</div>
				) : (
					""
				)}
			</>
		);
	}
}

export default sankey;
