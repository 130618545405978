import React, { Component } from 'react'

export default class Simple_question extends Component {
    constructor(props) {
        super(props)
        this.state = {
            answer: "",
            name: "",
            index: "",
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            answer: props.dataList.answer.value_arr[0],
            name: props.dataList.name,
            index: props.index + 1,
        };

    }
    render() {
        return (
            <div className="detail-card">
                <div className="detail-question-wrapper">
                    <div className="detail-question-sign">*</div>
                    <div className="detail-question-name">{`${this.state.index}. ${this.state.name}`}</div>
                </div>
                <div className="detail-answer-wrapper">
                    <div className="detail-answer">{this.state.answer}</div>
                </div>
            </div>
        )
    }
}
