import React, { Component } from 'react'
import './region_popup.scss';
import cur_icon_close_gre from "../../../../images/cur_icon_close_gre.png";
import cur_icon_next_pink from "../../../../images/cur_icon_next_pink.png";
import { message } from 'antd';

export default class region_pop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            region_list: [{ name: '', region: [{ name: '', is_show: false }], level_list: [] }],
            region_list_formal: [{ name: '', region: [{ name: '', is_show: false }] }],
            region_popup_items: [],
            region_popup_items_formal: [],
            region_select_index: 0,  //默认区域下标为0
            account_type: "1",
            region_obj: {},
            is_native: false
        }

    }
    setRegionList(tag_list, region_popup_items, account_type, is_native) {
        let region_list = [{ name: '全部', region: tag_list, level_list: [0, 1, 2] }]
        // if (account_type === '1') {
        //     region_list = [{ name: '全部', region: tag_list, level_list: [0, 1] }]
        // }
        let region_obj = {}

        tag_list.forEach((item, index) => {
            region_obj[item.value] = { ...item, ...{ is_show: false } }
            if (item.extras === "地区") {
                region_list.push({
                    name: item.value,
                    region: tag_list.filter((item2, index2) => {
                        return item.value === item2.extras || item.extras === "地区"
                    }),
                    level_list: [0, 1]
                    // level_list: account_type === '1' ? [0] : [0, 1]
                })
            }
        })
        // 初始化region_obj
        region_popup_items.forEach((item, index) => {
            region_obj[item]["is_show"] = true
        })

        this.setState({
            region_list: region_list, // 子组件进行修改后的
            region_obj: region_obj,// 地域obj
            region_popup_items: region_popup_items, // 已经勾选的
            region_popup_items_formal: region_popup_items,
            account_type: account_type,
            is_native: is_native
        })
    }
    // 获取分级地域
    get_region_level(index1) {
        return this.state.region_list[this.state.region_select_index].region.filter((item) => {
            if (this.state.region_list[this.state.region_select_index].name === "全部") {
                if (index1 === 0) {
                    return item.extras === "不限"
                } else if (index1 === 1) {
                    return item.extras === "地区"
                } else {
                    return item.extras === "" || (item.extras !== "地区" && item.extras !== "不限")
                }
            } else {
                if (index1 === 0) {
                    return item.extras === "地区" && item.value === this.state.region_list[this.state.region_select_index].name
                } else {
                    return item.extras === this.state.region_list[this.state.region_select_index].name
                }
            }
        })
    }
    // 选择地域
    handle_tag_click(level_index, key) {
        let region_obj = JSON.parse(JSON.stringify(this.state.region_obj))
        let region_index = this.state.region_select_index
        let flag = false
        let cur_value = region_obj[key].value
        let cur_extras = region_obj[key].extras

        if (this.state.account_type === '1' && this.state.is_native) { // 基础共创
            if (region_obj[key].extras !== "地区" && region_obj[key].extras !== "不限") {
                message.warning(`基础共创不可选择具体城市，请联系商务进行升级`, 3, () => { })
                return
            }
        }

        if (this.state.region_list[region_index].name === "全部") {
            if (level_index === 0) {// 之前华东or上海，现在选不限，华东or上海等要取消
                Object.values(region_obj).forEach((item) => {
                    if (item.is_show && item.value !== cur_value) {
                        flag = true
                        item.is_show = !item.is_show
                    }
                })
            } else if (level_index === 1) {
                for (let item of this.state.region_list[region_index].region) {
                    if (item.extras === cur_value && region_obj[item.value]?.is_show) {
                        region_obj[item.value].is_show = !region_obj[item.value].is_show
                        flag = true
                    }
                    if (item.value === "不限" && region_obj[item.value]?.is_show) {
                        region_obj[item.value].is_show = !region_obj[item.value].is_show
                        flag = true
                    }
                }
            } else {
                if (cur_extras && region_obj[cur_extras]?.is_show) { // 之前是华东，现在选上海，华东要取消
                    region_obj[cur_extras].is_show = !region_obj[cur_extras].is_show
                    flag = true
                }
                if (region_obj["不限"]?.is_show) {
                    region_obj["不限"].is_show = !region_obj["不限"].is_show
                    flag = true
                }
            }

        } else {
            if (level_index === 0) { // 之前是上海等，现在选华东，上海等要取消
                for (let item of this.state.region_list[region_index].region) {
                    if (item.extras === cur_value && region_obj[item.value]?.is_show) {
                        region_obj[item.value].is_show = !region_obj[item.value].is_show
                        flag = true
                    }
                }
            } else {
                if (region_obj[cur_extras]?.is_show) { // 之前是华东，现在选上海，华东要取消
                    region_obj[cur_extras].is_show = !region_obj[cur_extras].is_show
                    flag = true
                }
            }
        }

        if (flag) {
            message.warning(`注意地域标签发生变更，每个都为最小地域标签`, 3, () => { })
        }

        region_obj[key].is_show = !region_obj[key].is_show
        let region_popup_items = []
        Object.values(region_obj).forEach((item) => {
            if (item.is_show) {
                region_popup_items.push(item.value)
            }
        })
        this.setState({
            region_obj: region_obj,
            region_popup_items: region_popup_items
        })
    }
    tags_close() {
        this.setState({
            region_popup_items: [],
            region_popup_items_formal: [],
            region_list: [{ name: '', region: [{ name: '', is_show: false }], level_list: [] }],
            region_list_formal: [{ name: '', region: [{ name: '', is_show: false }], level_list: [] }],
        })
        this.props.get_region_popup_state({
            region_popup_items: this.state.region_popup_items_formal,
            region_popup_status: false,
            is_confirm: false
        })
    }
    tags_confirm() {
        let region_popup_items = [...this.state.region_popup_items]
        this.setState({
            region_popup_items: [],
            region_popup_items_formal: [],
            region_list: [{ name: '', region: [{ name: '', is_show: false }], level_list: [] }],
            region_list_formal: [{ name: '', region: [{ name: '', is_show: false }], level_list: [] }],
        })
        this.props.get_region_popup_state({
            region_popup_items,
            region_popup_status: false,
            is_confirm: true
        })
    }
    //设置选中的区域下标
    set_region_select_index(index) {
        this.setState({
            region_select_index: index
        })
    }
    render() {
        return (
            <div className='region-popup-container'>
                <div className='region-popup-bg'>
                    <div className='region-popup-main'>
                        <div className='region-popup-top'>
                            <div className='popup-top-title'>选择派送地址</div>
                            <div className='popup-delete-icon'>
                                <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                            </div>
                        </div>
                        <div className='region-popup-content'>
                            <div className='region-popup-content-left'>
                                {
                                    this.state.region_list.map((item, index) => {
                                        return (
                                            <div key={index} onClick={this.set_region_select_index.bind(this, index)} className={this.state.region_select_index === index ? 'content-left-btn content-left-select' : 'content-left-btn'}>
                                                <span key={index}>{item.name}</span>
                                                <img src={cur_icon_next_pink} className={this.state.region_select_index === index ? '' : 'isHide'} onClick={this.tags_close.bind(this)} alt="" />
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className='region-popup-content_right'>
                                {
                                    this.state.region_list[this.state.region_select_index]?.level_list.map((item, index) => {
                                        return (
                                            <div className='region-level' key={index}>
                                                {
                                                    this.get_region_level(index).map((item2, index2) => {
                                                        return <span key={index2} className={this.state.region_obj[item2.value].is_show ? 'region-right-select-item' : ''} onClick={this.handle_tag_click.bind(this, index, item2.value)} >{item2.value}</span>
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>

                        <div className='tag-btn'>
                            <div onClick={this.tags_confirm.bind(this)}>确认({this.state.region_popup_items.length})</div>
                            <div onClick={this.tags_close.bind(this)}>取消</div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
