import React, { Component } from 'react'
import "./proportion_template_info.scss"

export default class proportion_template_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: ' proportion_template_info',               //从哪个页面进入的
            dataList: {},
            answers: []
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                answers: props.dataList.answers || state.answers,
            }
        }
        return null;
    }
    componentDidMount() {
    }
    componentWillUnmount() {
        this.setState = () => { }
    }
    render() {
        return (
            <div className='proportion-template-info'>
                {/* <div className="proportion-info-top">
                    比重总和：100
                </div> */}
                <div className="detail-nps-answer">
                    {
                        this.state.answers.map((item, index) => {
                            return (
                                <div className="draw-slip" key={index}>
                                    <div className="draw-slip-left draw-slip-left-border">
                                        <div className='draw-slip-title'>
                                            <div className="edit-question-title-wrap">
                                                {item.name}
                                            </div>
                                            <div className={`${item.image.val ? "upload-question-pic" : "isHide"}`}>
                                                <img src={item.image.val} alt="" />
                                            </div>
                                        </div>
                                        <div className='draw-slip-top'>
                                            <div className='draw-slip-line'>
                                                <span className={`draw-slip-line_current_${index}`}></span>
                                            </div>
                                            <span className='draw-slip-num'>0</span>
                                        </div>
                                        <div className="draw-slip-index" >
                                            <span>0</span>
                                            <span>20</span>
                                            <span>40</span>
                                            <span>60</span>
                                            <span>80</span>
                                            <span>100</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div >
            </div>
        )
    }
}
