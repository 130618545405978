import React, { useState, useEffect } from "react";
import { Editor, Toolbar } from "@wangeditor/editor-for-react";
import { IDomEditor, IEditorConfig, IToolbarConfig } from "@wangeditor/editor";
import "@wangeditor/editor/dist/css/style.css";
import "./xm_editor.scss";

function XmEditor(props) {
  // editor 实例
  const [editor, setEditor] = useState(null);

  // 编辑器内容
  const [html, setHtml] = useState(props.html);

  // 模拟 ajax 请求，异步设置 html
  // useEffect(() => {
  //     setTimeout(() => {
  //         setHtml('<p>hello world</p>')
  //     }, 1500)
  // }, [editor])

  // 工具栏配置
  const toolbarConfig = {
    toolbarKeys: [
      // 菜单 key
      "headerSelect",
      "|", //标题
      "bold", // 加粗
      "underline", // 下划线
      "italic", // 斜体
      "color", // 文字颜色
      "bgColor", // 背景色
      "fontSize",
      "|", // 默认字号
      "undo", // 撤销
      "redo",
      "|", // 恢复
      "fullScreen",
      "|", // 全屏
      // 继续配置其他菜单...
    ],
  };

  // 编辑器配置
  const editorConfig = {
    placeholder: "请输入内容",
  };
  // const handleEditorChange = () => {
  //     console.log(123)
  //     props.handleEditorChange(html)
  // }

  // 及时销毁 editor ，重要！
  useEffect(() => {
    return () => {
      // handleEditorChange()
      if (editor == null) return;
      editor.destroy();
      setEditor(null);
    };
  }, [editor]);

  // 编辑器内容变化时，实时更新 html
  const handleChange = (editor) => {
    setHtml(editor.getHtml());
    props.handleEditorChange(editor.getHtml());
  };
  return (
    <>
      <div className="xm-editor-container">
        <Toolbar
          editor={editor}
          defaultConfig={toolbarConfig}
          mode="default"
          className="xm-editor-toolbar"
        />
        <Editor
          defaultConfig={editorConfig}
          value={props.html || html}
          onCreated={setEditor}
          // onChange={editor => setHtml(editor.getHtml())}
          onChange={handleChange}
          mode="simple"
          className="xm-editor"
        />
      </div>
    </>
  );
}
export default XmEditor;
