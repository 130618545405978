/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import "./survey_sankey.scss";
import Loading from "../../components/Loading/loading";
import XmSelect from "../../components/Select/xm_select";
import Sankey from "../../components/Sankey/sankey";

import { message } from "antd";

import util from "../../util/util";



import { getBrandSurvey } from "../../api/panelApi";
import {
	GetFollowUpSankeySession,
	GetFollowUpSankey,
	GetFollowUpSankeyWithCondition,
} from "../../api/sankeyApi/sankey";

class survey_sankey extends Component {
	constructor(props) {
		super(props);
		this.Sankey = React.createRef();
		this.state = {
			loading: false,
			survey_id: null,
			survey_list: [],
			sessions_id: null,
			sessions: [],
			metadata: null,
		};
	}
	//
	componentDidMount() {
		this.oGetSurveyList();
	}

	// 获取调研列表
	oGetSurveyList() {
		this.setState({ loading: true, loadingText: "加载调研信息中" });
		getBrandSurvey().then(
			(res) => {
				this.setState({ loading: false, loadingText: "" });
				if (res?.data?.length) {
					message.success("获取调研信息成功", 2, () => {});
					let survey_list = [];
					for (let i in res.data) {
						survey_list.push({
							key: res.data[i].id,
							value: res.data[i].name,
							is_sample: res.data[i]?.is_sample ? true : false,
						});
					}
					this.setState({ survey_list });
				} else {
					message.warning("暂无数据", 2, () => {});
					this.setState({ survey_list: [], sessions: [] });
				}
			},
			(err) => {
				console.log(err);
				this.setState({ loading: false, loadingText: "", survey_list: [], sessions: [] });
				message.warning("暂无数据", 2, () => {});
			}
		);
	}
	// 选择调研
	onSelectSurveyChange(e) {
		this.Sankey.current.clearSankey();
		this.setState({ survey_id: e,sessions_id: null, metadata: null }, () => {
			this.oGetSessionsData();
		});
	}
	// 获取sessions数据
	oGetSessionsData() {
		let params = {
			survey_id: this.state.survey_id,
		};
		this.setState({ loading: true });
		GetFollowUpSankeySession(params).then(
			(res) => {
				this.setState({ loading: false });
				if (res?.data?.sessions?.length) {
					let sessions = [];
					for (let i in res.data.sessions) {
						if(!res.data.sessions[i].parent_id){
							sessions.push({
								key: res.data.sessions[i].id,
								value: res.data.sessions[i].session_name,
							});
						}
					}
					this.setState({ sessions });
				} else {
					this.setState({ sessions: [] });
					message.warning("暂无数据", 2, () => {});
				}
			},
			(err) => {
				this.setState({ loading: false, sessions: [] });
				message.warning("暂无数据", 2, () => {});
			}
		);
	}
	// 选择sessions
	onSelectSessionsChange(e) {
		this.Sankey.current.clearSankey();
		this.setState({ sessions_id: e, metadata: null }, () => {
			this.oGetSankeyData();
		});
	}
	// 获取sankey数据
	oGetSankeyData() {
		let params = {
			survey_id: this.state.survey_id,
			session_id: this.state.sessions_id,
		};
		this.setState({ loading: true });
		GetFollowUpSankey(params).then(
			(res) => {
				this.setState({ loading: false });
				if (res?.data?.session?.length) {
					let session = res.data.session;
					session = this.replenishData(session, 0, '');
					let links = this.constructSankeyLinks(session,0);
					let metadata = {origination:links};
					this.setState({metadata});
					this.Sankey.current.constructMetaData(metadata);
				} else {
					message.warning("暂无数据", 2, () => {});
				}
			},
			(err) => {
				this.setState({ loading: false });
				message.warning("暂无数据", 2, () => {});
			}
		);
	}
	// 获取后续sankey数据
	oGetNextSankeyData(e) {
		let data = e.data;
		let level = (data.depth + 1) / 2;
		let answer_category = data.targetName;
		if(!answer_category || answer_category == '-') return;

		for(let i in data.sourceName){
			let params = {
				session_id: this.state.sessions_id,
				survey_id: this.state.survey_id,
				uuid: '',
				level,
				question_category:data.sourceName[i],
				answer_category,
			}
			GetFollowUpSankeyWithCondition(params).then(
				(res) => {
					if (res?.data?.session?.length) {
						let session = res.data.session;
						let metadata = this.state.metadata;
						let paramsStr = JSON.stringify(params);
						if(metadata[paramsStr]) return;

						session = this.replenishData(session, data.depth + 1, answer_category);
						let links = this.constructSankeyLinks(session,data.depth + 1);
						links.push(...this.constructSankeyPrelinks(session,data.name,data.depth))
						metadata[paramsStr] = links;
						this.setState({metadata});
						this.Sankey.current.constructMetaData(metadata);
					} else {
						
					}
				},
				(err) => {
					console.log(err);
				}
			);
		}
	}
	// 将原始数据补充完整
	replenishData(list, depth, str){
		// 关键词补充  
		for(let i in list){
			if(!list[i]?.question_categories?.length) list[i].question_categories = [];
			if(!list[i]?.answer_categories?.length) list[i].answer_categories = [];
			// 去重
			list[i].answer_categories = util.unique(list[i].answer_categories);
			list[i].question_categories = util.unique(list[i].question_categories);
			// 拷贝原始数据
			list[i].ori_question_categories = [...list[i].question_categories];
			list[i].ori_answer_categories = [...list[i].answer_categories];
			// 数据重构
			for(let n in list[i].question_categories){
				if(list[i].question_categories[n] === '-') list[i].question_categories[n] = `${str}-Q`
				list[i].question_categories[n] = `${depth + 1}:${list[i].question_categories[n]}`
			}
			for(let n in list[i].answer_categories){
				if(list[i].answer_categories[n] === '-') list[i].answer_categories[n] = `${str}-A`
				list[i].answer_categories[n] = `${depth + 2}:${list[i].answer_categories[n]}`
			}
			// 记录特殊名
			list[i].alias = str;
		}
		return list;
	}
	// 处理sankey边数据(组内)
	constructSankeyLinks(list,depth) {
		let links = [];
		let linksMap = {};
		for(let i in list){
			for(let k in list[i].question_categories){
				for(let n in list[i].answer_categories){
					let source = list[i].question_categories[k];
					let target = list[i].answer_categories[n];
					if(linksMap[`${source}$_$${target}`]){
						linksMap[`${source}$_$${target}`].value += 1;
						linksMap[`${source}$_$${target}`].questionList.push(list[i]);
					}else{
						linksMap[`${source}$_$${target}`] = {
							source: source,
							target: target,
							sourceName: list[i].ori_question_categories[k],
							targetName: list[i].ori_answer_categories[n],
							value: 1,
							questionList: [list[i]],
							type: 'group',
							depth,
						}
					}
				}
			}
		}
		for(let i in linksMap){
			linksMap[i].showValue = linksMap[i].value;
			links.push(linksMap[i])
		}
		return links;
	}
	// 处理sankey边数据(组间)
	constructSankeyPrelinks(list,name,depth) {
		let prelinks = [];
		let linksMap = {};
		for(let i in list){
			for(let n in list[i].question_categories){
				let source = name;
				let target = list[i].question_categories[n];
				if(linksMap[`${source}$_$${target}`]){
					linksMap[`${source}$_$${target}`].value += 1;
				}else{
					linksMap[`${source}$_$${target}`] = {
						source: source,
						target: target,
						questionList: [],
						value: 1,
						type: 'link',
						depth,
					}
				}
			}
			
		}
		for(let i in linksMap){
			linksMap[i].showValue = linksMap[i].value;
			prelinks.push(linksMap[i])
		}
		return prelinks;
	}

	render() {
		return (
			<div className='container sankey-container'>
				{this.state.loading ? <Loading></Loading> : ""}
				<div className='sankey-main'>
					<div className='sankey-main-top'>
						<div className='sankey-main-top-title'>调研主题</div>
						<div className='sankey-main-top-select'>
							<XmSelect
								showSearch
								value={this.state.survey_id || null}
								options={this.state.survey_list}
								onChange={this.onSelectSurveyChange.bind(this)}></XmSelect>
						</div>
						<div className='sankey-main-top-title'>调研主题</div>
						<div className='sankey-main-top-select'>
							<XmSelect
								showSearch
								value={this.state.sessions_id || null}
								options={this.state.sessions}
								onChange={this.onSelectSessionsChange.bind(this)}></XmSelect>
						</div>
					</div>
					<div className='sankey-container'>
						<Sankey ref={this.Sankey} metadata={this.state.metadata} nextSankey={this.oGetNextSankeyData.bind(this)}></Sankey>
					</div>
				</div>
			</div>
		);
	}
}
export default connect(
	(state) => ({
		user: state.user,
	}), //映射状态
	{} //映射操作状态的方法
)(survey_sankey);
