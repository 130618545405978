/** @format */

import React, { Component } from "react";
import { connect } from "react-redux";
import "./question_logic.scss";
import LogicCard from "./Logic_card/logic_card";
import Loading from "../../../Loading/loading";
import BlankLogic from "./Logic_card/Blank_condition/blank_condition";
import cur_icon_back from "../../../../images/cur_icon_back.png";
import project_icon_full from "../../../../images/project_icon_full.png";
import project_icon_add from "../../../../images/project_icon_add.png";
import project_icon_del from "../../../../images/project_icon_del.png";
import {
  saveProjectSurveyLogic,
  getProjectSurveyLogic,
  updateProjectSurveyLogic,
} from "../../../../api/Questionnaire_setting/question_logic";
import {
  questionType,
  getQuestionTypeValue,
  getQuestionType,
  getQuestionTypeName,
} from "../Question_rules/question_type_rule";
import { question_authority_obj } from "../question_authority_configuration";
import { account_type_obj } from "../../../../pages/User_info/Values/user_data";
import { message } from "antd";
let that = "";
class question_logic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 0, //获取中 getProjectSurveyLogic接口
      loading_save: 0, // 保存  saveProjectSurveyLogic和updateProjectSurveyLogic接口
      loadingText: "加载中",
      question_list: [],
      question_list_logic_formal: [],
      question_list_logic: [
        {
          question_id: 4,
          question_type: 12,
          logic_settings: [
            {
              condition_settings: {
                op: 1,
                condition: [
                  {
                    question_skip_option_id: 1,
                    option: {
                      answer_id: 1,
                      value: "",
                    },
                    judge: 0,
                  },
                ],
              },
              result_settings: [
                {
                  question_id: 4,
                  judge: 0,
                  score: "",
                  id: "",
                },
              ],
              question_skip_id: "",
            },
          ],
        },
        {
          question_id: 5,
          question_type: 13,
          logic_settings: [
            {
              condition_settings: {
                op: 1,
                condition: [
                  {
                    question_skip_option_id: 1,
                    option: {
                      answer_id: 1,
                      value: "",
                    },
                    judge: 0,
                  },
                ],
              },
              result_settings: [
                {
                  question_id: 4,
                  judge: 0,
                  score: "",
                  id: "",
                },
              ],
              question_skip_id: "",
            },
          ],
        },
        {
          question_id: 6,
          question_type: 11,
          logic_settings: [
            {
              condition_settings: {
                op: 1,
                condition: [
                  {
                    question_skip_option_id: 1,
                    option: {
                      answer_id: 1,
                      value: "",
                    },
                    judge: 0,
                  },
                ],
              },
              result_settings: [
                {
                  question_id: 4,
                  judge: 0,
                  score: "",
                  id: "",
                },
              ],
              question_skip_id: "",
            },
          ],
        },
      ],
      // question_list_logic: [],
      survey_id: "",
      showQuestionInfo: true,
      canPrev: false,
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props.showQuestionInfo) !==
      JSON.stringify(state.showQuestionInfo_prev)
    ) {
      return {
        needRefresh: true,
        question_list: props.question_list || [],

        survey_id: props.survey_id,
        showQuestionInfo: props.showQuestionInfo,

        showQuestionInfo_prev: props.showQuestionInfo,
      };
    }
    return null;
  }
  componentDidMount() {
    that = this;
    this._isMounted = true;
    // 防止新增问答的时候，子页面的自动渲染
    // 然后找不到.logic_settings
    this.initData();
  }
  componentDidUpdate() {
    if (this._isMounted && this.state.needRefresh) {
      this.setData();
      this.setState({
        needRefresh: false,
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    this.setState = () => { };
  }
  // 判断两个对象是否相等
  isObjectValueEqual(obj1, obj2) {
    if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
      return true;
    }
    return false;
  }
  // 设置initial
  setData() {
    this.getProjectSurveyLogic();
  }
  // 如果之前只保存了一个，现在有3个，给那一个渲染上后端传的，其他的初始化
  filterFormalLogicSetting(question_id, question_list_logic_formal) {
    return question_list_logic_formal.filter((element) => {
      return element.question_id == question_id;
    });
  }
  // 初始化从后端拿到的
  initFormalLogic(question_list_logic_formal) {
    let question_list_logic = [];
    for (let i = 0; i < question_list_logic_formal.length; i++) {
      let tmp = {
        question_id: question_list_logic_formal[i].question_id,
        question_type: this.state.question_list[i].question_type,
        logic_settings: question_list_logic_formal[i].logic_settings,
      };
      question_list_logic.push(tmp);
    }
    this.setState({
      question_list_logic_formal: question_list_logic,
    });
  }
  setFormalData(question_list_logic_formal) {
    let question_list_logic = [];
    this.initFormalLogic(question_list_logic_formal);
    for (let i = 0; i < this.state.question_list.length; i++) {
      let tmp2 = this.filterFormalLogicSetting(
        this.state.question_list[i].question_id,
        question_list_logic_formal
      );
      if (tmp2.length) {
        let tmp = {
          question_id: this.state.question_list[i].question_id,
          question_type: this.state.question_list[i].question_type,
          logic_settings: tmp2[0].logic_settings, // 因为过滤为id是个数组
          // logic_settings: this.filterFormalLogicSetting(question_id, question_list_logic_formal)
        };
        question_list_logic.push(tmp);
      } else {
        let tmp = this.initDataObj(i);
        question_list_logic.push(tmp);
      }
    }
    this.setState({
      question_list_logic: question_list_logic,
    });
  }
  // 获取问卷逻辑
  async getProjectSurveyLogic() {
    let survey_id = this.state.survey_id;
    if (!survey_id) {
      //!!!!!!!!!!!因为这里没有获取到survey_id
      return;
    }
    this.setState({
      loading: 1,
      loadingText: "加载中",
    });
    try {
      const response = await getProjectSurveyLogic({
        survey_id: survey_id,
        // survey_id: 1187
      });
      this.setState({
        loading: 0,
      });
      if (
        this.props.is_native &&
        question_authority_obj.rule_5[this.props.user.account_type]
      ) {
        message.warning(
          `您的账号类型为${account_type_obj[this.props.user.account_type]
          }，不可以使用问题逻辑，请联系商务进行升级`,
          2,
          () => { }
        );
      }
      if (response.status === 204) {
        this.initData();
      } else if (response.status === 200) {
        this.setFormalData(response.data.question_list);
        this.setState({ survey_id: response.data.survey_id });
      }
    } catch (error) {
      this.setState({
        loading: 0,
      });
      message.error("获取问卷逻辑后台发生错误");
      throw error;
    }
  }
  // 初始化ConditionAnswerId  因为就一个选项 所以answerid是固定的对于nps blank score
  initConditionAnswerId(question_index, question_type) {
    let question_type_name = getQuestionType(
      this.state.question_list[question_index].question_type
    );
    if (
      question_type_name === "nps" ||
      question_type_name === "blank" ||
      question_type_name === "score"
    ) {
      if (
        this.state.question_list[question_index].hasOwnProperty("answers") &&
        this.state.question_list[question_index].answers.length
      ) {
        if (
          this.state.question_list[question_index].answers[0].hasOwnProperty(
            "option_id"
          )
        ) {
          let option_id =
            this.state.question_list[question_index].answers[0].option_id;
          return option_id;
        }
      }
    }
    return "";
  }
  // 初始化data
  initData() {
    let question_list_logic = [];
    for (let i = 0; i < this.state.question_list.length; i++) {
      if (i === 0) {
        if (
          localStorage.getItem("current_project_type") === "1" &&
          !this.props.showDefaultLogic
        ) {
          let tmp = this.initFirstDataObj_1(i);
          question_list_logic.push(tmp);
        } else {
          let tmp = this.initDataObj(i);
          question_list_logic.push(tmp);
        }
      } else {
        let tmp = this.initDataObj(i);
        question_list_logic.push(tmp);
      }
    }
    this.setState({
      question_list_logic_formal: [],
      question_list_logic: question_list_logic,
    });
  }
  // 问卷派送
  initFirstDataObj_1(question_index) {
    // let logic_setting_obj_1 = {
    //   condition_settings: {
    //     op: 0,
    //     condition: [
    //       {
    //         judge: 1,  // 选中“种草”跳转到第二题
    //         option: {
    //           answer_id: this.state.question_list[0].answers[0].option_id,
    //           value: "",
    //         },
    //         question_skip_option_id: "",
    //       },
    //     ],
    //   },
    //   result_settings: [
    //     {
    //       judge: 1,
    //       question_id:
    //         this.state.question_list[1] &&
    //           this.state.question_list[1].hasOwnProperty("question_id")
    //           ? this.state.question_list[1].question_id
    //           : "",
    //       score: "",
    //       id: "",
    //     },
    //   ],
    //   question_skip_id: "",
    // };
    let logic_setting_obj_2 = {
      condition_settings: {
        op: 0,
        condition: [
          {
            judge: 2, // 不选择“种草”跳转到第三题
            option: {
              answer_id: this.state.question_list[0].answers[0].option_id,
              value: "",
            },
            question_skip_option_id: "",
          },
        ],
      },
      result_settings: [
        {
          judge: 1,
          question_id:
            this.state.question_list[2] &&
              this.state.question_list[2].hasOwnProperty("question_id")
              ? this.state.question_list[2].question_id
              : "",
          score: "",
          id: "",
        },
      ],
      question_skip_id: "",
    };
    let logic_settings = [];
    // logic_settings.push(logic_setting_obj_1);
    logic_settings.push(logic_setting_obj_2);
    let tmp = {
      question_id: this.state.question_list[question_index].question_id,
      question_type: this.state.question_list[question_index].question_type,
      logic_settings: logic_settings,
    };
    return tmp;
  }
  initDataObj(question_index) {
    let logic_setting_obj = {
      condition_settings: {
        op: 0,
        condition: [
          {
            judge: "",
            option: {
              answer_id: this.initConditionAnswerId(question_index),
              // answer_id: "",
              value: "",
            },
            question_skip_option_id: "",
          },
        ],
      },
      result_settings: [{ judge: "", question_id: "", score: "", id: "" }],
      question_skip_id: "",
    };
    let logic_settings = [];
    logic_settings.push(logic_setting_obj);
    let tmp = {
      question_id: this.state.question_list[question_index].question_id,
      question_type: this.state.question_list[question_index].question_type,
      logic_settings: logic_settings,
    };
    return tmp;
  }
  async prev() {
    //上一步
    this.setState({
      project_entry_number: 3,
    });
    this.props.handleQuestionInfo({
      project_entry_number: 3,
    });
  }
  async saveProjectSurveyLogic() {
    let survey_id = this.state.survey_id;
    let question_list_logic = this.state.question_list_logic;
    if (!survey_id) {
      return;
    }
    this.setState({
      loading_save: 1,
      loadingText: "保存中，请耐心等待",
    });
    try {
      const response = await saveProjectSurveyLogic({
        survey_id: survey_id,
        question_list: question_list_logic,
      });
      this.setState({ loading_save: 0 });
      if (response.status === 201) {
        this.setState({ canPrev: true });
        message.success("保存成功", 0.7, () => { });

        this.setState({
          project_entry_number: 3,
        });
        this.props.handleQuestionInfo({
          project_entry_number: 3,
        });
      }
    } catch (error) {
      this.setState({ canPrev: false, loading_save: 0 });
      message.error("保存失败，请检查是否填写有误!");
      throw error;
    }
  }
  async updateProjectSurveyLogic() {
    let survey_id = this.state.survey_id;
    let question_list_logic = this.state.question_list_logic;
    if (!survey_id) {
      return;
    }
    this.setState({
      loading_save: 1,
      loadingText: "更新中，请耐心等待",
    });
    try {
      const response = await updateProjectSurveyLogic({
        survey_id: survey_id,
        // survey_id: 558,
        question_list: question_list_logic,
      });
      if (response.status === 201 || response.status === 202) {
        this.setState({ canPrev: true });
        message.success("保存成功", 0.7, () => { });

        this.setState({
          project_entry_number: 3,
        });
        this.props.handleQuestionInfo({
          project_entry_number: 3,
        });
      }
      this.setState({
        loading_save: 0,
      });
    } catch (error) {
      this.setState({ canPrev: false, loading_save: 0 });
      message.error("保存失败，请检查是否有未填写!");
      throw error;
    }
  }
  async accomplish() {
    //完成
    if (
      this.props.is_native &&
      question_authority_obj.rule_5[this.props.user.account_type]
    ) {
      message.warning(
        `您的账号类型为${account_type_obj[this.props.user.account_type]
        }，不可以使用问题逻辑，请联系商务进行升级`,
        2,
        () => { }
      );
      return;
    }
    let tmpList = this.state.question_list_logic;
    let isObjectValueEqual = this.isObjectValueEqual(
      tmpList,
      this.state.question_list_logic_formal
    );

    if (!this.state.question_list_logic_formal.length) {
      //如果没有survey_id，按完成 或 上一步 ，会调保存问卷信息的接口
      await this.saveProjectSurveyLogic();
    } else if (
      this.state.question_list_logic_formal.length &&
      !isObjectValueEqual
    ) {
      //如果有survey_id  且列表发生变化 ，按完成 或 上一步 ，会调更新问卷信息的接口
      await this.updateProjectSurveyLogic();
    } else {
    }
  }
  // 如果添加
  handleAddCondition(index1, index2, question_index, question_type) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    let tmp = {
      question_skip_option_id: "",
      option: {
        answer_id: this.initConditionAnswerId(question_index),
        value: "",
      },
      judge: "",
    };
    question_list_logic[question_index].logic_settings[
      index1
    ].condition_settings.condition.splice(index2 + 1, 0, tmp);
    this.setState({ question_list_logic: question_list_logic });
  }
  // 否则添加
  handleAddResult(index1, index2, question_index) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    let tmp = { judge: "", question_id: "", score: "", id: "" };
    question_list_logic[question_index].logic_settings[
      index1
    ].result_settings.splice(index2 + 1, 0, tmp);
    this.setState({ question_list_logic: question_list_logic });
  }
  // 如果删除
  handleDeleteCondition(index1, index2, question_index, question_type) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings[
      index1
    ].condition_settings.condition.splice(index2, 1);
    this.setState({ question_list_logic: question_list_logic });
  }
  // 否则删除
  handleDeleteResult(index1, index2, question_index) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings[
      index1
    ].result_settings.splice(index2, 1);
    this.setState({ question_list_logic: question_list_logic });
  }
  // 整个如果否则删除
  handleDeleteSetting(index1, index2, question_index, question_type) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings.splice(index1, 1);
    this.setState({ question_list_logic: question_list_logic });
  }
  // 整个如果否则添加
  handleAddSetting(question_index, question_type) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    let logic_setting_obj = {
      condition_settings: {
        op: 0,
        condition: [
          {
            judge: "",
            option: {
              answer_id: this.initConditionAnswerId(question_index),
              value: "",
            },
            question_skip_option_id: "",
          },
        ],
      },
      result_settings: [{ judge: "", question_id: "", score: "", id: "" }],
      question_skip_id: "",
    };
    question_list_logic[question_index].logic_settings.push(logic_setting_obj);
    this.setState({
      question_list_logic: question_list_logic,
    });
  }
  handleClearSetting(question_index, question_type) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings = [];
    this.setState({
      question_list_logic: question_list_logic,
    });
  }

  //设置如果的且or或
  handleConditionOp(index, question_index) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    let tmp =
      question_list_logic[question_index].logic_settings[index]
        .condition_settings.op;
    question_list_logic[question_index].logic_settings[
      index
    ].condition_settings.op = 1 - tmp;
    this.setState({ question_list_logic: question_list_logic });
  }
  // 否则第2个select
  handleResultOptionChange(key, index2, index1, question_index) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings[index1].result_settings[
      index2
    ].question_id = key;
    this.setState({ question_list_logic: question_list_logic });
  }
  // 否则第1个
  handleResultJudgeChange(key, index2, index1, question_index) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings[index1].result_settings[
      index2
    ].judge = key;
    if (key === 7) {
      //向下顺延
      question_list_logic[question_index].logic_settings[
        index1
      ].result_settings[index2].question_id = "";
      question_list_logic[question_index].logic_settings[
        index1
      ].result_settings[index2].score = "";
    }
    this.setState({ question_list_logic: question_list_logic });
  }
  // 否则第2个input
  handleResultInputChange(value, index2, index1, question_index) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings[index1].result_settings[
      index2
    ].score = value;
    this.setState({ question_list_logic: question_list_logic });
  }
  // 如果第2个
  handleConditionJudgeChange(key, index2, index1, question_index) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings[
      index1
    ].condition_settings.condition[index2].judge = key;
    if (key === 8) {
      // 特殊人群
      question_list_logic[question_index].logic_settings[
        index1
      ].condition_settings.condition[index2].option.answer_id = "";
      question_list_logic[question_index].logic_settings[
        index1
      ].condition_settings.condition[index2].option.value = "";
    }

    if (
      getQuestionType(question_list_logic[question_index].question_type) ===
      "blank" ||
      getQuestionType(question_list_logic[question_index].question_type) ===
      "enumeration"
    ) {
      question_list_logic[question_index].logic_settings[
        index1
      ].condition_settings.condition[index2].option.value = "";
    } else if (
      getQuestionType(question_list_logic[question_index].question_type) ===
      "matrix_blank"
    ) {
      if (
        question_list_logic[question_index].logic_settings[index1]
          .condition_settings.condition[index2].option.value
      ) {
        let json_option_id = JSON.parse(
          question_list_logic[question_index].logic_settings[index1]
            .condition_settings.condition[index2].option.value
        ).optionId2;
        let json_obj = JSON.stringify({ optionId2: json_option_id, value: "" });
        question_list_logic[question_index].logic_settings[
          index1
        ].condition_settings.condition[index2].option.value = json_obj;
      }
    } else if (
      getQuestionType(question_list_logic[question_index].question_type) ===
      "heatmap"
    ) {
      if (key === 2 || key === 1) {
        question_list_logic[question_index].logic_settings[
          index1
        ].condition_settings.condition[index2].option.value = "";
      }
    }
    this.setState({ question_list_logic: question_list_logic });
  }
  // 如果第3个
  handleConditionOptionChange(answer_id, index2, index1, question_index) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings[
      index1
    ].condition_settings.condition[index2].option.answer_id = answer_id;

    this.setState({ question_list_logic: question_list_logic });
  }
  // 如果第3个
  handleConditionOptionChange2(value, index2, index1, question_index) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings[
      index1
    ].condition_settings.condition[index2].option.value = value;
    this.setState({ question_list_logic: question_list_logic });
  }
  // 如果第3个 sort
  handleConditionSortOptionChange(value, index2, index1, question_index) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings[
      index1
    ].condition_settings.condition[index2].option.value = value;

    this.setState({ question_list_logic: question_list_logic });
  }
  // 如果第3个
  handleConditionInputChange(value, index2, index1, question_index) {
    let question_list_logic = JSON.parse(
      JSON.stringify(this.state.question_list_logic)
    );
    question_list_logic[question_index].logic_settings[
      index1
    ].condition_settings.condition[index2].option.value = value;
    this.setState({ question_list_logic: question_list_logic });
  }
  //是否可以下一步标红
  canNext() {
    let flag = false;

    return flag;
  }
  //最后一道题目不可以有右上方的东秀
  canShowQuestionAction(curIndex) {
    let flag = true;
    let questionNum = this.state.question_list.length;
    if (curIndex === questionNum - 1) {
      flag = false;
    }
    return flag;
  }
  render() {
    return (
      <div className="logic_contain">
        {this.state.loading <= 0 && this.state.loading_save <= 0 ? (
          ""
        ) : (
          <Loading loadingText={this.state.loadingText}></Loading>
        )}
        <div className="logic_top">
          <img src={cur_icon_back} alt="" onClick={this.prev.bind(this)} />
          <span>调研问卷设置</span>
        </div>
        <div className="logic_contain_main">
          <div className="logic_left">
            <div className="logic-left-margin-left">
              {this.state.question_list.map((item, index) => {
                return (
                  <div
                    className={`logic-item ${item.is_question_active === 0 ? "logic-item-2" : ""
                      }`}
                    key={index}
                  >
                    <div className="logic-item-top">
                      <span>
                        <span className="logic-item-top-num">{index + 1}.</span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `${getQuestionTypeName(
                              getQuestionType(item.question_type)
                            )}-${item.name || ""}`,
                          }}
                        ></span>
                        {item.is_question_active === 0 ? (
                          <span className="is-question-active">
                            （该题目已设置为下架，不可编辑）
                          </span>
                        ) : (
                          ""
                        )}
                      </span>
                      <div
                        className={
                          this.canShowQuestionAction.call(this, index)
                            ? "logic-item-top-icon"
                            : "isHide"
                        }
                      >
                        <img
                          src={project_icon_add}
                          alt=""
                          onClick={this.handleAddSetting.bind(
                            this,
                            index,
                            item.question_type
                          )}
                        />
                        <img
                          src={project_icon_del}
                          alt=""
                          onClick={this.handleClearSetting.bind(
                            this,
                            index,
                            item.question_type
                          )}
                        />
                      </div>
                    </div>
                    <div className="logic-settings-container">
                      <LogicCard
                        current_question_index={index}
                        question_list={this.state.question_list}
                        question_list_logic={this.state.question_list_logic}
                        handleConditionOp={this.handleConditionOp.bind(this)}
                        handleConditionJudgeChange={this.handleConditionJudgeChange.bind(
                          this
                        )}
                        handleConditionInputChange={this.handleConditionInputChange.bind(
                          this
                        )}
                        handleDeleteCondition={this.handleDeleteCondition.bind(
                          this
                        )}
                        handleAddCondition={this.handleAddCondition.bind(this)}
                        handleDeleteSetting={this.handleDeleteSetting.bind(
                          this
                        )}
                        handleResultJudgeChange={this.handleResultJudgeChange.bind(
                          this
                        )}
                        handleResultOptionChange={this.handleResultOptionChange.bind(
                          this
                        )}
                        handleResultInputChange={this.handleResultInputChange.bind(
                          this
                        )}
                        handleDeleteResult={this.handleDeleteResult.bind(this)}
                        handleAddResult={this.handleAddResult.bind(this)}
                        handleConditionOptionChange={this.handleConditionOptionChange.bind(
                          this
                        )}
                        handleConditionOptionChange2={this.handleConditionOptionChange2.bind(
                          this
                        )}
                        handleConditionSortOptionChange={this.handleConditionSortOptionChange.bind(
                          this
                        )}
                      ></LogicCard>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="logic_right">
            <div className="contain_modules_title">
              <div className="name">
                <span className="line"></span>
                <span className="text">逻辑预览</span>
              </div>
              <div className="full_screen">
                <img src={project_icon_full} alt="" />
                {/*<span>退出全屏</span>*/}
              </div>
            </div>
            <div className="logic-right-tip">敬请期待～</div>
          </div>
        </div>
        <div className="operaion-row">
          <div
            onClick={this.accomplish.bind(this)}
            className={this.canNext() ? "next-active" : ""}
          >
            完成
          </div>
          <div onClick={this.prev.bind(this)}>上一步</div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }), //映射状态
  {} //映射操作状态的方法
)(question_logic);
