
import React, { Component } from 'react'
import { connect } from 'react-redux'
import './TagDispatch.scss';
import AddTags from './AddTags'
import AgeAreaPopup from './Popup/Age_area_popup'
import RegionPopup from './Popup/region_popup'
import XmSelect from '../../../components/Select/xm_select';
import Loading from "../../../components/Loading/loading"
import project_icon_bqps from '../../../images/project_icon_bqps.png'
import cur_icon_choice_sel from '../../../images/cur_icon_choice_sel.png'
import cur_icon_choice_nor from '../../../images/cur_icon_choice_nor.png'
import project_icon_del from '../../../images/project_icon_del.png'
import cur_icon_and_pink from '../../../images/cur_icon_and_pink.png'
import cur_icon_delete from '../../../images/cur_icon_delete.png'
import cur_icon_add from '../../../images/cur_icon_add.png'
import icon_text_yes from '../../../images/project_entry/tag_dispatch/icon_text_yes.png'
import icon_text_no from '../../../images/project_entry/tag_dispatch/icon_text_no.png'
import icon_percent from '../../../images/project_entry/tag_dispatch/icon_percent.png'
import icon_percent_nor from '../../../images/project_entry/tag_dispatch/icon_percent_nor.png'
import cur_icon_select from "../../../images/cur_icon_select.png";
import cur_icon_or_white from '../../../images/cur_icon_or_white.png'
import Toast from "../../Toast/Toast";
import UserAPI from "../../../api/UserAPI";
import { getProjectTA, getTagCategory, getTags, saveProjectTA, updateProjectTA } from '../../../api/Project/tag_dispatch_api'
import { message } from 'antd';

let that = ''
class TagDispatch extends Component {
    constructor(props) {
        super(props);
        this.tagsRef = React.createRef();
        this.regionRef = React.createRef();
        this.state = {
            loading: 0, //加载产品中 GetProduct接口
            loading_tag_category: 0, // 加载品牌 getBrand接口
            loading_save: 0,
            loadingText: "加载中",
            product_id: localStorage.getItem('product_id') ? localStorage.getItem('product_id') : '',    //产品ID
            brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : 23,   //品牌ID
            project_id: localStorage.getItem('project_id') ? localStorage.getItem('project_id') : '',
            project_entry_number: 0,     //第几步
            radio_number: 1,              //1:标签派送 2：随机派送
            AddTags_status: false,            //标签组件是否显示
            tree_data: [],          // 树数据
            tem_tree: {},
            throng_list: [],                   //人群的集合
            throng_list_index: 0,              //人群的下标
            tag_is_show_number: false,           //目前展示标签的数量
            region_popup_status: false,          //地域的弹框是否展示
            region_popup_index: 0,               //地域的下标
            region_popup_index2: 0,
            region_popup_id: '',                //地域ID
            rule: true,                   //外部的（且或）
            hasFilledTag: false,//是否是第一次进入，没有填写过tag false 没有，true 填写过,
            age_area_popup_flag: 0, //0 为添加，1为修改
            age_list: [
                { key: "不限", value: "不限", disabled: false },
                { key: '0-18', value: '0-18', disabled: false },
                { key: '19-22', value: '19-22', disabled: false },
                { key: '23-27', value: '23-27', disabled: false },
                { key: '28-35', value: '28-35', disabled: false },
                { key: '35+', value: '35+', disabled: false }
            ],
            age_list_1: [
                { key: "不限", value: "不限", disabled: false },
                { key: '16-25', value: '16-25', disabled: false },
                { key: '26-35', value: '26-35', disabled: false },
                { key: '35+', value: '35+', disabled: false }
            ],
            selected_age_key_list: [],
            selected_age_list: [],
            is_native: false,//0 老问卷后台 1新问卷cem
            tag_category_list_required: [],//必填的tag
        }

    }
    componentDidMount() {
        this._isMounted = true
        that = this
        this.setState({
            project_entry_number: this.props.project_entry_number
        })
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.project_entry_number) !== JSON.stringify(state.project_entry_number_prev)) {
            return {
                needRefresh: true,
                project_entry_number: props.project_entry_number || 0, //question
                project_entry_number_prev: props.project_entry_number,
            }
        }
        return null;
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            if (this.state.project_entry_number === 2) {
                this.init()
            }
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    // 初始化 获取所有的标签
    init() {
        // 获取标签
        this.getTagCategory()
    }
    // 获取标签
    async getTagCategory() {
        let project_id = localStorage.getItem('project_id') || ""
        let tag_list = []
        let current_project_type = localStorage.getItem('current_project_type') || ""
        let special_event_id = localStorage.getItem('special_event_id') ? localStorage.getItem('special_event_id') : ""
        let tag_category_list_required = []
        try {
            const response = await getTagCategory({
                project_id: project_id,
                type: current_project_type, // 冗余
                project_type: current_project_type,
                special_event_id: special_event_id
                // project_id: '946',
                // type: '1', // 冗余
                // project_type: '1',
                // special_event_id: special_event_id
            })
            let is_native = response.data["is_native"] ? response.data.is_native : false
            let tag_category_list = this.handle_tag_list(JSON.parse(JSON.stringify(response.data.tag_category)))
            for (let i = 0; i < tag_category_list.length; i++) {
                tag_category_list[i].rule = true // 子标签组的且或 true-或 false-且
                if (tag_category_list[i].name === "性别") {
                    tag_category_list[i].type = 1
                    tag_category_list[i].tag_flag = true // 设置为必选
                    tag_category_list[i].tag_list.map(item => { //设置为disabled
                        item.key = item.id
                        item.disabled = false
                        return item
                    })
                    tag_category_list_required.push(tag_category_list[i])
                } else if (tag_category_list[i].name === "年龄") {
                    tag_category_list[i].type = 2
                    tag_category_list[i].tag_flag = true
                    if (this.props.user.account_type === "1" && this.state.is_native) {
                        tag_category_list[i].tag_list = JSON.parse(JSON.stringify(this.state.age_list_1)) // id 和 value
                    } else {
                        tag_category_list[i].tag_list = JSON.parse(JSON.stringify(this.state.age_list)) // id 和 value
                    }
                    tag_category_list_required.push(tag_category_list[i])
                } else if (tag_category_list[i].name === "地域") {
                    tag_category_list[i].type = 3
                    tag_category_list[i].tag_flag = true
                    tag_category_list[i].tag_list.map(item => {
                        item.key = item.id
                        return item
                    })
                    tag_category_list_required.push(tag_category_list[i])
                } else {
                    tag_category_list[i].type = 1
                    tag_category_list[i].tag_flag = false
                    tag_category_list[i].tag_list.map(item => {
                        item.key = item.id
                        item.disabled = false
                        return item
                    })
                }
                tag_category_list[i].parentName = this.findParentNameById(tag_category_list[i].id, tag_category_list)
            }
            let tree_data = this.list_to_tree(tag_category_list);
            this.setState({
                tag_category_list_required: tag_category_list_required,
                tag_category_list: tag_category_list,
                tag_category_list_formal: tag_category_list,
                tree_data: tree_data,
                is_native: is_native
            }, () => {
                this.getProjectTA()
            })

        } catch (err) {
            console.log(err);
        }
    }
    // 初始化渲染标签
    async initTag() {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        if (throng_list.length === 0) {
            throng_list.push({
                name: '人群' + (throng_list.length + 1),
                group_rule: false, ta_content: '',
                tag_category_list: this.init_tag_category_list_cur(),
                ta_percentage: 100,
                ta_user_id: "",
                tag_num_required: 2, // 必填
                tag_num_optional: 0,  // 选填
            })
        }
        this.setState({
            throng_list
        })
    }
    // 初始化tag_category_list_required
    init_tag_category_list_cur() {
        let tag_category_list_cur = JSON.parse(JSON.stringify(this.state.tag_category_list_required))
        for (let i = 0; i < tag_category_list_cur.length; i++) {
            tag_category_list_cur[i]["result"] = []
            if (tag_category_list_cur[i].type === 2) { // 年龄
                let obj = {
                    tag_rule: true, // 反选 的 是 非
                    has_condition_percentage: false, // 是否有百分比
                    tag_id: [],// 选中的
                    condition_percentage: 100,
                    selected_id: "", // 后端生成的标签的id
                }
                tag_category_list_cur[i].result.push(JSON.parse(JSON.stringify(obj)))
            } else if (tag_category_list_cur[i].type === 3) { //地域

            } else {
                let obj = {
                    tag_rule: true,
                    has_condition_percentage: false,
                    tag_id: '',//相当于id，要传给后端的，对应select_list的id
                    condition_percentage: 100,
                    selected_id: ""
                }
                tag_category_list_cur[i].result.push(JSON.parse(JSON.stringify(obj)))
            }
        }
        return tag_category_list_cur
    }
    // 添加更多人群
    async addThrongList() {
        if (this.props.user.account_type === "1" && this.state.is_native) {
            message.warning(`基础共创只能添加一个人群，请联系商务进行升级`, 3, () => { })
            return
        }
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        let throng_list_length = this.state.throng_list.length
        if (throng_list_length >= 100) {
            message.warning(`人群数量已达到上限，不可添加人群`, 2, () => { })
            return
        }
        let throng_list_length_next = throng_list_length + 1
        let avg_ta_percent = parseInt(100 / throng_list_length_next)
        let last_ta_percent = 100 - throng_list_length * avg_ta_percent
        for (let i = 0; i < throng_list_length; i++) {
            throng_list[i].ta_percentage = avg_ta_percent
        }
        throng_list.push(Object.assign({}, { name: '人群' + (throng_list.length + 1), group_rule: false, ta_content: '', tag_category_list: this.init_tag_category_list_cur(), ta_percentage: last_ta_percent, ta_user_id: "" }))
        this.setState({
            throng_list
        })
    }

    // 通过标签id找到父级名字
    findParentNameById(id, list) {
        let name = [];
        for (let i in list) {
            if (list[i].id === id) {
                name.unshift(list[i].name)
            }
            if (list[i].parent_id !== 0 && list[i].id === id) {
                name.unshift(...this.findParentNameById(list[i].parent_id, list))
            }
        }
        return name;
    }
    //获取项目筛选人群
    async getProjectTA() {
        let project_id = localStorage.getItem('project_id') || ""
        let special_event_id = localStorage.getItem('special_event_id') ? localStorage.getItem('special_event_id') : ""
        let current_project_type = localStorage.getItem('current_project_type') || ""
        this.setState({ loading: 1 })
        try {
            const response = await getProjectTA({
                project_id: project_id,
                // project_id: '946',
                special_event_id: special_event_id,
                // special_event_id: '1',
                project_type: current_project_type
            })
            this.setState({ loading: 0 })
            if (!response) return
            if (response.data?.hasOwnProperty('dispatch_group')) {  //标签
                this.initProject(response.data.dispatch_group)
            } else {
                this.initTag()    // 初始化渲染标签
                if (parseInt(response.data.dispatch_type) === 2) { // 随机
                    this.setState({
                        radio_number: 2
                    })
                }
            }
        } catch (error) {
            this.setState({ loading: 0 })
            message.error('后台发生错误')
            throw error;
        }
    }
    // 之前有设置过标签
    initProject(dispatch_group) {
        let throng_list = []
        for (let i = 0; i < dispatch_group.length; i++) {
            throng_list.push(JSON.parse(JSON.stringify({
                id: dispatch_group[i].id,
                name: dispatch_group[i].ta_name,
                group_rule: dispatch_group[i].ta_conditions_type ? true : false, ta_content: dispatch_group[i].ta_content,
                ta_percentage: dispatch_group[i].ta_percentage,
                ta_user_id: dispatch_group[i].ta_use_id,
                tag_category_list: [],
                tag_num_required: 0,// 必填
                tag_num_optional: 0,// 选填
            })))
            try {
                dispatch_group[i].ta_conditions.forEach((item, index) => {
                    let tag_category_list_obj = {}
                    if (this.state.tem_tree[item.condition_id]) {
                        tag_category_list_obj = JSON.parse(JSON.stringify(this.state.tem_tree[item.condition_id]))
                    } else {
                        throw Error
                    }
                    if (item.condition_name === "年龄") {
                        tag_category_list_obj.rule = item.rule === 1 ? true : false
                        tag_category_list_obj.ta_condition_group_id = item.ta_condition_group_id
                        tag_category_list_obj.tag_flag = true
                        tag_category_list_obj["result"] = []
                        let infinite_num = 0 // 0 无不限字段 -1有不限字段
                        item.selected.forEach((item2, index2) => {
                            tag_category_list_obj["result"].push({
                                tag_rule: item2.tag_rule === 1 ? true : false,
                                has_condition_percentage: item2.has_condition_percentage === 1 ? true : false,
                                tag_id: [item2.tag_id],//和tag_list里面对应的id
                                condition_percentage: item2.condition_percentage,
                                selected_id: item2.selected_id || "",
                            })
                            if (item2.tag_id === "不限") {
                                infinite_num = -1
                            }
                        })
                        throng_list[i].tag_num_required += item.selected.length + infinite_num
                    } else if (item.condition_name === "地域") {
                        tag_category_list_obj.rule = item.rule === 1 ? true : false // 且 或
                        tag_category_list_obj.ta_condition_group_id = item.ta_condition_group_id
                        tag_category_list_obj.tag_flag = true
                        tag_category_list_obj["region_result"] = []
                        tag_category_list_obj["result"] = []
                        let infinite_num = 0 // 0 无不限字段 -1有不限字段
                        item.selected.forEach((item2, index2) => {
                            tag_category_list_obj["result"].push({
                                tag_rule: item2.tag_rule === 1 ? true : false,
                                has_condition_percentage: item2.has_condition_percentage === 1 ? true : false,
                                id: item2.value,//和tag_list里面对应的id
                                condition_percentage: item2.condition_percentage,
                                selected_id: item2.selected_id || "",
                            })
                            tag_category_list_obj["region_result"].push(item2.value)
                            if (item2.value === "不限") {
                                infinite_num = -1
                            }
                        })
                        throng_list[i].tag_num_required += item.selected.length + infinite_num
                    } else {
                        tag_category_list_obj.rule = item.rule === 1 ? true : false // 且 或
                        tag_category_list_obj.ta_condition_group_id = item.ta_condition_group_id
                        tag_category_list_obj.tag_flag = true
                        tag_category_list_obj["result"] = []
                        let infinite_num = 0 // 0 无不限字段 -1有不限字段
                        item.selected.forEach((item2, index2) => {
                            tag_category_list_obj["result"].push({
                                tag_rule: item2.tag_rule === 1 ? true : false,
                                has_condition_percentage: item2.has_condition_percentage === 1 ? true : false,
                                tag_id: item2.tag_id,//和tag_list里面对应的id
                                condition_percentage: item2.condition_percentage,
                                selected_id: item2.selected_id || "",
                            })
                            if (item.condition_name === "性别" && item2.value === "不限") {
                                infinite_num = -1
                            }
                        })
                        if (item.condition_name === "性别") {
                            throng_list[i].tag_num_required += item.selected.length + infinite_num
                        } else {
                            throng_list[i].tag_num_optional += item.selected.length + infinite_num
                        }
                    }
                    throng_list[i].tag_category_list.push(tag_category_list_obj)
                })
            } catch (error) {
                this.initTag()    // 初始化渲染标签
                message.warning(`后台发生错误，没有相应id`, 2, () => { })
                return
            }
        }
        this.setState({
            hasFilledTag: true,
            throng_list: throng_list
        })
    }
    // 地域
    getRegionTagList(tag_list_formal) {
        if (!tag_list_formal || !tag_list_formal.length) return []
        let tag_list = JSON.parse(JSON.stringify(tag_list_formal))
        let tag_list_item = tag_list[tag_list.length - 1]
        if (tag_list_item.value === "不限") {
            tag_list.unshift(tag_list_item)
            tag_list.pop()
        }
        return tag_list
    }
    // 后端有问题， 前端去重了
    handle_tag_list(tag_list) {
        let map = new Map()
        for (let item of tag_list) {
            if (!map.has(item.id)) {
                map.set(item.id, item)
            }
        }
        let arr = [...map.values()]
        return arr
    }
    save(t) {                                       //保存
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        let dispatch_group = []
        let ta_conditions = []
        let selected = []
        let throng_percent_list = []
        let object = {}
        if (parseInt(this.state.radio_number) === 2) { //随机选择
            object = {
                dispatch_type: this.state.radio_number,
                dispatch_group_type: 1,
                dispatch_project_id: localStorage.getItem('project_id') ? localStorage.getItem('project_id') : '',
                dispatch_brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : '',
                dispatch_group: []
            }
        } else {
            for (let i = 0; i < throng_list.length; i++) {
                ta_conditions = []
                if (throng_list[i].name === "") { //检查人群百分比不可为空
                    message.warning(`第${i + 1}个人群名字为空`, 2, () => { })
                    return
                } else if (throng_list[i].ta_percentage === "") { //检查人群百分比不可为空
                    message.warning(`${throng_list[i].name}的百分比不可为空`, 2, () => { })
                    return
                } else {
                    throng_percent_list.push(throng_list[i].ta_percentage)
                }
                for (let j = 0; j < throng_list[i].tag_category_list.length; j++) {
                    selected = []
                    //单独检查子标签是否有填写
                    if (throng_list[i].tag_category_list[j]?.result?.length === 0) {
                        message.warning(`请添加${throng_list[i].tag_category_list[j].name}标签组下的子标签`, 1, () => { })
                        return
                    }
                    let tag_percent_list = []
                    let is_all_tag_percent_list = true
                    //正常的循环遍历
                    for (let k = 0; k < throng_list[i].tag_category_list[j].result.length; k++) {
                        if (throng_list[i].tag_category_list[j].type === 1 && throng_list[i].tag_category_list[j].result.length) {
                            // 检查
                            if (throng_list[i].tag_category_list[j].result[k].tag_id === "") {
                                message.warning(`请填写"${throng_list[i].name}"的"${throng_list[i].tag_category_list[j].name}"的第${k + 1}个选项`, 5, () => { })
                                return
                            } else if (throng_list[i].tag_category_list[j].result[k].condition_percentage === "" && throng_list[i].tag_category_list[j].result[k].has_condition_percentage) {
                                message.warning(`请填写"${throng_list[i].name}"的"${throng_list[i].tag_category_list[j].name}"的第${k + 1}个的百分比`, 5, () => { })
                                return
                            } else {
                                if (throng_list[i].tag_category_list[j].result[k].has_condition_percentage) { // 有百分比
                                    tag_percent_list.push(Number(throng_list[i].tag_category_list[j].result[k].condition_percentage))
                                } else {
                                    is_all_tag_percent_list = false
                                }
                            }
                            let obj = {
                                tag_rule: throng_list[i].tag_category_list[j].result[k].tag_rule ? 1 : 0, // 反选 的 是 非
                                has_condition_percentage: throng_list[i].tag_category_list[j].result[k].has_condition_percentage ? 1 : 0, // 是否有百分比
                                tag_id: throng_list[i].tag_category_list[j].result[k].tag_id,
                                condition_percentage: throng_list[i].tag_category_list[j].result[k].condition_percentage,
                                selected_id: throng_list[i].tag_category_list[j].result[k].selected_id
                            }
                            selected.push(obj)
                        }
                        if (throng_list[i].tag_category_list[j].type === 2 && throng_list[i].tag_category_list[j].result.length) { //年龄
                            // 检查
                            if (!throng_list[i].tag_category_list[j].result[k].tag_id.length) {
                                message.warning(`请填写"${throng_list[i].name}"的"${throng_list[i].tag_category_list[j].name}"的第${k + 1}个选项`, 5, () => { })
                                return
                            } else if (throng_list[i].tag_category_list[j].result[k].condition_percentage === "" && throng_list[i].tag_category_list[j].result[k].has_condition_percentage) {
                                message.warning(`请填写"${throng_list[i].name}"的"${throng_list[i].tag_category_list[j].name}"的第${k + 1}个的百分比`, 5, () => { })
                                return
                            } else {
                                if (throng_list[i].tag_category_list[j].result[k].has_condition_percentage) { // 有百分比
                                    tag_percent_list.push(Number(throng_list[i].tag_category_list[j].result[k].condition_percentage))
                                } else {
                                    is_all_tag_percent_list = false
                                }
                            }
                            let obj = {
                                tag_rule: throng_list[i].tag_category_list[j].result[k].tag_rule ? 1 : 0, // 反选 的 是 非
                                has_condition_percentage: throng_list[i].tag_category_list[j].result[k].has_condition_percentage ? 1 : 0, // 是否有百分比
                                tag_id: throng_list[i].tag_category_list[j].result[k].tag_id[0],
                                condition_percentage: throng_list[i].tag_category_list[j].result[k].condition_percentage,
                                selected_id: throng_list[i].tag_category_list[j].result[k].selected_id
                            }
                            selected.push(obj)
                        }
                        if (throng_list[i].tag_category_list[j].type === 3 && throng_list[i].tag_category_list[j].result.length) { //地域
                            // 检查
                            for (let p = 0; p < throng_list[i].tag_category_list[j].tag_list.length; p++) {
                                if (throng_list[i].tag_category_list[j].tag_list[p].value === throng_list[i].tag_category_list[j].result[k].id) {
                                    if (throng_list[i].tag_category_list[j].result[k].condition_percentage === "" && throng_list[i].tag_category_list[j].result[k].has_condition_percentage) {
                                        message.warning(`请填写"${throng_list[i].name}"的"${throng_list[i].tag_category_list[j].name}"的第${k + 1}个的百分比`, 5, () => { })
                                        return
                                    } else {
                                        if (throng_list[i].tag_category_list[j].result[k].has_condition_percentage) { // 有百分比
                                            tag_percent_list.push(Number(throng_list[i].tag_category_list[j].result[k].condition_percentage))
                                        } else {
                                            is_all_tag_percent_list = false
                                        }
                                    }
                                    let obj = {
                                        tag_rule: throng_list[i].tag_category_list[j].result[k].tag_rule ? 1 : 0, // 反选 的 是 非
                                        has_condition_percentage: throng_list[i].tag_category_list[j].result[k].has_condition_percentage ? 1 : 0, // 是否有百分比
                                        tag_id: throng_list[i].tag_category_list[j].tag_list[p].id, //上海的id
                                        condition_percentage: throng_list[i].tag_category_list[j].result[k].condition_percentage,
                                        selected_id: throng_list[i].tag_category_list[j].result[k].selected_id
                                    }
                                    selected.push(obj)
                                }
                            }
                        }
                    }
                    // 检查
                    tag_percent_list = tag_percent_list.map(Number)
                    let tag_percent_list_sum = this.sum(tag_percent_list)
                    if (throng_list[i].tag_category_list[j].rule) {
                        if (is_all_tag_percent_list && tag_percent_list_sum !== 100) {
                            message.warning(`请填写"${throng_list[i].name}"的"${throng_list[i].tag_category_list[j].name}"的百分比相加需为1`, 5, () => { })
                            return
                        } else if (!is_all_tag_percent_list && tag_percent_list_sum >= 100) {
                            message.warning(`请填写"${throng_list[i].name}"的"${throng_list[i].tag_category_list[j].name}"的百分比相加不可超过1`, 5, () => { })
                            return
                        }
                    }
                    if (throng_list[i].tag_category_list[j].result.length) {
                        ta_conditions.push({
                            rule: throng_list[i].tag_category_list[j].rule ? 1 : 0,
                            condition_name: throng_list[i].tag_category_list[j].name,
                            condition_id: throng_list[i].tag_category_list[j].id,
                            is_mutex: throng_list[i].tag_category_list[j].is_mutex,
                            selected: selected,
                            ta_condition_group_id: throng_list[i].tag_category_list[j].ta_condition_group_id
                        })
                    }
                }
                throng_list[i].ta_content = this.displayThrongName(i)
                dispatch_group.push({ ta_name: throng_list[i].name, ta_conditions_type: throng_list[i].group_rule ? 1 : 0, ta_content: throng_list[i].ta_content, ta_conditions: ta_conditions, ta_percentage: throng_list[i].ta_percentage, ta_use_id: throng_list[i].ta_user_id })
            }
            object = {
                dispatch_type: this.state.radio_number,
                dispatch_group_type: 1,
                dispatch_project_id: localStorage.getItem('project_id') ? localStorage.getItem('project_id') : '',
                dispatch_project_type: localStorage.getItem('current_project_type') || "",
                dispatch_special_event_id: localStorage.getItem('special_event_id') ? localStorage.getItem('special_event_id') : "",
                dispatch_brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : '',
                dispatch_count: this.props.dispatch_count,
                dispatch_group: dispatch_group,
            }

            //检查人群比分比
            throng_percent_list = throng_percent_list.map(Number)
            let throng_percent_list_sum = this.sum(throng_percent_list)
            if (throng_percent_list_sum !== 100) {
                message.warning(`人群百分比总和加起来需为1`, 2, () => { })
                return
            }
        }
        if (!this.state.hasFilledTag) {
            this.saveProjectTA(object, t)
        } else if (this.state.hasFilledTag) {
            this.updateProjectTA(object, t)
        }
    }
    //求和
    sum(arr) {
        return arr.reduce(function (prev, cur, index, arr) {
            return prev + cur
        }, 0)
    }
    // 真的保存
    async saveProjectTA(object, t) {
        this.setState({
            loading_save: 1,
            loadingText: "保存中，请耐心等待",
        })
        try {
            const response = await saveProjectTA(object)
            if (!response) return
            if (response.status === 201 || response.status === 200) {
                this.setState({
                    hasFilledTag: true
                }, () => {
                    this.getProjectTA()
                    if (t === 'next') {
                        this.props.getData({
                            is_native: this.state.is_native,
                            project_entry_number: 3,
                        })
                        this.setState({
                            project_entry_number: 3,
                        })
                    }
                })
                this.setState({
                    loading_save: 0,
                    loadingText: "加载中",
                })
            }
        } catch (error) {
            this.setState({
                loading_save: 0,
                loadingText: "加载中",
            })
            message.error('保存失败，请检查', 2, () => { })
            throw error;
        }
    }
    // 真的更新
    async updateProjectTA(object, t) {
        this.setState({
            loading_save: 1,
            loadingText: "更新中，请耐心等待",
        })
        try {
            const response = await updateProjectTA(object)
            if (!response) return
            if (response.status === 201 || response.status === 200 || response.status === 202) {
                this.setState({
                    loading_save: 0,
                    loadingText: "加载中",
                    hasFilledTag: true
                }, () => {
                    this.getProjectTA()
                    if (t === 'next') {
                        this.props.getData({
                            is_native: this.state.is_native,
                            project_entry_number: 3,
                        })
                        this.setState({
                            project_entry_number: 3,
                        })
                    }
                })
            }
        } catch (error) {
            this.setState({
                loading_save: 0,
                loadingText: "加载中",
            })
            message.error('保存失败，请检查', 2, () => { })
            throw error;
        }
    }
    //更改人群名字
    update_throng_name(index, event) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        throng_list[index].name = event.target.value
        this.setState({
            throng_list
        })
    }
    //删除人群
    delete_throng_items(index, event) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        throng_list.splice(index, 1)
        let throng_list_length = throng_list.length
        let avg_ta_percent = parseInt(100 / throng_list_length)
        let last_ta_percent = 100 - (throng_list_length - 1) * avg_ta_percent

        for (let i = 0; i < throng_list.length; i++) {
            throng_list[i].name = '人群' + (i + 1)
            throng_list[i].ta_percentage = avg_ta_percent
            if (i === throng_list_length - 1) {
                throng_list[i].ta_percentage = last_ta_percent
            }
        }
        this.setState({
            throng_list
        })
    }
    //弹出添加标签的组件
    handle_click_add_tags(index, event) {
        // 处理树数据
        let tag_category_list = JSON.parse(JSON.stringify(this.state.tag_category_list))
        // 树数据
        // let tree_data = this.list_to_tree(tag_category_list);

        let checkedKeys = [];
        let defaultKeys = [];
        this.state.throng_list[index].tag_category_list.forEach((item, index) => {
            if (item.tag_flag) { // tag_flag 必填
                checkedKeys.push(item.id);
                if (item.flag == 1) defaultKeys.push(item.id); //flag为1 后端传来的必填
            }
        })
        this.tagsRef.current.setTreeData(this.state.tree_data, checkedKeys, defaultKeys, this.props.user.account_type, this.state.is_native)

        this.setState({
            AddTags_status: true,
            throng_list_index: index,
        })
    }
    // 列表转树
    list_to_tree(data) {
        let tree = [];
        let tem_tree = {};
        for (let i in data) {
            tem_tree[data[i].id] = data[i]
        }
        for (let i in data) {
            if (tem_tree[data[i].parent_id] && data[i].parent_id !== 0) {
                if (tem_tree[data[i].parent_id].children) {
                    tem_tree[data[i].parent_id].children.push(data[i]);
                } else {
                    tem_tree[data[i].parent_id].children = [data[i]];
                }
            } else if (data[i].parent_id === 0 || !data[i].parent_id) {
                tree.push(data[i])
            }

        }
        this.setState({ tem_tree })
        return tree;
    }
    //获取AddTags_status状态 和 tag_list数据
    get_AddTags_status_Add_Throng_list = (obj) => {
        let { AddTags_status, checkedKeys } = obj;
        let show_toast = false
        if (checkedKeys) {
            // 找到最底层标签
            let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
            let tag_category_list = throng_list[this.state.throng_list_index].tag_category_list;
            let tmp = []
            for (let i = 0; i < tag_category_list.length; i++) {
                let index = checkedKeys.indexOf(tag_category_list[i].id)
                if (index !== -1) {
                    tmp.push(tag_category_list[i])
                    checkedKeys.splice(index, 1)
                }
            }
            throng_list[this.state.throng_list_index].tag_category_list = tmp
            for (let i = 0; i < checkedKeys.length; i++) {
                let obj = JSON.parse(JSON.stringify(this.state.tem_tree[checkedKeys[i]]))
                if (obj.is_leaf) {
                    obj.tag_flag = true // 设置已填
                    if (throng_list[this.state.throng_list_index].tag_num_optional >= 8) {
                        //所有选填的标签组（除年龄、性别、地域）下的子标签的数量不可超过8个，请删除子标签数量
                        obj["result"] = []
                        show_toast = true
                    } else {
                        obj["result"] = [{
                            tag_rule: true, // 反选 的 是 非
                            has_condition_percentage: false, // 是否有百分比
                            tag_id: '',
                            condition_percentage: 100,
                            selected_id: ""
                        }]
                        throng_list[this.state.throng_list_index].tag_num_optional += 1
                    }
                    throng_list[this.state.throng_list_index].tag_category_list.push(obj)
                }
            }
            this.setState({
                throng_list,
                AddTags_status
            })
        } else {
            this.setState({ AddTags_status })
        }
        if (show_toast) {
            message.warning(`所有选填的标签组（除年龄、性别、地域）下的子标签的数量不可超过8个，请删除子标签数量`, 3, () => { })
        }
    }
    delete_tags_list(index1, index2) {                      //删除添加的标签
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        let type = throng_list[index1].tag_category_list[index2].type
        if (type === 2 || type === 3 || throng_list[index1].tag_category_list[index2].name === '性别') {
            throng_list[index1].tag_num_required -= throng_list[index1].tag_category_list[index2].result.length
        } else {
            if (throng_list[index1].tag_category_list[index2].result.length) {
                throng_list[index1].tag_num_optional -= throng_list[index1].tag_category_list[index2].result.length
            }
        }
        throng_list[index1].tag_category_list.splice(index2, 1)
        this.setState({
            throng_list
        })
    }

    handle_click_radio(number, event) {
        this.setState({
            radio_number: number
        })
    }
    //上一步
    prev() {
        this.props.getData({
            is_native: this.state.is_native,
            project_entry_number: 1,
        })
        this.setState({
            project_entry_number: 1
        })

    }
    //下一步
    next(t) {
        this.save(t)
    }
    //是否可以下一步标红
    canNext() {
        let flag = false
        if (this.state.radio_number === 2) {
            flag = true
        } else if (this.state.radio_number === 1) {
        }
        return flag
    }
    //验证数字
    validateNumber(event) {
        if (event.target.value === "") {
            return
        }
        event.target.value = event.target.value.replace(/^0[0-9]+/g, 0);
        event.target.value = event.target.value.replace(/[^/.\d]/g, "");
        event.target.value = event.target.value.replace(".", "");

    }
    validateAgeNumber(event) {
        if (event.target.value === "") {
            return
        }
        event.target.value = event.target.value.replace(/^0[0-9]+/g, 0);
        event.target.value = event.target.value.replace(/[^/\-+\d]/g, "");

    }
    //处理人群percent-input
    handleThrongPercentInput(index1, event) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        if (event.target.value === "") {
            throng_list[index1].ta_percentage = ""
        } else {
            let value = parseInt(event.target.value)
            if (value > 100) {
                event.target.value = 100
            }
            throng_list[index1].ta_percentage = parseInt(event.target.value)
        }
        this.setState({
            throng_list: throng_list
        })
    }
    //处理子标签percent-input
    handleTagPercentInput(index1, index2, index3, event) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        if (event.target.value === "") {
            throng_list[index1].tag_category_list[index2].result[index3].condition_percentage = ""
        } else {
            let value = parseInt(event.target.value)
            if (value > 100) {
                event.target.value = 100
            }
            throng_list[index1].tag_category_list[index2].result[index3].condition_percentage = parseInt(event.target.value)
        }
        this.setState({
            throng_list: throng_list
        })
    }
    //处理自定义标签textarea
    handleSpecialText(index1, index2, index3, event) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        throng_list[index1].tag_list[index2].result[index3].special_text = event.target.value

        this.setState({
            throng_list: throng_list
        })
    }
    // 标签里的添加item 自定义标签的item只能一个
    canAddTagsItem(index1, index2) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        let type = throng_list[index1].tag_category_list[index2].type
        if (type === 4) {
            if (throng_list[index1].tag_category_list[index2].result.length >= 1) {
                return false
            }
        }
        return true

    }
    //能否删必填的标签
    canDeleteTag(index1, index2) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        let flag = throng_list[index1].tag_category_list[index2].flag
        if (flag === 1) {
            return false
        } else {
            return true
        }
    }
    //能否删人群
    canDeleteThrongItems(index) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        if (throng_list.length <= 1) {
            return false
        }
        return true
    }
    // 显示派送结果 tagName名字
    displayThrongName(i) {
        let ans = this.state.throng_list[i].name + '：'
        this.state.throng_list[i].tag_category_list.forEach((item, index) => {
            ans += item.name + '：'
            if (item.type === 2) { // age
                item.result.forEach((item2, index2) => {
                    if (index2 !== 0) {
                        ans += item.rule ? '或' : '且'
                    }
                    item.tag_list.forEach((item3, index3) => {
                        if (item2.tag_id[0] === item3.key) {
                            ans += item3.value
                        }
                    })
                })

            } else if (item.type === 3) {
                item.result.forEach((item2, index2) => {
                    if (index2 !== 0) {
                        ans += item.rule ? '或' : '且'
                    }
                    item.tag_list.forEach((item3, index3) => {
                        if (item2.id === item3.value) {
                            ans += item3.value
                        }
                    })
                })

            } else {
                item.result.forEach((item2, index2) => {
                    if (index2 !== 0) {
                        ans += item.rule ? '或' : '且'
                    }
                    item.tag_list.forEach((item3, index3) => {
                        if (item2.tag_id === item3.key) {
                            ans += item3.value
                        }
                    })
                })

            }
            ans += "；"
        })
        return ans
    }
    get_region_popup_state(object) {
        if (!object.is_confirm) { // 只是取消
            this.setState({
                region_popup_status: object.region_popup_status
            })
            return
        }
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        let prev_region_num = throng_list[this.state.region_popup_index].tag_category_list[this.state.region_popup_index2].result.length
        let cur_region_num = object.region_popup_items.length
        let infinite_num = 0 // 0 无不限字段 -1有不限字段
        object.region_popup_items.forEach(item => {
            if (item === "不限") {
                infinite_num = -1
            }
        })
        if ((throng_list[this.state.region_popup_index].tag_num_required - prev_region_num + cur_region_num) + infinite_num > 8) {
            message.warning(`必填标签组下的子标签总和数量不可超过8个`, 2, () => { })
            this.setState({
                region_popup_status: object.region_popup_status
            })
            return
        } else {
            throng_list[this.state.region_popup_index].tag_num_required = throng_list[this.state.region_popup_index].tag_num_required - prev_region_num + cur_region_num + infinite_num
        }

        throng_list[this.state.region_popup_index].tag_category_list[this.state.region_popup_index2].region_result = [...object.region_popup_items]
        let region_result = throng_list[this.state.region_popup_index].tag_category_list[this.state.region_popup_index2].region_result

        let result_num = region_result.length
        let avg_percent = parseInt(100 / result_num)
        let last_percent = 100 - (result_num - 1) * avg_percent

        if (region_result.length) {
            let result = []
            for (let i = 0; i < result_num - 1; i++) {
                let obj = { tag_rule: true, has_condition_percentage: false, id: region_result[i], condition_percentage: avg_percent, selected_id: "" }
                result.push(obj)
            }
            result.push({ tag_rule: true, has_condition_percentage: false, id: region_result[result_num - 1], condition_percentage: last_percent, selected_id: "" })
            throng_list[this.state.region_popup_index].tag_category_list[this.state.region_popup_index2].result = result
        }


        this.setState({
            region_popup_status: object.region_popup_status,
            throng_list: throng_list,
        })
    }
    //选填是否显示且
    getShowNumber(tag_list) {
        let tmp = []
        tmp = tag_list.filter(item => item.tag_flag && item.flag !== 1)
        return tmp.length
    }
    // 年龄子标签渲染age下拉框
    handleAgeListOption(index1, index2, index3) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        let tmp = []
        for (let i = 0; i < throng_list[index1].tag_category_list[index2].result.length; i++) {
            if (i !== index3 && throng_list[index1].tag_category_list[index2].result[i].tag_id?.length) {
                tmp.push(throng_list[index1].tag_category_list[index2].result[i].tag_id[0])
            }
        }
        let tag_list = throng_list[index1].tag_category_list[index2].tag_list.filter(item => {
            if (tmp.includes(item.key)) {
                item.disabled = true
            }
            return item
        })
        return tag_list
    }
    // 渲染age默认的题目
    handleDefaultAgeListOption(index1, index2, index3) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        return throng_list[index1].tag_category_list[index2].result[index3].tag_id || []
    }
    // 判断年龄是否输入正确
    isConnectAge(age) {
        let reg = /^((\d)+-?(\d)+)|(\d)+\+$/
        let flag = reg.test(age)
        if (!flag) {
            let reg2 = /^(\d)+\+$/
            flag = reg2.test(age)
        }
        if (age.indexOf("-") !== -1 && age.indexOf("-") !== age.lastIndexOf("-")) {
            flag = false
        } else if (age.indexOf("+") !== -1 && age.indexOf("+") !== age.lastIndexOf("+")) {
            flag = false
        } else if (age.indexOf("-") !== -1 && age.indexOf("+") !== -1) {
            flag = false
        }
        return flag
    }
    //下拉框选择后，并赋值到age state里面 
    onAgeSelectChange(key_arr, index1, index2, index3) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        if (key_arr.length === 0) {
            if (throng_list[index1].tag_category_list[index2].result[index3].tag_id[0] === "不限") { //之前是不限
                throng_list[index1].tag_num_required += 1
            }

            throng_list[index1].tag_category_list[index2].result[index3].tag_id = key_arr
            throng_list[index1].tag_category_list[index2].tag_list = JSON.parse(JSON.stringify(this.state.age_list))
            this.setState({
                throng_list: throng_list
            })
            return
        }
        key_arr = [key_arr[key_arr.length - 1]]
        let age = String(key_arr[key_arr.length - 1])
        if (this.isConnectAge(age)) {
            if (age.indexOf('-') >= 0) {
                let [age_s, age_e] = age.split("-")
                if (Number(age_s) === 0 && Number(age_e) === 18) {
                    // ok
                } else if (Number(age_s) < 15 || Number(age_s) > 70) {
                    message.warning('起始年龄最小15岁,最大70岁')
                    return
                } else if (Number(age_e) < 15 || Number(age_e) > 70) {
                    message.warning('结束年龄最小15岁,最大70岁')
                    return
                } else if (Number(age_s) > Number(age_e)) {
                    message.warning('起始年龄需要大雨结束年龄')
                    return
                }
            } else if (age.indexOf('+') >= 0) {
                let [age_s] = age.split("+")
                if (Number(age_s) < 15 || Number(age_s) > 70) {
                    message.warning('年龄最小15岁,最大70岁')
                    return
                }
            } else {
                if (Number(age) < 15 || Number(age) > 70) {
                    message.warning('年龄最小15岁,最大70岁')
                    return
                }
            }
            if (this.props.user.account_type === "1" && this.state.is_native) {
                // 判断是否已经存在我们的age_list里面
                let is_exist = false
                throng_list[index1].tag_category_list[index2].tag_list.forEach((item, index) => {
                    if (item.key === age) {
                        is_exist = true
                    }
                })
                if (!is_exist) {
                    // throng_list[index1].tag_category_list[index2].tag_list.push({ key: age, value: age, disabled: false })
                    message.warning(`基础共创不可自主配置“年龄”标签，请联系商务进行升级`, 3, () => { })
                    return
                }
            }
            if (throng_list[index1].tag_category_list[index2].result[index3].tag_id[0] === "不限") { //之前是不限
                throng_list[index1].tag_num_required += 1
            }
            throng_list[index1].tag_category_list[index2].result[index3].tag_id = key_arr
            for (let i = 0; i < throng_list[index1].tag_category_list[index2].result.length; i++) {
                if (throng_list[index1].tag_category_list[index2].result[i].tag_id[0] === "不限") {
                    throng_list[index1].tag_category_list[index2].result.splice(i, 1)
                    i -= 1
                }
            }
            this.setState({
                throng_list: throng_list
            })
        } else if (age === "不限") {
            throng_list[index1].tag_category_list[index2].result[index3].tag_id = key_arr
            throng_list[index1].tag_num_required -= throng_list[index1].tag_category_list[index2].result.length
            throng_list[index1].tag_category_list[index2].result.splice(index3 + 1, throng_list[index1].tag_category_list[index2].result.length - index3)
            throng_list[index1].tag_category_list[index2].result.splice(0, index3)
            this.setState({
                throng_list: throng_list
            })
        } else {
            message.warning('年龄格式不正确,请输入20-30或30+或30', 2, () => { })
        }

    }
    // select选择标签
    onSelectTagChange(index1, index2, index3, key) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        if (throng_list[index1].tag_category_list[index2].name === '性别') {
            let value_prev = ""
            let value_cur = ""
            let infinity_id = ""
            throng_list[index1].tag_category_list[index2].tag_list.forEach(item => {
                if (item.value === "不限") {
                    infinity_id = item.id
                }
            })
            if (key === infinity_id) {
                value_cur = "不限"
            }
            if (throng_list[index1].tag_category_list[index2].result[index3].tag_id === infinity_id) {
                value_prev = "不限"
            }
            throng_list[index1].tag_category_list[index2].result[index3].tag_id = key
            if (value_cur === "不限") {
                throng_list[index1].tag_num_required -= throng_list[index1].tag_category_list[index2].result.length
                for (let i = 0; i < throng_list[index1].tag_category_list[index2].result.length; i++) {
                    if (throng_list[index1].tag_category_list[index2].result[i].tag_id !== infinity_id) {
                        throng_list[index1].tag_category_list[index2].result.splice(i, 1)
                        i -= 1
                    }
                }
            } else if (value_cur !== "不限" && value_prev === "不限") {
                throng_list[index1].tag_num_required += 1
            } else if (value_cur !== "不限" && value_prev !== "不限") {
                for (let i = 0; i < throng_list[index1].tag_category_list[index2].result.length; i++) {
                    if (throng_list[index1].tag_category_list[index2].result[i].tag_id === infinity_id) {
                        throng_list[index1].tag_category_list[index2].result.splice(i, 1)
                        i -= 1
                    }
                }
            }
        } else {
            throng_list[index1].tag_category_list[index2].result[index3].tag_id = key
        }
        this.setState({ throng_list: throng_list })
    }
    // 选择select_tag_ist
    get_select_tag_list(index1, index2, index3) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        let tmp = []
        for (let i = 0; i < throng_list[index1].tag_category_list[index2].result.length; i++) {
            if (i !== index3 && throng_list[index1].tag_category_list[index2].result[i].tag_id) {
                tmp.push(throng_list[index1].tag_category_list[index2].result[i].tag_id)
            }
        }
        let tag_list = throng_list[index1].tag_category_list[index2].tag_list.filter(item => {
            if (tmp.includes(item.id)) {
                item.disabled = true
            }
            return item
        })
        return tag_list
    }
    // 添加标签组的子标签
    add_tags_item(index1, index2) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        let result_num = throng_list[index1].tag_category_list[index2].result.length + 1
        // 判断必填和选填是否满足5个
        if (throng_list[index1].tag_category_list[index2].type === 2 || throng_list[index1].tag_category_list[index2].name === '性别') { // 必填的三项
            if (throng_list[index1].tag_num_required >= 8) {
                // 必填标签组下的子标签总和数量不可超过8个
                message.warning(`必填标签组下的子标签总和数量不可超过8个`, 2, () => { })
                return
            } else {
                throng_list[index1].tag_num_required += 1
            }
        } else if (throng_list[index1].tag_category_list[index2].type !== 3) { //  非必填的三项
            if (throng_list[index1].tag_num_optional >= 8) {
                // 所有选填的标签组（除年龄、性别、地域）下的子标签的数量不可超过8个
                message.warning(`所有选填的标签组（除年龄、性别、地域）下的子标签的数量不可超过8个`, 2, () => { })
                return
            } else {
                throng_list[index1].tag_num_optional += 1
            }
        }
        if (throng_list[index1].tag_category_list[index2].type === 1) { // 性别or普通选项
            if (result_num === 1) {
                throng_list[index1].tag_category_list[index2].result.push(JSON.parse(JSON.stringify({ tag_rule: true, has_condition_percentage: false, tag_id: "", condition_percentage: 100, selected_id: "" })))
            } else {
                let avg_percent = parseInt(100 / result_num)
                let last_percent = 100 - (result_num - 1) * avg_percent
                for (let i = 0; i < result_num - 1; i++) {
                    throng_list[index1].tag_category_list[index2].result[i].condition_percentage = avg_percent
                }
                throng_list[index1].tag_category_list[index2].result.push(JSON.parse(JSON.stringify({ tag_rule: true, has_condition_percentage: false, tag_id: "", condition_percentage: last_percent, selected_id: "" })))
            }
            this.setState({ throng_list })
        } else if (throng_list[index1].tag_category_list[index2].type === 2) { // 年龄
            if (result_num === 1) {
                throng_list[index1].tag_category_list[index2].result.push(JSON.parse(JSON.stringify({ tag_rule: true, has_condition_percentage: false, tag_id: [], condition_percentage: 100, selected_id: "" })))
            } else {
                let avg_percent = parseInt(100 / result_num)
                let last_percent = 100 - (result_num - 1) * avg_percent
                for (let i = 0; i < result_num - 1; i++) {
                    throng_list[index1].tag_category_list[index2].result[i].condition_percentage = avg_percent
                }
                throng_list[index1].tag_category_list[index2].result.push(JSON.parse(JSON.stringify({ tag_rule: true, has_condition_percentage: false, tag_id: [], condition_percentage: last_percent, selected_id: "" })))
            }
            this.setState({ throng_list })
        } else if (throng_list[index1].tag_category_list[index2].type === 3) { // 地域
            this.setState({
                region_popup_status: true,
                region_popup_index: index1,
                region_popup_index2: index2,
                region_popup_id: throng_list[index1].tag_category_list[index2].id
            }, () => {
                let region_popup_items = []
                if (this.state.throng_list[this.state.region_popup_index].tag_category_list[this.state.region_popup_index2].hasOwnProperty("region_result")) {
                    region_popup_items = this.state.throng_list[this.state.region_popup_index].tag_category_list[this.state.region_popup_index2].region_result
                }
                this.regionRef.current.setRegionList(throng_list[index1].tag_category_list[index2].tag_list, region_popup_items, this.props.user.account_type, this.state.is_native)
            })
        }
    }
    // 删除标签组的子标签
    delete_tags_item(index1, index2, index3) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        let type = throng_list[index1].tag_category_list[index2].type
        if (type === 3) {
            if (throng_list[index1].tag_category_list[index2].result[index3].id !== "不限") {
                throng_list[index1].tag_num_required -= 1
            }
        } else if (type === 2) {
            if (throng_list[index1].tag_category_list[index2].result[index3].tag_id[0] !== "不限") {
                throng_list[index1].tag_num_required -= 1
            }
        } else if (throng_list[index1].tag_category_list[index2].name === '性别') {
            let has_infinity = false
            throng_list[index1].tag_category_list[index2].tag_list.forEach(item => {
                if (item.value === "不限" && item.id === throng_list[index1].tag_category_list[index2].result[index3].tag_id) {
                    has_infinity = true
                }
            })
            if (!has_infinity) {
                throng_list[index1].tag_num_required -= 1
            }
        } else {
            throng_list[index1].tag_num_optional -= 1
        }
        let result_num = throng_list[index1].tag_category_list[index2].result.length - 1 //计算删除后的子标签的数量
        let avg_percent = parseInt(100 / result_num) //计算子标签的平均百分比
        let last_percent = 100 - (result_num - 1) * avg_percent //计算最后一个子标签的百分比
        throng_list[index1].tag_category_list[index2].result.splice(index3, 1)
        throng_list[index1].tag_category_list[index2].result = throng_list[index1].tag_category_list[index2].result.map((item, index) => {
            item.condition_percentage = avg_percent
            if (index === result_num - 1) {
                item.condition_percentage = last_percent
            }
            return item
        })
        if (type === 3) {
            throng_list[index1].tag_category_list[index2].region_result.splice(index3, 1)
        }
        this.setState({ throng_list })
    }
    // 子标签的反选
    item_tag_rule_click(index1, index2, index3) {
        if (this.props.user.account_type === "1" && this.state.is_native) {
            message.warning(`基础共创不能反选子标签，请联系商务进行升级`, 3, () => { })
            return
        }
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        throng_list[index1].tag_category_list[index2].result[index3].tag_rule = !throng_list[index1].tag_category_list[index2].result[index3].tag_rule
        this.setState({ throng_list })
    }
    // 人群的 且 或
    rule_click(index, event) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        throng_list[index].group_rule = !throng_list[index].group_rule
        this.setState({
            throng_list
        })
    }
    // 标签组下且或的修改
    item_rule_click(index, index2, event) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        if (throng_list[index].tag_category_list[index2].is_mutex === 1) {
            throng_list[index].tag_category_list[index2].rule = true
            message.warning(`该标签组下的子标签为互斥关系，不可切换成"且"关系`, 3, () => { })
        } else {
            throng_list[index].tag_category_list[index2].rule = !throng_list[index].tag_category_list[index2].rule
        }
        this.setState({ throng_list })
    }
    // 是否显示百分比
    show_condition_percentage(index1, index2, index3) {
        let throng_list = JSON.parse(JSON.stringify(this.state.throng_list))
        if (throng_list[index1].tag_category_list[index2].name === "性别" && this.props.user.account_type === "1" && this.state.is_native) {
            message.warning(`基础共创不可配置“性别”标签的百分比，请联系商务进行升级`, 3, () => { })
            return
        }
        throng_list[index1].tag_category_list[index2].result[index3].has_condition_percentage = !throng_list[index1].tag_category_list[index2].result[index3].has_condition_percentage
        if (!throng_list[index1].tag_category_list[index2].result[index3].has_condition_percentage) {
            throng_list[index1].tag_category_list[index2].result[index3].condition_percentage = throng_list[index1].tag_category_list[index2].result[index3].condition_percentage ? throng_list[index1].tag_category_list[index2].result[index3].condition_percentage : 0
        }
        this.setState({
            throng_list
        })
    }
    render() {
        return (
            <div className={this.state.project_entry_number === 2 ? 'tag_dispatch' : 'tag_dispatch isHide'}>
                {
                    this.state.loading <= 0 && this.state.loading_tag_category <= 0 && this.state.loading_save <= 0 ? "" : <Loading loadingText={this.state.loadingText}></Loading>
                }
                <div className='tag-dispatch-top'>
                    <img src={project_icon_bqps} alt="" />

                    <div className='msg'>
                        <div>为什么要添加标签派送？</div>
                        <div>添加派送标签可以更精准的将产品推送到给目标用户。</div>
                    </div>
                </div>
                <div className='dispatch-radio'>
                    <span className='dispatch_radio_sub'>派送人群选择</span>
                    <div className='dispatch-radio-item' onClick={this.handle_click_radio.bind(this, 1)}>
                        <div className='item-border'>
                            <img src={this.state.radio_number === 1 ? cur_icon_choice_sel : cur_icon_choice_nor} alt="" />
                        </div>
                        <span style={{ color: this.state.radio_number === 1 ? '#322D2C' : '' }}>标签匹配派送</span>
                    </div>
                    <div className='dispatch-radio-item' onClick={this.handle_click_radio.bind(this, 2)}>
                        <div className='item-border'>
                            <img src={this.state.radio_number === 2 ? cur_icon_choice_sel : cur_icon_choice_nor} alt="" />
                        </div>
                        <span style={{ color: this.state.radio_number === 2 ? '#322D2C' : '' }}>随机派送</span>
                    </div>
                </div>
                <div className={this.state.radio_number === 1 ? "" : "isHide"}>
                    <div className='setting_main'>
                        {
                            this.state.throng_list.map((item, index) => {
                                return (
                                    <div className='throng-setting' key={index}>
                                        <div className='throng-top'>  {/* 人群顶部 */}
                                            <div className='throng-top-left'>
                                                <div className='left-title'>
                                                    <input type='text' value={item.name} onChange={this.update_throng_name.bind(this, index)} />
                                                </div>
                                                <div className='left-percent'>
                                                    <input type='text' maxLength="3" onBlur={this.handleThrongPercentInput.bind(this, index)} onInput={this.validateNumber.bind(this)} defaultValue={item.ta_percentage} key={item.ta_percentage} />
                                                </div>
                                                <div className='left-percent-sign'>
                                                    %
                                                </div>
                                            </div>
                                            <div className="throng-top-right">
                                                <img src={project_icon_del} onClick={this.delete_throng_items.bind(this, index)} alt=""
                                                    className={this.canDeleteThrongItems.call(this, index) ? "" : "isHide"} />
                                            </div>
                                        </div>
                                        {/* 必填 */}
                                        <div className='tag-setting tag-setting-must'>
                                            <div className='tag-setting-items'>{/* 人群必填 */}
                                                {
                                                    item.tag_category_list.map((item2, index2) => {
                                                        return (
                                                            <div className={item2.tag_flag && item2.flag === 1 ? 'tag-setting-list' : 'isHide'} key={index2}>
                                                                <div className='tag-setting-list-contain'>
                                                                    <div className='tag-setting-list-title-wrapper'>
                                                                        <img src={cur_icon_delete} onClick={this.delete_tags_list.bind(this, index, index2)} alt="" className={this.canDeleteTag.call(this, index, index2) ? "tag-title-icon" : "isHide"} />
                                                                        <div className={this.canDeleteTag.call(this, index, index2) ? "isHide" : "tag-title-must"}>*</div>
                                                                        <div className={this.canDeleteTag.call(this, index, index2) ? "" : "tag-title"}>
                                                                            {item2.name}
                                                                        </div>
                                                                    </div>

                                                                    <div className={this.canAddTagsItem.call(this, index, index2) ? 'tag-setting-list-add-wrapper' : 'isHide'} onClick={this.add_tags_item.bind(this, index, index2)}>
                                                                        <img src={cur_icon_add} alt="" />
                                                                        <span>添加</span>  {/* 添加子标签 */}
                                                                    </div>
                                                                </div>
                                                                <div className={item2.result.length > 0 ? 'tag-setting-padding-left' : 'isHide'}>
                                                                    <div className={item2.result.length > 1 ? 'line' : 'isHide'} style={{ height: 'calc(' + (50 * item2.result.length - 3) + 'px)' }}></div>

                                                                    <div className={item2.result.length > 1 ? 'icon' : 'isHide'}><img src={item2.rule ? cur_icon_or_white : cur_icon_and_pink} onClick={this.item_rule_click.bind(this, index, index2)} alt="" /></div>

                                                                    <div className='tag-dispatch-main'>
                                                                        {
                                                                            item2.result.length ? item2.result.map((item3, index3) => {
                                                                                return (
                                                                                    <div className='tag-dispatch-item' key={index3}>
                                                                                        {
                                                                                            <div className="tag-rule-icon"><img src={item3.tag_rule ? icon_text_yes : icon_text_no} onClick={this.item_tag_rule_click.bind(this, index, index2, index3)} alt="" /></div> // 反选
                                                                                        }
                                                                                        {
                                                                                            item2.type === 1 ? <div className='tag-dispatch-select'>
                                                                                                <XmSelect
                                                                                                    placeholder="请进行选择"
                                                                                                    value={item3.tag_id === "" ? null : item3.tag_id}
                                                                                                    options={this.get_select_tag_list.call(this, index, index2, index3)}
                                                                                                    onChange={this.onSelectTagChange.bind(this, index, index2, index3)}></XmSelect>
                                                                                            </div> : ""
                                                                                        }
                                                                                        {
                                                                                            item2.type === 2 ? <div className='tag-dispatch-select tag-dispatch-age'>
                                                                                                <XmSelect
                                                                                                    mode="tags" placeholder="请输入年龄,如20-30或35+" onChange={(e) => { this.onAgeSelectChange.call(this, e, index, index2, index3) }} maxTagCount={'responsive'} allowClear
                                                                                                    options={this.handleAgeListOption.call(this, index, index2, index3, item2)}
                                                                                                    value={this.handleDefaultAgeListOption.call(this, index, index2, index3)}
                                                                                                    onInput={this.validateAgeNumber.bind(this)}
                                                                                                    iskey="true"
                                                                                                ></XmSelect>
                                                                                            </div> : ""
                                                                                        }
                                                                                        {
                                                                                            item2.type === 3 ? <div className="tag-dispatch-select region-text">
                                                                                                <span>{item3.id ? item3.id : ''}</span>
                                                                                            </div> : ""
                                                                                        }
                                                                                        <div className={item2.rule ? 'tag-dispatch-item-percent' : 'isHide'}>
                                                                                            {
                                                                                                item3.has_condition_percentage ?
                                                                                                    <>
                                                                                                        <div className='tag-dispatch-item-percent-icon' onClick={this.show_condition_percentage.bind(this, index, index2, index3)}>
                                                                                                            <img src={icon_percent} alt="" />
                                                                                                        </div>
                                                                                                        <div className='tag-dispatch-item-percent-name'>
                                                                                                            <input type='text' maxLength="3" onBlur={this.handleTagPercentInput.bind(this, index, index2, index3)} onInput={this.validateNumber.bind(this)} defaultValue={item3.condition_percentage} key={item3.condition_percentage} />
                                                                                                        </div>
                                                                                                        <div className='tag-dispatch-item-percent-name-sign'>
                                                                                                            %
                                                                                                        </div>
                                                                                                    </> :
                                                                                                    <div className='tag-dispatch-item-percent-icon' onClick={this.show_condition_percentage.bind(this, index, index2, index3)}>
                                                                                                        <img src={icon_percent_nor} alt="" />
                                                                                                    </div>
                                                                                            }
                                                                                        </div>
                                                                                        <img className='cur-icon-delete cursor' src={cur_icon_delete} onClick={this.delete_tags_item.bind(this, index, index2, index3)} alt="" />
                                                                                    </div>
                                                                                )
                                                                            }) : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="add-tag-wrapper">
                                            <span onClick={this.handle_click_add_tags.bind(this, index)}>添加标签</span>
                                        </div>
                                        {/* 选填 */}
                                        <div className='tag-setting'>
                                            <div className={this.getShowNumber(item.tag_category_list) > 1 ? 'tag-setting-icon' : 'isHide'}>
                                                <img src={item.group_rule ? cur_icon_or_white : cur_icon_and_pink} onClick={this.rule_click.bind(this, index)} alt="" />
                                            </div>
                                            <div className='tag-setting-items'>
                                                {
                                                    item.tag_category_list.map((item2, index2) => {
                                                        return (
                                                            <div className={item2.tag_flag && item2.flag !== 1 ? 'tag-setting-list' : 'isHide'} key={index2}>
                                                                <div className='tag-setting-list-contain'>
                                                                    <div className='tag-setting-list-title-wrapper'>
                                                                        <img src={cur_icon_delete} onClick={this.delete_tags_list.bind(this, index, index2)} alt="" className={this.canDeleteTag.call(this, index, index2) ? "tag-title-icon" : "isHide"} />
                                                                        <div className={this.canDeleteTag.call(this, index, index2) ? "isHide" : "tag-title-must"}>*</div>
                                                                        <div className={this.canDeleteTag.call(this, index, index2) ? "" : "tag-title"}>
                                                                            {item2.name}
                                                                        </div>
                                                                    </div>

                                                                    <div className={this.canAddTagsItem.call(this, index, index2) ? 'tag-setting-list-add-wrapper' : 'isHide'} onClick={this.add_tags_item.bind(this, index, index2)}>
                                                                        <img src={cur_icon_add} alt="" />
                                                                        <span>添加</span>
                                                                    </div>
                                                                </div>
                                                                <div className={item2.result.length > 0 ? 'tag-setting-padding-left' : 'isHide'}>
                                                                    <div className={item2.result.length > 1 ? 'line' : 'isHide'} style={{ height: 'calc(' + (50 * item2.result.length - 3) + 'px)' }}></div>

                                                                    <div className={item2.result.length > 1 ? 'icon' : 'isHide'}><img src={item2.rule ? cur_icon_or_white : cur_icon_and_pink} onClick={this.item_rule_click.bind(this, index, index2)} alt="" /></div>

                                                                    <div className='tag-dispatch-main'>
                                                                        {
                                                                            item2.result.length ? item2.result.map((item3, index3) => {
                                                                                return (
                                                                                    <div className='tag-dispatch-item' key={index3}>
                                                                                        {
                                                                                            <div className="tag-rule-icon"><img src={item3.tag_rule ? icon_text_yes : icon_text_no} onClick={this.item_tag_rule_click.bind(this, index, index2, index3)} alt="" /></div> // 反选
                                                                                        }
                                                                                        {
                                                                                            item2.type === 1 ? <div className='tag-dispatch-select'>
                                                                                                <XmSelect
                                                                                                    placeholder="请进行选择"
                                                                                                    value={item3.tag_id === "" ? null : item3.tag_id}
                                                                                                    options={this.get_select_tag_list.call(this, index, index2, index3)}
                                                                                                    onChange={this.onSelectTagChange.bind(this, index, index2, index3)}></XmSelect>
                                                                                            </div> : ""
                                                                                        }
                                                                                        <div className={item2.rule ? 'tag-dispatch-item-percent' : 'isHide'}>

                                                                                            {
                                                                                                item3.has_condition_percentage ?
                                                                                                    <>
                                                                                                        <div className='tag-dispatch-item-percent-icon' onClick={this.show_condition_percentage.bind(this, index, index2, index3)}>
                                                                                                            <img src={icon_percent} alt="" />
                                                                                                        </div>
                                                                                                        <div className='tag-dispatch-item-percent-name'>
                                                                                                            <input type='text' maxLength="3" onBlur={this.handleTagPercentInput.bind(this, index, index2, index3)} onInput={this.validateNumber.bind(this)} defaultValue={item3.condition_percentage} key={item3.condition_percentage} />
                                                                                                        </div>
                                                                                                        <div className='tag-dispatch-item-percent-name-sign'>
                                                                                                            %
                                                                                                        </div>
                                                                                                    </> :
                                                                                                    <div className='tag-dispatch-item-percent-icon' onClick={this.show_condition_percentage.bind(this, index, index2, index3)}>
                                                                                                        <img src={icon_percent_nor} alt="" />
                                                                                                    </div>
                                                                                            }
                                                                                        </div>
                                                                                        <img className='cur-icon-delete cursor' src={cur_icon_delete} onClick={this.delete_tags_item.bind(this, index, index2, index3)} alt="" />
                                                                                    </div>
                                                                                )
                                                                            }) : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className='add-tag' onClick={this.addThrongList.bind(this)}>添加人群</div>
                    <div className='tag-dispatch-reslute'>
                        <div className="reslute-left">派送选择结果：</div>
                        <div className="reslute-right">
                            {
                                this.state.throng_list.map((item, index) => { //人群1 2
                                    return (
                                        <div key={index}>{this.displayThrongName.call(this, index)}</div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div >
                <div className="dispatch-min-unit">
                    预估可派送人数（敬请期待）
                </div>
                <div className="operaion-row">
                    <div onClick={this.prev.bind(this)}>上一步</div>
                    <div onClick={this.save.bind(this)}>保存</div>
                    <div onClick={this.next.bind(this, 'next')} className={this.canNext() ? "next-active" : ""}>下一步</div>
                </div>
                <div className={this.state.AddTags_status ? '' : 'isHide'}>
                    <AddTags ref={this.tagsRef} get_AddTags_status_Add_Throng_list={this.get_AddTags_status_Add_Throng_list}></AddTags>
                </div>
                <div className={this.state.region_popup_status ? '' : 'isHide'}>
                    <RegionPopup ref={this.regionRef} get_region_popup_state={this.get_region_popup_state.bind(this)} ></RegionPopup>
                </div>
            </div >
        )
    }
}

export default connect(
    state => ({
        user: state.user,
    }),//映射状态
    {}//映射操作状态的方法
)(TagDispatch)