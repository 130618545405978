
import React, { Component } from 'react'
import { connect } from 'react-redux'
import './base_info.scss';
import cur_icon_select from '../../../images/cur_icon_select.png';
import Toast from "../../../components/Toast/Toast";
import XmSelect from '../../../components/Select/xm_select';
import XmModel from '../../../components/Xm_model/Xm_model'
import Loading from "../../../components/Loading/loading"
import 'antd/dist/antd.css'
import 'moment/locale/zh-cn';
import locale from 'antd/lib/date-picker/locale/zh_CN';
import moment from 'moment';
import NoProductPopup from './Popup/no_product_popup'
// import validate_msg_array from "./ValidateMsg"
import UserAPI from "../../../api/UserAPI";
import { getUser } from "../../../redux/actions/user"
import { handleUploadImg } from "../../../util/utils"
import upload_add_img from '../../../images/upload_add_img.png'
import upload_close_img from '../../../images/upload_close.png'
import { DatePicker, message, Upload, Image, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';


let that = ''
class base_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 0,
            loading_save: 0, // 防止保存的loading
            loadingText: "加载中",
            product_id: localStorage.getItem('product_id') ? localStorage.getItem('product_id') : '',    //产品ID
            brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : '',   //品牌ID
            project_id: localStorage.getItem('project_id') ? localStorage.getItem('project_id') : '',
            toastStatus: false,
            msg: '',       //验证错误信息
            baseInfo_width: '',       //底部的宽度
            baseInfo_main_Height: '',     //主内容的高度
            project_entry_number: 0,     //第1步

            dispatch_brand_text: '',    //派送品牌
            dispatch_brand_val: '',
            dispatch_brand_list: [],

            dispatch_product_text: '',    //派送产品
            dispatch_product_val: '',
            dispatch_product_list: [],

            dispatch_amount_val_prev: '',       //派送数量
            dispatch_amount_val: '',       //派送数量

            dispatch_weight_text: '',       //单件重量
            dispatch_weight_val: '',
            dispatch_weight_list: [],

            name_val: "", //项目名称
            dispatch_min_unit_val: '',    //最小派送单位

            dispatch_min_unit_image_val: "",  //最小派送单位图片
            filename: '',
            modelContent: "确定删除该图片吗？",
            showModel: false,
            modelTitle: "删除提示",

            dispatch_expect_val: '',     //派送效果预期

            product_expire_date_val: '',     //产品保质到期时间

            product_development_text: '', //产品研发阶段
            product_development_val: '',
            product_development_list: [],

            hasFilledTag: false,//是否是第一次进入，没有填写过tag false 没有，true 填写过
            project_type: "0",
            hasProduct: true, // 判断品牌是否有产品
            showNoProductPopup: false // 是否显示没有产品的提示框
        }

    }

    static getDerivedStateFromProps(props, state) {

        if (JSON.stringify(props.tab_key) !== JSON.stringify(state.tab_key_prev)) {
            return {
                needRefresh: true,
                project_entry_number: props.project_entry_number || 0, //question
                tab_key_prev: props.tab_key,
                tab_key: props.tab_key,
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
        that = this
        this.setState({
            project_entry_number: this.props.project_entry_number
        })
    }

    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            // this.toInitChart()
            // setTimeout(() => {
            //     this.toInitFoot()
            // })
            this.GetProjectBasic()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        // clearInterval(window.get_base_info_width)           //销毁监听
        this._isMounted = false;
        this.setState = () => { }
        console.log('Component WILL UNMOUNT!')
    }
    async GetProjectBasic() {    //获取项目基础信息
        await this.GetBrand()
        await this.GetProductWeights()
        await this.GetProductDevelopments()
        // 没有 project_id 则是新建
        if (this.state.project_id) {
            this.setState({ loading: 1 })
            UserAPI.GetProjectBasic({ project_id: this.state.project_id }).then((res) => {
                if (res.status == 200) {
                    let dispatch_product_text = ''
                    let dispatch_product_val = res.data.dispatch_product

                    let dispatch_brand_text = ''
                    let dispatch_brand_val = res.data.dispatch_brand_id

                    this.setState({
                        dispatch_brand_val: res.data.dispatch_brand_id
                    }, () => {
                        this.GetBrandProduct()
                    })

                    let dispatch_weight_text = ''
                    let dispatch_weight_val = res.data.dispatch_weight
                    let product_development_text = ''
                    let product_development_val = res.data.product_development

                    for (let i = 0; i < this.state.dispatch_brand_list.length; i++) {
                        if (this.state.dispatch_brand_list[i].key == dispatch_brand_val) {
                            dispatch_brand_text = this.state.dispatch_brand_list[i].value
                        }
                    }

                    for (let i = 0; i < this.state.dispatch_product_list.length; i++) {
                        if (this.state.dispatch_product_list[i].key == dispatch_product_val) {
                            dispatch_product_text = this.state.dispatch_product_list[i].value
                        }
                    }
                    for (let i = 0; i < this.state.dispatch_weight_list.length; i++) {
                        if (this.state.dispatch_weight_list[i].key == dispatch_weight_val) {
                            dispatch_weight_text = this.state.dispatch_weight_list[i].value
                        }
                    }
                    for (let i = 0; i < this.state.product_development_list.length; i++) {
                        if (this.state.product_development_list[i].key == product_development_val) {
                            product_development_text = this.state.product_development_list[i].value
                        }
                    }
                    this.setState({
                        dispatch_amount_val_prev: res.data.dispatch_amount,
                        dispatch_amount_val: res.data.dispatch_amount,
                        dispatch_brand_text,
                        dispatch_brand_val,
                        dispatch_product_text,
                        dispatch_product_val,
                        dispatch_weight_text,
                        dispatch_weight_val,
                        dispatch_expect_val: res.data.dispatch_expect,
                        name_val: res.data.project_name,
                        dispatch_min_unit_val: res.data.dispatch_min_unit,
                        product_development_text,
                        product_development_val,
                        dispatch_min_unit_image_val: res.data.dispatch_min_unit_image,
                        product_expire_date_val: res.data.product_expire_date,
                        project_type: res.data.project_type,
                        loading: 0
                    })
                    this.props.getData({
                        survey_id: res.data.survey_id
                    })
                }
            }).catch(error => {
                message.error('获取失败，后台发生错误')
                this.setState({
                    loading: 0
                })
            })
        } else {
            localStorage.removeItem('current_project_progress')
            localStorage.removeItem('finished_project_quota')
        }
    }

    GetBrand() {         //获取品牌所有产品
        return new Promise((resolve, reject) => {
            UserAPI.GetBrand().then((res) => {
                if (res.status === 200) {
                    let dispatch_brand_list = []
                    for (let i = 0; i < res.data.length; i++) {
                        let obj = { key: res.data[i].id, value: res.data[i].name, product_count: res.data[i].product_count }
                        dispatch_brand_list.push(obj)
                    }
                    this.setState({
                        dispatch_brand_list: dispatch_brand_list
                    })
                    // this.setState({
                    //     dispatch_product_list: res.data
                    // })
                    resolve(true)
                }
            })
        })
    }


    GetBrandProduct() {         //获取品牌所有产品
        return new Promise((resolve, reject) => {
            UserAPI.GetBrandProduct({ brand_id: this.state.dispatch_brand_val }).then((res) => {
                if (res.status === 200) {
                    let dispatch_product_list = []
                    for (let i = 0; i < res.data.length; i++) {
                        let obj = { key: res.data[i].id, value: res.data[i].title }
                        dispatch_product_list.push(obj)
                    }
                    this.setState({
                        dispatch_product_list: dispatch_product_list
                    })
                    // this.setState({
                    //     dispatch_product_list: res.data
                    // })
                    resolve(true)
                }
            })
        })
    }

    GetProductWeights() {  //获取重量分级
        return new Promise((resolve, reject) => {
            UserAPI.GetProductWeights({}).then((res) => {
                if (res.status === 200) {
                    let dispatch_weight_list = []
                    for (let i = 0; i < res.data.length; i++) {
                        let obj = { key: res.data[i].id, value: res.data[i].weight }
                        dispatch_weight_list.push(obj)
                    }
                    this.setState({
                        dispatch_weight_list: dispatch_weight_list
                    })
                    resolve(true)
                }
            })
        })
    }

    GetProductDevelopments() {   //获取产品研发阶段
        return new Promise((resolve, reject) => {
            UserAPI.GetProductDevelopments({}).then((res) => {
                if (res.status === 200) {
                    let product_development_list = []
                    for (let i = 0; i < res.data.length; i++) {
                        let obj = { key: res.data[i].id, value: res.data[i].development }
                        product_development_list.push(obj)
                    }
                    this.setState({
                        product_development_list: product_development_list
                    })
                    resolve(true)
                }
            })
        })
    }

    disabledDate(current) {
        // clearInterval(window.get_base_info_width)
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    //下拉框选择后，并赋值到state里面
    onSelectChange(key, option, t) {

        let cur_index = parseInt(option.key)
        this.setState({
            [t + '_text']: this.state[t + '_list'][cur_index].value,
            [t + '_val']: this.state[t + '_list'][cur_index].key,
        }, () => {
            if (t === "dispatch_brand") {
                this.setState({
                    dispatch_product_text: '',
                    dispatch_product_val: "",
                })
                if (this.state.dispatch_brand_list[cur_index].product_count > 0) {
                    this.setState({
                        dispatch_product_list: [],
                        hasProduct: true
                    })
                    this.GetBrandProduct()
                } else { // 品牌没有产品
                    this.setState({
                        dispatch_product_list: [],
                        hasProduct: false
                    })
                }

            }
        })
    }
    // 判断人数额度是否正确
    handleQuotaJudge(event) {
        let value = Number(event.target.value)
        if (parseInt(localStorage.getItem('current_project_progress')) >= 6) {
            message.warning('问卷已经结束，不可修改', 4, () => { })
            this.setState({ dispatch_amount_val: this.state.dispatch_amount_val_prev })
            return
        }
        let dispatch_amount_val_prev = parseInt(this.state.dispatch_amount_val_prev)
        let quota = parseInt(this.props.user.quota)
        if (this.state.project_id) {
            if (value < dispatch_amount_val_prev) {
                message.warning('人数额度需要大于等于之前的配置', 4, () => { })
                this.setState({ dispatch_amount_val: this.state.dispatch_amount_val_prev })
                return
            } else if (value > (quota + dispatch_amount_val_prev)) {
                this.setState({ dispatch_amount_val: this.state.dispatch_amount_val_prev })
                message.warning('账户人数余额不够，请联系商务进行升级', 4, () => { })
                return
            }
        } else if (value > (quota + dispatch_amount_val_prev)) {
            this.setState({ dispatch_amount_val: "" })
            message.warning('账户人数余额不够，请联系商务进行升级', 4, () => { })
            return
        }
        this.setState({
            dispatch_amount_val: String(value)
        })
    }
    // 单改变值，非下拉框的，比如填空题
    handleChange(t, event) {
        if (!t) return false
        if (!event) return false
        this.setState({ [t + '_val']: event.target.value })
    }
    // 显示日期
    handleChangeDate(t, date, string) {
        this.setState({ [t + '_val']: string })
    }
    //是否可以下一步标红
    canNext() {
        let flag = false

        if (this.state.dispatch_brand_text
            && this.state.dispatch_amount_val
            && this.state.dispatch_weight_text
            && this.state.name_val
            && this.state.name_val
            && this.state.dispatch_min_unit_val
            && this.state.dispatch_min_unit_image_val
            && this.state.product_development_text
            && this.state.dispatch_min_unit_val) {
            flag = true
        }
        if (this.state.hasProduct && !this.state.dispatch_product_text) {
            flag = false
        }
        return flag
    }
    next(t) {      //下一步
        let isValidate = this.validate()
        if (isValidate === 1) {
            let object = {
                dispatch_product_id: this.state.dispatch_product_val,
                dispatch_amount: this.state.dispatch_amount_val,
                dispatch_weight_id: this.state.dispatch_weight_val,
                name: this.state.name_val,
                dispatch_min_unit: this.state.dispatch_min_unit_val,
                dispatch_min_unit_image: {
                    filename: this.state.filename,
                    file: this.state.dispatch_min_unit_image_val
                },
                dispatch_expect: this.state.dispatch_expect_val,
                product_expire_date: this.state.product_expire_date_val,
                product_development_id: this.state.product_development_val,
                dispatch_brand_id: this.state.dispatch_brand_val,
            }
            if (this.state.loading_save) {
                return
            }
            this.setState({
                loading_save: 1,
                loadingText: "正在保存中，请稍后"
            })
            if (this.state.project_id) {
                object.project_id = this.state.project_id
                UserAPI.UpdateProjectBasic(object).then((res) => {
                    if (res.status === 202) {
                        message.success('更新成功', 1, () => { })
                        localStorage.setItem('project_id', res.data.project_id)
                        localStorage.setItem('dispatch_min_unit', this.state.dispatch_min_unit_val)
                        if (t === 'next') {
                            this.props.getData({
                                project_entry_number: 2, //第几步
                                product_id_for_template: this.state.dispatch_product_val,
                                dispatch_count: this.state.dispatch_amount_val
                            })
                        } else if (t === 'save_drag') {
                            localStorage.setItem('project_id_1', res.data.project_id)
                        }
                        this.setState({
                            project_id: res.data.project_id,
                            loading_save: 0
                        })
                        this.props.getUser()
                    }
                }).catch(err => {
                    if (err.response?.data?.detail) {
                        message.error(`${err.response.data.detail}`, 2, () => { })
                    }
                })
            } else {
                UserAPI.SaveProjectBasic(object).then((res) => {
                    if (res.status === 201) {
                        message.success('保存成功', 1, () => { })
                        localStorage.setItem('project_id', res.data.project_id)
                        localStorage.setItem('dispatch_min_unit', this.state.dispatch_min_unit_val)
                        if (t === 'next') {
                            this.props.getData({
                                project_entry_number: 2,//第几步
                                product_id_for_template: this.state.dispatch_product_val,
                                survey_id: res.data.survey_id,
                                dispatch_count: this.state.dispatch_amount_val
                            })
                        } else if (t === 'save_drag') {
                            this.props.getData({
                                project_entry_number: 1,//第几步
                                survey_id: res.data.survey_id,
                                dispatch_count: this.state.dispatch_amount_val
                            })
                            localStorage.setItem('project_id_1', res.data.project_id)
                        }
                        this.setState({
                            project_id: res.data.project_id,
                            loading_save: 0
                        })
                        this.props.getUser()
                    }
                }).catch(err => {
                    this.setState({
                        loading_save: 0
                    })
                    if (err.response?.data?.detail) {
                        message.error(`${err.response.data.detail}`, 2, () => { })
                    }
                })
            }

        } else {
            if (isValidate === 2) { //判断没有产品提示弹窗的 否则会进行toastStatus的操作
                return
            }
            if (!this.state.toastStatus) {
                this.setState({
                    toastStatus: true
                })
                setTimeout(() => {
                    this.setState({
                        toastStatus: false
                    })
                }, 2000)
            }
        }
    }

    //验证
    validate() {
        let flag = 1
        if (this.state.dispatch_brand_text.length <= 0) {
            this.setState({ msg: "请选择派送品牌" })
            return 0
        }
        if (!this.state.hasProduct) { // 品牌下没有产品则显示弹窗
            this.setState({ showNoProductPopup: "true" })
            return 2
        }
        if (this.state.hasProduct && this.state.dispatch_product_val.length <= 0) {
            this.setState({ msg: "请选择派送产品" })
            return 0
        }
        if (this.state.dispatch_amount_val === 0 || this.state.dispatch_amount_val === '') {
            this.setState({ msg: "派送数量要求大于0" })
            return 0
        }
        if (this.state.dispatch_weight_text.length <= 0) {
            this.setState({ msg: "请选择单件重量" })
            return 0
        }
        if (!this.state.name_val) {
            this.setState({ msg: "请输入项目名称" })
            return 0
        }
        if (!this.state.dispatch_min_unit_val.length) {
            this.setState({ msg: "请选择最小派送单位" })
            return 0
        } else {
            let char = 0
            let reg = /[\u4e00-\u9fa5]/
            for (let i = 0; i < this.state.dispatch_min_unit_val.length; i++) {
                if (this.state.dispatch_min_unit_val[i].match(reg)) {
                    char += 2
                } else {
                    char += 1
                }
            }
            if (char > 50) {
                this.setState({ msg: "最小派送单位字符不能超过50个" })
                return 0
            }
        }
        if (!this.state.dispatch_min_unit_image_val) {
            this.setState({ msg: "请上传最小派送单位图片" })
            return 0
        }
        if (!this.state.product_development_text) {
            this.setState({ msg: "请选择产品研发阶段" })
            return 0
        }
        return flag
    }
    // 下拉框搜索可以大小写
    filterSelectOption(input, option) {
        return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    // 子组件传给父组件状态
    canShowNoProductPopup(object) {
        this.setState({
            showNoProductPopup: object.showNoProductPopup
        })
    }
    // 上传图片
    handleUpload(args) {
        handleUploadImg(args).then(res => {
            if (res !== -1) {
                this.setState({
                    dispatch_min_unit_image_val: res.imgs,
                    filename: res.filename
                })
            }
        })
    }
    //图片设置为空
    handleDeleteUpload() {
        this.setState({
            dispatch_min_unit_image_val: "",
            filename: ""
        })
    }
    // 第一个参数是设置是否显示，第二个参数就是子组件选确认
    handleModel(val, flag) {
        if (flag) {
            this.handleDeleteUpload()
        }
        this.setState({
            showModel: val,
        })
    }
    render() {
        let suffixIcon = (
            <img src={cur_icon_select} alt="" className='select-icon' />
        )
        return (
            <div className='base-info' style={{ height: this.state.baseInfo_main_Height }}>
                {
                    this.state.loading <= 0 && this.state.loading_save <= 0 ? "" : <Loading loadingText={this.state.loadingText}></Loading>
                }
                <div className='base-info-item'>
                    <div className='base-info-left'><span>*</span>项目名称</div>


                    <div className='base-info-select'>
                        <input placeholder='请输入项目名称' className='base-info_right cursor' type='text' defaultValue={this.state.name_val} onChange={this.handleChange.bind(this, 'name')} />
                    </div>
                </div>
                <div className='base-info-item'>
                    <div className='base-info-left'><span>*</span>派送品牌</div>
                    <div className='base-info-select dispatch-product'>
                        <XmSelect
                            getPopupContainer={triggerNode => {
                                return triggerNode.parentNode || document.body
                            }}
                            showSearch
                            filterOption={(input, option) => {
                                return this.filterSelectOption.call(this, input, option)
                            }
                            }
                            placeholder="请选择派送品牌"
                            defaultValue={this.state.dispatch_brand_val || null} key={this.state.dispatch_brand_val}
                            options={this.state.dispatch_brand_list}
                            onChange={(e, index) => {
                                this.onSelectChange(e, index, 'dispatch_brand')
                            }}></XmSelect>
                    </div>
                </div>
                <div className={this.state.hasProduct ? "base-info-item" : "isHide"}>
                    <div className='base-info-left'><span>*</span>派送产品</div>
                    <div className='base-info-select dispatch-product'>
                        <XmSelect
                            getPopupContainer={triggerNode => {
                                return triggerNode.parentNode || document.body
                            }}
                            showSearch
                            filterOption={(input, option) => {
                                return this.filterSelectOption.call(this, input, option)
                            }
                            }
                            placeholder="请选择派送产品"
                            defaultValue={this.state.dispatch_product_val || null} key={this.state.dispatch_product_val}
                            options={this.state.dispatch_product_list}
                            onChange={(e, index) => {
                                this.onSelectChange(e, index, 'dispatch_product')
                            }}></XmSelect>
                    </div>
                </div>
                <div className='base-info-item'>
                    <div className='base-info-left'><span>*</span>派送数量</div>
                    <div className='base-info-select'>
                        <input placeholder='请输入派送数量' className='base-info_right' type='number' min="0" value={this.state.dispatch_amount_val} onChange={this.handleChange.bind(this, 'dispatch_amount')} onBlur={this.handleQuotaJudge.bind(this)} />
                    </div>
                </div>
                <div className='base-info-item'>
                    <div className='base-info-left'><span>*</span>单件重量</div>
                    <div className='base-info-select dispatch-weight'>
                        <XmSelect options={this.state.dispatch_weight_list} defaultValue={this.state.dispatch_weight_val || null} key={this.state.dispatch_weight_val}
                            onChange={(e, index) => { this.onSelectChange(e, index, "dispatch_weight") }}
                            placeholder="请选择单件重量"></XmSelect>
                    </div>
                </div>
                <div className='base-info-item'>
                    <div className='base-info-left'><span>*</span>最小派送单位（每人）</div>
                    <div className='base-info-select'>
                        <input placeholder='请输入，如 2 件' className='base-info_right cursor' type='text' defaultValue={this.state.dispatch_min_unit_val} onChange={this.handleChange.bind(this, 'dispatch_min_unit')} />
                        <div className='base-info-image-wrapper'>
                            <div className='base-info-image'>
                                <div className={this.state.dispatch_min_unit_image_val ? "isHide" : "show-upload-wrap"}>
                                    <Upload
                                        showUploadList={false}
                                        customRequest={this.handleUpload.bind(this)}
                                        onRemove={this.handleRemove}
                                    >
                                        <img src={upload_add_img} alt="" className="upload-pic" />
                                    </Upload>
                                </div>
                                <div className={this.state.dispatch_min_unit_image_val ? "show-pic-wrap" : "isHide"}>
                                    <ConfigProvider locale={zhCN}>
                                        <Image src={this.state.dispatch_min_unit_image_val} alt="" className="show-pic" width={72} height={72} />
                                    </ConfigProvider>
                                    <img src={upload_close_img} alt="" className="upload-close-img" onClick={this.handleModel.bind(this, true, false)} />
                                </div>
                            </div>
                            <div className="base-info-image-text">（这里是最小派送单位的图样）</div>
                        </div>

                    </div>
                </div>

                <div className='base-info-item'>
                    <div className='base-info-left'><span></span>派送效果预期</div>
                    <div className='base-info-select'>
                        <input placeholder='请输入派送效果预期' className='base-info_right cursor' type='text' defaultValue={this.state.dispatch_expect_val} onChange={this.handleChange.bind(this, 'dispatch_expect')} />
                    </div>
                </div>
                <div className='base-info-item'>
                    <div className='base-info-left'><span></span>产品保质到期时间</div>
                    <div className='base-info-select'>
                        {/* <DatePicker className="date_picker" disabledDate={this.disabledDate} placeholder={this.state.product_expire_date_val ? this.state.product_expire_date_val : "请选择产品保质到期时间"} locale={locale} onChange={this.handleChangeDate.bind(this, "product_expire_date")} suffixIcon={suffixIcon} showArrow/> */}
                        <DatePicker className="date_picker" disabledDate={this.disabledDate} placeholder={this.state.product_expire_date_val ? this.state.product_expire_date_val : "请选择产品保质到期时间"} locale={locale} onChange={this.handleChangeDate.bind(this, "product_expire_date")} />
                    </div>
                </div>
                <div className='base-info-item'>
                    <div className='base-info-left'><span>*</span>产品研发阶段</div>
                    <div className='base-info-select product-development'>
                        <XmSelect options={this.state.product_development_list} defaultValue={this.state.product_development_val || null} key={this.state.product_development_val}
                            onChange={(e, index) => { this.onSelectChange(e, index, "product_development") }}
                            placeholder="请选择产品研发阶段"></XmSelect>
                    </div>
                </div>
                <div className="operaion-row">
                    <div onClick={this.next.bind(this, 'save_drag')}>保存草稿</div>
                    <div onClick={this.next.bind(this, 'next')} className={this.canNext() ? "next-active" : ""}>下一步</div>
                </div>
                <Toast toastStatus={this.state.toastStatus} msg={this.state.msg}></Toast>
                <div className={this.state.showNoProductPopup ? '' : 'isHide'}>
                    <NoProductPopup history={this.props.history} canShowNoProductPopup={this.canShowNoProductPopup.bind(this)}></NoProductPopup>
                </div>
                <div className='delete-image-popup'>
                    <XmModel isShowModel={this.state.showModel} handleShowModel={this.handleModel.bind(this)} modelContent={this.state.modelContent} modelTitle={this.state.modelTitle}></XmModel>
                </div>
            </div >
        )
    }
}


export default connect(
    state => ({
        user: state.user,
    }),//映射状态
    { getUser }//映射操作状态的方法
)(base_info)