import * as echarts from 'echarts';
import { addListener } from "resize-detector";

export function formatChart(id, option) {
    if (document.getElementById(id)) {
        echarts.dispose(document.getElementById(id))
    }
    if (document.getElementById(id)) {
        let singleChart = echarts.init(document.getElementById(id));
        singleChart.setOption(option, true);
        addListener(document.getElementById(id), () => {
            singleChart.resize()
        })
    }
}