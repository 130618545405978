import React, { Component } from 'react'
import "./matrix_single_info.scss"
import { getQuestionType } from '../../../Question_rules/question_type_rule';
import EditNode from '../../../../../EditNode/edit_node';
import XmUpload from '../../../../../Upload_pic/xm_upload';
import cur_icon_circle_sel from "../../../../../../images/emotion_analysis/cur_icon_circle_sel.png"
import cur_icon_circle_nor from "../../../../../../images/emotion_analysis/cur_icon_circle_nor.png"
import cur_icon_rect_sel from "../../../../../../images/emotion_analysis/cur_icon_rect_sel.png"
import cur_icon_rect_nor from "../../../../../../images/emotion_analysis/cur_icon_rect_nor.png"
import cur_icon_delete from "../../../../../../images/cur_icon_delete.png"
import cur_icon_add from "../../../../../../images/cur_icon_add.png"


export default class matrix_single_info extends Component {
    constructor(props) {
        super(props);
        this.has_fixed_list = [11, 12, 13] // 最大小值 名称
        this.has_option_active_list = [1, 2, 12, 13, 4] //单选多选排序的选项上下家
        this.state = {
            name: 'single_info',
            dataList: {},
            answers: [],
            row_answers: [],
            column_answers: [],
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                answers: props.dataList.answers || state.answers,
                row_answers: props.dataList.answers.filter(item => item.option_position === "left"),
                column_answers: props.dataList.answers.filter(item => item.option_position === "up"),
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
        // if (this._isMounted) {
        //     this.toInitChart()
        //     setTimeout(() => {
        //         this.toInitFoot()
        //     })
        // }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            // this.toInitChart()
            // setTimeout(() => {
            //     this.toInitFoot()
            // })
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    // 编辑选项
    onChangeEditor(option_index, option_position, obj) {
        this.props.handleQuestionOptionName(option_index, obj.value, option_position)
    }
    // 添加选项图片
    addOptionPic(option_index, option_position, image, filename) {
        this.props.addOptionPic(option_index, image, filename, option_position)
    }
    // 删除选项图片
    deleteOptionPic(option_index, option_position) {
        this.props.deleteOptionPic(option_index, option_position)
    }
    //添加选项
    handleAddOption() {
        this.props.handleAddOption()
    }
    //添加行选项
    handleAddMatrixOption(option_position) {
        this.props.handleAddMatrixOption(option_position)
    }
    // 删除选项
    handleDeleteOption(option_index, option_position) {
        this.props.handleDeleteOption(option_index, option_position)
    }
    // 选项上下架
    handleActiveOption(option_index) {
        let option_position_index = -1
        let result_option_index = option_index
        for (let i = 0; i < this.state.dataList.answers.length; i++) {
            if (this.state.dataList.answers[i].option_position === "left") {
                option_position_index++
            }
            if (option_position_index === option_index) {
                result_option_index = i
                break
            }
        }
        this.props.handleActiveOption(result_option_index)
    }
    // 设置选项
    handleSetOption(option_index, option_position, e) {
        //如果提供了事件对象，则这是一个非IE浏览器 
        if (e && e.stopPropagation) {
            //因此它支持W3C的stopPropagation()方法 
            e.stopPropagation();
        } else {
            //否则，我们需要使用IE的方式来取消事件冒泡 
            window.event.cancelBubble = true;
        }

        this.props.handleSetOption(option_index, option_position)
    }
    // 获取当前选项的索引值
    getCurrentOptionIndex(currentOptionIndex, currentOptionPosition) {
        if (this.props.currentOptionIndex < 0) return -1;
        let current_question_type_val = getQuestionType(this.state.dataList.question_type)
        let has_matrix_list = ["matrix_single", "matrix_multiple", "matrix_blank", "matrix_psm"]

        let resultOptionIndex = currentOptionIndex
        if (has_matrix_list.includes(current_question_type_val)) {
            let option_position = this.state.dataList.answers[currentOptionIndex].option_position
            if (currentOptionPosition !== option_position) {
                return -1
            }
            let option_position_index = -1
            for (let i = 0; i < this.state.dataList.answers.length; i++) {
                if (this.state.dataList.answers[i].option_position === option_position) {
                    option_position_index++
                    if (i === currentOptionIndex) {
                        break
                    }
                }
            }
            resultOptionIndex = option_position_index
        }
        return resultOptionIndex
    }
    render() {
        let column_items = [];
        for (let i = 0; i < this.state.column_answers.length; i++) {
            if (this.state.dataList.question_type === 16) {
                column_items.push(<div className="grade-item-2" key={i}>
                </div>);
            } else {//矩阵单选多选
                if (i === 0) {
                    column_items.push(<div className="grade-item" key={i}>
                        <img src={(this.state.dataList.question_type === 8) || (this.state.dataList.question_type === 22) ? cur_icon_circle_sel : cur_icon_rect_sel} alt="" />
                    </div>);
                } else {
                    column_items.push(<div className="grade-item" key={i}>
                        <img src={(this.state.dataList.question_type === 8) || (this.state.dataList.question_type === 22) ? cur_icon_circle_nor : cur_icon_rect_nor} alt="" />
                    </div>);
                }
            }
        }
        return (
            <div className="matrix-single-info-container">
                <div className="matrix-score-column-answer">
                    <div className="matrix-score-column-answer-left">
                        {
                            this.state.column_answers.map((item, index) => {
                                return (
                                    <div className='draw-slip-row' key={index}>
                                        <div className="draw-slip-left draw-slip-left-border">
                                            <div className='draw-slip-title'>
                                                <div className={`edit-question-title-wrap  ${item.is_option_active === 1 ? "" : "edit-question-title-wrap-2"} ${index === this.getCurrentOptionIndex(this.props.currentOptionIndex, "up") ? "edit-question-title-wrap-setting" : ""}`} >
                                                    <EditNode value={item.name} onChange={this.onChangeEditor.bind(this, index, "up")} className="edit-question-title"></EditNode>
                                                    <div className="delete-up-option-btn-wrapper">
                                                        <div className="delete-up-option-btn">
                                                            <div className="delete-up-option-text" onClick={this.handleSetOption.bind(this, index, "up")}>设置</div>
                                                            <img src={cur_icon_delete} className='delete-option-icon' alt="" onClick={this.handleDeleteOption.bind(this, index, "up")} />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div>
                                                    <XmUpload className="upload-question-pic"
                                                        image_name={item.image.val}
                                                        handleDeletePic={this.deleteOptionPic.bind(this, index, "up")}
                                                        handleUploadPic={this.addOptionPic.bind(this, index, "up")}></XmUpload>
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        this.state.column_answers.length < 5 ? <div className="matrix-score-column-answer-right" onClick={this.handleAddMatrixOption.bind(this, "up")} >
                            <img src={cur_icon_add} className='add-option-icon' alt="" />
                            <div>添加列</div>
                        </div> : ""
                    }
                </div>
                <div className="matrix-score-row-answer">
                    {
                        this.state.row_answers.map((item, index) => {
                            return (
                                <div className={`draw-slip ${item.is_option_active === 1 ? "" : "draw-slip-2"} ${index === this.getCurrentOptionIndex(this.props.currentOptionIndex, "left") ? "draw-slip-setting" : ""}`} key={index}>
                                    <div className="draw-slip-left draw-slip-left-border">
                                        <div className='draw-slip-title'>
                                            <div className="edit-question-title-wrap">
                                                <EditNode value={item.name} onChange={this.onChangeEditor.bind(this, index, "left")} className="edit-question-title"></EditNode>
                                            </div>
                                            <div>
                                                <XmUpload className="upload-question-pic"
                                                    image_name={item.image.val}
                                                    handleDeletePic={this.deleteOptionPic.bind(this, index, "left")}
                                                    handleUploadPic={this.addOptionPic.bind(this, index, "left")}></XmUpload>
                                            </div>
                                        </div>
                                        <div className="draw-slip-index">
                                            {
                                                column_items
                                            }
                                        </div>
                                    </div >
                                    <div className="draw-slip-right">
                                        <img src={cur_icon_delete} className='delete-option-icon' alt="" onClick={this.handleDeleteOption.bind(this, index, "left")} />
                                        {
                                            <div className='option-active-wrapper' onClick={this.handleSetOption.bind(this, index, "left")}>
                                                {
                                                    <div>设置</div>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div >
                <div className="info-foot" onClick={this.handleAddMatrixOption.bind(this, "left")} >
                    <img src={cur_icon_add} className='add-option-icon' alt="" />
                    <div>添加选项</div>
                </div>

            </div>
        )
    }
}
