import axiosInstance from '../APIUtils'

// 保存
export function saveSpecialEvent(obj) {
    return axiosInstance({
        url: "/cem/saveSpecialEvent/",
        method: "POST",
        data: obj
    });
}

// 获取
export function getSpecialEvent(obj) {
    return axiosInstance({
        url: "/cem/getSpecialEvent/",
        method: "POST",
        data: obj
    });
}

// 更新
export function updateSpecialEvent(obj) {
    return axiosInstance({
        url: "/cem/updateSpecialEvent/",
        method: "POST",
        data: obj
    });
}