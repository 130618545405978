import html2canvas from "html2canvas";
import JSZip from "jszip";
import { message } from "antd";

//导出图片
export function exportImg(dom, name, scrollY = 0) {
  dom.style.backgroundColor = "#fff";
  let height = dom.offsetHeight;
  let width = dom.offsetWidth;
  html2canvas(dom, {
    backgroundColor: "#fff",
    height: height + 10,
    width: width,
    scrollY,
    useCORS: true,
  }).then(function (image) {
    if (image.msToBlob) {
      navigator.msSaveBlob(image.msToBlob(), `${name || Date.now()}.png`);
    } else {
      let url = image.toDataURL("image/png", 1.0);
      let $a = document.createElement("a");
      let type = "png";
      $a.href = url;
      $a.target = "_blank";
      $a.download = "" + name + "." + type;
      document.body.appendChild($a);
      $a.click();
      document.body.removeChild($a);
    }
  });
}
// 批量下载
export function saveImgZip(batch_base_url) {
  let suffix = ".png";
  // 创建JSZip对象
  function downloadZip() {
    var zip = new JSZip();
    // 创建文件夹
    var img = zip.folder("images");
    for (let i = 0; i < batch_base_url.length; i++) {
      let name = String(batch_base_url[i].name);
      let imgBase64 = batch_base_url[i].base64_img.substring(22);
      img.file(name + suffix, imgBase64, { base64: true });
    }
    zip.generateAsync({ type: "blob" }).then(function (content) {
      var filename = "批量下载.zip";
      // 创建隐藏的可下载链接
      var eleLink = document.createElement("a");
      eleLink.download = filename;
      eleLink.style.display = "none";
      // 下载内容转变成blob地址
      eleLink.href = URL.createObjectURL(content);
      // 触发点击
      document.body.appendChild(eleLink);
      eleLink.click();
      // 然后移除
      document.body.removeChild(eleLink);
    });
  }
  downloadZip();
}
/**
 * 单个url参数
 */
export function getQueryParameter(name) {
  return getStrQueryParameter(window.location.href, name);
}
/**
 * string类型的url的参数,非location
 */
export function getStrQueryParameter(str, name) {
  let url = decodeURIComponent(str);
  /* eslint-disable no-useless-escape */
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(url);
  return results == null ? "" : decodeURIComponent(results[1]);
}

//设置blob
export function getBlob(url) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };
    xhr.send();
  });
}

var tmp = "1";

//url 转base64
export function getBase64Image(imgUrl) {
  const _this = this;
  return new Promise(function (resolve, reject) {
    getBlob(imgUrl).then((blob) => {
      console.log(blob);
      // let oFileReader = new FileReader();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      var image = "";
      reader.onloadend = function (event) {
        //读取数据时触发load
        console.log(1111);
        image = this.result;
        console.log(image);
        resolve(this.result);
      };
    });
  });
}
export function getBase64Image3(imgUrl) {
  const _this = this;
  getBlob(imgUrl).then((blob) => {
    console.log(blob);
    // let oFileReader = new FileReader();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    var image = "";
    reader.onloadend = function (event) {
      //读取数据时触发load
      console.log(1111);
      image = this.result;

      tmp = image;
      console.log(tmp);
      // console.log(event.target.result)

      // _this.state.image = imgs
      // _this.props.handleUploadPic(_this.state.image, _this.state.filename)
      // _this.setState({
      //   image: imgs,
      //   filename: filename
      // }, () => {
      //   console.log(_this.props)
      //   console.log(_this.state.image)
      //   _this.props.handleUploadPic(_this.state.image, _this.state.filename)
      // })
    };
    setTimeout(() => {
      console.log(tmp);
      return tmp;
    }, 1000);

    // oFileReader.onload = function (e) {
    //     console.log("hhh")
    //     let base64 = e.target.result
    //     console.log(base64)
    // }
  });
}

export function getBase64Image2(url) {
  let self = this;
  var image = new Image();
  image.setAttribute("crossOrigin", "anonymous"); // use it if you try in a different origin of your web

  image.onload = function () {
    console.log(1111);
    var canvas = document.createElement("canvas");
    canvas.width = image.width;
    canvas.height = image.height;

    canvas.getContext("2d").drawImage(this, 0, 0);

    canvas.toBlob(
      function (source) {
        var newImg = document.createElement("img"),
          url = URL.createObjectURL(source);

        newImg.onload = function () {
          // no longer need to read the blob so it's revoked
          URL.revokeObjectURL(url);
        };

        newImg.src = url;
      },
      "image/jpeg",
      1
    );

    // If you ever stumble at 18 DOM Exception, just use this code to fix it
    // let dataUrl = canvas.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
    let dataUrl = canvas.toDataURL("image/jpeg");
    console.log(dataUrl);
    console.log("cek inside url : ", url);

    // if (url === backgroundImg) {
    //     self.assignImageBase64Background(dataUrl);
    // } else {
    //     self.assignImageBase64(dataUrl);
    // }
  };
  console.log(url);
  // image.src = url;
}

// 前端自己建dom点击
export function downloadByAnchor(url, name) {
  getBlob(url).then((blob) => {
    let anchor = document.createElement("a");
    anchor.href = window.URL.createObjectURL(blob);
    anchor.download = name;
    anchor.click();
    return anchor;
  });
}
//下载图片
export function downloadPic(url, name) {
  downloadByAnchor(url, name);
}
// 下载excel
export function download(value, name, type) {
  let objectURL = null;
  switch (type) {
    case "binary":
      let blob = new Blob([value], {
        // 这里一定要和后端对应，不然可能出现乱码或者打不开文件
        type: "application/vnd.ms-excel",
        // type: 'application/json'
        // type: 'application/pdf;chartset=UTF-8'
      });
      objectURL = window.URL.createObjectURL(blob);
      downloadByAnchor(objectURL, name);
      URL.revokeObjectURL(objectURL);
      break;
    default:
    // window.open(value);
  }
}

// 下载 ppt
export function downloadPPT(res, type, filename) {
  let objectURL = null;
  const blob = new Blob([res], {
    // 如何后端没返回下载文件类型，则需要手动设置：type: 'application/pdf;chartset=UTF-8' 表示下载文档为pdf，如果是word则设置为msword，excel为excel
    type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  });

  objectURL = window.URL.createObjectURL(blob);
  downloadByAnchor(objectURL, filename);
  URL.revokeObjectURL(objectURL);
}

// 比较对象是否相等
export function isObjectEqual(a, b) {
  var aProps = Object.getOwnPropertyNames(a);
  var bProps = Object.getOwnPropertyNames(b);
  if (aProps.length != bProps.length) {
    return false;
  }
  for (var i = 0; i < aProps.length; i++) {
    var propName = aProps[i];

    var propA = a[propName];
    var propB = b[propName];
    // 这里忽略了值为undefined的情况
    // 故先判断两边都有相同键名
    if (!b.hasOwnProperty(propName)) return false;
    if (propA instanceof Object) {
      if (isObjectEqual(propA, propB)) {
        // return true     这里不能return ,后面的对象还没判断
      } else {
        return false;
      }
    } else if (propA !== propB) {
      return false;
    } else {
    }
  }
  return true;
}
// 上传图片
export function handleUploadImg(args) {
  return new Promise((resolve, reject) => {
    let _this = this; //烦人的this
    let filename = args.file.name || "";
    let type = args.file.type;
    let file_size = args.file.size || 0;
    if (type !== "image/jpeg" && type !== "image/png" && type !== "image/jpg") {
      message.warning("图片格式只能是jpg、jpeg、png", 2, () => { });
      resolve(-1);
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(args.file);
    reader.onload = function (event) {
      //读取数据时触发load
      let imgs = this.result;
      resolve({ imgs: imgs, filename: filename, file_size: file_size });
      // _this.setState({
      //     dispatch_min_unit_image_val: imgs,
      //     filename: filename
      // })
    };
  });
}

// 复制
export function copyText(textToCopy) {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        message.success("复制成功", 0.7, () => { });
      })
      .catch(() => {
        message.error("复制成功失败");
      });
  } else {
    // 创建text area
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    // 使text area不在viewport，同时设置不可见
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    return new Promise((resolve, reject) => {
      // 执行复制命令并移除文本框
      document.execCommand("copy") ? resolve() : reject(new Error("出错了"));
      textArea.remove();
    }).then(
      () => {
        message.success("复制成功", 0.7, () => { });
      },
      () => {
        message.error("复制成功失败");
      }
    );
  }
}
// 计算score
export function calScore(best, worst, count) {
  let score = (best - worst) / count
  return score.toFixed(2)
}
// 计算概率p
export function calP(best, worst, count) {
  let p = (count - worst + best) / (2 * count)
  return p
}
// 计算回归系数b
export function calB(best, worst, count) {
  let p = calP(best, worst, count)
  let b = Math.log(p / (1 - p))
  return b
}
// 计算偏好份额
export function calPreference(index, data_list) {
  let p = data_list[index].p
  let b = data_list[index].b
  let e_single = Math.exp(b)
  let e_sum = 0
  for (let i = 0; i < data_list.length; i++) {
    e_sum += Math.exp(data_list[i].b)
  }
  let preference = e_single / e_sum;
  return preference
}