
import React, { Component } from 'react'
import './Home.css';
import axiosInstance from '../../api/APIUtils';
import icon1 from '../../images/home_icon_project.png'
import icon2 from '../../images/home_icon_product.png'
import icon3 from '../../images/home_icon_problem.png'

import home_icon_lrxm from '../../images/home_icon_lrxm.png'
import home_icon_lrcp from '../../images/home_icon_lrcp.png'
import home_icon_gxtk from '../../images/home_icon_gxtk.png'
import home_icon_cksj from '../../images/home_icon_cksj.png'
import home_icon_more from '../../images/home_icon_more.png'
import home_icon_bcwa from '../../images/home_icon_bcwa.png'
import vchart from '../../images/vchart.png'
import cur_icon_close_white from '../../images/cur_icon_close_white.png'
import home_icon_guide from '../../images/home_icon_guide.png'
import * as echarts from 'echarts';
import { addListener } from "resize-detector";

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            message: "",
            guide_number: 1,                //引导的页数
            guide_text: [{ sub: '最新公告', text: '最新公告最新公告最新公告最新公告最新公告最新公告' }, //引导的文案
            { sub: '快速入口', text: '快速打开常用软件功能助力快速办公提高效率' }],
            option: {                                  //eharts 配置项及数据
                color: '#FF5186',
                grid: [{ left: 40, right: 15, top: 30, bottom: 20 }],
                xAxis: {
                    type: 'category',
                    data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [150, 230, 224, 218, 135, 5007, 3660, 8000, 135, 147, 260, 8000, 4000, 147, 260, 8000, 135, 147, 260, 8000, 135, 147, 260, 8000, 135, 147, 260, 8000, 135, 147, 260],
                        type: 'line'
                    }
                ]
            }
        }

        this.getMessage = this.getMessage.bind(this);

        this.handleLogout = this.handleLogout.bind(this);

    }

    async getMessage() {
        try {
            let response = await axiosInstance.get('/hello/');
            const message = response.data.hello;
            this.setState({
                message: message,
            });

            return message;

        } catch (error) {
            console.log("Error: ", JSON.stringify(error, null, 4));
            throw error;
        }
    };


    async handleLogout() {
        try {
            const response = await axiosInstance.post('/blacklist/', {
                'refresh_token': localStorage.getItem('refresh_token')
            });
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            axiosInstance.defaults.headers['Authorization'] = null;

            return response;
        } catch (e) {
            console.log(e);
        }

    }

    componentDidMount() {
        let myChart = echarts.init(document.getElementById('text_echart'));
        myChart.setOption(this.state.option, true);
        addListener(document.getElementById('text_echart'), () => {
            myChart.resize()
        })

        // const messageData1 = this.getMessage();
        // console.log("messageData1: ", JSON.stringify(messageData1, null, 4));
    }

    close() {                                      //关闭指引页面
        this.setState({ guide_number: 0 })
    }

    pre_next(string) {                                   //指引页面上一步和下一步
        let guide_number = this.state.guide_number
        if (guide_number === 0) { return false }
        if (guide_number === 1 && string === 'pre') { return false }
        if (guide_number === 2 && string === 'next') {
            this.setState({ guide_number: 0 })
            return false
        }
        if (string === 'pre') {
            guide_number--
            this.setState({ guide_number })
        }
        if (string === 'next') {
            guide_number++
            this.setState({ guide_number })
        }

    }

    render() {
        return (
            <div className='contain'>
                <div className='home_top'>
                    <span>欢迎来到心愿盒</span>
                    <span>2022-02-07 01:00:00</span>
                </div>
                <div className='home_main'>
                    <div className='home_main_left'>
                        <div className='home_tag'>
                            <div className='list'>
                                <img src={icon1} alt="" />
                                <div>
                                    <span>项目数量(个)</span>
                                    <span>3,212</span>
                                </div>
                            </div>
                            <div className='list'>
                                <img src={icon2} alt="" />
                                <div>
                                    <span>产品数量(个)</span>
                                    <span>13,212</span>
                                </div>
                            </div>
                            <div className='list'>
                                <img src={icon3} alt="" />
                                <div>
                                    <span>题目库(个)</span>
                                    <span>3,212</span>
                                </div>
                            </div>
                        </div>

                        <div className='home_title'>
                            <div className='contain_modules'>
                                <div className='contain_modules_title'>
                                    <div className='name'>
                                        <span className='line'></span>
                                        <span className='text'>标题文案</span>
                                    </div>
                                    <div className='date'>
                                        <span>日</span>
                                        <span>月</span>
                                        <span>年</span>
                                    </div>
                                </div>
                                <div id='text_echart' className='text_echart'></div>
                            </div>
                        </div>

                        <div className='home_product'>
                            <div className='contain_modules'>
                                <div className='contain_modules_title'>
                                    <div className='name'>
                                        <span className='line'></span>
                                        <span className='text'>项目列表</span>
                                    </div>
                                    <div className='more'>
                                        查看更多
                                    </div>
                                </div>
                                <div className='home_product_list home_padding_bottom'>
                                    <div className='list_title'>
                                        <span>序号</span>
                                        <span>产品名称</span>
                                        <span>合作时间</span>
                                        <span>派送完成进度</span>
                                        <span>评论情况</span>
                                        <span>NPS</span>
                                        <span>备注</span>
                                    </div>
                                    <div className='list_name'>
                                        <span>1</span>
                                        <span>产品名称文案文案文案文案…</span>
                                        <span>05/12 18:43</span>
                                        <span>已派送</span>
                                        <span>2314</span>
                                        <span>45%</span>
                                        <span>详情</span>
                                    </div>
                                    <div className='list_name'>
                                        <span>2</span>
                                        <span>产品名称文案文案文案文案…</span>
                                        <span>05/12 18:43</span>
                                        <span>已派送</span>
                                        <span>2314</span>
                                        <span>45%</span>
                                        <span>详情</span>
                                    </div>
                                    <div className='list_name'>
                                        <span>3</span>
                                        <span>产品名称文案文案文案文案…</span>
                                        <span>05/12 18:43</span>
                                        <span>已派送</span>
                                        <span>2314</span>
                                        <span>45%</span>
                                        <span>详情</span>
                                    </div>
                                    {/*<div className='list_name'>*/}
                                    {/*    <span>4</span>*/}
                                    {/*    <span>产品名称文案文案文案文案…</span>*/}
                                    {/*    <span>05/12 18:43</span>*/}
                                    {/*    <span>已派送</span>*/}
                                    {/*    <span>2314</span>*/}
                                    {/*    <span>45%</span>*/}
                                    {/*    <span>详情</span>*/}
                                    {/*</div>*/}
                                    {/*<div className='list_name'>*/}
                                    {/*    <span>5</span>*/}
                                    {/*    <span>产品名称文案文案文案文案…</span>*/}
                                    {/*    <span>05/12 18:43</span>*/}
                                    {/*    <span>已派送</span>*/}
                                    {/*    <span>2314</span>*/}
                                    {/*    <span>45%</span>*/}
                                    {/*    <span>详情</span>*/}
                                    {/*</div>*/}
                                    {/*<div className='list_name'>*/}
                                    {/*    <span>6</span>*/}
                                    {/*    <span>产品名称文案文案文案文案…</span>*/}
                                    {/*    <span>05/12 18:43</span>*/}
                                    {/*    <span>已派送</span>*/}
                                    {/*    <span>2314</span>*/}
                                    {/*    <span>45%</span>*/}
                                    {/*    <span>详情</span>*/}
                                    {/*</div>*/}
                                    {/*<div className='list_name'>*/}
                                    {/*    <span>7</span>*/}
                                    {/*    <span>产品名称文案文案文案文案…</span>*/}
                                    {/*    <span>05/12 18:43</span>*/}
                                    {/*    <span>已派送</span>*/}
                                    {/*    <span>2314</span>*/}
                                    {/*    <span>45%</span>*/}
                                    {/*    <span>详情</span>*/}
                                    {/*</div>*/}
                                    {/*<div className='list_name'>*/}
                                    {/*    <span>8</span>*/}
                                    {/*    <span>产品名称文案文案文案文案…</span>*/}
                                    {/*    <span>05/12 18:43</span>*/}
                                    {/*    <span>已派送</span>*/}
                                    {/*    <span>2314</span>*/}
                                    {/*    <span>45%</span>*/}
                                    {/*    <span>详情</span>*/}
                                    {/*</div>*/}
                                    {/*<div className='list_name'>*/}
                                    {/*    <span>9</span>*/}
                                    {/*    <span>产品名称文案文案文案文案…</span>*/}
                                    {/*    <span>05/12 18:43</span>*/}
                                    {/*    <span>已派送</span>*/}
                                    {/*    <span>2314</span>*/}
                                    {/*    <span>45%</span>*/}
                                    {/*    <span>详情</span>*/}
                                    {/*</div>*/}
                                    {/*<div className='list_name'>*/}
                                    {/*    <span>10</span>*/}
                                    {/*    <span>产品名称文案文案文案文案…</span>*/}
                                    {/*    <span>05/12 18:43</span>*/}
                                    {/*    <span>已派送</span>*/}
                                    {/*    <span>2314</span>*/}
                                    {/*    <span>45%</span>*/}
                                    {/*    <span>详情</span>*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='home_main_right'>
                        <div className={this.state.guide_number === 1 ? 'home_news position_relative' : 'home_news'}>
                            <div className='contain_modules noneTop'>
                                <div className='contain_modules_title'>
                                    <div className='name'>
                                        <span className='line'></span>
                                        <span className='text'>最新公告</span>
                                    </div>
                                </div>
                                <div className='home_news_list'>
                                    <div className='home_news_item'>
                                        <span>心愿盒v.42.0产品更新公告</span>
                                        <span>10/27</span>
                                    </div>
                                    <div className='home_news_item'>
                                        <span>心愿盒v.42.0产品更新公告</span>
                                        <span>10/27</span>
                                    </div>
                                    <div className='home_news_item'>
                                        <span>心愿盒v.42.0产品更新公告</span>
                                        <span>10/27</span>
                                    </div>
                                    <div className='home_news_item'>
                                        <span>心愿盒v.42.0产品更新公告</span>
                                        <span>10/27</span>
                                    </div>
                                    <div className='home_news_item'>
                                        <span>心愿盒v.42.0产品更新公告</span>
                                        <span>10/27</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={this.state.guide_number === 2 ? 'home_entry position_relative' : 'home_entry'}>
                            <div className='contain_modules'>
                                <div className='contain_modules_title'>
                                    <div className='name'>
                                        <span className='line'></span>
                                        <span className='text'>快速入口</span>
                                    </div>
                                </div>
                                <div className='home_entry_icon_list'>
                                    <div className='home_entry_icon'>
                                        <div className='home_entry_icon_items'>
                                            <img src={home_icon_lrxm} alt="" />
                                            <span>录入项目</span>
                                        </div>
                                        <div className='home_entry_icon_items'>
                                            <img src={home_icon_lrcp} alt="" />
                                            <span>录入产品</span>
                                        </div>
                                        <div className='home_entry_icon_items'>
                                            <img src={home_icon_gxtk} alt="" />
                                            <span>更新题库</span>
                                        </div>
                                    </div>
                                    <div className='home_entry_icon home_entry_top'>
                                        <div className='home_entry_icon_items'>
                                            <img src={home_icon_cksj} alt="" />
                                            <span>查看数据</span>
                                        </div>
                                        <div className='home_entry_icon_items'>
                                            <img src={home_icon_more} alt="" />
                                            <span>补充文案</span>
                                        </div>
                                        <div className='home_entry_icon_items'>
                                            <img src={home_icon_bcwa} alt="" />
                                            <span>补充文案</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={this.state.guide_number === 3 ? 'home_vchart position_relative' : 'home_vchart'}>
                            <div className='contain_modules'>
                                <div className='contain_modules_title'>
                                    <div className='name'>
                                        <span className='line'></span>
                                        <span className='text'>官方小程序</span>
                                    </div>
                                </div>
                                <div className='vchart'>
                                    <img src={vchart} alt="" />
                                    <span className='text'>扫一扫二维码关注我们</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={this.state.guide_number !== 0 ? 'home_guide_popup' : 'home_guide_popup home_guide_popup_none'}>
                    <div className={this.state.guide_number !== 0 ? 'home_guide_contain home_guide_popup_position' + this.state.guide_number : 'home_guide_contain'}>
                        <div className='home_guide_title'>
                            <span>{this.state.guide_number + '/2'}</span>
                            <img src={cur_icon_close_white} onClick={this.close.bind(this)} alt="" />
                        </div>
                        <div className='home_guide_sub'>{this.state.guide_text[this.state.guide_number !== 0 ? this.state.guide_number - 1 : 0].sub}</div>
                        <div className='home_guide_text'>{this.state.guide_text[this.state.guide_number !== 0 ? this.state.guide_number - 1 : 0].text}</div>
                        <div className='home_guide_btn'>
                            <span onClick={this.pre_next.bind(this, 'pre')}>上一步</span>
                            <span onClick={this.pre_next.bind(this, 'next')}>下一步</span>
                        </div>
                        <img className='home_icon_guide' src={home_icon_guide} alt="" />
                    </div>
                </div>
            </div>
        )
    }
}



export default Home; 