import axiosInstance from "./APIUtils";

export function refreshToken(refreshToken) {
  return axiosInstance({
    url: "/core/token/refresh/",
    method: "POST",
    data: {
      refresh: refreshToken,
    },
  });
}

export function login(form) {
  return axiosInstance({
    url: "/core/token/obtain/",
    method: "POST",
    data: form,
  });
}

export function logout(refreshToken) {
  return axiosInstance({
    url: "/core/blacklist/",
    method: "POST",
    data: {
      refresh_token: refreshToken,
    },
  });
}

// 登陆页的留言板
export function loginComment(form) {
  return axiosInstance({
    url: "/core/user/create/external/",
    method: "POST",
    data: form,
  });
}

// 手机获取验证码（登录）
export function getSmsVerification(form) {
  return axiosInstance({
    url: "/core/getSmsVerification/",
    method: "POST",
    data: form,
  });
}

// 获取brandAuth 用不到
export function CreateBrandAuth(form) {
  return axiosInstance({
    url: "/core/CreateBrandAuth/",
    method: "POST",
    data: form,
  });
}

// 获取账户登录信息
export function getBrandAuthInfo(form) {
  return axiosInstance({
    url: "/core/GetBrandAuthInfo/",
    method: "POST",
    data: form,
  });
}

// 用户信息认证（初次登录）
export function saveBrandAuthInfo(form) {
  return axiosInstance({
    url: "/core/SaveBrandAuthInfo/",
    method: "POST",
    data: form,
  });
}

// 微信公众号登录（登录）
export function WXLogin(form) {
  return axiosInstance({
    url: "/core/WXLogin/",
    method: "POST",
    data: form,
  });
}

// 获取微信公众号登录二维码（登录）
export function getWXLoginQRCode(form) {
  return axiosInstance({
    url: "/core/GetWXLoginQRCode/",
    method: "POST",
    data: form,
  });
}

// 检测手机号是否已经注册
export function checkPhoneRegistered(form) {
  return axiosInstance({
    url: "/core/checkPhoneRegistered/",
    method: "POST",
    data: form,
  });
}

// 检测手机号是否已经注册
export function getBrandAuthInfoFull(form) {
  return axiosInstance({
    url: "/core/GetBrandAuthInfoFull/",
    method: "POST",
    data: form,
  });
}

// 更新用户所有个人信息
export function updateBrandAuthInfoFull(form) {
  return axiosInstance({
    url: "/core/UpdateBrandAuthInfoFull/",
    method: "POST",
    data: form,
  });
}

// quota流水记录
export function getQuotaFlowHistory(form) {
  return axiosInstance({
    url: "/cem/getQuotaFlowHistory/",
    method: "POST",
    data: form,
  });
}

// 删除product中的图片
export function deleteProductImage(form) {
  return axiosInstance({
    url: "/cem/deleteProductImage/",
    method: "POST",
    data: form,
  });
}
