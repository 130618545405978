import React, { Component } from "react";
import "./filter_analysis_select.scss";
import search_icon from "../../../images/overall_data/search_icon.png";
import clear_icon from "../../../images/overall_data/clear_icon.png";
import XmSelect from "../../../components/Select/xm_select";
import { message, Select, Input } from "antd";

import { DatePicker, Tooltip } from "antd";
import moment from "moment";

export default class filter_analysis_select extends Component {
  constructor(props) {
    super(props);
    this.state = {
      region_list: [],
      selected_region_key_list: [],
      selected_region_list: [],
      gender_list: [
        { key: 0, value: "女" },
        { key: 1, value: "男" },
      ],
      selected_gender_key_list: [],
      selected_gender_list: [],
      age_list: [
        { key: "0-18", value: "0-18" },
        { key: "19-22", value: "19-22" },
        { key: "23-27", value: "23-27" },
        { key: "28-35", value: "28-35" },
        { key: "35+", value: "35+" },
      ],
      selected_age_key_list: [],
      selected_age_list: [],
      word_count: "",
    };
  }
  componentDidMount() {}
  setFilterList(obj) {
    let { region_list, is_init_filter } = obj;
    let selected_region_key_list = this.state.selected_region_key_list;
    let selected_gender_key_list = this.state.selected_gender_key_list;
    let selected_age_key_list = this.state.selected_age_key_list;
    let selected_region_list = this.state.selected_region_list;
    let selected_gender_list = this.state.selected_gender_list;
    let selected_age_list = this.state.selected_age_list;
    let word_count = this.state.word_count;
    if (is_init_filter) {
      selected_region_key_list = [];
      selected_gender_key_list = [];
      selected_age_key_list = [];
      selected_region_list = [];
      selected_gender_list = [];
      selected_age_list = [];
      word_count = "";
    }
    this.setState({
      region_list: region_list,
      selected_region_key_list: selected_region_key_list,
      selected_gender_key_list: selected_gender_key_list,
      selected_age_key_list: selected_age_key_list,
      selected_region_list: selected_region_list,
      selected_gender_list: selected_gender_list,
      selected_age_list: selected_age_list,
      word_count: word_count,
    });
  }
  // 渲染地域下拉框
  handleRegionListOption() {
    return this.state.region_list;
  }
  // 渲染默认的题目
  handleDefaultRegionListOption() {
    return this.state.selected_region_key_list;
  }
  // 下拉框搜索可以大小写
  filterSelectOption(input, option) {
    return (
      option.children.props.children.toString().indexOf(input.toString()) >= 0
    );
  }
  //下拉框选择后，并赋值到state里面
  onRegionSelectChange(key_arr) {
    let selected_region_list = [];
    for (let i = 0; i < key_arr.length; i++) {
      selected_region_list.push(this.state.region_list[key_arr[i]].value);
    }
    this.setState(
      {
        selected_region_key_list: key_arr,
        selected_region_list: selected_region_list,
      },
      () => {
        this.updateFilter();
      }
    );
  }
  // 渲染gender下拉框
  handleGenderListOption() {
    return this.state.gender_list;
  }
  // 渲染gender默认的题目
  handleDefaultGenderListOption() {
    return this.state.selected_gender_key_list;
  }
  //下拉框选择后，并赋值到gender state里面
  onGenderSelectChange(key_arr) {
    let selected_gender_list = [];
    for (let i = 0; i < key_arr.length; i++) {
      selected_gender_list.push(this.state.gender_list[key_arr[i]].value);
    }
    this.setState(
      {
        selected_gender_key_list: key_arr,
        selected_gender_list: selected_gender_list,
      },
      () => {
        this.updateFilter();
      }
    );
  }

  // 清空所有的
  clearFilter() {
    this.setState({
      selected_region_key_list: [],
      selected_gender_key_list: [],
      selected_age_key_list: [],
      selected_region_list: [],
      selected_gender_list: [],
      selected_age_list: [],
      word_count: "",
    });
    let filter_obj = {
      selected_region_list: [],
      selected_gender_list: [],
      selected_age_list: [],
      word_count: "",
    };
    this.props.updateFilter(filter_obj);
  }
  // 搜索的filter
  updateFilter() {
    let filter_obj = {
      selected_region_list: this.state.selected_region_list,
      selected_gender_list: this.state.selected_gender_list,
      selected_age_list: this.state.selected_age_list,
      word_count: this.state.word_count,
    };
    this.props.updateFilter(filter_obj);
  }
  //验证数字
  validateNumber(event) {
    if (event.target.value === "") {
      return;
    }
    event.target.value = event.target.value.replace(/^0[0-9]+/g, 0);
    event.target.value = event.target.value.replace(/[^/\-+\d]/g, "");
  }
  // 渲染age下拉框
  handleAgeListOption() {
    return this.state.age_list;
  }
  // 渲染age默认的题目
  handleDefaultAgeListOption() {
    return this.state.selected_age_key_list;
  }
  //下拉框选择后，并赋值到age state里面
  onAgeSelectChange(key_arr) {
    if (key_arr.length === 0) {
      this.setState(
        {
          selected_age_key_list: key_arr,
          selected_age_list: key_arr,
        },
        () => {
          this.updateFilter();
        }
      );
      return;
    }
    let age = String(key_arr[key_arr.length - 1]);
    if (this.isConnectAge(age)) {
      if (age.indexOf("-") >= 0) {
        let [age_s, age_e] = age.split("-");
        if (Number(age_s) === 0 && Number(age_e) === 18) {
          // ok
        } else if (Number(age_s) < 15 || Number(age_s) > 70) {
          message.warning("起始年龄最小15岁,最大70岁");
          return;
        } else if (Number(age_e) < 15 || Number(age_e) > 70) {
          message.warning("结束年龄最小15岁,最大70岁");
          return;
        } else if (Number(age_s) > Number(age_e)) {
          message.warning("起始年龄需要大雨结束年龄");
          return;
        }
      } else if (age.indexOf("+") >= 0) {
        let [age_s] = age.split("+");
        if (Number(age_s) < 15 || Number(age_s) > 70) {
          message.warning("年龄最小15岁,最大70岁");
          return;
        }
      } else {
        if (Number(age) < 15 || Number(age) > 70) {
          message.warning("年龄最小15岁,最大70岁");
          return;
        }
      }
      this.setState(
        {
          selected_age_key_list: key_arr,
          selected_age_list: key_arr,
        },
        () => {
          this.updateFilter();
        }
      );
    } else {
      message.warning("年龄格式不正确,请输入20-30或30+或30", 2, () => {});
    }
  }
  // 判断年龄是否输入正确
  isConnectAge(age) {
    let reg = /^((\d)+-?(\d)+)|(\d)+\+$/;
    let flag = reg.test(age);
    if (!flag) {
      let reg2 = /^(\d)+\+$/;
      flag = reg2.test(age);
    }
    return flag;
  }
  // 输入字数
  handleWordCount(event) {
    this.setState(
      {
        word_count: event.target.value,
      },
      () => {
        this.updateFilter();
      }
    );
  }
  // 验证数字
  validateWordNumber(event) {
    event.target.value = event.target.value.replace(/^0[0-9]+/g, 0);
    event.target.value = event.target.value.replace(/[^/.\d]/g, "");
    event.target.value = event.target.value.replace(".", "");
  }
  render() {
    return (
      <div className="filter-analysis-select-container">
        <div className="region-wrapper">
          <div className="region-name">地域</div>
          <div className="region-select">
            <XmSelect
              mode="multiple"
              placeholder="请选择地域"
              onChange={(e) => {
                this.onRegionSelectChange.call(this, e);
              }}
              maxTagCount={"responsive"}
              allowClear
              options={this.handleRegionListOption.call(this)}
              value={this.handleDefaultRegionListOption.call(this)}
              filterOption={(input, option) => {
                return this.filterSelectOption.call(this, input, option);
              }}
            ></XmSelect>
          </div>
        </div>
        <div className="gender-wrapper">
          <div className="gender-name">性别</div>
          <div className="gender-select">
            <XmSelect
              mode="multiple"
              placeholder="请选择性别"
              onChange={(e) => {
                this.onGenderSelectChange.call(this, e);
              }}
              maxTagCount={"responsive"}
              allowClear
              options={this.handleGenderListOption.call(this)}
              value={this.handleDefaultGenderListOption.call(this)}
              filterOption={(input, option) => {
                return this.filterSelectOption.call(this, input, option);
              }}
            ></XmSelect>
          </div>
        </div>
        <div className="age-wrapper">
          <div className="age-name">年龄</div>
          <div className="age-select">
            <XmSelect
              mode="tags"
              placeholder="请输入年龄,如20-30或35+"
              onChange={(e) => {
                this.onAgeSelectChange.call(this, e);
              }}
              maxTagCount={"responsive"}
              allowClear
              options={this.handleAgeListOption.call(this)}
              value={this.handleDefaultAgeListOption.call(this)}
              onInput={this.validateNumber.bind(this)}
              iskey="true"
            ></XmSelect>
          </div>
        </div>
        <div className="word-wrapper">
          <div className="word-name">评论字数超过</div>
          <div className="word-input-wrapper">
            <Input
              className="word-input"
              value={this.state.word_count}
              placeholder="请输入字数"
              onChange={this.handleWordCount.bind(this)}
              onInput={this.validateWordNumber.bind(this)}
              maxLength={4}
            />
          </div>
          <div className="word-name">个</div>
        </div>
        <div className="operate-wrapper">
          <Tooltip placement="top" title="清空">
            <div
              className="clear-wrapper"
              onClick={this.clearFilter.bind(this)}
            >
              <img src={clear_icon} alt="" />
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }
}
