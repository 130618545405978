import React, { Component } from 'react'
import { questionType, getQuestionTypeValue, getQuestionType, getQuestionTypeName } from "../../../Question_rules/question_type_rule"
import XmSelect from '../../../../../Select/xm_select'

export default class enumeration_condition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "enumeration_condition",
            condition_judge_list: [{ value: "包含", key: 6 }, { value: "不包含", key: 7 }, { value: "小于", key: 4 }, { value: "大于", key: 3 }, { value: "等于", key: 5 }, { value: "与人群显示冲突", key: 8 }],//如果第2框 单选多选
            condition_settings_index: "",
            logic_settings_index: "",
            condition_settings_item: { judge: "", option: { value: "" } }
        }
    }
    componentDidMount() {
        this._isMounted = true
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.condition_settings_index) !== JSON.stringify(state.condition_settings_index_prev) || JSON.stringify(props.condition_settings_item) !== JSON.stringify(state.condition_settings_item_prev) || JSON.stringify(props.logic_settings_index) !== JSON.stringify(state.logic_settings_index_prev)) {
            return {
                needRefresh: true,
                condition_settings_index_prev: props.condition_settings_index,
                condition_settings_item_prev: props.condition_settings_item,
                condition_settings_index: props.condition_settings_index,
                condition_settings_item: props.condition_settings_item,
                logic_settings_index: props.logic_settings_index
            }
        }
        return null;
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    // 如果第2k
    handleConditionJudge(judge, index) {
        return this.state.condition_judge_list
    }
    // 如果第2k
    handleDefaultConditionJudge(judge, index) {
        if (judge === "") {
            return null
        }
        return judge
        // return null
    }
    // 如果第2个
    handleConditionJudgeChange(key, index2, index1) {
        this.props.handleConditionJudgeChange(key, index2, index1)
    }
    // 填空是否有字
    handle_blank_word(judge, index2, index1) {
        if (judge >= 2) {
            return true
        }
        return false
    }
    // 如果第3个 input
    handleConditionInputChange(index2, index1, event) {
        this.props.handleConditionInputChange(event.target.value, index2, index1)
    }
    // 如果第3k
    handleDefaultConditionInput(value, index) {
        if (value === "") {
            return null
        }
        return value
    }
    //验证数字
    validateNumber(event) {
        event.target.value = event.target.value.replace(/^0[0-9]+/g, 0);
        event.target.value = event.target.value.replace(/[^/.\d]/g, "");
        event.target.value = event.target.value.replace(".", "");
    }
    render() {
        return (
            <>
                <div className='logic_rule_select condition_select_second'>
                    {/* 如果2 */}
                    <div className="condition-option">
                        <XmSelect options={this.handleConditionJudge.call(this, this.state.condition_settings_item.judge, this.state.condition_settings_index)}
                            value={this.handleDefaultConditionJudge.call(this, this.state.condition_settings_item.judge, this.state.condition_settings_index)}
                            key={this.state.condition_settings_item.judge}
                            onChange={(e) => { this.handleConditionJudgeChange.call(this, e, this.state.condition_settings_index, this.state.logic_settings_index) }}
                            placeholder="请选择">
                        </XmSelect>
                    </div>
                </div>
                {
                    String(this.state.condition_settings_item.judge) !== "8" ? <>
                        <div className='logic_rule_select condition_select_third'>
                            {/* 填空 */}
                            {/* 如果3 */}
                            <span className={this.handle_blank_word.call(this, this.state.condition_settings_item.judge, this.state.condition_settings_index, this.state.logic_settings_index) ? "" : "isHide"}>
                                <input placeholder='请输入' className='condition-input' type='text' maxLength="3" onBlur={this.handleConditionInputChange.bind(this, this.state.condition_settings_index, this.state.logic_settings_index)} onInput={this.validateNumber.bind(this)} defaultValue={this.handleDefaultConditionInput.call(this, this.state.condition_settings_item.option.value, this.state.condition_settings_index)} key={this.state.condition_settings_item.option.value} />
                            </span>
                            <span className={this.handle_blank_word.call(this, this.state.condition_settings_item.judge, this.state.condition_settings_index, this.state.logic_settings_index) ? "isHide" : ""}>
                                <input placeholder='请输入' className='condition-input' type='text' onBlur={this.handleConditionInputChange.bind(this, this.state.condition_settings_index, this.state.logic_settings_index)} defaultValue={this.handleDefaultConditionInput.call(this, this.state.condition_settings_item.option.value, this.state.condition_settings_index)} key={this.state.condition_settings_item.option.value} />
                            </span>
                            <span className={this.handle_blank_word.call(this, this.state.condition_settings_item.judge, this.state.condition_settings_index, this.state.logic_settings_index) ? "blank-word" : "isHide"}>选项</span>
                        </div>
                    </> : ""
                }
            </>
        )
    }
}
