import React, { Component } from 'react'
import './about_us.scss';
import survey_flow from '../../../images/home_page/about_us/survey_flow.png'
import dispatch_flow from '../../../images/home_page/about_us/dispatch_flow.png'
import back_btn_icon from '../../../images/home_page/about_us/back_btn_icon.png'


export default class about_us extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    clickBackBtn() {
        this.props.canShowAboutUs(false)
    }
    render() {
        return (
            <div className="collaborate-container">
                <div className="collaborate-content">
                    <div className="collaborate-card">
                        <div className='collaborate-title-wrapper'>
                            <div className='title'>概念调研</div>
                            <div className='btn-wrapper'>
                                <div className='btn' onClick={this.clickBackBtn.bind(this, 'save_build')}>
                                    <img src={back_btn_icon} alt="" />
                                    <span>返回</span>
                                </div>
                            </div>
                        </div>
                        <div className="user-row">
                            <div className="user">
                                <div className="user-icon user-icon-1"></div>
                                <div className="user-name">matchbox</div>
                            </div>
                            <div className="user">
                                <div className="user-icon user-icon-2"></div>
                                <div className="user-name">用户</div>
                            </div>
                            <div className="user">
                                <div className="user-icon user-icon-3"></div>
                                <div className="user-name">品牌方</div>
                            </div>
                        </div>
                        <div className="collaborate-img">
                            <img src={survey_flow} alt="" />
                        </div>
                    </div>
                    <div className="collaborate-line"></div>
                    <div className="collaborate-card">
                        <div className='collaborate-title-wrapper'>
                            <div className='title'>派样测试</div>
                        </div>
                        <div className="user-row">
                            <div className="user">
                                <div className="user-icon user-icon-1"></div>
                                <div className="user-name">matchbox</div>
                            </div>
                            <div className="user">
                                <div className="user-icon user-icon-2"></div>
                                <div className="user-name">用户</div>
                            </div>
                            <div className="user">
                                <div className="user-icon user-icon-3"></div>
                                <div className="user-name">品牌方</div>
                            </div>
                        </div>
                        <div className="collaborate-img">
                            <img src={dispatch_flow} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
