import React, { Component } from 'react'
import './open_blank_list.scss';
import { Tabs, message, ConfigProvider, Table } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import cur_icon_search from "../../images/emotion_analysis/cur_icon_search.png"
import { Input } from 'antd';
const { TabPane } = Tabs;

export default class open_blank_list extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'CommentCustomChart',
            dataList: {},
            needRefresh: false,
            tab_list: [],
            cur_table_list: [],
            cur_table_list_formal: [],
            columns: [],
            search_text: "",
        };
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList,
                search_text: ""
            }
        }
        return null;

    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            this.toInitChart()
        }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    toInitChart() {
        let single_id = this.props.single_id
        let tab_list = []
        let cur_table_list = []
        let columns = []

        if (this.props.dataList.option_list.length) {
            cur_table_list = this.props.dataList.option_list[0].data
            if (cur_table_list.length) {
                columns = this.initColumn(cur_table_list[0])
            }
        }

        for (let i = 0; i < this.props.dataList.option_list.length; i++) {
            tab_list.push(this.props.dataList.option_list[i].name)
        }

        this.setState({
            tab_list: tab_list,
            cur_table_list: cur_table_list,
            cur_table_list_formal: cur_table_list,
            columns: columns
        })
    }
    initColumn(table_item_obj) {
        let columns = []
        for (var key in table_item_obj) {
            if (key === "name") {
                let tmp = {
                    title: '用户名',
                    dataIndex: 'name',
                    key: 'name',
                    width: 80,
                    render: (content, index) => {
                        if (this.state.search_text !== "") {
                            content = content.replace(this.state.search_text, `<span style='color: #FF5186'>${this.state.search_text}</span>`)
                        }
                        let html = { __html: content };
                        return <div dangerouslySetInnerHTML={html}></div>;
                    }
                }
                columns.push(tmp)
            } else if (key === "gender") {
                let tmp = {
                    title: '性别',
                    dataIndex: 'gender',
                    key: 'gender',
                    width: 60,
                    render: (content, index) => {
                        if (this.state.search_text !== "") {
                            content = content.replace(this.state.search_text, `<span style='color: #FF5186'>${this.state.search_text}</span>`)
                        }
                        let html = { __html: content };
                        return <div dangerouslySetInnerHTML={html}></div>;
                    }
                }
                columns.push(tmp)
            } else if (key === "age") {
                let tmp = {
                    title: '年龄',
                    dataIndex: 'age',
                    key: 'age',
                    width: 60,
                    render: (content, index) => {
                        if (this.state.search_text !== "") {
                            content = content.toString().replace(this.state.search_text, `<span style='color: #FF5186'>${this.state.search_text}</span>`)
                        }
                        let html = { __html: content };
                        return <div dangerouslySetInnerHTML={html}></div>;
                    }
                }
                columns.push(tmp)
            } else if (key === "user_id") {
                // 不操作
            } else {
                let tmp = {
                    title: key,
                    dataIndex: key,
                    key: key,
                    render: (content, index) => {
                        if (this.state.search_text !== "") {
                            content = content.replace(this.state.search_text, `<span style='color: #FF5186'>${this.state.search_text}</span>`)
                        }
                        let html = { __html: content };
                        return <div dangerouslySetInnerHTML={html}></div>;
                    }
                }
                columns.push(tmp)
            }
        }
        return columns
    }
    clickTab(key) {
        let index = parseInt(key)
        let cur_table_list = []
        let columns = []

        cur_table_list = this.props.dataList.option_list[index].data
        if (cur_table_list.length) {
            columns = this.initColumn(cur_table_list[0])
        }
        this.setState({
            cur_table_list: cur_table_list,
            cur_table_list_formal: cur_table_list,
            columns: columns // 这边其实不需要 因为columes不会变的
        })
    }
    showPagination() {
        if (this.state.cur_table_list.length < 30) {
            return false
        }
        return { pageSize: 30, showSizeChanger: false, showQuickJumper: true, size: "small" }
    }
    // 搜索按钮 传父
    handleSearch(t, event) {
        if (!t) return false
        if (!event) return false
        this.setState({
            cur_table_list: []
        })
        setTimeout(() => {
            let search_text = event.target.value.trim()
            if (search_text === "") {
                this.setState({
                    search_text: "",
                    cur_table_list: this.state.cur_table_list_formal
                })
            } else {
                let cur_table_list = []
                for (let i = 0; i < this.state.cur_table_list_formal.length; i++) {
                    for (let j = 0; j < this.state.columns.length; j++) {
                        if (String(this.state.cur_table_list_formal[i][this.state.columns[j].key]).indexOf(search_text) !== -1) {
                            cur_table_list.push(this.state.cur_table_list_formal[i])
                        }
                    }
                }
                this.setState({
                    search_text: search_text,
                    cur_table_list: cur_table_list
                })
            }
        })
    }
    render() {
        const prefix = (
            <img src={cur_icon_search} alt="" className='evaluation-icon-search' />
        )
        return (
            <div className="open-blank-container">
                <Tabs defaultActiveKey="1" size="small" onChange={this.clickTab.bind(this)}>
                    {
                        this.state.tab_list.map((item, index) => {
                            return (
                                <TabPane tab={`${item}`} key={index}>
                                    <div className="search-wrapper">
                                        <Input prefix={prefix} placeholder="搜索关键词" onPressEnter={this.handleSearch.bind(this, 'comment_search')} onBlur={this.handleSearch.bind(this, 'comment_search')} />
                                    </div>
                                    <ConfigProvider locale={zhCN}>
                                        <Table
                                            dataSource={this.state.cur_table_list}
                                            columns={this.state.columns}
                                            pagination={this.showPagination()}
                                            rowKey={columns => columns.user_id}
                                            scroll={{ y: this.props.isMaxChart ? this.props.blankListHeight : 200 }} />
                                    </ConfigProvider>
                                </TabPane>
                            )
                        })
                    }
                </Tabs>
            </div>
        )
    }
}
