/* 
    该模块是用于定义action对象中type类型的常量值，目的只有一个：便于管理的同时防止程序员单词写错
*/
export const EDIT_USER = "edit_user";
export const EDIT_USER_ITEM = "edit_user_item";

export const PRIORITIZE_SURVEY_QUEUE_ITEM = "prioritize_survey_queue_item";
export const ADD_SURVEY_QUEUE_ITEM = "ADD_SURVEY_QUEUE_ITEM"; // 增
export const EDIT_SURVEY_QUEUE_ITEM = "edit_survey_queue_item"; // 改
export const DELETE_SURVEY_QUEUE_ITEM = "delete_survey_queue_item"; // 删

export const EDIT_SURVEY_QUEUE = "edit_survey_queue"; // 改


export const START_POLLING = "start_polling"; // 改