export const questionType = {
  1: "single", // 单选题
  2: "multiple", // 多选题
  3: "blank", // 简单题-填空题
  4: "sort", // 排序题
  5: "score", // 打分题
  6: "proportion",// 比重题,
  7: "nps",// 滑条题
  8: "matrix_single", //矩阵单选
  9: "matrix_multiple", //矩阵多选
  10: "picture",//图片题
  11: "nps_2",// nps
  14: "matrix_score", //矩阵打分题
  15: "matrix_nps",// 矩阵滑条题
  12: "single_2",//单选题 种草
  13: "single_3",// 单选题 购买意愿
  16: "matrix_blank",// 矩阵填空题
  18: "enumeration",// 例举题-枚举题
  19: "max_diff", // maxdiff题
  20: "heatmap", // 热力图题
  22: "matrix_psm", // Price Sensitivity Meter
};
export const questionTypeName = {
  single_2: "种草题",//种草题
  single_3: "购买题",//购买题
  single: "单选题", // 单选题
  multiple: "多选题", // 多选题
  blank: "简答题", // 简单题-填空题
  sort: "排序题", // 排序题
  score: "打分题", // 打分题
  proportion: "比重题",// 比重题,
  nps: "滑条题",// 滑条题
  nps_2: "NPS",// 滑条题
  matrix_single: "矩阵单选题",//矩阵单选题
  matrix_multiple: "矩阵多选题",// 矩阵多选题
  matrix_score: "矩阵打分题",//矩阵打分题
  matrix_blank: "开放题", // 矩阵填空题
  matrix_nps: "矩阵滑条题",// 矩阵滑条题
  enumeration: "例举题",//例举题 枚举题
  picture: "图片题",//图片题
  max_diff: "MaxDiff",//MaxDiff题
  heatmap:"热力图",//热力图题
  matrix_psm: "价格敏感度",//Price Sensitivity Meter
};

// 根据key 获取value
export function getQuestionTypeName(key) {
  if (!Object.prototype.hasOwnProperty.call(questionTypeName, key)) {
    return -1;
  }
  return questionTypeName[key];
}

// 根据value 获取key
export function getQuestionTypeValue(value, compare = (a, b) => a === b) {
  let key = Object.keys(questionType).find(k => compare(questionType[k], value))
  if (key) {
    return key
  }
  return -1 //undefined
}

// 根据key 获取value
export function getQuestionType(key) {
  if (!Object.prototype.hasOwnProperty.call(questionType, key)) {
    return -1;
  }
  return questionType[key];
}
