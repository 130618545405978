import React, { Component } from 'react';
import './Overall_data.scss';
import overall_icon_intro1 from '../../images/overall_data/overall_icon_intro1.png'
import overall_icon_intro2 from '../../images/overall_data/overall_icon_intro2.png'
import overall_icon_intro3 from '../../images/overall_data/overall_icon_intro3.png'
import download_icon from '../../images/overall_data/download_icon.png'
import DispatchDateChart from "./Dispatch_charts/Dispatch_date_chart"
import DispatchSingleChart from './Dispatch_charts/Dispatch_single_chart';
import DesireSingleChart from './Dispatch_charts/Desire_single_chart'
import FilterSelect from './Filter_chart_select/filter_select'
import NormInfo from '../Home_page/Norm_info/norm_info'

import { getOverallData, getOverallDataLikeRate, exportProductReport, getPresetCommentSimple, exportSurvey, getRegionList } from '../../api/panelApi'
import { downloadPPT, download, saveImgZip, calScore, calP, calB, calPreference } from '../../util/utils'
import { message, Tooltip, Empty, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import ProductDropdown from "../Cem_product_dropdown/product_dropdown";
import Loading from "../../components/Loading/loading"
import ProgressBar from "../../components/progress_bar/progress_bar"
import SingleChart from "../Preinstall_comment/CommentSingleChart"
import CustomChart from "../Preinstall_comment/CommentCustomChart"
import html2canvas from "html2canvas";

class Overall_data extends Component {
    constructor(props) {
        super(props);
        this.filterRef = React.createRef()
        this.handleScroll = this.handleScroll.bind(this)
        this.state = {
            product_id: localStorage.getItem('product_id_cem') ? localStorage.getItem('product_id_cem') : "",    //产品ID
            survey_id: localStorage.getItem('project_survey_id_cem') ? localStorage.getItem('project_survey_id_cem') : 0,    //调研ID
            brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : 1,   //品牌ID
            name: 'Overall_data',
            product_list: [{ id: 1, text: '第二代小黑瓶肌底液' }, { id: 2, text: '第三代小黑瓶肌底液' }], //名称
            product_text: localStorage.getItem('product_text_cem') ? localStorage.getItem('product_text_cem') : "",
            dispatch_finish_val: 0, //已完成派送
            dispatch_sum_val: 0,//总共派送产品
            dispatch_feedback: "0%", //平均反馈率
            dispatch_average_age: 0, //平均年龄
            dispatch_average_feedback_duration: 0,//时长
            dispatch_time: "",//派送时间
            dispatch_product_status: {},
            dispatch_chart_list: [], //派送图表
            desire_chart_list: [], //种草图表
            _isMounted: false,
            maxCommentDate: this.getCurrentTime(),//已评论的最大题
            loading: 3,
            loading_chart: 0,
            loading_batch_download: 0, // 批量下载的loading
            current_batch_download_num: 0,  // 批量下载中每一个图片都下载
            loadingText: "数据较大，加载中",
            preinstall_single_list: [], //预设评论图表
            region_list: [],
            selected_region_list: [],
            selected_gender_list: [],
            selected_age_list: [],
            start_time: "",
            end_time: "",
            batch_base_url: [], //批量图片
            filter_start_date: "",//筛选时间框的开始时间
            filter_end_date: "",//筛选时间框的结束时间
            // 进度条数据
            barList: [
                {
                    name: '项目设立',
                }, {
                    name: '问卷审核',
                }, {
                    name: '问卷发布',
                }, {
                    name: '问卷收集',
                }, {
                    name: '数据分析阶段',
                }, {
                    name: '项目完结',
                }
            ],
        };
    }
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            window.addEventListener("scroll", this.handleScroll, true);
        }
    }

    componentWillUnmount() {
        //在componentWillUnmount，进行scroll事件的注销
        window.removeEventListener('scroll', this.handleScroll, true);
        this._isMounted = false;
        // this.setState = () => false;
        this.setState = (state, callback) => { }
    }
    setStateAsync = (state) => {
        return new Promise((resolve, reject) => {
            this.setState(state, resolve);
        });
    };
    getCurrentTime() { //当前日期
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        month = month < 10 ? "0" + month : month //小于10补0
        day = day < 10 ? "0" + day : day //小于10补0
        var time = year + "-" + month + "-" + day;
        return time;
    }
    async getAllData() {
        this.setState({
            loadingText: "派送数据较大，加载中",
            loading: this.state.loading + 1
        })
        let product_id = this.state.product_id
        let survey_id = this.state.survey_id
        try {
            const response = await getOverallData({
                product_id: product_id,
                survey_id: survey_id,
            })
            if (!response) return
            if (parseInt(response.status) === 204) {
                this.setState({
                    dispatch_finish_val: 0,
                    dispatch_sum_val: 0,
                    dispatch_feedback: "0%",
                    dispatch_average_age: 0,
                    dispatch_product_status: [],
                    dispatch_average_feedback_duration: 0,
                    dispatch_time: "",
                    dispatch_chart_list: [],
                    maxCommentDate: "",
                    filter_start_date: "",
                    filter_end_date: "",
                    loading: this.state.loading - 1
                }, () => {
                    this.initChart()
                })
                message.warning('暂无派送数据', 2, () => { })
            } else {
                //派送图表
                let dispatch_chart_list = []
                response.data.dispatch_distribution.forEach((item, index) => {
                    let chartObj = { name: item.name, type: item.type, data: [], sum: item.sum }
                    for (let i = 0; i < item.data.length; i++) {
                        let dataObj = { value: item.data[i].val, name: item.data[i].name || "未知" }
                        chartObj.data.push(dataObj)
                    }
                    dispatch_chart_list.push(chartObj)
                })

                let dispatch_product_status = response.data.dispatch_product_status
                let maxDate = "1970-01-01"
                dispatch_product_status.forEach((item, index) => {
                    let len = item.status_day.length
                    let maxdate2 = item.status_day[len - 1]
                    if (this.compareDate(maxDate, maxdate2) === 2) {
                        //maxDate小
                        maxDate = maxdate2
                    }
                })
                this.setState({
                    dispatch_finish_val: response.data.dispatch_finish_val,
                    dispatch_sum_val: response.data.dispatch_sum_val || 0,
                    dispatch_feedback: response.data.dispatch_feedback || '0%',
                    dispatch_average_age: Number(response.data.dispatch_average_age) || 0,
                    dispatch_product_status: response.data.dispatch_product_status,
                    dispatch_average_feedback_duration: response.data.dispatch_average_feedback_duration,
                    dispatch_time: response.data.dispatch_time,
                    filter_start_date: response.data.start_time,
                    filter_end_date: response.data.end_time,
                    dispatch_chart_list: dispatch_chart_list,
                    maxCommentDate: maxDate,
                    loading: this.state.loading - 1
                }, () => {
                    this.initChart() // 为了获取开始时间
                })
            }



            return response;
        } catch (error) {
            this.initChart()
            this.setState({
                loading: this.state.loading - 1
            }, () => {
                message.error('关于派送数据，后台发生错误')
            }
            )
            throw error;
        }
    }
    // 比较日期
    compareDate(date1, date2) {
        var oDate1 = new Date(date1);
        var oDate2 = new Date(date2);
        if (oDate1.getTime() > oDate2.getTime()) {
            return 1
        } else {
            return 2
        }
    }
    // 总体数据种草率分析
    async getOverallDataLikeRate() {
        this.setState({
            loadingText: "种草率数据较大，加载中",
            loading: this.state.loading + 1
        })
        let product_id = this.state.product_id
        try {
            const response = await getOverallDataLikeRate({

                product_id: product_id,
                survey_id: this.state.survey_id
            })
            if (!response) return
            if (parseInt(response.status) === 204) {
                this.setState({
                    desire_chart_list: [],
                    loading: this.state.loading - 1
                })
                message.warning('暂无种草率数据', 2, () => { })
            } else {
                this.setState({
                    desire_chart_list: response.data || [],
                    loading: this.state.loading - 1
                })
            }
            return response;
        } catch (error) {
            this.setState({
                loading: this.state.loading - 1
            }, () => {
                message.error('关于种草率，后台发生错误')
            }
            )
            throw error;
        }
    }
    //清空数据
    clearData() {
        this.setState({
            desire_chart_list: [], //likerate
            dispatch_finish_val: "",
            dispatch_sum_val: "",
            dispatch_feedback: "",
            dispatch_average_age: "",
            dispatch_product_status: [],
            dispatch_average_feedback_duration: "",
            dispatch_time: "",
            dispatch_chart_list: [],
            maxCommentDate: "",
            preinstall_single_list: []
        })
    }
    // 下拉框传来的数据
    handleProductChange(id, survey_id) {
        if (id === '-1') {
            // 没有品牌
            message.warning('没有品牌的数据', 2, () => { })
            this.clearData()
            this.setState({
                loading: 0,
                product_id: ''
            })
            return;
        }
        if (id === "") {
            // 只有品牌 没有产品
            message.warning('没有该产品的数据', 2, () => { })
            this.clearData()
            this.setState({
                loading: 0,
                product_id: ''
            })
            return;
        } else if (id !== this.state.product_id) {
            this.clearData()
        }
        this.setState({
            product_id: id,
            loading: 1,
            survey_id: survey_id
        }, () => {
            this.getRegionList()
            // this.getOverallDataLikeRate()
            // this.getAllData()
        })

    }
    // 添加数据
    addData() {
    }
    // 点击选择产品
    onClickMenu({ key }) {
        this.setState({ "product_text": this.state.product_list[key].text });
    };
    calculateDate() {
        //获取当前时间
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        // if (month < 10) {
        //     month = "0" + month;
        // }
        // if (day < 10) {
        //     day = "0" + day;
        // }
        var nowDate = year + "年" + month + "月" + day + "日";
        return nowDate
    }
    //下载ppt
    handleDownload() {
        this.setState({
            loading: 2,
            loadingText: "数据较大，下载中"
        }, () => {
            this.exportProductReports()
        })
    }
    exportProductReports() {
        let product_id = this.state.product_id
        exportProductReport({ product_id: product_id }).then(res => {
            this.setState({
                loading: 0
            })
            if (res.status === 204) {
                message.warning('暂未有报告', 2, () => { })
            } else {
                // 获取在response headers中返回的下载文件类型
                let type = res.headers['content-type']
                // let fileName = decodeURIComponent(JSON.parse(res.headers)['file-name'])
                let time = this.getCurrentDownloadTime()
                let brand_text_cem = localStorage.getItem('brand_text_cem') ? localStorage.getItem('brand_text_cem') : ""
                let product_text_cem = localStorage.getItem('product_text_cem') ? localStorage.getItem('product_text_cem') : ""
                downloadPPT(res.data, type, `深度分析报告_${brand_text_cem}${product_text_cem}_${time}`);
                return res;
            }
        }).catch(err => {
            this.setState({
                loading: 0
            }, () => {
                message.error('待上传')
            }
            )
            throw err;
        })
    }
    // 获取当前时间
    getCurrentDownloadTime() {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours(); // 时
        var minutes = date.getMinutes(); // 分
        var seconds = date.getSeconds() //秒
        var time = year + "-" + month + "-" + day + '_' + hour + '_' + minutes + '_' + seconds;
        return time;
    }
    //选择简单唯独图表
    chooseSingleChart(item, index) {
        if (item.type === 11) {
            return <CustomChart key={index} dataList={item} single_id={index}></CustomChart>
        }
        return <SingleChart
            key={index} dataList={item}
            single_id={index}
            survey_type={2}
            product_id={this.state.product_id}
            selected_region_list={this.state.selected_region_list}
            selected_gender_list={this.state.selected_gender_list}
            selected_age_list={this.state.selected_age_list}
            start_time={this.state.start_time}
            end_time={this.state.end_time}
            isRightToList={true}
        ></SingleChart>
    }
    async getRegionList() {
        let product_id = parseInt(this.state.product_id)

        try {
            const response = await getRegionList({
                product_id: product_id,
                survey_type: 2,
                survey_id: ""
            })
            let region_list = []
            for (let i = 0; i < response.data.length; i++) {
                let obj = { key: i, value: response.data[i] }
                region_list.push(obj)
            }

            this.setState(
                {
                    region_list: region_list,
                    loading: this.state.loading - 1
                }, () => {
                    this.getOverallDataLikeRate()
                    this.getAllData()

                })
            return response;
        } catch (error) {
            this.setState({
                loading: this.state.loading - 1
            }, () => {
                message.error('后台发生错误')
            }
            )
            throw error;
        }
    }
    // 删选初始化图表
    initChart() {
        // 防止接口回来满，但是setFilterList这个组件已经unmonted
        if (!this.filterRef.current) return;
        this.filterRef.current.setFilterList({
            region_list: this.state.region_list,
            filter_start_date: this.state.filter_start_date,
            filter_end_date: this.state.filter_end_date,
            is_init_filter: true
        })
        this.getPresetCommentSimple()
    }
    // 获取预设评论分析
    async getPresetCommentSimple() {
        let product_id = parseInt(this.state.product_id)
        let survey_id = parseInt(this.state.survey_id)
        this.setState({
            // preinstall_single_list: [],
            loading_chart: 1,
        }, () => {
        })

        try {
            const response = await getPresetCommentSimple({
                product_id: product_id,
                survey_id: survey_id,
                age_list: this.state.selected_age_list,
                region_list: this.state.selected_region_list,
                gender_list: this.state.selected_gender_list,
                start_time: this.state.start_time,
                end_time: this.state.end_time
            })
            if (!response) return
            if (parseInt(response.status) === 204) {
                this.setState({
                    preinstall_single_list: [],
                    loading_chart: this.state.loading_chart - 1
                })
                message.warning('暂无数据', 2, () => { })
            } else {
                let preinstall_single_list = JSON.parse(JSON.stringify(response.data))
                preinstall_single_list.forEach((item, index) => {
                    item["type"] = item.question_type
                    if (item.question_type === 1 || item.question_type === 12 || item.question_type === 13 || item.question_type === 6) { // 单选题、比重题
                        for (let i = 0; i < item.data.length; i++) {
                            item.data[i].name = item.data[i].name || "未知"
                        }
                    } else if (item.question_type === 15 || item.question_type === 14) {  //矩阵滑条题
                        // item.data_list = item.data_list //列表
                        // item.chart_list = item.chart_list // 图表
                    } else if (item.question_type === 16) {  //开放题-矩阵填空题
                        // item.name = item.name
                        // item.option_list = item.option_list
                    } else if (item.question_type === 3 || item.question_type === 18 || item.question_type === 10) {  //简答题
                        // item.name = item.name
                        // item.data = item.data
                    } else if (item.question_type === 2) {//多选题
                        item["xAxisList"] = item.x_axis
                        item.option_id_list = item.option_id
                    } else if (item.question_type === 4 || item.question_type === 8 || item.question_type === 9) { //排序题
                        item.name = item.chart_name
                    } else if (item.question_type === 5 || item.question_type === 7) { // 滑条和打分
                        // item.name = item.name
                        // item.data = item.data
                        // item.x_axis = item.x_axis
                    } else if (item.question_type === 11) { //自定义
                        // item.name = item.name
                        // item.data = item.data
                        // item.word_arr = item.word_arr
                        // item.word_bar_count_arr = item.word_bar_count_arr
                        // item.x_axis = item.x_axis
                    } else if (item.question_type === 19) {
                        item.data_list = []
                        let tmp_formal = JSON.parse(JSON.stringify(item.option_list))
                        let data_list = []
                        for (let i = 0; i < tmp_formal.length; i++) {
                            data_list.push({
                                option_name: tmp_formal[i].option_name,
                                preference: 0,
                                option_max_selected_count: tmp_formal[i].option_max_selected_count,
                                option_min_selected_count: tmp_formal[i].option_min_selected_count,
                                option_occurance: tmp_formal[i].option_occurance,
                                score: calScore(tmp_formal[i].option_max_selected_count, tmp_formal[i].option_min_selected_count, tmp_formal[i].option_occurance),
                                p: calP(tmp_formal[i].option_max_selected_count, tmp_formal[i].option_min_selected_count, tmp_formal[i].option_occurance),
                                b: calB(tmp_formal[i].option_max_selected_count, tmp_formal[i].option_min_selected_count, tmp_formal[i].option_occurance),
                            })
                        }
                        for (let i = 0; i < data_list.length; i++) {
                            data_list[i]["preference"] = calPreference(i, data_list) || 0
                        }
                        item.data_list = data_list
                        item["option_img"] = []
                        item["option_id"] = []
                    }
                })

                this.setState({
                    preinstall_single_list: preinstall_single_list,
                    loading_chart: this.state.loading_chart - 1
                })
            }
            return response;
        } catch (error) {
            this.setState({
                preinstall_single_list: [],
                loading_chart: 0
            }, () => {
                message.error('后台发生错误')
            }
            )
            throw error;
        }
    }
    // 监听滚动
    handleScroll() {
        let card_chart = document.querySelector(".filter-chart-container");
        let card_fixed = document.querySelector(".filter-container-fixed");
        let card_content = document.querySelector(".chart-container");
        let side_bar_left = document.querySelector(".sidebar-left-container");
        let side_bar_top = document.querySelector(".sidebar-top-container");
        let brand = document.querySelector(".overall-top");
        let brand_fixed = document.querySelector(".product-dropdown-container");

        if (!brand) return
        if (!card_fixed) return


        let card_fixed_self_height = card_fixed.offsetHeight //自身高度 
        let side_bar_self_width = side_bar_left.offsetWidth + 36 // 加上左右的padding
        let side_bar_self_height = side_bar_top.offsetHeight

        //品牌的固定位置
        let brand_rect = brand.getBoundingClientRect();
        if (brand_rect.top <= side_bar_self_height - 55) { // 到可视区窗口的头
            brand_fixed.style.cssText = `position: fixed;top:10px;`
        } else {
            brand_fixed.style.cssText = "position:unset;"
        }

        //筛选框的固定位置
        let card_chart_rect = card_chart.getBoundingClientRect();
        if (card_chart_rect.top <= side_bar_self_height) { // 到可视区窗口的头
            card_fixed.style.cssText = `position: fixed;top:${side_bar_self_height}px;width:calc(100% - ${side_bar_self_width}px);box-shadow: 0px 2px 20px 8px #f8f9fd;min-width:1120px;`
            card_content.style.cssText = `position: relative;top:${card_fixed_self_height}px;margin-bottom: ${card_fixed_self_height}px;background: #fff;`
        } else {
            card_fixed.style.cssText = "position: relative;top: 0px;"
            card_content.style.cssText = "position: relative;top:0px;"
        }
        this.setState({
            a: card_fixed.style.cssText,
            b: card_content.style.cssText,
            c: card_content.offsetHeight
        })
    }
    //下载chart excel
    handleDownloadChart() {
        this.setState({
            loading: 1,
            loadingText: "数据较大，下载中"
        }, () => {
            this.exportProductSurvey()
        })
    }
    //下载chart excel 接口
    exportProductSurvey() {
        let product_id = this.state.product_id
        exportSurvey({
            product_id: product_id,
            survey_id: this.state.survey_id,
            survey_type: '2',
        }).then(res => {
            this.setState({
                loading: 0
            })
            if (res.status === 402) {
                message.warning('请联系心愿盒商务，了解并升级账户，谢谢！', 5, () => { })
            } else if (res.status === 204) {
                message.warning('暂未有报告', 2, () => { })
            } else {
                let time = this.getCurrentDownloadTime()
                let brand_text_cem = localStorage.getItem('brand_text_cem') ? localStorage.getItem('brand_text_cem') : ""
                let product_text_cem = localStorage.getItem('product_text_cem') ? localStorage.getItem('product_text_cem') : ""
                download(res.data, `预设评论分析_${brand_text_cem}${product_text_cem}_${time}.xlsx`, "binary");
                return res;
            }
        }).catch(err => {
            this.setState({
                loading: 0
            }, () => {
                message.error('待上传')
            }
            )
            throw err;
        })
    }
    // 搜索的filter
    searchFilter(filter_obj) {
        let { selected_region_list, selected_gender_list, selected_age_list, start_time, end_time } = filter_obj
        this.setState({
            selected_region_list: selected_region_list,
            selected_gender_list: selected_gender_list,
            selected_age_list: selected_age_list,
            start_time: start_time,
            end_time: end_time,
        }, () => {
            this.getPresetCommentSimple()
        })
    }
    // 批量下载的导出图片
    exportImg(dom, name, scrollY = 0) {
        let that = this
        dom.style.backgroundColor = "#fff";
        let height = dom.offsetHeight;
        let width = dom.offsetWidth;
        html2canvas(dom, {
            backgroundColor: "#fff",
            height: height + 10,
            width: width,
            scrollY,
            useCORS: true
        }).then(function (image) {
            if (image.msToBlob) {
                navigator.msSaveBlob(image.msToBlob(), `${name || Date.now()}.png`);
            } else {
                let url = image.toDataURL("image/png", 1.0);

                let batch_base_url = JSON.parse(JSON.stringify(that.state.batch_base_url))
                let obj = {
                    name: name,
                    base64_img: url
                }
                batch_base_url.push(obj)
                that.setState({
                    current_batch_download_num: that.state.current_batch_download_num + 1,
                    batch_base_url: batch_base_url
                })
            }
        });
    }
    // 批量下载
    batchDownload() {
        this.setState({
            loading_batch_download: 1,
            loadingText: "批量下载中，数据较大，请耐心等待",
            current_batch_download_num: 0
        }, () => {
            setTimeout(() => {
                for (let i = 0; i < this.state.preinstall_single_list.length; i++) {
                    let id = "chart_" + i + "_wrapper"
                    let dom = document.getElementById(id)
                    this.exportImg(
                        document.getElementById(id),
                        this.state.preinstall_single_list[i].name
                    );
                }
                let timer = setInterval(() => {
                    if (this.state.current_batch_download_num === this.state.preinstall_single_list.length) {
                        saveImgZip(this.state.batch_base_url)
                        clearInterval(timer)
                        this.setState({
                            loading_batch_download: 0
                        })
                    }
                }, 100)
            }, 0)
        })
    }
    // 点击步骤
    stepClick(data) {
        let index = data.index;
        if (index === 0) {
            localStorage.setItem('project_id', data.project_id)
            localStorage.setItem('current_project_type', 1)

            this.props.history.push(`/dispatch_list/project_entry?survey_id=${data.survey_id}&project_type=1&step=3`)
        }
        if (index === 1 || index === 2 || index === 3) {
            this.props.history.push(`/dispatch_list?s=${data.name}`)
        }
    }
    render() {
        return (
            <div className="container overall-container">
                {
                    this.state.loading <= 0 && this.state.loading_chart <= 0 && this.state.loading_batch_download <= 0 ? "" : <Loading loadingText={this.state.loadingText}></Loading>
                }


                <ProgressBar barList={this.state.barList} id={this.state.product_id} type="1" stepClick={this.stepClick.bind(this)}></ProgressBar>



                <div className="overall-top">
                    <ProductDropdown handleProductChange={this.handleProductChange.bind(this)}></ProductDropdown>
                    {/* <div className="top-right" onClick={this.addData.bind(this)}>
                        <img src={overall_icon_add} alt="" />
                        添加数据-敬请期待
                    </div> */}
                </div>
                <div className="dispatch-row">
                    <div className="dispatch-left-card">
                        <div className="dispatch-tag-wrapper">
                            <div className="dispatch-tag-list">
                                <div className="content">
                                    <div>总共派送产品(份)</div>
                                    <div>{this.state.dispatch_sum_val}</div>
                                </div>
                            </div>
                            <div className="dispatch-tag-list">
                                <div className="content">
                                    <div>已完成派送(份)</div>
                                    <div>{this.state.dispatch_finish_val}</div>
                                </div>

                            </div>
                            <div className="dispatch-tag-list">
                                <div className="content">
                                    <div>截止到{this.state.maxCommentDate}反馈率</div>
                                    <div>{this.state.dispatch_feedback}</div>
                                </div>

                            </div>
                        </div>
                        <DispatchDateChart dispatch_product_status={this.state.dispatch_product_status}></DispatchDateChart>
                    </div>
                    <div className="dispatch-right-card">
                        <div className="card-title">派送简介</div>
                        <div className="dispatch-intro-wrapper">
                            <div className='dispatch-intro-list'>
                                <img src={overall_icon_intro1} alt="" />
                                <div>
                                    <span>{`已完成${this.state.dispatch_finish_val}份派送`}</span>
                                    <span>{this.state.dispatch_time}</span>
                                </div>
                            </div>
                            <div className='dispatch-intro-list'>
                                <img src={overall_icon_intro2} alt="" />
                                <div>
                                    <span>派发&评论用户</span>
                                    <span>{`平均年龄为${this.state.dispatch_average_age}岁`}</span>
                                </div>
                            </div>
                            <div className='dispatch-intro-list'>
                                <img src={overall_icon_intro3} alt="" />
                                <div>
                                    <span>{`反馈率为${this.state.dispatch_feedback}`}</span>
                                    <span>{`平均反馈时长为${this.state.dispatch_average_feedback_duration}天`}</span>
                                </div>
                            </div>
                            <div className="list-report" onClick={this.handleDownload.bind(this)}>
                                下载深度分析报告
                            </div>
                        </div>
                    </div>
                </div>

                <div className='overall-data-chart-container'>
                    {/* 派送种草 */}
                    {
                        this.state.desire_chart_list.length ?
                            // <div className='overall-desire-container'>
                            <div className={this.state.dispatch_chart_list.length > 0 ? "overall-desire-container" : "overall-desire-container-2"}>
                                {
                                    this.state.desire_chart_list.map((item, index) => {
                                        return <DesireSingleChart key={"a" + index} dataList={item} single_id={`overall_desire_single_chart_${index}`}></DesireSingleChart>
                                    })
                                }
                            </div> : ""
                    }
                    {/* 派送年龄 */}
                    {
                        this.state.dispatch_chart_list.length ?
                            <div className={this.state.desire_chart_list.length > 0 ? "overall-dispatch-container" : "overall-dispatch-container-2"}>
                                {
                                    this.state.dispatch_chart_list.map((item, index) => {
                                        return <DispatchSingleChart key={"b" + index} dataList={item} single_id={`overall_dispatch_single_chart_${index}`}></DispatchSingleChart>
                                    })
                                }
                            </div> : ""
                    }
                </div>
                <div className="like-row">
                    <NormInfo product_id={this.state.product_id}></NormInfo>
                </div>
                <div className="filter-chart-container">
                    <div className="filter-container-fixed">
                        <FilterSelect ref={this.filterRef} searchFilter={this.searchFilter.bind(this)}></FilterSelect>
                        <Tooltip placement="top" title="下载">
                            <div className="download-wrapper" onClick={this.handleDownloadChart.bind(this)}>
                                <img src={download_icon} alt="" />
                            </div>
                        </Tooltip>
                    </div>
                    <div className="chart-container">
                        {
                            this.state.preinstall_single_list.length === 0 ?
                                ""
                                :
                                <div className="batch-download-btn-wrapper">
                                    <div className="batch-download-btn" onClick={this.batchDownload.bind(this)}>批量下载</div>
                                </div>
                        }
                        {
                            this.state.preinstall_single_list.map((item, index) => {
                                return this.chooseSingleChart(item, index)
                            })
                        }
                        {
                            this.state.preinstall_single_list.length === 0 ?
                                <div className="rank-content-empty">
                                    <ConfigProvider locale={zhCN}>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </ConfigProvider>
                                </div>
                                :
                                ""
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Overall_data;
