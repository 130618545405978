import axiosInstance from "../APIUtils";

// 获取品牌的所有project
export function getBrandProject(obj) {
  return axiosInstance({
    url: "/cem/getBrandProject/",
    method: "POST",
    data: obj,
  });
}

// 获取简答题题目-产品
export function getAnalysisProjectQuestions(obj) {
  return axiosInstance({
    url: "/cem/getAnalysisProjectQuestions/",
    method: "POST",
    data: obj,
  });
}

// 获取简答题题目-调研
export function getAnalysisSpecialEventQuestions(obj) {
  return axiosInstance({
    url: "/cem/getAnalysisSpecialEventQuestions/",
    method: "POST",
    data: obj,
  });
}

// 查找分析数据会话数据列表 + 创建会话
export function getAnalysisSessionList(obj) {
  return axiosInstance({
    url: "/cem/getAnalysisSessionList/",
    method: "POST",
    data: obj,
  });
}

// 简答题回答数据分析
export function getAnalysisQuestions(obj) {
  return axiosInstance({
    url: "/cem/AnalysisQuestions/",
    method: "POST",
    data: obj,
    timeout: 360000, //6分钟
  });
}

// 删除会话历史记录
export function deleteAnalysisSession(obj) {
  return axiosInstance({
    url: "/cem/deleteAnalysisSession/",
    method: "POST",
    data: obj,
  });
}

// 获取问卷用户数据预览
export function getSurveyUserAnswer(obj) {
  return axiosInstance({
    url: "/cem/GetSurveyUserAnswer/",
    method: "POST",
    data: obj,
  });
}
