import React, { Component } from "react";
import "./survey_dropdown.scss";
import XmSelect from "../../../components/Select/xm_select";
import { getBrandSurvey } from "../../../api/panelApi";
import { getQueryParameter } from "../../../util/utils";
import { message } from "antd";

export default class survey_dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      survey_id: "",
      special_event_id: "",
      survey_list: [],
      survey_text: "",
    };
  }
  componentDidMount() {
    this.init();
  }
  init() {
    this.getBrandSurvey();
  }
  //获取调研主题列表
  async getBrandSurvey() {
    let brand_id = localStorage.getItem("brand_id")
      ? localStorage.getItem("brand_id")
      : "";
    try {
      const res = await getBrandSurvey({
        brand_id: parseInt(brand_id),
      });
      if (!res.data || res.data.length === 0) {
        message.warning("暂无数据", 2, () => {});
        return;
      }

      let survey_list = [];
      let survey_list_formal = JSON.parse(JSON.stringify(res.data));
      for (let i = 0; i < survey_list_formal.length; i++) {
        let obj = {
          key: survey_list_formal[i].id,
          value: survey_list_formal[i].name,
          special_event_id: survey_list_formal[i].special_event_id,
        };
        survey_list.push(obj);
      }
      if (window.location.search.indexOf("survey_id") !== -1) {
        // 从首页跳转过来
        this.initSurvey(survey_list);
      } else if (parseInt(localStorage.getItem("survey_id_cem")) > 0) {
        // 刷新 但已存在
        let survey_list_prev = JSON.parse(
          localStorage.getItem("survey_list_cem")
        );
        let isObjectValueEqual = this.isObjectValueEqual(
          survey_list,
          survey_list_prev
        );
        if (isObjectValueEqual) {
          this.initPanel(survey_list);
        } else {
          //如超户的survey列表进行了更新
          this.initSurvey(survey_list);
        }
      } else {
        // 之前没有survey_id
        this.initSurvey(survey_list);
      }
      return res;
    } catch (error) {
      message.error("后台发生错误");
      throw error;
    }
  }
  // 测试
  initSurvey(survey_list) {
    let survey_id = survey_list[0].key;
    let survey_text = survey_list[0].value;
    let special_event_id = survey_list[0].special_event_id;
    if (window.location.search.indexOf("survey_id") !== -1) {
      // 从首页跳转过来
      survey_id = getQueryParameter("survey_id");
      let item = this.getSurveyText(survey_id, survey_list);
      survey_text = item.value;
      special_event_id = item.special_event_id;
    }
    localStorage.setItem("survey_id_cem", parseInt(survey_id));
    localStorage.setItem("survey_text_cem", survey_text);
    localStorage.setItem("survey_list_cem", JSON.stringify(survey_list));
    localStorage.setItem("special_event_id_cem", special_event_id);
    this.setState(
      {
        survey_id: parseInt(survey_id),
        survey_list: survey_list,
        survey_text: survey_text,
        special_event_id: special_event_id,
      },
      () => {
        this.props.handleSurveyChange(survey_id, special_event_id);
      }
    );
  }
  // local storage 有
  initPanel() {
    let survey_id = localStorage.getItem("survey_id_cem");
    let survey_list =
      localStorage.getItem("survey_list_cem") !== ""
        ? JSON.parse(localStorage.getItem("survey_list_cem"))
        : [];
    let survey_text = localStorage.getItem("survey_text_cem");
    let special_event_id = localStorage.getItem("special_event_id_cem");
    this.setState(
      {
        survey_id: survey_id ? parseInt(survey_id) : "",
        survey_list: survey_list,
        survey_text: survey_text,
        special_event_id: special_event_id,
      },
      () => {
        this.props.handleSurveyChange(survey_id, special_event_id);
      }
    );
  }
  // 判断两个对象是否相等
  isObjectValueEqual(obj1, obj2) {
    if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
      return true;
    }
    return false;
  }
  // 下拉框搜索可以大小写
  filterSelectOption(input, option) {
    return (
      option.children.props.children
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  // 点击选择调研主题
  onSelectSurveyChange(key, option) {
    if (window.location.search.indexOf("survey_id") !== -1) {
      // 从调研计划跳转过来
      var url = window.location.href;
      if (url.indexOf("?") !== -1) {
        url = url.replace(/(\?|#)[^'"]*/, ""); //去掉http地址参数
        window.history.pushState({}, 0, url);
      }
    }
    let cur_index = parseInt(option.key);
    let survey_list = JSON.parse(JSON.stringify(this.state.survey_list));
    if (parseInt(key) !== parseInt(this.state.survey_id)) {
      this.clearData();
    }

    let survey_id = survey_list[cur_index].key;
    let survey_text = survey_list[cur_index].value || "";
    let item = this.getSurveyText(survey_id, survey_list);
    survey_text = item.value;
    let special_event_id = item.special_event_id;
    localStorage.setItem("survey_id_cem", survey_id);
    localStorage.setItem("survey_text_cem", survey_text);
    localStorage.setItem("special_event_id_cem", special_event_id);
    this.setState(
      {
        survey_id: survey_id,
        survey_text: survey_text,
        special_event_id: special_event_id,
      },
      () => {
        this.props.handleSurveyChange(survey_id, special_event_id);
      }
    );
  }
  // 获取 survey_text
  getSurveyText(id, survey_list) {
    for (let i = 0; i < survey_list.length; i++) {
      if (String(id) === String(survey_list[i].key)) {
        return survey_list[i];
      }
    }
    return "";
  }
  //清空数据
  clearData() {
    // this.setState({})
  }
  render() {
    return (
      <div className="gpt-survey-dropdown-container">
        <XmSelect
          showSearch
          filterOption={(input, option) => {
            return this.filterSelectOption.call(this, input, option);
          }}
          defaultValue={this.state.survey_id}
          key={this.state.survey_id}
          options={this.state.survey_list}
          onChange={(e, index) => {
            this.onSelectSurveyChange(e, index);
          }}
        ></XmSelect>
      </div>
    );
  }
}
