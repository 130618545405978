import React, { Component } from 'react'
import './progress_bar.scss'
import { getSpecialEventProgress, getProjectProgress } from '../../api/progressApi/progress'
// 引入图片

export default class progress_bar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      projectId: '',
      special_event_id: '',
      projectList: [],
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.id !== nextProps.id) {
      this.getProgressStep(nextProps.id);
    }
    return true;
  }

  // 获取进度条进度
  getProgressStep(id) {
    if (this.props.type === '1') {
      this.getProjectProgress(id);
    } else if (this.props.type === '2') {
      this.getSpecialProgress(id);
    } else {
      this.setState({
        step: 0
      })
    }
  }
  // 获取调研进度
  getSpecialProgress(id) {
    let param = {
      special_event_id: id
    }
    getSpecialEventProgress(param).then(res => {
      this.setState({
        step: Number(res.data.progress_status) === 6 ? 7 : Number(res.data.progress_status),
        projectId: res.data.product_id,
        special_event_id: id,
      })
    }, err => {
      console.log(err)
    })

  }
  // 获取派送进度
  getProjectProgress(id) {
    if (!id) {
      this.setState({ projectList: [] });
      return;
    }
    let param = {
      product_id: id
    }
    getProjectProgress(param).then(res => {
      res.data.map(item => {
        item.progess_status = Number(item.progess_status) === 6 ? 7 : Number(item.progess_status);
      })
      this.setState({ projectList: res.data })
      console.log(this);
    }, err => {
      console.log(err)
      this.setState({ projectList: [] })
    })
  }

  // 点击
  oClick(index) {
    let step = this.state.step;
    if (step === 1) {
      if (index === 0) {
        this.props.stepClick({ index: index, id: Number(this.state.projectId), special_event_id: this.state.special_event_id, step: 3 })
      }
    }
    if (step === 2) {
      if (index === 1) {
        this.props.stepClick({ index: index, id: Number(this.state.projectId), special_event_id: this.state.special_event_id, })
      }
    }
    if (step === 3) {
      if (index === 2) {
        this.props.stepClick({ index: index, id: Number(this.state.projectId), special_event_id: this.state.special_event_id, })
      }
    }
    if (step === 4) {
      if (index === 3) {
        this.props.stepClick({ index: index, id: Number(this.state.projectId), special_event_id: this.state.special_event_id, })
      }
    }

  }
  oClick1(index, project) {
    if (index === 1 && project.progess_status === 2) {
      this.props.stepClick({ index: index, name: project.project_name, survey_id: project.survey_id, project_id: project.project_id })
    }
    if (index === 2 && project.progess_status === 3) {
      this.props.stepClick({ index: index, name: project.project_name, survey_id: project.survey_id, project_id: project.project_id })
    }
    if (index === 3 && project.progess_status === 4) {
      this.props.stepClick({ index: index, name: project.project_name, survey_id: project.survey_id, project_id: project.project_id })
    }
  }
  // 返回不同的进度条
  randerProgressBar() {
    if (this.props.type == 1) {  // 
      return (
        <>
          {
            this.state.projectList.map((project, pIndex) => {
              return <>

                <p>项目名称：{project.project_name}</p>
                <div className="progress-bar mb10" key={pIndex}>
                  {
                    this.props.barList.map((item, index) => {
                      return <div className={`bar-item-type1 ${index < project.progess_status - 1 ? 'h' : ''} ${index === project.progess_status - 1 ? 'n' : ''}`} key={index}>
                        <div className='bar-content' onClick={this.oClick1.bind(this, index, project)}>
                          {item.name}
                        </div>
                      </div>
                    })
                  }
                </div>
              </>

            })
          }
        </>
      )
    } else if (this.props.type == 2) {  // 
      return (
        <div className="progress-bar line">
          {
            this.props.barList.map((item, index) => {
              return <div className={`bar-item ${index < this.state.step - 1 ? 'h' : ''} ${index === this.state.step - 1 ? 'n' : ''}`} key={index}>
                <div onClick={this.oClick.bind(this, index)}>
                  <img src={item.imgUrl} />
                </div>
                <p>{item.name}</p>
              </div>
            })
          }
        </div>
      )
    } else {
      return (<p>进度条组件-type值不正确</p>)
    }
  }
  render() {
    return <>
      {
        this.props.type == 2 || this.state.projectList.length ?
          <div className='progress-box'>
            {
              this.randerProgressBar.bind(this)()
            }
          </div>
          : ''
      }

    </>



  }
}