import React, { Component } from 'react'
import { Tooltip, Image, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import norm_1 from '../../../../images/home_page/norm_1.png'
import norm_2 from '../../../../images/home_page/norm_2.png'
import norm_3 from '../../../../images/home_page/norm_3.png'
import norm_4 from '../../../../images/home_page/norm_4.png'
import norm_5 from '../../../../images/home_page/norm_5.png'

export default class norm_item extends Component {
    constructor(props) {
        super(props)
        this.state = {
            norm_img_arr: [norm_1, norm_2, norm_3, norm_4, norm_5]
        }
    }
    render() {
        let index = this.props.index
        let norm = this.props.norm
        return (
            <div className="norm-item">
                <div className="norm-sort">
                    <img src={this.state.norm_img_arr[`${parseInt(norm.quantiles - 1)}`]} alt="" />
                    <div className='norm-sort-num'>{index + 1}</div>
                </div>
                <div className="norm-img">
                    <ConfigProvider locale={zhCN}>
                        <Image src={norm.product_image} alt="" width={42} height={42} />
                    </ConfigProvider>
                </div>

                <div className="norm-title-wrapper">
                    <div className="norm-name">
                        <Tooltip placement="bottom" title={norm.name}>
                            {norm.name}
                        </Tooltip>
                    </div>
                    <div className="norm-num-wrapper">
                        <span>种草率</span>
                        <span className="norm-num">{norm.like_rate}</span>
                    </div>
                </div>
            </div>
        )
    }
}
