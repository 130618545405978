import React, { Component } from 'react'
import { connect } from 'react-redux'
import { editUser, getUser, editUserItem } from "../../redux/actions/user"
import './user_info.scss';
import tab_img_avatar from '../../images/side_bar/tab_img_avatar.png'
import edit_icon from '../../images/login/edit_icon.png';
import edit_icon_active from '../../images/login/edit_icon_active.png';
import icon_user_upgrade from '../../images/side_bar/user_upgrade.png';
import icon_user_recharge from '../../images/side_bar/user_recharge.png';
import icon_user_purchase from '../../images/side_bar/user_purchase.png';
import icon_user_logout from '../../images/side_bar/user_logout.png';
import icon_user_logout_active from '../../images/side_bar/user_logout_active.png';
import XmModel from '../../components/Xm_model/Xm_model'
import Loading from "../../components/Loading/loading"
import { Upload, Table, ConfigProvider, Image, message } from 'antd';
import { account_type_obj } from './Values/user_data'
import { getRefreshToken } from '../../util/auth'
import { handleUploadImg } from "../../util/utils"
import { updateBrandAuthInfoFull, getQuotaFlowHistory } from '../../api/token'
import { logout } from '../../api/token'
import zhCN from 'antd/lib/locale/zh_CN';
import { desired_info_list } from './Values/user_data'
import UserEditPopup from './Popup/user_edit_popup'

class user_info extends Component {
    constructor(props) {
        super(props);
        this.editRef = React.createRef();
        this.state = {
            columns: [
                {
                    title: '流水名称',
                    dataIndex: 'comment',
                    key: 'comment',
                    fixed: 'left',
                },
                {
                    title: '人数额度流向',
                    dataIndex: 'direction',
                    key: 'direction',
                    align: 'center',
                    render: (item, index) => {
                        return <div>{item === 0 ? "流出" : "流入"}</div>;
                    }
                },
                {
                    title: '使用额度',
                    dataIndex: 'quota',
                    key: 'quota',
                    align: 'center',
                },
                {
                    title: '当前人数额度',
                    dataIndex: 'current_quota',
                    key: 'current_quota',
                    align: 'center',
                },
                {
                    title: '使用时间',
                    dataIndex: 'create_time',
                    key: 'create_time',
                    align: 'center',
                },
            ],
            showModel: false,
            modelTitle: "登出提示",
            modelContent: "确定登出心愿盒吗？",
            desired_info_list: desired_info_list,
            show_edit_popup: false,//是否显示修改弹窗
            quota_list: [],// 人数额度流水
            total_number: 0, // 总共多少条
            current_page: 1, //当前页
            quota_direction: 0,//流向 0 全部 1 进 2 出
            page_size: 5,//每页多少条
            show_quota_list: false,//是否显示人数额度流水
            loading_quota: 0,
            loadingText: "加载额度中",
            quota_direction_list: [
                { key: 0, value: "全部" },
                { key: 1, value: "进" },
                { key: 2, value: "出" },
            ]

        }
    }
    componentDidMount() {
        this.getQuotaFlowHistory()
    }
    // 上传图片
    handleUpload(args) {
        let _this = this//烦人的this
        handleUploadImg(args).then(res => {
            if (res !== -1) {
                let obj = {
                    file: res.imgs,
                    filename: res.filename
                }
                let user = JSON.parse(JSON.stringify(_this.props.user))
                user["profile_pic"] = obj
                _this.updateBrandAuthInfoFull(user)
            }
        })
    }
    // 第一个参数是设置是否显示，第二个参数就是子组件选确认
    handleModel(val, flag) {
        if (flag) {
            let refresh_token = getRefreshToken()
            logout(refresh_token).then(res => {
                if (res.status === 205) {
                    localStorage.clear();
                    window.location.href = '/login';
                }
            }).catch(err => {
                console.log('error', err)
            })
        }
        this.setState({
            showModel: val,
        })
    }
    // 子组件传给父组件修改用户信息
    handleEditInfo(obj) {
        this.setState({
            show_edit_popup: obj.show_edit_popup,
        })
        if (!obj.is_confirm) { // 按的取消的键
            return
        }
        // 按的是提交
        let { edit_type, edit_value, sms_code } = obj
        let user = JSON.parse(JSON.stringify(this.props.user))
        user[edit_type] = edit_value
        if (sms_code) {
            user.sms_code = sms_code
        }
        this.updateBrandAuthInfoFull(user)
    }
    // quota流水记录
    async getQuotaFlowHistory() {
        this.setState({
            loading_quota: 1
        })
        try {
            const response = await getQuotaFlowHistory({
                direction: this.state.quota_direction,//流向 0 全部 1 进 2 出
                page: this.state.current_page, // 页
                count: this.state.page_size // 每页条数
            })
            if (response.status === 204) {
                this.setState({
                    quota_list: [],
                    total_number: 0,
                    loading_quota: 0
                })
            } else {
                this.setState({
                    quota_list: response.data.data,
                    total_number: response.data.total,
                    loading_quota: 0
                })
            }
        } catch (error) {
            this.setState({
                loading_quota: 0
            })
            message.error('获取额度流水失败，请稍后再试')
            throw error;
        }
    }
    //更新用户所有个人信息
    async updateBrandAuthInfoFull(user) {
        try {
            const response = await updateBrandAuthInfoFull({
                ...user
            })
            // 再异步获取一次
            this.props.getUser()
            message.success('保存成功', 0.7, () => { })
        } catch (error) {
            message.error('修改失败，请稍后再试')
            throw error;
        }
    }
    // 是否显示修改弹窗
    showEditPopup(t) {
        if (t === "company") {
            message.warning('请联系管理员修改公司名称', 2, () => { })
            return
        }
        let val = this.props.user[t]
        this.setState({
            show_edit_popup: true
        }, () => {
            this.editRef.current.setEditInfo({
                edit_type: t,
                edit_value: val
            })
        })
    }
    // 提醒
    showNotice = () => {
        message.warning('敬请期待', 2, () => { })
    }
    // 点击quota列表的分页
    handleQuotaPage(page, pageSize) {
        this.setState({
            current_page: page
        }, () => {
            this.getQuotaFlowHistory()
        });
    }
    // 点击显示or隐藏额度流水列表
    handleQuotaList() {
        this.setState({
            show_quota_list: !this.state.show_quota_list
        })
    }
    handleQuotaDirection(item) {
        this.setState({
            quota_direction: item.key,
            current_page: 1,
        }, () => {
            this.getQuotaFlowHistory()
        })
    }
    render() {
        return (
            <div className='container user-container'>
                {
                    this.state.loading_quota <= 0 ? "" : <Loading loadingText={this.state.loadingText}></Loading>
                }
                <div className='user-info'>
                    <div className="user-info-top">
                        账户信息
                    </div>
                    <div className="user-avatar-wrapper">
                        <div className="user-avatar-img">
                            <ConfigProvider locale={zhCN}>
                                <Image src={`${this.props.user.profile_pic.file ? this.props.user.profile_pic.file : tab_img_avatar}`} alt="" className="show-pic" width={82} height={82} />
                            </ConfigProvider>
                        </div>
                        <Upload
                            showUploadList={false}
                            customRequest={this.handleUpload.bind(this)}
                        >
                            <div className="user-avatar-text">
                                上传新头像
                            </div>
                        </Upload>
                    </div>
                    <div className="user-detail-wrapper">
                        <div className="user-detail-item">
                            <div className="user-detail-left">
                                <div className="user-detail-title">用户昵称：</div>
                                <div className="user-detail-fill">{this.props.user.first_name}</div>
                            </div>
                            <div className="user-detail-right" onClick={this.showEditPopup.bind(this, "first_name")}>
                                <div className="user-revise-text">修改</div>
                                <div className="user-revise-img">
                                    <img src={edit_icon} alt="" className='edit-icon' />
                                    <img src={edit_icon_active} alt="" className='edit-icon-active' />
                                </div>
                            </div>
                        </div>
                        <div className="user-detail-item">
                            <div className="user-detail-left">
                                <div className="user-detail-title">账户电话：</div>
                                <div className="user-detail-fill">{this.props.user.phone}</div>
                            </div>
                            <div className="user-detail-right" onClick={this.showEditPopup.bind(this, "phone")}>
                                <div className="user-revise-text">修改</div>
                                <div className="user-revise-img">
                                    <img src={edit_icon} alt="" className='edit-icon' />
                                    <img src={edit_icon_active} alt="" className='edit-icon-active' />
                                </div>
                            </div>
                        </div>
                        <div className="user-detail-item">
                            <div className="user-detail-left">
                                <div className="user-detail-title">品牌名称：</div>
                                <div className="user-detail-fill">{this.props.user.brand}</div>
                            </div>
                            <div className="user-detail-right" onClick={this.showEditPopup.bind(this, "brand")}>
                                <div className="user-revise-text">修改</div>
                                <div className="user-revise-img">
                                    <img src={edit_icon} alt="" className='edit-icon' />
                                    <img src={edit_icon_active} alt="" className='edit-icon-active' />
                                </div>
                            </div>
                        </div>
                        <div className="user-detail-item">
                            <div className="user-detail-left">
                                <div className="user-detail-title">公司名称：</div>
                                <div className="user-detail-fill">{this.props.user.company}</div>
                            </div>
                            <div className="user-detail-right" onClick={this.showEditPopup.bind(this, "company")}>
                                <div className="user-revise-text">修改</div>
                                <div className="user-revise-img">
                                    <img src={edit_icon} alt="" className='edit-icon' />
                                    <img src={edit_icon_active} alt="" className='edit-icon-active' />
                                </div>
                            </div>
                        </div>
                        <div className="user-detail-item">
                            <div className="user-detail-left">
                                <div className="user-detail-title">部门：</div>
                                <div className="user-detail-fill">{this.props.user.department}</div>
                            </div>
                            <div className="user-detail-right" onClick={this.showEditPopup.bind(this, "department")}>
                                <div className="user-revise-text">修改</div>
                                <div className="user-revise-img">
                                    <img src={edit_icon} alt="" className='edit-icon' />
                                    <img src={edit_icon_active} alt="" className='edit-icon-active' />
                                </div>
                            </div>
                        </div>
                        <div className="user-detail-item">
                            <div className="user-detail-left">
                                <div className="user-detail-title">职位：</div>
                                <div className="user-detail-fill">{this.props.user.position}</div>
                            </div>
                            <div className="user-detail-right" onClick={this.showEditPopup.bind(this, "position")}>
                                <div className="user-revise-text">修改</div>
                                <div className="user-revise-img">
                                    <img src={edit_icon} alt="" className='edit-icon' />
                                    <img src={edit_icon_active} alt="" className='edit-icon-active' />
                                </div>
                            </div>
                        </div>
                        <div className="user-detail-item">
                            <div className="user-detail-left">
                                <div className="user-detail-title">账户类型：</div>
                                <div className="user-detail-fill">{account_type_obj[this.props.user.account_type]}</div>
                            </div>
                            <div className="user-detail-right">
                                <div className="user-revise-text user-revise-text-2" onClick={this.showNotice}>升级账户</div>
                                <div className="user-revise-img">
                                    <img src={icon_user_upgrade} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="user-detail-item">
                            <div className="user-detail-left">
                                <div className="user-detail-title">账户余额：</div>
                                <div className="user-detail-fill">{this.props.user.balance}元</div>
                            </div>
                            <div className="user-detail-right">
                                <div className="user-revise-text user-revise-text-2" onClick={this.showNotice}>充值余额</div>
                                <div className="user-revise-img">
                                    <img src={icon_user_recharge} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="user-detail-item">
                            <div className="user-detail-left">
                                <div className="user-detail-title">剩余人数quota：</div>
                                <div className="user-detail-fill">{this.props.user.quota}</div>
                            </div>
                            <div className="user-detail-right">
                                <div className='user-revise-text' onClick={this.handleQuotaList.bind(this)}>{this.state.show_quota_list ? "收起额度" : "查看额度"}</div>
                                <div className="user-revise-text user-revise-text-2" onClick={this.showNotice}>购买quota</div>
                                <div className="user-revise-img">
                                    <img src={icon_user_purchase} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className={this.state.show_quota_list ? 'user-quota-card' : "isHide"}>
                            <div className='quota-operation-wrapper'>
                                <div>人数quota流向：</div>
                                <div className='quota-operation'>
                                    {
                                        this.state.quota_direction_list.map((item, index) => {
                                            return (
                                                <div className={this.state.quota_direction === item.key ? 'quota-operation-item quota-operation-item-active' : 'quota-operation-item'} key={index} onClick={this.handleQuotaDirection.bind(this, item)}>{item.value}</div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="quota-operation-table">
                                <ConfigProvider locale={zhCN}>
                                    <Table
                                        dataSource={this.state.quota_list}
                                        columns={this.state.columns}
                                        pagination={{
                                            current: this.state.current_page,
                                            pageSize: this.state.page_size,
                                            total: this.state.total_number,
                                            showSizeChanger: false,
                                            showQuickJumper: true,
                                            onChange: (page, pageSize) => {
                                                this.handleQuotaPage(page, pageSize)
                                            }
                                        }}
                                        scroll={{ x: 900 }}
                                        rowKey={columns => columns.id} />
                                </ConfigProvider>
                                <div className="table-sum-wrapper">共<span>{this.state.quota_list.length || 0}</span>个结果，当前展示1-10行</div>
                            </div>
                        </div>
                        <div className="user-detail-item">
                            <div className="user-detail-left">
                                <div className="user-detail-title">想了解的信息：</div>
                                <div className="user-detail-fill">
                                    {
                                        this.props.user.desired_info.map((item, index) => {
                                            return <div key={index}> {this.state.desired_info_list[parseInt(item) - 1]?.val}</div>
                                        })
                                    }
                                </div>
                            </div>
                            <div className="user-detail-right" onClick={this.showEditPopup.bind(this, "desired_info")}>
                                <div className="user-revise-text">修改</div>
                                <div className="user-revise-img">
                                    <img src={edit_icon} alt="" className='edit-icon' />
                                    <img src={edit_icon_active} alt="" className='edit-icon-active' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="user-detial-btn" onClick={() => this.handleModel(true, false)}>
                        登出
                        <img src={icon_user_logout} alt="" className="icon" />
                        <img src={icon_user_logout_active} alt="" className="icon-active" />
                    </div>
                </div>
                <XmModel isShowModel={this.state.showModel} handleShowModel={this.handleModel.bind(this)} modelContent={this.state.modelContent} modelTitle={this.state.modelTitle}></XmModel>
                <div className={this.state.show_edit_popup ? "" : "isHide"}>
                    <UserEditPopup ref={this.editRef} handleEditInfo={this.handleEditInfo.bind(this)}></UserEditPopup>
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        user: state.user,
    }),//映射状态
    { editUser, getUser, editUserItem }//映射操作状态的方法
)(user_info)
