import React, { Component } from 'react'
import './freecross_info_popup.scss';
import cur_icon_close_gre from '../../../../images/cur_icon_close_gre.png'
import { Tabs, message, ConfigProvider, Table } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import debounce from 'lodash/debounce';

export default class freecross_info_popup extends Component {
    constructor(props) {
        super(props)
        this.resize = debounce(this.resize, 1000);
        this.state = {
            dataList: [],
            info_table_index: 0,
            blankListHeight: 258
        }
    }
    setMaxFreecrossInfo(obj) {
        this.debounceFunc = this.resize.bind(this);
        window.addEventListener("resize", this.debounceFunc, false);
        let { dataList, info_table_index } = obj
        this.setState({
            dataList: dataList,
            info_table_index: info_table_index
        }, () => {
            this.resize()
        })
    }
    tags_close() {
        this.props.canShowFreecrossInfoPopup({ showFreecrossInfoPopup: false })
    }
    // 分页
    showPagination(table_data_list) {
        if (table_data_list?.length < 30) {
            return false
        }
        return { pageSize: 30, showSizeChanger: false, showQuickJumper: true, size: "small" }
    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    resize() {
        let dom = document.getElementById("freecross_info_popup_" + this.state.info_table_index)
        if (!dom) { return; }
        let domWHeight = dom.offsetHeight;
        let blankDomHeight = 258
        blankDomHeight = domWHeight - 450;

        this.setState({ blankListHeight: blankDomHeight })
    }
    render() {
        return (
            <div className="freecross-info-popup" id={`freecross_info_popup_${this.state.info_table_index}`} >
                <div className="freecross-info-popup-container">
                    {/* 顶部展示 */}
                    <div className='max-chart-popup-top'>
                        <div className='popup-top-title'>列表展示</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                        </div>
                    </div>

                    <div className="popup-content">
                        <div className="table-name">
                            {this.state.dataList.table_name}
                        </div>
                        <ConfigProvider locale={zhCN}>
                            <Table
                                dataSource={this.state.dataList.table_data_list}
                                columns={this.state.dataList.columns}
                                pagination={this.showPagination(this.state.dataList.table_data_list)}
                                rowKey={columns => columns.id}
                                scroll={{ y: this.state.blankListHeight ? this.state.blankListHeight : 258 }}
                            />
                        </ConfigProvider>
                    </div>
                </div>
            </div>
        )
    }
}
