import React, { Component } from "react";
import "./ai_copywriting.scss";
import cur_icon_rect_nor from "../../../images/gpt_analysis/cur_icon_rect_nor.png";
import cur_icon_rect_half_sel from "../../../images/gpt_analysis/cur_icon_rect_half_sel.png";
import cur_icon_rect_sel from "../../../images/gpt_analysis/cur_icon_rect_sel.png";
import icon_xhs from "../../../images/emotion_analysis/ai_copywriting/icon_xhs.png";
import icon_search from "../../../images/product_list/icon_search.png";
import icon_delete from "../../../images/product_list/icon_delete.png";
import icon_history from "../../../images/emotion_analysis/ai_copywriting/icon_history.png";
import icon_copy from "../../../images/emotion_analysis/ai_copywriting/icon_copy.png";
import icon_loading from "../../../images/gpt_analysis/icon_loading.gif";
import {
  generateXhsComment,
  getXhsHistory,
  deleteXhsComment,
} from "../../../api/panelApi";
import { copyText } from "../../../util/utils";
import { validatePureNumber } from "../../../util/enum";
import EditNode from "../../../components/EditNode/edit_node";
import { message, Input, Pagination, ConfigProvider, Button } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
const { Search } = Input;

export default class ai_copywriting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product_id: -1,
      pagination_current_page: 1, //当前页面
      pagination_interval: 3, //每页5个
      copywriting_list_sum: 10, //总共10个
      scene_index: 0,
      scene_list: [
        {
          img: icon_xhs,
          name: "小红书文案生成",
          is_selected: true,
        },
      ],
      prompt: "",
      prompt_num: "", //多少条prompt
      search_text: "", //搜索关键字
      is_view_history: false, //是否查看历史
      checkbox_index: 0, //0 未选择 1 已选择 2全选
      checkbox_list: [
        {
          name: "未选择",
          img: cur_icon_rect_nor,
        },
        {
          name: "已选择",
          img: cur_icon_rect_half_sel,
        },
        {
          name: "全选",
          img: cur_icon_rect_sel,
        },
      ],
      copywriting_list_selected: [], //选中的文案
      copywriting_list: [
        // {
        //     index: 0,
        //     content: "👀【伦敦度假攻略】- 想吃到正宗的炸鱼薯条？来伦敦没错了！除了这个特色小吃，还有哪些必去景点？🤔",
        //     is_selected: false
        // },
        // {
        //     index: 1,
        //     content: "🍟【炸鱼薯条】- 来伦敦，怎么能错过这个经典美食呢？要想吃到最正宗的炸鱼薯条，可以去历史悠久的The Golden Hind或者The Seashell of Lisson Grove尝试一下。",
        //     is_selected: false
        // },
        // {
        //     index: 2,
        //     content: "一. 标题<br>1. 🍸一杯小酌不为过，晚上工作再累也要放松！\n2. 💆‍♀️解压神器，品味独特的小酌美味！\n3. 🥂想要工作生活更美好，一杯小酒神不可少！\n4. 🍷让躯体与灵魂得到放松，先来一杯小酌吧！\n5. 🍹品味生活中的小确幸，晚上小酌带给你轻松心情！\n\n二. 正文\n🥂正在奋斗的小伙伴们，你们是否也因压力太大而感到颓废郁闷呢？尝试一杯小酌，或许会使你的心情得到放松！\n🍷对于小酌来说，价格会直接影响到我们的购买意愿和数量，但产品的口感和包装也是不可忽略的关键，建议尝试6元-8元间的小酌，性价比更高哦！\n🍸当然，品味独特的小酌美味更是令人欣喜不已，品尝时，充分感受酒体的醇香，慢慢享受芳香回荡，细细品味细节之处，也能使自己得到放松。\n💆‍♀️晚上工作累了，一杯小酌，秉承“生活的真谛在于温馨和快乐”这一理念，带给我们轻松愉悦的心情和美好的品味。\n💡小提示：在享受小酌美味的同时，适量饮用并注意自己的身体健康哦。\n标签：#小酌美味#解压小品#轻松心情",
        //     is_selected: false
        // }
      ],
      copywriting_list_history: [], // xhs历史文案记录
      copywriting_list_generate: [], //当前生成的文案列表
      copywriting_list_generate_overloaded: [], //OpenAI 负载后再去请求一次
      is_generating: false, //是否正在生成
      is_generating_num: "", //正在生成多少条
      generate_button_text: "开始生成",
    };
  }
  componentDidMount() {
    // this.init()
  }
  componentDidUpdate(preProps, preState) {
    if (this.props.product_id_cem !== this.state.product_id) {
      this.setState(
        {
          product_id: this.props.product_id_cem,
        },
        () => {
          this.init();
        }
      );
    }
  }
  // 初始化
  init() {
    this.setState({
      copywriting_list: [],
      copywriting_list_generate: [],
      copywriting_list_generate_overloaded: [],
      prompt: "",
      prompt_num: "", //多少条prompt
      search_text: "", //搜索关键字
      is_generating: false, //是否正在生成
      generate_button_text: "开始生成",
      is_view_history: false,
      pagination_current_page: 1,
      checkbox_index: 0,
      copywriting_list_selected: [],
    });
  }
  // 切换场景
  handleScene = () => {
    message.warning("敬请期待", 1, () => { });
  };
  // 切换历史记录
  handleSwitchView = () => {
    if (this.state.is_view_history) {
      // 之前是历史记录
      this.setState({
        is_view_history: !this.state.is_view_history,
        copywriting_list: this.state.copywriting_list_generate,
        search_text: "",
      });
    } else {
      this.setState(
        {
          is_view_history: !this.state.is_view_history,
          checkbox_index: 0,
          copywriting_list_selected: [],
          search_text: "",
        },
        () => {
          this.getXhsHistory();
        }
      );
    }
  };
  // 获取小红书历史记录
  async getXhsHistory() {
    try {
      const response = await getXhsHistory({
        product_id: this.state.product_id,
        type: this.state.scene_index,
        page: this.state.pagination_current_page,
        count: this.state.pagination_interval,
        search_text: this.state.search_text,
      });
      if (response.status === 204) {
        this.setState({
          copywriting_list_history: [],
          copywriting_list: [],
          copywriting_list_sum: 0,
        });
        message.warning("暂无数据", 2, () => { });
        return;
      }
      let search_text = this.state.search_text;
      let copywriting_list = JSON.parse(
        JSON.stringify(response.data.xhs_copywriting_list)
      ).filter((item) => {
        if (item.content.indexOf(search_text) !== -1) {
          item.content = item.content.replace(
            search_text,
            `<span style='color: #FF5186'>${search_text}</span>`
          );
          return item;
        }
        return null;
      });
      this.setState({
        copywriting_list_history: response.data.xhs_copywriting_list ?? [],
        copywriting_list: copywriting_list ?? [],
        copywriting_list_sum: response.data.total_number,
      });
    } catch (error) {
      message.error("后台发生错误，获取历史记录失败");
    }
  }
  // 获取promot
  onChangeEditor(obj) {
    let { value } = obj;
    this.setState({
      prompt: value,
    });
  }
  // 验证数字
  validateWordNumber(event) {
    event.target.value = validatePureNumber(event.target.value);
    event.target.value = event.target.value.replace(/^0/g, "");
  }
  // 输入字数
  handlePromptNum(event) {
    if (event.target.value > 5) {
      event.target.value = 5;
      message.warning("一次最多生成5条", 1, () => { });
    }
    this.setState({
      prompt_num: event.target.value,
    });
  }
  // 搜索
  onSearch(value, event) {
    if (event.hasOwnProperty("type")) {
      this.setState(
        {
          pagination_current_page: 1,
          checkbox_index: 0,
          copywriting_list_selected: [],
        },
        () => {
          this.getXhsHistory();
        }
      );
    } else {
      this.setState(
        {
          search_text: "",
          pagination_current_page: 1,
          checkbox_index: 0,
          copywriting_list_selected: [],
        },
        () => {
          this.getXhsHistory();
        }
      );
    }
  }
  // 搜索框输入的值
  handleInput(event) {
    const { value } = event.target;
    this.setState({
      search_text: value,
    });
  }
  // 批量删除历史记录
  handleBatchDelete() {
    this.deleteXhsComment();
  }
  // 删除历史记录
  async deleteXhsComment() {
    try {
      const response = await deleteXhsComment({
        xhs_copywriting_id_list: this.state.copywriting_list_selected,
      });
      this.getXhsHistory();
      this.setState({
        checkbox_index: 0
      })
      message.success("删除成功", 0.7, () => { });
    } catch (error) {
      message.error("后台发生错误，删除失败");
    }
  }
  // 全选文案
  handleAllCopyWtriting() {
    let checkbox_index = this.state.checkbox_index;
    let copywriting_list = this.state.copywriting_list;
    if (checkbox_index === 0) {
      checkbox_index = 2; // 全选
      for (let i = 0; i < copywriting_list.length; i++) {
        copywriting_list[i].is_selected = true;
      }
    } else if (checkbox_index === 1) {
      checkbox_index = 2; // 全选
      for (let i = 0; i < copywriting_list.length; i++) {
        copywriting_list[i].is_selected = true;
      }
    } else if (checkbox_index === 2) {
      checkbox_index = 0; // 未选择
      for (let i = 0; i < copywriting_list.length; i++) {
        copywriting_list[i].is_selected = false;
      }
    }

    let copywriting_list_selected = [];
    for (let i = 0; i < copywriting_list.length; i++) {
      if (copywriting_list[i].is_selected) {
        copywriting_list_selected.push(copywriting_list[i].id);
      }
    }
    this.setState({
      checkbox_index: checkbox_index,
      copywriting_list: copywriting_list,
      copywriting_list_selected: copywriting_list_selected
    });
  }
  // 选择题目名称
  handleCopyWtriting(index) {
    let copywriting_list = this.state.copywriting_list;
    copywriting_list[index].is_selected = !copywriting_list[index].is_selected;
    let is_selected_num = 0;
    let checkbox_index = 0;
    let copywriting_list_selected = [];
    for (let i = 0; i < copywriting_list.length; i++) {
      if (copywriting_list[i].is_selected) {
        is_selected_num++;
        copywriting_list_selected.push(copywriting_list[i].id);
      }
    }
    if (is_selected_num === copywriting_list.length) {
      checkbox_index = 2;
    } else if (is_selected_num >= 1) {
      checkbox_index = 1;
    }
    this.setState({
      checkbox_index: checkbox_index,
      copywriting_list: copywriting_list,
      copywriting_list_selected: copywriting_list_selected,
    });
  }
  // 复制文案
  handleCopy(index) {
    // 防止复制进去 搜素关键词的富文本
    let textToCopy = this.state.copywriting_list_history[index].content.replace(
      /<br>/g,
      "\n"
    );
    copyText(textToCopy);
  }
  // 选择页
  onChangePagination(page) {
    this.setState(
      {
        pagination_current_page: page,
        checkbox_index: 0,
        copywriting_list_selected: [],
      },
      () => {
        this.getXhsHistory();
      }
    );
  }
  // 生成文案
  hanldeGenerate = () => {
    if (this.state.product_id === -1 || String(this.state.product_id) === "") {
      message.warning(
        "该品牌没有产品，请先到【我的产品】页中新增产品",
        2,
        () => { }
      );
      return;
    }
    if (!this.state.prompt_num) {
      message.warning(`请至少选填条数`, 2, () => { });
      return;
    }
    this.setState(
      {
        copywriting_list: [],
        copywriting_list_generate: [],
        is_generating: true,
        generate_button_text: "正在生成...",
        is_generating_num: this.state.prompt_num,
        copywriting_list_generate_overloaded: new Array(
          this.state.prompt_num
        ).fill(0),
        is_view_history: false, //init
        checkbox_index: 0,
        copywriting_list_selected: [],
        search_text: "",
      },
      () => {
        for (let i = 0; i < this.state.prompt_num; i++) {
          setTimeout(() => {
            this.generateXhsComment(i);
          }, 1000 * i);
        }
      }
    );
  };
  // AI生成小红书评论
  async generateXhsComment(index) {
    try {
      const response = await generateXhsComment({
        num: Number(this.state.prompt_num),
        product_id: this.state.product_id,
        type: this.state.scene_index,
        prompt: this.state.prompt.trim().replace(/\n/g, ""),
      });
      let copywriting_list_generate = this.state.copywriting_list_generate;
      copywriting_list_generate.push({
        index: index,
        content: response.data.content,
        is_selected: false,
      });
      let is_generating_num = this.state.is_generating_num - 1;
      this.setState({
        copywriting_list_generate: copywriting_list_generate,
        copywriting_list: copywriting_list_generate,
        is_generating_num: is_generating_num,
      });
      if (is_generating_num === 0) {
        // 全部生成完毕你
        this.setState({
          is_generating: false,
          generate_button_text: "再次生成",
        });
      }
      return response;
    } catch (error) {
      if (
        error?.response?.data?.detail &&
        error.response.data.detail.indexOf("没有可用评论") !== -1
      ) {
        message.warning(`${error.response.data.detail}`, 2, () => { });
      } else if (
        error?.response?.data?.detail &&
        error.response.data.detail.indexOf("OpenAI") !== -1
      ) {
        let copywriting_list_generate_overloaded = JSON.parse(
          JSON.stringify(this.state.copywriting_list_generate_overloaded)
        );
        copywriting_list_generate_overloaded[index] =
          copywriting_list_generate_overloaded[index] + 1;
        this.setState({
          copywriting_list_generate_overloaded:
            copywriting_list_generate_overloaded,
        });
        if (
          this.state.prompt_num &&
          copywriting_list_generate_overloaded[index] > 1
        ) {
          message.warning(`当前已负载，请稍后再试"`, 2, () => { });
        } else {
          this.generateXhsComment(index);
          return;
        }
      } else if (error?.message === "isCancel") {
        message.error(`第${index + 1}条文案生成超时，请稍后再试`);
      } else {
        message.error("后台发生错误");
      }
      let is_generating_num = this.state.is_generating_num - 1;
      this.setState({
        is_generating_num: is_generating_num,
      });
      if (is_generating_num === 0) {
        // 全部生成完毕你
        this.setState({
          is_generating: false,
          generate_button_text: "再次生成",
        });
      }
      throw error;
    }
  }
  render() {
    const prefix = (
      <img src={icon_search} alt="" className="evaluation-icon-search" />
    );
    return (
      <div className="ai-copywritng-container">
        <div className="ai-copywritng-left-container">
          <div className="ai-copywritng-scene-wrapper">
            <div className="ai-copywritng-scene-left">
              <img
                src={this.state.scene_list[this.state.scene_index].img}
                alt=""
              />
              <div>{this.state.scene_list[this.state.scene_index].name}</div>
            </div>
            <div
              className="ai-copywritng-scene-right"
              onClick={this.handleScene}
            >
              {`切换场景 >`}
            </div>
          </div>
          <div className="ai-copywritng-condition-wrapper">
            <div className="ai-copywritng-condition-title">文案生成条件</div>
            <div
              className={`ai-copywritng-condition-content ${this.state.is_generating
                ? "ai-copywritng-condition-content-not-allowed"
                : ""
                } `}
            >
              <div className="ai-copywritng-condition-input-wrapper">
                <Input
                  className="word-input"
                  value={this.state.prompt_num}
                  placeholder="请输入字数(1-5)"
                  onChange={this.handlePromptNum.bind(this)}
                  onInput={this.validateWordNumber.bind(this)}
                  maxLength={1}
                />
                <div>条</div>
                <div>（必填）</div>
              </div>
              <EditNode
                value={this.state.prompt}
                onChange={this.onChangeEditor.bind(this)}
                placeholder="小红书文案将根据用户真实体验评价自动生成，或者可以根据您在此填写内容生成哦～"
                className="edit-question-title edit-question-title-copywriting"
              ></EditNode>
            </div>
          </div>
          <div className="ai-copywritng-generation-wrapper">
            <Button
              className="generate-btn"
              onClick={this.hanldeGenerate}
              disabled={this.state.is_generating}
              loading={this.state.is_generating}
            >
              {this.state.generate_button_text}
            </Button>
          </div>
        </div>
        <div className="ai-copywritng-right-container">
          <div className="ai-copywritng-right-operaion">
            <div className="ai-copywritng-right-operaion-list">
              <div className="list-search">
                <Search
                  placeholder="请输入关键字"
                  allowClear
                  enterButton="搜索"
                  prefix={prefix}
                  onSearch={this.onSearch.bind(this)}
                  onInput={this.handleInput.bind(this)}
                  value={this.state.search_text}
                />
              </div>
              {this.state.is_view_history ? (
                <div
                  className="list-delete"
                  onClick={this.handleBatchDelete.bind(this)}
                >
                  <img src={icon_delete} alt="" />
                  <span>删除</span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div
              className="ai-copywritng-right-operaion-view"
              onClick={this.handleSwitchView}
            >
              {this.state.is_view_history ? (
                <div>{`<< 返回`}</div>
              ) : (
                <>
                  <img src={icon_history} alt="" />
                  <div>查看历史记录</div>
                </>
              )}
            </div>
          </div>
          {this.state.is_generating &&
            this.state.copywriting_list.length === 0 ? (
            <img
              src={icon_loading}
              alt=""
              className="ai-copywritng-right-loading"
            />
          ) : (
            ""
          )}
          {this.state.is_view_history ? (
            <div className="ai-copywritng-right-checkbox">
              <div
                className="ai-copywritng-right-checkbox-img"
                onClick={this.handleAllCopyWtriting.bind(this)}
              >
                <img
                  src={this.state.checkbox_list[this.state.checkbox_index].img}
                  alt=""
                />
              </div>
              <div className="ai-copywritng-right-checkbox-name">
                {this.state.checkbox_list[this.state.checkbox_index].name}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="ai-copywritng-right-content">
            {this.state.copywriting_list.map((item, index) => {
              return (
                <div className="copywriting-card" key={index}>
                  {this.state.is_view_history ? (
                    <div
                      className="copywriting-checkbox"
                      onClick={this.handleCopyWtriting.bind(this, index)}
                    >
                      {item.is_selected ? (
                        <img src={cur_icon_rect_sel} alt="" />
                      ) : (
                        <img src={cur_icon_rect_nor} alt="" />
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="copywriting-text-wrapper">
                    <div
                      className={`copywriting-text ${item.is_selected ? "copywriting-text-active" : ""
                        }`}
                      dangerouslySetInnerHTML={{
                        __html: item.content,
                      }}
                    ></div>
                    <div
                      className="copywriting-copy"
                      onClick={this.handleCopy.bind(this, index)}
                    >
                      <img src={icon_copy} alt=""></img>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {this.state.is_view_history ? (
            <div className="ai-copywritng-right-pagination">
              {this.state.copywriting_list.length > 0 ? (
                <>
                  <div className="table-sum-wrapper">
                    共<span>{this.state.copywriting_list_sum}</span>
                    个结果，当前展示1-5行
                  </div>
                  <ConfigProvider locale={zhCN}>
                    <Pagination
                      current={this.state.pagination_current_page}
                      defaultCurrent={1}
                      defaultPageSize={this.state.pagination_interval}
                      total={this.state.copywriting_list_sum}
                      onChange={this.onChangePagination.bind(this)}
                      // showQuickJumper={true}
                      showSizeChanger={false}
                    />
                  </ConfigProvider>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
