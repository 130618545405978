import axiosInstance from "../APIUtils";

// 获取题库树
export function getQuestionBankTree(obj) {
  return axiosInstance({
    url: "/cem/getQuestionBankTree/",
    method: "POST",
    data: obj,
  });
}

// 获取类别题库题目
export function getCategoryQuestionBank(obj) {
  return axiosInstance({
    url: "/cem/getCategoryQuestionBank/",
    method: "POST",
    data: obj,
  });
}

// AI生成问卷 根据prompt生成问卷
export function generateAISurvey(obj) {
  return axiosInstance({
    url: "/data/GenerateAISurvey/",
    method: "POST",
    data: obj,
  });
}

// 获取异步任务执行进度 轮训任务接口
export function getCeleryProgressView(obj) {
  return axiosInstance({
    url: "/cem/GetCeleryProgressView/",
    method: "POST",
    data: obj,
  });
}

// 获取异步列表任务执行进度 轮训任务接口
export function getCeleryProgressListView(obj) {
  return axiosInstance({
    url: "/cem/GetCeleryProgressListView/",
    method: "POST",
    data: obj,
  });
}

// 根据survey_id 获取AI生成的所有问卷
export function getAISurveyList(obj) {
  return axiosInstance({
    url: "/cem/GetAISurveyList/",
    method: "POST",
    data: obj,
  });
}

// 缓存AI问卷任务的队列
export function setAISurveyQueue(obj) {
  return axiosInstance({
    url: "/cem/SetAISurveyQueue/",
    method: "POST",
    data: obj,
  });
}

// 获取AI问卷任务的队列
export function getAISurveyQueue(obj) {
  return axiosInstance({
    url: "/cem/GetAISurveyQueue/",
    method: "POST",
    data: obj,
  });
}
