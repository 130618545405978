import React, { Component } from 'react'
import './picture_info.scss'
import EditNode from '../../../../../EditNode/edit_node';
import XmModel from '../../../../../../components/Xm_model/Xm_model'
import upload_add_img from '../../../../../../images/project_entry/project_info/upload_add_pic.png'
import upload_close_img from '../../../../../../images/upload_close.png'
import { handleUploadImg } from "../../../../../../util/utils"
import { Upload, Image, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';

export default class picture_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'picture_info',
            modelContent: "确定删除该图片吗？",
            showModel: false,
            modelTitle: "删除提示",
            dataList: props.dataList || {},
            answers: props.dataList.answers || [],
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                answers: props.dataList.answers || state.answers,
            }
        }
        return null;
    }
    // 上传图片
    handleUpload(args) {
        let _this = this//烦人的this
        handleUploadImg(args).then(res => {
            if (res !== -1) {
                _this.props.addQuestionPic(res.imgs, res.filename)
            }
        })
    }
    // 编辑按钮选项
    onChangeEditor(option_index, obj) {
        this.props.handleQuestionOptionName(option_index, obj.value)
    }
    // 第一个参数是设置是否显示，第二个参数就是子组件选确认
    handleModel(val, flag) {
        if (flag) {
            // this.handleDeleteUpload()
            this.props.deleteQuestionPic()
        }
        this.setState({
            showModel: val,
        })
    }
    render() {
        return (
            <div className="pic-info-container">
                <div className="pic-info-tip">
                    请上传图片文案示例
                </div>
                <div className="pic-info-wrapper">
                    <div className="pic-info-left">
                        <div className={this.state.dataList.image.val ? "isHide" : "show-upload-wrap"}>
                            <Upload
                                showUploadList={false}
                                customRequest={this.handleUpload.bind(this)}
                            >
                                <div className="upload-pic-wrapper">
                                    <img src={upload_add_img} alt="" className="upload-pic" />
                                    <div className="upload-pic-text">添加图片</div>
                                </div>

                            </Upload>
                        </div>
                        <div className={this.state.dataList.image.val ? "show-pic-wrap" : "isHide"}>
                            <ConfigProvider locale={zhCN}>
                                <Image src={this.state.dataList.image.val} alt="" className="show-pic" width={108} height={108} />
                            </ConfigProvider>
                            <img src={upload_close_img} alt="" className="upload-close-img" onClick={this.handleModel.bind(this, true, false)} />
                        </div>
                    </div>
                    <div className="pic-info-right">
                        <div className="pic-info-btn pic-info-btn-1">
                            <EditNode value={this.state.answers[0].name} onChange={this.onChangeEditor.bind(this, 0)} className="edit-question-title"></EditNode>
                        </div>
                        <div className="pic-info-btn pic-info-btn-2">
                            <EditNode value={this.state.answers[1].name} onChange={this.onChangeEditor.bind(this, 1)} className="edit-question-title"></EditNode>
                        </div>
                    </div>
                </div>
                <div className='delete-image-popup'>
                    <XmModel isShowModel={this.state.showModel} handleShowModel={this.handleModel.bind(this)} modelContent={this.state.modelContent} modelTitle={this.state.modelTitle}></XmModel>
                </div>
            </div>
        )
    }
}
