import { theme_color_list } from './Color_config'
export const stackbar_tooltip = {
    trigger: 'item',
    formatter: function (param) {
        var str = ""
        if (param.componentSubType === 'bar') {
            str = param.name + "：</br>" +
                param.marker + param.seriesName + "</br>" +
                "状态：" + param.seriesName + "</br>" +
                "数量：" + param.value;
        } else {
            //line线
            str = param.marker + "" + param.name + "</br>" +
                "占比：" + param.value + "%</br>";
        }

        return str;
    }
}

export const stackbar_legend = {
    top: 0,
    x: 'center',
    // left: 'left',
    icon: 'circle', //设置图例图表形状
    itemWidth: 10,
    itemHeight: 10,
    itemGap: 30,
    width: '80%',
    type: 'scroll',
    pageIconColor: '#cbc9cf',
    pageIconInactiveColor: '#e5e4e7',
    pageIconSize: 12,
    pageTextStyle: {
        color: '#595961',
    },
    textStyle: {
        fontSize: 14,
        lineHeight: 20,
        color: "#595961",
        fontFamily: "PingFang SC"
    }
}


export function get_stackbar_xAxis(xAxisList) {
    let xAxis = {
        type: 'category',
        data: xAxisList,
        axisTick: { // 去掉刻度条
            show: false
        },
        axisLine: { //x轴的颜色改变
            lineStyle: {
                color: '#E3E2E5'
            }
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#9797A1', //x轴文本字体颜色改变
                fontSize: 12,
                lineHeight: 14
            },
            // rotate: 30
            formatter: function (value, index) {
                let ans = ""
                let maxWordLength = 12  //每个柱子的title最多3个文字
                let barNum = xAxisList.length
                let valueLen = value.length //文字长度

                let num_reg = /[0-9]/g
                let tmp = Number(value[0])
                let hasNum = false
                if (num_reg.test(tmp)) {
                    hasNum = true
                }

                if (barNum >= 7) {
                    maxWordLength = 2
                } else if (barNum >= 6) {
                    maxWordLength = 3
                } else if (barNum >= 5) {
                    if (hasNum) {
                        maxWordLength = 5
                    } else {
                        maxWordLength = 4
                    }
                } else if (barNum >= 4) {
                    maxWordLength = 5
                } else if (barNum >= 3) {
                    maxWordLength = 6
                }
                let rowNum = Math.ceil(valueLen / maxWordLength)
                if (rowNum >= 4) {
                    let len = 3 * maxWordLength
                    value = value.substring(0, len + 1) + "..";
                }
                if (rowNum > 1) {
                    for (let i = 0; i < rowNum; i++) {
                        let tmp = ""
                        let start = i * maxWordLength
                        let end = start + maxWordLength
                        if (i === rowNum - 1) {
                            tmp = value.substring(start, end)
                        } else {
                            tmp = value.substring(start, end) + "\n"
                        }
                        ans += tmp
                    }
                    return ans
                } else {
                    return value
                }
            }
        },
    }
    return xAxis
}

export const stackbar_yAxis = [

    {
        type: 'value',
        // name: '数量',
        splitLine: {  // y轴的分割线
            lineStyle: {
                color: '#E3E2E5',
                type: 'dashed'
            }
        },
        axisLabel: {
            show: true,
            textStyle: {
                color: '#9797A1', //x轴文本字体颜色改变
                fontSize: 12
            }
        }
    },
    {
        type: 'value',
        // name: '种草率',
        min: 0,
        max: 100,
        interval: 20,
        axisLabel: {
            formatter: '{value}%',
            textStyle: {
                color: '#9797A1', //x轴文本字体颜色改变
                fontSize: 12
            }
        }
    }

]


export function getStackbarSeries(series) {
    return series
}

export function getStackbarOption(xAxisList, series) {
    let option = {
        grid: {
            left: '50px',
            right: '45px'
        },
        tooltip: stackbar_tooltip,
        legend: stackbar_legend,
        xAxis: get_stackbar_xAxis(xAxisList),
        yAxis: stackbar_yAxis,
        color: theme_color_list,
        series: getStackbarSeries(series)
    }
    return option
}



