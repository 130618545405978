import axiosInstance from '../APIUtils'

// 获取产品列表
export function getProductList(obj) {
    return axiosInstance({
        url: "/cem/getProductList/",
        method: "POST",
        data: obj
    });
}
// 搜索产品
export function searchProduct(obj) {
    return axiosInstance({
        url: "/cem/searchProduct/",
        method: "POST",
        data: obj
    });
}

// 删除产品
export function deleteProduct(obj) {
    return axiosInstance({
        url: "/cem/deleteProduct/",
        method: "POST",
        data: obj
    });
}

// 获取标签类别
export function getTagCategory(obj) {
    return axiosInstance({
        url: "/cem/getTagCategory/",
        method: "POST",
        data: obj
    });
}