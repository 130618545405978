import React, { Component } from 'react'

export default class Sort_question extends Component {
    constructor(props) {
        super(props)
        this.state = {
            answer: "",
            name: "",
            index: "",
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            answer: props.dataList.answer.value_arr,
            name: props.dataList.name,
            index: props.index + 1,
        };

    }
    render() {
        return (
            <div className="detail-card">
                <div className="detail-question-wrapper">
                    <div className="detail-question-sign">*</div>
                    <div className="detail-question-name">{`${this.state.index}. ${this.state.name}`}</div>
                </div>
                <div className="detail-answer-wrapper">
                    <div className="detail-sort-answer">
                        {this.state.answer.map((item, index) => {
                            return <span key={index}>{`（${index + 1}）${item}`}</span>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}
