import React, { Component } from 'react'
import './question_template.scss';
import icon_search from "../../../../images/product_list/icon_search.png"
import { getQuestionBankTree, getCategoryQuestionBank } from "../../../../api/Questionnaire_setting/question_template_api";
import { getTagCategory } from "../../../../api/Product/Product_list_api";
import { TreeSelect, Input, message } from 'antd';
import QuestionTemplatePanel from '../Question_template_panel/question_template_panel';
import Loading from "../../../Loading/loading";
const { Search } = Input;

export default class question_template extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 0, //加载中 getCategoryQuestionBank接口
            loading_tree: 0, // 保存 sgetTreeData
            loadingText: "数据较大，加载中",
            needRefresh: true,
            treeData: [],// 树选择框
            tree_id: null,
            question_template_list: [],//模版题目
            product_id_for_template: undefined, // 树id
            search_text: "", //搜索框的文字
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.project_entry_number) !== JSON.stringify(state.project_entry_number_prev)) {
            return {
                survey_id: props.survey_id,
                // needRefresh: true,
                project_entry_number: props.project_entry_number || 0, //question
                project_entry_number_prev: props.project_entry_number,
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
    }
    componentDidUpdate(preProps, preState) {
        if (this.props.project_entry_number === 3 && (this.props.product_id_for_template !== this.state.product_id_for_template)) {
            this.setState({
                product_id_for_template: this.props.product_id_for_template
            }, () => {
                this.getCategoryQuestionBank()
                this.getTreeData()
            })
        }
    }
    componentWillUnmount() {
        console.log('Component WILL UNMOUNT!')
        this.setState = () => { }
    }
    // 搜索
    onSearch(value, event) {
        if (event.hasOwnProperty("type")) {
            this.getCategoryQuestionBank()
        } else {
            this.setState({
                search_text: "",
                tree_id: null,
            })
        }
    }
    // 搜索框输入的值
    handleInput(event) {
        const { value } = event.target;
        this.setState({
            search_text: value,
            tree_id: null,
        })
    }
    // 树标签 change
    onTreeSelectChange(value, label, extra) {
        this.setState({
            tree_id: value,
            search_text: ""
        }, () => {
            this.getCategoryQuestionBank()
        })
    }
    // 获取题库
    async getCategoryQuestionBank() {
        this.setState({
            loading: 1
        })
        try {
            const response = await getCategoryQuestionBank({
                tag_category_id: this.state.tree_id ? this.state.tree_id : "",
                // tag_category_id: 21137,
                search_text: this.state.search_text,
            })
            this.setState({
                loading: 0
            })
            if (!response) return
            if (response.data.length === 0) {
                message.warning('暂未有该模版题目', 1, () => { })
            }
            this.setState({
                question_template_list: response.data
            })
        } catch (error) {
            this.setState({
                loading: 0
            })
            message.error('后台发生错误，加载模版题目失败!')
        }
    }
    // 获取树结构哦
    async getTreeData() {
        this.setState({
            loading_tree: 1
        })
        try {
            const response = await getQuestionBankTree({
                product_id: this.props.product_id_for_template
            })
            this.setState({
                loading_tree: 0
            })
            let tag_category = response.data.filter(element => {
                element.title = element.name
                element.value = element.id
                return element
            })
            const treeData = this.formatDataTree(tag_category)
            this.props.handleQuestionBankTree(treeData)
            this.setState({
                treeData: treeData,
            })
            return response;
        } catch (error) {
            this.setState({
                loading_tree: 0
            })
            message.error('后台发生错误，加载失败模版树标签失败')
        }
    }
    // 获取树
    formatDataTree(data) {
        let parents = data.filter(p => p.parent_id === 0)
        let children = data.filter(c => c.parent_id !== 0)
        dataToTree(parents, children)
        return parents
        function dataToTree(parents, children) {
            parents.map(p => {
                children.map((c, i) => {
                    if (c.parent_id === p.id) {
                        let _children = JSON.parse(JSON.stringify(children))
                        _children.splice(i, 1)
                        dataToTree([c], _children)
                        if (p.children) {
                            p.children.push(c)
                        } else {
                            p.children = [c]
                        }
                    }
                    return null
                })
                return null
            })
        }
    }
    addTemplateQuestion(item) {
        this.props.addTemplateQuestion(item)
    }
    render() {
        const prefix = (
            <img src={icon_search} alt="" className='evaluation-icon-search' />
        )
        return (
            <div className="question-template-container">
                {
                    this.state.loading <= 0 && this.state.loading_tree <= 0 ? "" : <Loading loadingText={this.state.loadingText}></Loading>
                }
                <div className="question-template-search">
                    <Search
                        placeholder="搜索题目"
                        allowClear
                        enterButton="搜索"
                        prefix={prefix}
                        onSearch={this.onSearch.bind(this)}
                        onInput={this.handleInput.bind(this)}
                        value={this.state.search_text}
                    />
                </div>
                <div className="question-template-tree-select">
                    <TreeSelect
                        showSearch
                        dropdownClassName="tree-select-popup"
                        treeNodeFilterProp="title"
                        style={{ width: '100%' }}
                        value={this.state.tree_id}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={this.state.treeData}
                        placeholder="请选择产品品类"
                        treeDefaultExpandAll
                        onChange={(value, label, extra) => {
                            this.onTreeSelectChange(value, label, extra)
                        }}
                    ></TreeSelect>
                </div>
                <QuestionTemplatePanel
                    template_name="question-template"
                    question_template_list={this.state.question_template_list}
                    addTemplateQuestion={this.addTemplateQuestion.bind(this)}
                ></QuestionTemplatePanel>
            </div>
        )
    }
}
