import React, { Component } from "react";
import "./Waterfall_evaluation.scss";
import check_1 from "../../../images/emotion_analysis/emotion_level/check_box_1.png";
import check_2 from "../../../images/emotion_analysis/emotion_level/check_box_2.png";
import check_3 from "../../../images/emotion_analysis/emotion_level/check_box_3.png";
import check_4 from "../../../images/emotion_analysis/emotion_level/check_box_4.png";
import TranslationPanel from "../Translation_panel/translation_panel";
import { incentiveStatus } from "./util";

export default class Waterfall_evaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataList: [],
      needRefresh: false,
      wordList: [], //高频词
      search_text: "",
      selectedRowKeys: [], //小红书的勾选框
      pagination_box_current_page: 0,
      product_id_cem: "",
      comment_list: [],
      waterfall_list: [], // 二维数组
      checkStatus: {
        1: check_1,
        2: check_2,
        3: check_3,
        4: check_4,
      },
      list_show_type: 0, //0是box 1是列表
    };
  }
  componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      // this.clearPanel()
      // this.initPanel()
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    this.setState = () => {};
  }
  componentDidUpdate() {
    if (this.props.tab_key !== "1") {
      // 不动
    } else if (
      (this.props.comment_list &&
        this.props.comment_list.length !== this.state.comment_list.length) ||
      String(this.props.product_id_cem) !== String(this.state.product_id_cem)
    ) {
      this.init();
    } else if (this.props.list_show_type !== this.state.list_show_type) {
      this.setState({
        list_show_type: this.props.list_show_type,
      });
      if (this.props.list_show_type === 0) {
        this.init();
      }
    } else if (
      this.props.list_show_type === 0 &&
      this.props.comment_list &&
      this.props.comment_list.length >= 0 &&
      !this.isObjectEqual(this.props.comment_list, this.state.comment_list)
    ) {
      this.init();
    }
  }
  // 比较对象是否相等
  isObjectEqual(a, b) {
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    if (aProps.length != bProps.length) {
      return false;
    }
    for (var i = 0; i < aProps.length; i++) {
      var propName = aProps[i];

      var propA = a[propName];
      var propB = b[propName];
      // 这里忽略了值为undefined的情况
      // 故先判断两边都有相同键名
      if (!b.hasOwnProperty(propName)) return false;
      if (propA instanceof Object) {
        if (this.isObjectEqual(propA, propB)) {
          // return true     这里不能return ,后面的对象还没判断
        } else {
          return false;
        }
      } else if (propA !== propB) {
        return false;
      } else {
      }
    }
    return true;
  }
  init() {
    this.setState(
      {
        comment_list: this.props.comment_list,
        wordList: this.props.wordList,
        search_text: this.props.search_text,
        product_id_cem: this.props.product_id_cem,
        waterfall_list: [],
      },
      () => {
        // this.clearPanel()
        this.initPanel(0);
      }
    );
  }
  // 审核状态
  getIncentiveStatus(item) {
    let ans = incentiveStatus[item.status];
    if (item.status === 3) {
      ans = item.sort + "次" + ans;
    }
    return ans;
  }
  //获取小红书激励记录列表
  hanldleIncentiveList(item) {
    let comment_id = item.id;
    this.props.getXhsIncentiveList(comment_id);
  }
  // 点击勾选
  hanldleCheck(item) {
    this.props.hanldleCheck(item);
  }
  // 点击了查看详情
  handleBox(item) {
    this.props.handleBox(item);
  }
  // 选择第几个box
  chooseBoxNum() {
    let box = document.querySelectorAll(".box");

    if (!box.length) return 0;
    if (box.length < 4) return box.length;

    let boxMinHeight = box[0].offsetHeight;
    let box_num = 0;
    for (let i = 1; i < box.length; i++) {
      if (box[i].offsetHeight < boxMinHeight) {
        boxMinHeight = box[i].offsetHeight;
        box_num = i;
      }
    }
    return box_num;
  }
  //情感分析大面板
  initPanel(index) {
    if (index >= this.state.comment_list.length) {
      return;
    }
    let item = this.state.comment_list[index];
    // let waterfall_list = this.state.waterfall_list;
    let waterfall_list = JSON.parse(JSON.stringify(this.state.waterfall_list));
    let box_num = this.chooseBoxNum();
    if (waterfall_list[box_num]) {
      waterfall_list[box_num].push(item);
    } else {
      waterfall_list[box_num] = [item];
    }

    this.setState(
      {
        waterfall_list: waterfall_list,
      },
      () => {
        // setTimeout(() => {
        this.initPanel(index + 1);
        // });
      }
    );
  }
  // 清楚面板
  clearPanel() {
    for (let i = 0; i < 4; i++) {
      let boxTmp = document.querySelector(`.box-${i}`);
      let childs = boxTmp.childNodes;
      for (let j = childs.length - 1; j >= 0; j--) {
        boxTmp.removeChild(childs[j]);
      }
    }
  }
  // 评论飙红
  handleComment(content) {
    this.state.wordList.forEach((item, index) => {
      if (item.checked) {
        content = content.replace(
          item.val,
          `<span style='color: #FF5186'>${item.val}</span>`
        );
      }
    });
    if (this.state.search_text !== "") {
      content = content.replace(
        this.state.search_text,
        `<span style='color: #FF5186'>${this.state.search_text}</span>`
      );
    }
    let html = { __html: content };
    return (
      <div dangerouslySetInnerHTML={html} className="card-content-wrap"></div>
    );
  }
  // 选择下拉框语言
  onLanguageChange(is_global, comment_index, translate_obj) {
    // translate_obj = { ...translate_obj, is_global: is_global, current_index: comment_index }
    let other_obj = { is_global: is_global, current_index: comment_index };
    this.props.onLanguageChange(other_obj, translate_obj);
  }
  // 操作翻译图标 单个
  handleTranslateLocal(comment_index) {
    this.props.handleTranslateLocal(comment_index);
  }
  render() {
    return (
      <div>
        <div className="waterfall-container">
          {this.state.waterfall_list.map((list, list_index) => {
            return (
              <div className={`box box-${list_index}`} key={list_index}>
                {list.map((item, item_index) => {
                  return (
                    <div className="card" key={item_index}>
                      <div className="card-top-wrapper">
                        {item.check === 1 || item.check === 2 ? (
                          <div
                            className={`card-top-left card-top-left-${item.check}`}
                          >
                            <img
                              src={this.state.checkStatus[item.check]}
                              alt=""
                              onClick={this.hanldleCheck.bind(this, item)}
                            />
                          </div>
                        ) : (
                          <div
                            className={`card-top-left card-top-left-disabled card-top-left-${item.check}`}
                          >
                            <img
                              src={this.state.checkStatus[item.check]}
                              alt=""
                              onClick={this.hanldleCheck.bind(this, item)}
                            />
                          </div>
                        )}
                        <div
                          className="card-top-right"
                          onClick={this.hanldleIncentiveList.bind(this, item)}
                        >
                          查看
                        </div>
                      </div>
                      <div className="card-title-wrap">
                        <div className="card-title-left">
                          {item.comment_image ? (
                            <img src={item.comment_image} alt="" />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="card-title-right">
                          <div className="card-username">{item.username}</div>
                          <div className="card-intro">
                            <div className="card-intro-item">
                              {item.address}
                            </div>
                            <div className="card-intro-item">
                              {item.occupation}
                            </div>
                            <div className="card-intro-item">{item.gender}</div>
                            <div className="card-intro-item">{item.age}</div>
                          </div>
                        </div>
                      </div>
                      <div className="card-translation-wrap">
                        <TranslationPanel
                          language_list={this.props.language_list}
                          language_id={item.language_id}
                          translate_status={item.translate_status}
                          retranslate={item.retranslate}
                          translate={item.translate}
                          handleTranslate={this.handleTranslateLocal.bind(
                            this,
                            item.comment_index
                          )}
                          onLanguageChange={this.onLanguageChange.bind(
                            this,
                            false,
                            item.comment_index
                          )}
                        ></TranslationPanel>
                      </div>
                      {this.handleComment.call(this, item.content)}
                      <div className="card-bottom-wrapper">
                        <div className="card-bottom-left">
                          <div className="card-time-wrap">
                            {item.create_time || ""}
                          </div>
                          <div
                            className="card-detail-wrap"
                            onClick={this.handleBox.bind(this, item)}
                          >
                            {`评论详情>`}
                          </div>
                        </div>
                        <div className="card-bottom-right">
                          {this.getIncentiveStatus.call(this, item)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
