import axiosInstance from "./APIUtils";

// 获取词云图
export function getWordcloud(obj) {
  return axiosInstance({
    url: "/cem/wordCloud/",
    method: "POST",
    data: obj,
  });
}
// 获取NLP的评论table
export function getComment(obj) {
  return axiosInstance({
    url: "/cem/getComment/",
    method: "POST",
    data: obj,
  });
}

// 获取NLP的评论table的评论详情
export function getCommentDetail(obj) {
  return axiosInstance({
    url: "/cem/getCommentDetail/",
    method: "POST",
    data: obj,
  });
}

// 总体数据分析
export function getOverallData(obj) {
  return axiosInstance({
    url: "/cem/overallData/",
    method: "POST",
    data: obj,
    isRequestPending: true,
  });
}

//总体数据种草率分析
export function getOverallDataLikeRate(obj) {
  return axiosInstance({
    url: "/cem/overallData/likeRate/",
    method: "POST",
    data: obj,
    isRequestPending: true,
  });
}

// 预设评论分析_简单
export function getPresetCommentSimple(obj) {
  return axiosInstance({
    url: "/cem/getPresetComment/simple/",
    method: "POST",
    data: obj,
    isRequestPending: true,
  });
}

// 获取自主评论选择
export function getCommentSelection(obj) {
  return axiosInstance({
    url: "/cem/getCommentSelection/",
    method: "POST",
    data: obj,
  });
}

// 获取品牌所有调研问卷
export function getBrandSurvey(obj) {
  return axiosInstance({
    url: "/cem/getBrandSurvey/",
    method: "POST",
    data: obj,
  });
}

// 获取品牌所有调研问卷
export function getSurveyAnalysisSimple(obj) {
  return axiosInstance({
    url: "/cem/getSurveyAnalysis/simple/",
    method: "POST",
    data: obj,
    isRequestPending: true,
  });
}

//下载pdf 下载总体数据分析 深度分析报告
export function exportSurvey(obj) {
  return axiosInstance({
    url: "/cem/exportSurvey/",
    method: "POST",
    data: obj,
    responseType: "blob",
  });
}

//下载pdf 下载总体数据分析 深度分析报告
export function exportProductReport(obj) {
  return axiosInstance({
    url: "/cem/exportProductReport/",
    method: "POST",
    data: obj,
    responseType: "blob",
  });
}

// 获取品牌所有调研问卷
export function getRegionList(obj) {
  return axiosInstance({
    url: "/cem/getRegionList/",
    method: "POST",
    data: obj,
  });
}

// 获取本期许愿问题展示
export function getWishQuestionList(obj) {
  return axiosInstance({
    url: "/cem/getWishQuestionList/",
    method: "POST",
    data: obj,
  });
}

// 消费者研究心愿分析_简单
export function getWishAnalysisSimple(obj) {
  return axiosInstance({
    url: "/cem/getWishAnalysis/simple/",
    method: "POST",
    data: obj,
  });
}

// 调研问卷信息
export function getSurveyInfo(obj) {
  return axiosInstance({
    url: "/cem/getSurveyInfo/",
    method: "POST",
    data: obj,
    isRequestPending: true,
  });
}

// 获取题目词云分析
export function geWordCloudQuestion(obj) {
  return axiosInstance({
    url: "/cem/wordCloudQuestion/",
    method: "POST",
    data: obj,
    isRequestPending: true,
  });
}

// AI生成小红书评论
export function generateXhsComment(obj) {
  return axiosInstance({
    url: "/cem/generateXhsComment/",
    method: "POST",
    data: obj,
    timeout: 360000, //6分钟
  });
}

// AI获取小红书历史记录
export function getXhsHistory(obj) {
  return axiosInstance({
    url: "/cem/getXhsHistory/",
    method: "POST",
    data: obj,
  });
}

// AI删除小红书历史记录
export function deleteXhsComment(obj) {
  return axiosInstance({
    url: "/cem/deleteXhsComment/",
    method: "POST",
    data: obj,
  });
}

// 获取图表相关评论
export function getRelatedCommentList(obj) {
  return axiosInstance({
    url: "/cem/getRelatedCommentList/",
    method: "POST",
    data: obj,
    isRequestPending: true,
  });
}

// 获取调研问卷自由交叉分析
export function getSpeicalEventCrossAnalysisInfo(obj) {
  return axiosInstance({
    url: "/cem/getSpeicalEventCrossAnalysisInfo/",
    method: "POST",
    data: obj,
  });
}

// 获取调研问卷自由交叉分析表格
export function getSpecialEventCrossAnalysisTable(obj) {
  return axiosInstance({
    url: "/cem/getSpecialEventCrossAnalysisTable/",
    method: "POST",
    data: obj,
  });
}

// 获取调研问卷交叉分析单个变量图表
export function getSpecialEventSingleQuestionChart(obj) {
  return axiosInstance({
    url: "/cem/getSpecialEventSingleQuestionChart/",
    method: "POST",
    data: obj,
  });
}

// 获取产品问卷自由交叉分析
export function getProductCrossAnalysisInfo(obj) {
  return axiosInstance({
    url: "/cem/getProductCrossAnalysisInfo/",
    method: "POST",
    data: obj,
  });
}

// 获取产品问卷自由交叉分析表格
export function getProductCrossAnalysisTable(obj) {
  return axiosInstance({
    url: "/cem/getProductCrossAnalysisTable/",
    method: "POST",
    data: obj,
  });
}

// 获取产品问卷交叉分析单个变量图表
export function getProductSingleQuestionChart(obj) {
  return axiosInstance({
    url: "/cem/getProductSingleQuestionChart/",
    method: "POST",
    data: obj,
  });
}

// 批量发起小红书激励接口
export function saveXhsIncentive(obj) {
  return axiosInstance({
    url: "/cem/SaveXhsIncentive/",
    method: "POST",
    data: obj,
  });
}

// 获取小红书激励记录列表
export function getXhsIncentiveList(obj) {
  return axiosInstance({
    url: "/cem/GetXhsIncentiveList/",
    method: "POST",
    data: obj,
  });
}

// 审核小红书激励
export function updateXhsIncentive(obj) {
  return axiosInstance({
    url: "/cem/UpdateXhsIncentive/",
    method: "POST",
    data: obj,
  });
}

// gpt翻译评论语言列表
export function translateContentLanguage(obj) {
  return axiosInstance({
    url: "/cem/translateContentLanguage/",
    method: "POST",
    data: obj,
  });
}

// gpt翻译评论语言列表
export function translateContent(obj) {
  return axiosInstance({
    url: "/cem/translateContent/",
    method: "POST",
    data: obj,
    isRequestPending: true,
    compareRequestParams: true,
  });
}
