import React, { Component } from 'react'
import './delivery_popup.scss';
import cur_icon_close_gre from '../../../../images/cur_icon_close_gre.png'
import Toast from "../../../../components/Toast/Toast";

export default class delivery_popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toastStatus: false,
            start: '',
            end: '',
            logistic_number: "",
            logistic_sender: "",
            logistic_phone: "",
            logistic_number_new: "",
            logistic_sender_new: "",
            logistic_phone_new: "",
            msg: '请输入',
        }

    }
    setDeliveryList(obj) {
        let { logistic_id, logistic_number, logistic_sender, logistic_phone } = obj
        this.setState({
            logistic_id: logistic_id,
            logistic_number: logistic_number,
            logistic_sender: logistic_sender,
            logistic_phone: logistic_phone,
            logistic_number_new: logistic_number,
            logistic_sender_new: logistic_sender,
            logistic_phone_new: logistic_phone
        })
    }
    tags_close() {
        this.setState({
            logistic_number_new: "",
            logistic_sender_new: "",
            logistic_phone_new: "",
        })
        this.props.get_delivery_popup_status({
            logistic_number: this.state.logistic_number,
            logistic_sender: this.state.logistic_sender,
            logistic_phone: this.state.logistic_phone,
            logistic_id: this.state.logistic_id,
            delivery_popup_status: false
        })
    }
    tags_confirm() {
        if (this.validate()) {
            this.setState({
                logistic_number_new: '',
                logistic_sender_new: '',
                logistic_phone_new: ''
            })
            this.props.get_delivery_popup_status({
                logistic_number: this.state.logistic_number_new,
                logistic_sender: this.state.logistic_sender_new,
                logistic_phone: this.state.logistic_phone_new,
                logistic_id: this.state.logistic_id,
                delivery_popup_status: false
            })
        } else {
            if (!this.state.toastStatus) {
                this.setState({
                    toastStatus: true
                })
                setTimeout(() => {
                    this.setState({
                        toastStatus: false
                    })
                }, 2000)
            }
        }
    }
    handle_change(string, event) {
        this.setState({
            [string]: event.target.value
        })
    }

    // 只能输入数字的字符
    validateNumber(event) {
        event.target.value = event.target.value.replace(/^(0+)|[^\d]+/g, '')
    }
    validate() {
        let flag = true
        let msg = ""
        let phone_reg = /^[1][3,4,5,7,8][0-9]{9}$/
        if (this.state.logistic_number_new === "") {
            flag = false
            msg = "请输入发货单号"
        } else if (this.state.logistic_sender_new === "") {
            flag = false
            msg = "请输入发货人"
        } else if (this.state.logistic_phone_new === "") {
            flag = false
            msg = "请输入发货电话"
        } else if (!phone_reg.test(this.state.logistic_phone_new)) {
            flag = false;
            msg = "请填写正确的发货电话"
        }
        this.setState({
            msg: msg
        })
        return flag
    }
    render() {
        return (
            <div className='age_area_popup'>
                <div className='age_area_popup_contain'>
                    <div className='age-area-popup-top'>
                        <div className='popup-top-title'>年龄区间</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                        </div>
                    </div>
                    <div className='age_area_popup_input'>
                        <span>发货单号：</span>
                        <input type='text' placeholder='请输入发货单号' onChange={this.handle_change.bind(this, 'logistic_number_new')} value={this.state.logistic_number_new} />
                    </div>
                    <div className='age_area_popup_input'>
                        <span>发货人：</span>
                        <input type='text' placeholder='请输入发货人' onChange={this.handle_change.bind(this, 'logistic_sender_new')} value={this.state.logistic_sender_new} />
                    </div>
                    <div className='age_area_popup_input'>
                        <span>发货电话：</span>
                        <input type='text' placeholder='请输入发货电话' onChange={this.handle_change.bind(this, 'logistic_phone_new')} onInput={this.validateNumber.bind(this)} maxLength="11" value={this.state.logistic_phone_new} />
                    </div>
                    <div className='tag-btn'>
                        <div onClick={this.tags_confirm.bind(this)}>确认</div>
                        <div onClick={this.tags_close.bind(this)}>取消</div>
                    </div>
                </div>
                <Toast toastStatus={this.state.toastStatus} msg={this.state.msg}></Toast>
            </div>
        )
    }
}
