import React, { Component } from 'react'
import { connect } from 'react-redux'
import './Word_cloud_panel.scss'
import { addListener } from "resize-detector";
import * as echarts from 'echarts';
import { message } from 'antd';
import cur_icon_radio_sel from "../../../images/emotion_analysis/cur_icon_radio_sel.png"
import cur_icon_radio_nor from "../../../images/emotion_analysis/cur_icon_radio_nor.png"
import WordCloud from "./Word_cloud";
import download_icon from '../../../images/overall_data/download_icon.png'
import { downloadPic } from '../../../util/utils'

class Word_cloud_panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // product_id: localStorage.getItem('product_id') ? localStorage.getItem('product_id') : 11,    //产品ID
            // product_brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : "23",   //品牌ID
            // keywords: [],
            dataList: [],
            // isProductChange: false,
            _isMounted: false,
            dataList_prev: [],
            needRefresh: false,
            option_checked: [{ checked: true }, { checked: false }, { checked: false }],
            current_wordcloud_list: [],
            current_index: 0,
            image_url: "https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fc-ssl.duitang.com%2Fuploads%2Fitem%2F202003%2F25%2F20200325161434_XPRRd.thumb.1000_0.jpeg&refer=http%3A%2F%2Fc-ssl.duitang.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=auto?sec=1653375368&t=d794b43d8f52345f908665c86d6b4f33"
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList,
                current_wordcloud_list: props.dataList.length ? props.dataList[0] : []
            };
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            // this.initChart();
            this.setState({
                needRefresh: false
            })
        }
        return true
    }
    componentWillUnMount = () => {
        this._isMounted = false;
        this.setState = () => { };

    }
    // 生成指定的词云
    handleWordCloud(item, index) {
        let option_checked_tmp = JSON.parse(JSON.stringify(this.state.option_checked))
        if (option_checked_tmp[index].checked) {
            return
        }
        option_checked_tmp.forEach((item2, index2) => {
            if (index === index2) {
                item2.checked = !item2.checked
            } else {
                item2.checked = false
            }
        })
        this.setState({
            option_checked: option_checked_tmp,
            current_wordcloud_list: item,
            current_index: index
        })
    }
    //选择词云
    choose_wordcloud_level(item, index) {
        return <div className="level-option-box" onClick={this.handleWordCloud.bind(this, item, index)} key={index}>
            <div className="level-option-box-image">
                <img src={cur_icon_radio_sel} alt="" className={this.state.option_checked[index].checked ? "" : "isHide"} />
                <img src={cur_icon_radio_nor} alt="" className={this.state.option_checked[index].checked ? "isHide" : ""} />
            </div>
            <div className="level-option-box-title">
                {item.title}
            </div>
        </div>
    }
    //下载词云图
    downloadQrcode() {
        if (this.props.user.account_type === "1") { //基础共创
            message.warning(`基础共创不可下载词云图，请联系商务进行升级`, 3, () => { })
            return
        }
        let current_index = this.state.current_index
        let wordcloud_type_text = "好评词云"
        if (current_index === 1) {
            wordcloud_type_text = "中评词云"
        } else if (current_index === 2) {
            wordcloud_type_text = "差评词云"
        }
        let brand_text = localStorage.getItem("brand_text_cem")
        let product_text = localStorage.getItem("product_text_cem")
        let word_name = brand_text + "_" + product_text + "_" + wordcloud_type_text
        if (this.state.current_wordcloud_list.image) {
            downloadPic(this.state.current_wordcloud_list.image, word_name)
        } else {
            message.warning(`数据暂时不足，无法生成词云图 )`, 2, () => { })
        }
    }
    render() {
        return (
            <div className="word-cloud-panel">
                <div className="word-cloud-operaion">
                    <div className="word-cloud-level">
                        <div className="word-cloud-level-name" >词云等级</div>
                        <div className="word-cloud-level-option">
                            {
                                this.state.dataList.map((item, index) => {
                                    return this.choose_wordcloud_level.call(this, item, index)
                                })
                            }
                        </div>
                    </div>
                    <div className="download-wrapper" onClick={this.downloadQrcode.bind(this)}>
                        <img src={download_icon} alt="" />
                        下载词云图
                    </div>
                </div>
                {
                    this.state.current_wordcloud_list.image ?
                        <div className="word-cloud-contain">
                            <img src={this.state.current_wordcloud_list.image} alt={this.state.current_wordcloud_list.title} className={`word-cloud-img ${this.props.user.account_type === "1" ? "word-cloud-img-vague" : ""}`} />
                        </div> :
                        <div className="empty-contain">
                            ( 数据暂时不足，无法生成词云，请稍后再试～ )
                        </div>
                }

            </div>
        )
    }
}
export default connect(
    state => ({
        user: state.user,
    }),//映射状态
    {}//映射操作状态的方法
)(Word_cloud_panel)
