import React, { Component } from 'react'
import "./matrix_single_template_info.scss"
import cur_icon_circle_sel from "../../../../../../images/emotion_analysis/cur_icon_circle_sel.png"
import cur_icon_circle_nor from "../../../../../../images/emotion_analysis/cur_icon_circle_nor.png"
import cur_icon_rect_sel from "../../../../../../images/emotion_analysis/cur_icon_rect_sel.png"
import cur_icon_rect_nor from "../../../../../../images/emotion_analysis/cur_icon_rect_nor.png"

export default class matrix_single_template_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "matrix_single_template_info",
            dataList: {},
            answers: [],
            row_answers: [],
            column_answers: [],
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                answers: props.dataList.answers || state.answers,
                row_answers: props.dataList.answers.filter(item => item.option_position === "left"),
                column_answers: props.dataList.answers.filter(item => item.option_position === "up"),
            }
        }
        return null;
    }
    componentDidMount() {
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
        this.setState = () => { }
    }
    render() {
        let column_items = [];
        for (let i = 0; i < this.state.column_answers.length; i++) {
            if (this.state.dataList.question_type === 16) {
                column_items.push(<div className="grade-item-2" key={i}>
                </div>);
            } else {//矩阵单选多选
                if (i === 0) {
                    column_items.push(<div className="grade-item" key={i}>
                        <img src={(this.state.dataList.question_type === 8) || (this.state.dataList.question_type === 22)? cur_icon_circle_sel : cur_icon_rect_sel} alt="" />
                    </div>);
                } else {
                    column_items.push(<div className="grade-item" key={i}>
                        <img src={(this.state.dataList.question_type === 8) || (this.state.dataList.question_type === 22)? cur_icon_circle_nor : cur_icon_rect_nor} alt="" />
                    </div>);
                }
            }
        }
        return (
            <div className='matrix-single-template-info'>
                <div className="matrix-score-column-answer">
                    <div className="matrix-score-column-answer-left">
                        {
                            this.state.column_answers.map((item, index) => {
                                return (
                                    <div className='draw-slip-row' key={index}>
                                        <div className="draw-slip-left draw-slip-left-border">
                                            <div className='draw-slip-title'>
                                                <div className="edit-question-title-wrap">
                                                    <div className="edit-question-title" dangerouslySetInnerHTML={{ __html: item.name }}></div>
                                                </div>
                                                <div className={`${item.image.val ? "upload-question-pic" : "isHide"}`}>
                                                    <img src={item.image.val} alt="" />
                                                </div>
                                            </div>
                                        </div >
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className="matrix-score-row-answer">
                    {
                        this.state.row_answers.map((item, index) => {
                            return (
                                <div className="draw-slip" key={index}>
                                    <div className="draw-slip-left draw-slip-left-border">
                                        <div className='draw-slip-title'>
                                            <div className="edit-question-title-wrap">
                                                <div className='edit-question-title'>{item.name}</div>
                                            </div>
                                            <div className={`${item.image.val ? "upload-question-pic" : "isHide"}`}>
                                                <img src={item.image.val} alt="" />
                                            </div>
                                        </div>
                                        <div className="draw-slip-index">
                                            {
                                                column_items
                                            }
                                        </div>
                                    </div >
                                </div>
                            )
                        })
                    }
                </div >
            </div>
        )
    }
}
