
import React, { Component } from 'react'
import './Age_area_popup.scss';
import cur_icon_close_gre from '../../../../images/cur_icon_close_gre.png'
import Toast from "../../../Toast/Toast";

class Age_area_popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toastStatus: false,
            start: '',
            end: '',
            age_area_popup_status: false,
            start_age_error: false,
            end_age_error: false,
            age_area_popup_flag: 0,
            msg: '请输入正确的年龄区间'
        }

    }
    setAgeList(obj) {
        let { start, end } = obj
        this.setState({
            start: start,
            end: end,
        })
    }

    tags_close() {
        this.setState({
            start: '',
            end: '',
            age_area_popup_status: false
        })
        this.props.get_age_area_popup_status({ start: '', end: '', age_area_popup_status: false })
    }
    tags_confirm() {
        if (this.validateAge()) {
            this.props.get_age_area_popup_status({
                start: this.state.start, end: this.state.end, age_area_popup_status: false
            })
            this.setState({
                start: '',
                end: '',
            })
        } else {
            if (!this.state.toastStatus) {
                this.setState({
                    toastStatus: true
                })
                setTimeout(() => {
                    this.setState({
                        toastStatus: false
                    })
                }, 2000)
            }
        }
    }

    handle_change(string, event) {
        if (string === 'start') {
            this.setState({
                start: event.target.value
            })
        } else if (string === 'end') {
            this.setState({
                end: event.target.value
            })
        }
    }

    validateAge() {
        let flag = true
        let msg = ""
        if (!this.state.start || !this.state.end) {
            flag = false
        }
        if (parseInt(this.state.start) > parseInt(this.state.end)) {
            flag = false
            msg = "请输入正确的年龄区间"
        }
        else if (parseInt(this.state.start) < 16) {
            flag = false
            msg = "起始年龄不可小于16"
        }
        else if (parseInt(this.state.end) > 90) {
            flag = false
            msg = "结束年龄不可大于90"
        }
        this.setState({
            msg: msg
        })
        return flag
    }
    // 只能输入数字的字符
    validateNumber(event) {
        event.target.value = event.target.value.replace(/^(0+)|[^\d]+/g, '')
    }

    render() {
        return (
            <div className='age_area_popup'>
                <div className='age_area_popup_contain'>
                    <div className='age-area-popup-top'>
                        <div className='popup-top-title'>年龄区间</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                        </div>
                    </div>
                    <div className='age_area_popup_input'>
                        <span>起始年龄：</span>
                        <input type='text' placeholder='请输入起始年龄' onChange={this.handle_change.bind(this, 'start')} value={this.state.start} onInput={this.validateNumber.bind(this)} maxLength="3" />
                        <span className={this.state.start_age_error ? 'isError' : 'isHide'}>年龄错误</span>
                    </div>
                    <div className='age_area_popup_input'>
                        <span>结束年龄：</span>
                        <input type='text' placeholder='请输入结束年龄' onChange={this.handle_change.bind(this, 'end')} value={this.state.end} onInput={this.validateNumber.bind(this)} maxLength="3" />
                        <span className={this.state.end_age_error ? 'isError' : 'isHide'}>年龄错误</span>
                    </div>
                    <div className='tag-btn'>
                        <div onClick={this.tags_confirm.bind(this)}>确认</div>
                        <div onClick={this.tags_close.bind(this)}>取消</div>
                    </div>
                </div>
                <Toast toastStatus={this.state.toastStatus} msg={this.state.msg}></Toast>
            </div>
        )
    }
}



export default Age_area_popup;