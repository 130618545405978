import React, { Component } from 'react'
import { message } from 'antd';
import './question_type_popup.scss';
import cur_icon_close_gre from "../../../../images/cur_icon_close_gre.png";
import type_1 from "../../../../images/project_entry/question_type/type_1.png";
import type_1_active from "../../../../images/project_entry/question_type/type_1_active.png";
import type_2 from "../../../../images/project_entry/question_type/type_2.png";
import type_2_active from "../../../../images/project_entry/question_type/type_2_active.png";
import type_3 from "../../../../images/project_entry/question_type/type_3.png";
import type_3_active from "../../../../images/project_entry/question_type/type_3_active.png";
import type_4 from "../../../../images/project_entry/question_type/type_4.png";
import type_4_active from "../../../../images/project_entry/question_type/type_4_active.png";
import type_5 from "../../../../images/project_entry/question_type/type_5.png";
import type_5_active from "../../../../images/project_entry/question_type/type_5_active.png";
import type_6 from "../../../../images/project_entry/question_type/type_6.png";
import type_6_active from "../../../../images/project_entry/question_type/type_6_active.png";
import type_7 from "../../../../images/project_entry/question_type/type_7.png";
import type_7_active from "../../../../images/project_entry/question_type/type_7_active.png";
import type_8 from "../../../../images/project_entry/question_type/type_8.png";
import type_8_active from "../../../../images/project_entry/question_type/type_8_active.png";
import type_9 from "../../../../images/project_entry/question_type/type_9.png";
import type_9_active from "../../../../images/project_entry/question_type/type_9_active.png";
import type_10 from "../../../../images/project_entry/question_type/type_10.png";
import type_10_active from "../../../../images/project_entry/question_type/type_10_active.png";
import type_14 from "../../../../images/project_entry/question_type/type_14.png";
import type_14_active from "../../../../images/project_entry/question_type/type_14_active.png";
import type_15 from "../../../../images/project_entry/question_type/type_15.png";
import type_15_active from "../../../../images/project_entry/question_type/type_15_active.png";
import type_16 from "../../../../images/project_entry/question_type/type_16.png";
import type_16_active from "../../../../images/project_entry/question_type/type_16_active.png";
import type_18 from "../../../../images/project_entry/question_type/type_18.png";
import type_18_active from "../../../../images/project_entry/question_type/type_18_active.png";
import { getQuestionType, getQuestionTypeName } from "../Question_rules/question_type_rule"

export default class question_type_popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_question_type: "",
            questionTypeList: [
                {
                    question_type: 1,// 单选题
                    question_type_val: "single",
                    is_selected: false,
                    icon: type_1,
                    icon_active: type_1_active
                },
                {

                    question_type: 2,// 多选题
                    question_type_val: "multiple",
                    is_selected: false,
                    icon: type_2,
                    icon_active: type_2_active
                },
                {
                    question_type: 3,// 简单题-填空题
                    question_type_val: "blank",
                    is_selected: false,
                    icon: type_3,
                    icon_active: type_3_active
                },
                {
                    question_type: 4,// 排序题
                    question_type_val: "sort",
                    is_selected: false,
                    icon: type_4,
                    icon_active: type_4_active
                },
                {
                    question_type: 5,// 打分题
                    question_type_val: "score",
                    is_selected: false,
                    icon: type_5,
                    icon_active: type_5_active
                },
                {
                    question_type: 6,// 比重题,
                    question_type_val: "proportion",
                    is_selected: false,
                    icon: type_6,
                    icon_active: type_6_active
                },
                {
                    question_type: 7,// 滑条题
                    question_type_val: "nps",
                    is_selected: false,
                    icon: type_7,
                    icon_active: type_7_active
                },
                {
                    question_type: 8, //矩阵单选
                    question_type_val: "matrix_single",
                    is_selected: false,
                    icon: type_8,
                    icon_active: type_8_active
                },
                {
                    question_type: 9,//矩阵多选
                    question_type_val: "matrix_multiple",
                    is_selected: false,
                    icon: type_9,
                    icon_active: type_9_active
                },
                {
                    question_type: 10,//图片题
                    question_type_val: "picture",
                    is_selected: false,
                    icon: type_10,
                    icon_active: type_10_active
                },
                {
                    question_type: 11,//NPS题 
                    question_type_val: "score",
                    is_selected: false,
                    icon: type_5,
                    icon_active: type_5_active
                },
                {
                    question_type: 12,// 种草
                    question_type_val: "single",
                    is_selected: false,
                    icon: type_1,
                    icon_active: type_1_active
                },
                {
                    question_type: 13, // 购买意愿
                    question_type_val: "single",
                    is_selected: false,
                    icon: type_1,
                    icon_active: type_1_active
                },
                {
                    question_type: 14,//矩阵打分题
                    question_type_val: "matrix_score",
                    is_selected: false,
                    icon: type_14,
                    icon_active: type_14_active
                },
                {
                    question_type: 15,// 矩阵滑条题
                    question_type_val: "matrix_nps",
                    is_selected: false,
                    icon: type_15,
                    icon_active: type_15_active
                },
                {
                    question_type: 16,// 矩阵填空题
                    question_type_val: "matrix_blank",
                    is_selected: false,
                    icon: type_16,
                    icon_active: type_16_active
                },
                {
                    question_type: 18,// 例举题-枚举题
                    question_type_val: "enumeration",
                    is_selected: false,
                    icon: type_18,
                    icon_active: type_18_active
                },
                {
                    question_type: 19,// max_diff题
                    question_type_val: "max_diff",
                    is_selected: false,
                    icon: type_6,
                    icon_active: type_6_active
                },
                {
                    question_type: 20,// 热力图题
                    question_type_val: "heatmap",
                    is_selected: false,
                    icon: type_1,
                    icon_active: type_1_active
                },
                {
                    question_type: 22, // Price Sensitivity Meter 价格敏感度
                    question_type_val: "matrix_psm",
                    is_selected: false,
                    icon: type_8,
                    icon_active: type_8_active
                }
            ]
        }

    }
    selectQuestionType = (index) => {
        let questionTypeList = JSON.parse(JSON.stringify(this.state.questionTypeList))
        for (let i = 0; i < questionTypeList.length; i++) {
            if (i === index) {
                questionTypeList[i].is_selected = true
            } else {
                questionTypeList[i].is_selected = false
            }
        }
        this.setState({
            questionTypeList: questionTypeList,
            selected_question_type: questionTypeList[index].question_type
        })
    }
    tags_confirm() {
        if (!this.state.selected_question_type) {
            message.warning(`请选择要添加的题目类型`, 1, () => { })
            return
        }
        if (!this.props.canAddSelectedQuestion(this.state.selected_question_type)) {
            return
        }
        this.props.canShowQuestionTypePopup({
            showQuestionTypePopup: false,
            add_question_type: this.state.selected_question_type
        })
    }
    tags_close() {
        this.props.canShowQuestionTypePopup({
            showQuestionTypePopup: false,
            add_question_type: ""
        })
    }
    render() {
        return (
            <div className='question-type-popup-container'>
                <div className='question-type-popup-bg'>
                    <div className='question-type-popup-main'>
                        <div className='question-type-popup-top'>
                            <div className='popup-top-title'>题型选择</div>
                            <div className='popup-delete-icon'>
                                <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                            </div>
                        </div>
                        <div className='question-type-popup-content'>
                            <div className='question-item-container'>
                                {
                                    this.state.questionTypeList.map((item, index) => {
                                        return (
                                            <div key={index} className={`question-item ${item.is_selected ? 'question-item-selected' : ""}`} onClick={this.selectQuestionType.bind(this, index)}>
                                                {
                                                    item.is_selected ?
                                                        <img src={item.icon_active} alt="" />
                                                        :
                                                        <img src={item.icon} alt="" />

                                                }
                                                <div className="question-item-text">{getQuestionTypeName(getQuestionType(item.question_type))}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>

                        <div className='tag-btn'>
                            <div onClick={this.tags_confirm.bind(this)}>确认</div>
                            <div onClick={this.tags_close.bind(this)}>取消</div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}
