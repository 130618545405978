import React, { Component } from 'react'
import { connect } from 'react-redux'
import './consumer_research.scss';
import wish_question_icon from '../../images/consumer_research/wish_question_icon.png'
import FilterSelect from '../Overall_data/Filter_chart_select/filter_select'
import RingChart from '../Home_page/Ring_chart/ring_chart'
import Loading from "../../components/Loading/loading"
import SingleChart from "../Preinstall_comment/CommentSingleChart"
import CustomChart from "../Preinstall_comment/CommentCustomChart"
import { getQueryParameter, saveImgZip, calScore, calP, calB, calPreference } from '../../util/utils'
import XmSelect from '../../components/Select/xm_select';
import { getWishList, getWishStats } from '../../api/Home_page/home_page_api'
import { getWishQuestionList, getWishAnalysisSimple, getRegionList } from '../../api/panelApi'

import { Tooltip, message, Empty, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import html2canvas from "html2canvas";
import { question_list } from './data';

class consumer_research extends Component {
    constructor(props) {
        super(props);
        this.filterRef = React.createRef()
        this.handleScroll = this.handleScroll.bind(this)
        this.state = {
            loading: 0,
            loading_chart: 0,
            loadingText: "数据较大，加载中",
            ring_data_list: {
                data: [],
                sum: 0
            },
            //new
            wish_id: "", //现在的
            future_wish_id: -1,
            wish_list: [],//心愿列表
            swiper_cur_wish: [], //本期的心愿
            question_list: [],// 题目列表
            wish_list_formal: [],
            region_list: [],
            selected_region_list: [],
            selected_gender_list: [],
            selected_age_list: [],
            start_time: "",
            end_time: "",
            preinstall_single_list: [],
            batch_base_url: [],//批量图片
            filter_start_date: "",//筛选时间框的开始时间
            filter_end_date: "",//筛选时间框的结束时间
        }
    }
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            window.addEventListener("scroll", this.handleScroll, true);
            this.init()
        }
    }
    //在componentWillUnmount，进行scroll事件的注销
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        this._isMounted = false;
        this.setState = (state, callback) => { }
    }
    init() {
        this.getWishList()
    }
    // 获取所有心愿的列表 取倒数两个
    async getWishList() {
        this.setState({
            loading: 1
        })
        try {
            const response = await getWishList({})
            if (response.data.wish_list.length === 0) {
                this.setState({
                    loading: 0
                })
                message.warning('暂无数据', 2, () => { })
                return
            }
            let wish_list = []
            let wish_list_formal = JSON.parse(JSON.stringify(response.data.wish_list))
            let future_wish = response.data.future_wish

            this.setState({
                wish_list_formal: wish_list_formal,
                future_wish_id: future_wish,
                loading: 0
            })
            for (let i = 0; i < response.data.wish_list.length; i++) {
                let obj = { key: response.data.wish_list[i].id, value: response.data.wish_list[i].name }
                wish_list.push(obj)
            }
            if (window.location.search.indexOf("wish_id") !== -1) {
                // 从首页跳转过来
                this.initWish(wish_list)
            } else if (parseInt(localStorage.getItem("wish_id_cem"))) {
                // 刷新 但已存在
                let wish_list_prev = JSON.parse(localStorage.getItem("wish_list_cem"))
                let isObjectValueEqual = this.isObjectValueEqual(wish_list, wish_list_prev)
                if (isObjectValueEqual) {
                    this.initPanel()
                } else { ///如超户的wish列表进行了更新
                    this.initWish(wish_list)
                }
            } else { // 之前没有wish_id
                this.initWish(wish_list)
            }
        } catch (error) {
            this.setState({ loading: 0 }, () => { message.error('后台发生错误') })
            throw error;
        }
    }
    // 判断两个对象是否相等
    isObjectValueEqual(obj1, obj2) {
        if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
            return true
        }
        return false
    }
    // 获取许愿内容 图片
    getSwiperCurWish(id) {
        for (let i = 0; i < this.state.wish_list_formal.length; i++) {
            if (parseInt(this.state.wish_list_formal[i].id) === parseInt(id)) {
                return this.state.wish_list_formal[i]
            }
        }
        return {}
    }
    // local storage 有
    initPanel() {
        let wish_id = localStorage.getItem("wish_id_cem")
        let wish_list = localStorage.getItem("wish_list_cem") !== "" ? JSON.parse(localStorage.getItem("wish_list_cem")) : []
        this.setState({
            wish_id: wish_id ? parseInt(wish_id) : "",
            wish_list: wish_list,
            swiper_cur_wish: this.getSwiperCurWish(wish_id),
        }, () => {
            this.getRegionList()
        })
    }
    //初始化wish
    initWish(wish_list) {
        let wish_id = wish_list[wish_list.length - 1].key
        if (window.location.search.indexOf("wish_id") !== -1) {
            // 从首页跳转过来
            wish_id = getQueryParameter("wish_id");
        }
        localStorage.setItem('wish_id_cem', parseInt(wish_id))
        localStorage.setItem('wish_list_cem', JSON.stringify(wish_list))
        this.setState({
            wish_id: parseInt(wish_id),
            swiper_cur_wish: this.getSwiperCurWish(wish_id),
            wish_list: wish_list
        }, () => {
            this.getRegionList()
        })
    }
    // 获取心愿图表
    async getWishStats() {
        try {
            const response = await getWishStats({
                wish_id: this.state.wish_id,
                future_wish: this.state.future_wish_id,
            })
            // if (!response) return
            let ring_data_list = {
                sum: response.data.user_count,
                data: [
                    { name: "女性", value: response.data.female_count, percent: response.data.female_percent },
                    { name: "男性", value: response.data.male_count, percent: response.data.male_percent }]
            }
            this.setState({
                ring_data_list: ring_data_list,
                filter_start_date: response.data.start_time,
                filter_end_date: response.data.end_time,
                loading: this.state.loading - 1
            }, () => {
                this.initChart()
            })
        } catch (error) {
            this.initChart()
            this.setState({
                loading: this.state.loading - 1
            }, () => {
                message.error('后台发生错误')
            }
            )
            throw error;
        }
    }
    // 获取本期许愿问题展示
    async getWishQuestionList() {
        try {
            const response = await getWishQuestionList({
                wish_id: this.state.wish_id,
            })
            if (parseInt(response.status) === 204) {
                this.setState({
                    question_list: [],
                    loading: this.state.loading - 1
                })
                message.warning('暂无本期许愿问题展示数据', 2, () => { })
            } else {
                let question_list = response.data.question_list
                this.setState({
                    question_list: question_list,
                    loading: this.state.loading - 1
                })
            }
        } catch (error) {
            this.setState({
                loading: this.state.loading - 1
            }, () => {
                message.error('后台发生错误')
            }
            )
            throw error;
        }
    }
    //获取地域列表
    async getRegionList() {
        let wish_id = parseInt(this.state.wish_id)
        this.setState({
            loading: 3
        })
        try {
            const response = await getRegionList({
                product_id: "",
                survey_type: 1,
                survey_id: "",
                wish_id: wish_id
            })
            let region_list = []
            for (let i = 0; i < response.data.length; i++) {
                let obj = { key: i, value: response.data[i] }
                region_list.push(obj)
            }

            this.setState(
                {
                    region_list: region_list,
                    loading: this.state.loading - 1
                }, () => {
                    this.getWishStats()
                    this.getWishQuestionList()
                })
            return response;
        } catch (error) {
            this.setState({
                loading: 0
            }, () => {
                message.error('后台发生错误')
            }
            )
            throw error;
        }
    }
    async getWishAnalysisSimple() {
        let wish_id = parseInt(this.state.wish_id)
        this.setState({
            // preinstall_single_list: [],
            loading_chart: 1,
        }, () => {
        })

        try {
            const response = await getWishAnalysisSimple({
                wish_id: wish_id,
                age_list: this.state.selected_age_list,
                region_list: this.state.selected_region_list,
                gender_list: this.state.selected_gender_list,
                start_time: this.state.start_time,
                end_time: this.state.end_time
            })
            if (!response) return
            if (parseInt(response.status) === 204) {
                this.setState({
                    preinstall_single_list: [],
                    loading_chart: this.state.loading_chart - 1
                })
                message.warning('暂无图表数据', 2, () => { })
            } else {
                let preinstall_single_list = JSON.parse(JSON.stringify(response.data))
                preinstall_single_list.forEach((item, index) => {
                    item["type"] = item.question_type
                    if (item.question_type === 1 || item.question_type === 12 || item.question_type === 13 || item.question_type === 6) {
                        for (let i = 0; i < item.data.length; i++) {
                            item.data[i].name = item.data[i].name || "未知"
                        }
                    } else if (item.question_type === 15 || item.question_type === 14) {  //矩阵滑条题
                        // item.data_list = item.data_list //列表
                        // item.chart_list = item.chart_list // 图表
                    } else if (item.question_type === 16) {  //开放题-矩阵填空题
                        // item.name = item.name
                        // item.option_list = item.option_list
                    } else if (item.question_type === 3 || item.question_type === 18 || item.question_type === 10) {  //简答题
                        // item.name = item.name
                        // item.data = item.data
                    } else if (item.question_type === 2) {//多选题
                        item["xAxisList"] = item.x_axis
                        item.option_id_list = item.option_id
                    } else if (item.question_type === 4 || item.question_type === 8 || item.question_type === 9) { //排序题
                        // item.name = item.chart_name
                    } else if (item.question_type === 5 || item.question_type === 7) { // 滑条和打分
                        // item.name = item.name
                        // item.data = item.data
                        // item.x_axis = item.x_axis
                    } else if (item.question_type === 11) { //自定义
                        // item.name = item.name
                        // item.data = item.data
                        // item.word_arr = item.word_arr
                        // item.word_bar_count_arr = item.word_bar_count_arr
                        // item.x_axis = item.x_axis
                    } else if (item.question_type === 19) {
                        item.data_list = []
                        let tmp_formal = JSON.parse(JSON.stringify(item.option_list))
                        let data_list = []
                        for (let i = 0; i < tmp_formal.length; i++) {
                            data_list.push({
                                option_name: tmp_formal[i].option_name,
                                preference: 0,
                                option_max_selected_count: tmp_formal[i].option_max_selected_count,
                                option_min_selected_count: tmp_formal[i].option_min_selected_count,
                                option_occurance: tmp_formal[i].option_occurance,
                                score: calScore(tmp_formal[i].option_max_selected_count, tmp_formal[i].option_min_selected_count, tmp_formal[i].option_occurance),
                                p: calP(tmp_formal[i].option_max_selected_count, tmp_formal[i].option_min_selected_count, tmp_formal[i].option_occurance),
                                b: calB(tmp_formal[i].option_max_selected_count, tmp_formal[i].option_min_selected_count, tmp_formal[i].option_occurance),
                            })
                        }
                        for (let i = 0; i < data_list.length; i++) {
                            data_list[i]["preference"] = calPreference(i, data_list) || 0
                        }
                        item.data_list = data_list
                        item["option_img"] = []
                        item["option_id"] = []
                    }
                })
                this.setState({
                    preinstall_single_list: preinstall_single_list,
                    loading_chart: this.state.loading_chart - 1
                })
            }
            return response;
        } catch (error) {
            this.setState({
                preinstall_single_list: [],
                loading_chart: 0
            }, () => {
                message.error('后台发生错误')
            }
            )
            throw error;
        }
    }
    // 监听滚动
    handleScroll() {
        let card_chart = document.querySelector(".filter-chart-container");
        let card_fixed = document.querySelector(".filter-container-fixed");
        let card_content = document.querySelector(".chart-container");
        let side_bar_left = document.querySelector(".sidebar-left-container");
        let side_bar_top = document.querySelector(".sidebar-top-container");
        let brand = document.querySelector(".consumer-top");
        let brand_fixed = document.querySelector(".product-dropdown-container");

        if (!brand) return
        if (!card_fixed) return

        let card_fixed_self_height = card_fixed.offsetHeight //自身高度 
        let side_bar_self_width = side_bar_left.offsetWidth + 36// 加上左右的padding
        let side_bar_self_height = side_bar_top.offsetHeight

        //品牌的固定位置
        let brand_rect = brand.getBoundingClientRect();
        if (brand_rect.top <= side_bar_self_height - 55) { // 到可视区窗口的头
            brand_fixed.style.cssText = `position: fixed;top:10px;`
        } else {
            brand_fixed.style.cssText = "position:unset;"
        }
        //筛选框的固定位置
        let card_chart_rect = card_chart.getBoundingClientRect();
        if (card_chart_rect.top <= side_bar_self_height) { // 到可视区窗口的头
            // card_fixed.style.cssText = "position: fixed;top: 0px;"
            card_fixed.style.cssText = `position: fixed;top:${side_bar_self_height}px;width:calc(100% - ${side_bar_self_width}px);box-shadow: 0px 2px 20px 8px #f8f9fd;min-width:1036px;`
            card_content.style.cssText = `position: relative;top:${card_fixed_self_height}px;margin-bottom: ${card_fixed_self_height}px;background: #fff;`
        } else {
            card_fixed.style.cssText = "position: relative;top: 0px;"
            card_content.style.cssText = "position: relative;top:0px;"
        }
    }
    // 删选初始化图表
    initChart() {
        // 防止接口回来满，但是setFilterList这个组件已经unmonted
        if (!this.filterRef.current) return;
        this.filterRef.current.setFilterList({
            region_list: this.state.region_list,
            filter_start_date: this.state.filter_start_date,
            filter_end_date: this.state.filter_end_date,
            is_init_filter: true
        })
        this.getWishAnalysisSimple()
    }
    //清空数据
    clearData() {
        this.setState({
            question_list: [],
            ring_data_list: {
                data: [],
                sum: 0
            },
        })
    }
    // 点击选择wish
    onSelectWishChange(key, option) {
        if (window.location.search.indexOf("wish_id") !== -1) {
            // 从首页跳转过来
            var url = window.location.href;
            if (url.indexOf("?") !== -1) {
                url = url.replace(/(\?|#)[^'"]*/, ''); //去掉http地址参数
                window.history.pushState({}, 0, url);
            }
        }
        let cur_index = parseInt(option.key) // 位置
        if (parseInt(key) !== parseInt(this.state.wish_id)) {
            this.clearData()
        }
        let wish_list = JSON.parse(JSON.stringify(this.state.wish_list_formal))
        let wish_id = wish_list[cur_index].id
        let swiper_cur_wish = wish_list[cur_index]
        localStorage.setItem('wish_id_cem', wish_id)
        this.setState({
            wish_id: wish_id,
            swiper_cur_wish: swiper_cur_wish
        }, () => {
            this.getRegionList()
        })
    };
    // 处理日期
    getDateNoZero(date) {
        let arr = date.split('.');
        let str1 = Number(arr[0])
        let str2 = Number(arr[1])
        return str1 + "." + str2
    }
    // 下拉框搜索可以大小写
    filterSelectOption(input, option) {
        return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    //选择简单唯独图表
    chooseSingleChart(item, index) {
        if (item.type === 11) {
            return <CustomChart key={index} dataList={item} single_id={index}></CustomChart>
        }
        return <SingleChart
            isConsumer={true} key={index}
            dataList={item}
            single_id={index}
            survey_type={1}
            wish_id={this.state.wish_id}
            selected_region_list={this.state.selected_region_list}
            selected_gender_list={this.state.selected_gender_list}
            selected_age_list={this.state.selected_age_list}
            start_time={this.state.start_time}
            end_time={this.state.end_time}
            isRightToList={false}
        ></SingleChart>
    }
    // 搜索的filter
    searchFilter(filter_obj) {
        if (this.props.user.account_type === "1") { //基础共创
            message.warning(`基础共创不支持筛选搜索功能，请联系商务进行升级`, 3, () => { })
            return
        }
        let { selected_region_list, selected_gender_list, selected_age_list, start_time, end_time } = filter_obj
        this.setState({
            selected_region_list: selected_region_list,
            selected_gender_list: selected_gender_list,
            selected_age_list: selected_age_list,
            start_time: start_time,
            end_time: end_time,
        }, () => {
            this.getWishAnalysisSimple()
        })
    }
    // 批量下载的导出图片
    exportImg(dom, name, scrollY = 0) {
        let that = this
        dom.style.backgroundColor = "#fff";
        let height = dom.offsetHeight;
        let width = dom.offsetWidth;
        html2canvas(dom, {
            backgroundColor: "#fff",
            height: height + 10,
            width: width,
            scrollY,
            useCORS: true
        }).then(function (image) {
            if (image.msToBlob) {
                navigator.msSaveBlob(image.msToBlob(), `${name || Date.now()}.png`);
            } else {
                let url = image.toDataURL("image/png", 1.0);

                let batch_base_url = JSON.parse(JSON.stringify(that.state.batch_base_url))
                let obj = {
                    name: name,
                    base64_img: url
                }
                batch_base_url.push(obj)
                that.setState({
                    batch_base_url: batch_base_url
                })
            }
        });
    }
    // 批量下载
    batchDownload() {
        message.warning(`数据较大，下载中`, 2, () => { })
        for (let i = 0; i < this.state.preinstall_single_list.length; i++) {
            let id = "chart_" + i + "_wrapper"
            let dom = document.getElementById(id)
            this.exportImg(
                document.getElementById(id),
                this.state.preinstall_single_list[i].name
            );
        }
        setTimeout(() => {
            saveImgZip(this.state.batch_base_url)
        }, 2000)
    }
    //去除富文本
    removeRichtext(richtext) {
        return richtext.replace(/<[^<>]+>/g, " ").replace(/&nbsp;/gi, ""); //这里是去除标签
    }
    render() {
        return (
            <div className="container consumer-container">
                {
                    this.state.loading <= 0 && this.state.loading_chart <= 0 ? "" : <Loading loadingText={this.state.loadingText}></Loading>
                }
                <div className="consumer-top">
                    <div className="product-dropdown-container">
                        <XmSelect
                            dropdownClassName="consumer-dropdown"
                            showSearch
                            // filterOption={(input, option) => {
                            //     return this.filterSelectOption.call(this, input, option)
                            // }

                            // }
                            defaultValue={this.state.wish_id} key={this.state.wish_id}
                            options={this.state.wish_list}
                            onChange={(e, index) => {
                                this.onSelectWishChange(e, index)
                            }}></XmSelect>
                    </div>
                </div>
                {/* 本期心愿活动 */}
                <div className="wish-activity-container">
                    <div className="swiper-card">
                        <div className="swiper-bg">
                            <img src={this.state.swiper_cur_wish.wish_image} alt="" />
                        </div>
                        <div className="swiper-content-wrapper">
                            <div className="swiper-title">本期心愿活动</div>
                            <div className="swiper-title">{this.state.swiper_cur_wish.name}</div>
                            <div className="swiper-content">
                                {this.state.swiper_cur_wish.content}
                            </div>
                            <div className="swiper-btn">
                                →
                            </div>
                        </div>
                    </div>
                    <div className="chart-card">
                        <div className="chart-title">本期心愿盒许愿人数</div>

                        <div className="wish-chart-container">
                            <RingChart single_id="ring_chart" dataList={this.state.ring_data_list} ></RingChart>
                        </div>
                    </div>
                </div>
                {/* 问题展示 */}
                <div className="wish-question-row">
                    <div className="wish-question-title-wrapper">
                        本期许愿问题展示
                    </div>
                    <div className="wish-question-content-wrapper">
                        {
                            this.state.question_list.map((item, index) => {
                                return (
                                    <div className="wish-question-card" key={index}>
                                        <div className="wish-question-icon">
                                            <img src={wish_question_icon} alt="" />
                                        </div>
                                        <div className="wish-question-wrapper">
                                            <Tooltip placement="bottom" title={this.removeRichtext.bind(this, item.question_name)}>
                                                <div dangerouslySetInnerHTML={{ __html: item.question_name }} className="wish-question">
                                                </div>
                                            </Tooltip>
                                            <div className="wish-question-line">

                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
                    {
                        this.state.question_list.length === 0 ?
                            <div className="rank-content-empty">
                                <ConfigProvider locale={zhCN}>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </ConfigProvider>
                            </div>
                            :
                            ""
                    }
                </div>
                {/* 固定筛选图表 */}
                <div className="filter-chart-container">
                    <div className="filter-container-fixed">
                        <FilterSelect ref={this.filterRef} searchFilter={this.searchFilter.bind(this)}></FilterSelect>
                    </div>
                    {/* <div className="chart-container"> */}
                    <div className={`chart-container ${this.props.user.account_type === "1" ? "chart-container-vague" : ""}`}>
                        {
                            this.state.preinstall_single_list.length === 0 ?
                                ""
                                :
                                <div className="batch-download-btn-wrapper">
                                    <div className="batch-download-btn" onClick={this.batchDownload.bind(this)}>批量下载</div>
                                </div>
                        }
                        {
                            this.state.preinstall_single_list.map((item, index) => {
                                return this.chooseSingleChart(item, index)
                            })
                        }
                    </div>
                    {
                        this.state.preinstall_single_list.length === 0 ?
                            <div className="rank-content-empty">
                                <ConfigProvider locale={zhCN}>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </ConfigProvider>
                            </div>
                            :
                            ""
                    }
                </div>
            </div>
        )
    }
}
export default connect(
    state => ({
        user: state.user,
    }),//映射状态
    {}//映射操作状态的方法
)(consumer_research)
