import React, { Component } from 'react'
import './Xm_model.scss';
import { Modal } from 'antd';
export default class Xm_model extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            content: "",
            title: "",
        }
    }
    handleOk() {
        this.props.handleShowModel(false, true)
    }
    handleCancel() {
        this.props.handleShowModel(false, false)
    }
    componentWillReceiveProps(obj) {
        this.setState({
            isModalVisible: obj.isShowModel,
            content: obj.modelContent,
            title: obj.modelTitle
        })
    }
    render() {
        // const { isShowModel } = this.props;
        return (

            <Modal title={this.state.title} okText="确认" cancelText="取消" visible={this.state.isModalVisible} onOk={this.handleOk.bind(this)} onCancel={this.handleCancel.bind(this)} wrapClassName={'xm-modal'} maskClosable={false} >
                <p>{this.state.content}</p>
            </Modal>

        )
    }
}
