import React, { Component } from "react";
import { connect } from "react-redux";
import { editUser, getUser, editUserItem } from "../../redux/actions/user";
import { setSurveyPolling } from "../../redux/actions/survey";
import {
  getSurveyQueueFromBackend,
  setSurveyQueueToBackEnd,
} from "../../redux/actions/survey";
import "./side_bar.scss";
import icon_home from "../../images/side_bar/product_entry.png";
import logo from "../../images/side_bar/logo.png";
import logo_text from "../../images/side_bar/logo_text.png";
import icon_workbench from "../../images/side_bar/workbench.png";
import header_handle from "../../images/side_bar/header_handle.png";
import header_handle_min from "../../images/side_bar/header_handle_min.png";
import tab_img_avatar from "../../images/side_bar/tab_img_avatar.png";
import { Link } from "react-router-dom";
import dropdown_img_0 from "../../images/side_bar/dropdown_img_0.png";
import dropdown_img_active_0 from "../../images/side_bar/dropdown_img_active_0.png";
import dropdown_img_1 from "../../images/side_bar/dropdown_img_1.png";
import dropdown_img_active_1 from "../../images/side_bar/dropdown_img_active_1.png";
import dropdown_img_2 from "../../images/side_bar/dropdown_img_2.png";
import dropdown_img_active_2 from "../../images/side_bar/dropdown_img_active_2.png";
import dropdown_img_3 from "../../images/side_bar/dropdown_img_3.png";
import dropdown_img_active_3 from "../../images/side_bar/dropdown_img_active_3.png";
import dropdown_img_4 from "../../images/side_bar/dropdown_img_4.png";
import dropdown_img_active_4 from "../../images/side_bar/dropdown_img_active_4.png";
import card_img_1_1 from "../../images/side_bar/card_img_1_1.png";
import card_img_active_1_1 from "../../images/side_bar/card_img_active_1_1.png";
import card_img_1_2 from "../../images/side_bar/card_img_1_2.png";
import card_img_active_1_2 from "../../images/side_bar/card_img_active_1_2.png";
import card_img_1_3 from "../../images/side_bar/card_img_1_3.png";
import card_img_active_1_3 from "../../images/side_bar/card_img_active_1_3.png";
import card_img_2_1 from "../../images/side_bar/card_img_2_1.png";
import card_img_active_2_1 from "../../images/side_bar/card_img_active_2_1.png";
import card_img_2_2 from "../../images/side_bar/card_img_2_2.png";
import card_img_active_2_2 from "../../images/side_bar/card_img_active_2_2.png";
import card_img_3_1 from "../../images/side_bar/card_img_3_1.png";
import card_img_active_3_1 from "../../images/side_bar/card_img_active_3_1.png";
import card_img_3_2 from "../../images/side_bar/card_img_3_2.png";
import card_img_active_3_2 from "../../images/side_bar/card_img_active_3_2.png";
import card_img_3_3 from "../../images/side_bar/card_img_3_3.png";
import card_img_active_3_3 from "../../images/side_bar/card_img_active_3_3.png";
import card_img_4_1 from "../../images/side_bar/card_img_4_1.png";
import card_img_active_4_1 from "../../images/side_bar/card_img_active_4_1.png";
import user_company from "../../images/side_bar/user_company.png";
import user_money from "../../images/side_bar/user_money.png";
import user_quota from "../../images/side_bar/user_quota.png";
import user_type from "../../images/side_bar/user_type.png";
import user_recharge from "../../images/side_bar/user_recharge.png";
import contrast_icon_active from '../../images/side_bar/contrast_icon_active.png'
import contrast_icon from '../../images/side_bar/contrast_icon.png'
import UserPopup from "./User_popup/user_popup";
import { account_type_obj } from "../../pages/User_info/Values/user_data";
import { NavLink } from "react-router-dom";

class side_bar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_user_popup: false, //是否显示用户弹窗
      is_sidebar_min: false,
      isShowSideBar: false, //控制侧边栏是否显示
      isShowLeftSideBar: false, // 是否显示左边侧边栏
      account_type_obj: account_type_obj,
      route_list: [
        {
          name: "首页",
          path: "/home_page", //首页
          dropdown_img: dropdown_img_0,
          dropdown_img_active: dropdown_img_active_0,
          dropdown_name: "首页",
          selected: false,
        },
        {
          name: "工作台",
          path: "/workbench", //工作台
          dropdown_img: dropdown_img_1,
          dropdown_img_active: dropdown_img_active_1,
          dropdown_name: "工作台",
          selected: false,
          children: [
            {
              name: "我的产品",
              path: "/product_list",
              card_img: card_img_1_1,
              card_img_active: card_img_active_1_1,
              selected: false,
            },
            {
              name: "派送计划",
              path: "/dispatch_list",
              card_img: card_img_1_2,
              card_img_active: card_img_active_1_2,
              selected: false,
            },
            {
              name: "调研计划",
              path: "/survey_list",
              card_img: card_img_1_3,
              card_img_active: card_img_active_1_3,
              selected: false,
            },
          ],
        },
        {
          name: "市场洞察",
          path: "/insight",
          dropdown_img: dropdown_img_3,
          dropdown_img_active: dropdown_img_active_3,
          dropdown_name: "市场洞察",
          selected: false,
          children: [
            {
              name: "调研数据分析",
              path: "/brand_survey",
              children: [],
              card_img: card_img_3_1,
              card_img_active: card_img_active_3_1,
              selected: false,
            },
            {
              name: "自由交叉分析",
              path: "/survey_freecross",
              children: [],
              card_img: card_img_3_2,
              card_img_active: card_img_active_3_2,
              selected: false,
            },
            {
              name: "心盒AI分析",
              path: "/ai_analysis_survey",
              children: [],
              card_img: card_img_3_3,
              card_img_active: card_img_active_3_3,
              selected: false,
            },
            // {
            //   name: "sankey",
            //   path: "/survey_sankey",
            //   children: [],
            //   card_img: card_img_3_2,
            //   card_img_active: card_img_active_3_2,
            //   selected: false,
            // },
            {
              name: "多问卷对比分析",
              path: "/contrast_survey",
              children: [],
              card_img: contrast_icon,
              card_img_active: contrast_icon_active,
              selected: false,
            },
          ],
        },
        {
          name: "产品评测",
          path: "/analysis", //产品评测
          dropdown_img: dropdown_img_2,
          dropdown_img_active: dropdown_img_active_2,
          dropdown_name: "产品评测",
          selected: false,
          children: [
            {
              name: "总体数据分析",
              path: "/overall_data",
              children: [],
              card_img: card_img_2_1,
              card_img_active: card_img_active_2_1,
              selected: false,
            },
            {
              name: "评测评论分析",
              path: "/emotion_analysis",
              children: [],
              card_img: card_img_2_2,
              card_img_active: card_img_active_2_2,
              selected: false,
            },
            {
              name: "自由交叉分析",
              path: "/product_freecross",
              children: [],
              card_img: card_img_3_2,
              card_img_active: card_img_active_3_2,
              selected: false,
            },
            {
              name: "心盒AI分析",
              path: "/ai_analysis_product",
              children: [],
              card_img: card_img_3_3,
              card_img_active: card_img_active_3_3,
              selected: false,
            },
            {
              name: "多问卷对比分析",
              path: "/contrast_product",
              children: [],
              card_img: contrast_icon,
              card_img_active: contrast_icon_active,
              selected: false,
            },
          ],
        },
        {
          name: "其他热门数据",
          path: "/hot", //产品评测
          dropdown_img: dropdown_img_4,
          dropdown_img_active: dropdown_img_active_4,
          dropdown_name: "消费者研究",
          selected: false,
          children: [
            {
              name: "消费者研究",
              path: "/consumer_research", //总体数据分析
              children: [],
              card_img: card_img_4_1,
              card_img_active: card_img_active_4_1,
              selected: false,
            },
          ],
        },
      ],
      survey_queue: [],
    };
  }
  componentDidMount() {
    this._isMounted = true;
    let isShowSideBar = false; // 顶部
    let isShowLeftSideBar = false; // 侧边
    let pathname_arr = [
      "/login",
      "/",
      "/user_agreement",
      "/privacy_agreement",
      "/error_page",
    ];
    if (!pathname_arr.includes(window.location.pathname)) {
      if (!this.props.user.phone) {
        this.props.getUser();
        this.props.getSurveyQueueFromBackend();
      }
    }
    this.interval = setInterval(() => {
      if (pathname_arr.includes(window.location.pathname)) {
        isShowSideBar = false;
        isShowLeftSideBar = false;
      } else if (window.location.pathname === "/user_info") {
        isShowSideBar = true;
        isShowLeftSideBar = false;
      } else {
        isShowSideBar = true;
        isShowLeftSideBar = true;
      }
      this.setState(
        {
          isShowSideBar: isShowSideBar,
          isShowLeftSideBar: isShowLeftSideBar,
        },
        () => {
          if (this.state.isShowSideBar && this.state.isShowLeftSideBar) {
            this.initPanel();
            // clearInterval(this.interval) //为了防止浏览器回退
          }
        }
      );
    }, 300);

    // 测试suvey
    // while (this.props.survey_queue.length) {
    //     // console.log("side_bar")
    //     let queue_item = this.props.survey_queue[0]
    //     this.props.setSurveyPolling(queue_item)
    // }
    // let queue_item = { id: 1 }
    // this.props.setSurveyPolling(queue_item)
  }
  componentDidUpdate(preProps, preState) {
    if (this.props.survey_queue?.length !== this.state.survey_queue.length) {
      this.setState(
        {
          survey_queue: this.props.survey_queue,
        },
        () => {
          this.props.setSurveyQueueToBackEnd(this.props.survey_queue);
        }
      );
    } else if (
      this.props.survey_queue?.length &&
      this.state.survey_queue?.length &&
      !this.ifCompare(this.props.survey_queue[0], this.state.survey_queue[0])
    ) {
      this.setState(
        {
          survey_queue: this.props.survey_queue,
        },
        () => {
          this.props.setSurveyQueueToBackEnd(this.props.survey_queue);
        }
      );
    }

    if (
      this.props.survey_queue?.length &&
      (this.state.survey_queue.length === 0 ||
        !this.ifCompare(this.props.survey_queue[0], this.state.survey_queue[0]))
    ) {
      let queue_item = this.props.survey_queue[0];
      this.props.setSurveyPolling(queue_item);
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval); //为了防止浏览器回退
    this._isMounted = false;
    this.setState = (state, callback) => {};
  }
  ifCompare(object1, object2) {
    var o1keys = Object.keys(object1);
    var o2keys = Object.keys(object2);
    if (o2keys.length !== o1keys.length) return false;
    for (let i = 0; i <= o1keys.length - 1; i++) {
      let key = o1keys[i];
      if (!o2keys.includes(key)) return false;
      if (object2[key].toString() !== object1[key].toString()) return false;
    }
    return true;
  }
  initPanel() {
    let cur_path = window.location.pathname;
    let route_list = JSON.parse(JSON.stringify(this.state.route_list));
    for (let i = 0; i < route_list.length; i++) {
      if (
        route_list[i].path === cur_path ||
        cur_path.indexOf(route_list[i].path) >= 0
      ) {
        //首页
        route_list[i].selected = true;
      } else if (route_list[i].children) {
        // 其他页面
        for (let j = 0; j < route_list[i].children.length; j++) {
          if (
            route_list[i].children[j].path === cur_path ||
            cur_path.indexOf(route_list[i].children[j].path) >= 0
          ) {
            route_list[i].children[j].selected = true;
            route_list[i].selected = true;
            break ;
          } else {
            route_list[i].selected = false;
            route_list[i].children[j].selected = false;
          }
        }
      } else {
        route_list[i].selected = false;
      }
    }
    this.setState({
      route_list: route_list,
    });
  }
  click_first_sidebar(index) {
    let route_list = JSON.parse(JSON.stringify(this.state.route_list));
    if (!route_list[index].selected && route_list[index].children) {
      return;
    }
    if (!route_list[index].selected && !route_list[index].children) {
      for (let i = 0; i < route_list.length; i++) {
        route_list[i].selected = false;
        if (route_list[i].children) {
          for (let j = 0; j < route_list[i].children.length; j++) {
            route_list[i].children[j].selected = false;
          }
        }
      }
      route_list[index].selected = true;
    }
    this.setState({
      route_list: route_list,
    });
  }
  click_second_sidebar(index, index2) {
    let route_list = JSON.parse(JSON.stringify(this.state.route_list));
    if (
      route_list[index].selected &&
      route_list[index].children[index2].selected
    ) {
      // 已选中
      return;
    }
    for (let i = 0; i < route_list.length; i++) {
      route_list[i].selected = false;
      if (route_list[i].children) {
        for (let j = 0; j < route_list[i].children.length; j++) {
          route_list[i].children[j].selected = false;
        }
      }
    }
    route_list[index].selected = true;
    route_list[index].children[index2].selected = true;
    this.setState({
      route_list: route_list,
    });
  }
  handle_min_first_path(path, index) {
    let route_list = JSON.parse(JSON.stringify(this.state.route_list));
    if (route_list[index].children) {
      return;
    }
    return path;
  }
  // 点击头像
  clickAvatar = () => {
    this.setState({
      show_user_popup: !this.state.show_user_popup,
    });
  };
  // 用户弹窗穿父组
  handleUserInfo(obj) {
    this.setState({
      show_user_popup: obj.show_user_popup,
    });
  }
  // 点击商标
  clickLogo = () => {
    this.props.history.push(`/home_page`);
  };
  render() {
    return (
      <>
        {this.state.isShowSideBar ? (
          <div className="sidebar-container">
            <div className="sidebar-top-container">
              <div className="logo-wrapper">
                <div className="logo-text-wrapper">
                  <div className="logo-img-2">
                    <NavLink to="/home_page">
                      <img src={logo_text} alt="" />
                    </NavLink>
                  </div>
                  <NavLink to="/home_page">
                    <div className="logo-text">一站式产品体验共创平台</div>
                  </NavLink>
                </div>
              </div>
              <div className="header-wrapper">
                <div className="header-left-wrapper"></div>
                <div className="header-right-wrapper">
                  <div className="info-wrapper">
                    <div className="info-item-wrapper">
                      <div className="info-item-img">
                        <img src={user_money} alt="" />
                      </div>
                      <div className="info-item-text">
                        <span className="info-item-name">账户余额：</span>
                        {this.props.user.balance}元
                      </div>
                    </div>
                    <div className="info-item-wrapper">
                      <div className="info-item-img">
                        <img src={user_quota} alt="" />
                      </div>
                      <div className="info-item-text">
                        <span className="info-item-name">剩余人数quota：</span>
                        {this.props.user.quota}
                      </div>
                    </div>
                    <div className="info-item-wrapper">
                      <div className="info-item-img">
                        <img src={user_type} alt="" />
                      </div>
                      <div className="info-item-text">
                        <span className="info-item-name">账户类型：</span>
                        {account_type_obj[this.props.user.account_type]}
                      </div>
                    </div>
                  </div>
                  <div className="avatar-wrapper" onClick={this.clickAvatar}>
                    <div className="avatar-img">
                      <img
                        src={`${
                          this.props.user.profile_pic.file
                            ? this.props.user.profile_pic.file
                            : tab_img_avatar
                        }`}
                        alt=""
                      />
                    </div>
                    <div className="avatar-text">
                      {this.props.user.first_name}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {this.state.isShowLeftSideBar ? (
              <div className="sidebar-left-container">
                <div className="sidebar-content-wrapper">
                  {this.state.route_list.map((item, index) => {
                    return (
                      <div className="sidebar-card" key={index}>
                        {this.state.is_sidebar_min ? (
                          <div
                            className={`sidebar-card-title-wrapper ${
                              item.selected ? "active" : ""
                            }`}
                          >
                            <Link
                              key={"a_main_" + index}
                              to={this.handle_min_first_path.bind(
                                this,
                                item.path,
                                index
                              )}
                              className="sidebar-card-title"
                              onClick={this.click_first_sidebar.bind(
                                this,
                                index
                              )}
                            >
                              <div className="sidebar-card-title-img">
                                {item.selected ? (
                                  <img src={item.dropdown_img_active} alt="" />
                                ) : (
                                  <img src={item.dropdown_img} alt="" />
                                )}
                              </div>
                              <div className="sidebar-card-title-text">
                                {item.children
                                  ? item.dropdown_name
                                  : item.dropdown_name}
                              </div>
                            </Link>
                          </div>
                        ) : (
                          <div
                            className={`sidebar-card-title-wrapper ${
                              item.selected ? "active" : ""
                            }`}
                          >
                            {item.children ? (
                              item.name
                            ) : (
                              <Link
                                key={"a_main_" + index}
                                to={item.path}
                                className="sidebar-card-title"
                                onClick={this.click_first_sidebar.bind(
                                  this,
                                  index
                                )}
                              >
                                {item.name}
                              </Link>
                            )}
                          </div>
                        )}

                        {item.children ? (
                          <div className="sidebar-card-content">
                            {item.children.map((item2, index2) => {
                              return (
                                <Link
                                  key={"a_sub_" + index2}
                                  to={item2.path}
                                  className={`link-content-wrapper ${
                                    item2.selected ? "active" : ""
                                  }`}
                                  onClick={this.click_second_sidebar.bind(
                                    this,
                                    index,
                                    index2
                                  )}
                                >
                                  {this.state.is_sidebar_min ? (
                                    ""
                                  ) : (
                                    <div className="link-content-img">
                                      {item2.selected ? (
                                        <img
                                          src={item2.card_img_active}
                                          alt=""
                                        />
                                      ) : (
                                        <img src={item2.card_img} alt="" />
                                      )}
                                    </div>
                                  )}
                                  <div className="link-content-name">
                                    {item2.name}
                                  </div>
                                </Link>
                              );
                            })}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className={this.state.show_user_popup ? "" : "isHide"}>
              <UserPopup
                handleUserInfo={this.handleUserInfo.bind(this)}
              ></UserPopup>
            </div>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}
export default connect(
  (state) => ({
    user: state.user,
    survey_queue: state.surveyReducer,
  }), //映射状态
  {
    editUser,
    getUser,
    editUserItem,
    getSurveyQueueFromBackend,
    setSurveyPolling,
    setSurveyQueueToBackEnd,
  } //映射操作状态的方法
)(side_bar);
