/** @format */

import React, { Component } from "react";
import {
  questionType,
  getQuestionTypeValue,
  getQuestionType,
  getQuestionTypeName,
} from "../../Question_rules/question_type_rule";
import { message } from "antd";
import project_icon_add from "../../../../../images/project_icon_add.png";
import project_icon_del from "../../../../../images/project_icon_del.png";
import cur_icon_add from "../../../../../images/cur_icon_add.png";
import cur_icon_delete from "../../../../../images/cur_icon_delete.png";
import cur_icon_and_pink from "../../../../../images/cur_icon_and_pink.png";
import cur_icon_or_white from "../../../../../images/cur_icon_or_white.png";
import XmSelect from "../../../../Select/xm_select";
import BlankCondition from "./Blank_condition/blank_condition";
import EnumerationCondition from "./Enumeration_condition/enumeration_condition";
import SortCondition from "./Sort_condition/Sort_condition";
import SingleCondition from "./Single_condition/single_condition";
import ScoreCondition from "./Score_condition/score_condition";
import ProportionCondtion from "./Proportion_condtion/proportion_condtion";
import MatrixSingleCondition from "./Matrix_single_condition/matrix_single_condition";
import MatrixBlankCondition from "./Matrix_blank_condition/matrix_blank_condition";
import MaxdiffCondition from "./Maxdiff_condition/maxdiff_condition";
import HeatmapCondition from "./Heatmap_condition/heatmap_condition"
export default class logic_card extends Component {
  constructor(props) {
    super(props);
    this.has_sort_list = ["sort"];
    this.has_single_list = ["single", "multiple"];
    this.has_number_input_list = ["nps", "score"];
    this.has_matrix_number_list = ["proportion", "matrix_score"];
    this.has_blank_list = ["blank"];
    this.state = {
      name: "logic_card",
      question_list: [], //题目列表
      current_question_index: "", ////当前的question
      current_question: {}, //当前的question
      condition_question_list: [{ value: "", key: "" }], //如果第1框
      result_option_list: [
        { value: "跳转", key: 1 },
        { value: "+", key: 2 },
        { value: "-", key: 3 },
        { value: "显示", key: 6 },
        { value: "必不显示", key: 9 },
        { value: "向下顺延", key: 7 },
        { value: "转移跳转", key: 8 },
      ], //结果第1框
      result_question_list: [], //结果第2框

      logic_settings: [], // 如果 否则
      current_question_type: "",
      current_question_type_name: "",
    };
  }
  static getDerivedStateFromProps(props, state) {
    if (
      JSON.stringify(props.question_list_logic) !==
      JSON.stringify(state.question_list_logic_prev) ||
      JSON.stringify(props.question_list) !==
      JSON.stringify(state.question_list_prev)
    ) {
      return {
        needRefresh: true,
        question_list_logic_prev: props.question_list_logic || [],
        question_list_prev: props.question_list || [],
        question_list: props.question_list,
        question_list_logic: props.question_list_logic,
        current_question_index: props.current_question_index,
        current_question:
          props.question_list[props.current_question_index] || {},
        logic_settings:
          props.question_list_logic[props.current_question_index]
            ?.logic_settings || [],
        current_question_type:
          props.question_list[props.current_question_index].question_type,
        current_question_type_name: getQuestionType(
          props.question_list[props.current_question_index].question_type
        ),
      };
    }
    return null;
  }
  componentDidMount() {
    this._isMounted = true;
  }
  componentDidUpdate() {
    if (this._isMounted && this.state.needRefresh) {
      this.setData();
      this.setState({
        needRefresh: false,
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    this.setState = () => { };
  }
  // 设置值
  setData() {
    //如果第1框
    let condition_question_list = JSON.parse(
      JSON.stringify(this.state.condition_question_list)
    );
    condition_question_list[0].value = this.state.current_question.name;
    condition_question_list[0].key = this.state.current_question_index;
    //结论第3框
    let result_question_list = JSON.parse(
      JSON.stringify(this.state.result_question_list)
    );
    for (let i = 0; i < this.state.question_list.length; i++) {
      result_question_list[i] = { value: "", key: "" };
      result_question_list[i].value =
        i + 1 + "." + this.state.question_list[i].name;
      result_question_list[i].key =
        this.state.question_list[i].question_id.toString();
      result_question_list[i].is_question_active =
        this.state.question_list[i].is_question_active;
    }
    this.setState({
      condition_question_list: condition_question_list,
      result_question_list: result_question_list,
    });
  }
  //设置如果的且or或
  handleConditionOp(index) {
    this.props.handleConditionOp(index, this.state.current_question_index);
  }
  // 如果第2k
  handleDefaultConditionJudge(judge, index) {
    if (judge === "") {
      return null;
    }
    return judge;
  }
  // 如果第2个
  handleConditionJudgeChange(key, index2, index1) {
    if (key === 8) {
      //特殊人群
      for (
        let i = 0;
        i <
        this.state.logic_settings[index1].condition_settings.condition.length;
        i++
      ) {
        if (
          i !== index2 &&
          this.state.logic_settings[index1].condition_settings.condition[i]
            .judge
        ) {
          message.warning(
            '如选了"选择与人群显示冲突"的选项只能存在一项条件设置，请检查',
            3,
            () => { }
          );
          this.props.handleConditionJudgeChange(
            "",
            index2,
            index1,
            this.state.current_question_index
          );
          return;
        }
      }
    } else {
      for (
        let i = 0;
        i <
        this.state.logic_settings[index1].condition_settings.condition.length;
        i++
      ) {
        if (
          i !== index2 &&
          this.state.logic_settings[index1].condition_settings.condition[i]
            .judge === 8
        ) {
          message.warning(
            '如选了"选择与人群显示冲突"的选项只能存在一项条件设置，请检查',
            3,
            () => { }
          );
          this.props.handleConditionJudgeChange(
            "",
            index2,
            index1,
            this.state.current_question_index
          );
          return;
        }
      }
    }

    this.props.handleConditionJudgeChange(
      key,
      index2,
      index1,
      this.state.current_question_index
    );
  }
  // 填空是否有字
  handle_blank_word(judge, index2, index1) {
    if (judge >= 2) {
      return true;
    }
    return false;
  }
  // 如果第3个 input
  handleConditionInputChange(value, index2, index1, event) {
    this.props.handleConditionInputChange(
      value,
      index2,
      index1,
      this.state.current_question_index
    );
  }
  // 如果第3k
  handleDefaultConditionInput(value, index) {
    if (value === "") {
      return null;
    }
    return value;
  }
  //  如果删除
  handleDeleteCondition(index1, index2) {
    this.props.handleDeleteCondition(
      index1,
      index2,
      this.state.current_question_index,
      this.state.current_question_type
    );
  }
  // 如果添加
  handleAddCondition(index1, index2) {
    this.props.handleAddCondition(
      index1,
      index2,
      this.state.current_question_index,
      this.state.current_question_type
    );
  }
  // 整个如果否则删除
  handleDeleteSetting(index1, index2) {
    this.props.handleDeleteSetting(
      index1,
      index2,
      this.state.current_question_index,
      this.state.current_question_type
    );
  }
  //验证数字
  validateNumber(event) {
    event.target.value = event.target.value.replace(/^0[0-9]+/g, 0);
    event.target.value = event.target.value.replace(/[^/.\d]/g, "");
    event.target.value = event.target.value.replace(".", "");
  }
  // 否则最后一道题目没有跳转
  handleResultOptionList(index2, index1) {
    if (
      this.state.current_question_index === "" ||
      this.state.question_list.length === 0
    ) {
      return [];
    }
    let result_option_list = JSON.parse(
      JSON.stringify(this.state.result_option_list)
    );
    if (
      this.state.current_question_index ===
      this.state.question_list.length - 1
    ) {
      result_option_list = result_option_list.filter(
        (item) =>
          item.key !== 1 &&
          item.key !== 6 &&
          item.key !== 9 &&
          item.key !== 7 &&
          item.key !== 8
      );
    }
    let flag = false;
    let condition =
      this.state.logic_settings[index1].condition_settings.condition;
    for (let i = 0; i < condition.length; i++) {
      if (condition[i].judge === 8) {
        flag = true;
        break;
      }
    }
    if (!flag) {
      result_option_list = result_option_list.filter(
        (item) => item.key !== 7 && item.key !== 8
      );
    }
    return result_option_list;
  }
  // 否则第1个
  handleDefaultResultJudge(judge, index2, index1) {
    if (judge === "") {
      return null;
    }
    return judge;
  }
  // 否则第1个
  handleResultJudgeChange(key, index2, index1) {
    this.props.handleResultJudgeChange(
      key,
      index2,
      index1,
      this.state.current_question_index
    );
  }
  // 否则第2个select
  handleDefaultResultOption(question_id, index2, index1) {
    if (question_id === "") {
      return null;
    }
    let result_question_obj = this.state.result_question_list.filter(
      (ele, index) =>
        index > this.state.current_question_index &&
        ele.key.toString() === question_id.toString()
    )[0];
    if (!result_question_obj) return null; //此题之前排序到当前题目前面了，所以不可以跳转
    if (result_question_obj.is_question_active === 0) return null; //题目下架了
    return question_id.toString();
  }
  // 否则第2个
  handleResultOptionChange(key, index2, index1) {
    this.props.handleResultOptionChange(
      key,
      index2,
      index1,
      this.state.current_question_index
    );
  }
  // 结果第2个 input
  handleResultInputChange(index2, index1, event) {
    this.props.handleResultInputChange(
      event.target.value,
      index2,
      index1,
      this.state.current_question_index
    );
  }
  // 否则第2个input
  handleDefaultResultInput(score, index2, index1) {
    return score;
  }
  // 否则删除
  handleDeleteResult(index1, index2) {
    this.props.handleDeleteResult(
      index1,
      index2,
      this.state.current_question_index
    );
  }
  // 否则添加
  handleAddResult(index1, index2) {
    this.props.handleAddResult(
      index1,
      index2,
      this.state.current_question_index
    );
  }
  // 否则的跳转选项
  jump_result_list() {
    return this.state.result_question_list.filter(
      (ele, index) =>
        index > this.state.current_question_index &&
        ele.is_question_active === 1
    );
  }
  // 如果第3个
  handleConditionOptionChange(key, index2, index1) {
    //key answer_id
    this.props.handleConditionOptionChange(
      key,
      index2,
      index1,
      this.state.current_question_index
    );
  }
  // 如果第3个
  handleConditionOptionChange2(key, index2, index1) {
    //key answer_id
    this.props.handleConditionOptionChange2(
      key,
      index2,
      index1,
      this.state.current_question_index
    );
  }
  // 如果第3个 sort
  handleConditionSortOptionChange(key, index2, index1) {
    //key answer_id
    this.props.handleConditionSortOptionChange(
      key,
      index2,
      index1,
      this.state.current_question_index
    );
  }
  // 不同题目的condition
  chooseQuestionContion(item2, index2, item, index) {
    let question_type_val = getQuestionType(this.state.current_question_type);
    let has_single_list = [
      "single",
      "multiple",
      "picture",
      "single_2",
      "single_3",
    ];
    let has_number_input_list = ["nps", "score", "nps_2"];
    let has_matrix_number_list = ["proportion", "matrix_score", "matrix_nps"];
    let has_matrix_single_list = ["matrix_single", "matrix_multiple", "matrix_psm"];
    let has_matrix_blank_list = ["matrix_blank"];
    if (question_type_val === "blank") {
      return (
        <BlankCondition
          condition_settings_item={item2}
          condition_settings_index={index2}
          logic_settings_index={index}
          handleConditionJudgeChange={this.handleConditionJudgeChange.bind(
            this
          )}
          handleConditionInputChange={this.handleConditionInputChange.bind(
            this
          )}
        ></BlankCondition>
      );
    } else if (question_type_val === "enumeration") {
      return (
        <EnumerationCondition
          condition_settings_item={item2}
          condition_settings_index={index2}
          logic_settings_index={index}
          handleConditionJudgeChange={this.handleConditionJudgeChange.bind(
            this
          )}
          handleConditionInputChange={this.handleConditionInputChange.bind(
            this
          )}
        ></EnumerationCondition>
      );
    } else if (question_type_val === "sort") {
      return (
        <SortCondition
          current_question={this.state.current_question}
          condition_settings_item={item2}
          condition_settings_index={index2}
          logic_settings_index={index}
          handleConditionJudgeChange={this.handleConditionJudgeChange.bind(
            this
          )}
          handleConditionOptionChange={this.handleConditionOptionChange.bind(
            this
          )}
          handleConditionSortOptionChange={this.handleConditionSortOptionChange.bind(
            this
          )}
        ></SortCondition>
      );
    } else if (has_single_list.includes(question_type_val)) {
      return (
        <SingleCondition
          current_question={this.state.current_question}
          condition_settings_item={item2}
          condition_settings_index={index2}
          logic_settings_index={index}
          handleConditionOptionChange={this.handleConditionOptionChange.bind(
            this
          )}
          handleConditionJudgeChange={this.handleConditionJudgeChange.bind(
            this
          )}
        ></SingleCondition>
      );
    } else if (has_number_input_list.includes(question_type_val)) {
      return (
        <ScoreCondition
          condition_settings_item={item2}
          condition_settings_index={index2}
          logic_settings_index={index}
          handleConditionJudgeChange={this.handleConditionJudgeChange.bind(
            this
          )}
          handleConditionInputChange={this.handleConditionInputChange.bind(
            this
          )}
        ></ScoreCondition>
      );
    } else if (has_matrix_number_list.includes(question_type_val)) {
      return (
        <ProportionCondtion
          current_question={this.state.current_question}
          condition_settings_item={item2}
          condition_settings_index={index2}
          logic_settings_index={index}
          handleConditionJudgeChange={this.handleConditionJudgeChange.bind(
            this
          )}
          handleConditionInputChange={this.handleConditionInputChange.bind(
            this
          )}
          handleConditionOptionChange={this.handleConditionOptionChange.bind(
            this
          )}
        ></ProportionCondtion>
      );
    } else if (question_type_val === "heatmap") {
      return (
        <HeatmapCondition
          current_question={this.state.current_question}
          condition_settings_item={item2}
          condition_settings_index={index2}
          logic_settings_index={index}
          handleConditionJudgeChange={this.handleConditionJudgeChange.bind(
            this
          )}
          handleConditionInputChange={this.handleConditionInputChange.bind(
            this
          )}
          handleConditionOptionChange={this.handleConditionOptionChange.bind(
            this
          )}
        ></HeatmapCondition>
      );
    } else if (has_matrix_single_list.includes(question_type_val)) {
      return (
        <MatrixSingleCondition
          current_question={this.state.current_question}
          condition_settings_item={item2}
          condition_settings_index={index2}
          logic_settings_index={index}
          handleConditionJudgeChange={this.handleConditionJudgeChange.bind(
            this
          )}
          handleConditionOptionChange={this.handleConditionOptionChange.bind(
            this
          )}
          handleConditionOptionChange2={this.handleConditionOptionChange2.bind(
            this
          )}
        ></MatrixSingleCondition>
      );
    } else if (has_matrix_blank_list.includes(question_type_val)) {
      return (
        <MatrixBlankCondition
          current_question={this.state.current_question}
          condition_settings_item={item2}
          condition_settings_index={index2}
          logic_settings_index={index}
          handleConditionJudgeChange={this.handleConditionJudgeChange.bind(
            this
          )}
          handleConditionOptionChange={this.handleConditionOptionChange.bind(
            this
          )}
          handleConditionOptionChange2={this.handleConditionOptionChange2.bind(
            this
          )}
        ></MatrixBlankCondition>
      );
    } else if (question_type_val === "max_diff") {
      return < MaxdiffCondition
        current_question={this.state.current_question}
        condition_settings_item={item2}
        condition_settings_index={index2}
        logic_settings_index={index}
        handleConditionJudgeChange={
          this.handleConditionJudgeChange.bind(
            this
          )
        }
        handleConditionOptionChange={
          this.handleConditionOptionChange.bind(
            this
          )
        }
        handleConditionOptionChange2={
          this.handleConditionOptionChange2.bind(
            this
          )
        }
      ></MaxdiffCondition >
    }
    return <div></div>;
  }

  render() {
    return (
      <>
        {this.state.logic_settings.map((item, index) => {
          return (
            <div key={index} className="">
              {/* 如果 */}
              <div className="logic_item_main dashed_line">
                <div className="logic_item_main_left">
                  <span>如果</span>
                  <div
                    className={
                      item.condition_settings.condition.length >= 2
                        ? ""
                        : "isHide"
                    }
                  >
                    <div
                      className="logic_item_line"
                      style={{
                        height:
                          "calc(" +
                          (48 * item.condition_settings.condition.length - 32) +
                          "px)",
                      }}
                    >
                      <img
                        src={
                          item.condition_settings.op
                            ? cur_icon_or_white
                            : cur_icon_and_pink
                        }
                        className="cur_icon_and_pink"
                        alt=""
                        onClick={this.handleConditionOp.bind(this, index)}
                      />
                    </div>
                  </div>
                </div>
                <div className="logic_item_main_right">
                  {item.condition_settings.condition.map((item2, index2) => {
                    return (
                      <div className="logic_item_rule" key={index2}>
                        <div className="logic_rule_select condition_select_first">
                          <XmSelect
                            options={this.state.condition_question_list}
                            defaultValue={
                              this.state.condition_question_list[0].key
                            }
                            key={this.state.condition_question_list[0].key}
                            placeholder="请选择"
                            hasRichText={true}
                            showRichText={false}
                          ></XmSelect>
                        </div>
                        {this.chooseQuestionContion.call(
                          this,
                          item2,
                          index2,
                          item,
                          index
                        )}
                        <div className={index2 === 0 ? "isHide" : ""}>
                          <img
                            src={cur_icon_delete}
                            className="cur_icon_delete"
                            alt=""
                            onClick={this.handleDeleteCondition.bind(
                              this,
                              index,
                              index2
                            )}
                          />
                        </div>
                        <div>
                          <img
                            src={cur_icon_add}
                            className="cur_icon_delete"
                            alt=""
                            onClick={this.handleAddCondition.bind(
                              this,
                              index,
                              index2
                            )}
                          />
                        </div>
                        <div className={index2 === 0 ? "" : "isHide"}>
                          <img
                            src={project_icon_del}
                            onClick={this.handleDeleteSetting.bind(
                              this,
                              index,
                              index2
                            )}
                            className="cur_icon_delete"
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* 否则 */}
              <div className="logic_item_main">
                <div className="logic_item_main_left">
                  <span>则</span>
                </div>
                <div className="logic_item_main_right">
                  {item.result_settings.map((item2, index2) => {
                    /* 否则单行的条件 */
                    return (
                      <div className="logic_item_rule" key={index2}>
                        <div className="logic_rule_select result_select_first">
                          <XmSelect
                            options={this.handleResultOptionList.call(
                              this,
                              index2,
                              index
                            )}
                            defaultValue={this.handleDefaultResultJudge.call(
                              this,
                              item2.judge,
                              index2,
                              index
                            )}
                            onChange={(e) => {
                              this.handleResultJudgeChange.call(
                                this,
                                e,
                                index2,
                                index
                              );
                            }}
                            key={item2.judge}
                            placeholder="请选择"
                          ></XmSelect>
                        </div>

                        <div
                          className={
                            item2.judge === 1 ||
                              item2.judge === 6 ||
                              item2.judge === 9 ||
                              item2.judge === 8
                              ? "logic_rule_select result_select_second"
                              : "isHide"
                          }
                        >
                          {/* 否则 2 */}
                          <XmSelect
                            options={this.jump_result_list.call(this)}
                            value={String(item2.question_id) || null}
                            onChange={(e) => {
                              this.handleResultOptionChange.call(
                                this,
                                e,
                                index2,
                                index
                              );
                            }}
                            placeholder="请选择"
                            hasRichText={true}
                            showRichText={false}
                          ></XmSelect>
                        </div>
                        <div
                          className={
                            item2.judge === 2 || item2.judge === 3
                              ? "logic_rule_select result_select_second"
                              : "isHide"
                          }
                        >
                          {/* 否则 2 */}
                          <input
                            placeholder="请输入分值"
                            className="result-input"
                            type="text"
                            maxLength="3"
                            key={item2.score}
                            onBlur={this.handleResultInputChange.bind(
                              this,
                              index2,
                              index
                            )}
                            defaultValue={this.handleDefaultResultInput.call(
                              this,
                              item2.score,
                              index2
                            )}
                            onInput={this.validateNumber.bind(this)}
                          />
                        </div>
                        <div
                          className={
                            item2.judge === 1 ? "logic_rule_text" : "isHide"
                          }
                        >
                          ，否则正常进入下一题
                        </div>
                        <div
                          className={
                            item.result_settings.length > 1 ? "" : "isHide"
                          }
                        >
                          <img
                            src={cur_icon_delete}
                            className="cur_icon_delete"
                            alt=""
                            onClick={this.handleDeleteResult.bind(
                              this,
                              index,
                              index2
                            )}
                          />
                        </div>
                        <div>
                          <img
                            src={cur_icon_add}
                            className="cur_icon_delete"
                            alt=""
                            onClick={this.handleAddResult.bind(
                              this,
                              index,
                              index2
                            )}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  }
}
