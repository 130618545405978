import React, { Component } from 'react'
import './detail_chart_popup.scss';
import cur_icon_close_gre from '../../../images/cur_icon_close_gre.png'
import cur_icon_search from "../../../images/emotion_analysis/cur_icon_search.png"
import DetailSingleChart from './detail_chart/detail_single_chart'
import debounce from 'lodash/debounce';
import { Input, Tabs } from 'antd';
const { TabPane } = Tabs;

export default class detail_chart_popup extends Component {
    constructor(props) {
        super(props);
        this.resize = debounce(this.resize, 1000);
        this.state = {
            option_distribution: [],
            cur_detail_chart: {},
            single_id: "",
            bar_option_name: "",
            question_name: "",
            blankListHeight: 0,
            search_text: "",
            tab_list: [] // 三个切换
        }
    }
    setDetailChart(obj) {
        this.debounceFunc = this.resize.bind(this);
        window.addEventListener("resize", this.debounceFunc, false);
        let { option_distribution, single_id, bar_option_name, question_name } = obj
        let tab_list = []
        for (let i = 0; i < option_distribution.length; i++) {
            let obj = option_distribution[i].name
            tab_list.push(obj)
        }
        this.setState({
            option_distribution: option_distribution,
            single_id: single_id,
            bar_option_name: bar_option_name,
            question_name: question_name,
            search_text: "",
            tab_list: tab_list,
            cur_detail_chart: option_distribution[0]
        }, () => {
            this.resize()
        })
    }
    // 这里主要是定义高度
    resize() {
        // let blankDom = document.getElementById("detail_chart_" + this.state.single_id + "_wrapper")
        // if (!blankDom) { return; }
        // let blankDomHeight = blankDom.offsetHeight - 60;
        // this.setState({ blankListHeight: blankDomHeight })
    }
    // 关闭对话框
    tags_close() {
        this.props.canShowDetailChartPopup({ showDetailChartPopup: false })
    }
    clickTab(key) {
        let index = parseInt(key)
        let cur_detail_chart = this.state.option_distribution[index]
        this.setState({
            cur_detail_chart: cur_detail_chart,
        })
    }
    render() {
        const prefix = (
            <img src={cur_icon_search} alt="" className='evaluation-icon-search' />
        )
        return (
            <div className='detail-chart-popup'>
                <div className='detail-chart-popup-container'>
                    <div className='detail-chart-popup-top'>
                        <div className='popup-top-title'>列表详情展示</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                        </div>
                    </div>
                    {/* 图表名字 */}
                    <div className='popup-content-top'>
                        <div className='top-title-wrapper'>
                            <div className='main-title'>{this.state.question_name}</div>
                            <div className='sub-title'>选项：{this.state.bar_option_name}</div>
                        </div>
                    </div>
                    {/* 显示真正的列表*/}
                    <div className='detail-chart-wrapper' id={`detail_chart_${this.state.single_id}_wrapper`} >
                        <div className="detail-chart-wrapper-2">
                            <Tabs defaultActiveKey="1" size="small" onChange={this.clickTab.bind(this)}>
                                {
                                    this.state.tab_list.map((item, index) => {
                                        return (
                                            <TabPane tab={`${item}`} key={index}>
                                                <DetailSingleChart key={"detail" + index} dataList={this.state.cur_detail_chart} single_id={`detail_single_chart_${index}`}></DetailSingleChart>
                                            </TabPane>
                                        )
                                    })
                                }
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
