import React, { Component } from 'react'
import './brand_survey.scss';
import add_icon from '../../images/brand_survey/add_icon.png'
import download_icon from '../../images/overall_data/download_icon.png'
import overall_icon_intro1 from '../../images/overall_data/overall_icon_intro1.png'
import overall_icon_intro2 from '../../images/overall_data/overall_icon_intro2.png'
import overall_icon_intro3 from '../../images/overall_data/overall_icon_intro3.png'

import Loading from "../../components/Loading/loading"
import ProgressBar from "../../components/progress_bar/progress_bar"
import XmSelect from '../../components/Select/xm_select';
import FilterSelect from '../Overall_data/Filter_chart_select/filter_select'
import DispatchSingleChart from '../Overall_data/Dispatch_charts/Dispatch_single_chart';
import SingleChart from "../Preinstall_comment/CommentSingleChart"
import CustomChart from "../Preinstall_comment/CommentCustomChart"

import { download, getQueryParameter, saveImgZip, calScore, calP, calB, calPreference } from '../../util/utils'
import { getBrandSurvey, getSurveyAnalysisSimple, exportSurvey, getRegionList, getSurveyInfo } from '../../api/panelApi';
import { message, Tooltip, Empty, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import html2canvas from "html2canvas";

import img1 from '../../images/progress_bar/1.png'
import img2 from '../../images/progress_bar/2.png'
import img3 from '../../images/progress_bar/3.png'
import img4 from '../../images/progress_bar/4.png'
import img5 from '../../images/progress_bar/5.png'
import img6 from '../../images/progress_bar/6.png'

export default class Brand_survey extends Component {
    constructor(props) {
        super(props);
        this.filterRef = React.createRef()
        this.handleScroll = this.handleScroll.bind(this)
        this.state = {
            brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : "",   //品牌ID
            loading: 0,
            loading_chart: 0,
            loading_batch_download: 0, // 批量下载的loading
            current_batch_download_num: 0, // 批量下载中每一个图片都下载
            loadingText: "数据较大，加载中",
            special_event_id: "",
            survey_id: "",
            survey_text: "",
            survey_list: [],
            survey_chart_list: [],
            survey_theme: {},
            survey_target_audience: [],
            survey_date: {},
            survey_tip: {},
            dispatch_chart_list: [
                {
                    name: "参与年龄分布", type: 0, sum: 0,
                    data: []
                },
                {
                    name: "参与地域分布", type: 2, sum: 0,
                    data: []
                }
            ],
            region_list: [],
            selected_region_list: [],
            selected_gender_list: [],
            selected_age_list: [],
            start_time: "",
            end_time: "",
            batch_base_url: [], //批量图片
            filter_start_date: "",//筛选的开始时间
            filter_end_date: "",//筛选的结束时间
            // 进度条数据
            barList: [
                {
                    step: 1,
                    name: '项目设立',
                    imgUrl: img1,
                }, {
                    step: 2,
                    name: '问卷审核',
                    imgUrl: img2,
                }, {
                    step: 3,
                    name: '问卷发布',
                    imgUrl: img3,
                }, {
                    step: 4,
                    name: '问卷收集',
                    imgUrl: img4,
                }, {
                    step: 5,
                    name: '数据分析阶段',
                    imgUrl: img5,
                }, {
                    step: 6,
                    name: '项目完结',
                    imgUrl: img6,
                }
            ],
            step: 3,  // 当前进度
        }
    }
    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            window.addEventListener("scroll", this.handleScroll, true);
            this.init()
        }
    }
    //在componentWillUnmount，进行scroll事件的注销
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll, true);
        this._isMounted = false;
        this.setState = (state, callback) => { }
    }
    // 初始化
    init() {
        this.getBrandSurvey()
    }
    // 判断两个对象是否相等
    isObjectValueEqual(obj1, obj2) {
        if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
            return true
        }
        return false
    }
    //清空数据
    clearData() {
        // this.setState({})
    }
    //获取调研主题列表
    async getBrandSurvey() {
        this.setState({
            loading: 1
        })
        let brand_id = localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : ""
        try {
            const res = await getBrandSurvey({
                brand_id: parseInt(brand_id)
            })
            this.setState({
                loading: 0
            })
            if (!res.data || res.data.length === 0) {
                message.warning('暂无数据', 2, () => { })
                return
            }

            let survey_list = []
            let survey_list_formal = JSON.parse(JSON.stringify(res.data))
            for (let i = 0; i < survey_list_formal.length; i++) {
                let obj = { key: survey_list_formal[i].id, value: survey_list_formal[i].name, special_event_id: survey_list_formal[i].special_event_id }
                survey_list.push(obj)
            }
            if (window.location.search.indexOf("survey_id") !== -1) {
                // 从首页跳转过来
                this.initSurvey(survey_list)
            } else if (parseInt(localStorage.getItem("survey_id_cem")) > 0) {
                // 刷新 但已存在
                let survey_list_prev = JSON.parse(localStorage.getItem("survey_list_cem"))
                let isObjectValueEqual = this.isObjectValueEqual(survey_list, survey_list_prev)
                if (isObjectValueEqual) {
                    this.initPanel(survey_list)
                } else { //如超户的survey列表进行了更新
                    this.initSurvey(survey_list)
                }
            } else { // 之前没有survey_id
                this.initSurvey(survey_list)
            }
            return res;
        } catch (error) {
            this.setState({ loading: 0 }, () => { message.error('后台发生错误') })
            throw error;
        }
    }
    // local storage 有
    initPanel() {
        let survey_id = localStorage.getItem("survey_id_cem")
        let survey_list = localStorage.getItem("survey_list_cem") !== "" ? JSON.parse(localStorage.getItem("survey_list_cem")) : []
        let survey_text = localStorage.getItem("survey_text_cem")
        let special_event_id = localStorage.getItem("special_event_id_cem")
        this.setState({
            survey_id: survey_id ? parseInt(survey_id) : "",
            survey_list: survey_list,
            survey_text: survey_text,
            special_event_id: special_event_id,
        }, () => {
            this.getRegionList()
        })
    }
    // 获取 survey_text
    getSurveyText(id, survey_list) {
        for (let i = 0; i < survey_list.length; i++) {
            if (String(id) === String(survey_list[i].key)) {
                return survey_list[i]
            }
        }
        return ""
    }
    // 测试
    initSurvey(survey_list) {
        let survey_id = survey_list[0].key
        let survey_text = survey_list[0].value
        let special_event_id = survey_list[0].special_event_id;
        if (window.location.search.indexOf("survey_id") !== -1) {
            // 从首页跳转过来
            survey_id = getQueryParameter("survey_id");
            let item = this.getSurveyText(survey_id, survey_list)
            survey_text = item.value;
            special_event_id = item.special_event_id;
        }
        localStorage.setItem('survey_id_cem', parseInt(survey_id))
        localStorage.setItem('survey_text_cem', survey_text)
        localStorage.setItem('survey_list_cem', JSON.stringify(survey_list))
        localStorage.setItem('special_event_id_cem', special_event_id)
        this.setState({
            survey_id: parseInt(survey_id),
            survey_list: survey_list,
            survey_text: survey_text,
            special_event_id: special_event_id,
        }, () => {
            this.getRegionList()
        })
    }
    //获取地域列表
    async getRegionList() {
        let survey_id = parseInt(this.state.survey_id)
        this.setState({
            loading: 2
        })
        try {
            const response = await getRegionList({
                product_id: "",
                survey_type: 4,
                survey_id: survey_id,
                wish_id: ""
            })
            let region_list = []
            for (let i = 0; i < response.data.length; i++) {
                let obj = { key: i, value: response.data[i] }
                region_list.push(obj)
            }

            this.setState(
                {
                    region_list: region_list,
                    loading: this.state.loading - 1
                }, () => {
                    this.getSurveyInfo()
                })
            return response;
        } catch (error) {
            this.setState({
                loading: 0
            }, () => {
                message.error('后台发生错误')
            }
            )
            throw error;
        }
    }
    // 调研问卷信息
    async getSurveyInfo() {
        let survey_id = parseInt(this.state.survey_id)
        try {
            const response = await getSurveyInfo({
                survey_id: survey_id
            })
            if (!response) return this.setState({ loading: this.state.loading - 1 });
            let survey_tip = {
                question_num: response.data.question_num,
                finished_num: response.data.finished_num,
                avg_answer_duration: response.data.avg_answer_duration,
            }
            let survey_theme = {
                title: response.data.survey_name,
                content: response.data.survey_content,
            }

            let survey_date = {
                survey_start_time: response.data.survey_start_time,
                survey_end_time: response.data.survey_end_time,
            }


            let dispatch_chart_list = []
            response.data.survey_distribution.forEach((item, index) => {
                if (item.type === 0 || item.type === 2) {
                    let chartObj = { name: item.name, type: item.type, data: [], sum: item.sum }
                    for (let i = 0; i < item.data.length; i++) {
                        let dataObj = { value: item.data[i].val, name: item.data[i].name || "未知" }
                        chartObj.data.push(dataObj)
                    }
                    dispatch_chart_list.push(chartObj)
                }
            })

            this.setState({
                survey_tip: survey_tip,
                survey_theme: survey_theme,
                survey_date: survey_date,
                dispatch_chart_list: dispatch_chart_list,
                survey_target_audience: response.data.survey_target_audience,
                filter_start_date: response.data.survey_start_time,
                filter_end_date: response.data.survey_end_time,
                loading: this.state.loading - 1
            }, () => {
                this.initChart()
            })
        } catch (error) {
            this.initChart()
            this.setState({
                loading: this.state.loading - 1
            }, () => {
                message.error('后台发生错误')
            }
            )
            throw error;
        }
    }
    // 获取chart
    async getSurveyAnalysisSimple() {
        let brand_id = localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : ""
        let survey_id = this.state.survey_id
        this.setState({
            // survey_chart_list: [],
            loading_chart: 1,
        })

        try {
            const res = await getSurveyAnalysisSimple({
                survey_id: survey_id,
                brand_id: brand_id,
                product_id: "",
                age_list: this.state.selected_age_list,
                region_list: this.state.selected_region_list,
                gender_list: this.state.selected_gender_list,
                start_time: this.state.start_time,
                end_time: this.state.end_time
            })
            if (!res) return
            if (parseInt(res.status) === 204) {
                this.setState({
                    survey_chart_list: [],
                    loading_chart: this.state.loading_chart - 1
                })
                message.warning('暂无数据', 2, () => { })
            } else {
                let survey_chart_list = JSON.parse(JSON.stringify(res.data))
                survey_chart_list.forEach((item, index) => {
                    item["type"] = item.question_type
                    if (item.question_type === 1 || item.question_type === 12 || item.question_type === 13) {
                        for (let i = 0; i < item.data.length; i++) {
                            item.data[i].name = item.data[i].name || "未知"
                        }
                    } else if (item.question_type === 15 || item.question_type === 14) {  //矩阵滑条题
                        // item.data_list = item.data_list //列表
                        // item.chart_list = item.chart_list // 图表
                    } else if (item.question_type === 16) {  //开放题-矩阵填空题
                        // item.name = item.name
                        // item.option_list = item.option_list
                    } else if (item.question_type === 3 || item.question_type === 18 || item.question_type === 10) {  //简答题
                        // item.name = item.name
                        // item.data = item.data
                    } else if (item.question_type === 2) {//多选题
                        item["xAxisList"] = item.x_axis
                        item.option_id_list = item.option_id
                    } else if (item.question_type === 4 || item.question_type === 8 || item.question_type === 9) { //排序题
                        item.name = item.chart_name
                    } else if (item.question_type === 5 || item.question_type === 7) { // 滑条和打分
                        // item.name = item.name
                        // item.data = item.data
                        // item.x_axis = item.x_axis
                    } else if (item.question_type === 11) {  //自定义
                        // item.name = item.name
                        // item.data = item.data
                        // item.word_arr = item.word_arr
                        // item.word_bar_count_arr = item.word_bar_count_arr
                        // item.x_axis = item.x_axis
                    } else if (item.question_type === 19) {
                        item.data_list = []
                        let tmp_formal = JSON.parse(JSON.stringify(item.option_list))
                        let data_list = []
                        for (let i = 0; i < tmp_formal.length; i++) {
                            data_list.push({
                                option_name: tmp_formal[i].option_name,
                                preference: 0,
                                option_max_selected_count: tmp_formal[i].option_max_selected_count,
                                option_min_selected_count: tmp_formal[i].option_min_selected_count,
                                option_occurance: tmp_formal[i].option_occurance,
                                score: calScore(tmp_formal[i].option_max_selected_count, tmp_formal[i].option_min_selected_count, tmp_formal[i].option_occurance),
                                p: calP(tmp_formal[i].option_max_selected_count, tmp_formal[i].option_min_selected_count, tmp_formal[i].option_occurance),
                                b: calB(tmp_formal[i].option_max_selected_count, tmp_formal[i].option_min_selected_count, tmp_formal[i].option_occurance),
                            })
                        }
                        for (let i = 0; i < data_list.length; i++) {
                            data_list[i]["preference"] = calPreference(i, data_list) || 0
                        }
                        item.data_list = data_list
                        item["option_img"] = []
                        item["option_id"] = []
                    } else if (item.question_type === 20) {
                        // 热力图
                    }
                })
                this.setState({
                    survey_chart_list: survey_chart_list,
                    loading_chart: this.state.loading_chart - 1
                })
            }
            return res;
        } catch (error) {
            this.setState({
                survey_chart_list: [],
                loading_chart: 0
            }, () => {
                message.error('后台发生错误')
            }
            )
            throw error;
        }

    }

    // 点击选择调研主题
    onSelectSurveyChange(key, option) {
        if (window.location.search.indexOf("survey_id") !== -1) {
            // 从调研计划跳转过来
            var url = window.location.href;
            if (url.indexOf("?") !== -1) {
                url = url.replace(/(\?|#)[^'"]*/, ''); //去掉http地址参数
                window.history.pushState({}, 0, url);
            }
        }
        let cur_index = parseInt(option.key)
        let survey_list = JSON.parse(JSON.stringify(this.state.survey_list))
        if (parseInt(key) !== parseInt(this.state.survey_id)) {
            this.clearData()
        }

        let survey_id = survey_list[cur_index].key
        let survey_text = survey_list[cur_index].value || ""
        let item = this.getSurveyText(survey_id, survey_list)
        survey_text = item.value;
        let special_event_id = item.special_event_id;
        localStorage.setItem('survey_id_cem', survey_id)
        localStorage.setItem('survey_text_cem', survey_text)
        localStorage.setItem('special_event_id_cem', special_event_id)
        this.setState({
            survey_id: survey_id,
            survey_text: survey_text,
            special_event_id: special_event_id,
        }, () => {
            this.getRegionList()
        })
    };
    // 下拉框搜索可以大小写
    filterSelectOption(input, option) {
        return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    // 监听滚动
    handleScroll() {
        let card_chart = document.querySelector(".filter-chart-container");
        let card_fixed = document.querySelector(".filter-container-fixed");
        let card_content = document.querySelector(".chart-container");
        let side_bar_left = document.querySelector(".sidebar-left-container");
        let side_bar_top = document.querySelector(".sidebar-top-container");
        if (!card_fixed) return

        let card_fixed_self_height = card_fixed.offsetHeight //自身高度 
        let side_bar_self_width = side_bar_left.offsetWidth + 36 // 加上左右的padding
        let side_bar_self_height = side_bar_top.offsetHeight

        let card_chart_rect = card_chart.getBoundingClientRect();
        if (card_chart_rect.top <= side_bar_self_height) { // 到可视区窗口的头
            // card_fixed.style.cssText = "position: fixed;top: 0px;"
            card_fixed.style.cssText = `position: fixed;top:${side_bar_self_height}px;width:calc(100% - ${side_bar_self_width}px);box-shadow: 0px 2px 20px 8px #f8f9fd;min-width:1200px;`
            card_content.style.cssText = `position: relative;top:${card_fixed_self_height}px;margin-bottom: ${card_fixed_self_height}px;background: #fff;`
        } else {
            card_fixed.style.cssText = "position: relative;top: 0px;"
            card_content.style.cssText = "position: relative;top:0px;"
        }
    }
    // 删选初始化图表
    initChart() {
        // 防止接口回来满，但是setFilterList这个组件已经unmonted
        if (!this.filterRef.current) return;
        this.filterRef.current.setFilterList({
            region_list: this.state.region_list,
            filter_start_date: this.state.filter_start_date,
            filter_end_date: this.state.filter_end_date,
            is_init_filter: true
        })
        this.getSurveyAnalysisSimple()
    }
    //下载excel
    handleDownload() {
        this.setState({
            loading: 1,
            loadingText: "数据较大，下载中"
        }, () => {
            this.exportProductSurvey()
        })
    }
    exportProductSurvey() {
        let survey_id = this.state.survey_id
        exportSurvey({
            product_id: "",
            survey_type: '4',
            survey_id: survey_id
        }).then(res => {
            this.setState({
                loading: 0
            })
            if (res.status === 402) {
                message.warning('请联系心愿盒商务，了解并升级账户，谢谢！', 5, () => { })
            }
            else if (res.status === 204) {
                message.warning('暂未有报告', 2, () => { })
            } else {
                let time = this.getCurrentDownloadTime()
                let survey_text = this.state.survey_text
                download(res.data, `调研问卷分析_${survey_text}_${time}.xlsx`, "binary");
                return res;
            }
        }).catch(err => {
            this.setState({
                loading: 0
            }, () => {
                message.error('待上传')
            }
            )
            throw err;
        })
    }
    // 获取当前时间
    getCurrentDownloadTime() {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours(); // 时
        var minutes = date.getMinutes(); // 分
        var seconds = date.getSeconds() //秒
        var time = year + "-" + month + "-" + day + '_' + hour + '_' + minutes + '_' + seconds;
        return time;
    }
    //选择简单唯独图表
    chooseSingleChart(item, index) {
        if (item.type === 11) {
            return <CustomChart key={index} dataList={item} single_id={index}></CustomChart>
        }
        return <SingleChart
            key={index}
            dataList={item}
            single_id={index}
            survey_type={4}
            survey_id={this.state.survey_id}
            selected_region_list={this.state.selected_region_list}
            selected_gender_list={this.state.selected_gender_list}
            selected_age_list={this.state.selected_age_list}
            start_time={this.state.start_time}
            end_time={this.state.end_time}
            isRightToList={false}
        ></SingleChart>
    }
    // 搜索的filter
    searchFilter(filter_obj) {
        let { selected_region_list, selected_gender_list, selected_age_list, start_time, end_time } = filter_obj
        this.setState({
            selected_region_list: selected_region_list,
            selected_gender_list: selected_gender_list,
            selected_age_list: selected_age_list,
            start_time: start_time,
            end_time: end_time
        }, () => {
            this.getSurveyAnalysisSimple()
        })
    }
    // 新增调研计划
    handleAddSurvey() {
        this.props.history.push(`/dispatch_list/project_entry`)
    }
    exportImg(dom, name, scrollY = 0) {
        let that = this
        dom.style.backgroundColor = "#fff";
        let height = dom.offsetHeight;
        let width = dom.offsetWidth;
        html2canvas(dom, {
            backgroundColor: "#fff",
            height: height + 10,
            width: width,
            scrollY,
            useCORS: true
        }).then(function (image) {
            if (image.msToBlob) {
                navigator.msSaveBlob(image.msToBlob(), `${name || Date.now()}.png`);
            } else {
                let url = image.toDataURL("image/png", 1.0);
                let batch_base_url = JSON.parse(JSON.stringify(that.state.batch_base_url))
                let obj = {
                    name: name,
                    base64_img: url
                }
                batch_base_url.push(obj)
                that.setState({
                    current_batch_download_num: that.state.current_batch_download_num + 1,
                    batch_base_url: batch_base_url
                })
            }
        });
    }
    batchDownload() {
        this.setState({
            loading_batch_download: 1,
            loadingText: "批量下载中，数据较大，请耐心等待",
            current_batch_download_num: 0
        }, () => {
            setTimeout(() => {
                for (let i = 0; i < this.state.survey_chart_list.length; i++) {
                    let id = "chart_" + i + "_wrapper"
                    this.exportImg(
                        document.getElementById(id),
                        this.state.survey_chart_list[i].name
                    );
                }
                let timer = setInterval(() => {
                    if (this.state.current_batch_download_num === this.state.survey_chart_list.length) {
                        saveImgZip(this.state.batch_base_url)
                        clearInterval(timer)
                        this.setState({
                            loading_batch_download: 0
                        })
                    }
                }, 100)
            }, 0)
        })
    }
    // 点击步骤
    stepClick(data) {
        let index = data.index;
        let id = data.id;
        let special_event_id = data.special_event_id;
        if (index === 0) {
            // 跳转调研问卷设置
            localStorage.setItem('current_project_type', 2);
            localStorage.setItem('project_id', id);
            localStorage.setItem('special_event_id', special_event_id);
            this.props.history.push(`/survey_list/project_entry?survey_id=${this.state.survey_id}&project_type=2&step=${data.step}`)
        }
        if (index === 1) {
            this.props.history.push(`/survey_list?s=${this.state.survey_text}`)
        }
        if (index === 2) {
            this.props.history.push(`/survey_list?s=${this.state.survey_text}`)
        }
        if (index === 3) {
            this.props.history.push(`/survey_list?s=${this.state.survey_text}`)
        }
    }
    render() {
        return (
            <div className="container brand-survey-container">
                {
                    this.state.loading <= 0 && this.state.loading_chart <= 0 && this.state.loading_batch_download <= 0 ? "" : <Loading loadingText={this.state.loadingText}></Loading>
                }
                <ProgressBar barList={this.state.barList} id={this.state.special_event_id} type="2" stepClick={this.stepClick.bind(this)}></ProgressBar>
                <div className="theme-row">
                    <div className="theme-top-wrapper">
                        <div className="theme-title">调研主题</div>
                        <div className="theme-select">
                            <XmSelect
                                showSearch
                                filterOption={(input, option) => {
                                    return this.filterSelectOption.call(this, input, option)
                                }

                                }
                                defaultValue={this.state.survey_id} key={this.state.survey_id}
                                options={this.state.survey_list}
                                onChange={(e, index) => {
                                    this.onSelectSurveyChange(e, index)
                                }}></XmSelect>
                        </div>
                        <div className="theme-add-wrapper" onClick={this.handleAddSurvey.bind(this)}>
                            <img src={add_icon} alt="" />
                            新增调研计划
                        </div>
                    </div>
                    <div className="theme-card-row">
                        <div className="card">
                            <div className="card-title-wrapper">
                                <div>调研主题：</div>
                                <div>{this.state.survey_theme.title}</div>
                            </div>
                            <div className="card-content-wrapper">
                                <div dangerouslySetInnerHTML={{ __html: this.state.survey_theme.content }}></div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title-wrapper">
                                <div>调研人群范围：</div>
                            </div>
                            <div className="card-content-wrapper">
                                {
                                    this.state.survey_target_audience.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                {item.title}:{item.content}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-title-wrapper">
                                <div>调研时间</div>
                            </div>
                            <div className="card-content-wrapper">
                                <div className="date-wrapper">
                                    <div>开始时间：</div>
                                    <div>{this.state.survey_date.survey_start_time}</div>
                                </div>
                                <div className="date-wrapper">
                                    <div>结束时间：</div>
                                    <div>{this.state.survey_date.survey_end_time}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 题目数量+ 年龄地域参与 */}
                <div className="other-tip-tow">
                    <div className="tip-card tip-left-card">
                        <div className='tip-card-list'>
                            <img src={overall_icon_intro1} alt="" />
                            <div>
                                <span>题目数量</span>
                                <span>{this.state.survey_tip.question_num}</span>
                            </div>
                        </div>
                        <div className='tip-card-list'>
                            <img src={overall_icon_intro2} alt="" />
                            <div>
                                <span>完成调研人数</span>
                                <span>{this.state.survey_tip.finished_num}</span>
                            </div>
                        </div>
                        <div className='tip-card-list'>
                            <img src={overall_icon_intro3} alt="" />
                            <div>
                                <span>平均答题时长</span>
                                <span>{this.state.survey_tip.avg_answer_duration}</span>
                            </div>
                        </div>
                    </div>
                    {
                        this.state.dispatch_chart_list.map((item, index) => {
                            return (
                                <div className="tip-card join-age-card" key={index}>
                                    <DispatchSingleChart key={"b" + index} dataList={item} single_id={`overall_dispatch_single_chart_${index}`}></DispatchSingleChart>
                                </div>
                            )
                        })
                    }
                </div>
                {/* 固定筛选图表 */}
                <div className="filter-chart-container">
                    <div className="filter-container-fixed">
                        <FilterSelect ref={this.filterRef} searchFilter={this.searchFilter.bind(this)}></FilterSelect>
                        <Tooltip placement="top" title="下载">
                            <div className="download-wrapper" onClick={this.handleDownload.bind(this)}>
                                <img src={download_icon} alt="" />
                            </div>
                        </Tooltip>
                    </div>
                    <div className="chart-container">
                        {
                            this.state.survey_chart_list.length === 0 ?
                                ""
                                :
                                <div className="batch-download-btn-wrapper">
                                    <div className="batch-download-btn" onClick={this.batchDownload.bind(this)}>批量下载</div>
                                </div>
                        }
                        {
                            this.state.survey_chart_list.map((item, index) => {
                                return this.chooseSingleChart(item, index)
                            })
                        }
                        {
                            this.state.survey_chart_list.length === 0 ?
                                <div className="rank-content-empty">
                                    <ConfigProvider locale={zhCN}>
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                    </ConfigProvider>
                                </div>
                                :
                                ""
                        }
                    </div>
                </div>
            </div>
        )
    }
}
