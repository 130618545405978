import React, { Component } from 'react'
import './download_brief.scss';
import back_btn_icon from '../../../images/home_page/about_us/back_btn_icon.png'
import download_icon from '../../../images/overall_data/download_icon.png'

export default class download_brief extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            description: "",
            upload_time: ""
        }
    }
    setDowloadBrief(obj) {
        let { name, description, upload_time, location } = obj
        this.setState({
            name: name,
            description: description,
            upload_time: upload_time,
            location: location
        })
    }
    clickBackBtn() {
        this.props.canShowDownloadBrief(false)
    }
    // 立即下载文件
    handleDownload() {
        let fileLink = document.createElement('a');
        fileLink.href = this.state.location;
        fileLink.target = "_blank";
        fileLink.click();
    }
    render() {
        return (
            <div className="download-brief-container">
                <div className="download-brief-content">
                    <div className='download-title-wrapper'>
                        <div className='title'>市场分析报告</div>
                        <div className='btn-wrapper'>
                            <div className='btn' onClick={this.handleDownload.bind(this)}>
                                <img src={download_icon} alt="" />
                                <span>下载</span>
                            </div>
                            <div className='btn' onClick={this.clickBackBtn.bind(this)}>
                                <img src={back_btn_icon} alt="" />
                                <span>返回</span>
                            </div>
                        </div>
                    </div>
                    <div className="download-brief-wrapper">
                        <div className="name">
                            {this.state.name}
                        </div>
                        <div className="time">
                            {this.state.upload_time}
                        </div>
                        <div className="description" dangerouslySetInnerHTML={{ __html: this.state.description }}></div>
                    </div>
                </div>
            </div>
        )
    }
}
