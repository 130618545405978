// eslint-disable-next-line import/no-anonymous-default-export
export default {
    profile_pic: { filename: "", file: "" },
    phone: "",//手机
    first_name: "",
    brand: "",
    company: "",
    department: "",
    position: "",
    account_type: "",
    balance: "",
    quota: "",
    desired_info: [],
    sms_code: ""
}