import React, { Component } from 'react'
import "./matrix_nps_template_info.scss"

export default class matrix_nps_template_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'matrix_nps_info',               //从哪个页面进入的
            dataList: {},
            min_value: 0,
            max_value: 10,
            min_name: "",
            max_name: "",
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                min_value: parseInt(props.dataList.min_value),
                max_value: parseInt(props.dataList.max_value) || "",
                min_name: props.dataList.min_name || state.min_name,
                max_name: props.dataList.max_name || state.max_name,
            }
        }
        return null;
    }
    componentDidMount() {
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
        this.setState = () => { }
    }
    render() {
        let items = [];
        for (let i = 1; i <= this.state.max_value; i++) {
            items.push(<span key={i}>{i}</span>);
        }
        return (
            <div className='matrix-nps-template-info'>
                <div className="matrix-nps-info-top">
                    <div className="info-top-name-wrap">
                        <div className="info-top-name">{this.state.min_name}</div>
                        <div className="info-top-name">{this.state.max_name}</div>
                    </div>
                    <div className="info-top-value-wrap">
                        <div className={this.state.max_value < 10 ? "draw-slip-index" : "isHide"}>
                            {
                                items
                            }
                        </div>
                        <div className={this.state.max_value === 10 ? "draw-slip-index" : "isHide"}>
                            <span>1</span>
                            <span>2</span>
                            <span>4</span>
                            <span>6</span>
                            <span>8</span>
                            <span>10</span>
                        </div>
                        <div className={this.state.max_value > 10 ? "draw-slip-index" : "isHide"}>
                            <span>1</span>
                            <span>20</span>
                            <span>40</span>
                            <span>60</span>
                            <span>80</span>
                            <span>100</span>
                        </div>
                    </div>
                </div>
                <div className="matrix-score-info-answer">
                    {
                        this.state.dataList.answers.map((item, index) => {
                            return (
                                <div className="draw-slip" key={index}>
                                    <div className="draw-slip-left draw-slip-left-border">
                                        <div className='draw-slip-title'>
                                            <div className="edit-question-title-wrap">
                                                <div className='edit-question-title'>{item.name}</div>
                                            </div>
                                            <div className={`${item.image.val ? "upload-question-pic" : "isHide"}`}>
                                                <img src={item.image.val} alt="" />
                                            </div>
                                        </div>
                                        <div className="draw-slip-top">
                                            <div className='draw-slip-line'>
                                                <span></span>
                                            </div>
                                            <span className='draw-slip-num'>0</span>
                                        </div>
                                    </div >
                                </div>
                            )
                        })
                    }
                </div >
            </div>
        )
    }
}
