import React, { Component } from 'react'
import './Dispatch_date_chart.scss';
import { getBarOption } from "../../../util/Chart_config/Date_chart_config";
import { formatChart } from "../../../util/Chart_config/Config";

// import { DatePicker, Space } from 'antd';
// import moment from 'moment';
// import { getOverallData } from '../../../api/panelApi'

export default class Dispatch_date_chart extends Component {

    constructor(props) {
        super(props);
        //   控制提示框的样式
        this.state = {
            name: 'Overall_data',
            product_id: localStorage.getItem('product_id') ? localStorage.getItem('product_id') : 11,    //产品ID
            product_brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : 23,   //品牌ID
            date_type_show: '', //城市派送
            date_type_list: [{ id: 1, text: '选项一' }, { id: 2, text: '选项二' }, { id: 3, text: '选项三' }, { id: 4, text: '选项四' }],
            date_type_text: '',
            date_type_val: '',
            dispatch_product_status: props.dispatch_product_status,
            needRefresh: false,
        };
    }
    compareDate(date1, date2) {
        var oDate1 = new Date(date1);
        var oDate2 = new Date(date2);
        if (oDate1.getTime() > oDate2.getTime()) {
            return 1
        } else {
            return 2
        }
    }
    toInitChart() {
        let res = this.state.dispatch_product_status
        let option = getBarOption(res)
        let id = 'date_echart'
        formatChart(id, option)
    }
    // getSnapshotBeforeUpdate() {
    //     return null
    // }
    componentDidMount() {
        this._isMounted = true
    }
    componentDidUpdate() {
        if (this.state.dispatch_product_status.length && this._isMounted && this.state.needRefresh) {
            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
        return true
    }
    componentWillUnMount = () => {
        this._isMounted = false;
        this.setState = (state, callback) => {
            return;
        };

    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dispatch_product_status) !== JSON.stringify(state.dispatch_product_status_prev)) {
            return {
                needRefresh: true,
                dispatch_product_status: props.dispatch_product_status,
                dispatch_product_status_prev: props.dispatch_product_status,
            };
        }
        return null;

    }
    //点击下拉框，设置state的值
    handleClick(t, index, event) {
        if (!t) return false
        if (!event) return false
        this.setState({
            [t + '_text']: this.state[t + '_list'][index].text,
            [t + '_val']: this.state[t + '_list'][index].id,
            [t + '_show']: !this.state[t + '_show']
        })
    }
    // 是否显示
    handle_show(t, event) {
        this.setState({
            [t + '_show']: !this.state[t + '_show']
        })
    }
    //时间改变的方法
    onPickerChange = (date, dateString) => {
        //这两个参数值antd自带的参数
        this.setState({
            startTime: dateString[0],
            endTime: dateString[1],
        })
    }
    render() {
        // const { RangePicker } = DatePicker;
        // const dateFormat = 'YYYY-MM-DD' || undefined;
        return (
            <div className='pannel-card pannel-card-date'>
                <div className='card-title-wrapper'>
                    <div className='title-left'>派送产品</div>
                    {/* <div className='title-right'>
                        <RangePicker
                            className='range-picker'
                            onChange={this.onPickerChange}
                            value={this.state.startTime === undefined || this.state.endTime === undefined || this.state.startTime === "" || this.state.endTime === "" ? null : [moment(this.state.startTime, dateFormat), moment(this.state.endTime, dateFormat)]}
                            format={dateFormat}
                            placeholder={['开始时间', '结束时间']}
                        />
                    </div> */}
                </div>
                {
                    this.state.dispatch_product_status.length ? <div id="date_echart" className="echart-container date-echart"></div> : ""
                }

            </div>
        )
    }
}
