import React from 'react';
import './Login.scss';
import QRCode from 'qrcode.react';
import code_icon from '../images/login/code_icon.png';
import login_logo from '../images/login/login_logo.png';
import login_list_img from '../images/login/login_list_img.png';
import login_wx_img from '../images/login/login_wx_img.png';
import login_wx_icon from '../images/login/login_wx_icon.png';
import login_tel_img from '../images/login/login_tel_img.png';
import login_tel_icon from '../images/login/login_tel_icon.png';

import axiosInstance from '../api/APIUtils';
// import api from '../api/config';
import { setToken, setRefreshToken, setBrandID, getBrandID, setUserStatus, getUserStatus, removeUserStatus, setBrandInfoPopupStatus, getBrandInfoPopupStatus, removeBrandInfoPopupStatus, setLoginType, getLoginType } from '../util/auth'
import { login, loginComment } from '../api/token'
import { getSmsVerification, getBrandAuthInfo, saveBrandAuthInfo, WXLogin, getWXLoginQRCode } from '../api/token'

// import { useNavigate } from 'react-router-dom';
import { Input, Button, Form, message } from 'antd';
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

let that = ''
function WithNavigate(props) {
  // let navigate = useNavigate();
  return <Login {...props} />
}

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      phone_number: '',//用户手机号
      sms_code: '',//验证码
      code_button_disabled: false,//禁止
      code_button_loading: false,//加载
      code_button_text: '获取验证码',//验证码文字
      companyName: '',
      companyPhone: '',
      companyJob: '',
      companyBrand: '',
      companyComment: '',
      fingerPrintStr: '',
      is_wx_login: true,
      qr_code_url: "",// 二维码链接
      scene_str: "",// 轮询拿着的str
      openid: "", // 微信扫码拿到的openid
    };
    this.handleChange = this.handleChange.bind(this);

  }


  handleChange(event) {
    let target_name = event.target.name;
    this.setState({ [target_name]: event.target.value });
  }

  componentDidMount() {
    // init 
    that = this
    this.init()
    //生成浏览器指纹
    this.handleFinger()
    // this.props.history.push('/home_page')
    // this.props.history.push('/home')
    // let navigate = useNavigate();
    // console.log(navigate)
  }
  componentDidUpdate(preProps, preState) {
    if (preState.is_wx_login !== this.state.is_wx_login) {
      if (!this.state.is_wx_login) { // 短信验证码
        // 清空
        clearInterval(that.loginInterval)
      } else {
        that.loginInterval = setInterval(that.WXLogin, 3000)
      }
    }
    // if (preProps.single_id !== this.props.single_id || preProps.dataList !== this.props.dataList) {
    //   this.setState({
    //     dataList: this.props.dataList,
    //     single_id: this.props.single_id
    //   }, () => {
    //     this.init()
    //   })
    // }
  }
  componentWillUnmount() {
    clearInterval(that.loginInterval)
    this.setState = () => { }
  }
  // 初始化
  init() {
    // 微信扫码登录 获取二维码链接
    this.getWXLoginQRCode()
  }
  // 处理登陆2
  async login_phone(t) {//验证并成功跳转
    setLoginType(t)
    if (t === "phone_login") {
      if (!this.state.phone_number) {
        message.warning('请输入手机号', 2, () => { })
        return
      } else if (!this.isPhone(this.state.phone_number)) {
        message.warning('请输入正确的手机号', 2, () => { })
        return
      } else if (!this.state.sms_code) {
        message.warning('请输入验证码', 2, () => { })
        return
      } else if (!this.isSmsCode(this.state.sms_code)) {
        message.warning('请输入正确的验证码', 2, () => { })
        return
      }
    }
    try {
      const response = await login({
        username: "cem_user",
        password: "Matchbox123*",
        fingerPrint: this.state.fingerPrintStr,
        sms_code: this.state.sms_code,
        phone_number: this.state.phone_number,
        login_type: t === "phone_login" ? 1 : 2,
        openid: this.state.openid
      })
      const data = response.data;
      // console.log('在Login.js中获取 token', response, data)
      axiosInstance.defaults.headers['Authorization'] = "JWT " + data.access; //可以没有
      setToken(data.access)
      setRefreshToken(data.refresh)

      //设置brandID
      const payloadBase64 = data.access.split('.')[1];
      const decodedJson = Buffer.from(payloadBase64, 'base64').toString();
      const decoded = JSON.parse(decodedJson)
      setBrandID(decoded.brand_id)

      message.success('进入首页成功', 0.7, () => {
        this.props.history.push('/home_page');

      })
      return data;
    } catch (err) {
      if (err.response?.data?.detail) {
        message.error(`${err.response.data.detail}`, 2, () => { })
      } else {
        message.error('登录失败')
      }
      throw err
    }
  }
  //处理指纹
  handleFinger() {
    // Initialize an agent at application startup.
    const fpPromise = FingerprintJS.load({
      apiKey: 'cAXv1fHzCDA48vvDSfFq', region: 'ap'
    })
    // Get the visitor identifier when you need it.
    fpPromise
      .then(fp => fp.get())
      .then(result => {
        const visitorId = result.visitorId
        this.setState({ fingerPrintStr: visitorId })
      })
      .catch(err => {
        console.error(err)
      })
  }
  // 只能输入数字的字符
  validateNumber(event) {
    event.target.value = event.target.value.replace(/^[^\d]+/g, '')
  }
  // 选择登录方式 是微信还是账号
  handleLoginStyle(t) {
    if (t === 'phone_login' && !this.state.is_wx_login) {
      return
    } else if (t === 'wx_login' && this.state.is_wx_login) {
      return
    }
    this.setState({
      is_wx_login: !this.state.is_wx_login
    }, () => {
      if (!this.state.is_wx_login) {
        clearInterval(that.loginInterval)
      }
    })
  }
  // 输入手机号
  handlePhone(event) {
    this.setState({
      phone_number: event.target.value
    })
  }
  // 输入验证码
  handleSmsCode(event) {
    this.setState({
      sms_code: event.target.value
    })
  }
  // 获取短信验证码
  sendCode(t) {
    if (!this.isPhone(this.state.phone_number)) {
      message.warning('请输入正确的手机号', 2, () => { })
      return
    }
    this.setState({
      code_button_loading: true,
      code_button_text: "发送中",
    }, () => {
      let expire = 180
      if (t === "phone_login") {
        expire = 60
      }
      this.getSmsVerification(expire)
    })
  }
  // 判断手机号
  isPhone(val) {
    return /^1[0-9]{10}$/.test(val);
  }
  // 判断验证码
  isSmsCode(val) {
    return /^[0-9]{6}$/.test(val);
  }
  // 手机获取验证码（登录）
  async getSmsVerification(expire) {//验证并成功跳转
    try {
      const response = await getSmsVerification({
        phone_number: this.state.phone_number,
        expire: expire
        // phone_number: "17717281798",
      })
      this.countDown(expire)
      return null;
    } catch (error) {
      this.setState({
        code_button_loading: false,
        code_button_text: "重新获取"
      })
      message.error('请重新获取验证码!')
      throw error;
    }
  }
  // 倒计时
  countDown = (expire) => {
    // 定时器
    let timer = null
    // 倒计时时间
    let sec = expire
    //修改状态
    this.setState({
      code_button_loading: false,
      code_button_disabled: true,
      code_button_text: `${sec}s`
    })

    timer = setInterval(() => {
      sec--;
      if (sec <= 0) {
        this.setState({
          code_button_text: `重新获取`,
          code_button_disabled: false,
        })
        clearInterval(timer);
        return false
      }
      this.setState({
        code_button_text: `${sec}s`
      })
    }, 1000)
  }
  // 获取微信公众号登录二维码（登录） 
  // 获取二维码链接
  async getWXLoginQRCode() {

    try {
      const response = await getWXLoginQRCode({})
      if (!response) return
      let qr_code_url = response.data.qr_code
      let scene_str = response.data.scene_str
      // debugger
      this.setState({
        qr_code_url: qr_code_url,
        scene_str: scene_str
      }, () => {
        // 将链接转换为二维码
        // debugger
        that.loginInterval = setInterval(that.WXLogin, 3000)
      })
      return response
    } catch (error) {
      message.error('获取二维码失败')
      throw error;
    }
  }
  // 微信登陆轮询
  async WXLogin() {
    try {
      const response = await WXLogin({
        scene_str: that.state.scene_str
      })
      if (!response) return
      let status = response.data.status
      let openid = response.data.openid
      let message = response.data.message // 1是ok
      if (message === 1) {
        clearInterval(that.loginInterval)
        that.setState({
          openid: openid
        }, () => {
          that.login_phone('wx_login')
        })
      }
      return response
    } catch (error) {
      clearInterval(that.loginInterval)
      message.error('获取二维码失败')
      throw error;
    }
  }
  // 平台服务协议
  clickUserAgreement = () => {
    let tmpwindow = window.open('_blank');
    tmpwindow.location = '/user_agreement'; // 可以打开新的地址
  }
  // 隐私协议
  clickPrivacyAgreement = () => {
    let tmpwindow = window.open('_blank');
    tmpwindow.location = '/privacy_agreement'; // 可以打开新的地址
  }
  render() {
    const prefix_1 = (
      <span className='phone-input-prefix'>+86</span>
    )
    return (
      <div className="login-container">
        <div className="login-tip">心盒共创·一站式新品共创平台</div>
        <div className="login-content">
          <div className="login-left">
            <div className="logo">
              <img src={login_logo} alt="" />
            </div>
            <div className="login-left-title">
              <div>即刻开始</div>
              <div>与海量消费者实现产品共创</div>
            </div>
            <div className="login-left-list">
              <div className="login-left-list-item">
                <img src={login_list_img} alt="" />
                <div>聆听市场最新需求</div>
              </div>
              <div className="login-left-list-item">
                <img src={login_list_img} alt="" />
                <div>新品研发的最新趋势</div>
              </div>
              <div className="login-left-list-item">
                <img src={login_list_img} alt="" />
                <div>为产品孵化实现快速决策</div>
              </div>
            </div>
          </div>
          <div className="login-right">
            <div className="login-info">
              {
                this.state.is_wx_login ?
                  <div className='login-card-wrapper wx-login-card-wrapper'>
                    <div className="login-card-title-wrapper">
                      <img src={login_wx_img} alt="" />
                      <div>微信扫码登录/注册</div>
                    </div>
                    <div className="login-card-tip">
                      关注心盒科技体验共创公众号进行注册
                    </div>
                    <div className="login-qrcode">
                      <QRCode value={this.state.qr_code_url} size={211} className="qrcode-img" />
                    </div>
                  </div> :
                  <div className='login-card-wrapper phone-login-card-wrapper'>
                    <div className="login-card-title-wrapper">
                      <img src={login_tel_img} alt="" />
                      <div>验证登录/注册</div>
                    </div>
                    <div className="phone-login-card">
                      <div className="card-item">
                        <div className="item-input-wrapper">
                          <Input prefix={prefix_1} className="phone-input" value={this.state.phone_number} placeholder="请输入手机号" onChange={this.handlePhone.bind(this)} onInput={this.validateNumber.bind(this)} maxLength={11} />
                        </div>
                      </div>
                      <div className="card-item">
                        <div className="item-input-wrapper">
                          <Input className="sms-input" placeholder="请输入短信验证码" value={this.state.sms_code} onChange={this.handleSmsCode.bind(this)} maxLength={6} onInput={this.validateNumber.bind(this)} />
                          <Button className="sms-btn" onClick={this.sendCode.bind(this, "phone_login")} disabled={this.state.code_button_disabled} loading={this.state.code_button_loading}>{this.state.code_button_text}</Button>
                        </div>
                      </div>
                      <div className="login-btn-wrapper" onClick={this.login_phone.bind(this, "phone_login")}>
                        登录
                      </div>
                      <div className="phone-login-tip">未注册手机号自动创建心盒共创账号</div>
                    </div>
                  </div>
              }
              <div className="login-method-tip-wrapper">
                <div className="login-method-tip-line"></div>
                <div className='login-method-tip-text'>其他方式登录</div>
                <div className="login-method-tip-line"></div>
              </div>
              {
                this.state.is_wx_login ?
                  <div className="login-method-wrapper">
                    <img src={login_tel_icon} alt="" onClick={this.handleLoginStyle.bind(this, 'phone_login')} />
                    <div onClick={this.handleLoginStyle.bind(this, 'phone_login')}>手机验证</div>
                  </div> : <div className="login-method-wrapper">
                    <img src={login_wx_icon} alt="" onClick={this.handleLoginStyle.bind(this, 'wx_login')} />
                    <div onClick={this.handleLoginStyle.bind(this, 'wx_login')}>微信登录</div>
                  </div>
              }
            </div>
            <div className="login-info-bottom">
              登录或注册即同意 <span onClick={this.clickUserAgreement}>平台服务协议</span>、<span onClick={this.clickPrivacyAgreement}>隐私政策</span>
            </div>
          </div>
        </div>
      </div >
    )

  }
}

export default WithNavigate;
