import React, { Component } from 'react'
import { Table, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import "./table_list.scss"
export default class chart_list extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chart_list: [],
            dataList: {},
            needRefresh: false,
            columns: [],
            appendix: []
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList,
                question_type: props.questionType
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {

            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }


    toInitChart() {
        let columns = this.props.dataList.data_table_obj.columns || [];
        let chart_list = this.props.dataList.data_table_obj.data_list || [];
        let appendix = this.props.dataList.data_table_obj.appendix || [];
        // 处理index，让index不显示出来
        columns.forEach((item, index) => {
            if (item.title === "index") {
                item.title = ""
            }
        })
        this.setState({
            columns: columns,
            chart_list: chart_list,
            appendix: appendix
        })
    }
    showPagination() {
        if (this.state.chart_list.length < 30) {
            return false
        }
        return { pageSize: 30, showSizeChanger: false, showQuickJumper: true, size: "small" }
    }
    render() {
        return (
            <div className="table-list-container">
                <ConfigProvider locale={zhCN}>
                    <Table
                        dataSource={this.state.chart_list}
                        columns={this.state.columns}
                        pagination={this.showPagination()}
                        rowKey={columns => columns.id}
                        scroll={{ y: this.props.isMaxChart ? this.props.blankListHeight : 340 }} />
                </ConfigProvider>
                <div className="appendix">
                    <ul>
                        {this.state.appendix.map(d => (<li key={d.id}>{d.name}: {d.content}</li>))}
                    </ul>
                </div>
            </div>

        )
    }
}
