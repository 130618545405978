import React, { Component } from 'react'
import './ring_chart.scss';
import { formatChart } from "../../../util/Chart_config/Config";
import { getRingOption } from "../../../util/Chart_config/ring_chart_config";


export default class ring_chart extends Component {
    constructor(props) {
        super(props);
        this.Xindex = "abc"
        this.state = {
            flag: 1,
            dataList: {},
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList, //question
                dataList_prev: props.dataList,
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    toInitChart() {
        let id = this.props.single_id
        let data = this.props.dataList.data
        let sum = this.props.dataList.sum
        let option = getRingOption(data, sum)
        formatChart(id, option)
    }
    render() {
        let single_id = String(this.props.single_id) || ""
        return (
            <div>
                <div id={single_id} className={`echart-container ring-echart comment_${single_id}`}></div>
            </div>
        )
    }
}
