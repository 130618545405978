import "./single_template_info.scss"
import React, { Component } from 'react'
import cur_icon_radio_nor from "../../../../../../images/cur_icon_radio_nor.png"

export default class single_template_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataList: {},
            answers: [],
            question_name: ""
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                answers: props.dataList.answers || state.answers,
            }
        }
        return null;
    }
    render() {
        return (
            <div className="single-template-info">
                {
                    this.state.answers.map((item, index) => {
                        return <div className="single-item-wrapper" key={index}>
                            <div className="single-item-left">
                                <img src={cur_icon_radio_nor} alt="" className="setting-radio-icon" />
                            </div>
                            <div className="single-item-right">
                                <div className="single-item-title-wrapper">
                                    <div className="single-item-title">{`${!item.is_other ? item.name : "其他"}`}</div>
                                    <div className={`${item.image.val ? "" : "isHide"}`}>
                                        <img src={item.image.val} alt="" />
                                    </div>
                                </div>
                                {
                                    item.is_other ? <div className="single-item-other-input"></div> : ""
                                }
                            </div>
                        </div>
                    })
                }
            </div>
        )
    }
}
