export const translate_language = {
  // 1: "Chinese",
  2: "English",
  3: "French",
  4: "Japanese",
  5: "Russian",
  6: "Korean",
};
export const retranslate_text = {
  // "Chinese": "重新翻译",
  English: "retranslate",
  French: "Retraduire la traduction",
  Japanese: "翻訳し直します",
  Russian: "Переведи",
  Korean: "새로 번역하다",
};

// 根据key 获取value
export function getLanguageName(key) {
  if (!Object.prototype.hasOwnProperty.call(translate_language, key)) {
    return -1;
  }
  return translate_language[key];
}

// 根据key 获取value
export function getRetranslateText(key) {
  if (!Object.prototype.hasOwnProperty.call(retranslate_text, key)) {
    return -1;
  }
  return retranslate_text[key];
}

// 日期
export function formatDate(date, fmt) {
  date = new Date(date);
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (date.getFullYear() + "").substring(4 - RegExp.$1.length)
    );
  }
  let o = {
    "M+": date.getMonth() + 1,
    "d+": date.getDate(),
    "h+": date.getHours(),
    "m+": date.getMinutes(),
    "s+": date.getSeconds(),
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + "";
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1 ? str : padLeftZero(str)
      );
    }
  }
  return fmt;
}
function padLeftZero(str) {
  return ("00" + str).substring(str.length);
}

export function validatePureNumber(num) {
  num = num.replace(/^0[0-9]+/g, 0);
  num = num.replace(/[^/.\d]/g, "");
  num = num.replace(".", "");
  return num;
}
