import React, { Component } from 'react'
import "./filter_select.scss"
import search_icon from '../../../images/overall_data/search_icon.png'
import clear_icon from '../../../images/overall_data/clear_icon.png'
import XmSelect from '../../../components/Select/xm_select';
import { message, Select } from "antd";

import { DatePicker, Tooltip } from 'antd';
import moment from 'moment';
export default class filter_select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            region_list: [],
            selected_region_key_list: [],
            selected_region_list: [],
            gender_list: [{ key: 0, value: "女" }, { key: 1, value: "男" }],
            selected_gender_key_list: [],
            selected_gender_list: [],
            age_list: [
                { key: '0-18', value: '0-18' },
                { key: '19-22', value: '19-22' },
                { key: '23-27', value: '23-27' },
                { key: '28-35', value: '28-35' },
                { key: '35+', value: '35+' }
            ],
            selected_age_key_list: [],
            selected_age_list: [],
            start_time: "",
            end_time: "",
            filter_start_date: "",//筛选的开始时间
            filter_end_date: "",//筛选的结束时间
        }
    }
    componentDidMount() { }
    setFilterList(obj) {
        let { region_list, filter_start_date, filter_end_date, is_init_filter } = obj;
        let selected_region_key_list = this.state.selected_region_key_list
        let selected_gender_key_list = this.state.selected_gender_key_list
        let selected_age_key_list = this.state.selected_age_key_list
        let selected_region_list = this.state.selected_region_list
        let selected_gender_list = this.state.selected_gender_list
        let selected_age_list = this.state.selected_age_list
        if (is_init_filter) {
            selected_region_key_list = []
            selected_gender_key_list = []
            selected_age_key_list = []
            selected_region_list = []
            selected_gender_list = []
            selected_age_list = []
        }
        this.setState({
            region_list: region_list,
            selected_region_key_list: selected_region_key_list,
            selected_gender_key_list: selected_gender_key_list,
            selected_age_key_list: selected_age_key_list,
            selected_region_list: selected_region_list,
            selected_gender_list: selected_gender_list,
            selected_age_list: selected_age_list,
            filter_start_date: filter_start_date,
            filter_end_date: filter_end_date
        })
    }
    // 渲染地域下拉框
    handleRegionListOption() {
        return this.state.region_list
    }
    // 渲染默认的题目
    handleDefaultRegionListOption() {
        return this.state.selected_region_key_list
    }
    // 下拉框搜索可以大小写
    filterSelectOption(input, option) {
        return option.children.props.children.toString().indexOf(input.toString()) >= 0
    }
    //下拉框选择后，并赋值到state里面 
    onRegionSelectChange(key_arr) {
        let selected_region_list = []
        for (let i = 0; i < key_arr.length; i++) {
            selected_region_list.push(this.state.region_list[key_arr[i]].value)
        }
        this.setState({
            selected_region_key_list: key_arr,
            selected_region_list: selected_region_list
        })
    }
    // 渲染gender下拉框
    handleGenderListOption() {
        return this.state.gender_list
    }
    // 渲染gender默认的题目
    handleDefaultGenderListOption() {
        return this.state.selected_gender_key_list
    }
    //下拉框选择后，并赋值到gender state里面 
    onGenderSelectChange(key_arr) {
        let selected_gender_list = []
        for (let i = 0; i < key_arr.length; i++) {
            selected_gender_list.push(this.state.gender_list[key_arr[i]].value)
        }
        this.setState({
            selected_gender_key_list: key_arr,
            selected_gender_list: selected_gender_list
        })
    }

    // 清空所有的
    clearFilter() {
        this.setState({
            selected_region_key_list: [],
            selected_gender_key_list: [],
            selected_age_key_list: [],
            selected_region_list: [],
            selected_gender_list: [],
            selected_age_list: [],
            start_time: "",
            end_time: ""
        })
        let filter_obj = {
            selected_region_list: [],
            selected_gender_list: [],
            selected_age_list: [],
            start_time: "",
            end_time: ""
        }
        this.props.searchFilter(filter_obj)
    }
    // 搜索的filter
    searchFilter() {
        let filter_obj = {
            selected_region_list: this.state.selected_region_list,
            selected_gender_list: this.state.selected_gender_list,
            selected_age_list: this.state.selected_age_list,
            start_time: this.state.start_time,
            end_time: this.state.end_time
        }
        this.props.searchFilter(filter_obj)
    }
    //验证数字
    validateNumber(event) {
        if (event.target.value === "") {
            return
        }
        event.target.value = event.target.value.replace(/^0[0-9]+/g, 0);
        event.target.value = event.target.value.replace(/[^/\-+\d]/g, "");

    }
    // 渲染age下拉框
    handleAgeListOption() {
        return this.state.age_list
    }
    // 渲染age默认的题目
    handleDefaultAgeListOption() {
        return this.state.selected_age_key_list
    }
    //下拉框选择后，并赋值到age state里面 
    onAgeSelectChange(key_arr) {
        if (key_arr.length === 0) {
            this.setState({
                selected_age_key_list: key_arr,
                selected_age_list: key_arr,
            })
            return
        }
        let age = String(key_arr[key_arr.length - 1])
        if (this.isConnectAge(age)) {
            if (age.indexOf('-') >= 0) {
                let [age_s, age_e] = age.split("-")
                if (Number(age_s) === 0 && Number(age_e) === 18) {
                    // ok
                } else if (Number(age_s) < 15 || Number(age_s) > 70) {
                    message.warning('起始年龄最小15岁,最大70岁')
                    return
                } else if (Number(age_e) < 15 || Number(age_e) > 70) {
                    message.warning('结束年龄最小15岁,最大70岁')
                    return
                } else if (Number(age_s) > Number(age_e)) {
                    message.warning('起始年龄需要大雨结束年龄')
                    return
                }
            } else if (age.indexOf('+') >= 0) {
                let [age_s] = age.split("+")
                if (Number(age_s) < 15 || Number(age_s) > 70) {
                    message.warning('年龄最小15岁,最大70岁')
                    return
                }
            } else {
                if (Number(age) < 15 || Number(age) > 70) {
                    message.warning('年龄最小15岁,最大70岁')
                    return
                }
            }
            this.setState({
                selected_age_key_list: key_arr,
                selected_age_list: key_arr,
            })
        } else {
            message.warning('年龄格式不正确,请输入20-30或30+或30', 2, () => { })
        }

    }
    // 判断年龄是否输入正确
    isConnectAge(age) {
        let reg = /^((\d)+-?(\d)+)|(\d)+\+$/
        let flag = reg.test(age)
        if (!flag) {
            let reg2 = /^(\d)+\+$/
            flag = reg2.test(age)
        }
        return flag
    }
    //时间改变的方法
    onPickerChange = (date, dateString) => {
        //这两个参数值antd自带的参数
        this.setState({
            start_time: dateString[0],
            end_time: dateString[1],
        })
    }
    disabledDate = (current) => {
        let filter_start_date = String(this.state.filter_start_date)
        let filter_end_date = String(this.state.filter_end_date)
        // let filter_start_date = "2022-09-19"
        // let filter_end_date = "2022-10-20"
        return current && (current > moment(filter_end_date).endOf('day') || current < moment(filter_start_date).startOf('day'))
    }
    render() {
        const { RangePicker } = DatePicker;
        const dateFormat = 'YYYY-MM-DD HH:mm:ss' || undefined;
        return (
            <div className="filter-select-container">
                {/* <div className="region-wrapper">
                    <div className="region-name">地域</div>
                    <div className="region-select">
                        <XmSelect
                            mode="multiple" placeholder="请选择地域" onChange={(e) => { this.onRegionSelectChange.call(this, e) }} maxTagCount={'responsive'} allowClear
                            options={this.handleRegionListOption.call(this)}
                            value={this.handleDefaultRegionListOption.call(this)}
                            filterOption={(input, option) => {
                                return this.filterSelectOption.call(this, input, option)
                            }
                            }
                        >
                        </XmSelect>
                    </div>
                </div>
                <div className="gender-wrapper">
                    <div className="gender-name">性别</div>
                    <div className="gender-select">
                        <XmSelect
                            mode="multiple" placeholder="请选择性别" onChange={(e) => { this.onGenderSelectChange.call(this, e) }} maxTagCount={'responsive'} allowClear
                            options={this.handleGenderListOption.call(this)}
                            value={this.handleDefaultGenderListOption.call(this)}
                            filterOption={(input, option) => {
                                return this.filterSelectOption.call(this, input, option)
                            }
                            }
                        >
                        </XmSelect>
                    </div>
                </div>
                <div className="age-wrapper">
                    <div className="age-name">年龄</div>
                    <div className="age-select">
                        <XmSelect
                            mode="tags" placeholder="请输入年龄,如20-30或35+" onChange={(e) => { this.onAgeSelectChange.call(this, e) }} maxTagCount={'responsive'} allowClear
                            options={this.handleAgeListOption.call(this)}
                            value={this.handleDefaultAgeListOption.call(this)}
                            onInput={this.validateNumber.bind(this)}
                            iskey="true"
                        >
                        </XmSelect>
                    </div>
                </div> */}
                <div className="date-wrapper">
                    <div className="date-name">回答时间</div>
                    <div className="date-select">
                        <RangePicker
                            showTime={{ format: 'HH:mm:ss' }}
                            className='range-picker'
                            onChange={this.onPickerChange}
                            value={this.state.start_time === undefined || this.state.end_time === undefined || this.state.start_time === "" || this.state.end_time === "" ? null : [moment(this.state.start_time, dateFormat), moment(this.state.end_time, dateFormat)]}
                            format={dateFormat}
                            disabledDate={this.disabledDate}
                            placeholder={['开始时间', '结束时间']}
                        />
                    </div>
                </div>
                <div className="operate-wrapper">
                    <Tooltip placement="top" title="搜索">
                        <div className="search-wrapper" onClick={this.searchFilter.bind(this)}>
                            <img src={search_icon} alt="" />
                        </div>
                    </Tooltip>
                    <Tooltip placement="top" title="清空">
                        <div className="clear-wrapper" onClick={this.clearFilter.bind(this)}>
                            <img src={clear_icon} alt="" />
                        </div>
                    </Tooltip>
                </div>

            </div>
        )
    }
}
