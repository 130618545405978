import React, { Component } from 'react'

export default class Nps_question extends Component {
    constructor(props) {
        super(props)
        this.state = {
            answer: "",
            name: "",
            index: "",
            min_value: "",
            max_value: "",
        }
    }
    static getDerivedStateFromProps(props, state) {
        return {
            answer: props.dataList.answer.value_arr[0],
            name: props.dataList.name,
            index: props.index + 1,
            min_value: props.dataList.answer.min_value,
            max_value: props.dataList.answer.max_value
        };
    }
    componentDidUpdate() {
        this.initNps()
        return true
    }
    componentDidMount() {
        this.initNps()
    }

    componentWillUnMount = () => {
        this.setState = () => { };

    }

    initNps() {
        let line = document.querySelector(`.draw_slip_line_current_${this.state.index}`)
        if (this.state.max_value < 10) {
            let line_val = parseInt(this.state.answer * 100 / this.state.max_value)
            line.style.width = `${line_val}%`
        }
        else if (this.state.max_value === 10) {
            let line_val = this.state.answer * 10
            line.style.width = `${line_val}%`
        } else {
            line.style.width = `${this.state.answer}%`
        }
    }
    render() {
        let items = [];
        for (let i = 0; i <= this.state.max_value; i++) {
            items.push(<span key={i}>{i}</span>);
        }
        return (
            <div className="detail-card">
                <div className="detail-question-wrapper">
                    <div className="detail-question-sign">*</div>
                    <div className="detail-question-name">{`${this.state.index}. ${this.state.name}`}</div>
                </div>
                <div className="detail-answer-wrapper">
                    <div className="detail-nps-answer">
                        <div className='draw_slip'>
                            <div className='draw_slip_top'>
                                <div className='draw_slip_line'>
                                    <span className={`draw_slip_line_current_${this.state.index}`}></span>
                                </div>
                                <span className='draw_slip_num'>{this.state.answer}</span>
                            </div>
                            <div className={this.state.max_value < 10 ? "draw_slip_index" : "isHide"}>
                                {
                                    items
                                }

                            </div>
                            <div className={this.state.max_value === 10 ? "draw_slip_index" : "isHide"}>
                                <span>0</span>
                                <span>2</span>
                                <span>4</span>
                                <span>6</span>
                                <span>8</span>
                                <span>10</span>
                            </div>
                            <div className={this.state.max_value > 10 ? "draw_slip_index" : "isHide"}>
                                <span>0</span>
                                <span>20</span>
                                <span>40</span>
                                <span>60</span>
                                <span>80</span>
                                <span>100</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
