import React, { Component } from 'react'
import { useCallback } from 'react';
import "./question_ai_list.scss"
import { Collapse, Input, ConfigProvider, Empty } from 'antd';
import icon_search from "../../../../../images/product_list/icon_search.png"
import QuestionTemplatePanel from '../../Question_template_panel/question_template_panel';
import zhCN from 'antd/lib/locale/zh_CN';
const { Panel } = Collapse;
const { Search } = Input;

export default class question_ai_list extends Component {
    constructor(props) {
        super(props)
        this.state = {
            search_text: "",
            ai_survey_list: [],
            ai_survey_list_formal: []
        }
    }
    componentDidUpdate(preProps, preState) {
        if (this.props.ai_survey_list.length !== this.state.ai_survey_list_formal.length) {
            this.setState({
                ai_survey_list: this.props.ai_survey_list,
                ai_survey_list_formal: this.props.ai_survey_list
            })
        }
    }
    // 传给父亲
    addTemplateQuestion(item) {
        this.props.addTemplateQuestion(item)
    }
    // 搜索
    onSearch(value, event) {
        if (event.hasOwnProperty("type")) {
            let ai_survey_list = JSON.parse(JSON.stringify(this.state.ai_survey_list_formal))
            ai_survey_list = ai_survey_list.filter(item => {
                let flag1 = false
                if (item.prompt.indexOf(value) !== -1) {
                    flag1 = true
                    item["is_search"] = true
                    item["prompt_for_search"] = item.prompt.replace(value, `<span style='color: #FF5186'>${value}</span>`)
                }
                let flag = false
                for (let i = 0; i < item.question_list.length; i++) {
                    if (item.question_list[i].name.indexOf(value) !== -1) {
                        flag = true
                        item.question_list[i]["is_search"] = true
                        item.question_list[i]["name_for_search"] = item.question_list[i].name.replace(value, `<span style='color: #FF5186'>${value}</span>`)
                    }
                }
                if (flag) return item
                if (flag1) return item
                return null
            })
            this.setState({
                ai_survey_list: ai_survey_list
            })
        } else {
            this.setState({
                search_text: "",
                ai_survey_list: this.state.ai_survey_list_formal,
            })
        }
    }
    // 搜索框输入的值
    handleInput(event) {
        const { value } = event.target;
        this.setState({
            search_text: value
        })
    }
    // 重新获取ai survey 列表
    debounceGetAISurveyList = (fn) => {
        let timer = null;
        let that = this
        return function (e) {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                that.props.getAISurveyList()
            }, 1000);
        }
    };
    render() {
        const prefix = (
            <img src={icon_search} alt="" className='evaluation-icon-search' />
        )
        return (
            <div className='question-ai-list'>
                <div className="question-ai-operaion">
                    <div className="question-ai-search">
                        <Search
                            placeholder="搜索题目"
                            allowClear
                            enterButton="搜索"
                            prefix={prefix}
                            onSearch={this.onSearch.bind(this)}
                            onInput={this.handleInput.bind(this)}
                            value={this.state.search_text}
                        />
                    </div>
                    <div className="question-ai-refresh" onClick={this.debounceGetAISurveyList()}>
                        <img src={require("../../../../../images/project_entry/icon_refresh.png").default} alt='' />
                        <div>刷新AI问卷列表</div>
                    </div>
                </div>
                {
                    this.state.ai_survey_list.length ?
                        <Collapse accordion>
                            {
                                this.state.ai_survey_list.map((item, index) => {
                                    return <Panel header={item.prompt} key={index + 1}>
                                        {/* <p>{item.prompt}</p> */}
                                        <div className='ai-collapse-panel-card'>
                                            <div className='prompt-title-wrapper'>
                                                <span>AI问卷：</span>
                                                {
                                                    item.hasOwnProperty("is_search") && item.is_search ? <span dangerouslySetInnerHTML={{ __html: item.prompt_for_search }}></span> : <span>{item.prompt}</span>
                                                }

                                            </div>
                                            <div className='ai-template-panel'>
                                                <QuestionTemplatePanel
                                                    template_name={`ai-template-${index}`}
                                                    question_template_list={item.question_list}
                                                    addTemplateQuestion={this.addTemplateQuestion.bind(this)}
                                                ></QuestionTemplatePanel>
                                            </div>
                                        </div>
                                    </Panel>
                                })
                            }
                        </Collapse> :
                        <div className="ai-collapse-empty">
                            <ConfigProvider locale={zhCN}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </ConfigProvider>
                        </div>

                }

            </div>
        )
    }
}
