import React, { Component } from 'react'
import "./matrix_score_template_info.scss"
import cur_icon_circle_sel from "../../../../../../images/emotion_analysis/cur_icon_circle_sel.png"
import cur_icon_circle_nor from "../../../../../../images/emotion_analysis/cur_icon_circle_nor.png"

export default class matrix_score_template_info extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: 'matrix_score_info',               //从哪个页面进入的
            dataList: {},
            min_value: 0,
            max_value: 5,
            min_name: "",
            max_name: "",
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList || state.dataList,
                dataList_prev: props.dataList,
                min_value: parseInt(props.dataList.min_value),
                max_value: parseInt(props.dataList.max_value) || "",
                min_name: props.dataList.min_name || state.min_name,
                max_name: props.dataList.max_name || state.max_name,
            }
        }
        return null;
    }
    componentDidMount() {
    }
    componentDidUpdate() {
    }
    componentWillUnmount() {
        this.setState = () => { }
    }
    render() {
        let items = [];
        for (let i = this.state.min_value; i <= this.state.max_value; i++) {
            if (i === this.state.min_value) {
                items.push(<div className="grade-item" key={i}>
                    <img src={cur_icon_circle_sel} alt="" />
                    {/* <span>{i}</span> */}
                </div>);
            } else {
                items.push(<div className="grade-item" key={i}>
                    <img src={cur_icon_circle_nor} alt="" />
                    {/* <span>{i}</span>  */}
                </div>);
            }

        }
        let val_items = []
        for (let i = this.state.min_value; i <= this.state.max_value; i++) {
            val_items.push(<div className="grade-item" key={i}>
                <span>{i}</span>
            </div>);
        }
        return (
            <div className='matrix-score-template-info'>
                <div className="matrix-score-info-top">
                    <div className="info-top-name-wrap">
                        <div className="info-top-name">{this.state.min_name}</div>
                        <div className="info-top-name">{this.state.max_name}</div>
                    </div>
                    <div className="info-top-value-wrap">
                        {
                            val_items
                        }
                    </div>
                </div>
                <div className="matrix-score-info-answer">
                    {
                        this.state.dataList.answers.map((item, index) => {
                            return (
                                <div className="draw-slip" key={index}>
                                    <div className="draw-slip-left draw-slip-left-border">
                                        <div className='draw-slip-title'>
                                            <div className="edit-question-title-wrap">
                                                <div className="edit-question-title">
                                                    {item.name}
                                                </div>
                                            </div>
                                            <div className={`${item.image.val ? "upload-question-pic" : "isHide"}`}>
                                                <img src={item.image.val} alt="" />
                                            </div>
                                        </div>
                                        <div className="draw-slip-index">
                                            {
                                                items
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div >
            </div>
        )
    }
}
