const TokenKey = "access_token";
const RefreshTokenKey = "refresh_token";

const BrandKey = "brand_id"
const UserStatusKey = "user_status"
const BrandInfoPopupStatusKey = "brand_info_popup_status"
const LoginTypeKey = "login_type"
const WXLoginCountKey = "wx_login_count"

export function setToken(token) {
    localStorage.setItem(TokenKey, token);
}

export function setRefreshToken(token) {
    localStorage.setItem(RefreshTokenKey, token);
}

export function getToken() {
    let token = localStorage.getItem(TokenKey);
    return token;
}

export function getRefreshToken() {
    let refreshToken = localStorage.getItem(RefreshTokenKey);
    return refreshToken;
}

export function removeToken() {
    localStorage.removeItem(TokenKey);
}

export function removeRefreshToken() {
    localStorage.removeItem(RefreshTokenKey);
}


export function setBrandID(token) {
    localStorage.setItem(BrandKey, token);
}

export function getBrandID() {
    let token = localStorage.getItem(BrandKey);
    return token;
}
export function removeBrandID() {
    localStorage.removeItem(BrandKey);
}

// 审核状态 1 新用户 2 审核中 3 老用户
export function setUserStatus(status) {
    localStorage.setItem(UserStatusKey, status);
}

export function getUserStatus() {
    let status = localStorage.getItem(UserStatusKey);
    return status;
}
export function removeUserStatus() {
    localStorage.removeItem(UserStatusKey);
}

// 在刷新浏览器前，用户信息认证状态是否打开 0-关闭 1-打开
export function setBrandInfoPopupStatus(status) {
    localStorage.setItem(BrandInfoPopupStatusKey, status);
}

export function getBrandInfoPopupStatus() {
    let status = localStorage.getItem(BrandInfoPopupStatusKey);
    return status;
}
export function removeBrandInfoPopupStatus() {
    localStorage.removeItem(BrandInfoPopupStatusKey);
}

// 在刷新浏览器前，用户信息认证状态的路线是 从扫码进来的还是验证码进来的 验证码-phone_login wx扫码-wx_login
export function setLoginType(status) {
    localStorage.setItem(LoginTypeKey, status);
}

export function getLoginType() {
    let status = localStorage.getItem(LoginTypeKey);
    return status;
}
export function removeLoginType() {
    localStorage.removeItem(LoginTypeKey);
}

// 在刷新浏览器前，如果是wx扫码进来的，刷新两次会到登录页，所以这里是个计数功能
export function setWXLoginCount(status) {
    localStorage.setItem(WXLoginCountKey, status);
}

export function getWXLoginCount() {
    let status = localStorage.getItem(WXLoginCountKey);
    return status;
}
export function removeWXLoginCount() {
    localStorage.removeItem(WXLoginCountKey);
}
