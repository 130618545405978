import React, { Component } from 'react'
import { connect } from 'react-redux'
import "./free_cross.scss"
import { getSpeicalEventCrossAnalysisInfo, getSpecialEventCrossAnalysisTable, getBrandSurvey, getSpecialEventSingleQuestionChart } from '../../api/panelApi';
import { download, getQueryParameter, saveImgZip } from '../../util/utils'
import { message } from 'antd';
import FreecrossConfig from './Freecross_config/freecross_config'
import FreecrossInfo from './Freecross_info/freecross_info'
import Loading from "../../components/Loading/loading"

class free_cross extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: 0,
            loadingText: "加载中",
            survey_id: "",
            survey_text: "",
            survey_list: [],//调研表
            top_variable_list: [],
            left_variable_list: [],
            calculation_method_list: [],
            score_source_list: [],
            table_list: [],
            selected_method_key_list: [],
            selected_age_key_list: [],
            top_variable_id: "",
            left_variable_id: "",
            score_source_id: ""
        }
    }
    componentDidMount() {
        this._isMounted = true;
        this.init()
    }
    componentWillUnMount = () => {
        this._isMounted = false;
        this.setState = () => { }
    }
    // 初始化
    init() {
        this.getBrandSurvey()
    }
    // 判断两个对象是否相等
    isObjectValueEqual(obj1, obj2) {
        if (JSON.stringify(obj1) === JSON.stringify(obj2)) {
            return true
        }
        return false
    }
    //获取调研主题列表
    async getBrandSurvey() {
        this.setState({
            loading: 1
        })
        let brand_id = localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : ""
        try {
            const res = await getBrandSurvey({
                brand_id: parseInt(brand_id)
            })
            this.setState({
                loading: 0
            })
            if (!res.data || res.data.length === 0) {
                message.warning('暂无数据', 2, () => { })
                return
            }
            let survey_list = []
            let survey_list_formal = JSON.parse(JSON.stringify(res.data))
            for (let i = 0; i < survey_list_formal.length; i++) {
                let obj = { key: survey_list_formal[i].id, value: survey_list_formal[i].name, is_sample: survey_list_formal[i]?.is_sample ? true : false }
                survey_list.push(obj)
            }
            if (window.location.search.indexOf("survey_id") !== -1) {
                // 从首页跳转过来
                this.initSurvey(survey_list)
            } else if (parseInt(localStorage.getItem("survey_id_cem")) > 0) {
                // 刷新 但已存在
                let survey_list_prev = JSON.parse(localStorage.getItem("survey_list_cem"))
                let isObjectValueEqual = this.isObjectValueEqual(survey_list, survey_list_prev)
                if (isObjectValueEqual) {
                    this.initPanel()
                } else { //如超户的survey列表进行了更新
                    this.initSurvey(survey_list)
                }
            } else { // 之前没有survey_id
                this.initSurvey(survey_list)
            }
            return res;
        } catch (error) {
            this.setState({ loading: 0 }, () => { message.error('后台发生错误') })
            throw error;
        }
    }
    // 获取 survey_text
    getSurveyText(id, survey_list) {
        for (let i = 0; i < survey_list.length; i++) {
            if (String(id) === String(survey_list[i].key)) {
                return survey_list[i].value
            }
        }
        return ""
    }
    // local storage 有
    initPanel() {
        let survey_id = localStorage.getItem("survey_id_cem")
        let survey_list = localStorage.getItem("survey_list_cem") !== "" ? JSON.parse(localStorage.getItem("survey_list_cem")) : []
        let survey_text = localStorage.getItem("survey_text_cem")
        this.setState({
            survey_id: survey_id ? parseInt(survey_id) : "",
            survey_list: survey_list,
            survey_text: survey_text,
        }, () => {
            this.getSpeicalEventCrossAnalysisInfo()
        })
    }
    // 测试
    initSurvey(survey_list) {
        let survey_id = survey_list[0].key
        let survey_text = survey_list[0].value
        if (window.location.search.indexOf("survey_id") !== -1) {
            // 从首页跳转过来
            survey_id = getQueryParameter("survey_id");
            survey_text = this.getSurveyText(survey_id, survey_list)
        }
        localStorage.setItem('survey_id_cem', parseInt(survey_id))
        localStorage.setItem('survey_text_cem', survey_text)
        localStorage.setItem('survey_list_cem', JSON.stringify(survey_list))
        this.setState({
            survey_id: parseInt(survey_id),
            survey_list: survey_list,
            survey_text: survey_text
        }, () => {
            this.getSpeicalEventCrossAnalysisInfo()
        })
    }
    //清空数据
    clearData() {
        this.setState({
            table_list: [],
            selected_method_key_list: [],
            selected_age_key_list: [],
            top_variable_id: "",
            left_variable_id: "",
            score_source_id: ""
        })
    }
    //切换调研问卷
    onSelectSurveyChange(key, option) {
        if (window.location.search.indexOf("survey_id") !== -1) {
            // 从调研计划跳转过来
            var url = window.location.href;
            if (url.indexOf("?") !== -1) {
                url = url.replace(/(\?|#)[^'"]*/, ''); //去掉http地址参数
                window.history.pushState({}, 0, url);
            }
        }
        let cur_index = parseInt(option.key)
        let survey_list = JSON.parse(JSON.stringify(this.state.survey_list))
        if (parseInt(key) !== parseInt(this.state.survey_id)) {
            this.clearData()
        }
        if (this.props.user.account_type === "1" && !survey_list[cur_index].is_sample) { // is_sample 0 自己的问卷 1 给的可以看的问卷
            message.warning(`请联系商务进行升级`, 3, () => { })
            return
        }
        let survey_id = survey_list[cur_index].key
        let survey_text = survey_list[cur_index].value || ""
        localStorage.setItem('survey_id_cem', survey_id)
        localStorage.setItem('survey_text_cem', survey_text)
        this.setState({
            survey_id: survey_id,
            survey_text: survey_text
        }, () => {
            this.getSpeicalEventCrossAnalysisInfo()
        })
    }
    // 将返回只有字符串的转为key和value
    getObjectArray(array) {
        return array.map((ele, index) => {
            return { key: index, value: ele }
        })
    }
    // 将返回为id和value的 转换为key和value
    getKeyObjectArray(array) {
        return array.map((ele, index) => {
            return { key: index, value: ele.value, type: ele.type, disabled: false, id: ele.id }
        })
    }
    // 获取调研问卷自由交叉分析
    async getSpeicalEventCrossAnalysisInfo() {
        this.setState({ loading: 1 })
        try {
            const response = await getSpeicalEventCrossAnalysisInfo({
                survey_id: this.state.survey_id,
                special_event_id: "",
                brand_id: ""
            })
            this.setState({ loading: 0 })
            if (parseInt(response.status) === 204) {
                message.warning(`暂无配置数据`, 2, () => { })
                this.setState({
                    calculation_method_list: [],
                    left_variable_list: [],
                    score_source_list: [],
                    top_variable_list: []
                })
            } else {
                let { calculation_method, left_variable, score_source, top_variable } = response.data
                // let top_variable_list = this.getKeyObjectArray(top_variable)
                // top_variable_list[1].disabled = true
                // let left_variable_list = this.getKeyObjectArray(left_variable)
                // left_variable_list[0].disabled = true
                this.setState({
                    top_variable_list: this.getKeyObjectArray(top_variable),
                    left_variable_list: this.getKeyObjectArray(left_variable),
                    calculation_method_list: this.getObjectArray(calculation_method),
                    score_source_list: this.getKeyObjectArray(score_source),
                })
            }
            return response;
        } catch (error) {
            this.setState({ loading: 0 }, () => { message.error('后台发生错误') })
            throw error;
        }
    }
    // 根据key查找value
    getValue(t, id) {
        if (id === "") return ""
        let list = JSON.parse(JSON.stringify(this.state[t + "_list"]))
        return list[parseInt(id)].value
    }
    // 获取调研问卷自由交叉分析表格
    async getSpecialEventCrossAnalysisTable() {
        if (!this.state.selected_method_key_list.length) {
            message.warning('请选择计算方式', 2, () => { })
            return
        } else if (this.state.left_variable_id === "" && this.state.top_variable_id === "") {
            message.warning('请选择顶部选项或左侧选项', 2, () => { })
            return
        } else if (this.getValue("left_variable", this.state.left_variable_id) === this.getValue("top_variable", this.state.top_variable_id)) {
            this.setState({ table_list: [] })
            message.warning('顶部选项与左侧选项不可以一样，请重新选择')
            return
        }
        // else if (this.state.left_variable_list[this.state.left_variable_id].value === this.state.top_variable_list[this.state.top_variable_id].value) {
        //     this.setState({ table_list: [] })
        //     message.warning('顶部选项与左侧选项不可以一样，请重新选择')
        //     return
        // }
        for (let i = 0; i < this.state.selected_method_key_list.length; i++) {
            let selected_method_key = parseInt(this.state.selected_method_key_list[i])
            if (this.state.calculation_method_list[selected_method_key].value === "平均分" && this.state.score_source_id === "") {
                message.warning('请选择分数来源', 2, () => { })
                return
            }
        }
        this.setState({ loading: 1 })
        try {
            const response = await getSpecialEventCrossAnalysisTable({
                age_list: this.state.selected_age_key_list,
                // left_variable: this.state.left_variable_id === "" ? "" : this.state.left_variable_list[this.state.left_variable_id].value,
                // top_variable: this.state.top_variable_id === "" ? "" : this.state.top_variable_list[this.state.top_variable_id].value,
                left_variable: this.state.left_variable_id === "" ? "" : this.getValue("left_variable", this.state.left_variable_id),
                top_variable: this.state.top_variable_id === "" ? "" : this.getValue("top_variable", this.state.top_variable_id),
                calculation_method: this.state.selected_method_key_list.map((ele, index) => {
                    return this.state.calculation_method_list[ele].value
                }),
                score_source: this.state.score_source_id === "" ? "" : this.getValue("score_source", this.state.score_source_id),
                special_event_id: "",
                survey_id: this.state.survey_id,
            })
            this.setState({ loading: 0 })
            if (parseInt(response.status) === 204) {
                message.warning(`暂无自由交叉分析数据`, 2, () => { })
                this.setState({
                    table_list: []
                })
            } else {
                let table_list = []
                for (let i = 0; i < response.data.length; i++) {
                    let columns = []
                    let table_data_list = []
                    if (response.data[i].table_data_list.length) {
                        columns = this.initColumn(response.data[i].column)
                        table_data_list = response.data[i].table_data_list.map((item, index) => {
                            item["id"] = index
                            return item
                        })
                    }
                    table_list.push({ table_name: response.data[i].table_name, table_data_list: table_data_list, columns: columns })
                }
                this.setState({
                    table_list: table_list
                })
            }
            return response;
        } catch (error) {
            this.setState({ loading: 0 }, () => { message.error('后台发生错误') })
            throw error;
        }
    }
    // 初始化table列表
    initColumn(table_item_obj) {
        let columns = []
        for (let i = 0; i < table_item_obj.length; i++) {
            let tmp = {
                title: table_item_obj[i],
                dataIndex: table_item_obj[i],
                key: table_item_obj[i],
                // width: 200
            }
            if (i) {
                // tmp.width = 150
                tmp.align = 'center'
            } else {
                tmp.title = ""
                // tmp.width = 300
            }
            columns.push(tmp)
        }
        return columns
    }
    // 配置方法变化
    onSelectMethodConfigChange(selected_method_key_list, score_source_id) {
        this.setState({
            selected_method_key_list: selected_method_key_list,
            score_source_id: score_source_id
        }, () => {
            this.getSpecialEventCrossAnalysisTable()
        })
    }
    // 配置变化则变化
    onSelectConfigChange(key, option, t) {
        if (t === "age_list") {
            this.setState({ selected_age_key_list: key }, () => [
                this.getSpecialEventCrossAnalysisTable()
            ])
        } else {
            this.setState({ [t + '_id']: key }, () => {
                this.getSpecialEventCrossAnalysisTable()
            })
        }
    }
    // 配置选好 初始化的一次请求
    onSelectAllConfigChange(obj) {
        let { top_variable_id, left_variable_id, selected_method_key_list } = obj
        this.setState({
            top_variable_id, left_variable_id, selected_method_key_list
        }, () => {
            this.getSpecialEventCrossAnalysisTable()
        })
    }
    render() {
        return (
            <div className='container freecross-container'>
                {
                    this.state.loading <= 0 ? "" : <Loading loadingText={this.state.loadingText}></Loading>
                }
                <div className="freecross-main">
                    <FreecrossInfo
                        table_list={this.state.table_list}
                        survey_list={this.state.survey_list}
                        survey_id={this.state.survey_id}
                        onSelectSurveyChange={this.onSelectSurveyChange.bind(this)}
                    ></FreecrossInfo>
                    <FreecrossConfig
                        survey_id={this.state.survey_id}
                        top_variable_list={this.state.top_variable_list}
                        left_variable_list={this.state.left_variable_list}
                        calculation_method_list={this.state.calculation_method_list}
                        score_source_list={this.state.score_source_list}
                        onSelectConfigChange={this.onSelectConfigChange.bind(this)}
                        onSelectMethodConfigChange={this.onSelectMethodConfigChange.bind(this)}
                        onSelectAllConfigChange={this.onSelectAllConfigChange.bind(this)}
                    ></FreecrossConfig>
                </div>

            </div>
        )
    }
}
export default connect(
    state => ({
        user: state.user,
    }),//映射状态
    {}//映射操作状态的方法
)(free_cross)
