import React, { Component } from 'react'
import "./translation_panel.scss"
import cur_icon_path from "../../../images/emotion_analysis/cur_icon_path.png"
import cur_icon_path_active from "../../../images/emotion_analysis/cur_icon_path_active.png"
import XmSelect from '../../../components/Select/xm_select';
import { getLanguageName, getRetranslateText } from '../../../util/enum';

export default class translation_panel extends Component {
    constructor(props) {
        super(props);
        this.filterRef = React.createRef()
        this.state = {
        }
    }
    // 点击选择语言
    onLanguageChange(key, option) {
        if (!this.props.language_list || this.props.language_list.length === 0) return
        let cur_index = parseInt(option.key) // 位置
        let translate_obj = {
            language_id: this.props.language_list[cur_index].key,
            retranslate: 0,  // 0：非重新翻译
            translate: 1  // 1: 正在翻译
        }
        this.props.onLanguageChange(translate_obj)
    }
    // 操作翻译图标
    handleTranslate(retranslate, translate) {
        if (retranslate === 1 || translate === 1) return
        this.props.handleTranslate()
    }
    // 重新翻译
    reTranslate() {
        let translate_obj = {
            language_id: this.props.language_id,
            retranslate: 1,// 1 为 重新翻译
            translate: 0, // 分开哈
        }
        this.props.onLanguageChange(translate_obj)
    }
    render() {
        return (
            <>
                <div className={`translation-image ${this.props.retranslate === 1 || this.props.translate === 1 ? "translation-image-not-allowed" : ""}`} onClick={this.handleTranslate.bind(this, this.props.retranslate, this.props.translate)}>
                    {
                        this.props.translate_status === 0 ? <img src={cur_icon_path} alt='' /> : <img src={cur_icon_path_active} alt='' />
                    }
                </div>
                {
                    this.props.translate_status === 1 ?
                        <div className="translation-select">
                            <XmSelect
                                placeholder="请选择语言"
                                value={this.props.language_id >= 0 ? this.props.language_id : null}
                                options={this.props.language_list || []}
                                onChange={(e, index) => {
                                    this.onLanguageChange(e, index)
                                }}
                            ></XmSelect>
                        </div> : ""
                }
                {
                    this.props.translate_status === 2 ?
                        <div className="translation-tip">
                            不满意翻译结果，
                            <span onClick={this.reTranslate.bind(this)}>{getRetranslateText(getLanguageName(this.props.language_id))}</span>
                        </div> : ""
                }
            </>
        )
    }
}
