import React, { Component } from 'react'
import '../../css/ant_common/ant_table.scss';
import './dispatch_list.scss'
import icon_add from "../../images/product_list/icon_add.png"
import icon_delete from "../../images/product_list/icon_delete.png"
import icon_search from "../../images/product_list/icon_search.png"
import { Input, Checkbox, Table, Tooltip, Pagination, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import {
    getProjectList,
    deleteProject,
    updateSpecialEventFlagCount,
    updateSurveyAuditProgress
} from '../../api/Project/project_list_api'
import XmModel from '../../components/Xm_model/Xm_model'
import Loading from '../../components/Loading/loading'
import CopyPopup from '../Survey_list/Copy_popup/copy_popup'
import { message } from 'antd';
import { getQueryParameter } from "../../util/utils";
import cur_icon_close from "../../images/cur_icon_close_gre.png";
const { Search } = Input;
let that = ''
export default class dispatch_list extends Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                title: '项目名称',
                dataIndex: 'project_name',
                key: 'project_name',
                width: 130,
                fixed: 'left',
            },
            {
                title: '品牌名称',
                dataIndex: 'brand_name',
                key: 'brand_name',
                width: 130,
            },
            {
                title: '产品名称',
                dataIndex: 'product_name',
                key: 'product_name',
                width: 130,
            },
            {
                title: '题目数量',
                dataIndex: 'question_count',
                key: 'question_count',
                width: 100,
            },
            {
                title: '当次派送总数量',
                dataIndex: 'dispatch_amount',
                key: 'dispatch_amount',
                width: 130,
                align: 'center',
            },
            {
                title: '收货物流',
                dataIndex: '',
                key: 'action',
                width: 90,
                render: (item) => {
                    return <label>
                        <span className="logistics-info" onClick={this.handleLogisticsRevise.bind(this, item)} >修改</span>
                    </label >
                }
            },
            {
                title: '平均反馈时间',
                dataIndex: 'duration',
                key: 'duration',
                width: 120,
                align: 'center',
            },
            {
                title: '目标反馈率',
                dataIndex: '',
                key: 'flag_percent',
                width: 120,
                align: 'center',
                render: (item) => {
                    return <label>
                        <span>{`${item.flag_percent || 0}%`}</span>
                        <span className="btn" onClick={this.onChangeFlagCount.bind(this, item)}>修改</span>
                    </label>
                }
            },
            {
                title: '反馈率',
                dataIndex: 'product_feedback_rate',
                key: 'product_feedback_rate',
                width: 100,
                align: 'center',
            },
            {
                title: '项目周期',
                dataIndex: 'development',
                key: 'development',
                width: 100,
            },
            {
                title: '当次派送规格',
                dataIndex: 'weight',
                key: 'weight',
                width: 120,
                align: 'center',
            },
            {
                title: '调研目标/目的',
                dataIndex: 'dispatch_expect',
                key: 'dispatch_expect',
                width: 130,
                align: 'center',
            },
            {
                title: '审核状态',
                dataIndex: '',
                key: 'audit_progress',
                width: 100,
                align: "center",
                render: (item) => {
                    return <span>
                        {(item.audit_progress === 0 && '未审核') || (item.audit_progress === 1 && '待审核') || (item.audit_progress === 2 && '审核通过') || (item.audit_progress === 3 && '审核不通过') || (item.audit_progress === 4 && '发布')}
                    </span>
                }
            },
            {
                title: '操作',
                dataIndex: '',
                key: 'action',
                width: 190,
                render: (item) => {
                    return <label>
                        {item.audit_progress === 0 ? <span style={{ color: 'var(--audit-color)' }} onClick={this.onExamine.bind(this, item, 1)}>审核</span> : ''}
                        {item.audit_progress === 2 ? <span style={{ color: 'var(--publish-color)' }} onClick={this.onExamine.bind(this, item, 4)}>发布</span> : ''}
                        <span style={{ color: 'var(--modify-color)' }} onClick={this.handleRevise.bind(this, item)} >修改</span>
                        <span style={{ color: 'var(--detail-color)' }} onClick={this.handleDetail.bind(this, item)}>详情</span>
                        <span style={{ color: 'var(--import-color)' }} onClick={this.handleCopy.bind(this, item)}>导入</span>
                    </label >
                }
            },
        ]
        this.state = {
            brand_id: localStorage.getItem('brand_id') ? localStorage.getItem('brand_id') : 1,   //品牌ID
            project_list: [],//产品列表
            selectedRowKeys: [],
            modelContent: "确定删除该产品吗？",
            showModel: false,
            modelTitle: "删除提示",
            total_page: 1, // 总共多少页
            total_number: 0, // 总共多少条
            current_page: 1, //当前页
            loading: 0, // 显示加载
            search_name: "", //是否有

            dialogFlag: false,
            editFlagCount: {},
            newFlagCount: 0,
            showCopyPopup: false, // 是否显示复制弹窗
            to_survey_id: "", // 复制的问卷id
        }

    }
    componentDidMount() {
        that = this
        let value = getQueryParameter('s');
        this.setState({
            search_name: value,
            current_page: 1
        }, () => {
            this.getProjectList();
        })
    }
    async getProjectList() {
        this.setState({
            loading: 0
        })
        let brand_id = this.state.brand_id
        getProjectList({
            brand_id: parseInt(brand_id),
            page: this.state.current_page,
            count: 10,
            project_name: this.state.search_name,
            type: 1
        }).then((res) => {
            if (res.status === 200) {
                this.setState({
                    project_list: res.data.project_list,
                    total_page: res.data.total_page,
                    total_number: res.data.total_number,
                    loading: 1
                })

            } else if (res.status === 204) {
                this.setState({
                    project_list: [],
                    total_page: 1,
                    total_number: 0,
                    loading: 1
                })
            }
        }).catch((err) => {
            this.setState({
                loading: 1
            })
            message.error('加载失败!')
        })
    }
    onSelectChange = (selectedRowKeys, record) => {
        this.setState({ selectedRowKeys, record });
    };
    // 修改物流跳转
    handleLogisticsRevise(item) {
        this.props.history.push(`/dispatch_list/logistics_info?project_id=${item.project_id}`)
    }
    //修改跳转
    handleRevise(item) {
        let project_type = 1
        localStorage.setItem('project_id', item.project_id)
        localStorage.setItem('current_project_type', project_type)
        localStorage.setItem('current_project_progress', item.current_progress) // 当前的问卷进度
        if (item.survey_id) {
            this.props.history.push(`/dispatch_list/project_entry?survey_id=${item.survey_id}&project_type=${project_type}`)
        } else {
            this.props.history.push(`/dispatch_list/project_entry?project_type=${project_type}`)
        }
    }
    //查看详情
    handleDetail(item) {
        this.props.history.push(`/overall_data`)
        // window.location.href = `/overall_data?brand_id=${item.brand_id}&product_id=${item.product_id}`;
        // window.location.href = `/overall_data`;
    }
    // 复制
    handleCopy(item) {
        this.setState({
            showCopyPopup: true,
            to_survey_id: item.survey_id
        })
    }
    // 第一个参数是设置是否显示，第二个参数就是子组件选确认
    handleModel(val, flag) {
        let project_id_list = this.state.selectedRowKeys.map(Number)
        if (!project_id_list.length) {
            message.warning('删除不可为空', 0.4, () => {
            })
            return
        }
        if (flag) {
            this.deleteProject()
        }
        this.setState({
            showModel: val,
        })
    }
    //搜索
    onSearch(value) {
        that.searchProject(value)
    };
    //搜索接口
    async searchProject(value) {
        this.props.history.push(`/dispatch_list?s=${value}`)
        this.setState({
            search_name: value,
            current_page: 1
        }, () => {
            this.getProjectList();
        })
    }
    //删除
    async deleteProject() {
        // let tmpList = JSON.parse(JSON.stringify(this.state.project_list))
        let brand_id = this.state.brand_id
        let project_id_list = this.state.selectedRowKeys.map(Number)
        let tmpList = this.state.project_list.filter((item) => {
            return !project_id_list.includes(parseInt(item.project_id))
        })
        deleteProject({
            brand_id: parseInt(brand_id),
            project_id_list: project_id_list
        }).then((res) => {
            if (res.status === 200) {
                message.success('删除成功', 0.2, () => {
                    this.setState({
                        project_list: tmpList
                    }, () => {
                        this.getProjectList()
                    })
                })

            }
        }).catch((err) => {
            message.error('删除失败!')
        })
    }
    //添加产品
    addProject() {
        this.props.history.push(`/dispatch_list/project_entry`)
        localStorage.removeItem('project_id')
    }
    //对表格的行进行操作
    handleTableRow(record, index) {
        return {
            onMouseEnter: (event) => { // 鼠标移入
            },
            onMouseLeave: (event) => {// 鼠标移出
            }
        }
    }
    // 操作分页
    handlePage(page, pageSize) {
        this.setState({
            current_page: page
        }, () => {
            this.getProjectList()
        });
    }
    // 审核
    onExamine(item, audit_progress) {
        let param = {
            id: item.project_id,
            type: 0,
            audit_progress: audit_progress
        }
        this.setState({ loading: 1 })
        updateSurveyAuditProgress(param).then(res => {
            this.getProjectList();
        }, err => {
            this.getProjectList();
        })
    }
    onChangeFlagCount(item) {
        this.setState({ dialogFlag: true, editFlagCount: item })
    }
    updateFlagCount() {
        let param = {
            id: this.state.editFlagCount.project_id,
            flag_count: Number(this.state.newFlagCount),
            type: 1,
        }
        this.setState({ loading: 1 });
        updateSpecialEventFlagCount(param).then(res => {
            this.getProjectList();
            this.setState({ dialogFlag: false });
        }, err => {
            this.setState({ dialogFlag: false });
            this.getProjectList();
        })
    }
    // 关闭弹窗
    onClose() {
        this.setState({ dialogFlag: false })
    }
    // 是否显示复制框
    canShowCopyPopup(object) {
        this.setState({
            showCopyPopup: object.showCopyPopup
        })
    }
    render() {
        const prefix = (
            <img src={icon_search} alt="" className='evaluation-icon-search' />
        )
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className='container dispatch-list-container-bg'>
                {
                    this.state.loading === 0 ? <Loading></Loading> : ""
                }
                <div className='dispatch-list-container'>
                    <div className='dispatch-entry-top'>
                        <div className='top-title'>项目-派送列表</div>
                    </div>
                    <div className="dispatch-operaion">
                        <div className="dispatch-operaion-left">
                            <Search
                                placeholder="请输入商品名称"
                                allowClear
                                enterButton="查询"
                                prefix={prefix}
                                onSearch={this.onSearch}
                            />
                        </div>
                        <div className="dispatch-operaion-right">
                            <div className="dispatch-operaion-button" onClick={this.handleModel.bind(this, true, false)}>
                                <img src={icon_delete} alt="" />
                                <span>删除</span>
                            </div>
                            <div className="dispatch-operaion-button" onClick={this.addProject.bind(this)}>
                                <img src={icon_add} alt="" />
                                <span>添加计划</span>
                            </div>
                        </div>
                    </div>
                    <div className='dispatch-list-main'>
                        <div className='dispatch-list-table'>
                            <ConfigProvider locale={zhCN}>
                                {/* <Table rowSelection={rowSelection} dataSource={this.state.project_list} columns={this.columns} pagination={{ pageSize: 10, showSizeChanger: false, showQuickJumper: true }} scroll={{ x: 900 }} rowKey={columns => columns.project_id} onRow={(record, index) => this.handleTableRow.call(this, record, index)} /> */}
                                <Table rowSelection={rowSelection} dataSource={this.state.project_list} columns={this.columns}
                                    pagination={{
                                        current: this.state.current_page,
                                        pageSize: 10, total: this.state.total_number,
                                        showSizeChanger: false, showQuickJumper: true,
                                        onChange: (page, pageSize) => {
                                            this.handlePage(page, pageSize)
                                        }
                                    }} scroll={{ x: 900 }} rowKey={columns => columns.project_id} onRow={(record, index) => this.handleTableRow.call(this, record, index)} />
                            </ConfigProvider>
                            <div className="table-sum-wrapper">共<span>{this.state.total_number || 0}</span>个结果，当前展示1-10行</div>
                        </div>
                    </div>
                </div>
                <div className='hello'>
                    <XmModel isShowModel={this.state.showModel} handleShowModel={this.handleModel.bind(this)} modelContent={this.state.modelContent} modelTitle={this.state.modelTitle}></XmModel>
                </div>
                {
                    this.state.dialogFlag ?
                        <div className="dialog_flag">
                            <div className="dialog_main">
                                <div className="dialog_main_title">
                                    <span>修改目标反馈率</span>
                                    <div onClick={this.onClose.bind(this)}>
                                        <img src={cur_icon_close} alt="" />
                                    </div>
                                </div>
                                <div className="dialog_main_content">
                                    <div className="item">
                                        <span>当前目标：</span>{this.state.editFlagCount.flag_percent}%
                                    </div>
                                    <div className="item">
                                        <span>新设目标：</span>
                                        <input type="number" min={1} max={100} defaultValue={this.state.editFlagCount.flag_percent} onInput={(e) => { this.setState({ newFlagCount: e.target.value }) }} />
                                    </div>
                                </div>
                                <div className="dialog_main_foot">
                                    <button onClick={this.onClose.bind(this)}>取消</button>
                                    <button onClick={this.updateFlagCount.bind(this)}>确定</button>
                                </div>
                            </div>
                        </div>
                        : ''
                }
                {
                    this.state.showCopyPopup ?
                        <CopyPopup
                            to_survey_id={this.state.to_survey_id}
                            canShowCopyPopup={this.canShowCopyPopup.bind(this)}
                        ></CopyPopup> : ''
                }
            </div>
        )
    }
}
