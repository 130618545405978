import React, { Component } from 'react';
import { connect } from 'react-redux'
import './user_popup.scss';
import tab_img_avatar from '../../../images/side_bar/tab_img_avatar.png'
import user_company from '../../../images/side_bar/user_company.png'
import user_type from '../../../images/side_bar/user_type.png'
import user_money from '../../../images/side_bar/user_money.png'
import user_quota from '../../../images/side_bar/user_quota.png'
import user_recharge from '../../../images/side_bar/user_recharge.png'
import icon_user_logout from '../../../images/side_bar/user_logout.png';
import icon_user_logout_active from '../../../images/side_bar/user_logout_active.png';
import icon_edit from '../../../images/login/edit_icon_active.png';
import icon_edit_active from '../../../images/login/edit_icon_white.png';
import { account_type_obj } from '../../../pages/User_info/Values/user_data'
import { NavLink, Link } from 'react-router-dom'
import XmModel from '../../../components/Xm_model/Xm_model'
import { getRefreshToken } from '../../../util/auth'
import { logout } from '../../../api/token'


class user_popup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModel: false,
            modelTitle: "登出提示",
            modelContent: "确定登出心愿盒吗？",
            profile_pic: { filename: "", file: "" },
            phone: "13562985888",
            first_name: "追风少女",// 用户昵称
            company: "上海心盒科技有限公司",//公司名称
            account_type: "1", //账户类型 1 基础 2 进阶 3 深度 4 定制
            balance: 100,//账户余额
            quota: "",//剩余Quota
        }
    }
    closeUserPopup = () => {
        this.props.handleUserInfo({
            show_user_popup: false
        })
    }
    clickRevise = () => {
        this.props.history.push(`/user_info`)
    }
    // 第一个参数是设置是否显示，第二个参数就是子组件选确认
    handleModel(val, flag) {
        this.props.handleUserInfo({
            show_user_popup: false
        })
        if (flag) {
            let refresh_token = getRefreshToken()
            logout(refresh_token).then(res => {
                if (res.status === 205) {
                    localStorage.clear();
                    window.location.href = '/login';
                }
            }).catch(err => {
                console.log('error', err)
            })
        }
        this.setState({
            showModel: val,
        })
    }
    render() {
        return (
            <div className="user-popup-container" onClick={this.closeUserPopup}>
                <div className="user-popup-main" onClick={(e) => (e.stopPropagation())}>
                    <div className="user-popup-top">
                        <div className="user-popup-top-avatar">
                            <img src={`${this.props.user.profile_pic.file ? this.props.user.profile_pic.file : tab_img_avatar}`} alt="" />
                        </div>
                        <div className="user-popup-top-detail">
                            <div>{this.props.user.first_name}</div>
                            <div>{this.props.user.phone}</div>
                        </div>
                    </div>
                    <div className="user-popup-content">
                        <div className="user-popup-item-wrapper">
                            <div className="user-popup-item-left">
                                <img src={user_company} alt="" />
                                <div>公司名称：</div>
                                <div>{this.props.user.company}</div>
                            </div>
                        </div>
                        <div className="user-popup-item-wrapper">
                            <div className="user-popup-item-left">
                                <img src={user_type} alt="" />
                                <div>账户类型：</div>
                                <div>{account_type_obj[this.props.user.account_type]}</div>
                            </div>
                        </div>
                        <div className="user-popup-item-wrapper">
                            <div className="user-popup-item-left">
                                <img src={user_money} alt="" />
                                <div>账户余额：</div>
                                <div>{this.props.user.balance}</div>
                            </div>
                            <div className="user-popup-item-right">
                                <div>快速充值</div>
                                <img src={user_recharge} alt="" />

                            </div>
                        </div>
                        <div className="user-popup-item-wrapper">
                            <div className="user-popup-item-left">
                                <img src={user_quota} alt="" />
                                <div>剩余人数quota：</div>
                                <div>{this.props.user.quota}</div>
                            </div>
                        </div>
                    </div>
                    <div className="user-popup-operation">
                        <Link to="/user_info" onClick={this.closeUserPopup}>
                            <div className="user-operation-btn user-edit-btn">
                                修改
                                <img src={icon_edit} alt="" className="icon" />
                                <img src={icon_edit_active} alt="" className="icon-active" />
                            </div>
                        </Link>
                        <div className="user-operation-btn" onClick={() => this.handleModel(true, false)}>
                            登出
                            <img src={icon_user_logout} alt="" className="icon" />
                            <img src={icon_user_logout_active} alt="" className="icon-active" />
                        </div>
                    </div>
                </div>
                <XmModel isShowModel={this.state.showModel} handleShowModel={this.handleModel.bind(this)} modelContent={this.state.modelContent} modelTitle={this.state.modelTitle}></XmModel>
            </div>
        )
    }
}
export default connect(
    state => ({
        user: state.user,
    }),//映射状态
    {}//映射操作状态的方法
)(user_popup)
