import React, { Component } from 'react'
import './max_chart_popup.scss';
import cur_icon_close_gre from '../../../images/cur_icon_close_gre.png'
import download_icon from '../../../images/overall_data/download_icon.png'
import cur_icon_search from "../../../images/emotion_analysis/cur_icon_search.png"
import XmSelect from '../../../components/Select/xm_select';
import ChartList from '../chart_list'
import OpenBlankList from "../open_blank_list"
import BlankList from '../blank_list'
import DetailOptionList from './detail_option_list'
import DetailSingleChart from './detail_chart/detail_single_chart'
import MatrixNpsList from '../Matrix_nps_chart/matrix_nps_list'
import MaxdiffList from '../Chart_display/maxdiff_list'
import debounce from 'lodash/debounce';
import { Input, Tabs, Empty, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
const { TabPane } = Tabs;
export default class max_chart_popup extends Component {
    constructor(props) {
        super(props);
        this.resize = debounce(this.resize, 1000);
        this.maxChartRef = React.createRef();
        this.state = {
            dataList: { name: "", type: "" },
            cur_detail_chart: {}, //当前tab页面的图
            current_tab: '0', //当前tab页面
            single_id: "",
            chart_flag: 0,// 0 为饼图 1 为 列表
            isConsumer: false,
            isMaxSreen: false,
            blankListHeight: 0,
            option_distribution: [],
            option_distribution_prev: [],
            isShowRightDetail: true, // 是否展示右边列表
            search_text: "",
            bar_option_name: "",// 当前所选择的柱子
            tab_list: [] // 三个切换
        }

    }
    setMaxChart(obj) {
        this.debounceFunc = this.resize.bind(this);
        window.addEventListener("resize", this.debounceFunc, false);
        let { dataList, single_id, isConsumer } = obj
        this.setState({
            dataList: dataList,
            single_id: single_id,
            isConsumer: isConsumer,
            search_text: ""
        }, () => {
            this.resize("first_enter")
            // this.props.toInitChart()
        })
    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {
            // this.setState({
            //   dataList: this.props.dataList
            // })
            // this.toInitChart()
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            if (this.state.option_distribution?.length >= 0 && this.state.isShowRightDetail) {
                let max_chart_left = document.getElementById("max_chart_" + this.state.single_id)
                max_chart_left.style.cssText = `width: 45%`
                let max_chart_right = document.getElementById("max_chart_right_" + this.state.single_id)
                max_chart_right.style.cssText = `width: 55%`
                this.judgeResetChart()
            } else if (this.state.option_distribution === null) {
                // let max_chart_left = document.getElementById("max_chart_" + this.state.single_id)
                // max_chart_left.style.cssText = `width: 100%`
            }
            let tab_list = []
            for (let i = 0; i < this.props.option_distribution?.length; i++) {
                let obj = this.props.option_distribution[i].name
                tab_list.push(obj)
            }
            this.setState({
                needRefresh: false,
                tab_list: tab_list,
            })
        }
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.option_distribution) !== JSON.stringify(state.option_distribution_prev)) {
            return {
                needRefresh: true,
                option_distribution: props.option_distribution,
                option_distribution_prev: props.option_distribution,
                cur_detail_chart: props.option_distribution && props.option_distribution.length ? props.option_distribution[parseInt(state.current_tab)] : {},
                isShowRightDetail: true,
                search_text: ""
            }
        }
        return null;

    }
    // 判断是否要重新渲染chart
    judgeResetChart() {
        let type = this.state.dataList.type
        if (type === 1 || type === 2 || type === 6 || type === 7 || type === 8 || type === 12 || type === 13) {
            let dom = document.getElementById("max_chart_" + this.state.single_id)
            if (!dom) { return; }
            let domWidth = dom.offsetWidth;
            if (domWidth !== this.state.domWidth && domWidth <= 800) {
                this.props.toInitChart(false)
            }
        }

    }
    resize(flag) {
        let isMaxSreen = this.state.isMaxSreen
        let sreenWidth = document.body.clientWidth
        let type = this.state.dataList.type
        let dom = document.getElementById("max_chart_popup_" + this.state.single_id)
        if (!dom) { return; }
        let domWidth = dom.offsetWidth;
        let domWHeight = dom.offsetHeight;
        let blankDomHeight = 258
        if (type === 11) { // 特殊的自定义 因为下面有foot
            this.props.toInitChart()
            return
        } else if (type === 20) {
            this.props.toInitChart(true)
            return
        }
        if (domWidth > 800) {
            if (isMaxSreen === false || flag === "first_enter") { // 之前是false
                if (type === 3 || type === 15 || type === 16 || type === 18 || type === 19) {//简答题
                    let blankDom = document.getElementById("max_chart_" + this.state.single_id + "_wrapper")
                    blankDomHeight = blankDom.offsetHeight - 140;
                    this.setState({ blankListHeight: blankDomHeight })
                } else {
                    this.props.toInitChart(true)
                }
                if (type === 15 || type === 19) { // 折线图
                    this.props.toInitChart(true)
                }
                this.setState({ isMaxSreen: true })
            }
        } else {
            if (isMaxSreen === true || flag === "first_enter") { // 之前是true
                if (type === 3 || type === 15 || type === 16 || type === 18 || type === 19) {//简答题
                    let blankDom = document.getElementById("max_chart_" + this.state.single_id + "_wrapper")
                    blankDomHeight = blankDom.offsetHeight - 140;
                    this.setState({ blankListHeight: blankDomHeight })
                } else {
                    this.props.toInitChart(false)
                }
                if (type === 15 || type === 19) { // 折线图
                    this.props.toInitChart(false)
                }
                this.setState({ isMaxSreen: false })
            }
        }
        if (type === 1 || type === 2 || type === 3 || type === 5 || type === 6 || type === 7 || type === 8 || type === 10 || type === 12 || type === 13 || type === 18) {
            let blankDom = document.getElementById("max_chart_" + this.state.single_id + "_wrapper")
            blankDomHeight = blankDom.offsetHeight - 260;
            this.setState({ blankListHeight: blankDomHeight })
        }
        let chartDom = document.getElementById("max_chart_" + this.state.single_id)
        let chartDomWidth = 0
        if (chartDom) {
            chartDomWidth = chartDom.offsetWidth;
        }
        this.setState({
            chartDomWidth: chartDomWidth
        })
    }
    tags_close() {
        this.props.canShowMaxChartPopup({ showMaxChartPopup: false })
    }
    download() {
        this.props.downloadChart()
    }
    // 切换图
    handleChangeChart(index) {
        if (this.state.chart_flag === index) {
            return
        }
        this.setState({
            chart_flag: index
        })
    }
    // 切换词云
    handleWordCloud(index) {
        this.handleChangeChart(index)
        if (!this.props.wordcloud_image) {
            this.props.geWordCloudQuestion()
        }
        // if (!this.state.dataList.hasOwnProperty('wordcloud_image')) {
        //     this.props.geWordCloudQuestion()
        // }
    }
    // 点击柱子
    onMouseDown() {
        let Xindex = JSON.parse(localStorage.getItem("Xindex"))
        if (!Xindex || (this.state.dataList.type !== 2 && this.state.dataList.type !== 1 && this.state.dataList.type !== 12 && this.state.dataList.type !== 13)) {
            return
        }
        let bar_index = ""
        let bar_option_id = ""
        let bar_option_name = ""
        if (this.state.dataList.type === 2) {
            bar_index = Xindex[0].dataIndex;
            bar_option_id = this.state.dataList.option_id_list[bar_index];
            bar_option_name = this.state.dataList.xAxisList[bar_index];
        } else if (this.state.dataList.type === 1 || this.state.dataList.type === 12 || this.state.dataList.type === 13) {
            bar_index = Xindex.dataIndex;
            bar_option_id = this.state.dataList.data[bar_index].option_id;
            bar_option_name = this.state.dataList.data[bar_index].name;
        }
        localStorage.removeItem('Xindex')
        this.setState({
            bar_option_name: bar_option_name,
        })
        this.props.getRelatedCommentList(bar_option_id, bar_option_name, true)
    }
    //是否展示右边的详情
    handleShowRightDetail() {
        this.setState({
            isShowRightDetail: !this.state.isShowRightDetail
        }, () => {
            if (this.state.isShowRightDetail) {
                let max_chart_left = document.getElementById("max_chart_" + this.state.single_id)
                max_chart_left.style.cssText = `width: 45%`
                let max_chart_right = document.getElementById("max_chart_right_" + this.state.single_id)
                max_chart_right.style.cssText = `width: 55%`
            } else {
                let max_chart_left = document.getElementById("max_chart_" + this.state.single_id)
                max_chart_left.style.cssText = `width: 100%`
                let max_chart_right = document.getElementById("max_chart_right_" + this.state.single_id)
                max_chart_right.style.cssText = `width: 20px`
            }
            this.judgeResetChart()
        })
    }
    clickTab(key) {
        let index = parseInt(key)
        let cur_detail_chart = this.state.option_distribution[index]
        this.setState({
            cur_detail_chart: cur_detail_chart,
            current_tab: String(key)
        })
    }
    render() {
        const prefix = (
            <img src={cur_icon_search} alt="" className='evaluation-icon-search' />
        )
        return (
            <div className='max-chart-popup' id={`max_chart_popup_${this.state.single_id}`} >
                <div className='max-chart-popup-container'>
                    <div className='max-chart-popup-top'>
                        <div className='popup-top-title'>图表展示</div>
                        <div className='popup-delete-icon'>
                            <img src={cur_icon_close_gre} className='cursor' onClick={this.tags_close.bind(this)} alt="" />
                        </div>
                    </div>
                    {/* 图表名字 */}
                    <div className='popup-content-top'>
                        <div className='top-title-wrapper'>
                            {this.state.dataList.name}
                        </div>
                        <div className='top-btn-wrapper' onClick={this.download.bind(this)}>
                            <img src={download_icon} alt="" />
                            <span>下载</span>
                        </div>
                    </div>
                    {/* 图表切换 */}
                    {
                        this.state.dataList.type === 1 || this.state.dataList.type === 2 || this.state.dataList.type === 5 || this.state.dataList.type === 6 || this.state.dataList.type === 7 || this.state.dataList.type === 8 || this.state.dataList.type === 12 || this.state.dataList.type === 13 || this.state.dataList.type === 15 || this.state.dataList.type === 19 ? <div className='max-chart-change-wrapper'>
                            <div className={`chart-change-text ${this.state.chart_flag === 0 ? "chart-change-text-selected" : ""}`} onClick={this.handleChangeChart.bind(this, 0)}>图表</div>
                            <div className="chart-change-sign">|</div>
                            <div className={`chart-change-text ${this.state.chart_flag === 1 ? "chart-change-text-selected" : ""}`} onClick={this.handleChangeChart.bind(this, 1)}>列表</div>
                        </div> : ""
                    }
                    {
                        this.state.dataList.type === 3 || this.state.dataList.type === 16 || this.state.dataList.type === 18 ? <div className='max-chart-change-wrapper'>
                            <div className={`chart-change-text ${this.state.chart_flag === 0 ? "chart-change-text-selected" : ""}`} onClick={this.handleChangeChart.bind(this, 0)}>列表</div>
                            <div className="chart-change-sign">|</div>
                            <div className={`chart-change-text ${this.state.chart_flag === 1 ? "chart-change-text-selected" : ""}`} onClick={this.handleWordCloud.bind(this, 1)}>词云</div>
                        </div> : ""
                    }
                    {/* 显示真正的图表 */}
                    <div className='max-chart-wrapper' id={`max_chart_${this.state.single_id}_wrapper`} >
                        <div className={this.state.chart_flag === 1 || this.state.dataList.type === 3 || this.state.dataList.type === 16 || this.state.dataList.type === 18 || this.state.dataList.type === 10 || this.state.dataList.type === 20 ? "isHide" : "max-chart-wrapper-2"}>
                            <div id={`max_chart_${this.state.single_id}`} className={`echart-container max-preinstall-echart comment_${this.state.single_id} max-chart-left`} onMouseDown={this.onMouseDown.bind(this)}></div>
                            {
                                this.props.option_distribution === null ? "" :
                                    <div className="max-chart-right-container" id={`max_chart_right_${this.state.single_id}`}>
                                        <div className="max-chart-right-operator" onClick={this.handleShowRightDetail.bind(this)}>
                                            <div className={this.state.isShowRightDetail ? "" : "isHide"}>{"<<"}</div>
                                            <div className={this.state.isShowRightDetail ? "isHide" : ""}>{">>"}</div>
                                        </div>

                                        {/* <div className="max-chart-detail-container"> */}
                                        <div className={this.state.isShowRightDetail ? "max-chart-detail-container" : "isHide"}>
                                            {
                                                this.props.option_distribution?.length > 0 ?
                                                    <>
                                                        <div className='option-title'>选项：{this.state.bar_option_name}</div>
                                                        <div className='max-chart-option-right'>
                                                            <Tabs activeKey={this.state.current_tab} size="small" onChange={this.clickTab.bind(this)}>
                                                                {
                                                                    this.state.tab_list.map((item, index) => {
                                                                        return (
                                                                            <TabPane tab={`${item}`} key={index}>
                                                                                <DetailSingleChart key={"detail" + index} dataList={this.state.cur_detail_chart} single_id={`detail_single_chart_${index}`}></DetailSingleChart>
                                                                            </TabPane>
                                                                        )
                                                                    })
                                                                }
                                                            </Tabs>
                                                        </div>
                                                    </>
                                                    :
                                                    <div>暂无数据</div>
                                            }
                                        </div>
                                    </div>
                            }
                        </div>
                        {/* 显示heatmap */}
                        <div className={this.state.dataList.type === 20 ? "chart-wrapper-2" : "isHide"}>
                            <div className='heatmap-select'>
                                <XmSelect
                                    dropdownClassName="download-type-dropdown"
                                    placeholder="请选择"
                                    value={this.props.precision_id}
                                    options={this.props.precision_list}
                                    onChange={(e, index) => {
                                        this.props.selectPrecisionChange(e, index, true)
                                    }}>
                                </XmSelect>
                            </div>
                            <div className={`echart-container comment_${this.state.single_id} heatmap-image-container`} id={`max-heatmap-image-container-${this.state.single_id}`}>
                                <img src={this.state.dataList.question_img} alt='' />
                            </div>
                        </div>
                        <div className={this.state.chart_flag === 0 ? "isHide" : "max-chart-wrapper-2"}>
                            {
                                this.state.dataList.type === 3 || this.state.dataList.type === 16 || this.state.dataList.type === 18 ?
                                    <div className="max-wordcloud-image-wrapper">
                                        {this.props.wordcloud_image ?
                                            <img src={this.props.wordcloud_image} alt="" /> : <div className="wordcloud-image-empty">
                                                <ConfigProvider locale={zhCN}>
                                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                                </ConfigProvider>
                                            </div>
                                        }
                                    </div> : ""
                            }
                            {
                                this.state.dataList.type === 1 || this.state.dataList.type === 2 || this.state.dataList.type === 5 || this.state.dataList.type === 6 || this.state.dataList.type === 7 || this.state.dataList.type === 8 || this.state.dataList.type === 12 || this.state.dataList.type === 13 ?
                                    <ChartList
                                        isConsumer={this.state.isConsumer}
                                        dataList={this.state.dataList}
                                        isMaxChart={true}
                                        questionType={this.state.dataList.type}
                                        blankListHeight={this.state.blankListHeight}
                                    ></ChartList> : ""
                            }
                            {
                                this.state.dataList.type === 15 ? <MatrixNpsList
                                    isConsumer={this.state.isConsumer}
                                    dataList={this.state.dataList}
                                    isMaxChart={true}
                                    blankListHeight={this.state.blankListHeight}
                                > </MatrixNpsList> : ""
                            }
                            {
                                this.state.dataList.type === 19 ? <MaxdiffList
                                    isConsumer={this.state.isConsumer}
                                    dataList={this.state.dataList}
                                    isMaxChart={true}
                                    blankListHeight={this.state.blankListHeight}
                                > </MaxdiffList> : ""
                            }
                        </div>
                        <div className={this.state.chart_flag === 1 || this.state.dataList.type === 20 ? "isHide" : "max-chart-wrapper-2"}>
                            {
                                this.state.dataList.type === 3 || this.state.dataList.type === 10 || this.state.dataList.type === 18 ?
                                    <BlankList
                                        dataList={this.state.dataList}
                                        isMaxChart={true}
                                        blankListHeight={this.state.blankListHeight}
                                    ></BlankList>
                                    : ""
                            }
                            {
                                this.state.dataList.type === 16 ?
                                    <OpenBlankList
                                        dataList={this.state.dataList}
                                        isMaxChart={true}
                                        blankListHeight={this.state.blankListHeight}
                                    ></OpenBlankList> : ""
                            }
                        </div>
                        {
                            this.state.dataList.type === 11 ?
                                <div className="max-custom-chart-foot">
                                    <div className="max-foot-name max-foot-name-0">
                                        <div className="max-line"></div>
                                        <div className="max-sign"></div>
                                        <div className="max-content">贬损者</div>
                                    </div>
                                    <div className="max-foot-name max-foot-name-1">
                                        <div className="max-line"></div>
                                        <div className="max-sign"></div>
                                        <div className="max-content">被动者</div>
                                    </div>
                                    <div className="max-foot-name max-foot-name-2">
                                        <div className="max-line"></div>
                                        <div className="max-sign"></div>
                                        <div className="max-content">推荐者</div>
                                    </div>
                                </div> : ""
                        }
                    </div>
                </div>
            </div >
        )
    }
}
