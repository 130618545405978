import React, { Component } from 'react'
import './logistics_info.scss';
import { getWarehouseInfoList, saveProjectBusinessLogistic, getProjectBusinessLogisticList, updateProjectBusinessLogistic, deleteProjectBusinessLogistic } from '../../../api/Project/logistics_api'
import { getQueryParameter } from '../../../util/utils'
import logistics_prompt from '../../../images/project_entry/logistics_info/prompt.png'
import logistics_copy from '../../../images/project_entry/logistics_info/copy.png'
import XmSelect from '../../../components/Select/xm_select';
import { message, Input, Checkbox, Table, Tooltip, Pagination, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import XmModel from '../../../components/Xm_model/Xm_model'
import DeliveryPopup from './Popup/delivery_popup'

export default class logistics_info extends Component {
    constructor(props) {
        super(props);
        this.deliveryRef = React.createRef();
        this.columns = [
            {
                title: '项目名称',
                dataIndex: 'warehouse_name',
                key: 'warehouse_name',
                width: 130,
                fixed: 'left',
            },
            {
                title: '收货仓地址',
                dataIndex: 'warehouse_address',
                key: 'warehouse_address',
                width: 130,
            },
            {
                title: '收货仓电话',
                dataIndex: 'warehouse_phone',
                key: 'warehouse_phone',
                width: 130,
            },
            {
                title: '发货单号',
                dataIndex: 'logistic_number',
                key: 'logistic_number',
                width: 100,
            },
            {
                title: '发货人昵称',
                dataIndex: 'sender_name',
                key: 'sender_name',
                width: 120,
                align: 'center',
            },
            {
                title: '发货人电话',
                dataIndex: 'sender_phone',
                key: 'sender_phone',
                width: 130,
                align: 'center',
            },
            {
                title: '产品数量',
                dataIndex: 'sent_count',
                key: 'sent_count',
                width: 120,
                align: 'center',
            },
            {
                title: '物流状态',
                dataIndex: 'logistic_status',
                key: 'logistic_status',
                width: 100,
                align: 'center',
            },
            {
                title: '提交时间',
                dataIndex: 'logistic_time',
                key: 'logistic_time',
                width: 100,
                align: 'center',
            },
            {
                title: '操作',
                dataIndex: '',
                key: 'action',
                width: 110,
                render: (item) => {
                    return <label>
                        <span onClick={this.handleReviseLogisticNnumber.bind(this, item)} >修改</span>
                    </label >
                }
            },
        ]
        this.state = {
            name: "Logistics_info",
            project_entry_number: 0,    //第几步    第4步代表:逻辑设置 
            project_id: "",
            warehouse_list: [],
            warehouse_id: "",
            warehouse_name: "", //收货仓
            warehouse_receiver: "", //收货人
            warehouse_phone: "",//收货电话
            warehouse_address: "",//收货地址
            sender: "",//发货人
            phone: "",//发货电话
            product_count: "",//产品数量
            logistic_number: "",//发货单号
            logistic_list: [],//收货仓发货记录list
            selectedRowKeys: [],//删除的产品派送计划发货记录
            modelContent: "确定删除该产品吗？",
            showModel: false,
            modelTitle: "删除提示",
            logistic_id: "",//物流信息记录id
            current_logistic_item: {},//列表当前修改的行
            prev_logistic_info: {},//物流信息之前的
            delivery_popup_status: false,
        }

    }
    componentDidMount() {
        this.init()
    }
    componentWillUnmount() {
        this.setState = () => { }
    }
    init() {
        let project_id = this.state.project_id
        if (window.location.search.indexOf("project_id") !== -1) {
            // 从产品派送计划跳转过来
            project_id = getQueryParameter("project_id");
            this.setState({
                project_entry_number: 0,
                project_id: project_id
            }, () => {
                this.getProjectBusinessLogisticList()
            })
        } else {
            this.setState({
                project_entry_number: this.props.project_entry_number,
                project_id: this.props.project_id
            }, () => {
                this.getProjectBusinessLogisticList()
            })
        }
        this.getWarehouseInfoList()

    }
    // 获取table表
    async getProjectBusinessLogisticList() {
        try {
            const response = await getProjectBusinessLogisticList({
                project_id: this.state.project_id
            })
            if (!response) return
            if (parseInt(response.status) === 204) {
                this.setState({
                    logistic_list: []
                })
            } else {
                let logistic_list = response.data.logistic_list
                this.setState({
                    logistic_list: logistic_list
                })
            }
            return response;
        } catch (error) {
            message.error('后台发生错误，获取失败')
            throw error;
        }
    }
    // 获取收货仓库信息接口
    async getWarehouseInfoList() {
        try {
            const response = await getWarehouseInfoList({})
            if (!response) return
            if (parseInt(response.status) === 204) {
                this.setState({
                    warehouse_list: []
                })
            } else {
                let warehouse_list = response.data.warehouse_list
                let warehouse_id = warehouse_list[0].id
                let warehouse_receiver = warehouse_list[0].receiver
                let warehouse_phone = warehouse_list[0].phone
                let warehouse_address = warehouse_list[0].address
                for (let i = 0; i < warehouse_list.length; i++) {
                    warehouse_list[i].key = warehouse_list[i].id
                    warehouse_list[i].value = warehouse_list[i].name
                }
                this.setState({
                    warehouse_list: warehouse_list,
                    warehouse_id: warehouse_id,
                    warehouse_receiver: warehouse_receiver,
                    warehouse_phone: warehouse_phone,
                    warehouse_address: warehouse_address
                })
            }
            return response;
        } catch (error) {
            throw error;
        }
    }
    //下拉框选择后，并赋值到state里面
    onLogisticsSelectChange(key, option) {
        let cur_index = parseInt(option.key)
        this.setState({
            warehouse_id: this.state.warehouse_list[cur_index].id,
            warehouse_name: this.state.warehouse_list[cur_index].key,
            warehouse_receiver: this.state.warehouse_list[cur_index].receiver,
            warehouse_phone: this.state.warehouse_list[cur_index].phone,
            warehouse_address: this.state.warehouse_list[cur_index].address
        })
    }
    // 下拉框搜索可以大小写
    filterSelectOption(input, option) {
        return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    // 单改变值，非下拉框的，比如填空题
    handleInput(t, event) {
        if (!t) return false
        if (!event) return false
        this.setState({ [t]: event.target.value })
    }
    // 复制物流信息
    copyLogistics() {
        if (this.state.warehouse_id === "") {
            message.warning('请先选择收货仓', 0.7, () => { })
            return
        }
        // let warehouse_name_text = `收货仓：${this.state.warehouse_name}\n`
        let warehouse_receiver_text = `收货人：${this.state.warehouse_receiver}\n`
        let warehouse_phone_text = `收货电话：${this.state.warehouse_phone}\n`
        let warehouse_address_text = `收货地址：${this.state.warehouse_address}`
        var textToCopy = warehouse_receiver_text + warehouse_phone_text + warehouse_address_text
        // navigator.clipboard.writeText(textToCopy)
        if (navigator.clipboard && window.isSecureContext) {
            navigator.clipboard
                .writeText(textToCopy)
                .then(() => {
                    message.success('复制成功', 0.7, () => { })
                })
                .catch(() => {
                    message.error('复制成功失败')
                })
        } else {
            // 创建text area
            const textArea = document.createElement('textarea')
            textArea.value = textToCopy
            // 使text area不在viewport，同时设置不可见
            document.body.appendChild(textArea)
            textArea.focus()
            textArea.select()
            return new Promise((resolve, reject) => {
                // 执行复制命令并移除文本框
                document.execCommand('copy') ? resolve() : reject(new Error('出错了'))
                textArea.remove()
            }).then(
                () => {
                    message.success('复制成功', 0.7, () => { })
                },
                () => {
                    message.error('复制成功失败')
                }
            )
        }
    }
    // 是否保存项目派送收货仓发货信息
    saveLogisticsInfo(t) {
        if (t === 'next' && this.state.logistic_list.length) {
            this.nextJump()
        } else if (this.validateLogisticsInfo()) {
            let current_logistic_info = {
                sender: this.state.sender,
                phone: this.state.phone,
                product_count: this.state.product_count,
                // warehouse_id: this.state.warehouse_id,
                logistic_number: this.state.logistic_number
            }
            if (this.is_exist(current_logistic_info) && t !== "next") {
                message.warning('产品物流信息已存在相同信息', 0.7, () => { })
                return
            } else if (this.is_exist(current_logistic_info) && t === "next") {
                this.nextJump()
            }
            this.setState({
                prev_logistic_info: current_logistic_info
            }, () => {
                this.saveProjectBusinessLogistic(t)
            })

        }
    }
    // 按完成跳转页面
    nextJump() {
        let current_project_type = localStorage.getItem('current_project_type') || ""
        localStorage.removeItem('current_project_type')
        localStorage.removeItem('project_id')
        if (parseInt(current_project_type) === 2) {
            this.props.history.push(`/survey_list`)
        } else {
            this.props.history.push(`/dispatch_list`)
        }
    }
    // 保存项目派送收货仓发货信息
    async saveProjectBusinessLogistic(t) {
        try {
            const response = await saveProjectBusinessLogistic({
                project_id: this.state.project_id,
                sender: this.state.sender,
                phone: this.state.phone,
                product_count: this.state.product_count,
                warehouse_id: this.state.warehouse_id,
                logistic_number: this.state.logistic_number
            })
            message.success('保存成功', 0.7, () => { })
            if (t === "next") {
                this.nextJump()
            } else {
                this.getProjectBusinessLogisticList()
            }
            return response;
        } catch (error) {
            message.error('保存项目派送收货仓发货信息失败')
            throw error;
        }
    }
    // 确认物流信息是否填写
    validateLogisticsInfo() {
        let flag = true;
        let phone_reg = /^[1][3,4,5,7,8][0-9]{9}$/
        if (this.state.warehouse_id === "") {
            message.warning('请选择收货仓', 0.7, () => { })
            flag = false;
        } else if (this.state.sender === "") {
            message.warning('请填写发货人', 0.7, () => { })
            flag = false;
        } else if (this.state.phone === "") {
            message.warning('请填写发货电话', 0.7, () => { })
            flag = false;
        } else if (!phone_reg.test(this.state.phone)) {
            message.warning('请填写正确的发货电话', 0.7, () => { })
            flag = false;
        } else if (this.state.product_count === "") {
            message.warning('请填写产品数量', 0.7, () => { })
            flag = false;
        } else if (this.state.logistic_number === "") {
            message.warning('请填写发货单号', 0.7, () => { })
            flag = false;
        }
        return flag
    }
    //是否可以下一步标红
    canSaveLogisticsInfo() {
        let flag = false
        if (this.state.warehouse_id
            && this.state.sender
            && this.state.phone
            && this.state.product_count) {
            flag = true
        }
        return flag
    }
    // 只能输入数字的字符
    validateNumber(event) {
        event.target.value = event.target.value.replace(/^(0+)|[^\d]+/g, '')
    }
    // 第一个参数是设置是否显示，第二个参数就是子组件选确认
    handleModel(val, flag) {
        let logistic_id_list = this.state.selectedRowKeys.map(Number)
        if (!logistic_id_list.length) {
            message.warning('删除不可为空', 0.4, () => {
            })
            return
        }
        if (flag) {
            this.deleteProjectBusinessLogistic()
        }
        this.setState({
            showModel: val,
        })
    }
    // 勾选列表
    onSelectChange = (selectedRowKeys, record) => {
        this.setState({ selectedRowKeys, record });
    };
    //删除
    async deleteProjectBusinessLogistic() {
        let logistic_id_list = this.state.selectedRowKeys.map(Number)
        try {
            const response = await deleteProjectBusinessLogistic({
                logistic_id_list: logistic_id_list
            })
            if (!response) return
            message.success('删除成功', 0.2, () => {
                this.setState({
                    selectedRowKeys: [],
                }, () => {
                    this.getProjectBusinessLogisticList()
                })
            })
            return response;
        } catch (error) {
            message.error('后台发生错误，删除失败')
            throw error;
        }
    }
    // 修改发货信息行
    handleReviseLogisticNnumber(item) {
        let obj = {
            logistic_id: item.logistic_id,
            logistic_number: item.logistic_number,
            logistic_sender: item.sender_name,
            logistic_phone: item.sender_phone,
            delivery_popup_status: false,
        }
        this.setState({
            delivery_popup_status: true,
            current_logistic_item: obj
        }, () => {
            this.deliveryRef.current.setDeliveryList(obj)
        })
    }
    // 列出所有的键，接着遍历数组
    ifCompare(object1, object2) {
        var o1keys = Object.keys(object1);
        var o2keys = Object.keys(object2);
        if (o2keys.length !== o1keys.length) return false;
        for (let i = 0; i <= o1keys.length - 1; i++) {
            let key = o1keys[i];
            if (!o2keys.includes(key)) return false;
            if (object2[key].toString() !== object1[key].toString()) return false;
        }
        return true;
    }
    // 发货信息弹窗 子传父
    get_delivery_popup_status(obj) {
        let { delivery_popup_status } = obj
        this.setState({
            delivery_popup_status: delivery_popup_status,
        })
        if (this.ifCompare(obj, this.state.current_logistic_item)) { // 必须保证key一样
            return // 如果相同的修改
        }
        this.setState({
            current_logistic_item: obj
        }, () => {
            this.updateProjectBusinessLogistic()
        })
    }
    // 修改派送计划收货仓发货记录
    async updateProjectBusinessLogistic() {
        let { logistic_id, logistic_number, logistic_sender, logistic_phone } = this.state.current_logistic_item
        try {
            const response = await updateProjectBusinessLogistic({
                logistic_id: logistic_id,
                logistic_number_new: logistic_number,
                logistic_sender_new: logistic_sender,
                logistic_phone_new: logistic_phone
            })
            if (!response) return
            message.success('更新成功', 0.2, () => {
                this.getProjectBusinessLogisticList()
            })
            return response;
        } catch (error) {
            message.error('后台发生错误，更新失败')
            throw error;
        }
    }
    // 上一步 返回第三步
    prev() {
        this.props.getData({
            project_entry_number: 3,
        })
        this.setState({
            project_entry_number: 3
        })

    }
    //完成 + 下一步
    accomplish(t) {
        this.saveLogisticsInfo(t)
    }
    // 是否已经存在
    is_exist(current_logistic_info) {
        let is_exist = false
        for (let i = 0; i < this.state.logistic_list.length; i++) {
            let is_exist_obj = {
                sender: this.state.logistic_list[i].sender_name,
                phone: this.state.logistic_list[i].sender_phone,
                product_count: this.state.logistic_list[i].sent_count,
                // warehouse_id: this.state.warehouse_id,
                logistic_number: this.state.logistic_list[i].logistic_number,
            }
            if (this.ifCompare(current_logistic_info, is_exist_obj)) {
                is_exist = true
                return is_exist
            }
        }
        return is_exist
    }
    render() {
        const { selectedRowKeys } = this.state;
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className="container logistics-container-bg">
                <div className="logistics-info-container">
                    <div className="logistics-info-title">
                        产品物流信息
                    </div>
                    {
                        this.state.project_entry_number === 6 ?
                            <div className='step-container'>
                                <div className="step-card-wrapper">
                                    <div className={this.state.project_entry_number >= 1 ? 'step-card step-card-active' : 'step-card'}>
                                        <div className='step-card-number'>1</div>
                                        <div className='step-card-text'>填写基础信息</div>
                                    </div>
                                </div>
                                <div className="step-line"></div>
                                <div className="step-card-wrapper">
                                    <div className={this.state.project_entry_number >= 2 ? 'step-card step-card-active' : 'step-card'}>
                                        <div className='step-card-number'>2</div>
                                        <div className='step-card-text'>标签派送</div>
                                    </div>
                                </div>
                                <div className="step-line"></div>
                                <div className="step-card-wrapper">
                                    <div className={this.state.project_entry_number >= 3 ? 'step-card step-card-active' : 'step-card'}>
                                        <div className='step-card-number'>3</div>
                                        <div className='step-card-text'>调研问卷设置</div>
                                    </div>
                                </div>
                                <div className="step-line"></div>
                                <div className="step-card-wrapper">
                                    <div className={this.state.project_entry_number >= 6 ? 'step-card step-card-active' : 'step-card'}>
                                        <div className='step-card-number'>4</div>
                                        <div className='step-card-text'>产品物流设置</div>
                                    </div>
                                </div>
                            </div> : ""
                    }
                    <div className="logistics-info">
                        <div className="logistics-info-top">
                            <img src={logistics_prompt} alt="" />
                            <div className='msg'>
                                <div>提示：请于5个工作日内完成产品入仓，产品入仓后开始进入派送队列，预计15个工作日内完成派送</div>
                            </div>
                        </div>
                        {/* <div className="logistics-info-item">
                            <div className="logistics-info-left">
                                <span></span>收获仓
                            </div>
                            <div className='logistics-info-right'>
                                <XmSelect
                                    getPopupContainer={triggerNode => {
                                        return triggerNode.parentNode || document.body
                                    }}
                                    showSearch
                                    filterOption={(input, option) => {
                                        return this.filterSelectOption.call(this, input, option)
                                    }
                                    }
                                    placeholder="请选择收获仓"
                                    defaultValue={this.state.warehouse_name || null} key={this.state.warehouse_name}
                                    options={this.state.warehouse_list}
                                    onChange={(e, index) => {
                                        this.onLogisticsSelectChange(e, index)
                                    }}></XmSelect>
                            </div>
                        </div> */}
                        <div className='logistics-info-item'>
                            <div className='logistics-info-left'><span></span>收货人</div>
                            <div className='logistics-info-right'>
                                <input placeholder='请先选择收货仓' className='logistics-info-input logistics-info-input-disabled' type='text' defaultValue={this.state.warehouse_receiver} disabled />
                            </div>
                        </div>
                        <div className='logistics-info-item'>
                            <div className='logistics-info-left'><span></span>收货电话</div>
                            <div className='logistics-info-right'>
                                <input placeholder='请先选择收货仓' className='logistics-info-input logistics-info-input-disabled' type='text' defaultValue={this.state.warehouse_phone} disabled />
                            </div>
                        </div>
                        <div className='logistics-info-item'>
                            <div className='logistics-info-left'><span></span>收货地址</div>
                            <div className='logistics-info-right'>
                                <input placeholder='请先选择收货仓' className='logistics-info-input logistics-info-input-disabled' type='text' defaultValue={this.state.warehouse_address} disabled />
                                <img src={logistics_copy} alt="" className="logistics-info-img" onClick={this.copyLogistics.bind(this)} />
                            </div>
                        </div>
                        <div className='logistics-info-item'>
                            <div className='logistics-info-left'><span>*</span>发货人</div>
                            <div className='logistics-info-right'>
                                <input placeholder='请输入发货人名称' className='logistics-info-input' type='text' defaultValue={this.state.sender} onChange={this.handleInput.bind(this, 'sender')} />
                            </div>
                        </div>
                        <div className='logistics-info-item'>
                            <div className='logistics-info-left'><span>*</span>发货电话</div>
                            <div className='logistics-info-right'>
                                <input placeholder='请输入发货电话' className='logistics-info-input' type='text' maxLength="11" onInput={this.validateNumber.bind(this)} defaultValue={this.state.phone} onBlur={this.handleInput.bind(this, 'phone')} />
                            </div>
                        </div>
                        <div className='logistics-info-item'>
                            <div className='logistics-info-left'><span>*</span>发货数量</div>
                            <div className='logistics-info-right'>
                                <input placeholder='请输入发货数量' className='logistics-info-input' type='text' defaultValue={this.state.product_count} onChange={this.handleInput.bind(this, 'product_count')} onInput={this.validateNumber.bind(this)} />
                            </div>
                        </div>
                        <div className='logistics-info-item'>
                            <div className='logistics-info-left'><span>*</span>发货单号</div>
                            <div className='logistics-info-right'>
                                <input placeholder='请输入发货物流单号' className='logistics-info-input' type='text' defaultValue={this.state.logistic_number} onChange={this.handleInput.bind(this, 'logistic_number')} />
                            </div>
                        </div>
                        {
                            this.state.project_entry_number === 6 ?
                                <div className="operaion-row">
                                    <div onClick={this.prev.bind(this)}>上一步</div>
                                    <div onClick={this.accomplish.bind(this)}>保存</div>
                                    <div onClick={this.accomplish.bind(this, 'next')}>完成</div>
                                </div> : <div className="operaion-row-2">
                                    <div onClick={this.saveLogisticsInfo.bind(this)} className={this.canSaveLogisticsInfo() ? "next-active" : ""}>提交</div>
                                </div>
                        }

                    </div>
                </div>
                <div className={this.state.logistic_list.length ? "logistics-list-container" : "isHide"}>
                    <div className="logistics-list-title">
                        产品物流信息
                    </div>
                    {
                        this.state.selectedRowKeys.length ? <div className="logistics-operaion">
                            <div className="dispatch-operaion-right">
                                <div className="dispatch-operaion-button" onClick={this.handleModel.bind(this, true, false)}>
                                    <span>删除</span>
                                </div>
                            </div>
                        </div> : ""
                    }

                    <div className='logistics-list-table'>
                        <ConfigProvider locale={zhCN}>
                            <Table rowSelection={rowSelection} dataSource={this.state.logistic_list} columns={this.columns} pagination={{ pageSize: 10, showSizeChanger: false, showQuickJumper: true }} scroll={{ x: 900 }} rowKey={columns => columns.logistic_id} />
                        </ConfigProvider>
                    </div>
                </div>
                <div className='hello'>
                    <XmModel isShowModel={this.state.showModel} handleShowModel={this.handleModel.bind(this)} modelContent={this.state.modelContent} modelTitle={this.state.modelTitle}></XmModel>
                </div>
                <div className={this.state.delivery_popup_status ? '' : 'isHide'}>
                    <DeliveryPopup ref={this.deliveryRef} get_delivery_popup_status={this.get_delivery_popup_status.bind(this)} ></DeliveryPopup>
                </div>
            </div>
        )
    }
}
