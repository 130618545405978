import React, { Component } from "react";
import { connect } from "react-redux";
import "./gpt_analysis.scss";
import tab_img_avatar from "../../images/side_bar/tab_img_avatar.png";
import logo_icon from "../../images/logo_icon.png";
import cur_icon_rect_nor from "../../images/gpt_analysis/cur_icon_rect_nor.png";
import cur_icon_rect_half_sel from "../../images/gpt_analysis/cur_icon_rect_half_sel.png";
import cur_icon_rect_sel from "../../images/gpt_analysis/cur_icon_rect_sel.png";
import cur_icon_bin from "../../images/gpt_analysis/cur_icon_bin.png";
import icon_loading from "../../images/gpt_analysis/icon_loading.gif";
import { getRegionList } from "../../api/panelApi";
import { message, ConfigProvider, Empty, Table } from "antd";
import Loading from "../../components/Loading/loading";
import EditNode from "../../components/EditNode/edit_node";
// import ProductDropdown from "../Cem_product_dropdown/product_dropdown";
import FilterAnalysisSelect from "./Filter_analysis_select/filter_analysis_select";
import {
  getAnalysisProjectQuestions,
  getAnalysisSpecialEventQuestions,
  getAnalysisSessionList,
  getAnalysisQuestions,
  deleteAnalysisSession,
  getSurveyUserAnswer,
} from "../../api/chat_api/chat_api";
import {
  getQuestionType,
  getQuestionTypeName,
} from "../../components/Project_entry/QuestionnaireSetting/Question_rules/question_type_rule";
import {
  getCeleryProgressView,
  getCeleryProgressListView,
} from "../../api/Questionnaire_setting/question_template_api";
import SurveyDropdown from "../Gpt_dropdown/survey_dropdown/survey_dropdown";
import ProductDropdown from "../Gpt_dropdown/product_dropdown/product_dropdown";
import zhCN from "antd/lib/locale/zh_CN";

var analysisInterval = null;
class gpt_analysis extends Component {
  constructor(props) {
    super(props);
    var analysisInterval = null;
    this.filterRef = React.createRef();
    this.timer_filter = null;
    this.state = {
      loading: 0,
      loadiing_user_answer: 0,
      survey_id: "",
      special_event_id: "",
      isProductPage: true, // 是否是产品
      selected_region_list: [],
      selected_gender_list: [],
      selected_age_list: [],
      word_count: "",
      checkbox_index: 0, //0 未选择 1 已选择 2全选
      checkbox_list: [
        {
          name: "未选择",
          img: cur_icon_rect_nor,
        },
        {
          name: "已选择",
          img: cur_icon_rect_half_sel,
        },
        {
          name: "全选",
          img: cur_icon_rect_sel,
        },
      ],
      question_list: [
        // {
        //     name: "问题1hhhh哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈1233333",
        //     is_selected: false,
        // },
      ],
      question_list_selected: [], // 选中的问题列表
      session_list_formal: [],
      session_list: [
        // {
        //     id: 1,
        //     conclusion: "说话问题1hhhh哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈哈1233333",
        //     user_type: 0,
        //     is_loading: false
        // },
      ],
      prompt: "",
      user_input: "", // 用户输入的text
      is_summary_analysis: 0, //1点击了总结分析 //0 没有点击总结分析
      session_id: "", // 聊天窗口id
      is_prompt_pending: false,
      columns: [
        {
          title: "昵称",
          dataIndex: "nickname",
          key: "nickname",
          width: 150,
        },
        {
          title: "设备",
          dataIndex: "device",
          key: "device",
          width: 150,
        },
        {
          title: "职业",
          dataIndex: "occupation",
          key: "occupation",
        },
        {
          title: "地址",
          dataIndex: "region",
          key: "region",
        },
        {
          title: "年龄",
          dataIndex: "age",
          key: "age",
        },
        {
          title: "性别",
          dataIndex: "gender",
          key: "gender",
        },
      ], //table的column
      table_list: [], //table的list
      async_id_list: [], //异步id列表
      async_id_list_pure: [], //异步id列表
    };
  }
  componentDidMount() {
    this.init();
  }
  componentDidUpdate(preProps, preState) {
    if (
      window.location.pathname === "/ai_analysis_survey" &&
      this.state.isProductPage
    ) {
      this.clearData();
      this.setState({
        isProductPage: false,
      });
    } else if (
      window.location.pathname === "/ai_analysis_product" &&
      !this.state.isProductPage
    ) {
      this.clearData();
      this.setState({
        isProductPage: true,
      });
    }
    if (
      this.state.async_id_list.length &&
      this.state.async_id_list.length !== preState.async_id_list.length
    ) {
      this.setAnalysisPatchPolling(this.state.async_id_list);
    } else if (
      !this.state.async_id_list.length &&
      preState.async_id_list.length
    ) {
      clearInterval(analysisInterval);
    }
  }
  componentWillUnMount = () => {
    this.setState = () => { };
  };
  init() {
    // init
  }
  // 轮训异步id
  setAnalysisPatchPolling(async_id_list) {
    let interval_time = 20000; // 每10s一次
    clearInterval(analysisInterval);
    let that = this;
    let queue = async_id_list.map((item) => item.async_id);
    setTimeout(() => {
      analysisInterval = setInterval(async () => {
        try {
          const response = await getCeleryProgressListView({ uid: queue });
          response.data.forEach((item, index) => {
            let status_current = Number(item.status);
            let curTime = new Date();
            let queue_item = async_id_list.filter((item2) => {
              return item2.async_id === item.async_id;
            })[0];
            let status = Number(queue_item.status);
            // if (Number(curTime) >= Number(queue_item.finish_time)) {
            //   message.warning(
            //     `问答<${queue_item.conclusion.slice(0, 15)}...>超时,稍后再试`,
            //     4,
            //     () => { }
            //   );
            //   that.deleteQueueItem(item.async_id);
            //   clearInterval(analysisInterval);
            // } else
            if (status_current === 1) {
              // 任务完成
              message.success(
                `问答<${queue_item.conclusion.slice(
                  0,
                  15
                )}...>的分析完成，请回到对话查看结果`,
                4,
                () => { }
              );
              that.deleteQueueItem();
              that.getAnalysisSessionList();
              clearInterval(analysisInterval);
            } else if (status_current === 5 || status_current === 6) {
              // 任务失败
              message.error(
                `问答<${queue_item.conclusion.slice(0, 15)}...>的${item.detail
                }`,
                4,
                () => { }
              );
              that.deleteQueueItem();
              clearInterval(analysisInterval);
            } else {
              // 234
              if (status_current !== status) {
                that.editQueueItem(status_current, item.async_id);
              }
            }
          });
        } catch {
          clearInterval(analysisInterval);
        }
      }, interval_time);
    }, 0);
  }
  // 获取结论
  getConclusionText(async_id, session_list) {
    // 为什么传进来session_list 防止在生成对话的时候，删除了问题，但是它的回答gpt还是在执行的，所以要传进来
    let conclusion = "";
    for (let i = session_list.length - 1; i >= 0; i--) {
      if (
        session_list[i].user_type === 0 &&
        session_list[i].async_id === async_id
      ) {
        conclusion = session_list[i].conclusion;
        break;
      }
    }
    return conclusion;
  }
  // 编辑第一个queue_item
  editQueueItem(status_current, async_id) {
    let async_id_list = JSON.parse(JSON.stringify(this.state.async_id_list));
    async_id_list = async_id_list.map((item) => {
      if (item.async_id === async_id) {
        item.status = status_current;
      }
      return item;
    });
    this.setState(
      {
        async_id_list: async_id_list,
      },
      () => { }
    );
  }
  // 删除第一个queue_item
  deleteQueueItem(async_id) {
    let async_id_list = JSON.parse(JSON.stringify(this.state.async_id_list));
    async_id_list = async_id_list.filter((item) => item.async_id !== async_id);
    this.setState({
      async_id_list: async_id_list,
    });
  }
  async getAnalysisProjectQuestions() {
    try {
      const response = await getAnalysisProjectQuestions({
        project_id: this.state.project_id,
      });
      let question_list = [];
      if (response.status === 204) {
        this.setState({
          question_list: question_list,
          survey_id: "",
          table_list: [],
        });
        return;
      }
      for (let i = 0; i < response.data.data.length; i++) {
        question_list.push({
          id: response.data.data[i].question_id ?? response.data.data[i].id,
          name: response.data.data[i].name,
          sort: response.data.data[i].sort ?? i + 1,
          question_type: response.data.data[i].question_type ?? 3,
          has_answer: response.data.data[i].has_answer ?? 1,
          is_selected: false,
        });
      }
      let async_id_list_pure = response.data.async_id_list;
      this.setState(
        {
          question_list: question_list,
          survey_id: response.data.survey_id,
          async_id_list: [],
          async_id_list_pure: async_id_list_pure,
          table_list: [],
        },
        () => {
          // 获取会话列表
          this.getAnalysisSessionList();
          this.getSurveyUserAnswer();
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  // 获取简答题题目-调研
  async getAnalysisSpecialEventQuestions() {
    try {
      const response = await getAnalysisSpecialEventQuestions({
        special_event_id: this.state.special_event_id,
      });
      let question_list = [];
      if (response.status === 204) {
        this.setState({
          question_list: question_list,
        });
        return;
      }
      for (let i = 0; i < response.data.data.length; i++) {
        question_list.push({
          id: response.data.data[i].question_id ?? response.data.data[i].id,
          name: response.data.data[i].name,
          sort: response.data.data[i].sort ?? i + 1,
          question_type: response.data.data[i].question_type ?? 3,
          has_answer: response.data.data[i].has_answer ?? 1,
          is_selected: false,
        });
      }
      let async_id_list_pure = response.data.async_id_list;
      this.setState(
        {
          question_list: question_list,
          async_id_list: [],
          async_id_list_pure: async_id_list_pure,
          table_list: [],
        },
        () => {
          this.getAnalysisSessionList();
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
  handleSurveyChange(survey_id, special_event_id) {
    if (survey_id !== this.state.survey_id) {
      this.clearData();
    }
    this.setState(
      {
        loading: 1,
        survey_id: survey_id,
        special_event_id: special_event_id,
        table_list: [],
      },
      () => {
        // 获取会话列表 因为调研是有survey_id的，所以可以并行进行
        // this.getAnalysisSessionList();
        // 获取调研的问题
        this.getAnalysisSpecialEventQuestions();
        this.getRegionList();
        this.getSurveyUserAnswer(); // 获取预览数据
      }
    );
  }
  // 下拉框传来的数据
  handleProductChange(id, project_id) {
    // 切换品牌id的时候清空
    if (id === "-2") {
      this.clearData();
      this.setState({
        loading: 0,
      });
      return;
    }
    if (id === "-1") {
      // 没有品牌
      message.warning("没有品牌的数据", 2, () => { });
      this.clearData();
      this.setState({
        loading: 0,
        product_id: "",
        project_id: "",
      });
      return;
    }
    if (id === "") {
      // 只有品牌 没有产品
      message.warning("没有该产品的数据", 2, () => { });
      this.clearData();
      this.setState({
        loading: 0,
        product_id: "",
        project_id: "",
      });
      return;
    } else if (id !== this.state.product_id) {
      this.clearData();
    } else if (project_id !== this.state.project_id) {
      this.clearData();
    }
    this.setState(
      {
        product_id: id,
        project_id: project_id,
        loading: 1,
        async_id_list: [],
        table_list: [],
      },
      () => {
        this.getAnalysisProjectQuestions();
        this.getRegionList();
      }
    );
  }
  //清空数据
  clearData() {
    this.setState({
      selected_region_list: [],
      selected_gender_list: [],
      selected_age_list: [],
      word_count: "",
      checkbox_index: 0, //0 未选择 1 已选择 2全选
      question_list_selected: [],
      session_list_formal: [],
      session_list: [],
      question_list: [],
      prompt: "",
      user_input: "",
      session_id: "", // 聊天窗口id
      is_prompt_pending: false,
      async_id_list: [],
      table_list: [],
    });
  }
  // 获取默认地域列表
  async getRegionList() {
    let product_id = this.state.product_id
      ? parseInt(this.state.product_id)
      : "";
    let survey_id = this.state.survey_id ? parseInt(this.state.survey_id) : "";
    let survey_type = 2;
    if (window.location.pathname === "/ai_analysis_survey") {
      survey_type = 4;
    }
    // if (!this.state.isProductPage) {
    //     survey_type = 4
    // }
    try {
      const response = await getRegionList({
        product_id: product_id,
        survey_type: survey_type,
        survey_id: survey_id,
      });
      let region_list = [];
      for (let i = 0; i < response.data.length; i++) {
        let obj = { key: i, value: response.data[i] };
        region_list.push(obj);
      }

      this.setState(
        {
          region_list: region_list,
          loading: this.state.loading - 1,
        },
        () => {
          this.initChart();
        }
      );
      return response;
    } catch (error) {
      this.setState(
        {
          loading: this.state.loading - 1,
        },
        () => {
          message.error("后台发生错误");
        }
      );
      throw error;
    }
  }
  // 删选初始化图表
  initChart() {
    // 防止接口回来满，但是setFilterList这个组件已经unmonted
    if (!this.filterRef.current) return;
    this.filterRef.current.setFilterList({
      region_list: this.state.region_list,
      is_init_filter: true,
    });
  }
  // 选择题目名称
  handleQuestion(item, index) {
    if (!item.has_answer) {
      return;
    }
    let question_list = this.state.question_list;
    if (
      !question_list[index].is_selected &&
      this.state.question_list_selected.length >= 5
    ) {
      message.warning("最多选择5个问题", 2, () => { });
      return;
    }
    question_list[index].is_selected = !question_list[index].is_selected;
    let is_selected_num = 0;
    let checkbox_index = 0;
    let question_list_selected = [];
    for (let i = 0; i < question_list.length; i++) {
      if (question_list[i].is_selected) {
        is_selected_num++;
        // question_list_selected.push(question_list[i].id)
        question_list_selected.push({
          id: question_list[i].id,
          index: i + 1,
          sort: question_list[i].sort,
          name: question_list[i].name,
        });
      }
    }
    if (is_selected_num === question_list.length) {
      checkbox_index = 2;
    } else if (is_selected_num >= 1) {
      checkbox_index = 1;
    }
    this.setState({
      checkbox_index: checkbox_index,
      question_list: question_list,
      question_list_selected: question_list_selected,
    });
  }
  // 全选题目名称
  handleAllQuestion() {
    let checkbox_index = this.state.checkbox_index;
    let question_list = this.state.question_list;
    if (checkbox_index === 0) {
      checkbox_index = 2; // 全选
      for (let i = 0; i < question_list.length; i++) {
        question_list[i].is_selected = true;
      }
    } else if (checkbox_index === 1) {
      checkbox_index = 2; // 全选
      for (let i = 0; i < question_list.length; i++) {
        question_list[i].is_selected = true;
      }
    } else if (checkbox_index === 2) {
      checkbox_index = 0; // 未选择
      for (let i = 0; i < question_list.length; i++) {
        question_list[i].is_selected = false;
      }
    }
    this.setState({
      checkbox_index: checkbox_index,
      question_list: question_list,
    });
  }
  // 搜索的filter
  updateFilter(filter_obj) {
    let {
      selected_region_list,
      selected_gender_list,
      selected_age_list,
      word_count,
    } = filter_obj;
    this.setState(
      {
        selected_region_list: selected_region_list,
        selected_gender_list: selected_gender_list,
        selected_age_list: selected_age_list,
        word_count: word_count,
      },
      () => {
        // this.getPresetCommentSimple()
      }
    );
  }
  // 获取promot
  onChangeEditor(obj) {
    let { value } = obj;
    this.setState({
      user_input: value,
    });
  }
  getPrompt() {
    let prompt = "您好，我想要根据";
    let question_list_text = this.state.question_list_selected
      .map((item) => {
        let name = `【问题${item.sort}-${this.removeRichtext(item.name)}】`;
        return name;
      })
      .join("、");
    if (question_list_text) {
      question_list_text = question_list_text + "的数据";
    }
    prompt += question_list_text;
    // 地域
    if (this.state.selected_region_list.length > 0) {
      prompt += "，以及地域为" + this.state.selected_region_list.join("、");
    }
    // 性别
    if (this.state.selected_gender_list.length > 0) {
      prompt += "，以及性别为" + this.state.selected_gender_list.join("、");
    }
    // 年龄
    if (this.state.selected_age_list.length > 0) {
      prompt += "，以及年龄为" + this.state.selected_age_list.join("、");
    }
    // 字数
    if (this.state.word_count) {
      prompt += `，以及评论字数超过${this.state.word_count}个字`;
    }
    // user_input
    if (this.state.user_input) {
      prompt += `，对于如下的<${this.state.user_input}>主题`;
    }
    return prompt;
  }
  // 确定filter
  confirmFilterChat() {
    // 获取数据预览
    let that = this;
    if (!this.state.survey_id) {
      message.warning("相关题目暂无数据", 2, () => { });
      return;
    }
    let fun = function (e) {
      if (that.timer_filter) {
        return;
      }
      that.timer_filter = setTimeout(() => {
        that.getSurveyUserAnswer();
      }, 3000);
    };
    fun();
  }
  // 简答题回答数据分析
  async getAnalysisQuestions(user_index, gpt_index) {
    let question_list_selected_index = this.state.question_list_selected.map(
      (item) => String(item.id)
    );
    try {
      const response = await getAnalysisQuestions({
        word_count: this.state.word_count,
        age_list: this.state.selected_age_list,
        region_list: this.state.selected_region_list,
        gender_list: this.state.selected_gender_list,
        question_list: question_list_selected_index,
        text: this.state.prompt,
        session_id: this.state.session_id,
        user_input: this.state.user_input,
        is_summary_analysis: this.state.is_summary_analysis,
      });

      let session_list = JSON.parse(JSON.stringify(this.state.session_list));
      let async_id_list = JSON.parse(JSON.stringify(this.state.async_id_list));
      let curTime = new Date();
      let obj = {
        async_id: response.data.asyn_id, // 前端
        status: -1,
        create_time: curTime.getTime(),
        finish_time: new Date(
          curTime.setSeconds(curTime.getSeconds() + 180)
        ).getTime(),
        conclusion: session_list[user_index].conclusion,
      };

      async_id_list.push(obj);
      session_list[user_index].id = response.data.user_id;
      session_list[gpt_index].id = response.data.gpt_id;
      session_list[user_index].async_id = response.data.asyn_id;
      session_list[gpt_index].async_id = response.data.asyn_id;
      this.setState({
        session_list: session_list,
        async_id_list: async_id_list,
        user_input: "",
        checkbox_index: 0,// 清空选择
        question_list_selected: [],// 清空选择
        question_list: this.state.question_list.map((item) => {
          item.is_selected = false;
          return item;
        })
      });
      return response;
    } catch (error) {
      if (error?.message === "isCancel") {
        message.error("后台超时发生错误");
      } else if (
        error?.response?.data?.detail &&
        error.response.data.detail.indexOf("OpenAI") !== -1
      ) {
        message.warning(`当前已负载，请稍后再试`, 2, () => { });
        this.getAnalysisSessionList();
      } else {
        message.error(`后台发生错误，请稍后再试`, 2, () => { });
        let session_list = JSON.parse(JSON.stringify(this.state.session_list));
        session_list.splice(user_index, 2);
        this.setState({
          session_list: session_list,
        });
      }
      throw error;
    }
  }
  // 获取会话列表 查找分析数据会话数据列表 + 创建会话
  async getAnalysisSessionList() {
    try {
      const response = await getAnalysisSessionList({
        survey_id: this.state.survey_id,
      });
      let session_list = [];
      for (let i = 0; i < response.data.data.length; i++) {
        let obj = {
          id: response.data.data[i].id,
          async_id: response.data.data[i].async_id,
          user_type: response.data.data[i].user_type,
          conclusion: response.data.data[i].conclusion,
          is_loading: false,
        };
        session_list.push(obj);
      }
      let async_id_list = [];
      let curTime = new Date();
      for (let i = 0; i < this.state.async_id_list_pure.length; i++) {
        let obj = {
          async_id: this.state.async_id_list_pure[i],
          status: -1,
          create_time: curTime.getTime(),
          finish_time: new Date(
            curTime.setSeconds(curTime.getSeconds() + 300)
          ).getTime(),
          conclusion: this.getConclusionText(
            this.state.async_id_list_pure[i],
            session_list
          ),
        };
        async_id_list.push(obj);
      }
      this.setState({
        session_list_formal: session_list,
        session_list: session_list,
        session_id: response.data.session_id,
        async_id_list: async_id_list,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  // 删除会话历史记录
  deleteSession(item, index) {
    if (item.id === "") {
      message.warning("id缺失，删除失败，请稍后再试", 2, () => { });
      return;
    }
    let async_id_list = JSON.parse(JSON.stringify(this.state.async_id_list));
    async_id_list = async_id_list.filter((item2) => {
      return (
        item2.async_id !== item.async_id ||
        (item2.async_id === item.async_id && item.user_type === 0)
      );
    });
    this.setState(
      {
        async_id_list: async_id_list,
      },
      () => {
        this.deleteAnalysisSession(item.id, index);
      }
    );
  }
  // 删除会话历史记录 接口
  async deleteAnalysisSession(id, index) {
    try {
      const response = await deleteAnalysisSession({
        id: id,
      });
      let session_list = JSON.parse(JSON.stringify(this.state.session_list));
      session_list.splice(index, 1);
      this.setState({
        session_list: session_list,
      });
      message.success("删除成功", 2, () => { });
      return response;
    } catch (error) {
      message.error("后台发生错误，删除成功，请稍后再试");
      throw error;
    }
  }
  // 获取问卷用户数据预览 接口
  async getSurveyUserAnswer() {
    clearTimeout(this.timer_filter);
    this.timer_filter = null;
    this.setState({
      loadiing_user_answer: 1,
    });
    let question_list_selected_index = this.state.question_list_selected.map(
      (item) => String(item.id)
    );
    try {
      const response = await getSurveyUserAnswer({
        survey_id: this.state.survey_id,
        question_list: question_list_selected_index,
        region_list: this.state.selected_region_list,
        gender_list: this.state.selected_gender_list,
        age_list: this.state.selected_age_list,
        word_count: this.state.word_count,
      });
      let table_list = response.data.data.filter((item, index) => {
        item["id"] = index;
        return item;
      });
      this.setState({
        loadiing_user_answer: 0,
        table_list: table_list,
      });
      return response;
    } catch (error) {
      message.error("后台发生错误，获取数据预览失败，请稍后再试");
      this.setState({
        loadiing_user_answer: 0,
      });
      throw error;
    }
  }
  //去除富文本
  removeRichtext(richtext) {
    return richtext.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, ""); //这里是去除标签
  }
  // 显示分页
  showPagination() {
    if (this.state.table_list.length < 5) {
      return false;
    }
    return { pageSize: 5, showSizeChanger: false, showQuickJumper: true };
  }
  // 获取异步任务执行进度
  // async getCeleryProgressView() {
  //   let res = await getCeleryProgressView({
  //     uid: "855f9e1a-186d-400c-84ae-c3dd0e7c5a1d",
  //   })
  // }
  // 发送prompt
  sendPrompt() {
    if (this.state.question_list.length === 0) {
      message.warning(`暂无简答题和定量题数据，无法进行分析`, 2, () => { });
      return;
    }
    if (this.state.question_list_selected.length === 0) {
      message.warning(`请选择题目，否则无法进行分析`, 2, () => { });
      return;
    }
    let prompt = this.getPrompt();

    prompt += `，进行分析`;
    let session_list = JSON.parse(JSON.stringify(this.state.session_list));
    let obj1 = {
      id: "",
      async_id: "",
      conclusion: prompt,
      user_type: 0,
      is_loading: false,
    };
    let obj2 = {
      id: "",
      async_id: "",
      conclusion: "好的，正在生成 请稍后…",
      user_type: 1,
      is_loading: true,
    };
    session_list.push(obj1);
    session_list.push(obj2);
    this.setState(
      {
        prompt: prompt,
        session_list: session_list,
        is_summary_analysis: 0,
      },
      () => {
        let user_index = this.state.session_list.length - 2;
        let gpt_index = this.state.session_list.length - 1;
        message.warning("预计生成要几分钟，请耐心等待", 2, () => { });
        this.getAnalysisQuestions(user_index, gpt_index);
      }
    );
  }
  // 发送总结prompt
  sendPromptForConclude() {
    if (!this.state.session_list.length) {
      message.warning("暂无当前对话，不能进行总结分析", 2, () => { });
      return;
    }
    let prompt = "您好，我想要总结当前对话。";
    let session_list = JSON.parse(JSON.stringify(this.state.session_list));
    let obj1 = {
      id: "",
      conclusion: prompt,
      user_type: 0,
      is_loading: false,
    };
    let obj2 = {
      id: "",
      conclusion: "好的，正在生成 请稍后…",
      user_type: 1,
      is_loading: true,
    };
    session_list.push(obj1);
    session_list.push(obj2);
    this.setState(
      {
        prompt: prompt,
        session_list: session_list,
        is_summary_analysis: 1,
      },
      () => {
        let user_index = this.state.session_list.length - 2;
        let gpt_index = this.state.session_list.length - 1;
        message.warning("预计生成要几分钟，请耐心等待", 2, () => { });
        this.getAnalysisQuestions(user_index, gpt_index);
      }
    );
  }
  render() {
    return (
      <div className="container gpt-analysis-container">
        {this.state.loading <= 0 && this.state.loadiing_user_answer <= 0 ? (
          ""
        ) : (
          <Loading loadingText={this.state.loadingText}></Loading>
        )}
        <div className="gpt-analysis-top">
          {window.location.pathname === "/ai_analysis_survey" ? (
            <SurveyDropdown
              handleSurveyChange={this.handleSurveyChange.bind(this)}
            ></SurveyDropdown>
          ) : (
            <ProductDropdown
              handleProductChange={this.handleProductChange.bind(this)}
            ></ProductDropdown>
          )}
        </div>
        <div className="gpt-analysis-main">
          <div className="gpt-analysis-main-top">
            <div className="gpt-analysis-title">总结分析</div>
          </div>
          {/* 筛选 */}
          <div className="gpt-analysis-selection-card">
            {/* <div className='gpt-analysis-content'> */}
            {/* <div
              className={`gpt-analysis-content ${this.state.is_prompt_pending
                ? "gpt-analysis-content-not-allowed"
                : ""
                }`}
            > */}
            <div className={`gpt-analysis-content`}>
              <div className="gpt-analysis-checkbox-card">
                {this.state.question_list.length ? (
                  <>
                    <div className="gpt-analysis-checkbox-top">
                      <div
                        className="gpt-analysis-checkbox-img"
                        onClick={this.handleAllQuestion.bind(this)}
                      >
                        <img
                          src={
                            this.state.checkbox_list[this.state.checkbox_index]
                              .img
                          }
                          alt=""
                        />
                      </div>
                      <div className="gpt-analysis-checkbox-name">
                        {
                          this.state.checkbox_list[this.state.checkbox_index]
                            .name
                        }
                      </div>
                    </div>
                    <div className="gpt-analysis-checkbox-body">
                      {this.state.question_list.map((item, index) => {
                        return (
                          <div className="question-wrapper" key={index}>
                            <div
                              className={`question-checkbox ${item.has_answer
                                ? ""
                                : "question-checkbox-not-allowed"
                                }`}
                              onClick={this.handleQuestion.bind(
                                this,
                                item,
                                index
                              )}
                            >
                              {item.is_selected ? (
                                <img src={cur_icon_rect_sel} alt="" />
                              ) : (
                                <img src={cur_icon_rect_nor} alt="" />
                              )}
                            </div>
                            <div className="question-name-wrapper">
                              <div className="question-name">
                                <span>{`${item.sort}. `}</span>
                                <span>{`${getQuestionTypeName(
                                  getQuestionType(item.question_type)
                                )}-`}</span>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: item.name,
                                  }}
                                ></span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </>
                ) : (
                  <ConfigProvider locale={zhCN}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  </ConfigProvider>
                )}
              </div>
              <div className="gpt-analysis-filter-card">
                <FilterAnalysisSelect
                  ref={this.filterRef}
                  updateFilter={this.updateFilter.bind(this)}
                ></FilterAnalysisSelect>
              </div>
            </div>
            {/* <div className='gpt-analysis-operation'> */}
            {/* <div
              className={`gpt-analysis-operation ${this.state.is_prompt_pending
                ? "gpt-analysis-operation-not-allowed"
                : ""
                }`}
            > */}
            {/* <div className={`gpt-analysis-operation`}>
              <div onClick={this.confirmFilterChat.bind(this)}>确认筛选</div>
            </div> */}
          </div>
          {/* 数据预览 */}
          {/* <div className="gpt-analysis-preview-card">
            <div className="gpt-analysis-preview-title">数据预览</div>
            <div className="gpt-analysis-preview-table">
              <ConfigProvider locale={zhCN}>
                <Table
                  dataSource={this.state.table_list}
                  columns={this.state.columns}
                  pagination={this.showPagination()}
                  rowKey={(columns) => columns.id}
                />
              </ConfigProvider>
              {this.state.table_list.length ? (
                <div className="table-sum-wrapper">
                  共<span>{this.state.table_list.length}</span>
                  个结果，当前展示1-5行
                </div>
              ) : (
                ""
              )}
            </div>
          </div> */}
          {/* 和gpt对话 */}
          <div className="gpt-analysis-chart-card">
            {this.state.session_list.length ? (
              <>
                {this.state.session_list.map((item, index) => {
                  return (
                    <div className="gpt-analysis-session-wrapper" key={index}>
                      {item.user_type === 0 ? (
                        <div className="gpt-analysis-session gpt-analysis-session-right">
                          <div className="gpt-analysis-session-text-wrapper">
                            <div
                              className="gpt-analysis-session-delete"
                              onClick={this.deleteSession.bind(
                                this,
                                item,
                                index
                              )}
                            >
                              <img src={cur_icon_bin} alt=""></img>
                            </div>
                            <div className="gpt-analysis-session-text">
                              <div
                                className="session-text"
                                dangerouslySetInnerHTML={{
                                  __html: item.conclusion,
                                }}
                              ></div>
                            </div>
                          </div>
                          <div className="gpt-analysis-session-avatar">
                            <img
                              src={`${this.props.user.profile_pic.file
                                ? this.props.user.profile_pic.file
                                : tab_img_avatar
                                }`}
                              alt=""
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="gpt-analysis-session gpt-analysis-session-left">
                          <div className="gpt-analysis-session-avatar">
                            <img src={logo_icon} alt="" />
                          </div>
                          <div className="gpt-analysis-session-text-wrapper">
                            <div className="gpt-analysis-session-text">
                              <div
                                className="session-text"
                                dangerouslySetInnerHTML={{
                                  __html: item.conclusion,
                                }}
                              ></div>
                              {item.is_loading ? (
                                <img src={icon_loading} alt="" />
                              ) : (
                                ""
                              )}
                            </div>
                            <div
                              className="gpt-analysis-session-delete"
                              onClick={this.deleteSession.bind(
                                this,
                                item,
                                index
                              )}
                              p
                            >
                              <img src={cur_icon_bin} alt=""></img>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            ) : (
              <ConfigProvider locale={zhCN}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </ConfigProvider>
            )}
          </div>
          {/* ask gpt */}
          <div className="gpt-analysis-ask-card">
            <EditNode
              value={this.state.user_input}
              onChange={this.onChangeEditor.bind(this)}
              placeholder="请输入您的内容"
              className="edit-question-title edit-question-title-ask"
            ></EditNode>
            <div className="gpt-analysis-ask-operation">
              <div onClick={this.sendPromptForConclude.bind(this)}>
                总结当前对话
              </div>
              <div onClick={this.sendPrompt.bind(this)}>确定</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  (state) => ({
    user: state.user,
  }), //映射状态
  {} //映射操作状态的方法
)(gpt_analysis);
