import { EDIT_USER, EDIT_USER_ITEM } from '../constant'
import { getBrandAuthInfoFull } from '../../api/token'
import { message } from 'antd';

//创建增加一个人的action动作对象
export const editUser = user_obj => ({ type: EDIT_USER, data: user_obj })
export const editUserItem = user_item_obj => ({ type: EDIT_USER_ITEM, data: user_item_obj })

//异步action，就是指action的值为函数,异步action中一般都会调用同步action，异步action不是必须要用的。
export const getUser = () => {
    return (dispatch) => {
        getBrandAuthInfoFull({}).then((response) => {
            let user_obj = {
                profile_pic: response.data.profile_pic,
                phone: response.data.phone.slice(3),//手机
                first_name: response.data.first_name,
                brand: response.data.brand,
                company: response.data.company,
                department: response.data.department,
                position: response.data.position,
                account_type: response.data.account_type,
                balance: response.data.balance,
                quota: response.data.quota,
                desired_info: response.data.desired_info,
            }
            dispatch(editUser(user_obj))
        }).catch(err => {
            message.error('获取信息失败')
        })
    }
}