import React, { Component } from 'react'
import "./freecross_info_product.scss"
import { Tabs, message, ConfigProvider, Table } from 'antd';
import XmSelect from '../../../components/Select/xm_select';
import FreecrossInfoPopup from '../../Free_cross/Freecross_info/freecross_info_popup/freecross_info_popup'
import no_data from '../../../images/free_cross/no_data.png'
import zhCN from 'antd/lib/locale/zh_CN';

export default class freecross_info_product extends Component {
    constructor(props) {
        super(props);
        this.maxTableRef = React.createRef();
        this.state = {
            survey_list: [],//调研主题
            product_id: "",
            table_list: [], //交叉分析表
            showFreecrossInfoPopup: false, // 是否显示放大图表的对话框
        }
    }
    componentDidMount() {
        this._isMounted = true;
    }
    componentDidUpdate(preProps, preState) {
        if (preProps.product_id !== this.props.product_id || preProps.survey_list !== this.props.survey_list || preProps.table_list !== this.props.table_list) {
            this.setState({
                product_id: this.props.product_id,
                survey_list: this.props.survey_list,
                table_list: this.props.table_list
            })
        }
    }
    componentWillUnMount = () => {
        this._isMounted = false;
        this.setState = () => { }
    }
    // 下拉框搜索可以大小写
    filterSelectOption(input, option) {
        return option.children.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    // 分页
    showPagination(table_data_list) {
        if (table_data_list.length < 30) {
            return false
        }
        return { pageSize: 30, showSizeChanger: false, showQuickJumper: true, size: "small" }
    }
    // 放大图表
    handleMaxFreecrossInfo(index) {
        this.setState({
            showFreecrossInfoPopup: true,
        }, () => {
            this.maxTableRef.current.setMaxFreecrossInfo({
                dataList: this.state.table_list[index] || [],
                info_table_index: index,
            })
        })
    }
    // 子组件传给父组件状态 是否放大图表
    canShowFreecrossInfoPopup(object) {
        this.setState({
            showFreecrossInfoPopup: object.showFreecrossInfoPopup
        })
    }
    render() {
        return (
            <div className='freecross-info-container'>
                <div className="freecross-info-top">
                </div>
                {
                    this.state.table_list.length ?
                        <div className="freecross-info-tables">
                            {
                                this.state.table_list.map((ele, index) => {
                                    return <div className="freecross-info-table" key={index}>
                                        <div className="freecross-info-table-name-wrapper">
                                            <div className="freecross-info-table-name">
                                                {ele.table_name}
                                            </div>
                                            <div className="operator-wrapper">
                                                <div className="operator-text" onClick={this.handleMaxFreecrossInfo.bind(this, index)}>放大</div>
                                            </div>
                                        </div>
                                        <div className="freecross-info-table-wrapper">
                                            <ConfigProvider locale={zhCN}>
                                                <Table
                                                    dataSource={ele.table_data_list}
                                                    columns={ele.columns}
                                                    pagination={this.showPagination(ele.table_data_list)}
                                                    rowKey={columns => columns.id}
                                                    scroll={{ x: 550 }}
                                                />
                                            </ConfigProvider>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        :
                        <div className="freecross-info-empty">
                            <img src={no_data} alt="" />
                            <div>可以在右侧选择查看数据哦～</div>
                        </div>
                }
                {
                    this.state.showFreecrossInfoPopup ?
                        <FreecrossInfoPopup
                            ref={this.maxTableRef}
                            canShowFreecrossInfoPopup={this.canShowFreecrossInfoPopup.bind(this)}
                        ></FreecrossInfoPopup> : ""
                }
            </div>
        )
    }
}
