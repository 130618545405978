import { ADD_SURVEY_QUEUE_ITEM, EDIT_SURVEY_QUEUE_ITEM, DELETE_SURVEY_QUEUE_ITEM, EDIT_SURVEY_QUEUE, START_POLLING, PRIORITIZE_SURVEY_QUEUE_ITEM } from '../constant'
import { getAISurveyQueue, getCeleryProgressView, setAISurveyQueue } from '../../api/Questionnaire_setting/question_template_api'
import { message } from 'antd';
import { survey_queue, polling_obj } from '../states/survey';

var surveyInterval = null

export const prioritizeSurveyQueueItem = survey_id => ({ type: PRIORITIZE_SURVEY_QUEUE_ITEM, data: survey_id })
// 入队
export const addSurveyQueueItem = queue_item => ({ type: ADD_SURVEY_QUEUE_ITEM, data: queue_item })

export const deleteSurveyQueueItem = () => ({ type: DELETE_SURVEY_QUEUE_ITEM, data: "" })

export const editSurveyQueueItem = queue_item => ({ type: EDIT_SURVEY_QUEUE_ITEM, data: queue_item })


//创建增加一个人的action动作对象
export const editSurveyQueue = survey_queue => ({ type: EDIT_SURVEY_QUEUE, data: survey_queue })
// export const editUserItem = user_item_obj => ({ type: EDIT_USER_ITEM, data: user_item_obj })

//异步action，就是指action的值为函数,异步action中一般都会调用同步action，异步action不是必须要用的。
export const getSurveyQueueFromBackend = () => {
    return (dispatch) => {
        getAISurveyQueue({}).then((response) => {
            dispatch(editSurveyQueue(response.data.ai_survey_queue))
        }).catch(err => {
            message.error('获取信息失败')
        })
    }
}

export const setSurveyQueueToBackEnd = (data) => {
    return (dispatch) => {
        setAISurveyQueue({
            ai_survey_queue: data
        }).then((response) => {
            console.log("设置成功")
        }).catch(err => {
            message.error('设置失败')
        })
    }
}

export const startPolling = polling_obj => ({ type: START_POLLING, data: polling_obj })

// export const setSurveyPolling = (queue_item) => {
//     return async (dispatch) => {
//         let status = queue_item.status
//         try {
//             const response = await getCeleryProgressView({ uid: queue_item.uid })
//             let status_current = response.data.status

//             if (status_current === 1) { // 任务完成
//                 message.success(`问卷${queue_item.survey_id}的${response.data.detail}`, 0.2, () => { })
//                 dispatch(deleteSurveyQueueItem())
//             } else if (status_current === 5 || status_current === 6) { // 任务失败
//                 message.error(`问卷${queue_item.survey_id}的${response.data.detail}`, 0.2, () => { })
//                 console.log("deleter")
//                 dispatch(deleteSurveyQueueItem())
//             } else { // 234
//                 if (status_current !== status) {
//                     let queue_item_current = JSON.parse(JSON.stringify(queue_item))
//                     queue_item_current.status = status_current
//                     dispatch(editSurveyQueueItem(queue_item_current))
//                 }
//             }
//         } catch (error) {
//             console.log(error)
//         }
//     }
// }


export const setSurveyPolling = (queue_item) => {
    return (dispatch) => {
        let interval_time = 5000
        clearInterval(surveyInterval)
        setTimeout(() => {
            surveyInterval = setInterval(async () => {
                let status = Number(queue_item.status)
                try {
                    const response = await getCeleryProgressView({ uid: queue_item.uid })
                    let status_current = Number(response.data.status)
                    let curTime = new Date()
                    let status = Number(queue_item.status)
                    if (Number(curTime) >= Number(queue_item.finish_time)) {
                        message.warning(`问卷<${queue_item.prompt.slice(0, 15)}...>超时,稍后再试`, 4, () => { })
                        dispatch(deleteSurveyQueueItem())
                        clearInterval(surveyInterval)
                    } else if (status_current === 1) { // 任务完成
                        message.success(`问卷<${queue_item.prompt.slice(0, 15)}...>的${response.data.detail}`, 4, () => { })
                        dispatch(deleteSurveyQueueItem())
                        clearInterval(surveyInterval)
                    } else if (status_current === 5 || status_current === 6) { // 任务失败
                        message.error(`问卷<${queue_item.prompt.slice(0, 15)}...>的${response.data.detail}`, 4, () => { })
                        dispatch(deleteSurveyQueueItem())
                        clearInterval(surveyInterval)
                    } else { // 234
                        if (status_current !== status) {
                            status = status_current
                            let queue_item_current = JSON.parse(JSON.stringify(queue_item))
                            queue_item_current.status = status_current
                            dispatch(editSurveyQueueItem(queue_item_current))
                        }
                    }
                } catch {
                    clearInterval(surveyInterval)
                }
            }, interval_time);
        }, 0)
    }
}

export const pollHandler = async (queue_item, dispatch) => {
    // let status = queue_item.status
    // try {
    //     const response = await getCeleryProgressView({ uid: queue_item.uid })
    //     let status_current = response.data.status

    //     if (status_current === 1) { // 任务完成
    //         clearInterval(surveyInterval)
    //         message.success(`问卷${queue_item.survey_id}的${response.data.detail}`, 0.2, () => { })
    //         dispatch(deleteSurveyQueueItem())
    //     } else if (status_current === 5 || status_current === 6) { // 任务失败
    //         clearInterval(surveyInterval)
    //         message.error(`问卷${queue_item.survey_id}的${response.data.detail}`, 0.2, () => { })
    //         console.log("deleter")
    //         dispatch(deleteSurveyQueueItem())
    //     } else { // 234
    //         if (status_current !== status) {
    //             let queue_item_current = JSON.parse(JSON.stringify(queue_item))
    //             queue_item_current.status = status_current
    //             dispatch(editSurveyQueueItem(queue_item_current))
    //         }
    //     }
    //     return response
    // } catch (error) {
    //     console.log(error)
    //     clearInterval(surveyInterval)
    // }
}
