import { theme_color_list } from "./Color_config"

export const pie_radius = ['25%', '48%']

export const pie_legend = {
    width: '80%',
    bottom: '6%',
    left: 'center',
    icon: 'circle', //设置图例图表形状
    itemWidth: 8,
    itemHeight: 8,
    itemGap: 10,
    // padding:20,
    type: 'scroll',
    pageIconColor: '#cbc9cf',
    pageIconInactiveColor: '#e5e4e7',
    pageIconSize: 12,
    pageTextStyle: {
        color: '#595961',
    },
    textStyle: {
        color: '#595961',
        fontSize: 12
    }
}

export function getPieTooltip(isConsumer, data, showOptionImg) {
    let pie_tooltip = {
        trigger: 'item',
        formatter: function (parms) {
            localStorage.setItem('Xindex', JSON.stringify(parms))
            let name = ""
            let maxNameLength = 10
            let rowNum = Math.ceil(parms.data.name.length / maxNameLength)
            for (let i = 0; i < rowNum; i++) {
                let tmp = ""
                let start = i * maxNameLength
                let end = start + maxNameLength
                if (i === rowNum - 1) {
                    tmp = parms.data.name.substring(start, end)
                } else {
                    tmp = parms.data.name.substring(start, end) + "</br>"
                }
                name += tmp
            }
            var str = parms.marker + "" + name + "</br>"

            let option_index = parseInt(parms.dataIndex)
            let option_img_str = ""
            if (showOptionImg && data[option_index].option_img) {
                option_img_str = "<img src=" + data[option_index].option_img + " style='width: auto;height: 150px;vertical-align: middle;object-fit: cover;'></img>"
            }
            if (option_img_str) {
                str = parms.marker + "" + name + "</br>" + option_img_str + "</br>"
            }

            if (isConsumer) {
                str = str + "占比：" + parms.percent + "%";
            } else {
                str = str + "数量：" + parms.data.value + "</br>" +
                    "占比：" + parms.percent + "%";
            }
            return str;
        }
    }
    return pie_tooltip
}

export function getPieSeries(data) {
    let pie_series = [
        {
            name: 'Access From',
            type: 'pie',
            radius: pie_radius,
            avoidLabelOverlap: false,

            emphasis: {
                label: {
                    show: true,
                    fontSize: 12
                }
            },
            label: { //饼图图形上的文本标签
                fontSize: 12,    //文字的字体大小
                fontWeight: 400,
                color: '#9790A1',
                lineHeight: 14,
                // formatter: '{b}\n{c}%',
                // formatter: '{b}:{d}%'
                // formatter: '{name|{b}}\n{percent|{d}%}',
                formatter: function (params) {
                    let name = params.data.name.toString();
                    let maxWordLength = 7
                    if (name.length > maxWordLength) {
                        name = name.substring(0, maxWordLength - 2) + "..";
                    }
                    // name = name + "\n" + params.percent + "%"
                    return `{name|${name}}\n{percent|${params.percent}%}`
                },
                rich: {
                    percent: {
                        fontSize: 12,
                        color: '#9797A1'
                    }
                }
            },
            labelLine: {
                show: true,
                lineStyle: {
                    color: '#E8E8EB'
                }
            },
            data: data
        }
    ]
    return pie_series
}
export function getPieSeries_max(data) {
    let pie_series = [
        {
            name: 'Access From',
            type: 'pie',
            radius: pie_radius,
            avoidLabelOverlap: false,

            emphasis: {
                label: {
                    show: true,
                    fontSize: 12
                }
            },
            label: { //饼图图形上的文本标签
                fontSize: 12,    //文字的字体大小
                fontWeight: 400,
                color: '#9790A1',
                lineHeight: 14,
                // formatter: '{b}\n{c}%',
                // formatter: '{b}:{d}%'
                // formatter: '{name|{b}}\n{percent|{d}%}',
                formatter: function (params) {
                    let name = params.data.name.toString();
                    // let maxWordLength = 7
                    // if (name.length > maxWordLength) {
                    //     name = name.substring(0, maxWordLength - 2) + "..";
                    // }
                    // name = name + "\n" + params.percent + "%"
                    return `{name|${name}}\n{percent|${params.percent}%}`
                },
                rich: {
                    percent: {
                        fontSize: 12,
                        color: '#9797A1'
                    }
                }
            },
            labelLine: {
                show: true,
                lineStyle: {
                    color: '#E8E8EB'
                }
            },
            data: data
        }
    ]
    return pie_series
}
export function getPieOption(data, isConsumer, isMaxSreen, showOptionImg) {
    let option = {
        tooltip: getPieTooltip(isConsumer, data, showOptionImg),
        legend: pie_legend,
        color: theme_color_list,
        series: isMaxSreen === true ? getPieSeries_max(data) : getPieSeries(data),
    }
    return option
}