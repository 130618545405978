import React, { Component } from 'react'
import { Table, ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import './detail_option_list.scss';

export default class detail_option_list extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detail_option_list: [],
            dataList: {},
            needRefresh: false,
            columns: [
                {
                    title: '用户名',
                    dataIndex: 'name',
                    key: 'name',
                    width: 100,
                    // fixed: 'left',
                },
                {
                    title: '性别',
                    dataIndex: 'gender',
                    key: 'gender',
                    width: 60,
                },
                {
                    title: '年龄',
                    dataIndex: 'age',
                    key: 'age',
                    width: 60,
                },
                {
                    title: '地址',
                    dataIndex: 'address',
                    key: 'address',
                    width: 80,
                },
                {
                    title: '评论',
                    dataIndex: 'content',
                    key: 'content',
                    render: (content, index) => {
                        if (this.props.search_text !== "") {
                            content = content.replace(this.props.search_text, `<span style='color: #FF5186'>${this.props.search_text}</span>`)
                        }
                        let html = { __html: content };
                        return <div dangerouslySetInnerHTML={html}></div>;
                    }
                },
            ]
        };
    }
    static getDerivedStateFromProps(props, state) {
        if (JSON.stringify(props.dataList) !== JSON.stringify(state.dataList_prev)) {
            return {
                needRefresh: true,
                dataList: props.dataList,
                dataList_prev: props.dataList
            }
        }
        return null;
    }
    componentDidMount() {
        this._isMounted = true
        if (this._isMounted) {

            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentDidUpdate() {
        if (this._isMounted && this.state.needRefresh) {
            this.toInitChart()
            this.setState({
                needRefresh: false
            })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        this.setState = () => { }
    }
    toInitChart() {
        let detail_option_list = []
        for (let i = 0; i < this.props.dataList.length; i++) {
            let obj = {
                name: this.props.dataList[i].name,
                age: this.props.dataList[i].age,
                gender: this.props.dataList[i].gender,
                address: this.props.dataList[i].address,
                content: this.props.dataList[i].content,
                id: "detail_option_list_" + i
            }
            detail_option_list.push(obj)
        }
        this.setState({
            detail_option_list: detail_option_list,
        })
    }
    showPagination() {
        if (this.state.detail_option_list.length < 30) {
            return false
        }
        return { pageSize: 30, showSizeChanger: false, showQuickJumper: true, size: "small" }
    }
    render() {
        return (
            <div className="detail-option-list-container">
                <ConfigProvider locale={zhCN}>
                    <Table
                        dataSource={this.state.detail_option_list}
                        columns={this.state.columns}
                        pagination={this.showPagination()}
                        rowKey={columns => columns.id}
                        scroll={{ x: 400, y: this.props.isMaxChart ? this.props.blankListHeight : this.props.blankListHeight }} />
                </ConfigProvider>
            </div>
        )
    }
}
