import axiosInstance from '../APIUtils'

// 获取收货仓库信息接口
export function getWarehouseInfoList(obj) {
    return axiosInstance({
        url: "/cem/getWarehouseInfoList/",
        method: "POST",
        data: obj
    });
}

// 保存项目派送收货仓发货信息
export function saveProjectBusinessLogistic(obj) {
    return axiosInstance({
        url: "/cem/saveProjectBusinessLogistic/",
        method: "POST",
        data: obj
    });
}

// 获取派送计划收货仓发货记录
export function getProjectBusinessLogisticList(obj) {
    return axiosInstance({
        url: "/cem/getProjectBusinessLogisticList/",
        method: "POST",
        data: obj
    });
}

// 修改派送计划收货仓发货记录
export function updateProjectBusinessLogistic(obj) {
    return axiosInstance({
        url: "/cem/updateProjectBusinessLogistic/",
        method: "POST",
        data: obj
    });
}

// 删除产品派送计划发货记录
export function deleteProjectBusinessLogistic(obj) {
    return axiosInstance({
        url: "/cem/deleteProjectBusinessLogistic/",
        method: "POST",
        data: obj
    });
}

